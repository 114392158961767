import * as types from '../store/orders/questionnaire/types'
import i18n from '../i18n'

type LooseQuestionnaire = types.Questionnaire | undefined

export const getTargetJobURLs = (questionnaire: LooseQuestionnaire): types.JobPosting[] => {
  if (!questionnaire || !questionnaire.questions || !questionnaire.questions.goals) {
    return []
  }

  const goals = questionnaire.questions.goals

  if (goals.answers.length === 0) {
    return []
  }

  return goals.answers.map(goal => goal.answer).filter(goal => !!goal.url && goal.url.length > 0)
}

export const getClientNotes = (questionnaire: LooseQuestionnaire): string[] | null => {
  if (
    !questionnaire ||
    !questionnaire.questions ||
    !questionnaire.questions.writerNote ||
    questionnaire.questions.writerNote.answers.length === 0
  ) {
    return null
  }

  return questionnaire.questions.writerNote.answers.map(note => note.answer)
}

export const getGoals = (questionnaire: types.Questionnaire | undefined): string[] => {
  if (
    !questionnaire ||
    !questionnaire.questions ||
    !questionnaire.questions.job_title ||
    questionnaire.questions.job_title.answers.length === 0
  ) {
    return []
  }

  return questionnaire.questions.job_title.answers.map(jt => jt.answer).filter(jt => jt.length > 0)
}

export const getAccomplishments = (questionnaire: LooseQuestionnaire): types.Accomplishment[] => {
  if (
    !questionnaire ||
    !questionnaire.questions ||
    !questionnaire.questions.accomplishments_list ||
    questionnaire.questions.accomplishments_list.answers.length === 0
  ) {
    return []
  }

  return questionnaire.questions.accomplishments_list.answers
    .map(
      (accomplishment): types.Accomplishment => {
        const { list = [i18n.t('accomplishmentList__defaultText')] } = accomplishment.answer
        let filteredList = list.flat().filter(l => (Array.isArray(l) ? l.find(li => !!li) : !!l && l.length > 0))

        if (!filteredList.length) {
          filteredList = [i18n.t('accomplishmentList__defaultText')]
        }

        return {
          ...accomplishment.answer,
          list: filteredList,
        }
      }
    )
    .filter(
      accomplishment =>
        accomplishment.list &&
        accomplishment.list.length > 0 &&
        accomplishment.list.filter(a => (Array.isArray(a) ? a.find(li => !!li) : !!a)).length > 0
    )
}

export const getAccomplishmentsTexts = (questionnaire: LooseQuestionnaire): types.QuestionAnswer<string[]>[] => {
  const result: types.QuestionAnswer<string[]>[] = []

  if (questionnaire && questionnaire.questions) {
    if (questionnaire.questions.accomplishment_text && questionnaire.questions.accomplishment_text.answers.length > 0) {
      result.push({
        question: questionnaire.questions.accomplishment_text.question,
        answer: questionnaire.questions.accomplishment_text.answers
          .map(a => a.answer.trim())
          .filter(a => !!a && a.length > 0),
      })
    }

    if (
      questionnaire.questions.accomplishments_text &&
      questionnaire.questions.accomplishments_text.answers.length > 0
    ) {
      result.push({
        question: questionnaire.questions.accomplishments_text.question,
        answer: questionnaire.questions.accomplishments_text.answers
          .map(a => (typeof a.answer === 'string' ? a.answer.trim() : a.answer.toString()))
          .filter(a => !!a && a.length > 0),
      })
    }
  }

  return result
}

export const getConcerns = (questionnaire: LooseQuestionnaire): types.QuestionAnswer<string[]>[] => {
  return types.ConcernKeys.map((key: types.ConcernKey): types.QuestionAnswer<string[]> | null => {
    if (!questionnaire || !questionnaire.questions) {
      return null
    }

    const concernQuestion = questionnaire.questions[key]

    if (!concernQuestion || concernQuestion.answers.length === 0) {
      return null
    }

    return {
      question: concernQuestion.question,
      answer: concernQuestion.answers.map(a => a.answer).filter(a => !!a && a.length > 0),
    }
  }).filter((qa): qa is types.QuestionAnswer<string[]> => !!qa && qa.answer.length > 0)
}

export const getLinkedInURL = (questionnaire: LooseQuestionnaire): string | null => {
  if (
    !questionnaire ||
    !questionnaire.questions ||
    !questionnaire.questions.linkedinLink ||
    questionnaire.questions.linkedinLink.answers.length === 0
  ) {
    return null
  }

  return questionnaire.questions.linkedinLink.answers[0].answer
}

export const getAddress = (questionnaire: LooseQuestionnaire): string[] | null => {
  if (
    !questionnaire ||
    !questionnaire.questions ||
    !questionnaire.questions.address ||
    questionnaire.questions.address.answers.length === 0
  ) {
    return null
  }

  const addresses = questionnaire.questions.address.answers
    .map((rawAddress): string | null => {
      const { street1, city, state, zip, country, location } = rawAddress.answer
      const addressParts = [street1, city, state, zip, country]
        .map((ap): string => {
          if (!ap) {
            return ''
          }

          return Array.isArray(ap) ? ap.map(a => a.trim()).join(', ') : ap.trim()
        })
        .filter(ap => !!ap)

      const address = addressParts.join(', ')

      if (address.length === 0) {
        if (!!location) {
          return location
        }

        return null
      }

      return address
    })
    .filter((address): address is string => !!address && address.length > 0)

  if (addresses.length === 0) {
    return null
  }

  return addresses
}

export const getPhoneNumbers = (questionnaire: LooseQuestionnaire): types.PhoneNumber[] | null => {
  if (
    !questionnaire ||
    !questionnaire.questions ||
    !questionnaire.questions.phone ||
    questionnaire.questions.phone.answers.length === 0
  ) {
    return null
  }

  return questionnaire.questions.phone.answers.map(a => a.answer)
}

export const getName = (questionnaire: LooseQuestionnaire): string | null => {
  if (
    !questionnaire ||
    !questionnaire.questions ||
    !questionnaire.questions.name ||
    questionnaire.questions.name.answers.length === 0
  ) {
    return null
  }

  const nameAnswer = questionnaire.questions.name.answers[0]

  // Most newer questionnaires store the full name as a simple string
  if (nameAnswer.type === 'text') {
    return nameAnswer.answer.trim()
  }

  const { firstName, middleName, lastName, fullName } = nameAnswer.answer

  // Older questionnaires store them as an object, tho. If we have full name, let's just return it.
  if (typeof fullName === 'string' && fullName.length > 0) {
    return fullName.trim()
  }

  // Otherwise, lets join the name parts
  return [firstName, middleName, lastName]
    .map(n => typeof n === 'string' && n.trim())
    .filter(n => !!n)
    .join(' ')
}

export const getIndustry = (questionnaire: LooseQuestionnaire): string | null => {
  if (
    !questionnaire ||
    !questionnaire.questions ||
    !questionnaire.questions.industry ||
    questionnaire.questions.industry.answers.length === 0
  ) {
    return null
  }

  const industryAnswer = questionnaire.questions.industry.answers[0].answer

  if (industryAnswer.other_answer) {
    return industryAnswer.other_answer.trim()
  }

  if (industryAnswer.answer) {
    return industryAnswer.answer
  }

  const translatedAnswer = i18n.t(`industry__industry${industryAnswer.id}`)

  if (!translatedAnswer) {
    return null
  }

  return translatedAnswer
}

export const getEmail = (questionnaire: LooseQuestionnaire): string | null => {
  if (
    !questionnaire ||
    !questionnaire.questions ||
    !questionnaire.questions.email ||
    questionnaire.questions.email.answers.length === 0
  ) {
    return null
  }

  return questionnaire.questions.email.answers[0].answer
}

export const getCountry = (questionnaire: LooseQuestionnaire): string | null => {
  if (
    !questionnaire ||
    !questionnaire.questions ||
    !questionnaire.questions.address ||
    questionnaire.questions.address.answers.length === 0 ||
    !questionnaire.questions.address.answers[0].answer.country
  ) {
    return null
  }

  return questionnaire.questions.address.answers[0].answer.country
}

export const getIsInternational = (questionnaire: LooseQuestionnaire): boolean => {
  if (
    !questionnaire ||
    !questionnaire.questions ||
    !questionnaire.questions.address ||
    questionnaire.questions.address.answers.length === 0 ||
    !questionnaire.questions.address.answers[0].answer.nonUsAddress
  ) {
    return false
  }

  return questionnaire.questions.address.answers[0].answer.nonUsAddress
}

export const getResumeImages = (questionnaire: LooseQuestionnaire): string[] => {
  if (!questionnaire) {
    return []
  }

  return questionnaire.resume_images || []
}

export const getResumeDownloadURL = (questionnaire: LooseQuestionnaire): string | null => {
  if (
    !questionnaire ||
    !questionnaire.questions ||
    !questionnaire.questions.documents ||
    !questionnaire.questions.documents.files.length
  ) {
    return null
  }

  return questionnaire.questions.documents.files[0].download_url || null
}

export const getAdditionalDocuments = (questionnaire: LooseQuestionnaire): types.QuestionAnswer<types.File[]>[] => {
  return types.AdditionalDocumentsKeys.map((key): types.QuestionAnswer<types.File[]> | null => {
    if (!questionnaire || !questionnaire.questions) {
      return null
    }

    const item = questionnaire.questions[key]

    if (!item || item.answers.length === 0 || item.files.length === 0) {
      return null
    }

    return {
      question: item.question,
      answer: item.files,
    }
  }).filter((d): d is types.QuestionAnswer<types.File[]> => !!d && d.answer.length > 0)
}
