import { OfferPromise, UserPromiseState } from '../store/promises/types'

export const userCurrentPromise = (state: UserPromiseState): [OfferPromise | null, boolean] => {
  try {
    if (!state.currentPromiseID) {
      return [null, state.displayPromises]
    }
    return [state.promises[state.currentPromiseID], state.displayPromises]
  } catch (e) {
    return [null, false]
  }
}

export const userHasPromises = (state: UserPromiseState): boolean => {
  try {
    return Object.keys(state.promises).length > 0
  } catch (e) {
    return false
  }
}
