import { FileContexts } from '../documents/types'
import { OrderItemTypes } from '../items/types'
import { LoadedLoadingErrorState, ProgressLoadingErrorState } from '../../utils/state'
import { DocumentResponse } from '../documents/types'

export interface FileUploadRequest {
  file: File
  context: FileContexts
  context_id: number
  order_id?: number | null
  order_item_id?: number | null
  doc_type?: OrderItemTypes | null
  doc_type_tag_keys?: string | null
  revision?: number | null
}

export interface PendingUploadState extends FileUploadRequest {
  preprocessID?: number | null
}

export interface FileUpload {
  file: FileUploadRequest
  meta: ProgressLoadingErrorState
  preprocessID?: number
  fileID?: number
}

export interface FileUploadResponse {
  scan_options: FileScanOption[]
  preprocess_id: number
}

export enum FileScanOptionKeys {
  Watermark = 'watermark_sync',
  Virus = 'virus_sync',
  FileSize = 'file_size_async',
  RA = 'resume_authority_sync',
  DraftComparison = 'draft_comparison_async',
  AutoQA = 'auto_qa_async',
}

export enum FileScanResults {
  Success = 'success',
  Failure = 'failure',
  Pending = 'pending',
  Warning = 'warning',
}

export interface FileScanOption {
  async: boolean
  route: string
  active: boolean
  require_success: boolean
  allow_failure_retry: boolean
  key: FileScanOptionKeys
}

interface FileScanError {
  error: string
}

interface FileScanWatermark {
  watermarked: boolean
}

interface FileScanVirus {
  scan_id: string
}

export interface FileSizeScanOutput {
  percentile: number
  size: string
  success: boolean
}

interface FileScanResumeAuthoritySync {
  document_digest: string
  ra_result: object
  success: boolean
}

export interface FileScanResult<Output = AllScanTypes> {
  result: FileScanResults
  output?: Output | FileScanError
  run_time?: number
  name: FileScanOptionKeys
  id?: number
  preprocess_id: number
}

export interface DraftComparisonScanOutput {
  draft_comparison: {
    contact_info: {
      Differences: ContactInformationDiff[]
    }
  }
}

export enum AutoQAScanResult {
  Pass = 'pass',
  Warning = 'warning',
  Fail = 'fail',
}

export enum AutoQAImprovementStatus {
  Same = 'same',
  Worse = 'worse',
  Better = 'better',
}

export enum AutoQASectionType {
  Readability = 'readability',
  Originality = 'originality',
  ContactInfo = 'contact_info',
}

export enum AutoQAFeatureKeys {
  LanguageVerbatim = 'verbatim',
  LanguageTemplates = 'templates',
  LanguagePersonal = 'personal',
  LanguageRepetition = 'repetition',
  LanguageWeak = 'weak',
  LanguageGrammar = 'grammar',
  LanguageReadability = 'readability',
  ClientContactInfo = 'contact_info',
}

export interface AnalysisSection {
  total_score: number
}

export interface LanguageAnalysisSection extends AnalysisSection {
  stems: StemCount[] | null
}

export interface GrammarAnalysisSection extends AnalysisSection {
  sentences: SentenceGrammarAnalysis[]
}

export interface ReadabilityAnalysisResult extends AnalysisSection {
  sections: ReadabilityAnalysisSection[]
}

export interface PhraseAnalysisSection extends AnalysisSection {
  error_count: number
  phrases: PhraseScore[]
}

export interface ContactInfoAnalysisSection extends AnalysisSection {
  differences: ContactInfoDifference[] | null
}

export interface ContactInfoDifference {
  key: string
  orig: string
  draft: string
}

export interface PhraseScore {
  score: number
  phrase: string
}

export interface StemCount {
  stem: string
  count: number
  score: number
  occurrences: string[]
}

export interface SentenceGrammarAnalysis {
  score: number
  sentence: string
  reasons: string[]
}

export interface ReadabilityAnalysisSection {
  score: number
  flesch_reading_ease: number
  text: string
}

export interface AutoQAFeature {
  result: AutoQAScanResult
  feature: AutoQAFeatureKeys
  improvements: AutoQAImprovementStatus
  id: number
}

export interface AutoQALanguageFeature extends AutoQAFeature {
  details: LanguageAnalysisSection
}

export interface AutoQAGrammarFeature extends AutoQAFeature {
  details: GrammarAnalysisSection
}

export interface AutoQAPhraseCollisionsFeature extends AutoQAFeature {
  details: PhraseAnalysisSection
}

export interface AutoQAContactInfoFeature extends AutoQAFeature {
  details: ContactInfoAnalysisSection
}

export interface AutoQAScanOutput {
  total_result: AutoQAScanResult
  results: AutoQAFeature[]
}

export interface ContactInformationDiff {
  Draft: string
  Key: string
  Orig: string
}

export interface UploadsState {
  // files that have finished scanning
  uploads: FileUploadList
  // files that have been uploaded for preprocessing and are being processed
  preprocess: FilePreprocessList
  // files that have not been uploaded yet
  pending: FilePendingList
}

export interface FilePreprocessList {
  [preprocessId: number]: FileScan
}

export interface FileUploadList {
  [uploadIdentifier: string]: FileUpload
}

export interface FilePendingList {
  [filename: string]: PendingUploadState
}

export interface UploadOverview {
  pending: PendingUploadState
  preprocess: FileScan | null
  upload: FileUpload | null
}

export type AllScanTypes =
  | FileScanVirus
  | FileScanWatermark
  | FileScanResumeAuthoritySync
  | FileSizeScanOutput
  | DraftComparisonScanOutput
  | AutoQAScanOutput

export interface FileScan {
  [FileScanOptionKeys.Virus]?: ScanDetails<FileScanVirus>
  [FileScanOptionKeys.Watermark]?: ScanDetails<FileScanWatermark>
  [FileScanOptionKeys.RA]?: ScanDetails<FileScanResumeAuthoritySync>
  [FileScanOptionKeys.FileSize]?: ScanDetails<FileSizeScanOutput>
  [FileScanOptionKeys.DraftComparison]?: ScanDetails<DraftComparisonScanOutput>
  [FileScanOptionKeys.AutoQA]?: ScanDetails<AutoQAScanOutput>
  order: FileScanOptionKeys[]
}

export interface ScanDetails<Output = AllScanTypes> {
  meta: LoadedLoadingErrorState
  option?: FileScanOption
  result?: FileScanResult<Output>
}

export interface UploadFinalizeResponse {
  file: DocumentResponse
}

export interface UploadDocTypeMenu {
  order_id: number | null
  order_item_id: number | null
  doc_type: OrderItemTypes
  doc_type_tag_keys: string
  label: string
  duplicateIndex: number | null
  revision?: number
}
