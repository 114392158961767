import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import i18n from '../../i18n'
import styles from '../../styles'
import { UploadDocVersionMenu } from './Upload'
import { FileUploadRequest, PendingUploadState, UploadDocTypeMenu } from '../../store/uploads/types'
import StyledSelect from '../common/StyledSelect'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '.8rem .5rem',
      borderBottom: '1px solid #dbe0eb',
      maxWidth: '420px',
    },
    icon: {
      marginRight: '1rem',
      fontSize: '1.875rem',
      '@media (max-width: 440px)': {
        display: 'none',
      },
    },
    file: {
      flexGrow: 1,
      position: 'relative',
      marginRight: '1rem',
    },
    fileName: {
      width: '100%',
      marginBottom: '1.2rem',
      textAlign: 'left',
      fontSize: '1.2rem',
      fontWeight: 500,
      color: styles.palette.contrast.color,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    controls: {
      display: 'flex',
      justifyContent: 'space-between',

      '& > *': {
        width: '48%',
      },
    },
    errors: {},
    error: {
      marginTop: '.3rem',
      color: '#ff6650',
    },
    closeContainer: {
      position: 'absolute',
      right: '-1.5rem',
      top: '-.125rem',
      padding: '.125rem',
      '& .icon': {
        color: '#6f737c',
        fontSize: '1rem',
      },
    },
    '@media (max-width: 800px)': {
      controls: {
        flexDirection: 'row',
      },
    },
  })
)

interface UploadItemProps {
  file: PendingUploadState
  removeItem: () => void
  updateItem: (data: Partial<FileUploadRequest>) => void
  showErrors: boolean
  options: {
    type: UploadDocTypeMenu[]
    version: UploadDocVersionMenu[]
  }
}

const UploadItem: React.FC<UploadItemProps> = ({ file, removeItem, updateItem, options, showErrors }) => {
  const classes = useStyles()

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      updateItem({
        [event.target.name as string]: event.target.value,
      })
    },
    [updateItem]
  )

  const handleDocTypeChange = React.useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const orderItemID = event.target.value
      const targetItem = options.type.find(o => o.order_item_id === orderItemID)

      if (targetItem) {
        updateItem({
          doc_type: targetItem.doc_type,
          doc_type_tag_keys: targetItem.doc_type_tag_keys,
          order_item_id: targetItem.order_item_id,
          revision: targetItem.revision,
        })
      }
    },
    [updateItem, options.type]
  )

  return (
    <Box className={classes.root}>
      <Box className={classes.file}>
        <Typography variant={'h2'} className={classes.fileName}>
          {file.file.name}
        </Typography>
        <Box className={classes.controls}>
          <StyledSelect
            label={i18n.t('messaging__upload__fileType')}
            value={file.order_item_id || ''}
            onChange={handleDocTypeChange}
            items={options.type.map(o => {
              return {
                value: o.order_item_id || '',
                label: o.label + (o.duplicateIndex ? ` #${o.duplicateIndex}` : ''),
              }
            })}
            SelectProps={{
              fullWidth: false,
            }}
            variant="shadowed"
          />
          <StyledSelect
            label={i18n.t('messaging__upload__version')}
            value={isNaN(file.revision as number) || file.revision === null ? '' : (file.revision as number)}
            onChange={handleChange}
            items={options.version}
            name="revision"
            SelectProps={{
              fullWidth: false,
            }}
            variant="shadowed"
          />
        </Box>
        {showErrors && (
          <Box className={classes.errors}>
            {!file.revision && file.revision !== 0 && (
              <Typography variant={'h6'} className={classes.error}>
                {i18n.t('messaging__upload__error__version')}
              </Typography>
            )}
            {!file.order_item_id && (
              <Typography variant={'h6'} className={classes.error}>
                {i18n.t('messaging__upload__error__fileType')}
              </Typography>
            )}
          </Box>
        )}
        <IconButton className={classes.closeContainer} onClick={() => removeItem()}>
          <CloseIcon className="icon" />
        </IconButton>
      </Box>
    </Box>
  )
}

export default UploadItem
