import produce from 'immer'

import { ClientsActionType, ClientsActionTypes } from './actions'
import { OrdersActionTypes } from '../orders/actions'
import * as stateUtils from '../../utils/state'
import { ClientOrdersAggregateActions } from '../clientOrderAggregate/actions'
import { ClientProductsMetadata } from './types'
import { MessageActionTypes } from '../messages/actions'
import { MessageContexts, MessageStatuses } from '../messages/types'

export interface Client {
  id: number
  full_name: string
  primary_email: string
  bcc_email: string | null
  secondary_emails: string[] | null
  products_user_id: number
  order_ids: number[]
  email_domain: string
  default_resume_product_name: 'Resume' | 'CV'
  industry_id: number
  industry_freeform: string
  unread_mail_count: number
  productsMetadata?: ClientProductsMetadata
  test_order: 0 | 1
  outplacement_order: 0 | 1
  CIOMagicLink?: string
}

export interface ClientList {
  [client_id: number]: Client
}

export interface Upsell {
  name: string
  discount_token: string
  discount_pct: number | null
  purchase_url: string
  upsell_eligible: boolean
}

export interface UpsellList {
  [client_id: number]: Upsell[]
}

export interface ClientsState {
  clients: ClientList
  meta: stateUtils.MultiLoadedLoadingErrorState
  upsells: UpsellList
}

export const initialState: ClientsState = {
  clients: {},
  meta: stateUtils.initialMultiLoadedLoadingErrorState,
  upsells: {},
}

export default function clientsReducer(state: ClientsState = initialState, action: ClientsActionType): ClientsState {
  return produce(state, (draft: ClientsState) => {
    switch (action.type) {
      case ClientsActionTypes.FETCH_CLIENTS:
        draft.meta.global = stateUtils.setStartState(state.meta.global)
        break

      case ClientsActionTypes.FETCH_CLIENT: {
        const clientID = action.clientID
        draft.meta = stateUtils.setStartStateForItem(clientID, state.meta)
        break
      }

      case ClientsActionTypes.FETCH_CLIENTS_SUCCESS:
        draft.meta.global = stateUtils.setSuccessState(state.meta.global)
        const clients = action.payload.data
        clients.forEach(client => {
          draft.clients[client.id] = client
        })
        break

      case ClientsActionTypes.FETCH_CLIENT_SUCCESS:
        {
          const clientID = action.meta.previousAction.clientID
          draft.meta = stateUtils.setSuccessStateForItem(clientID, state.meta)
          const client = action.payload.data
          if (!draft.clients[client.id]) {
            draft.clients[client.id] = client
          }
        }
        break

      case ClientsActionTypes.FETCH_CLIENTS_FAIL:
        draft.meta.global = stateUtils.setErrorState(state.meta.global, action.error)
        break

      case ClientsActionTypes.FETCH_CLIENT_FAIL:
        {
          const clientID = action.meta.previousAction.clientID
          draft.meta = stateUtils.setErrorStateForItem(clientID, state.meta, action.error)
        }
        break

      case OrdersActionTypes.FETCH_ORDERS_SUCCESS:
        const ordersData = action.payload.data
        ordersData &&
          ordersData.forEach(order => {
            draft.clients[order.client.id] = order.client
          })
        break

      case OrdersActionTypes.FETCH_ORDER_SUCCESS:
        const order = action.payload.data
        draft.clients[order.client.id] = order.client
        break

      case ClientOrdersAggregateActions.FETCH_USER_AGGREGATED_ORDERS_SUCCESS: {
        const response = action.payload.data
        !!response &&
          response.orders &&
          response.orders.forEach(aggr => {
            const clientID = aggr.client.id
            draft.clients[clientID] = aggr.client
            draft.clients[clientID].order_ids = aggr.orders.map(order => order.order_id)
          })
        break
      }
      case ClientOrdersAggregateActions.FETCH_CLIENT_ORDERS_SUCCESS: {
        const response = action.payload.data
        const clientID = action.meta.previousAction.clientID
        if (!!response && response.length > 0) {
          // careful here - non-verbose version of the response might overwrite verbose client info
          draft.clients[clientID] = response[0].client
          // PHANTOM PAYMENTS CAN MESS THIS UP
          draft.clients[clientID].order_ids = response.map(x => x.order_id)
        }
        break
      }
      case ClientsActionTypes.FETCH_CLIENT_METADATA_SUCCESS:
        {
          const response = action.payload.data
          const clientID = action.meta.previousAction.clientID
          draft.clients[clientID].productsMetadata = response
        }
        break
      case MessageActionTypes.STATUS_CREATE_SUCCESS:
        {
          // if we're ghosting message states are displayed for the authenticated user regardless of who they are ghosting,
          // so we can go ahead and update the count
          const meta = action.meta.previousAction
          if (meta.context === MessageContexts.CLIENT) {
            const clientId = meta.contextID
            const status = action.payload.data.status
            if (status === MessageStatuses.Read && draft.clients[clientId].unread_mail_count > 0) {
              draft.clients[clientId].unread_mail_count -= 1
            }
          }
        }
        break
      case MessageActionTypes.STATUS_END_SUCCESS:
        {
          const meta = action.meta.previousAction
          if (meta.context === MessageContexts.CLIENT) {
            const clientId = meta.contextID
            const status = action.payload.data.status
            if (status === MessageStatuses.Read) {
              draft.clients[clientId].unread_mail_count += 1
            }
          }
        }
        break
      case ClientsActionTypes.UPDATE_CLIENT_SUCCESS:
        {
          const clientID = action.meta.previousAction.clientID
          draft.clients[clientID] = { ...draft.clients[clientID], ...action.payload.data }
        }
        break

      case ClientsActionTypes.FETCH_UPSELL_DATA_SUCCESS:
        {
          const response = action.payload.data
          const clientID = action.meta.previousAction.clientID

          draft.upsells[clientID] = response
        }
        break
      case ClientsActionTypes.FETCH_CIO_MAGIC_LINK_SUCCESS:
        {
          const response = action.payload.data
          const clientID = action.meta.previousAction.clientID

          draft.clients[clientID].CIOMagicLink = response.url
        }
        break
    }
  })
}
