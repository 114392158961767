import React from 'react'
import Select, { SelectProps } from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'

import NotesStyles from '../Notes/Styles'
import styles from '../../styles/index'

export interface MenuItemWithCount {
  value: string | number
  label: string | React.ReactNode | React.ReactNodeArray
  count?: number
}

interface Props {
  items: MenuItemWithCount[]
  value: string | number
  onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => void
  outlined?: boolean
  startAdornment?: React.ReactNode
  variant?: 'grey' | 'bold' | 'shadowed'
  name?: string
  SelectProps?: Partial<SelectProps>
  label?: string | React.ReactNode
  className?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    selector: {
      border: NotesStyles.selector.border,
      borderRadius: '.2em',
      padding: '.2em 0 .2em .8em',

      '& div': {
        backgroundColor: NotesStyles.selector.bg,
        borderBottom: NotesStyles.selector.borderInner,
      },

      '&:active, &:after, &:before': {
        borderBottom: NotesStyles.selector.borderInner,
      },
    },
    grey: {
      color: '#78849a',
    },
    outlined: {
      border: '1px solid #7a8498',
    },
    shadowed: {
      borderBottom: '2px solid #e4e8f0',
      boxShadow: '0px 5px 5px -1px rgba(0, 0, 0, .05)',
      color: styles.palette.shadowedSelect.color,
    },
    select: {
      '& span': {
        display: 'none',
      },
    },
    listMenu: {
      boxShadow: NotesStyles.shadow2,
      fontSize: NotesStyles.fontSize,

      '& ul': {
        '& li': {
          '&:active': {
            backgroundColor: NotesStyles.colorSelected,
          },
          '&:hover': {
            backgroundColor: NotesStyles.colorHover,
          },
        },
      },
    },
    menuItem: {
      justifyContent: 'space-between',
    },
    menuItemSelected: {
      backgroundColor: NotesStyles.colorSelected,
    },
    label: {
      textTransform: 'uppercase',
      color: '#51648a',
      marginBottom: '1rem',
    },
  })
)

const StyledSelect: React.FC<Props> = ({
  value,
  onChange,
  items,
  outlined = false,
  startAdornment,
  variant = 'bold',
  name = '',
  SelectProps = {},
  label,
  className,
}) => {
  const classes = useStyles()

  return (
    <FormGroup>
      {label && <FormLabel className={classes.label}>{label}</FormLabel>}
      <Select
        onChange={onChange}
        value={value}
        fullWidth
        name={name}
        classes={{
          select: classes.select,
        }}
        className={classNames(
          {
            [classes.selector]: true,
            [classes.outlined]: outlined,
            [classes.grey]: variant === 'grey',
            [classes.shadowed]: variant === 'shadowed',
          },
          className
        )}
        MenuProps={{
          classes: {
            paper: classes.listMenu,
          },
        }}
        startAdornment={startAdornment}
        IconComponent={ExpandMoreIcon}
        {...SelectProps}
      >
        {items.map(({ value, label, count = -1 }) => (
          <MenuItem
            value={value}
            className={classes.menuItem}
            classes={{
              selected: classes.menuItemSelected,
            }}
            key={value}
          >
            {label}
            {count > -1 && <span>({count})</span>}
          </MenuItem>
        ))}
      </Select>
    </FormGroup>
  )
}

export default StyledSelect
