import {
  EventsState,
  EventTypes,
  FirstDraftSubmittedEvent,
  OrderAdHocPayCreatedEvent,
  OrderBonusCreatedEvent,
  OrderClosedEvent,
  OrderItemAddedDeletedEvent,
  OrderRefundedEvent,
  OrdersPaidEvent,
  RevisionDraftSubmittedEvent,
  UserEventStreamEvent,
  VulcanBeautifiedEvent,
} from '../store/events/types'
import { filterHashMap } from '../utils/selectors'
import { LoadedLoadingErrorState } from '../utils/state'
import i18n from '../i18n'
import { getGhostID } from '../utils/link'
import { getClientUrl } from '../utils/consts'
import { PaginationState } from '../utils/pagination'
import { formatCurrency } from '../utils/formatting'
import { HashMap } from '../utils/HashMap'

export const getClientLink = (clientID: number) => {
  const ghostID = getGhostID()
  return [getClientUrl(clientID, ''), !!ghostID ? `?ghost=${ghostID}` : ''].join('')
}

// ordered by page, asc and created_at desc
export const getOrderedUserEvents = (state: EventsState): VulcanBeautifiedEvent[] => {
  let events: VulcanBeautifiedEvent[] = []
  Object.entries(state.pagination.pages).forEach(([, eventIDs]) => {
    const eventsPage = Object.values(filterHashMap(state.events, ev => eventIDs.includes(ev.aramis_event.id)))
    const sortedEvents = eventsPage.sort(sortByCreatedDateDesc)
    // @ts-ignore
    events = events.concat(sortedEvents.map(beautifyVulcanEvent).filter(ev => ev != null))
  })
  return events
}

export const getEventsMeta = (state: EventsState): LoadedLoadingErrorState => state.meta
export const getEventsPaging = (state: EventsState): PaginationState => state.pagination

const sortByCreatedDateDesc = (e1: UserEventStreamEvent, e2: UserEventStreamEvent) => {
  return new Date(e1.aramis_event.created_at) < new Date(e2.aramis_event.created_at) ? 1 : -1
}

// Returns formatted event and null if the input event is malformed and we don't know how to display it
const beautifyVulcanEvent = (userEvent: UserEventStreamEvent): VulcanBeautifiedEvent | null => {
  switch (userEvent.aramis_event.event) {
    case EventTypes.FirstDraftSubmitted:
      return beautifyFirstDraftSubmitted(userEvent.aramis_event as FirstDraftSubmittedEvent, userEvent.client_name)
    case EventTypes.RevisionDraftSubmitted:
      return beautifyRevisionDraftSubmitted(
        userEvent.aramis_event as RevisionDraftSubmittedEvent,
        userEvent.client_name
      )
    case EventTypes.OrderClosed:
      return beautifyOrderClosed(userEvent.aramis_event as OrderClosedEvent, userEvent.client_name)
    case EventTypes.OrderRefunded:
      return beautifyOrderRefunded(userEvent.aramis_event as OrderRefundedEvent, userEvent.client_name)
    case EventTypes.OrderBonusPayCreated:
      return beautifyOrderBonusCreated(userEvent.aramis_event as OrderBonusCreatedEvent, userEvent.client_name)
    case EventTypes.OrderAdHocPayCreated:
      return beautifyOrderAdHocPayCreated(userEvent.aramis_event as OrderAdHocPayCreatedEvent, userEvent.client_name)
    case EventTypes.OrdersPaid:
      return beautifyOrdersPaid(userEvent.aramis_event as OrdersPaidEvent)
    case EventTypes.OrderItemAdded:
    case EventTypes.OrderItemDeleted:
      return beautifyOrderItemAddedDeleted(userEvent.aramis_event as OrderItemAddedDeletedEvent, userEvent.client_name)
    case EventTypes.OrderCanceled:
      return {
        client_id: (userEvent.aramis_event.variables as HashMap<number>).client_id,
        text: i18n.t('events__order_canceled__wording', {
          client_name: userEvent.client_name,
          client_id: (userEvent.aramis_event.variables as HashMap<number>).client_id,
          client_link: getClientLink((userEvent.aramis_event.variables as HashMap<number>).client_id),
          interpolation: { escapeValue: false },
        }),
        created_at: userEvent.aramis_event.created_at,
        id: userEvent.aramis_event.id,
      }
    case EventTypes.ExpertEarnedPerformanceIncentive:
      return {
        client_id: (userEvent.aramis_event.variables as HashMap<number>).client_id,
        text: i18n.t('events__earned_performance_incentive__wording', {
          client_name: userEvent.client_name,
          client_id: (userEvent.aramis_event.variables as HashMap<number>).client_id,
          client_link: getClientLink((userEvent.aramis_event.variables as HashMap<number>).client_id),
          interpolation: { escapeValue: false },
        }),
        created_at: userEvent.aramis_event.created_at,
        id: userEvent.aramis_event.id,
      }
    case EventTypes.UserOrderReassigned:
      return {
        client_id: (userEvent.aramis_event.variables as HashMap<number>).client_id,
        text: i18n.t('events__order_reassigned__wording', {
          client_name: userEvent.client_name,
          client_id: (userEvent.aramis_event.variables as HashMap<number>).client_id,
          client_link: getClientLink((userEvent.aramis_event.variables as HashMap<number>).client_id),
          interpolation: { escapeValue: false },
        }),
        created_at: userEvent.aramis_event.created_at,
        id: userEvent.aramis_event.id,
      }
    default:
      return {
        client_id: (userEvent.aramis_event.variables as HashMap<number>).client_id,
        text: i18n.t('events__default__wording', {
          client_name: userEvent.client_name,
          client_id: (userEvent.aramis_event.variables as HashMap<number>).client_id,
          client_link: getClientLink((userEvent.aramis_event.variables as HashMap<number>).client_id),
          interpolation: { escapeValue: false },
          event: userEvent.aramis_event.event,
        }),
        created_at: userEvent.aramis_event.created_at,
        id: userEvent.aramis_event.id,
      }
  }
}

const beautifyFirstDraftSubmitted = (
  ev: FirstDraftSubmittedEvent,
  clientName: string | null
): VulcanBeautifiedEvent | null => {
  if (!ev.variables.doc_keys || !clientName) {
    return null
  }
  let text = ev.event.toString()
  if (ev.variables.doc_keys.length === 1) {
    text = `<b>${i18n.t(`doc_key__${ev.variables.doc_keys[0]}`)} </b> ${i18n.t('events__firstDraft__wording_single', {
      client_name: clientName,
      client_id: ev.variables.client_id,
      client_link: getClientLink(ev.variables.client_id),
      interpolation: { escapeValue: false },
    })}`
  } else {
    const docNames = ev.variables.doc_keys.map(key => i18n.t(`doc_key__${key}`))
    text = i18n.t('events__firstDraft__wording_multiple', {
      doc_keys: docNames.join(', '),
      client_name: clientName,
      client_id: ev.variables.client_id,
      client_link: getClientLink(ev.variables.client_id),
      interpolation: { escapeValue: false },
    })
  }

  return {
    id: ev.id,
    client_id: ev.variables.client_id,
    created_at: ev.created_at,
    text: text,
  }
}

const beautifyRevisionDraftSubmitted = (
  ev: RevisionDraftSubmittedEvent,
  clientName: string | null
): VulcanBeautifiedEvent | null => {
  if (!ev.variables.docs || !clientName) {
    return null
  }
  const docDetails = ev.variables.docs
    .filter(doc => doc.doc_key && doc.revision)
    .map(docMeta => `${i18n.t(`doc_key__${docMeta.doc_key}`)} revision ${docMeta.revision}`)

  if (docDetails.length === 0) {
    return null
  }
  const text = i18n.t('events__revision__wording', {
    docs: docDetails.join(', '),
    client_name: clientName,
    client_id: ev.variables.client_id,
    client_link: getClientLink(ev.variables.client_id),
    interpolation: { escapeValue: false },
  })

  return {
    id: ev.id,
    client_id: ev.variables.client_id,
    created_at: ev.created_at,
    text: text,
  }
}

const beautifyOrderClosed = (ev: OrderClosedEvent, clientName: string | null): VulcanBeautifiedEvent | null => {
  if (!clientName) {
    return null
  }
  const translationKey =
    ev.variables.initiator && ev.variables.initiator === 'client'
      ? 'events__order_closed_client__wording'
      : 'events__order_closed__wording'

  return {
    id: ev.id,
    client_id: ev.variables.client_id,
    created_at: ev.created_at,
    text: i18n.t(translationKey, {
      client_name: clientName,
      client_id: ev.variables.client_id,
      client_link: getClientLink(ev.variables.client_id),
      interpolation: { escapeValue: false },
    }),
  }
}

const beautifyOrderRefunded = (ev: OrderRefundedEvent, clientName: string | null): VulcanBeautifiedEvent | null => {
  if (!clientName) {
    return null
  }

  const translationKey = ev.variables.pay_original_writer
    ? 'events__order_refunded_paid__wording'
    : 'events__order_refunded_not_paid__wording'

  return {
    id: ev.id,
    client_id: ev.variables.client_id,
    created_at: ev.created_at,
    text: i18n.t(translationKey, {
      client_name: clientName,
      client_id: ev.variables.client_id,
      client_link: getClientLink(ev.variables.client_id),
      interpolation: { escapeValue: false },
    }),
  }
}

const beautifyOrderBonusCreated = (
  ev: OrderBonusCreatedEvent,
  clientName: string | null
): VulcanBeautifiedEvent | null => {
  if (!clientName) {
    return null
  }

  return {
    id: ev.id,
    client_id: ev.variables.client_id,
    created_at: ev.created_at,
    text: i18n.t('events__order_bonus_created__wording', {
      client_name: clientName,
      client_id: ev.variables.client_id,
      client_link: getClientLink(ev.variables.client_id),
      rating: ev.variables.rating,
      interpolation: { escapeValue: false },
    }),
  }
}

const beautifyOrderAdHocPayCreated = (ev: OrderAdHocPayCreatedEvent, clientName: string | null) => {
  if (!clientName) {
    return null
  }

  return {
    id: ev.id,
    client_id: ev.variables.client_id,
    created_at: ev.created_at,
    text: i18n.t('events__order_adhoc_added__wording', {
      client_name: clientName,
      client_id: ev.variables.client_id,
      client_link: getClientLink(ev.variables.client_id),
      amount: ev.variables.amount,
      interpolation: { escapeValue: false },
    }),
  }
}

const beautifyOrdersPaid = (ev: OrdersPaidEvent) => {
  return {
    id: ev.id,
    client_id: null,
    created_at: ev.created_at,
    text: i18n.t('events__orders_paid__wording', {
      payment: formatCurrency(ev.variables.payment.amount, ev.variables.payment.currency),
    }),
  }
}

const beautifyOrderItemAddedDeleted = (ev: OrderItemAddedDeletedEvent, clientName: string | null) => {
  if (!clientName) {
    return null
  }

  const key =
    ev.event === EventTypes.OrderItemDeleted
      ? 'events__order_item_deleted__wording'
      : 'events__order_item_added__wording'

  if (!Array.isArray(ev.variables.items)) {
    return null
  }

  return {
    id: ev.id,
    client_id: ev.variables.client_id,
    created_at: ev.created_at,
    text: i18n.t(key, {
      items: ev.variables.items.join(', '),
      client_name: clientName,
      client_id: ev.variables.client_id,
      client_link: getClientLink(ev.variables.client_id),
      interpolation: { escapeValue: false },
    }),
  }
}
