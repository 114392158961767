import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import { NoteContexts, Note, CreateNotePayload, UpdateNotePayload } from './types'
import { FetchOrdersSuccess, FetchOrderSuccess } from '../orders/actions'
import { FetchClientOrdersSuccess, FetchUserAggregatedOrdersSuccess } from '../clientOrderAggregate/actions'
import { userId } from '../user/reducers'

export enum NotesActionTypes {
  FETCH_NOTES = 'FETCH_NOTES',
  FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS',
  FETCH_NOTES_FAIL = 'FETCH_NOTES_FAIL',
  CREATE_NOTE = 'CREATE_NOTE',
  CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS',
  CREATE_NOTE_FAIL = 'CREATE_NOTE_FAIL',
  UPDATE_NOTE = 'UPDATE_NOTE',
  UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS',
  UPDATE_NOTE_FAIL = 'UPDATE_NOTE_FAIL',
  DELETE_NOTE = 'DELETE_NOTE',
  DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS',
  DELETE_NOTE_FAIL = 'DELETE_NOTE_FAIL',
  FETCH_USER_NOTES = 'FETCH_USER_NOTES',
  FETCH_USER_NOTES_SUCCESS = 'FETCH_USER_NOTES_SUCCESS',
  FETCH_USER_NOTES_FAIL = 'FETCH_USER_NOTES_FAIL',
}

export interface FetchNotes extends AxiosMiddlewareActionCreator {
  type: typeof NotesActionTypes.FETCH_NOTES
  context: NoteContexts
  contextID: number
}

export interface FetchNotesSuccess extends AxiosMiddlewareActionSuccess<Note[], FetchNotes> {
  type: typeof NotesActionTypes.FETCH_NOTES_SUCCESS
}

export interface FetchNotesFail extends AxiosMiddlewareActionFail<FetchNotes> {
  type: typeof NotesActionTypes.FETCH_NOTES_FAIL
}

export function fetchNotes(context: NoteContexts, contextID: number): FetchNotes {
  return {
    type: NotesActionTypes.FETCH_NOTES,
    context,
    contextID,
    payload: {
      request: {
        url: `/v1/notes/${context}/${contextID}`,
      },
    },
  }
}

export interface CreateNote extends AxiosMiddlewareActionCreator {
  type: typeof NotesActionTypes.CREATE_NOTE
  context: NoteContexts
  contextID: number
}

export interface CreateNoteSuccess extends AxiosMiddlewareActionSuccess<Note, CreateNote> {
  type: typeof NotesActionTypes.CREATE_NOTE_SUCCESS
}

export interface CreateNoteFail extends AxiosMiddlewareActionFail<CreateNote> {
  type: typeof NotesActionTypes.CREATE_NOTE_FAIL
}

export interface CreateNoteFunctionSignature extends CreateNotePayload {
  context: NoteContexts
  contextID: number
}

export function createNote({ context, contextID, ...data }: CreateNoteFunctionSignature): CreateNote {
  return {
    type: NotesActionTypes.CREATE_NOTE,
    context,
    contextID,
    payload: {
      request: {
        url: `/v1/notes/context/${context}/${contextID}`,
        method: 'POST',
        data,
      },
    },
  }
}

export interface DeleteNote extends AxiosMiddlewareActionCreator {
  type: typeof NotesActionTypes.DELETE_NOTE
  context: NoteContexts
  contextID: number
  id: number
}

export interface DeleteNoteSuccess extends AxiosMiddlewareActionSuccess<Note, DeleteNote> {
  type: typeof NotesActionTypes.DELETE_NOTE_SUCCESS
}

export interface DeleteNoteFail extends AxiosMiddlewareActionFail<DeleteNote> {
  type: typeof NotesActionTypes.DELETE_NOTE_FAIL
}

export function deleteNote(context: NoteContexts, contextID: number, id: number): DeleteNote {
  return {
    type: NotesActionTypes.DELETE_NOTE,
    context,
    contextID,
    id,
    payload: {
      request: {
        url: `/v1/notes/context/${context}/${contextID}/${id}`,
        method: 'DELETE',
      },
    },
  }
}

interface UpdateNote extends AxiosMiddlewareActionCreator {
  type: typeof NotesActionTypes.UPDATE_NOTE
  context: NoteContexts
  contextID: number
  id: number
}

interface UpdateNoteSuccess extends AxiosMiddlewareActionSuccess<Note, UpdateNote> {
  type: typeof NotesActionTypes.UPDATE_NOTE_SUCCESS
}

interface UpdateNoteFail extends AxiosMiddlewareActionFail<UpdateNote> {
  type: typeof NotesActionTypes.UPDATE_NOTE_FAIL
}

export function updateNote(context: NoteContexts, contextID: number, id: number, data: UpdateNotePayload): UpdateNote {
  return {
    type: NotesActionTypes.UPDATE_NOTE,
    context,
    contextID,
    id,
    payload: {
      request: {
        url: `/v1/notes/context/${context}/${contextID}/${id}`,
        method: 'PATCH',
        data,
      },
    },
  }
}

interface FetchUserNotes extends AxiosMiddlewareActionCreator {
  type: typeof NotesActionTypes.FETCH_USER_NOTES
  userID: userId
}

interface FetchUserNotesSuccess extends AxiosMiddlewareActionSuccess<Note[], FetchUserNotes> {
  type: typeof NotesActionTypes.FETCH_USER_NOTES_SUCCESS
}

interface FetchUserNotesFail extends AxiosMiddlewareActionFail<FetchUserNotes> {
  type: typeof NotesActionTypes.FETCH_USER_NOTES_FAIL
}

export function fetchUserNotes(userID: userId = 'me'): FetchUserNotes {
  return {
    type: NotesActionTypes.FETCH_USER_NOTES,
    userID,
    payload: {
      request: {
        url: `/v1/notes/user/${userID}`,
      },
    },
  }
}

export type NotesActionType =
  | FetchNotes
  | FetchNotesSuccess
  | FetchNotesFail
  | CreateNote
  | CreateNoteSuccess
  | CreateNoteFail
  | DeleteNote
  | DeleteNoteSuccess
  | DeleteNoteFail
  | UpdateNote
  | UpdateNoteSuccess
  | UpdateNoteFail
  | FetchOrdersSuccess
  | FetchOrderSuccess
  | FetchClientOrdersSuccess
  | FetchUserAggregatedOrdersSuccess
  | FetchUserNotes
  | FetchUserNotesSuccess
  | FetchUserNotesFail
