import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useEffectOnce, useLocation } from 'react-use'

import LoginPage from './LoginPage'
import LogoutPage from './LogoutPage'
import CallbackPage from './CallbackPage'
import AuthIframePage from './AuthIframePage'
import { Routes } from '../../utils/consts'
import IntercomContainer from '../common/IntercomContainer'
import { fetchConfigAndConfigure } from '../../store/config/actions'
import { getGroupsFromJWT } from '../../utils/auth'
import { Groups } from '../../store/user/reducers'

export const AuthPages: React.FC = () => {
  const dispatch = useDispatch()
  const intercom = <IntercomContainer />
  const { pathname } = useLocation()

  useEffectOnce(() => {
    dispatch(fetchConfigAndConfigure())
  })

  useEffect(() => {
    if (getGroupsFromJWT().includes(Groups.Nexxt) && !pathname?.includes('auth')) {
      // Hardcoding this because IDGAF
      window.location.href = 'https://oms.talentinc.com'
    }
  }, [pathname])

  return (
    <>
      <Route
        path={Routes.Login}
        component={() => (
          <>
            <LoginPage />
            {intercom}
          </>
        )}
      />
      <Route
        path={Routes.Logout}
        component={() => (
          <>
            <LogoutPage />
            {intercom}
          </>
        )}
      />
      <Route
        path={Routes.Auth0Callback}
        component={() => (
          <>
            <CallbackPage />
            {intercom}
          </>
        )}
      />
      <Route path={Routes.AuthIframe} component={AuthIframePage} />
    </>
  )
}

export default AuthPages
