import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'

export enum AIDraftActionTypes {
  FETCH_IS_AI_DRAFT_EXISTS = 'FETCH_IS_AI_DRAFT_EXISTS',
  FETCH_IS_AI_DRAFT_EXISTS_SUCCESS = 'FETCH_IS_AI_DRAFT_EXISTS_SUCCESS',
  FETCH_IS_AI_DRAFT_EXISTS_FAIL = 'FETCH_IS_AI_DRAFT_EXISTS_FAIL',

  FETCH_JOB_ROLES = 'FETCH_JOB_ROLES',
  FETCH_JOB_ROLES_SUCCESS = 'FETCH_JOB_ROLES_SUCCESS',
  FETCH_JOB_ROLES_FAIL = 'FETCH_JOB_ROLES_FAIL',

  GENERATE_AI_DRAFT = 'GENERATE_AI_DRAFT',
  GENERATE_AI_DRAFT_SUCCESS = 'GENERATE_AI_DRAFT_SUCCESS',
  GENERATE_AI_DRAFT_FAIL = 'GENERATE_AI_DRAFT_FAIL',

  FETCH_IS_AI_FEEDBACK_EXISTS = 'FETCH_IS_AI_FEEDBACK_EXISTS',
  FETCH_IS_AI_FEEDBACK_EXISTS_SUCCESS = 'FETCH_IS_AI_FEEDBACK_EXISTS_SUCCESS',
  FETCH_IS_AI_FEEDBACK_EXISTS_FAIL = 'FETCH_IS_AI_DRAFT_EXISTS_FAIL',

  SUBMIT_OR_SKIP_AI_FEEDBACK = 'SUBMIT_OR_SKIP_AI_FEEDBACK',
  SUBMIT_OR_SKIP_AI_FEEDBACK_SUCCESS = 'SUBMIT_OR_SKIP_AI_FEEDBACK_SUCCESS',
  SUBMIT_OR_SKIP_AI_FEEDBACK_FAIL = 'SUBMIT_OR_SKIP_AI_FEEDBACK_FAIL',

  FETCH_AI_DRAFT_MAGIC_LINK = 'FETCH_AI_DRAFT_MAGIC_LINK',
  FETCH_AI_DRAFT_MAGIC_LINK_SUCCESS = 'FETCH_AI_DRAFT_MAGIC_LINK_SUCCESS',
  FETCH_AI_DRAFT_MAGIC_LINK_FAIL = 'FETCH_AI_DRAFT_MAGIC_LINK_FAIL',
}

export interface FetchIsAIDraftExists extends AxiosMiddlewareActionCreator {
  clientID: number
  type: typeof AIDraftActionTypes.FETCH_IS_AI_DRAFT_EXISTS
}

export interface FetchIsAIDraftExistsSuccess
  extends AxiosMiddlewareActionSuccess<{ draft_exists: boolean }, FetchIsAIDraftExists> {
  type: typeof AIDraftActionTypes.FETCH_IS_AI_DRAFT_EXISTS_SUCCESS
}

export interface FetchIsAIDraftExistsFail extends AxiosMiddlewareActionFail<FetchIsAIDraftExists> {
  type: typeof AIDraftActionTypes.FETCH_IS_AI_DRAFT_EXISTS_FAIL
}

export function fetchIsAIDraftExists(clientID: number): FetchIsAIDraftExists {
  return {
    type: AIDraftActionTypes.FETCH_IS_AI_DRAFT_EXISTS,
    clientID: clientID,
    payload: {
      request: {
        url: `/v1/client-data/client/${clientID}/generate-draft`,
      },
    },
  }
}

export interface FetchJobRoles extends AxiosMiddlewareActionCreator {
  type: typeof AIDraftActionTypes.FETCH_JOB_ROLES
}

export interface FetchJobRolesSuccess extends AxiosMiddlewareActionSuccess<{ job_roles: string[] }, FetchJobRoles> {
  type: typeof AIDraftActionTypes.FETCH_JOB_ROLES_SUCCESS
}

export interface FetchJobRolesFail extends AxiosMiddlewareActionFail<FetchJobRoles> {
  type: typeof AIDraftActionTypes.FETCH_JOB_ROLES_FAIL
}

export function fetchJobRoles(searchParam = ''): FetchJobRoles {
  return {
    type: AIDraftActionTypes.FETCH_JOB_ROLES,
    payload: {
      request: {
        url: `/v1/client-data/job-roles?searchParam=${searchParam}`,
      },
    },
  }
}

export interface GenerateAIDraft extends AxiosMiddlewareActionCreator {
  clientID: number
  type: typeof AIDraftActionTypes.GENERATE_AI_DRAFT
}

export interface GenerateAIDraftSuccess
  extends AxiosMiddlewareActionSuccess<{ draft_exists: boolean }, GenerateAIDraft> {
  type: typeof AIDraftActionTypes.GENERATE_AI_DRAFT_SUCCESS
}

export interface GenerateAIDraftFail extends AxiosMiddlewareActionFail<GenerateAIDraft> {
  type: typeof AIDraftActionTypes.GENERATE_AI_DRAFT_FAIL
}

export function generateAIDraft(clientID: number, career_change: string): GenerateAIDraft {
  return {
    type: AIDraftActionTypes.GENERATE_AI_DRAFT,
    clientID,
    payload: {
      request: {
        url: `/v1/client-data/ai-resume`,
        method: 'POST',
        data: {
          client_ids: [clientID],
          career_change,
        },
      },
    },
  }
}

export interface FetchIsAIFeedbackExists extends AxiosMiddlewareActionCreator {
  clientID: number
  type: typeof AIDraftActionTypes.FETCH_IS_AI_FEEDBACK_EXISTS
}

export interface FetchIsAIFeedbackExistsSuccess
  extends AxiosMiddlewareActionSuccess<{ submitted_or_skipped: boolean }, FetchIsAIFeedbackExists> {
  type: typeof AIDraftActionTypes.FETCH_IS_AI_FEEDBACK_EXISTS_SUCCESS
}

export interface FetchIsAIFeedbackExistsFail extends AxiosMiddlewareActionFail<FetchIsAIFeedbackExists> {
  type: typeof AIDraftActionTypes.FETCH_IS_AI_FEEDBACK_EXISTS_FAIL
}

export function fetchIsAIFeedbackExists(clientID: number): FetchIsAIFeedbackExists {
  return {
    type: AIDraftActionTypes.FETCH_IS_AI_FEEDBACK_EXISTS,
    clientID: clientID,
    payload: {
      request: {
        url: `/v1/client-data/ai-resume/${clientID}/ai-feedback`,
      },
    },
  }
}

export interface SubmitOrSkipAIFeedback extends AxiosMiddlewareActionCreator {
  clientID: number
  type: typeof AIDraftActionTypes.SUBMIT_OR_SKIP_AI_FEEDBACK
}

export interface SubmitOrSkipAIFeedbackSuccess
  extends AxiosMiddlewareActionSuccess<{ submitted_or_skipped: boolean }, SubmitOrSkipAIFeedback> {
  type: typeof AIDraftActionTypes.SUBMIT_OR_SKIP_AI_FEEDBACK_SUCCESS
}

export interface SubmitOrSkipAIFeedbackFail extends AxiosMiddlewareActionFail<SubmitOrSkipAIFeedback> {
  type: typeof AIDraftActionTypes.SUBMIT_OR_SKIP_AI_FEEDBACK_FAIL
}

export function submitOrSkipAIFeedback(clientID: number, skip: boolean): SubmitOrSkipAIFeedback {
  return {
    type: AIDraftActionTypes.SUBMIT_OR_SKIP_AI_FEEDBACK,
    clientID,
    payload: {
      request: {
        url: `/v1/client-data/ai-resume/${clientID}/ai-feedback`,
        method: 'POST',
        data: { skip },
      },
    },
  }
}

export interface FetchAIDraftMagicLink extends AxiosMiddlewareActionCreator {
  clientID: number
  type: typeof AIDraftActionTypes.FETCH_AI_DRAFT_MAGIC_LINK
}

export interface FetchAIDraftMagicLinkSuccess
  extends AxiosMiddlewareActionSuccess<{ link: string }, FetchAIDraftMagicLink> {
  type: typeof AIDraftActionTypes.FETCH_AI_DRAFT_MAGIC_LINK_SUCCESS
}

export interface FetchAIDraftMagicLinkFail extends AxiosMiddlewareActionFail<FetchAIDraftMagicLink> {
  type: typeof AIDraftActionTypes.FETCH_AI_DRAFT_MAGIC_LINK_FAIL
}

export function fetchAIDraftMagicLink(clientID: number): FetchAIDraftMagicLink {
  return {
    type: AIDraftActionTypes.FETCH_AI_DRAFT_MAGIC_LINK,
    clientID: clientID,
    payload: {
      request: {
        url: `/v1/client-data/ai-resume/${clientID}/rio-user-auth`,
      },
    },
  }
}

export type AIDraftActionType =
  | FetchIsAIDraftExists
  | FetchIsAIDraftExistsSuccess
  | FetchIsAIDraftExistsFail
  | FetchJobRoles
  | FetchJobRolesSuccess
  | FetchJobRolesFail
  | GenerateAIDraft
  | GenerateAIDraftSuccess
  | GenerateAIDraftFail
  | FetchIsAIFeedbackExists
  | FetchIsAIFeedbackExistsSuccess
  | FetchIsAIFeedbackExistsFail
  | SubmitOrSkipAIFeedback
  | SubmitOrSkipAIFeedbackSuccess
  | SubmitOrSkipAIFeedbackFail
  | FetchAIDraftMagicLink
  | FetchAIDraftMagicLinkSuccess
  | FetchAIDraftMagicLinkFail
