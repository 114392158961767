import React from 'react'

import styles from '../../../styles'

const SvgTopInterview = props => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="currentColor" {...props}>
    <text fontFamily={styles.fonts.primary.family} fontSize={18} fontWeight="bold">
      <tspan x={12} y={28}>
        {'Ti'}
      </tspan>
    </text>
  </svg>
)

export default SvgTopInterview
