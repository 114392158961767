import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Radio, { RadioProps } from '@material-ui/core/Radio'

import styles from '../../styles'

export default withStyles({
  root: {
    color: styles.palette.contrast.color,

    '&$checked': {
      color: styles.palette.contrast.color,
    },
  },
})((props: RadioProps) => <Radio color="default" {...props} />)
