import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import classNames from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { GhostLink } from '../../utils/link'

export interface FloatingNudgeProps {
  text: string
  onClick: () => void
  onManualDismiss?: () => void
  onAutoDismiss?: () => void
  visibleFor?: number | null
  link?: string | undefined | null
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
  nudge: {
    height: '2.75rem',
    borderRadius: '8px',
    boxShadow: '0 2px 14px 0 rgba(0, 0, 0, 0.12)',
    border: 'solid 2px #09143b',
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'black',
    position: 'fixed',
    bottom: '3.35rem',
    zIndex: 100,
    margin: '0 auto',
    cursor: 'pointer',
    padding: '1rem 0.2rem 1rem 1.6rem',
  },
  text: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'black',
  },
  body: {
    display: 'flex',
    overflow: 'hidden',
  },
  close: {
    margin: '0 0 0 1rem',
    '& .icon': {
      fontSize: '1.2rem',
      fill: 'black',
    },
  },
}))

const FloatingNudge: React.FC<FloatingNudgeProps> = ({
  visibleFor,
  onClick,
  onManualDismiss,
  onAutoDismiss,
  text,
  link,
}) => {
  const classes = useStyles()

  let timerID: number | undefined

  if (!!visibleFor && !!onAutoDismiss) {
    timerID = window.setTimeout(() => {
      onAutoDismiss()
    }, visibleFor)
  }

  useEffect(() => {
    return function cleanup() {
      if (!!timerID) {
        window.clearTimeout(timerID)
      }
    }
  })

  return (
    <Box className={classNames([classes.root])}>
      <Box className={classNames([classes.nudge])}>
        <Box className={classes.body} onClick={onClick}>
          {!!link && (
            <GhostLink to={link}>
              <Typography component="span" className={classes.text}>
                {text}
              </Typography>
            </GhostLink>
          )}
          {!link && (
            <Typography component="span" className={classes.text}>
              {text}
            </Typography>
          )}
        </Box>
        {onManualDismiss && (
          <IconButton onClick={onManualDismiss} className={classNames([classes.close])}>
            <CloseIcon className="icon" />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default FloatingNudge
