import React, { useState } from 'react'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import format from 'date-fns/format'

import i18n from '../../i18n'
import { Note } from '../../store/notes/types'
import { getStructuredNotes } from '../../store/notes/selectors'
import NotesStyles from './Styles'
import StyledSelect from '../common/StyledSelect'

enum NoteGroups {
  all = 'all',
  dueSoon = 'due-soon',
  myNotes = 'my-note',
  completed = 'completed',
}

interface Props {
  notes: Note[]
  selectedNote: Note | null
  selectNote: (noteID: number) => void
  addNote: () => void
  canAddNote: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    listItem: {
      cursor: NotesStyles.listItem.cursor,
      fontWeight: NotesStyles.listItem.weight,
      letterSpacing: NotesStyles.listItem.letterSpacing,
      maxWidth: NotesStyles.fullWidth,
      overflow: NotesStyles.listItem.overflow,
      padding: NotesStyles.listItem.padding,
      textOverflow: NotesStyles.listItem.textOverflow,

      '&:hover': {
        backgroundColor: NotesStyles.colorHover,
      },
    },
    listItemAlarm: {
      backgroundColor: NotesStyles.listItem.bgAlarm,
      color: NotesStyles.colorAlarm,
      cursor: NotesStyles.listItem.cursor,
      fontWeight: NotesStyles.listItem.weight,
      letterSpacing: NotesStyles.listItem.letterSpacing,
      maxWidth: NotesStyles.fullWidth,
      overflow: NotesStyles.listItem.overflow,
      padding: NotesStyles.listItem.padding,
      textOverflow: NotesStyles.listItem.textOverflow,
    },
    listItemSelected: {
      backgroundColor: NotesStyles.colorSelected,

      '&:hover': {
        backgroundColor: NotesStyles.colorSelected,
      },
    },
    listItemText: {
      borderBottom: NotesStyles.border,
      marginBottom: '0',
      marginTop: '0',
      overflow: NotesStyles.listItem.overflow,
      padding: NotesStyles.padding,
      paddingBottom: NotesStyles.padding2,
      paddingLeft: '0',
      paddingTop: NotesStyles.padding2,
      textOverflow: NotesStyles.listItem.textOverflow,
    },
    listItemTextPrimary: {
      textOverflow: NotesStyles.listItem.textOverflow,
      whiteSpace: 'nowrap',
    },
    listItemTextSecondary: {
      alignItems: 'center',
      color: 'inherit',
      display: 'flex',
    },
    listItemTextSecondaryIcon: {
      marginRight: '.2em',
    },
    listItemTextSecondaryNoDate: {
      alignItems: 'center',
      color: 'inherit',
      display: 'flex',
      fontSize: '.7em',
    },
    menuItem: {
      justifyContent: 'space-between',
    },
    menuItemSelected: {
      backgroundColor: NotesStyles.colorSelected,
    },
    noteAdd: {
      backgroundColor: 'white',
      boxShadow: '0 2px 5px 0 rgba(129, 139, 158, 0.5)',
      bottom: '1em',
      margin: '0 0 0 15.5em',
      padding: '0',
      position: 'fixed',
      width: 'initial',

      '&:hover': {
        backgroundColor: 'white !important',
        boxShadow: '0 2px 10px 0 rgba(129, 139, 158, 0.5)',
      },
    },
    noteAddButton: {
      minWidth: '0',
      padding: '0',
      width: 'initial',

      '&:hover': {
        backgroundColor: 'white !important',
      },
    },
    noteAddButtonLabel: {
      fontSize: '2.4em',
      lineHeight: '.5em',
      padding: '.3em .2em .3em',
      width: 'initial',
    },
    noteCategory: {
      borderBottom: NotesStyles.border,
      borderTop: NotesStyles.border,
      color: NotesStyles.label.color,
      fontSize: '.8em',
      letterSpacing: '.01em',
      margin: '0',
      padding: '.8em 2em',
    },
    noteCategoryText: {
      fontSize: 'inherit',
      letterSpacing: '0.06em',
      lineHeight: '1.17em',
      textTransform: 'uppercase',
    },
    noteList: {
      marginTop: '4.55em',
      padding: '0',
      overflowY: 'auto',
      width: NotesStyles.fullWidth,
    },
    selectorContainer: {
      backgroundColor: NotesStyles.selector.bg,
      boxShadow: NotesStyles.selector.boxShadow,
      fontWeight: NotesStyles.selector.fontWeight,
      letterSpacing: NotesStyles.selector.letterSpacing,
      marginTop: '-4.55em',
      padding: NotesStyles.selector.padding,
      position: 'fixed',
      width: '14em',
      zIndex: NotesStyles.selector.zIndex,

      '&:hover': {
        backgroundColor: NotesStyles.colorWhiteHover,
      },
    },
    selectOverrides: {
      paddingLeft: 0,
      width: '11.2rem',
    },

    '@media (max-width: 767px)': {
      listItemText: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      noteList: {
        width: NotesStyles.fullWidth,
      },
      selectorContainer: {
        width: NotesStyles.fullWidth,
      },
    },
  })
)

interface NoteListItemProps {
  note: Note
  onClick: () => void
  selected: boolean
  variant: NoteGroups
}

const NoteListItem: React.FC<NoteListItemProps> = ({ note, selected, onClick, variant }) => {
  const classes = useStyles()
  let listItemClass
  const now = new Date()
  let secondaryClass = classes.listItemTextSecondary
  let secondaryText

  switch (variant) {
    case NoteGroups.dueSoon:
      if (note.expiration_date) {
        const dateFormat = i18n.t('notes__dateFormat')

        if (now > new Date(note.expiration_date)) {
          listItemClass = classes.listItemAlarm
          secondaryText = (
            <>
              {' '}
              <AccessAlarmIcon className={classes.listItemTextSecondaryIcon} />{' '}
              {format(new Date(note.expiration_date), dateFormat)}{' '}
            </>
          )
        } else {
          listItemClass = classes.listItem
          secondaryText = (
            <>
              {' '}
              <AccessTimeIcon className={classes.listItemTextSecondaryIcon} />{' '}
              {format(new Date(note.expiration_date), dateFormat)}{' '}
            </>
          )
        }
      }
      break

    case NoteGroups.myNotes:
      {
        const dateFormat = i18n.t('notes__dateFormat')
        listItemClass = classes.listItem
        secondaryClass = classes.listItemTextSecondaryNoDate
        secondaryText = `created on ${format(new Date(note.created_at), dateFormat)}`
      }
      break

    case NoteGroups.completed:
      if (note.completion_date) {
        const dateFormat = i18n.t('notes__dateFormat')
        listItemClass = classes.listItem
        secondaryText = (
          <>
            {' '}
            <CheckIcon className={classes.listItemTextSecondaryIcon} />{' '}
            {format(new Date(note.completion_date), dateFormat)}{' '}
          </>
        )
      }
      break
  }

  return (
    <ListItem
      onClick={onClick}
      selected={selected}
      className={listItemClass}
      classes={{ selected: classes.listItemSelected }}
    >
      <ListItemText
        primary={note.client}
        secondary={secondaryText}
        className={classes.listItemText}
        classes={{ primary: classes.listItemTextPrimary, secondary: secondaryClass }}
      />
    </ListItem>
  )
}

const NotesList: React.FC<Props> = ({ notes, selectedNote, selectNote, addNote, canAddNote }) => {
  const classes = useStyles()
  const [selectedGroup, setSelectedGroup] = useState<NoteGroups>(NoteGroups.all)
  const { dueSoon, notDueSoon, completed } = getStructuredNotes(notes)
  const showDividers = selectedGroup === NoteGroups.all
  const showAllGroups = selectedGroup === NoteGroups.all

  return (
    <List className={classes.noteList}>
      <ListItem className={classes.selectorContainer}>
        <StyledSelect
          onChange={e => setSelectedGroup(e.target.value as NoteGroups)}
          value={selectedGroup}
          variant="bold"
          className={classes.selectOverrides}
          items={[
            {
              label: i18n.t('notes__section__all'),
              value: NoteGroups.all,
              count: notes.length,
            },
            {
              label: i18n.t('notes__section__dueSoon'),
              value: NoteGroups.dueSoon,
              count: dueSoon.length,
            },
            {
              label: i18n.t('notes__section__myNotes'),
              value: NoteGroups.myNotes,
              count: notDueSoon.length,
            },
            {
              label: i18n.t('notes__section__completed'),
              value: NoteGroups.completed,
              count: completed.length,
            },
          ]}
        />
      </ListItem>
      {!selectedNote && canAddNote && (
        <ListItem
          key="new-note"
          selected={!selectedNote}
          className={classes.listItem}
          classes={{ selected: classes.listItemSelected }}
        >
          <ListItemText primary={i18n.t('notes__list__newNote')} />
        </ListItem>
      )}
      {(showAllGroups || selectedGroup === NoteGroups.dueSoon) && dueSoon.length > 0 && (
        <>
          {showDividers && (
            <ListItem key={NoteGroups.dueSoon} className={classes.noteCategory}>
              <ListItemText
                primary={i18n.t('notes__section__dueSoon')}
                classes={{ primary: classes.noteCategoryText }}
              />
            </ListItem>
          )}
          {dueSoon.map(note => (
            <NoteListItem
              key={note.id}
              onClick={() => selectNote(note.id)}
              selected={!!selectedNote && note.id === selectedNote.id}
              note={note}
              variant={NoteGroups.dueSoon}
            />
          ))}
        </>
      )}
      {(showAllGroups || selectedGroup === NoteGroups.myNotes) && notDueSoon.length > 0 && (
        <>
          {showDividers && (
            <ListItem key={NoteGroups.myNotes} className={classes.noteCategory}>
              <ListItemText
                primary={i18n.t('notes__section__myNotes')}
                classes={{ primary: classes.noteCategoryText }}
              />
            </ListItem>
          )}
          {notDueSoon.map(note => (
            <NoteListItem
              key={note.id}
              onClick={() => selectNote(note.id)}
              selected={!!selectedNote && note.id === selectedNote.id}
              note={note}
              variant={NoteGroups.myNotes}
            />
          ))}
        </>
      )}
      {(showAllGroups || selectedGroup === NoteGroups.completed) && completed.length > 0 && (
        <>
          {showDividers && (
            <ListItem key={NoteGroups.completed} className={classes.noteCategory}>
              <ListItemText
                primary={i18n.t('notes__section__completed')}
                classes={{ primary: classes.noteCategoryText }}
              />
            </ListItem>
          )}
          {completed.map(note => (
            <NoteListItem
              key={note.id}
              onClick={() => selectNote(note.id)}
              selected={!!selectedNote && note.id === selectedNote.id}
              note={note}
              variant={NoteGroups.completed}
            />
          ))}
        </>
      )}
      {canAddNote && (
        <ListItem className={classes.noteAdd}>
          <Button onClick={addNote} className={classes.noteAddButton} classes={{ label: classes.noteAddButtonLabel }}>
            +
          </Button>
        </ListItem>
      )}
    </List>
  )
}

// @ts-ignore
NotesList.whyDidYouRender = true

export default NotesList
