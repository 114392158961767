import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import isEqual from 'lodash/isEqual'

import CardWrapper from './CardWrapper'
import DashboardStyles from './Styles'

export interface StatCardProps {
  title: string
  primary: string
  subtitle: string
}

const useStyles = makeStyles(() =>
  createStyles({
    body2: {
      color: DashboardStyles.smallText.color,
      fontSize: DashboardStyles.smallText.size,
      marginTop: '1em',
    },
    h2: {
      fontSize: DashboardStyles.largeText.size,
      fontWeight: DashboardStyles.largeText.weight,
    },
  })
)

export const StatCard: React.FC<StatCardProps> = props => {
  const classes = useStyles()

  return (
    <CardWrapper cardClass="inner" title={props.title}>
      <Typography className={classes.h2} display={'block'} variant="h2" area-label="last_pay_amount">
        {props.primary}
      </Typography>
      <Typography className={classes.body2} variant="body2">
        {props.subtitle}
      </Typography>
    </CardWrapper>
  )
}

const memoizedStatCard = React.memo(StatCard, isEqual)
// @ts-ignore
memoizedStatCard.whyDidYouRender = true

export default memoizedStatCard
