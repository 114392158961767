import React, { useState } from 'react'
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import InputBase from '@material-ui/core/InputBase'
import classNames from 'classnames'
import MenuItem from '@material-ui/core/MenuItem'
import ButtonBase from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import ReactHtmlParser from 'react-html-parser'

import i18n from '../../i18n'
import Button from '../common/Button'
import { SnippetList, Snippet } from '../../store/templates/types'
import { snippetsByTypeSelector, allSnippetTypesSelector } from '../../selectors/templates'
import { renderEmailTemplate, HandlebarsContextType } from '../../utils/handlebars'
import StrategyIcon from '../../assets/svgs/strategy.svg'

const BootstrapInput = withStyles(theme => ({
  input: {
    width: '11.25rem',
    height: '2.5rem',
    boxSizing: 'border-box',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #d5d9e0',
    borderRadius: '.25rem',
    fontSize: '.9375rem',
    lineHeight: '1.25rem',
    fontWeight: 600,
    color: '#6f737c',
    padding: '.625rem 1.8rem .625rem .85rem',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: '.25rem',
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      cursor: 'pointer',
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column',
      },
      '& .icon-img': {
        display: 'flex',
        alignItems: 'center',
        height: '1.5rem',
        color: '#bcc9d6',
        '@media (max-width: 540px)': { display: 'none' },
      },
      '& .icon-text': {
        marginTop: '.1rem',
        fontSize: '.5625rem',
        color: '#3f3f3f',
      },
    },
    highlighted: {
      backgroundColor: '#80bdff',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    strategy: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      alignItems: 'center',
    },
    container: {
      position: 'absolute',
      width: '22.8125rem',
      borderRadius: '0.3125rem',
      boxShadow: '0 1.5px 0.5px 0 rgba(0, 0, 0, 0.5)',
      border: 'solid 0.9px #979797',
      background: '#fff',
      transform: 'translateY(-100%)',
      top: '-.5rem',
      left: '0',
      zIndex: 2,
    },
    title: {
      marginBottom: '.7rem',
    },
    close: {
      position: 'absolute',
      selfAlign: 'flex-end',
      right: '.7rem',
      top: '.5rem',
      fontSize: '1.3rem',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    head: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1.125rem 0',
      borderBottom: '1px solid #979797',
      textAlign: 'center',
    },
    list: { listStyle: 'none', maxHeight: '20.875rem', overflowY: 'scroll' },
    listItem: {
      padding: '0.75rem 1.9375rem 0.75rem 1rem ',
      borderBottom: '1px solid #979797',
    },
    footer: {
      padding: '0.5rem 1rem',
      borderTop: '1px solid #979797',
    },
    type: {},
    body: {},
    active: {
      backgroundColor: 'rgba(0, 153, 230, 0.15)',
    },
  })
)

interface StrategyProps {
  snippets: SnippetList
  insertSnippet: (snippet: Snippet) => void
  handlebarsContext: HandlebarsContextType
  isHighlighted?: boolean
}

const Strategy: React.FC<StrategyProps> = ({ snippets, insertSnippet, handlebarsContext, isHighlighted = false }) => {
  const classes = useStyles()
  const snippetTypes = allSnippetTypesSelector(Object.values(snippets))
  const [visibility, setVisiblity] = useState(false)
  const [highlighted, setHighlighted] = useState(isHighlighted)
  const [selectedStrategyGroup, setSelectedStrategyGroup] = useState<string | null>(null)
  const [selectedStrategy, setSelectedStrategy] = useState<Snippet | null>(null)
  const snippetsBySelectedType = snippetsByTypeSelector(Object.values(snippets), selectedStrategyGroup)

  const onTriggerClick = () => {
    setVisiblity(!visibility)
    setHighlighted(false)
  }

  // We load languages besides English after we load this, so we must set this if it already isn't set.
  React.useEffect(() => {
    setSelectedStrategyGroup(currentGroup => {
      if (!currentGroup && snippetTypes.length) {
        return snippetTypes[0]
      }

      return currentGroup
    })
  }, [snippetTypes, setSelectedStrategyGroup])

  const handleInsertSnippet = React.useCallback(
    (snippet: Snippet) => {
      setVisiblity(false)
      insertSnippet(snippet)
      setSelectedStrategy(null)
    },
    [setVisiblity, insertSnippet, setSelectedStrategy]
  )

  const handleStrategyClick = React.useCallback(
    (snippet: Snippet) => {
      if (selectedStrategy && snippet.id === selectedStrategy.id) {
        handleInsertSnippet(snippet)
      } else {
        setSelectedStrategy(snippet)
      }
    },
    [handleInsertSnippet, setSelectedStrategy, selectedStrategy]
  )

  const insertSelectectSnippet = React.useCallback(() => {
    if (selectedStrategy) {
      handleInsertSnippet(selectedStrategy)
    }
  }, [selectedStrategy, handleInsertSnippet])

  return (
    <Box className={classes.strategy}>
      <Tooltip title={i18n.t('messaging__tooltip__openClientStrategy') || 'Open Client Strategy'} open={highlighted}>
        <ButtonBase
          className={classNames({ [classes.icon]: true, [classes.highlighted]: highlighted })}
          aria-controls="template-selection-menu"
          aria-haspopup="true"
          id="message-toolbox-templates-selector"
          onClick={onTriggerClick}
        >
          <Box className="icon-img">
            <img src={StrategyIcon} alt="" />
          </Box>
          <Typography display="block" variant={'body2'} className="icon-text" area-label="test__strategy">
            {i18n.t('messaging__tool__strategy')}
          </Typography>
        </ButtonBase>
      </Tooltip>
      {visibility && (
        <Box className={classes.container}>
          <Box className={classes.head}>
            <FormControl>
              <Typography variant={'body2'} className={classes.title}>
                {i18n.t('client__strategy__snippets')}
              </Typography>
              <Select
                input={<BootstrapInput id="m_strategy_name" />}
                value={selectedStrategyGroup}
                onChange={e => setSelectedStrategyGroup(e.target.value as string)}
              >
                {snippetTypes.map(snippetType => (
                  <MenuItem value={snippetType} key={`strategy-name-${snippetType}`}>
                    {snippetType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box onClick={() => setVisiblity(!visibility)} className={classes.close}>
              X
            </Box>
          </Box>
          <ul className={classes.list}>
            {snippetsBySelectedType.map((s: Snippet) => (
              <li
                key={`strategy-type-${s.id}`}
                onClick={() => handleStrategyClick(s)}
                className={classNames({
                  [classes.listItem]: true,
                  [classes.active]: selectedStrategy && s.id === selectedStrategy.id,
                  'snippet-list-item': true,
                })}
              >
                <Typography variant={'h5'} className={classes.type}>
                  {s.name}
                </Typography>
                <Typography variant={'h6'} className={classes.body}>
                  {ReactHtmlParser(renderEmailTemplate(s.body, { ...handlebarsContext, snippetName: s.name }))}
                </Typography>
              </li>
            ))}
          </ul>
          <Box className={classes.footer}>
            <Button type="primary" disabled={!selectedStrategy} onClick={insertSelectectSnippet}>
              {i18n.t('client__strategy__snippetsInsert')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

function propsAreEqual(prev: StrategyProps, next: StrategyProps) {
  return Object.keys(prev.snippets).length === Object.keys(next.snippets).length
}

const memoizedStrategy = React.memo(Strategy, propsAreEqual)
// @ts-ignore
memoizedStrategy.whyDidYouRender = true

export default memoizedStrategy
