import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import SingleSelectOption from './SingleSelectOption'
import Box from '@material-ui/core/Box'

export interface SingleSelectDataItem {
  label?: string
  text: string
  value: string | number
}

export type SingleSelectData = SingleSelectDataItem[]

export type SingleSelectValue = string | number | null

export interface SingleSelectProps {
  data: SingleSelectData
  value?: SingleSelectValue
  handleChange?: (value: SingleSelectValue) => void
  allowEmpty?: boolean
  size?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
)

const SingleSelect: React.FC<SingleSelectProps> = props => {
  const classes = useStyles()
  const { data, handleChange, size } = props
  const allowEmpty = 'allowEmpty' in props ? props.allowEmpty : true
  const value = 'value' in props ? props.value : allowEmpty ? null : data[0].value
  const [selection, setSelection] = useState<SingleSelectValue>(value ? value : null)

  const triggerChangeHandler = (value: SingleSelectValue) => {
    handleChange && handleChange(value)
  }

  const handleSelectionChange = (value: SingleSelectValue) => {
    if (selection === value && !allowEmpty) {
      return
    }
    const newSelection = selection === value ? null : value
    setSelection(newSelection)
    triggerChangeHandler(newSelection)
  }

  return (
    <Box className={classes.root}>
      {data.map((d, i) => (
        <SingleSelectOption
          label={d.label}
          text={d.text}
          value={d.value}
          active={selection === d.value}
          handleClick={() => handleSelectionChange(d.value)}
          size={size}
          key={i}
        />
      ))}
    </Box>
  )
}

export default SingleSelect
