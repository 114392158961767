import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { AutoLinkedin, AutoLinkedinMeta, Sections } from '../../store/autolinkedin/types'
import {
  getAutoLinkedinByItemID,
  getAutoLinkedinParsedSections,
  getAutoLinkedinUpdateMeta,
  getAutoLinkJsonMeta,
} from '../../store/autolinkedin/selectors'
import { LoadedLoadingErrorState } from '../../utils/state'
import ModalWrapper from '../common/ModalWrapper'
import Header from '../common/Header'
import i18n from '../../i18n'
import Box from '@material-ui/core/Box'
import NavigationBar from './NavigationBar'
import ResumePreview from './ResumePreview'
import Typography from '@material-ui/core/Typography'
import HistoryIcon from '@material-ui/icons/History'
import SectionForm from './SectionForm'
import BackToTop from '../common/BackToTop'
import Footer from '../common/Footer'
import Button from '../common/Button'
import WarningIcon from '@material-ui/icons/Warning'
import { formatDate } from '../../utils/formatting'
import { isToday } from 'date-fns'
import useUserInfoState from '../common/useUserInfo'
import Tooltip from '@material-ui/core/Tooltip'
import withStyles from '@material-ui/core/styles/withStyles'
import { useEffectOnce } from 'react-use'

export interface LinkedinEditableFormProps {
  handleNext: () => void
  handleBack: () => void
  handleClose: () => void
  handleReset: () => void
  handleValidate: () => void
  linkedinItemID: number
  validationOn: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    cancelButton: {
      marginRight: '1.2rem',
      padding: '.5rem 1.547rem',
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    controlsBox: {
      marginLeft: '2em',
      marginTop: '25px',
      '& > *': {
        margin: '9px 0',
      },
    },
    errorBox: {
      color: styles.palette.pink.hex,
      '& > svg': {
        verticalAlign: 'text-bottom',
        marginRight: '.5rem',
      },
    },
    errorBoxText: {
      fontSize: '.95rem',
    },
    forms: {
      marginLeft: '19.5em !important',
      width: '80%',
    },
    formUpdateStatus: {
      justifyContent: 'center',
      display: 'flex',
      width: '100%',
      margin: 'auto',
    },
    formUpdateStatusText: {
      fontStyle: 'italic',
      fontSize: '.95rem',
    },
    highlighted: {
      border: `3px solid ${styles.palette.darkerBlue.color.hex} !important`,
    },
    icon: {
      cursor: 'pointer',
      display: 'inline-block',
      marginBottom: '-.2em',
    },
    iconText: {
      cursor: 'pointer',
      color: styles.palette.darkerBlue.color.hex,
      fontSize: '15px',
      fontWeight: 500,
    },
    navigationBox: {
      position: 'fixed',
      top: '13em',
      width: '17em',
    },
    submitButton: {
      padding: '.5rem 1.547rem',
    },
  })
)

const DisabledButtonTooltip = withStyles({
  tooltip: {
    color: '#3c4a66',
    backgroundColor: '#fcfcfc',
    fontSize: '.93rem',
    padding: '1rem',
    boxShadow: '0 5px 10px 0 rgba(9, 20, 59, 0.05)',
    border: 'solid 1px rgba(46, 91, 255, 0.08)',
    maxWidth: 330,
    marginBottom: 35,
  },
  arrow: {
    color: '#fcfcfc',
  },
})(Tooltip)

const EditableFormWrapper: React.FC<LinkedinEditableFormProps> = ({
  handleNext,
  handleBack,
  handleClose,
  handleReset,
  handleValidate,
  linkedinItemID,
  validationOn,
}) => {
  const classes = useStyles()

  const autoLinkedinSections = useSelector<AppState, Sections[]>(state =>
    getAutoLinkedinParsedSections(state.autolinkedinReducer, linkedinItemID)
  )

  const sectionValidityMap: Record<string, boolean> = {}
  autoLinkedinSections.forEach(s => {
    sectionValidityMap[s.section] = true
  })
  const [sectionValidity, setSectionValidity] = useState<Record<string, boolean>>(sectionValidityMap)

  const sectionPristineMap: Record<string, boolean> = {}
  autoLinkedinSections.forEach(s => {
    sectionPristineMap[`${s.section}-form`] = true
  })
  const [sectionPristine, setSectionPristine] = useState<Record<string, boolean>>(sectionPristineMap)

  const handleNextClick = useCallback(() => {
    // re-render the form with validation visible
    if (Object.values(sectionValidity).find(s => !s) !== undefined) {
      handleValidate()
    } else {
      handleNext()
    }
  }, [sectionValidity, handleValidate, handleNext])

  const updateMeta = useSelector<AppState, LoadedLoadingErrorState | undefined>(state =>
    getAutoLinkedinUpdateMeta(state.autolinkedinReducer, linkedinItemID)
  )

  const jsonMeta = useSelector<AppState, AutoLinkedinMeta | null>(state =>
    getAutoLinkJsonMeta(state.autolinkedinReducer, linkedinItemID)
  )

  const autoLinkedin = useSelector<AppState, AutoLinkedin | undefined | null>(state =>
    getAutoLinkedinByItemID(state.autolinkedinReducer, linkedinItemID)
  )

  const workExperienceValidated = !!jsonMeta && jsonMeta.parsed_work_experience_validated

  const lastUpdatedToday = !!autoLinkedin?.updated_at ? isToday(new Date(autoLinkedin?.updated_at)) : false

  const { timeZone, locale } = useUserInfoState()

  const allFormsValid = useCallback(() => {
    return Object.values(sectionValidity).find(s => !s) === undefined
  }, [sectionValidity])

  useEffect(() => {
    const errors = document.getElementsByClassName('Mui-error')
    if (!!errors && errors.length > 0) {
      errors[0].scrollIntoView()
    }
  })

  const submitChangedForms = useCallback(() => {
    if (!workExperienceValidated) {
      return
    }
    const formsToSubmit = Object.entries(sectionPristine)
      .filter(([, value]) => !value)
      .map(([key]) => key)

    formsToSubmit.forEach(f => {
      const form = document.getElementById(f)
      if (!!form) {
        form.dispatchEvent(new Event('submit', { cancelable: true }))
      }
    })
  }, [sectionPristine, workExperienceValidated])

  const autoSaveTimerRef = useRef<undefined | number>()

  useEffectOnce(() => {
    // @ts-ignore
    autoSaveTimerRef.current = window.setInterval(submitChangedForms, 30 * 1000)
    return () => clearInterval(autoSaveTimerRef.current)
  })

  const renderNextButton = () => {
    if (!workExperienceValidated) {
      return (
        <DisabledButtonTooltip
          title={
            <span
              dangerouslySetInnerHTML={{
                __html: i18n.t('alink__EditLIProfileSectionsJobDesc__next_tooltip'),
              }}
            />
          }
          arrow={true}
        >
          <span>
            <Button
              type="contrast"
              showLoader={updateMeta?.isLoading}
              disabled={updateMeta?.isLoading || !workExperienceValidated || (validationOn && !allFormsValid())}
              className={classes.submitButton}
              onClick={handleNextClick}
              id="edit-li-form-next"
            >
              {i18n.t('alink__EditLIProfileSections__next')}
            </Button>
          </span>
        </DisabledButtonTooltip>
      )
    } else if (validationOn && !allFormsValid()) {
      return (
        <DisabledButtonTooltip
          title={<span>{i18n.t('alink__EditLIProfileSectionsFormInvalid__next_tooltip')}</span>}
          arrow={true}
        >
          <span>
            <Button
              type="contrast"
              showLoader={updateMeta?.isLoading}
              disabled={true}
              className={classes.submitButton}
              onClick={handleNextClick}
              id="edit-li-form-next"
            >
              {i18n.t('alink__EditLIProfileSections__next')}
            </Button>
          </span>
        </DisabledButtonTooltip>
      )
    } else {
      return (
        <Button
          type="contrast"
          showLoader={updateMeta?.isLoading}
          disabled={updateMeta?.isLoading || !workExperienceValidated || (validationOn && !allFormsValid())}
          className={classes.submitButton}
          onClick={handleNextClick}
          id="edit-li-form-next"
        >
          {i18n.t('alink__EditLIProfileSections__next')}
        </Button>
      )
    }
  }

  return (
    <ModalWrapper>
      <Header
        activeStep={1}
        closeButton={true}
        handleClose={() => {
          submitChangedForms()
          handleClose()
        }}
        title={i18n.t(
          !workExperienceValidated
            ? 'alink__EditLIProfileSectionsJobDesc__title'
            : 'alink__EditLIProfileSections__title'
        )}
        transparent={true}
      />
      <Box>
        <Box className={classes.navigationBox}>
          <NavigationBar sections={autoLinkedinSections} greyOutEmpty={false} />
          <Box className={classes.controlsBox}>
            <ResumePreview
              imageUrls={autoLinkedin?.resume_image_urls}
              iconClass={classes.icon}
              iconTextClass={classes.iconText}
            />
            <Typography className={classes.iconText} onClick={handleReset}>
              <HistoryIcon className={classes.icon} /> {i18n.t('alink__nav__restart')}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.forms}>
          {autoLinkedinSections.map(parsedSection => (
            <SectionForm
              validationOn={validationOn}
              readOnly={false}
              sectionValidity={sectionValidity}
              setSectionValidity={setSectionValidity}
              sectionPristine={sectionPristine}
              setSectionPristine={setSectionPristine}
              highlightClass={classes.highlighted}
              key={parsedSection.section}
              linkedinItemID={linkedinItemID}
              section={parsedSection}
            />
          ))}
        </Box>
      </Box>

      <BackToTop topElement="header" />

      <Footer
        leftColumn={
          <Button type="secondary" className={classes.cancelButton} onClick={handleBack} id="edit-li-form-cancel">
            {i18n.t('alink__EditLIProfileSections__cancel')}
          </Button>
        }
        rightColumn={renderNextButton()}
      >
        <Box className={classes.formUpdateStatus}>
          {updateMeta?.isLoading && (
            <Typography className={classes.formUpdateStatusText}>
              {i18n.t('alink__EditLIProfileSections__saving')}
            </Typography>
          )}
          {updateMeta?.loaded && !!updateMeta?.error && (
            <Box className={classes.errorBox}>
              <WarningIcon />
              <Typography className={classes.errorBoxText} component="span">
                {updateMeta?.error === 'SystemError'
                  ? i18n.t('alink__updateSystemError')
                  : i18n.t('alink__updateError')}
              </Typography>
            </Box>
          )}
          {updateMeta?.loaded && !updateMeta?.error && lastUpdatedToday && autoLinkedin?.updated_at && (
            <Typography className={classes.formUpdateStatusText}>
              {i18n.t('alink__EditLIProfileSections__last_saved', {
                time: formatDate(autoLinkedin?.updated_at, timeZone, locale, 'pp'),
              })}
            </Typography>
          )}
        </Box>
      </Footer>
    </ModalWrapper>
  )
}

export default EditableFormWrapper
