import React, { useEffect, useLayoutEffect } from 'react'
// @ts-ignore
import * as CronofyElements from 'cronofy-elements'
import { AppState } from '../../../store'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import CalendarStyle from '../Styles'
import { getAvailabilityWidgetToken } from '../../../store/scheduling/actions'
import {
  getAvailabilityTimeBoundaries,
  getElementToken,
  getElementTokenMeta,
} from '../../../store/scheduling/selectors'
import { LoadedLoadingErrorState } from '../../../utils/state'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'
import i18n from '../../../i18n'
import Fade from '@material-ui/core/Fade'

interface Props {
  userID: number
  userTimezone: string
  setAvailabilitySaved: (availabilitySaved: boolean) => void
}

interface NotificationProps {
  notification: {
    type: string
  }
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      position: 'relative',
      maxWidth: '43.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: '0',
    },
    col: {
      '& .availability__calendars': {
        display: 'none',
      },
      '& .availability .availability__submit': {
        borderRadius: '4px',
        backgroundColor: CalendarStyle.color.cta,
        '&:hover': {
          backgroundColor: CalendarStyle.color.ctaHover,
        },
      },
      '& .availability': {
        marginBottom: 0,
      },
      '& .availability .availability__legend': {
        position: 'absolute',
        top: '1.8rem',
        left: '6rem',
      },
      '& .availability .availability__label-row': {
        paddingTop: '5rem',
      },
      '& .availability .availability__label--day': {
        fontSize: '.9rem',
        fontWeight: 700,
        textTransform: 'uppercase',
      },
      '& .availability .availability__timeline': {
        stroke: '#15b3d6',
      },
      '& .availability__legend-item': {
        alignItems: 'center',
      },
      '& .availability__example--available': {
        height: '10px',
        width: '10px',
        marginLeft: '8px',
        marginRight: '8px',
      },
      '& .availability__example--unavailable': {
        height: '10px',
        width: '10px',
        marginLeft: '8px',
        marginRight: '8px',
      },
      '& .availability__legend-label': {
        fontSize: CalendarStyle.fontSizeSm,
        color: 'black',
      },
      '& .availability__label--day': {
        border: '1px solid',
        borderColor: CalendarStyle.color.borders,
      },
      '& .availability__confirmation': {
        display: 'flex',
        alignItems: 'center',
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      },
      '& .availability__submit': {
        display: 'none',
        padding: '8px',
        minWidth: '70px',
      },
      '& .availability__tick-mark': {
        display: 'none',
      },
      '& .availability__tick-mark-wrapper': {
        borderTop: '1px solid',
        borderColor: CalendarStyle.color.borders,
      },
    },
    message: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      bottom: '0px',
      right: '0px',
      width: '16.375rem',
    },
    messageText: {
      fontSize: '12px',
      lineHeight: '1.4',
      fontWeight: 600,
    },
    icon: {
      color: 'black',
      width: '1rem',
      height: '1rem',
      marginRight: '.5rem',
    },
  })
)

export const AvailabilityWidget: React.FC<Props> = ({ userID, userTimezone, setAvailabilitySaved }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAvailabilityWidgetToken(userID))
  }, [dispatch, userID])

  const element_token = useSelector<AppState, string | null>(state => getElementToken(state.schedulingReducer))

  const element_token_meta = useSelector<AppState, LoadedLoadingErrorState>(state =>
    getElementTokenMeta(state.schedulingReducer)
  )

  const [start, end] = useSelector<AppState, [string, string]>(state =>
    getAvailabilityTimeBoundaries(state.schedulingReducer)
  )

  useLayoutEffect(() => {
    if (element_token_meta && element_token_meta.loaded) {
      CronofyElements.AvailabilityRules({
        element_token: element_token,
        target_id: 'cronofy-availability-rules',
        availability_rule_id: 'default',
        config: {
          start_time: start,
          end_time: end,
          duration: 30,
        },
        styles: {
          colors: {
            available: CalendarStyle.color.blank,
            unavailable: CalendarStyle.color.unavailable,
          },
          prefix: 'availability',
        },
        translations: {
          en: {
            availability_rules: {
              save_new_rules: 'Save',
              time_zone: 'Timezone',
              confirm: 'Confirm',
              rules_saved: 'Saved!',
            },
          },
        },
        callback: (update: NotificationProps) => {
          if (update.notification.type === 'availability_rule_edited') {
            setAvailabilitySaved(true)
          }
        },
        tzid: userTimezone,
      })
    }
  }, [element_token, element_token_meta, end, setAvailabilitySaved, start, userTimezone])

  return (
    <Fade in={true}>
      <Box className={classes.container}>
        <Box id="cronofy-availability-rules" className={classes.col} />
        <Box className={classes.message}>
          <InfoIcon className={classes.icon} />
          <Typography className={classes.messageText}>{i18n.t('messaging__availability_save')}</Typography>
        </Box>
      </Box>
    </Fade>
  )
}

export default AvailabilityWidget
