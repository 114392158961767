// Stolen from https://stackoverflow.com/a/39249054

export default function compare(...params) {
  if (params[3]) {
    //handle case insensitive conditions if 4 param is passed.
    params[0] = params[0].toLowerCase()
    params[2] = params[2].toLowerCase()
  }
  const [lhs, operator, rhs] = params
  switch (operator) {
    case '==':
      /* eslint-disable-next-line eqeqeq */
      return lhs == rhs
    case '!=':
      /* eslint-disable-next-line eqeqeq */
      return lhs != rhs
    case '===':
      return lhs === rhs
    case '!==':
      return lhs !== rhs
    case '<':
      return lhs < rhs
    case '<=':
      return lhs <= rhs
    case '>':
      return lhs > rhs
    case '>=':
      return lhs >= rhs
    case '&&':
      return !!(lhs && rhs)
    case '||':
      return !!(lhs || rhs)
    default:
      return false
  }
}
