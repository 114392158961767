import { PaymentItemWithDate, UserGoal, UserPreferencesState } from '../store/user_preferences/reducers'
import { BasePay } from '../store/orders/types'
import { UserDashboardState } from '../store/dashboard/types'
import { utcToZonedTime } from 'date-fns-tz'
import { AppState } from '../store'

export const userPendingPay = (state: UserPreferencesState, userID: number): BasePay | null => {
  try {
    return state.users[userID].payment.pending_pay
  } catch (e) {
    return null
  }
}

export const userAcceptedPay = (state: UserPreferencesState, userID: number): BasePay | null => {
  try {
    const payInfo = state.users[userID].payment
    return { amount: payInfo.pending_pay.amount - payInfo.earned_pay.amount, currency: payInfo.pending_pay.currency }
  } catch (e) {
    return null
  }
}

export const userNextPay = (state: AppState, userID: number): PaymentItemWithDate | null => {
  try {
    const userPref = state.userPreferencesReducer.users[userID]
    const userInfo = state.userReducer.users[userID]

    // In between the payday and the next cutoff date there's a time when we don't know the next pay amount
    // Let's approximate it with earned amount.
    const nextPayLocal = utcToZonedTime(userPref.payment.pay_dates.next_pay_date, userInfo.timezone_string)
    const payPeriodEndsLocal = utcToZonedTime(userPref.payment.pay_dates.pay_period_ends, userInfo.timezone_string)

    const now = utcToZonedTime(new Date(), userInfo.timezone_string)

    if (nextPayLocal < now && now < payPeriodEndsLocal) {
      return {
        amount: userPref.payment.earned_pay.amount,
        currency: userPref.payment.earned_pay.currency,
        date: userPref.payment.pay_dates.next_next_pay_date,
      }
    }

    return {
      amount: userPref.payment.next_payment.amount,
      currency: userPref.payment.next_payment.currency,
      date: userPref.payment.pay_dates.next_pay_date,
    }
  } catch (e) {
    return null
  }
}

export interface EarningsGoalProgress {
  current: BasePay
  accepted: BasePay
  goal?: UserGoal | null
  periodEnds: string
}

export const getEarningsGoalProgress = (
  userPreferences: UserPreferencesState,
  dashboardState: UserDashboardState,
  userID: number
): EarningsGoalProgress | null => {
  try {
    const userPayment = userPreferences.users[userID].payment
    const acceptedAmount = userPayment.pending_pay.amount - userPayment.earned_pay.amount
    return {
      current: userPayment.earned_pay,
      goal: userPayment.weekly_earnings_goal,
      accepted: { amount: acceptedAmount, currency: userPayment.pending_pay.currency },
      periodEnds: userPayment.pay_dates.pay_period_ends,
    }
  } catch (e) {
    return null
  }
}
