import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import i18n from '../../i18n'
import TopMenuItem from '../TopMenu/TopMenuItem'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import PromiseIcon from '../common/Icons/Promise'
import classNames from 'classnames'
import { BasePay } from '../../store/orders/types'
import { formatCurrency } from '../../utils/formatting'

interface ReviewOrdersProps {
  state: ReviewOrderState
  onReviewOrdersClick: () => void
  pendingPay: BasePay | null
}

export enum ReviewOrderStates {
  Active = 'Active',
  Inactive = 'Inactive',
  Disabled = 'Disabled',
}

export type ReviewOrderState = keyof typeof ReviewOrderStates

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      '&.active': {
        backgroundColor: '#64cea0',
        '& .container': { color: '#fff' },
      },
      '& .container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#64cea0',
        '& .disablePromises': {
          color: '#CACFD8',
        },
      },
      '@media (max-width: 580px)': {
        '& .container': { color: '#CACFD8' },
      },
    },
    main: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      fontSize: '1.1875rem',
      marginRight: '.5rem',
      '@media (max-width: 580px)': {
        marginRight: 0,
      },
    },
    text: {
      textTransform: 'initial',
      fontSize: '1.1875rem',
      fontWeight: 600,
      '@media (max-width: 580px)': {
        display: 'none',
      },
      '&.disablePromises': {
        display: 'none',
      },
    },
    subtext: {
      marginTop: '-.2rem',
      fontSize: '.6875rem',
      '@media (max-width: 580px)': {
        display: 'none',
      },
      '&.disablePromises': {
        display: 'none',
      },
    },
  })
)

const ReviewOrders: React.FC<ReviewOrdersProps> = props => {
  const classes = useStyles()
  const { state, pendingPay, onReviewOrdersClick } = props

  return (
    <TopMenuItem
      delimiter="both"
      className={classNames({ [classes.root]: true, active: state === ReviewOrderStates.Active })}
    >
      <Box className="container" onClick={onReviewOrdersClick}>
        {!!pendingPay && (
          <Box className={classes.main}>
            <PromiseIcon
              className={classNames({ [classes.icon]: true, disablePromises: state === ReviewOrderStates.Disabled })}
            />
            <Typography
              display="block"
              className={classNames({ [classes.text]: true, disablePromises: state === ReviewOrderStates.Disabled })}
              variant={'h2'}
            >
              {`${formatCurrency(pendingPay.amount, pendingPay.currency)} ${i18n.t('topBar__reviewOrders__pending')}`}
            </Typography>
          </Box>
        )}
        <Typography
          display="block"
          className={classNames({ [classes.subtext]: true, disablePromises: state === ReviewOrderStates.Disabled })}
          variant={'h6'}
        >
          {i18n.t('topBar__reviewOrders__button')}
        </Typography>
      </Box>
    </TopMenuItem>
  )
}

export default ReviewOrders
