import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import partition from 'lodash/partition'
import isEqual from 'lodash/isEqual'
import format from 'date-fns-tz/format'
import classNames from 'classnames'
import { AppState } from '../../../store'
import { useSelector } from 'react-redux'

import i18n from '../../../i18n'
import { ClientOrdersDisplayAggregate } from '../../../selectors/clientOrderAggregates'
import { OrderItemTypes } from '../../../store/items/types'
import { FulfillableOrderItemDisplay, GenericOrderItemDisplay } from '../../Orders/OrderItems'
import { formatCurrency } from '../../../utils/formatting'
import useUserInfoState from '../../common/useUserInfo'
import { isOffshoreSelector } from '../../../selectors/users'

export interface OrderPayProps {
  currentOrderGroup: ClientOrdersDisplayAggregate
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      backgroundColor: 'white',
      border: '1px solid #D5D9E0',
      borderRadius: '.1875rem',
      color: 'black',
      '@media (max-width: 1024px)': {
        border: 'none',
        marginBottom: '0 !important',
        width: '25%',
      },
      '@media (max-width: 560px)': {
        width: '100%',
      },
    },
    titleContainer: {
      padding: '.9375rem 1.5rem',
      borderBottom: '1px solid #D5D9E0',
      '@media (max-width: 1024px)': {
        display: 'none',
      },
    },
    title: {
      fontWeight: 600,
    },
    content: {
      padding: '.9375rem 1.5rem',
      '@media (max-width: 1024px)': {
        padding: '.9375rem 1rem',
      },
      '@media (max-width: 560px)': {
        paddingBottom: '0',
      },
    },
    summary: {
      '& .category': {
        '&:not(:first-child)': {
          marginTop: '.875rem',
        },
        '& .ctitle': {
          marginBottom: '.5625rem',
          color: '#c0c4ca',
          fontSize: '.8125rem',
          fontWeight: 500,
          lineHeight: '1.125rem',
        },
        '& .item': {
          display: 'flex',
          flexDirection: 'row',
          boxSizing: 'border-box',
          flexWrap: 'wrap',
          '&:not(:last-child)': {
            marginBottom: '.875rem',
          },
          '& .title': {
            display: 'inline-flex',
            flexGrow: 1,
            fontSize: '.8125rem',
            lineHeight: '1.125rem',
            width: '75%',
          },
          '& .price': {
            fontSize: '.8125rem',
            lineHeight: '1.125rem',
            textAlign: 'right',
            width: '25%',
          },
          '& .directions': {},
        },
      },
      '@media (max-width: 1024px)': {
        display: 'none',
      },
    },
    total: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '.9375rem',
      marginTop: '.9375rem',
      borderTop: '1px solid #d5d9e0',
      '& .label': {
        display: 'inline-flex',
        flexGrow: 1,
        fontSize: '.875rem',
        fontWeight: 600,
        lineHeight: '1.125rem',
        '@media (max-width: 1024px)': {
          marginBottom: '1.75rem',
        },
        '@media (max-width: 560px)': {
          marginBottom: '1rem',
        },
      },
      '& .price': {
        fontSize: '1.125rem',
        fontWeight: 500,
        lineHeight: '1.125rem',
        '@media (max-width: 1024px)': {
          fontSize: '1.5rem',
          fontWeight: 'bold',
        },
      },
      '@media (max-width: 1024px)': {
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
    },
    opportunity: {
      color: '#64cea0',
      fontWeight: 'bold',
    },
  })
)

export interface LedgerOrderItem {
  id: number
  name: string
  pay: string
}

const OrderPay: React.FC<OrderPayProps> = ({ currentOrderGroup }) => {
  const classes = useStyles()
  const { currency, timeZone } = useUserInfoState()

  const isOffshoreUser = useSelector<AppState, boolean>(state => isOffshoreSelector(state.userReducer))

  const [regularItems, incentives] = partition<FulfillableOrderItemDisplay | GenericOrderItemDisplay>(
    currentOrderGroup.items,
    item =>
      !item.compound_item_key.includes('rush') &&
      item.type !== OrderItemTypes.Rush &&
      item.type !== OrderItemTypes.Incentive
  )

  const [detailedIncentives, regularIncentives] = partition<GenericOrderItemDisplay>(
    incentives,
    item => item.type === OrderItemTypes.Incentive && !!item.incentives && item.incentives.length > 0
  )

  const flattenedDetailedIncentives: LedgerOrderItem[] = detailedIncentives
    .map(incentive => incentive.incentives)
    .flat()
    .map(i => {
      return {
        id: i.incentive_id,
        name: i.name,
        pay: formatCurrency(i.amount, currentOrderGroup.basePay.currency),
      }
    })

  const orderTitle = isOffshoreUser ? i18n.t('messaging__side__orderContents') : i18n.t('messaging__side__orderPay')

  return (
    <Box className={classes.root}>
      <Box className={classes.titleContainer}>
        <Typography variant={'body1'} className={classes.title} area-label="order_pay">
          {orderTitle}
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.summary}>
          {regularItems.length > 0 && (
            <Box className="category" key={'order-items'}>
              <Typography variant={'body2'} className="ctitle">
                {i18n.t('messaging__side__orderPay__order_items')}
              </Typography>
              {regularItems.map((item, index) => (
                <Box className="item" key={`item-order-${index}`}>
                  <Typography variant={'body2'} className="title">
                    {item.name}
                  </Typography>
                  {!isOffshoreUser && (
                    <Typography variant={'body2'} className="price">
                      {formatCurrency(
                        item.pay.map(x => x.amount).reduce((acc, y) => acc + y),
                        item.pay[0].currency
                      )}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          )}
          {incentives.length > 0 && !isOffshoreUser && (
            <Box className="category" key={'order-incentives'}>
              <Typography variant={'body2'} className="ctitle">
                {i18n.t('messaging__side__orderPay__incentives')}
              </Typography>
              {regularIncentives.length > 0 &&
                regularIncentives.map((item, index) => (
                  <Box className="item" key={`item-regular-incentives-${index}`}>
                    <Typography variant={'body2'} className="title">
                      {item.name}
                    </Typography>
                    <Typography variant={'body2'} className="price">
                      {formatCurrency(
                        item.pay.map(x => x.amount).reduce((acc, y) => acc + y),
                        item.pay[0].currency
                      )}
                    </Typography>
                  </Box>
                ))}
              {flattenedDetailedIncentives.length > 0 &&
                flattenedDetailedIncentives.map(item => (
                  <Box className="item" key={`item-detailed-incentives-${item.id}`}>
                    <Typography variant={'body2'} className="title">
                      {item.name}
                    </Typography>
                    <Typography variant={'body2'} className="price">
                      {item.pay}
                    </Typography>
                  </Box>
                ))}
            </Box>
          )}
          {currentOrderGroup.timeBasedIncentives.length > 0 && !isOffshoreUser && (
            <Box className="category" key="opportunities-incentive">
              <Typography variant={'body2'} className="ctitle">
                {i18n.t('messaging__side__orderPay__opportunities')}
              </Typography>
              {currentOrderGroup.timeBasedIncentives.map(incentive => (
                <Box
                  className={classNames(['item', `opportunity-${incentive.segmentation.toLowerCase()}`])}
                  key={`item-detailed-incentives-${incentive.id}`}
                >
                  <Typography variant={'body2'} className={classNames(['title', classes.opportunity])}>
                    {incentive.name}
                  </Typography>
                  <Typography variant={'body2'} className={classNames(['price', classes.opportunity])}>
                    {formatCurrency(incentive.amount, currency)}
                  </Typography>
                  <Typography variant={'h6'} className="directions">
                    {i18n.t(`messaging__side__orderPay__opportunities${incentive.segmentation}Copy`, {
                      date: format(
                        new Date(incentive.bonus_due_date),
                        i18n.t(`messaging__side__orderPay__opportunities${incentive.segmentation}DateFormat`),
                        { timeZone }
                      ),
                    })}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        {!isOffshoreUser && (
          <Box className={classes.total}>
            <Typography variant={'body2'} className="label">
              {currentOrderGroup.status === 'paid'
                ? i18n.t('messaging__side__paid')
                : i18n.t('messaging__side__pending')}
            </Typography>
            <Typography variant={'body2'} className="price">
              {formatCurrency(currentOrderGroup.basePay.amount, currentOrderGroup.basePay.currency)}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

function propsAreEqual(prev: OrderPayProps, next: OrderPayProps) {
  if (prev.currentOrderGroup.items.length !== next.currentOrderGroup.items.length) {
    return false
  }
  if (prev.currentOrderGroup.basePay.amount !== next.currentOrderGroup.basePay.amount) {
    return false
  }
  if (prev.currentOrderGroup.timeBasedIncentives.length !== next.currentOrderGroup.timeBasedIncentives.length) {
    return false
  }
  return isEqual(prev.currentOrderGroup.items, next.currentOrderGroup.items)
}

const memoizedOrderPay = React.memo(OrderPay, propsAreEqual)
// @ts-ignore
memoizedOrderPay.whyDidYouRender = true

export default memoizedOrderPay
