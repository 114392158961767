import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import diff from 'object-diff'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useField } from 'react-final-form-hooks'

import i18n from '../../../i18n'
import styles from '../../../styles'
import { Order } from '../../../store/orders/types'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import * as questionnaireSelectors from '../../../selectors/questionnaire'
import { ReadOnlyTextField, TextField } from '../../common/formHelpers'
import { AppState } from '../../../store'
import { Client } from '../../../store/clients/reducer'
import Button from '../../common/Button'
import { updateClient } from '../../../store/clients/actions'
import { Questionnaire } from '../../../store/orders/questionnaire/types'
import { updateQuestionnaire } from '../../../store/questionnaire/actions'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      display: 'grid',
      gridRowGap: '2rem',
      gridTemplateColumns: 'auto auto',
      '@media (max-width: 1215px)': {
        gridTemplateColumns: 'auto',
      },
      '@media (max-width: 1025px)': {
        gridTemplateColumns: 'auto auto',
      },
      '@media (max-width: 805px)': {
        gridTemplateColumns: 'auto',
      },
      '& a': {
        color: styles.palette.link.color,
      },
    },
    saveContainer: {
      display: 'flex',
    },
    saveButton: {
      marginLeft: 'auto',
    },
    title: {
      fontSize: '1.3125rem',
      marginBottom: '1.75rem',
      fontWeight: 'bold',
    },
    achivementTitle: {
      marginBottom: '0.5rem',
      color: styles.palette.lightBlack.color,
      fontWeight: 'bold',
    },
    achivementsList: {
      marginBottom: '1.4375rem',
      color: '#5b5b64',
      listStyle: 'disc',
      '& li': {
        whiteSpace: 'pre-line',
      },
    },
    hr: {
      width: '100%',
      height: '1px',
      margin: '2.4375rem 0',
      background: '#d7dbe1',
    },
    question: {
      color: styles.palette.lightBlack.color,
      marginBottom: '1rem',
      fontWeight: 'bold',
    },
    answer: {
      color: '#5b5b64',
      marginBottom: '2.625rem',
      whiteSpace: 'pre-line',
      '& a': {
        color: styles.palette.link.color,
      },
    },
    linkedinContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: '2.4375rem',
    },
  })
)

interface ClientDetailsProps {
  order: Order
  questionnaire: Questionnaire | undefined
}

export interface ClientInfoUpdate {
  name: string
  email: string
}

export interface ClientLinkedinUpdate {
  linkedin: string
}

// A lot of this data is also on the order/customer record. This pane tries to ignore that data and pull from the
// questionnaire, as it often as differing information on repeat customers and is somewhat more accurate. That said, if
// you update something line a customer's email address in OMS, it will not be reflected here and that is on purpose.
const ClientDetails: React.FC<ClientDetailsProps> = ({ order, questionnaire }) => {
  const classes = useStyles()
  const [hasErrors, setHasErrors] = React.useState(false)
  const [hasLinkedinError, setHasLinkedinError] = React.useState(false)
  const [editLinkedin, setEditLinkedin] = React.useState(false)
  const dispatch = useDispatch()

  const clientData = useSelector<AppState, Client>(state => state.clientReducer.clients[order.client_id])

  const industry = questionnaireSelectors.getIndustry(questionnaire)
  const address = questionnaireSelectors.getAddress(questionnaire)
  const phoneNumbers = questionnaireSelectors.getPhoneNumbers(questionnaire)
  const linkedinURL = questionnaireSelectors.getLinkedInURL(questionnaire)
  const country = questionnaireSelectors.getCountry(questionnaire)
  const concerns = questionnaireSelectors.getConcerns(questionnaire)
  const documents = questionnaireSelectors.getAdditionalDocuments(questionnaire)
  const hasConcerns = concerns.length > 0
  const hasDocuments = documents.length > 0
  // @TODO This isn't the exact brand name from Products, which is what the current version of the OMS shows here,
  // I think. We maybe need to return that from the API, if this isn't good enough.
  const brandName = order.brand.name

  // @TODO We need to hide the address, phone number, and LinkedIn URL from FCC admins

  const validate = (values: ClientInfoUpdate) => {
    const errors: { [key: string]: string } = {}

    Object.entries(values).forEach(([key, value]) => {
      if (!value) {
        errors[key] = i18n.t('account__error__required')
      }
    })

    if (!values.email) {
      errors.email = i18n.t('account__error__required')
    } else if (!values.email.includes('@')) {
      errors.email = i18n.t('account__error__email')
    }

    setHasErrors(Object.keys(errors).length > 0)
    return errors
  }

  const onSubmit = (data: ClientInfoUpdate): Promise<void> => {
    return new Promise(async resolve => {
      const oldClient: ClientInfoUpdate = { name: clientData.full_name, email: clientData.primary_email }

      const diffedData = diff(oldClient, data)

      if (!Object.keys(diffedData).length) {
        return resolve()
      }

      await dispatch(updateClient(order.client_id, diffedData))
      resolve()
    })
  }

  const { form, handleSubmit, pristine, submitting } = useForm({ onSubmit, validate })

  const name = useField('name', form)
  const email = useField('email', form)

  useEffect(() => {
    form.initialize({ name: clientData.full_name, email: clientData.primary_email })
  }, [form, clientData])

  const validateLinkedin = (values: ClientLinkedinUpdate) => {
    setHasLinkedinError(!values.linkedin)
  }

  const onLinedinSubmit = (data: ClientLinkedinUpdate): Promise<void> => {
    return new Promise(async resolve => {
      if (linkedinURL === data.linkedin) {
        return resolve()
      }

      try {
        await dispatch(
          updateQuestionnaire(
            order.client_id,
            {
              question_id: 18,
              answer: data.linkedin,
            },
            'linkedinLink'
          )
        )
      } catch (error) {
        setTimeout(() => linkedinForm.reset())
      }

      setEditLinkedin(false)
      resolve()
    })
  }

  const {
    form: linkedinForm,
    handleSubmit: handleLinkedinSubmit,
    pristine: pristineLinkedin,
    submitting: submittingLinkedin,
  } = useForm({ onSubmit: onLinedinSubmit, validate: validateLinkedin })

  const linkedin = useField('linkedin', linkedinForm)

  useEffect(() => {
    linkedinForm.initialize({ linkedin: linkedinURL ?? '' })
  }, [linkedinForm, linkedinURL])

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box className={classes.container}>
          <TextField field={name} label={i18n.t('order__page__client__details__name')} />
          <TextField field={email} label={i18n.t('order__page__client__details__email')} htmlType={'email'} />

          {!!address && (
            <ReadOnlyTextField label={i18n.t('order__page__client__details__address')} text={address.join('\n')} />
          )}
          {!!country && <ReadOnlyTextField label={i18n.t('order__page__client__details__country')} text={country} />}
          {!!phoneNumbers && (
            <ReadOnlyTextField
              label={i18n.t('order__page__client__details__phone')}
              text={phoneNumbers
                .filter(pn => !!pn.number)
                .map(pn => `${i18n.t(`order__page__client__details__phone__type__${pn.type}`)}: ${pn.number}`)
                .join(', ')}
            />
          )}
          {!!industry && <ReadOnlyTextField label={i18n.t('order__page__client__details__industry')} text={industry} />}
          <ReadOnlyTextField label={i18n.t('order__page__client__details__source')} text={brandName} />
        </Box>
        <Box className={classes.saveContainer}>
          <Button
            className={classes.saveButton}
            type="primary"
            disabled={submitting || pristine || hasErrors}
            onClick={() => form.submit()}
            showLoader={submitting}
            area-label="save_changes"
          >
            {i18n.t('account__save')}
          </Button>
        </Box>
      </form>
      <form className={classes.linkedinContainer} onSubmit={handleLinkedinSubmit}>
        {!editLinkedin && !!linkedinURL ? (
          <ReadOnlyTextField label={i18n.t('order__page__client__details__linkedin')} text={linkedinURL} />
        ) : (
          <TextField field={linkedin} label={i18n.t('order__page__client__details__linkedin')} />
        )}
        {!editLinkedin && !!linkedinURL ? (
          <Button
            className={classes.saveButton}
            type="primary"
            onClick={() => setEditLinkedin(true)}
            area-label="edit_linkedin"
          >
            {i18n.t('account__linkedin__edit')}
          </Button>
        ) : (
          <Button
            className={classes.saveButton}
            type="primary"
            disabled={submittingLinkedin || pristineLinkedin || hasLinkedinError}
            onClick={() => linkedinForm.submit()}
            showLoader={submitting}
            area-label="save_linkedin"
          >
            {i18n.t('account__linkedin__save')}
          </Button>
        )}
        <Box className={classes.saveContainer}></Box>
      </form>
      <Box>
        {hasConcerns && (
          <>
            <hr className={classes.hr} />
            <Typography className={classes.title}>
              {i18n.t('order__page__client__details__primary__concerns')}
            </Typography>
            {concerns.map((c, i) => {
              return (
                <Box key={i}>
                  <Typography variant={'body1'} className={classes.question}>
                    {c.question}
                  </Typography>
                  {c.answer.map((answer, ai) => (
                    <Typography variant={'body2'} key={`concern-answer-${i}-${ai}`} className={classes.answer}>
                      {answer}
                    </Typography>
                  ))}
                </Box>
              )
            })}
          </>
        )}

        {hasDocuments && (
          <>
            <hr className={classes.hr} />
            <Typography className={classes.title}>{i18n.t('order__page__client__details__documents')}</Typography>
            {documents.map((docs, i) => (
              <Box key={i}>
                <Typography variant={'body1'} className={classes.question}>
                  {docs.question}
                </Typography>
                <Typography
                  variant={'body2'}
                  className={classNames([classes.achivementsList, classes.answer])}
                  component={'ul'}
                >
                  {docs.answer.map((doc, di) => (
                    <Typography key={`additional-doc-${i}-${di}`} variant={'body2'} component={'li'}>
                      <a href={doc.download_url} className="file-download-link">
                        {doc.originalName}
                      </a>
                    </Typography>
                  ))}
                </Typography>
              </Box>
            ))}
          </>
        )}
      </Box>
    </>
  )
}

export default React.memo(ClientDetails)
