import { AxiosError } from 'axios'
import produce from 'immer'

import { UserPreferencesActionType, UserPreferencesActionTypes } from './actions'
import { USER } from '../user/actions'
import { BasePay } from '../orders/types'

export interface UserPreferences {
  user_id: number
  communication: CommunicationPreferences
  payment: UserPayment
  assignment: AssignmentPreferences
}

export interface PaymentItemWithDate extends BasePay {
  date: string
}

export interface PayDates {
  pay_period_ends: string
  next_pay_date: string
  next_next_pay_date: string
}

export interface UserGoalPayload {
  type: 'earnings'
  cadence: 'week' | 'month'
  amount: number
}

export interface UserGoal extends UserGoalPayload {
  id: number
  expert_id: number
  created_at: string
  updated_at: string
  deleted_at?: string | null
  started_at: string
  ended_at?: string | null
}

export interface UserPayment {
  pending_pay: BasePay
  earned_pay: BasePay
  next_payment: BasePay
  pay_ytd: BasePay
  recent_payments: PaymentItemWithDate[]
  pay_dates: PayDates
  weekly_earnings_goal?: UserGoal | null
}

export interface CommunicationPreferences {
  email_from_client: boolean
  text_on_assignment: boolean
  text_on_assignment_number: string | null
  email_on_assignment: boolean
  email_on_assignment_address: string | null
}

export interface AssignmentPreferences {
  assignments_per_week: number
  availability: UserAvailability[]
  prefer_rush: boolean
  industry_preferences: number[]
  prefer_phone_calls: boolean
}
export interface UserPreferencesList {
  [userID: number]: UserPreferences
}

export interface UserAvailability {
  day_of_week: number
  enabled: boolean
}

export interface UserPreferencesState {
  isLoading: boolean
  error: string | AxiosError | null
  loggedInUserID: number | null
  users: UserPreferencesList
  getLoggedInUserPreferences: () => UserPreferences | null
}

export const initialState: UserPreferencesState = {
  isLoading: false,
  error: null,
  loggedInUserID: null,
  users: {},
  getLoggedInUserPreferences: function(): UserPreferences | null {
    return this.loggedInUserID !== null ? this.users[this.loggedInUserID] : null
  },
}

export default function userPreferencesReducer(
  state: UserPreferencesState = initialState,
  action: UserPreferencesActionType
): UserPreferencesState {
  return produce(state, (draft: UserPreferencesState) => {
    switch (action.type) {
      case USER.FETCH_USER_SUCCESS:
        // If we implicitly fetched the currently logged in user, set the ID in the store
        if (action.meta.previousAction.targetUserID === 'me') {
          draft.loggedInUserID = action.payload.data.id
        }
        break

      case UserPreferencesActionTypes.FETCH_USER_PREFERENCES:
        draft.isLoading = true
        draft.error = null
        break

      case UserPreferencesActionTypes.FETCH_USER_PREFERENCES_SUCCESS:
        const userPrefs = action.payload.data
        draft.isLoading = false
        draft.error = null
        draft.users[userPrefs.user_id] = userPrefs

        if (action.meta.previousAction.targetUserID === 'me') {
          draft.loggedInUserID = userPrefs.user_id
        }
        break

      case UserPreferencesActionTypes.FETCH_USER_PREFERENCES_FAIL:
        draft.isLoading = false
        draft.error = action.error
        break

      case UserPreferencesActionTypes.UPDATE_USER_COMMUNICATION_PREFERENCES:
      case UserPreferencesActionTypes.UPDATE_USER_ASSIGNMENT_PREFERENCES:
        draft.isLoading = true
        draft.error = null
        break

      case UserPreferencesActionTypes.UPDATE_USER_COMMUNICATION_PREFERENCES_SUCCESS:
      case UserPreferencesActionTypes.UPDATE_USER_ASSIGNMENT_PREFERENCES_SUCCESS:
        const updatedUserPrefs = action.payload.data
        draft.isLoading = false
        draft.error = null
        draft.users[updatedUserPrefs.user_id] = updatedUserPrefs

        if (action.meta.previousAction.targetUserID === 'me') {
          draft.loggedInUserID = updatedUserPrefs.user_id
        }
        break

      case UserPreferencesActionTypes.UPDATE_USER_COMMUNICATION_PREFERENCES_FAIL:
      case UserPreferencesActionTypes.UPDATE_USER_ASSIGNMENT_PREFERENCES_FAIL:
        draft.isLoading = false
        draft.error = action.error
        break

      case UserPreferencesActionTypes.UPDATE_EARNINGS_GOAL:
        draft.isLoading = true
        draft.error = null
        break

      case UserPreferencesActionTypes.UPDATE_EARNINGS_GOAL_SUCCESS:
        const goal = action.payload.data
        draft.users[goal.expert_id].payment.weekly_earnings_goal = goal
        draft.isLoading = false
        draft.error = null
        break

      case UserPreferencesActionTypes.UPDATE_EARNINGS_GOAL_FAIL:
        draft.isLoading = false
        draft.error = action.error
        break
    }
  })
}
