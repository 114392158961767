import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import diff from 'object-diff'
import pick from 'lodash/pick'

import { AppState } from '..'
import { userId, UserInfo } from './reducers'
import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import { CommunicationPreferences } from '../user_preferences/reducers'
import {
  fetchUserPreferences,
  FetchUserPreferences,
  updateUserCommunicationPreferences,
} from '../user_preferences/actions'
import { fetchConfigAndConfigure } from '../config/actions'
import { fetchSnippets, FetchSnippets, fetchTemplates, FetchTemplates } from '../templates/actions'
import { SnippetFilters } from '../templates/types'
import { completedOnboardingSelector, isNewZipJobWriter, isOnboardingMenteeSelector } from '../../selectors/users'
import { EndUserOnboardingSuccess, fetchOnboarding } from '../onboarding/actions'
import { FetchUserAggregatedOrders, fetchUserClientOrders } from '../clientOrderAggregate/actions'
import { fetchOffersForUser, FetchUserOffers } from '../offers/actions'
import { getFeatureFlags } from '../../selectors/featureFlags'
import { updateNudgeTrigger } from '../nudges/actions'
import { fetchSchedulingPreferences, GetPreferences } from '../scheduling/actions'

export enum USER {
  FETCH_USER = 'FETCH_USER',
  FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE = 'FETCH_USER_FAIL',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE = 'UPDATE_USER_FAIL',
  FETCH_CONFIG = 'FETCH_CONFIG',
  FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS',
  FETCH_CONFIG_FAIL = 'FETCH_CONFIG_FAIL',
}

export interface FetchUser extends AxiosMiddlewareActionCreator {
  type: typeof USER.FETCH_USER
  targetUserID: userId
}

export interface FetchUserSuccess extends AxiosMiddlewareActionSuccess<UserInfo, FetchUser> {
  type: typeof USER.FETCH_USER_SUCCESS
}

export interface FetchUserFailure extends AxiosMiddlewareActionFail {
  type: typeof USER.FETCH_USER_FAILURE
}

export interface UpdateUserInfo extends AxiosMiddlewareActionCreator {
  type: typeof USER.UPDATE_USER
  targetUserID: userId
}

export interface UpdateUserInfoSuccess extends AxiosMiddlewareActionSuccess<UserInfo, UpdateUserInfo> {
  type: typeof USER.UPDATE_USER_SUCCESS
}

export interface UpdateUserInfoFailure extends AxiosMiddlewareActionFail {
  type: typeof USER.UPDATE_USER_FAILURE
}

export function fetchUser(userID: userId = 'me'): FetchUser {
  return {
    type: USER.FETCH_USER,
    targetUserID: userID,
    payload: {
      request: {
        url: `/v1/users/${userID}`,
      },
    },
  }
}

export function updateUserInfo(
  oldInfo: UserInfo | Partial<UserInfo>,
  newInfo: UserInfo | Partial<UserInfo>,
  userID: userId = 'me'
): UpdateUserInfo | null {
  const diffedData = diff(oldInfo, newInfo)

  if (!Object.keys(diffedData).length) {
    return null
  }

  return {
    type: USER.UPDATE_USER,
    targetUserID: userID,
    payload: {
      request: {
        url: `/v1/users/${userID}`,
        method: 'PATCH',
        data: diffedData,
      },
    },
  }
}

export type UpdateUserInfoAndCommunicationPreferencesInputType = UserInfo & CommunicationPreferences

export function updateUserInfoAndCommunicationPreferences(
  userID: number,
  data: UpdateUserInfoAndCommunicationPreferencesInputType
): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    const state = getState()
    const newUserInfo = pick<UserInfo>(data, [
      'name',
      'name_last',
      'email_signature',
      'email',
      'timezone_string',
      'phone',
    ])
    const oldUserInfo = pick<UserInfo>(state.userReducer.users[userID], Object.keys(newUserInfo))
    const newUserCommunicationPreferences = pick<CommunicationPreferences>(data, [
      'email_from_client',
      'text_on_assignment',
      'text_on_assignment_number',
      'email_on_assignment',
      'email_on_assignment_address',
    ])
    const oldUserCommunicationPreferences = pick<CommunicationPreferences>(
      state.userPreferencesReducer.users[userID].communication,
      Object.keys(newUserCommunicationPreferences)
    )

    const actions = [
      updateUserInfo(oldUserInfo, newUserInfo, userID),
      updateUserCommunicationPreferences(oldUserCommunicationPreferences, newUserCommunicationPreferences, userID),
    ]

    await Promise.all(actions.map(action => action && dispatch(action)))
  }
}

const DEFAULT_OPEN_PAGE_SIZE = 150

type PreloadAction =
  | FetchUser
  | FetchUserPreferences
  | FetchTemplates
  | FetchSnippets
  | FetchUserOffers
  | ThunkAction<void, AppState, {}, AnyAction>
  | FetchUserAggregatedOrders
  | GetPreferences

export function preloadApp(): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    const preloadActions: PreloadAction[] = [
      fetchConfigAndConfigure(),
      fetchUser(),
      fetchUserPreferences(),
      fetchTemplates({ context: 'client' }),
      fetchUserClientOrders({
        status: ['open'],
        page_size: DEFAULT_OPEN_PAGE_SIZE,
        page: 1,
      }),
    ]

    // @ts-ignore
    await Promise.all(preloadActions.map(action => dispatch(action)))

    const userState = getState().userReducer

    const user = userState.getLoggedInUser()
    const { showScheduler } = getFeatureFlags(user)

    if (!!userState.loggedInUserID && showScheduler) {
      dispatch(fetchSchedulingPreferences())
    }

    if (
      !!userState.loggedInUserID &&
      (isOnboardingMenteeSelector(userState) || isNewZipJobWriter(userState)) &&
      !completedOnboardingSelector(userState)
    ) {
      dispatch(fetchOnboarding(userState.loggedInUserID))
    }

    const snippetFilters: SnippetFilters = {}
    if (userState.loggedInUserID) {
      snippetFilters.user_id = userState.loggedInUserID
    }

    dispatch(fetchSnippets(snippetFilters))

    const { showOrderShopping } = getFeatureFlags(user)

    if (user && showOrderShopping) {
      await dispatch(fetchOffersForUser(user.id))

      const shoppingEligibility = getState().offerReducer.shoppingEligibility

      const { showNewOrderNudges } = getFeatureFlags(getState().userReducer.getLoggedInUser())
      if (!!shoppingEligibility && showNewOrderNudges) {
        updateNudgeTrigger(shoppingEligibility, user.id)
      }
    }
  }
}

export type UserActionTypes =
  | FetchUser
  | FetchUserSuccess
  | FetchUserFailure
  | UpdateUserInfo
  | UpdateUserInfoSuccess
  | UpdateUserInfoFailure
  | EndUserOnboardingSuccess
