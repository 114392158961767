import React, { useCallback, useEffect } from 'react'
import produce from 'immer'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import * as Sentry from '@sentry/browser'

import { HashMap } from '../../store/items/types'

type RefType = React.MutableRefObject<HTMLDivElement | null>

interface UseScrollToMessageReturn {
  currentMessage: number | null
  scrollToMessage: (messageID: number, behavior?: ScrollOptions['behavior']) => Promise<boolean>
  setMessageRef: (messageID: number, ref: RefType) => void
  setCurrentMessage: (messageID: number | null) => void
  getMessageState: (messageID: number) => MessageState | null
  expandAllMessages: () => void
  collapseAllMessages: () => void
  toggleMessageExpansion: (messageID: number) => void
  expandedMessageCount: number
}

interface AddMessageAction {
  type: 'ADD_MESSAGE'
  messageID: number
  ref: RefType
}

interface ExpandMessageAction {
  type: 'TOGGLE_MESSAGE_EXPANSION'
  messageID: number
}

interface CollapseAllMessages {
  type: 'COLLAPSE_ALL_MESSAGES'
}

interface ExpandAllMessages {
  type: 'EXPAND_ALL_MESSAGES'
}

interface UnsetCurrentMessage {
  type: 'UNSET_CURRENT_MESSAGE'
}

type Actions = AddMessageAction | ExpandMessageAction | CollapseAllMessages | ExpandAllMessages | UnsetCurrentMessage

interface MessageState {
  ref: RefType | null
  expanded: boolean
}

interface State {
  messages: HashMap<MessageState>
  currentMessage: number | null
}

const initialState: State = {
  messages: {},
  currentMessage: null,
}

function refsReducer(state: State, action: Actions): State {
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_MESSAGE':
        draft.messages[action.messageID] = {
          // @ts-ignore
          ref: action.ref,
          expanded: draft.messages[action.messageID] ? draft.messages[action.messageID].expanded : false,
        }
        break

      case 'TOGGLE_MESSAGE_EXPANSION':
        // If this isn't already set, we should create the item in an expanded state
        if (!draft.messages[action.messageID]) {
          draft.messages[action.messageID] = {
            ref: null,
            expanded: true,
          }
        } else {
          const { messageID } = action
          const toBeExpanded = !state.messages[messageID].expanded
          draft.messages[messageID].expanded = toBeExpanded

          if (toBeExpanded) {
            draft.currentMessage = messageID
          }
        }
        break

      case 'EXPAND_ALL_MESSAGES':
      case 'COLLAPSE_ALL_MESSAGES':
        Object.values(draft.messages).forEach(m => (m.expanded = action.type === 'EXPAND_ALL_MESSAGES'))
        break

      case 'UNSET_CURRENT_MESSAGE':
        draft.currentMessage = null
        break
    }
  })
}

export const useScrollToMessage = (): UseScrollToMessageReturn => {
  const [currentMessage, setCurrentMessage] = React.useState<number | null>(null)
  const [state, dispatch] = React.useReducer(refsReducer, initialState)
  const expandedMessageCount = Object.values(state.messages).filter(m => m.expanded).length

  const scrollToMessage = useCallback(
    async (messageID: number, behavior: ScrollOptions['behavior'] = 'auto') => {
      const message = state.messages[messageID]

      if (message && message.ref && message.ref.current) {
        Sentry.addBreadcrumb({
          category: 'messaging',
          message: JSON.stringify({
            msg: 'Scrolling to message',
            messageID,
            windowDimensions: [window.innerWidth, window.innerHeight],
            scrollY: window.scrollY,
          }),
          level: Sentry.Severity.Info,
        })

        // Scroll the message into view in the window...
        await scrollIntoView(message.ref.current, {
          behavior,
          block: 'start',
          inline: 'start',
        })

        // ...add offest it by the sticky header.
        window.scrollBy(0, -60)

        Sentry.addBreadcrumb({
          category: 'messaging',
          message: JSON.stringify({
            msg: 'Scolled to message',
            messageID,
            windowDimensions: [window.innerWidth, window.innerHeight],
            scrollY: window.scrollY,
          }),
          level: Sentry.Severity.Info,
        })

        return true
      }

      return false
    },
    [state.messages]
  )

  const setMessageRef = useCallback(
    (messageID: number, ref: RefType) => {
      if (state.messages[messageID]) {
        return
      }

      dispatch({
        type: 'ADD_MESSAGE',
        messageID,
        ref,
      })
    },
    [state.messages]
  )

  // When we expand a message, we scroll to it after it opens
  const toggleMessageExpansion = useCallback(
    (messageID: number) => {
      dispatch({
        type: 'TOGGLE_MESSAGE_EXPANSION',
        messageID,
      })

      Sentry.addBreadcrumb({
        category: 'messaging',
        message: JSON.stringify({
          msg: 'Toggled message expansion',
          messageID,
        }),
        level: Sentry.Severity.Info,
      })
    },
    [dispatch]
  )

  const expandAllMessages = React.useCallback(() => dispatch({ type: 'EXPAND_ALL_MESSAGES' }), [dispatch])
  const collapseAllMessages = React.useCallback(() => dispatch({ type: 'COLLAPSE_ALL_MESSAGES' }), [dispatch])
  const getMessageState = React.useCallback(
    (messageID: number) => {
      if (!state) {
        return null
      }
      return state.messages[messageID] || null
    },
    [state]
  )

  // We set the currentMessage when we expand a message. We check to see if it's set and scroll to it until we get it.
  useEffect(() => {
    if (state.currentMessage && scrollToMessage(state.currentMessage)) {
      dispatch({ type: 'UNSET_CURRENT_MESSAGE' })
    }
  }, [state.currentMessage, dispatch, scrollToMessage])

  return {
    currentMessage,
    scrollToMessage,
    setMessageRef,
    setCurrentMessage,
    getMessageState,
    toggleMessageExpansion,
    expandAllMessages,
    collapseAllMessages,
    expandedMessageCount,
  }
}

export default useScrollToMessage
