import { ClientAIDraftState } from './reducer'

export const getIsAIDraftExists = (state: ClientAIDraftState, clientID: number): boolean | null => {
  try {
    return state.aiDraft[clientID]?.isAIDraftExists ?? null
  } catch (e) {
    return null
  }
}

export const getJobRoles = (state: ClientAIDraftState): string[] => {
  try {
    return state.jobRoles ?? []
  } catch (e) {
    return []
  }
}

export const getIsAIFeedbackExists = (state: ClientAIDraftState, clientID: number): boolean | null => {
  try {
    return state.aiDraft[clientID]?.isAIFeedbackExists ?? null
  } catch (e) {
    return null
  }
}

export const getAIDraftMagicLink = (state: ClientAIDraftState, clientID: number): string | null => {
  try {
    return state.aiDraft[clientID]?.aiDraftMagicLink ?? null
  } catch (e) {
    return null
  }
}
