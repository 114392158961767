import React, { useMemo, useState } from 'react'
import { useEffectOnce } from 'react-use'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Tooltip from '@material-ui/core/Tooltip'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { fetchClientWorkHistory } from '../../../store/autoresearch/actions'
import { getWorkHistory, getWorkHistoryMeta } from '../../../store/autoresearch/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../store'
import { LoadedLoadingErrorState } from '../../../utils/state'
import Loader from '../../common/Loader'
import Briefcase from '../../../assets/svgs/Briefcase.svg'
import StarICon from '../../../assets/svgs/suggestionStar.svg'
import i18n from '../../../i18n'
import { WorkHistory as IWorkHistory } from '../../../store/autoresearch/types'
import { HashMap } from '../../../utils/HashMap'

const useStyles = makeStyles(() =>
  createStyles({
    workHistory: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '.7rem',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 600,
      width: '9.0625rem',
    },
    briefcase: {
      fontSize: '1.3125rem',
      width: '1.625rem',
      height: '1.25rem',
      color: '#0DA1EC',
      marginTop: '.125rem',
    },
    positions: {
      fontSize: '.875rem,',
      marginTop: '.25rem',
      color: '#656E83',
    },
    roles: {
      backgroundColor: '#F7F9FC',
      border: '.0625rem solid #E4E8F0',
      padding: '.625rem 1rem',
      fontSize: '.875rem',
      fontWeight: 400,
      color: '#495163',
      textTransform: 'none',
      margin: '0rem .6875rem .8125rem 0rem',
    },
    selectRole: {
      backgroundColor: '#F7F9FC',
      color: '#1E2532',
      fontSize: '.875rem',
      fontWeight: 600,
      textTransform: 'none',
      margin: '0rem .6875rem .8125rem 0rem',
      border: '.0625rem solid #E4E8F0',
      padding: '.625rem 1rem',
    },
    hr: {
      background: '#d7dbe1',
      width: '100%',
      height: '.0625rem',
      border: 0,
      color: '#E4E8F0',
      margin: '1.5rem 0 1.5rem 0',
    },
    tooltip: {
      fontSize: '.9375rem',
    },
    programTitle: {
      fontSize: '1.50rem',
      marginBottom: '1rem',
      fontWeight: 600,
    },
    programSubTitle: {
      fontSize: '1.0625rem',
      marginBottom: '0.5rem',
      fontWeight: 600,
    },
    subTitleText: {
      fontSize: '1.0625rem',
      marginBottom: '2.25rem',
    },
    suggestedDataContainer: {
      backgroundColor: '#0DA1EC0F',
      borderRadius: '1.25rem',
      padding: '1.5625rem 1rem',
    },
    suggestedDataTitleBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1.5625rem',
    },
    suggestedDataText: {
      marginLeft: '0.5rem',
      fontWeight: 600,
    },
    jobDutiesList: {
      marginTop: '1.5625rem',
      fontSize: '.9375rem',
      fontWeight: 'bold',
    },
    clientInfoTitle: {
      marginTop: '2.25rem',
      fontWeight: 600,
    },
    clientInfoDetail: {
      display: 'flex',
      marginTop: '1.75rem',
      borderRadius: '1.25rem',
      marginBottom: '0.5rem',
      fontWeight: 600,
    },
    listItem: {
      fontSize: '.9375rem',
      whiteSpace: 'pre-line',
      marginLeft: '1.5rem',
    },
    question: {
      marginTop: '1.5rem',
      marginBottom: '0.75rem',
      fontWeight: 600,
      fontSize: '.9375rem',
    },
  })
)

interface Props {
  clientID: number
}

const WorkHistoryDetails: React.FC<Props> = ({ clientID }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [selectedRoleIndex, setSelectedRoleIndex] = useState<number>(0)

  const workHistoryData = useSelector<AppState, HashMap<IWorkHistory>>(
    state => getWorkHistory(state.autoResearchReducer, clientID) ?? {}
  )

  const meta = useSelector<AppState, LoadedLoadingErrorState>(state =>
    getWorkHistoryMeta(state.autoResearchReducer, clientID)
  )

  useEffectOnce(() => {
    if (!meta || (!meta.loaded && !meta.isLoading)) {
      dispatch(fetchClientWorkHistory(clientID))
    }
  })

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const sortedWorkHistory = useMemo(
    () =>
      Object.values(workHistoryData).sort((a, b) => {
        const aEndDate = Number(a?.end_date ? new Date(a.end_date).getTime() : null)
        const bEndDate = Number(b?.end_date ? new Date(b.end_date).getTime() : null)
        const aStartDate = Number(a?.start_date ? new Date(a.start_date).getTime() : null)
        const bStartDate = Number(b?.start_date ? new Date(b.start_date).getTime() : null)
        return aEndDate !== bEndDate ? bEndDate - aEndDate : bStartDate - aStartDate
      }),
    [workHistoryData]
  )

  const selectedJob = useMemo(() => {
    return sortedWorkHistory[selectedRoleIndex]
  }, [selectedRoleIndex, sortedWorkHistory])

  const getDate = (start: string, end = '') => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const jobDuration = {
      jobStartYear: Number.isNaN(startDate.getFullYear()) ? '' : startDate.getFullYear(),
      jobStartMonth: Number.isNaN(startDate.getMonth()) ? '' : months[startDate.getMonth()],
      jobEndYear: Number.isNaN(endDate.getFullYear()) ? '' : endDate.getFullYear(),
      jobEndMonth: Number.isNaN(endDate.getMonth()) ? '' : months[endDate.getMonth()],
    }
    return jobDuration
  }

  if (!!meta && meta.isLoading) {
    return <Loader fullPage={true} />
  }

  return sortedWorkHistory.length ? (
    <Box>
      <Typography className={classes.workHistory}>
        <Typography className={classes.title}>{i18n.t('order__page__tab__title__work_history')}</Typography>
        <img src={Briefcase} className={classes.briefcase} alt="" />
        <Typography className={classes.positions}>
          {sortedWorkHistory.length} {i18n.t('order__page__work_history__positions')}
        </Typography>
      </Typography>

      <Typography style={{ fontSize: '.9375rem' }}>
        {i18n.t('order__page__work_history__description')}.{' '}
        <Typography style={{ fontWeight: 500 }} component="span">
          {i18n.t('order__page__work_history__select_a_role')}{' '}
        </Typography>
        {i18n.t('order__page__work_history__to_view_a_role')}.
      </Typography>

      <Grid style={{ marginTop: '.9rem' }} container>
        {sortedWorkHistory.map((role, index) => (
          <Grid item>
            <Button
              className={selectedRoleIndex === index ? classes.selectRole : classes.roles}
              onClick={() => setSelectedRoleIndex(index)}
            >
              {role?.title}&nbsp;{' '}
              {`${getDate(role.start_date, role?.end_date).jobStartYear} - ${getDate(role.start_date, role?.end_date)
                .jobEndYear || 'Present'}`}
            </Button>
          </Grid>
        ))}
      </Grid>
      <hr className={classes.hr} />
      {selectedJob ? (
        <Box>
          <Typography className={classes.programTitle}>{selectedJob?.title}</Typography>
          <Typography className={classes.programSubTitle}>{selectedJob?.org_name}</Typography>
          <Typography className={classes.subTitleText}>{`${
            getDate(selectedJob?.start_date, selectedJob?.end_date).jobStartMonth
          }
        ${getDate(selectedJob?.start_date, selectedJob?.end_date).jobStartYear} - ${
            getDate(selectedJob?.start_date, selectedJob?.end_date).jobEndMonth
          } ${getDate(selectedJob?.start_date, selectedJob?.end_date).jobEndYear || 'Present'}`}</Typography>
          {selectedJob.auto_research_suggestions?.length ||
          selectedJob.auto_research_skill_suggestions?.soft_skills?.length ||
          selectedJob.auto_research_skill_suggestions?.hard_skills?.length ? (
            <Box className={classes.suggestedDataContainer}>
              <Box className={classes.suggestedDataTitleBox}>
                <Box style={{ display: 'flex' }}>
                  <img alt="" src={StarICon} />
                  <Typography className={classes.suggestedDataText}>
                    {i18n.t('order__page__work_history__suggested_data')}
                  </Typography>
                </Box>
                <Tooltip
                  title={i18n.t('order__page__work_history__suggested_data_tooltip') as string}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <HelpOutlineIcon style={{ fill: '#0DA1EC' }} fontSize="small" />
                </Tooltip>
              </Box>
              <Box>
                {selectedJob.auto_research_skill_suggestions?.soft_skills?.length ? (
                  <Typography style={{ fontSize: '.9375rem', marginBottom: '0.5rem' }}>
                    <strong>{i18n.t('order__page__work_history__soft_skills')}:</strong>{' '}
                    {selectedJob.auto_research_skill_suggestions.soft_skills.join(', ')}
                  </Typography>
                ) : null}

                {selectedJob.auto_research_skill_suggestions?.hard_skills?.length ? (
                  <Typography style={{ fontSize: '.9375rem' }}>
                    <strong>{i18n.t('order__page__work_history__hard_skills')}:</strong>{' '}
                    {selectedJob.auto_research_skill_suggestions.hard_skills.join(', ')}
                  </Typography>
                ) : null}
              </Box>
              <Box>
                {selectedJob.auto_research_suggestions?.length ? (
                  <Typography variant={'body1'} component={'ul'} className={classes.jobDutiesList}>
                    {i18n.t('order__page__work_history__job_duty_recommendations')}:
                    {selectedJob.auto_research_suggestions.map((jobDuty: string) => (
                      <Typography component={'li'} className={classes.listItem}>
                        {jobDuty}
                      </Typography>
                    ))}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          ) : null}

          {selectedJob.proudest_achievements?.length ? (
            <Typography variant={'body1'} component={'ul'} className={classes.question}>
              {i18n.t('order__page__work_history__onboarding_questionnaire')}
              {selectedJob.proudest_achievements.map(questionnaire => (
                <Typography component={'li'} className={classes.listItem}>
                  {questionnaire}
                </Typography>
              ))}
            </Typography>
          ) : null}

          {selectedJob.description ? (
            <Box>
              <Typography className={classes.clientInfoDetail}>
                {i18n.t('order__page__work_history__client_provided_info')}
              </Typography>
              <Box style={{ paddingLeft: '.5rem' }}>
                {selectedJob.description.split('\n').map(item => (
                  <Typography style={{ fontSize: '.9375rem' }}>{item}</Typography>
                ))}
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  ) : (
    <Typography>{i18n.t('order__page__work_history__has_no_data')}</Typography>
  )
}

export default WorkHistoryDetails
