import { useCallback, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import formatISO from 'date-fns/formatISO'
import parseISO from 'date-fns/parseISO'
import { useParams } from 'react-router-dom'

import { AppState } from '../../store'
import { Note, NoteContexts, UpdateNotePayload } from '../../store/notes/types'
import { getNoteByIDForContext, getNotesForUseNotes } from '../../store/notes/selectors'
import {
  createNote,
  CreateNoteFunctionSignature,
  fetchUserNotes,
  deleteNote as reduxDeleteNote,
  updateNote,
} from '../../store/notes/actions'
import { NoteFormPayload } from './NoteForm'

interface UseNotes {
  notes: Note[]
  selectedNote: Note | null
  saveNote: (payload: NoteFormPayload) => void
  isOpen: boolean
  openDrawer: () => void
  closeDrawer: () => void
  selectNote: (noteID: number) => void
  addNote: () => void
  deleteSelectedNote: () => void
  completeSelectedNote: (payload: NoteFormPayload) => void
  contextID: number | null
  isFormOpen: boolean
  closeForm: () => void
}

interface State {
  currentNoteID: number | null
  isOpen: boolean
  isFormOpen: boolean
}

interface SetNoteID {
  type: 'SET_NOTE_ID'
  noteID: number | null
  isFormOpen: boolean
}

interface ToggleDrawer {
  type: 'TOGGLE_DRAWER'
  isOpen: boolean
}

interface ToggleForm {
  type: 'TOGGLE_FORM'
  isFormOpen: boolean
}

type Actions = SetNoteID | ToggleDrawer | ToggleForm

const initialState: State = {
  currentNoteID: null,
  isOpen: false,
  isFormOpen: true,
}

function notesReducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'TOGGLE_DRAWER':
      state = {
        ...state,
        isOpen: action.isOpen,
      }
      break

    case 'SET_NOTE_ID':
      if (action.noteID !== state.currentNoteID) {
        state = {
          ...state,
          currentNoteID: action.noteID,
          isFormOpen: action.isFormOpen,
        }
      }
      break

    case 'TOGGLE_FORM':
      state = {
        ...state,
        isFormOpen: action.isFormOpen,
      }
  }

  return state
}

const useNotes = (context: NoteContexts): UseNotes => {
  // Hooks
  const { clientID } = useParams()
  const contextID = clientID ? parseInt(clientID) : null
  const dispatch = useDispatch()
  const [state, localDispatch] = useReducer(notesReducer, initialState)

  // Selected state
  const userID = useSelector<AppState, number | null>(state => state.userReducer.loggedInUserID)
  const notes = useSelector<AppState, Note[]>(
    state => getNotesForUseNotes(state.notesReducer, { context, contextID, userID }),
    isEqual
  )
  const selectedNote = useSelector<AppState, Note | null>(
    s => getNoteByIDForContext(s.notesReducer, context, state.currentNoteID),
    isEqual
  )

  // Callbacks
  const selectNote = useCallback((noteID: number) => localDispatch({ type: 'SET_NOTE_ID', noteID, isFormOpen: true }), [
    localDispatch,
  ])

  const addNote = useCallback(() => localDispatch({ type: 'SET_NOTE_ID', noteID: null, isFormOpen: true }), [
    localDispatch,
  ])

  const noteFormPayload2UpdateNote = useCallback(
    (payload: NoteFormPayload): Partial<UpdateNotePayload> => {
      const data: Partial<UpdateNotePayload> = {
        note: payload.note,
      }

      if (selectedNote) {
        data.expiration_date = selectedNote.expiration_date
        data.completion_date = selectedNote.completion_date || null
      }

      if ('expirationDate' in payload) {
        data.expiration_date = payload.expirationDate
          ? formatISO(parseISO(`${payload.expirationDate} ${payload.expirationTime || ''}`))
          : null
      }

      return data
    },
    [selectedNote]
  )

  const saveNote = useCallback(
    async (payload: NoteFormPayload) => {
      if (selectedNote) {
        dispatch(
          updateNote(
            selectedNote.context,
            selectedNote.context_id,
            selectedNote.id,
            noteFormPayload2UpdateNote(payload)
          )
        )
      } else {
        const resp = await dispatch(
          createNote({
            context: NoteContexts.userClient,
            contextID: payload.contextID,
            ...noteFormPayload2UpdateNote(payload),
          } as CreateNoteFunctionSignature)
        )
        // @ts-ignore
        selectNote(resp.payload.data.id)
      }
    },
    [dispatch, selectedNote, noteFormPayload2UpdateNote, selectNote]
  )

  const deleteSelectedNote = useCallback(() => {
    if (selectedNote) {
      dispatch(reduxDeleteNote(selectedNote.context, selectedNote.context_id, selectedNote.id))
    }
  }, [dispatch, selectedNote])

  const completeSelectedNote = useCallback(
    (payload: NoteFormPayload) => {
      if (selectedNote) {
        dispatch(
          updateNote(selectedNote.context, selectedNote.context_id, selectedNote.id, {
            ...noteFormPayload2UpdateNote(payload),
            // Why not use toISOString? Because it converts to UTC and this messes up the timestamp in the DB.
            completion_date: formatISO(new Date()),
          })
        )
      }
    },
    [dispatch, selectedNote, noteFormPayload2UpdateNote]
  )

  const openDrawer = useCallback(() => localDispatch({ type: 'TOGGLE_DRAWER', isOpen: true }), [localDispatch])

  const closeDrawer = useCallback(() => {
    localDispatch({ type: 'TOGGLE_DRAWER', isOpen: false })
    localDispatch({ type: 'SET_NOTE_ID', noteID: null, isFormOpen: true })
  }, [localDispatch])

  const closeForm = useCallback(() => localDispatch({ type: 'TOGGLE_FORM', isFormOpen: false }), [localDispatch])

  // Effects
  useEffect(() => {
    dispatch(fetchUserNotes())
  }, [dispatch])

  return {
    notes,
    saveNote,
    openDrawer,
    closeDrawer,
    isOpen: state.isOpen,
    selectNote,
    selectedNote,
    addNote,
    deleteSelectedNote,
    completeSelectedNote,
    contextID,
    isFormOpen: state.isFormOpen,
    closeForm,
  }
}

export default useNotes
