import InfoIcon from '@material-ui/icons/Info'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import UploadsV3Styles from '../UploadsV3/styles'
import Tooltip from '@material-ui/core/Tooltip'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { createStyles } from '@material-ui/core'

export interface InfoTooltipProps {
  text: string
}

const useStyles = makeStyles(() =>
  createStyles({
    infoIcon: {
      color: '#b7c2d8',
      size: '1.1rem',
    },
  })
)

const StyledTooltip = withStyles({
  tooltip: {
    color: '#3c4a66',
    backgroundColor: 'white',
    fontSize: '.93rem',
    padding: '.8rem 1.2rem .8rem 1.2rem',
    boxShadow: UploadsV3Styles.autoQAResults.group.boxShadow,
    border: UploadsV3Styles.autoQAResults.group.border,
    maxWidth: 420,
  },
  arrow: {
    color: 'white',
  },
})(Tooltip)

const InfoTooltip: React.FC<InfoTooltipProps> = ({ text }) => {
  const classes = useStyles()

  return (
    <StyledTooltip arrow={true} disableFocusListener disableTouchListener placement="right" title={text}>
      <InfoIcon className={classes.infoIcon} />
    </StyledTooltip>
  )
}

export default InfoTooltip
