import React from 'react'
import Box from '@material-ui/core/Box'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Pie } from 'react-chartjs-2'

import i18n from '../../i18n'
import CardWrapper from './CardWrapper'
import { UserIndustryCount } from '../../store/dashboard/types'
import DashboardStyles from './Styles'

interface Props {
  industries: UserIndustryCount[] | null
}

const backgroundColors = ['#08133b', '#51769c', '#b6e9ff', '#71c6f6', '#0da1ed']

const transformIndustriesForChart = (industries: UserIndustryCount[] | null) => {
  if (!industries || !industries.length) {
    return null
  }

  return {
    labels: industries.map(industry => i18n.t(`industry__industry${industry.industry_id}`)),
    datasets: [
      {
        data: industries.map(industry => industry.percent),
        backgroundColor: backgroundColors,
      },
    ],
  }
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      minHeight: '20em',
    },
    chartColumn: {
      marginTop: '1em',
      width: '45%',
    },
    legend: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      listStyle: 'none',
      width: '55%',
      maxHeight: '10em',
    },
    legendColor: {
      marginRight: '.7em',
      content: ' ',
      width: '.7em',
      height: '.7em',
      borderRadius: '50%',
      display: 'inline-block',
    },
    legendPercent: {
      float: 'right',
    },
    chartContainer: {
      marginTop: '-1em',
      marginBottom: '.5em',
    },
    [theme.breakpoints.down('sm')]: {
      chartColumn: {
        margin: DashboardStyles.card.marginMobile,
        width: DashboardStyles.fullWidth,
      },
      container: {
        flexDirection: 'column',
        minHeight: 'auto',
      },
      legend: {
        width: DashboardStyles.fullWidth,
      },
    },
  })
)

const IndustriesChart: React.FC<Props> = ({ industries }) => {
  const classes = useStyles()
  const data = transformIndustriesForChart(industries)

  return (
    <CardWrapper title={i18n.t('dashboard__industriesChart__title')} id="dashboard-industries-chart">
      <Box className={classes.container}>
        {!!data && !!industries && (
          <Box className={classes.chartColumn}>
            <Box className={classes.chartContainer}>
              <Pie
                data={data}
                options={{
                  legend: { display: false },
                  maintainAspectRatio: false,
                  tooltips: {
                    displayColors: false,
                    callbacks: {
                      label: point =>
                        i18n.t('dashboard__industriesChart__toolTip', {
                          count: industries[point.index || 0].order_count,
                        }),
                    },
                  },
                }}
              />
            </Box>
          </Box>
        )}
        <ul className={classes.legend}>
          {!data &&
            backgroundColors.map(color => (
              <li key={color}>
                <span className={classes.legendColor} style={{ backgroundColor: color }} />-
                <span className={classes.legendPercent}>0%</span>
              </li>
            ))}
          {!!data &&
            data.labels.map((industry, idx) => (
              <li key={industry}>
                <span
                  className={classes.legendColor}
                  style={{ backgroundColor: data.datasets[0].backgroundColor[idx] }}
                />
                {industry}
                <span className={classes.legendPercent}>{data.datasets[0].data[idx]}%</span>
              </li>
            ))}
        </ul>
      </Box>
    </CardWrapper>
  )
}

export default IndustriesChart
