import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import ListSubheader from '@material-ui/core/ListSubheader'
import MenuItem from '@material-ui/core/MenuItem'
import RadioGroup from '@material-ui/core/RadioGroup'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import i18n from '../../../i18n'
import CustomRadio from '../../common/CustomRadio'
import Button from '../../common/Button'
import { useDispatch, useSelector } from 'react-redux'
import { fetchJobRoles, generateAIDraft } from '../../../store/aiDraft/actions'
import { AppState } from '../../../store'
import { getJobRoles } from '../../../store/aiDraft/selectors'

const useStyles = makeStyles({
  container: {
    padding: '0px 20px',
  },
  question: {
    paddingBottom: '10px',
  },
  closeButton: {
    alignSelf: 'flex-end',
  },
  submitButton: {
    alignSelf: 'flex-end',
    margin: '20px',
  },
  radioGroup: {
    flexDirection: 'row',
  },
  input: {
    backgroundColor: '#fff',
  },
  select: {
    minWidth: '120px',
  },
  loadingContainer: {
    padding: '20px',
    minWidth: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loader: {
    marginBottom: '20px',
  },
  success: {
    marginBottom: '20px',
    color: '#36942f',
    fontSize: '48px',
  },
  error: {
    marginBottom: '20px',
    color: '#ff0000',
    fontSize: '48px',
  },
})

enum RadioOptions {
  YES = 'yes',
  NO = 'no',
}

export interface AIDraftPopupProps {
  clientID: number
  onClose: () => void
}

const AIDraftPopup: React.FC<AIDraftPopupProps> = ({ clientID, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [needChange, setNeedChange] = useState('')
  const [occupation, setOccupation] = useState('')
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [menuPosition, setMenuPosition] = React.useState({ top: 200, left: 200 })

  const jobRoles = useSelector<AppState, string[]>(state => getJobRoles(state.clientAIDraftReducer))

  useEffect(() => {
    const element = document.getElementById('select-occupation')
    const x = element?.getBoundingClientRect()?.x
    const y = element?.getBoundingClientRect()?.y
    setMenuPosition({ top: y ?? 200, left: x ?? 200 })
  }, [needChange])

  useEffect(() => {
    dispatch(fetchJobRoles())
  }, [dispatch])

  const handleSelectClose = useCallback(() => {
    setSearchText('')
  }, [])

  const handleNeedChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNeedChange(e.target.value)
  }, [])

  const handleOccupationSelect = useCallback((e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setOccupation(e.target.value as string)
  }, [])

  const displayedOptions = useMemo(
    () => jobRoles.filter(option => option.toLowerCase().includes(searchText.toLowerCase())),
    [jobRoles, searchText]
  )

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true)
      const carrerChange = needChange === RadioOptions.YES ? occupation : ''
      await dispatch(generateAIDraft(clientID, carrerChange))
      const aiResumeEnqueued = JSON.parse(localStorage.getItem('aiResumeEnqueued') as string) ?? {}
      const currentDate = new Date()
      currentDate.setHours(currentDate.getHours() + 1)
      localStorage.setItem('aiResumeEnqueued', JSON.stringify({ ...aiResumeEnqueued, [clientID]: currentDate }))
      setSuccess(true)
    } catch (error) {
      setError(
        error?.error?.response?.data?.error ? i18n.t('aiDraft_popup_error') : i18n.t('aiDraft_popup_unknown_error')
      )
    } finally {
      setLoading(false)
    }
  }, [dispatch, needChange, occupation, clientID])

  return (
    <Dialog onClose={onClose} aria-labelledby="ai-draft-popup" open={true}>
      <IconButton
        className={classes.closeButton}
        type="button"
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      {loading || error || success ? (
        <Box className={classes.loadingContainer}>
          {success ? (
            <>
              <CheckCircleOutlineIcon className={classes.success} />
              <Typography>{i18n.t('aiDraft_popup_success')}</Typography>
            </>
          ) : error ? (
            <>
              <ErrorOutlineIcon className={classes.error} />
              <Typography>{error}</Typography>
            </>
          ) : loading ? (
            <>
              <CircularProgress className={classes.loader} />
              <Typography>{i18n.t('aiDraft_popup_loading')}</Typography>
            </>
          ) : null}
        </Box>
      ) : (
        <>
          <Box className={classes.container}>
            <Box className={classes.question}>
              <Typography>{i18n.t('aiDraft_popup_ques_needChange')}</Typography>
              <RadioGroup className={classes.radioGroup} value={needChange} onChange={handleNeedChange}>
                <FormControlLabel value={RadioOptions.YES} control={<CustomRadio />} label={RadioOptions.YES} />
                <FormControlLabel value={RadioOptions.NO} control={<CustomRadio />} label={RadioOptions.NO} />
              </RadioGroup>
            </Box>
            {needChange === RadioOptions.YES ? (
              <Box className={classes.question}>
                <Typography>{i18n.t('aiDraft_popup_ques_occupation')}</Typography>
                <Select
                  id="select-occupation"
                  labelId="select-occupation-label"
                  value={occupation}
                  onChange={handleOccupationSelect}
                  placeholder="select occupation"
                  className={classes.select}
                  MenuProps={{
                    autoFocus: false,
                    anchorReference: 'anchorPosition',
                    anchorPosition: menuPosition,
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    style: { maxHeight: '600px' },
                    MenuListProps: { style: { border: '1px solid #ccc}' } },
                  }}
                  onClose={handleSelectClose}
                  renderValue={() => occupation}
                >
                  <ListSubheader>
                    <TextField
                      size="small"
                      autoFocus
                      placeholder={i18n.t('aiDraft_popup_ques_occupation_placeholder')}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      className={classes.input}
                      onChange={e => setSearchText(e.target.value)}
                      onKeyDown={e => {
                        if (e.key !== 'Escape') {
                          // Prevents autoselecting item while typing (default Select behaviour)
                          e.stopPropagation()
                        }
                      }}
                      onClick={e => e.stopPropagation()}
                    />
                  </ListSubheader>
                  {displayedOptions.map(item => (
                    <MenuItem value={item} key={`name-${item}`}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            ) : null}
          </Box>
          <Button
            className={classes.submitButton}
            type="primary"
            disabled={!(needChange === RadioOptions.NO || (needChange === RadioOptions.YES && occupation))}
            onClick={onSubmit}
            showLoader={false}
            area-label="submit_question"
          >
            {i18n.t('aiDraft_popup_submit')}
          </Button>
        </>
      )}
    </Dialog>
  )
}

export default AIDraftPopup
