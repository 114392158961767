import React, { useState } from 'react'
import classNames from 'classnames'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import AttachmentsIcon from '@material-ui/icons/AttachFile'
import IconButton from '@material-ui/core/IconButton'
import SeenIcon from '@material-ui/icons/Check'
import ReplyIcon from '@material-ui/icons/Reply'
import ReadIcon from '@material-ui/icons/Visibility'
import UnreadIcon from '@material-ui/icons/VisibilityOff'
import ContractIcon from '@material-ui/icons/KeyboardArrowUp'
import Tooltip from '@material-ui/core/Tooltip'

import i18n from '../../i18n'
import styles from '../../styles'
import { Message } from '../../store/messages/types'
import { Document } from '../../store/documents/types'
import { DocumentItem, DocumentItemVariants } from '../Documents/DocumentItem'
import {
  messageIsUnreadByUser,
  messageIsFromTalentInc,
  messageIsAutomated,
  messageReadByClientAt,
} from '../../selectors/messages'
import useUserInfoState from '../common/useUserInfo'
import { formatDate } from '../../utils/formatting'
import MessageText, { stripHTML } from './MessageText'

export interface ChatMessageActions {
  markMessageAsRead: (message: Message) => void
  markMessageAsUnread: (message: Message) => void
  toggleMessageRead: (message: Message) => void
}

interface ChatMessageProps {
  message: Message
  attachments?: Document[]
  isBeingRepliedTo?: boolean
  expanded?: boolean
  onReplyClick?: () => void
  setMessageRef?: (messageID: number, ref: React.MutableRefObject<HTMLDivElement | null>) => void
  toggleExpansion?: (changeStatus?: boolean) => void
  actions?: ChatMessageActions
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      position: 'relative',
      border: '1.5px solid #000',
      borderRadius: '.84375rem',
      marginBottom: '.4rem',
      backgroundColor: '#fff',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '-1.5px',
        left: '-1.5px',
        zIndex: 1,
        width: '1rem',
        height: 'calc(100% + 3px)',
        borderRadius: '0 0 0 .84375rem',
        backgroundColor: '#000',
      },
      '&.client': {
        '&::before': { backgroundColor: '#0099E6' },
      },
      '&.company': {
        '&::before': { backgroundColor: '#f74325' },
      },
      '&.read': {
        borderColor: '#bcc9d6',
      },
    },
    head: {
      display: 'flex',
      alignItems: 'center',
      padding: '.75rem 1rem .75rem 2rem',
      cursor: 'pointer',
      '&.expanded': {
        borderBottom: '1px solid #bcc9d6',
        // @TODO Remove texts ellipsis ?
        '& .texts .subtitle': { color: styles.palette.lightBlack.color },
        '& .controls': {
          '& .dateControl': { color: styles.palette.lightBlack.color },
          '& .seenControl': { marginLeft: 0, marginRight: '.5rem' },
        },
      },
      '&:not(.expanded).read': {
        opacity: 0.5,
      },
      '& .texts': {
        width: '1rem',
        flexGrow: 1,
        marginRight: '1.5rem',
        '& .title': {
          width: '100%',
          marginBottom: '.625rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#000',
          fontWeight: 600,
        },
        '& .subtitle': {
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#000',
          fontSize: '.8125rem',
          fontWeight: 500,
        },
      },
      '& .controls': {
        display: 'flex',
        alignItems: 'center',
        '& .attachmentsControl': {
          width: '2.5rem',
          height: '2.5rem',
          marginRight: '1rem',
          padding: '.5rem',
          borderRadius: '.73125rem',
          backgroundColor: '#f1f2f4',
          color: '#bcc9d6',
        },
        '& .infoContainer': {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '8rem',
          '& .dateControl': {
            whiteSpace: 'nowrap',
            color: '#000',
            fontSize: '.8125rem',
            fontWeight: 500,
          },
          '& .seenControl': {
            position: 'relative',
            marginLeft: '.5rem',
            marginRight: '-.5rem',
            '&.active .seenControl-icon': { color: '#00a2f4' },
            '& .seenControl-icon': {
              padding: '.5rem',
              color: styles.palette.lightBlack.color,
            },
            '& .seenControl-popup': {
              position: 'absolute',
              top: '-1.5rem',
              right: '100%',
              zIndex: 2,
              marginRight: '.5rem',
              padding: '.8rem .625rem',
              borderRadius: '.125rem',
              backgroundColor: '#fff',
              boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
              color: styles.palette.lightBlack.color,
              '& *': { whiteSpace: 'nowrap', fontSize: '.8125rem' },
              '& .title': {
                margin: '0 .4rem',
                paddingBottom: '.3rem',
                borderBottom: '1px solid #bcc9d6',
                textAlign: 'center',
              },
              '& .entry': {
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '.4rem',
                '& > :first-child': { marginRight: '1rem' },
              },
            },
          },
        },
        '& .togglesContainer': {
          display: 'flex',
          alignItems: 'center',
          marginLeft: '1rem',
          '& .replyControl': {
            padding: '.5rem',
            '&.active': {
              color: '#00a2f4',
            },
          },
          '& .readControl': {
            padding: '.5rem',
            color: styles.palette.lightBlack.color,
          },
          '& .contractControl': {
            padding: '.5rem',
            color: styles.palette.lightBlack.color,
          },
        },
      },
      '@media (max-width: 768px)': {
        flexDirection: 'column',
        padding: '.5rem .5rem .2rem 1.5rem',
        '& .texts': {
          width: '100%',
          marginRight: 0,
          '& .title': { marginBottom: '.2rem !important', fontSize: '13px' },
          '& .subtitle': { fontSize: '12px !important' },
        },
        '& .controls': {
          width: '100%',
          minHeight: '27.7px',
          justifyContent: 'flex-end',
          '& .attachmentsControl': {
            width: '21.33px !important',
            height: '21.33px !important',
            padding: '0 !important',
            backgroundColor: 'transparent !important',
          },
          '& .infoContainer': {
            flexGrow: 1,
            width: 'auto !important',
            '& .dateControl': { fontSize: '12px !important' },
            '& .seenControl': {
              '& .seenControl-icon': { padding: '.2rem !important' },
            },
          },
          '& .togglesContainer': {
            '& .replyControl, & .readControl, & .contractControl': {
              marginLeft: '.5rem',
              padding: '.2rem !important',
            },
          },
        },
        '&:not(.expanded)': {
          '& .seenControl': {
            marginLeft: '.2rem !important',
            marginRight: '0 !important',
          },
        },
        '&.expanded': {
          '& .infoContainer': { justifyContent: 'flex-start !important' },
          '& .seenControl .seenControl-popup': {
            left: '100%',
            right: 'auto !important',
            marginLeft: '.5rem',
            marginRight: '0 !important',
          },
        },
      },
    },
    content: {
      padding: '.75rem 1rem .75rem 2rem',
      wordBreak: 'break-word',
    },
    innerText: {
      fontSize: '.8125rem',
      '& p, ul, ol': {
        margin: '1rem 0',
        lineHeight: '1.4',
        '&:blank': {
          display: 'none',
        },
      },
      '& p:last-child': { marginBottom: 0 },
      '& pre': {
        fontFamily: styles.fonts.primary.family,
        whiteSpace: 'pre-line',
      },
      '& a': { textDecoration: 'underline' },
    },
    plainTextEmail: {
      whiteSpace: 'pre-line',
    },
    attachments: {
      marginTop: '.5rem',
      '& > *': {
        display: 'inline-flex',
        width: '10rem',
        marginTop: '.5rem',
        marginRight: '1rem',
        '& > :first-child': { marginBottom: 0 },
        '@media (max-width: 440px)': {
          width: '100%',
          marginRight: 0,
        },
      },
    },
    error: {
      textAlign: 'center',
    },
    errorIcon: {
      display: 'block',
      margin: '0 auto',
    },
  })
)

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  attachments = [],
  isBeingRepliedTo = false,
  expanded,
  onReplyClick = () => {},
  setMessageRef,
  toggleExpansion = () => {},
  actions,
}) => {
  const classes = useStyles()
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const { timeZone, locale } = useUserInfoState()
  const [isTogglingMessageRead, setIsTogglingMessageRead] = useState(false)
  const ref = React.useRef<HTMLDivElement | null>(null)
  const dateTimeFormat = i18n.t('messaging__message_datetime')
  const text = (message.outbound && !!message.message_text ? message.message_text : message.body)
    .replace(/(Ã‚|&zwng;&nbsp;|^&nbsp;$)/gm, '')
    .trim()
  // Some messages were saved without surrounding <pre> tags as plain text. This is a cheap guess to determine if the
  // message has HTML in it or not.
  const isPlainText = message.outbound && !text.includes('<') && !text.includes('>')
  const isAutomated = messageIsAutomated(message)
  const fromTalentInc = !isAutomated && messageIsFromTalentInc(message)
  const readableByUser = !message.outbound || fromTalentInc
  const readByUser = !messageIsUnreadByUser(message)
  const clientReadMessageAt = messageReadByClientAt(message)
  const subject = message.subject || i18n.t('messaging__noSubject')

  React.useLayoutEffect(() => {
    if (setMessageRef && isFirstLoad) {
      setMessageRef(message.id, ref)
      setIsFirstLoad(false)
    }
  }, [setMessageRef, message.id, ref, isFirstLoad, setIsFirstLoad])

  // @TODO wire up client read state
  const renderSeenControl = () => {
    if (!clientReadMessageAt) {
      return null
    }

    return (
      <Tooltip
        title={
          i18n.t('messaging__openedAt', {
            time: formatDate(clientReadMessageAt, timeZone, locale, dateTimeFormat),
          }) || ''
        }
        className="seenControl"
      >
        <SeenIcon />
      </Tooltip>
    )
  }

  return (
    <div
      className={classNames({
        [classes.container]: true,
        expanded: expanded,
        read: readByUser || (!readByUser && isTogglingMessageRead) || isAutomated,
        client: !message.outbound && !fromTalentInc,
        company: fromTalentInc,
      })}
      ref={ref}
      id={`message-${message.id}`}
    >
      <Box
        className={classNames({
          [classes.head]: true,
          expanded: expanded,
          read: readByUser,
        })}
        onClick={async () => {
          const shouldToggleState = !readByUser

          if (shouldToggleState) {
            setIsTogglingMessageRead(true)
          }

          try {
            await toggleExpansion()
          } catch (e) {}

          if (shouldToggleState) {
            setIsTogglingMessageRead(false)
          }
        }}
      >
        <Box className="texts">
          <Typography display="block" variant={'body2'} className="title">
            {!expanded ? subject : message.sender}
          </Typography>
          <Typography display="block" variant={'body2'} className="subtitle">
            {!expanded ? stripHTML(text, { stripNewlines: true, preview: true }) : subject}
          </Typography>
        </Box>
        <Box className="controls">
          {!!attachments.length && !expanded && <AttachmentsIcon className="attachmentsControl" />}
          <Box className="infoContainer">
            {/* @TODO Wire up read statuses */}
            {expanded && renderSeenControl()}
            <time dateTime={message.sent} className="dateControl">
              {formatDate(message.sent, timeZone, locale, dateTimeFormat)}
            </time>
            {/* @TODO Wire up read statuses */}
            {!expanded && renderSeenControl()}
          </Box>
          {expanded && (
            <Box className="togglesContainer">
              <Tooltip title={i18n.t('messaging__tooltip__replyToMessage') || 'Reply to Message'}>
                <IconButton
                  className={classNames({ replyControl: true, active: isBeingRepliedTo })}
                  onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    event.stopPropagation()
                    onReplyClick()
                  }}
                >
                  <ReplyIcon />
                </IconButton>
              </Tooltip>
              {readableByUser && (
                <Tooltip
                  title={
                    readByUser
                      ? i18n.t('messaging__tooltip__markUnread') || ''
                      : i18n.t('messaging__tooltip__markRead') || ''
                  }
                  onClick={async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    event.stopPropagation()

                    if (actions) {
                      toggleExpansion(false)
                      setIsTogglingMessageRead(true)
                      await actions.toggleMessageRead(message)
                      setIsTogglingMessageRead(false)
                    }
                  }}
                >
                  <span>
                    <IconButton className="readControl" disabled={isTogglingMessageRead}>
                      {readByUser || isTogglingMessageRead ? <ReadIcon /> : <UnreadIcon />}
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <IconButton
                className="contractControl"
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.stopPropagation()
                  toggleExpansion()
                }}
              >
                <ContractIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
      {expanded && (
        <Box className={classes.content}>
          <Typography
            key={`body-message-${message.id}`}
            display="block"
            variant={'body2'}
            component={'div'}
            className={classNames({
              [classes.innerText]: true,
              [classes.plainTextEmail]: isPlainText,
            })}
          >
            <MessageText html={text} />
          </Typography>
          {attachments && (
            <Box className={classes.attachments}>
              {attachments.map(attachment => (
                <DocumentItem
                  document={attachment}
                  variant={[DocumentItemVariants.Download, DocumentItemVariants.HalfHeight]}
                  key={`message-${message.id}-attachment-${attachment.id}`}
                />
              ))}
            </Box>
          )}
        </Box>
      )}
    </div>
  )
}

// As it turns out, transforming HTML and converting it to React components is very expensive. Given how important this
// component is, we only want to render once, so we only re-render when the reply button is pressed. If you have more
// props in the future that need to re-render, they much be added here.
function chatMessageIsEqual(prevProps: ChatMessageProps, nextProps: ChatMessageProps): boolean {
  // We should only re-render if the user presses the reply button or expanded/read status changes
  if (
    prevProps.isBeingRepliedTo !== nextProps.isBeingRepliedTo ||
    prevProps.expanded !== nextProps.expanded ||
    prevProps.message.statuses !== nextProps.message.statuses
  ) {
    return false
  }

  return true
}

const memoizedChatMessage = React.memo(ChatMessage, chatMessageIsEqual)
// @ts-ignore
memoizedChatMessage.whyDidYouRender = true

export default memoizedChatMessage
