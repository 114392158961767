import { AccountType, SchedulingState } from './types'
import { SCHEDULING, ScheduleInActionTypes } from './actions'
import produce from 'immer'
import {
  initialLoadingErrorState,
  LoadedLoadingErrorState,
  setErrorState,
  setStartState,
  setSuccessState,
} from '../../utils/state'

const initialLoadedLoadingErrorState: LoadedLoadingErrorState = {
  ...initialLoadingErrorState,
  loaded: false,
}

export const initialState: SchedulingState = {
  accounts: {},
  weeklyPeriods: [],
  preferences: null,
  calendarEvents: [],
  elementToken: null,
  is_available_for_scheduled_items: true,
  metaData: {
    [SCHEDULING.GENERATE_SCHEDULE]: initialLoadedLoadingErrorState,
    [SCHEDULING.SET_AVAILABILITY]: initialLoadedLoadingErrorState,
    [SCHEDULING.SET_PREFERENCES]: initialLoadedLoadingErrorState,
    [SCHEDULING.GET_PREFERENCES]: initialLoadedLoadingErrorState,
    [SCHEDULING.GET_CALENDAR_EVENTS]: initialLoadedLoadingErrorState,
    [SCHEDULING.CREATE_CALENDAR_EVENT]: initialLoadedLoadingErrorState,
    [SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN]: initialLoadedLoadingErrorState,
    [SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS]: initialLoadedLoadingErrorState,
    [SCHEDULING.DISCONNECT_CALENDAR]: initialLoadedLoadingErrorState,
  },
}

export default function schedulingReducer(
  state: SchedulingState = initialState,
  action: ScheduleInActionTypes
): SchedulingState {
  return produce(state, (draft: SchedulingState) => {
    switch (action.type) {
      case SCHEDULING.GENERATE_SCHEDULE:
        draft.metaData[SCHEDULING.GENERATE_SCHEDULE] = setStartState(state.metaData[SCHEDULING.GENERATE_SCHEDULE])
        break
      case SCHEDULING.GENERATE_SCHEDULE_SUCCESS: {
        draft.metaData[SCHEDULING.GENERATE_SCHEDULE] = setSuccessState(state.metaData[SCHEDULING.GENERATE_SCHEDULE])
        const type = action.payload.data.type
        const id = action.payload.data.id
        if (!!action.payload.data) {
          if (!draft.accounts[type]) {
            draft.accounts[type] = {}
          }
          draft.accounts[type][id] = action.payload.data
        }
        break
      }
      case SCHEDULING.GENERATE_SCHEDULE_FAIL:
        draft.metaData[SCHEDULING.GENERATE_SCHEDULE] = setErrorState(
          state.metaData[SCHEDULING.GENERATE_SCHEDULE],
          action.error
        )
        break
      case SCHEDULING.SET_AVAILABILITY:
        draft.metaData[SCHEDULING.SET_AVAILABILITY] = setStartState(state.metaData[SCHEDULING.SET_AVAILABILITY])
        break
      case SCHEDULING.SET_AVAILABILITY_SUCCESS:
        if (!!action.payload.data) {
          const data = action.payload.data
          if (!!data && !!data.weekly_periods && data.weekly_periods.length > 0) {
            draft.weeklyPeriods = data.weekly_periods
          }
          if (!!data && !!data.scheduling_preferences) {
            draft.preferences = data.scheduling_preferences
          }
          draft.metaData[SCHEDULING.SET_AVAILABILITY] = setSuccessState(state.metaData[SCHEDULING.SET_AVAILABILITY])
        }
        break
      case SCHEDULING.SET_AVAILABILITY_FAIL:
        draft.metaData[SCHEDULING.SET_AVAILABILITY] = setErrorState(
          state.metaData[SCHEDULING.SET_AVAILABILITY],
          action.error
        )
        break
      case SCHEDULING.SET_PREFERENCES:
        draft.metaData[SCHEDULING.SET_PREFERENCES] = setStartState(state.metaData[SCHEDULING.SET_PREFERENCES])
        break
      case SCHEDULING.SET_PREFERENCES_SUCCESS:
        if (!!action.payload.data) {
          const data = action.payload.data
          if (!!data && !!data.weekly_periods && data.weekly_periods.length > 0) {
            draft.weeklyPeriods = data.weekly_periods
          }
          if (!!data && !!data.scheduling_preferences) {
            draft.preferences = data.scheduling_preferences
          }
          draft.is_available_for_scheduled_items = data.is_available_for_scheduled_items
          draft.metaData[SCHEDULING.SET_PREFERENCES] = setSuccessState(state.metaData[SCHEDULING.SET_PREFERENCES])
        }
        break
      case SCHEDULING.SET_PREFERENCES_FAIL:
        draft.metaData[SCHEDULING.SET_PREFERENCES] = setErrorState(
          state.metaData[SCHEDULING.SET_PREFERENCES],
          action.error
        )
        break
      case SCHEDULING.GET_PREFERENCES:
        draft.metaData[SCHEDULING.GET_PREFERENCES] = setStartState(state.metaData[SCHEDULING.GET_PREFERENCES])
        break
      case SCHEDULING.GET_PREFERENCES_SUCCESS:
        if (!!action.payload.data) {
          const data = action.payload.data
          if (!!data && !!data.weekly_periods && data.weekly_periods.length > 0) {
            draft.weeklyPeriods = data.weekly_periods
          }
          if (!!data && !!data.scheduling_preferences) {
            draft.preferences = data.scheduling_preferences
          }
          if (!!data && data.scheduling_accounts.length > 0) {
            data.scheduling_accounts.forEach(account => {
              if (!draft.accounts[account.type]) {
                draft.accounts[account.type] = {}
              }
              draft.accounts[account.type][account.id] = account
            })
          }
          draft.is_available_for_scheduled_items = data.is_available_for_scheduled_items
          draft.metaData[SCHEDULING.GET_PREFERENCES] = setSuccessState(state.metaData[SCHEDULING.GET_PREFERENCES])
        }
        break
      case SCHEDULING.GET_PREFERENCES_FAIL:
        draft.metaData[SCHEDULING.GET_PREFERENCES] = setErrorState(
          state.metaData[SCHEDULING.GET_PREFERENCES],
          action.error
        )
        break
      case SCHEDULING.GET_CALENDAR_EVENTS:
        draft.metaData[SCHEDULING.GET_CALENDAR_EVENTS] = setStartState(state.metaData[SCHEDULING.GET_CALENDAR_EVENTS])
        break
      case SCHEDULING.GET_CALENDAR_EVENTS_SUCCESS:
        if (!!action.payload.data) {
          const data = action.payload.data
          draft.calendarEvents = data.calendar_events
          draft.metaData[SCHEDULING.GET_CALENDAR_EVENTS] = setSuccessState(
            state.metaData[SCHEDULING.GET_CALENDAR_EVENTS]
          )
        }
        break
      case SCHEDULING.GET_CALENDAR_EVENTS_FAIL:
        const status = !!action.error.response ? action.error.response.status : -1
        if (status === 404) {
          draft.metaData[SCHEDULING.GET_CALENDAR_EVENTS] = setSuccessState(
            state.metaData[SCHEDULING.GET_CALENDAR_EVENTS]
          )
          draft.metaData[SCHEDULING.GET_PREFERENCES] = setSuccessState(state.metaData[SCHEDULING.GET_PREFERENCES])
        } else {
          draft.metaData[SCHEDULING.GET_CALENDAR_EVENTS] = setErrorState(
            state.metaData[SCHEDULING.GET_CALENDAR_EVENTS],
            action.error
          )
        }
        break
      case SCHEDULING.CREATE_CALENDAR_EVENT:
        draft.metaData[SCHEDULING.CREATE_CALENDAR_EVENT] = setStartState(
          state.metaData[SCHEDULING.CREATE_CALENDAR_EVENT]
        )
        break
      case SCHEDULING.CREATE_CALENDAR_EVENT_SUCCESS:
        draft.metaData[SCHEDULING.CREATE_CALENDAR_EVENT] = setSuccessState(
          state.metaData[SCHEDULING.CREATE_CALENDAR_EVENT]
        )
        break
      case SCHEDULING.CREATE_CALENDAR_EVENT_FAIL:
        draft.metaData[SCHEDULING.CREATE_CALENDAR_EVENT] = setErrorState(
          state.metaData[SCHEDULING.CREATE_CALENDAR_EVENT],
          action.error
        )
        break
      case SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN:
        draft.metaData[SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN] = setStartState(
          state.metaData[SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN]
        )
        break
      case SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN_SUCCESS:
        if (!!action.payload.data) {
          const data = action.payload.data
          draft.elementToken = data.element_token
          draft.metaData[SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN] = setSuccessState(
            state.metaData[SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN]
          )
        }
        break
      case SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN_FAIL:
        draft.metaData[SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN] = setErrorState(
          state.metaData[SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN],
          action.error
        )
        break
      case SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS:
        draft.metaData[SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS] = setStartState(
          state.metaData[SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS]
        )
        break
      case SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS_SUCCESS:
        draft.metaData[SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS] = setSuccessState(
          state.metaData[SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS]
        )
        break
      case SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS_FAIL:
        draft.metaData[SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS] = setErrorState(
          state.metaData[SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS],
          action.error
        )
        break
      case SCHEDULING.DISCONNECT_CALENDAR:
        draft.metaData[SCHEDULING.DISCONNECT_CALENDAR] = setStartState(state.metaData[SCHEDULING.DISCONNECT_CALENDAR])
        break
      case SCHEDULING.DISCONNECT_CALENDAR_SUCCESS:
        draft.metaData[SCHEDULING.DISCONNECT_CALENDAR] = setSuccessState(state.metaData[SCHEDULING.DISCONNECT_CALENDAR])
        if (draft.accounts[AccountType.user]) {
          delete draft.accounts[AccountType.user]
        }
        break
      case SCHEDULING.DISCONNECT_CALENDAR_FAIL:
        draft.metaData[SCHEDULING.DISCONNECT_CALENDAR] = setErrorState(
          state.metaData[SCHEDULING.DISCONNECT_CALENDAR],
          action.error
        )
        break
      default:
        break
    }
  })
}
