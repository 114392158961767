import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import i18n from '../../i18n'
import Loader from '../common/Loader'
import auth0, { setAuth0Cookie } from '../../utils/auth0'
import { postLoginRedirect } from '../../utils/auth'
import { preloadApp } from '../../store/user/actions'
import { sendUserEvent } from '../../store/events/actions'
import { EventTypes } from '../../store/events/types'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      paddingTop: '7rem',
      flexDirection: 'column',
      '& > *': {
        width: '100%',
        textAlign: 'center',
      },
    },
    icon: {
      fontSize: '12rem',
    },
  })
)

export const CallbackPage: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [finishedCallback, setHasFinishedCallback] = useState(false)
  const [error, setError] = useState<string | null | undefined | object>(null)

  useEffect(() => {
    if (finishedCallback) {
      return
    }

    auth0.parseHash({ hash: window.location.hash }, (err, authResult) => {
      if (err && err.errorDescription) {
        setError(err.errorDescription)
        return
      }

      if (!authResult || !authResult.idToken || !authResult.idTokenPayload) {
        setError(i18n.t('auth__callbackErrorGeneric', { authResult: JSON.stringify(authResult) }))
        return
      }

      setAuth0Cookie(authResult)
      dispatch(preloadApp())
      dispatch(
        sendUserEvent(
          {
            event: EventTypes.UserLoggedInWithAuth0,
            variables: {},
          },
          authResult.idTokenPayload['https://experts.talentinc.com/oms_user_id'] || null
        )
      )
      setHasFinishedCallback(true)
    })
  }, [dispatch, setHasFinishedCallback, finishedCallback])

  if (finishedCallback) {
    if (postLoginRedirect()) {
      return null
    }

    return <Redirect to="/" />
  }

  if (error) {
    return (
      <Box className={classes.root}>
        <ErrorOutlineIcon color="error" fontSize="large" className={classes.icon} />
        <Typography component="h1" variant="h1">
          {i18n.t('auth__callbackErrorTitle')}
        </Typography>
        <Typography>{i18n.t('auth__callbackErrorFromProvider', { error })}</Typography>
      </Box>
    )
  }

  return <Loader fullPage={true} />
}

export default CallbackPage
