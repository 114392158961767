import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export interface MultipleCheckOptionProps {
  label: string
  index: string | number
  active: boolean
  handleChange: React.EventHandler<React.ChangeEvent<HTMLInputElement>>
  size?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      flexBasis: 0,
      flexGrow: 1,
      height: '7.375rem',
      margin: 0,
      cursor: 'pointer',
      '&.small': {
        height: '5.5rem',
        '& > :last-child': { height: '2.75rem', marginBottom: '2.75rem', fontSize: '.875rem' },
        '& > :first-child': { bottom: '1.375rem' },
      },
      '&:not(.active):hover': {},
      '&.active': {
        '& > :last-child': {
          backgroundColor: '#edf5fc',
          color: '#00a3f4',
        },
      },
      '&:not(:last-child)': {
        borderRight: '1px solid #d5d9e0',
      },
      '& > :last-child': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '3.6875rem',
        marginBottom: '3.6875rem',
        backgroundColor: '#f5f6f7',
        color: '#6f737c',
        fontSize: '1.25rem',
        fontWeight: 500,
      },
      '& > :first-child': {
        position: 'absolute',
        bottom: '1.84375rem',
        transform: 'translateY(50%)',
      },
    },
  })
)

const MultipleCheckOption: React.FC<MultipleCheckOptionProps> = props => {
  const classes = useStyles()
  const { label, index, active, handleChange, size } = props

  return (
    <FormControlLabel
      className={classNames({
        [classes.root]: true,
        'MultipleCheck-option': true,
        active: active,
        small: size && size === 'small',
      })}
      value={index}
      control={<Checkbox color="primary" checked={active} onChange={handleChange} />}
      label={<span className="MultipleCheck-label">{label}</span>}
      labelPlacement="top"
    />
  )
}

export default MultipleCheckOption
