import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

import { refreshToken, FullfillmentJWT } from './auth0'
import { Groups } from '../store/user/reducers'

export const CookieName = 'auth0_token'
export const RedirectQueryStringKey = 'redirect'
export const RedirectStorageKey = 'post-login-redirect'

interface AuthTokenHeaders {
  Authorization?: string
}

export function clearAllAuthCookies() {
  Cookies.remove(CookieName, {
    path: '/',
    domain: 'talentinc.com',
    expires: -1,
    secure: true,
  })
}

export function setPostLoginRedirectFromUrl() {
  const urlParams = new URLSearchParams(window.location.search)
  const redirectUri = urlParams.get(RedirectQueryStringKey) ?? ''
  if (redirectUri !== '') {
    window.localStorage.setItem(RedirectStorageKey, redirectUri)
  }
}

export function postLoginRedirect(): boolean {
  const url = window.localStorage.getItem(RedirectStorageKey) ?? ''
  if (url.trim() !== '') {
    window.localStorage.removeItem(RedirectStorageKey)
    window.location.href = url
    return true
  }
  return false
}

export async function getAuthToken(): Promise<string | null> {
  // Prevent redirect ping ponging
  if (window.location.pathname.includes('auth')) {
    return null
  }

  // If the JWT expired, attempt to refresh the token
  if (!Cookies.get(CookieName)) {
    await refreshToken()
  }

  const token = Cookies.get(CookieName)

  if (token) {
    return token
  }

  return null
}

export function getAuthTokenSync(): string | null {
  // Prevent redirect ping ponging
  if (window.location.pathname.includes('auth')) {
    return null
  }

  const token = Cookies.get(CookieName)

  if (token) {
    return token
  }

  return null
}

export async function getAuthTokenHeaders(): Promise<AuthTokenHeaders> {
  const token = await getAuthToken()

  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    }
  }

  return {}
}

export function getGroupsFromJWT(): Groups[] {
  const token = getAuthTokenSync()

  if (!token) {
    return []
  }

  const decoded = jwtDecode<FullfillmentJWT>(token)

  return decoded['https://experts.talentinc.com/groups'] || []
}
