import produce from 'immer'

import { TemplateActionType, TemplateActionTypes } from './actions'
import { TemplateState } from './types'
import { initialLoadedLoadingErrorState } from '../../utils/state'

const initialState: TemplateState = {
  snippets: {},
  snippetsMeta: { ...initialLoadedLoadingErrorState },
  templates: {},
  templatesMeta: { ...initialLoadedLoadingErrorState },
}

export default function templatesReducer(
  state: TemplateState = initialState,
  action: TemplateActionType
): TemplateState {
  return produce(state, (draft: TemplateState) => {
    switch (action.type) {
      case TemplateActionTypes.FETCH_SNIPPETS:
        draft.snippetsMeta.isLoading = true
        draft.snippetsMeta.loaded = false
        draft.snippetsMeta.error = null
        break

      case TemplateActionTypes.FETCH_SNIPPETS_SUCCESS:
        draft.snippetsMeta.isLoading = false
        draft.snippetsMeta.loaded = true
        draft.snippetsMeta.error = null
        action.payload.data.forEach(snippet => {
          draft.snippets[snippet.id] = snippet
        })
        break

      case TemplateActionTypes.FETCH_SNIPPETS_FAIL:
        draft.snippetsMeta.isLoading = false
        draft.snippetsMeta.loaded = false
        draft.snippetsMeta.error = action.error
        break

      case TemplateActionTypes.FETCH_TEMPLATES:
        draft.templatesMeta.isLoading = true
        draft.templatesMeta.loaded = false
        draft.templatesMeta.error = null
        break

      case TemplateActionTypes.FETCH_TEMPLATES_SUCCESS:
        draft.templatesMeta.isLoading = false
        draft.templatesMeta.loaded = true
        draft.templatesMeta.error = null
        action.payload.data.forEach(template => {
          draft.templates[template.id] = template
        })
        break

      case TemplateActionTypes.FETCH_TEMPLATES_FAIL:
        draft.templatesMeta.isLoading = false
        draft.templatesMeta.loaded = false
        draft.templatesMeta.error = action.error
        break
    }
  })
}
