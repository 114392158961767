import dateFormat from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

import i18n from '../../../i18n'
import { OrderItem, OrderItemStates } from '../../../store/items/types'

export default function(scheduledItem: OrderItem): string | null {
  const scheduledTimeState = scheduledItem?.status.active.find(
    ({ state }) => state === OrderItemStates.ConductCall || state === OrderItemStates.ConductInterview
  )

  if (!scheduledTimeState?.due_at) {
    return ''
  }

  const scheduledTime = parseISO(scheduledTimeState.due_at)

  return dateFormat(scheduledTime, `${i18n.t('time_format')}`)
}
