export interface IntercomConfig {
  app_id: string
  user_id?: string
  email?: string
  name?: string
  user_hash?: string
}

interface SentryConfig {
  dsn: string
  release: string
  environment: string
}

export interface Config {
  oms_url: string
  intercom: IntercomConfig
  sentry: SentryConfig
  zoom: ZoomConfig
}

export interface ConfigState {
  config: Config | null
}

export const defaultState = {
  config: null,
}

interface ZoomConfig {
  api_key: string
  sdk_key: string
}
