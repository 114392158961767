import produce from 'immer'

import { OrderItem, OrderItemState } from './types'
import { ItemsActionType, ItemsActionTypes } from './actions'
import { OrdersActionTypes } from '../orders/actions'
import { ClientOrdersAggregateActions } from '../clientOrderAggregate/actions'
import { SCHEDULING } from '../scheduling/actions'

const initialState: OrderItemState = {
  isLoading: false,
  error: null,
  itemsByOrderId: {},
  itemsMeetingInfo: {},
}

const putItemInTheStore = (item: OrderItem, draft: OrderItemState) => {
  if (item.status.completed == null) {
    item.status.completed = []
  }
  if (item.status.active == null) {
    item.status.completed = []
  }
  if (!draft.itemsByOrderId[item.order_id]) {
    draft.itemsByOrderId[item.order_id] = {}
  }
  draft.itemsByOrderId[item.order_id][item.id] = item
}

export default function itemsReducer(state: OrderItemState = initialState, action: ItemsActionType) {
  return produce(state, (draft: OrderItemState) => {
    switch (action.type) {
      case OrdersActionTypes.FETCH_ORDERS_SUCCESS:
        const orders = action.payload.data
        orders &&
          orders.forEach(order => {
            order.order_items.forEach(i => putItemInTheStore(i, draft))
          })
        break
      case OrdersActionTypes.FETCH_ORDER_SUCCESS:
        const order = action.payload.data
        order.order_items && order.order_items.forEach(i => putItemInTheStore(i, draft))
        break
      case ItemsActionTypes.FETCH_ITEM:
        draft.isLoading = true
        break
      case ItemsActionTypes.FETCH_ITEM_SUCCESS:
        draft.isLoading = false
        const item = action.payload.data
        putItemInTheStore(item, draft)
        break
      case ItemsActionTypes.FETCH_ITEM_FAIL:
        draft.isLoading = false
        draft.error = action.error
        break
      case ClientOrdersAggregateActions.FETCH_USER_AGGREGATED_ORDERS_SUCCESS:
        const response = action.payload.data
        if (!!response && !!response.orders) {
          response.orders.map(aggr =>
            aggr.orders.map(order => order.order_items.map(item => putItemInTheStore(item, draft)))
          )
        }
        break
      case ClientOrdersAggregateActions.FETCH_CLIENT_ORDERS_SUCCESS: {
        const response = action.payload.data
        if (!!response) {
          response.map(order => order.order_items.map(item => putItemInTheStore(item, draft)))
        }
        break
      }
      case SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS_SUCCESS: {
        const response = action.payload.data
        if (!!response) {
          const itemID = action.meta.previousAction.itemID
          draft.itemsMeetingInfo[itemID] = response
        }
        break
      }
    }
  })
}
