import React from 'react'
import i18n from '../../i18n'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import DoneIcon from '@material-ui/icons/Done'
import { Sections } from '../../store/autolinkedin/types'
import clsx from 'clsx'

export interface Props {
  name: string
  greyOutEmpty: boolean
  sections: Sections[]
}
const useStyles = makeStyles(() =>
  createStyles({
    list: {
      padding: '.5em 0 .5em .75em',
    },
    listItem: {
      color: styles.palette.darkerBlue.color.hex,
      padding: '0',
      '&.empty': {
        color: styles.palette.lightGrey.color,
      },
    },
    listItemText: {
      fontSize: '15px',
      fontWeight: 500,
    },
    span: {
      marginRight: '1em',
    },
    typography: {
      fontSize: '15px',
      fontWeight: 700,
    },
  })
)

const NavigationList: React.FC<Props> = ({ greyOutEmpty, name, sections }) => {
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.typography}>{i18n.t(`alink__nav__${name}`)}</Typography>
      <List className={classes.list}>
        {sections.map(section => (
          <ListItem
            key={section.section}
            className={clsx(classes.listItem, greyOutEmpty && section.recordCount === 0 ? 'empty' : null)}
            component={greyOutEmpty ? 'span' : 'a'}
            href={`#${section.section}`}
          >
            <ListItemText className={classes.listItemText} disableTypography>
              <span className={classes.span}>•</span>
              {i18n.t(`alink__${section.section}`)}
            </ListItemText>
            {section.recordCount > 0 && <DoneIcon />}
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default NavigationList
