import produce from 'immer'

import { AutoQAScanOutput, AutoQAScanResult, FileScanOptionKeys, FileScanResults, UploadsState } from './types'
import { UPLOAD, UploadsActionTypes } from './actions'
import { filterUnimportantResults } from '../../selectors/autoQA'

const initialState: UploadsState = {
  uploads: {},
  preprocess: {},
  pending: {},
}

export default function uploadsReducer(state: UploadsState = initialState, action: UploadsActionTypes): UploadsState {
  return produce(state, (draft: UploadsState) => {
    switch (action.type) {
      case UPLOAD.ADD_PENDING_FILE:
        draft.pending[action.file.name] = {
          ...action.data,
          context: action.context,
          context_id: action.contextID,
          file: action.file,
        }
        break

      case UPLOAD.ADD_PENDING_FILES:
        action.actions.forEach(a => {
          draft.pending[a.file.name] = {
            ...a.data,
            context: a.context,
            context_id: a.contextID,
            file: a.file,
          }
        })
        break

      case UPLOAD.REMOVE_PENDING_FILE: {
        const { filename } = action
        const preprocessID = state.pending[filename].preprocessID

        if (preprocessID) {
          delete draft.preprocess[preprocessID]
        }

        delete draft.pending[filename]
        delete draft.uploads[filename]
        break
      }

      case UPLOAD.UPDATE_PENDING_FILE:
        draft.pending[action.filename] = {
          ...state.pending[action.filename],
          ...action.data,
        }
        break

      case UPLOAD.UPLOAD_FILE:
        draft.uploads[action.uploadIdentifier] = {
          file: action.file,
          meta: {
            isLoading: true,
            error: null,
            progress: 0,
          },
        }
        break

      case UPLOAD.UPLOAD_FILE_SUCCESS: {
        const uploadIdentifier = action.meta.previousAction.uploadIdentifier
        draft.uploads[uploadIdentifier].meta.isLoading = false
        draft.uploads[uploadIdentifier].meta.progress = 100

        const uploadResponse = action.payload.data
        const preprocessID = uploadResponse.preprocess_id

        draft.uploads[uploadIdentifier].preprocessID = preprocessID

        if (!draft.preprocess[preprocessID]) {
          draft.preprocess[preprocessID] = {
            order: uploadResponse.scan_options.map(o => o.key),
          }
          draft.pending[uploadIdentifier] = {
            ...(draft.pending[uploadIdentifier] || {}),
            preprocessID,
          }
        }
        uploadResponse.scan_options.forEach(o => {
          draft.preprocess[preprocessID][o.key] = {
            meta: {
              loaded: false,
              isLoading: false,
              error: null,
            },
            option: o,
          }
        })
        break
      }

      case UPLOAD.UPLOAD_FILE_FAIL: {
        const fileId = action.meta.previousAction.uploadIdentifier
        draft.uploads[fileId].meta = {
          isLoading: false,
          error: action.error,
          progress: 0,
        }
        break
      }

      case UPLOAD.SET_UPLOAD_PROGRESS: {
        const { progressPercent, uploadIdentifier } = action
        draft.uploads[uploadIdentifier].meta.progress = progressPercent
        break
      }

      case UPLOAD.REMOVE_UPLOAD: {
        const { uploadIdentifier, preprocessID } = action
        delete draft.uploads[uploadIdentifier]
        delete draft.pending[uploadIdentifier]
        if (!!preprocessID) {
          delete draft.preprocess[preprocessID]
        }
        break
      }

      case UPLOAD.SCAN_FILE: {
        const { preprocessId, scanOption } = action

        draft.preprocess[preprocessId][scanOption] = {
          ...state.preprocess[preprocessId][scanOption],
          meta: {
            loaded: false,
            isLoading: true,
            error: null,
          },
          result: undefined,
        }
        break
      }

      case UPLOAD.SCAN_FILE_SUCCESS: {
        let response = action.payload.data
        const { preprocess_id, name } = response

        // we want to hide low score verbatim results
        if (
          response.name === FileScanOptionKeys.AutoQA &&
          !!(response.output as AutoQAScanOutput) &&
          (response.output as AutoQAScanOutput).total_result !== AutoQAScanResult.Pass
        ) {
          // @ts-ignore
          response = {
            ...response,
            // @ts-ignore
            output: filterUnimportantResults(response.output),
          }
        }

        // @ts-ignore
        draft.preprocess[preprocess_id][name] = {
          ...state.preprocess[preprocess_id][name],
          // @ts-ignore
          result: response,
          meta: {
            loaded: true,
            isLoading: false,
            error: null,
          },
        }
        break
      }

      case UPLOAD.SCAN_FILE_FAIL:
        const { preprocessId, scanOption, hasRetries } = action.meta.previousAction
        let result = undefined

        if (!!action.error.response && action.error.response.data.errors === null) {
          result = {
            result: FileScanResults.Failure,
            name: scanOption,
            preprocess_id: preprocessId,
          }
        } else if (!!action.error.response && !!action.error.response.data) {
          result = action.error.response.data
        }

        draft.preprocess[preprocessId][scanOption] = {
          ...state.preprocess[preprocessId][scanOption],
          meta: {
            loaded: !hasRetries,
            isLoading: false,
            error: action.error,
          },
          result,
        }
        break

      case UPLOAD.UPLOAD_FINALIZE: {
        const { preprocessID } = action
        const pendingRecord = Object.values(state.pending).find(p => p.preprocessID === preprocessID)

        if (pendingRecord) {
          draft.uploads[pendingRecord.file.name].meta.isLoading = true
          draft.uploads[pendingRecord.file.name].meta.error = null
        }
        break
      }

      case UPLOAD.UPLOAD_FINALIZE_SUCCESS: {
        const { preprocessID } = action.meta.previousAction
        const pendingRecord = Object.values(state.pending).find(p => p.preprocessID === preprocessID)

        if (pendingRecord) {
          draft.uploads[pendingRecord.file.name].meta.isLoading = false
          draft.uploads[pendingRecord.file.name].fileID = action.payload.data.file.id
        }
        break
      }

      case UPLOAD.UPLOAD_FINALIZE_FAIL: {
        const { preprocessID } = action.meta.previousAction
        const pendingRecord = Object.values(state.pending).find(p => p.preprocessID === preprocessID)

        if (pendingRecord) {
          draft.uploads[pendingRecord.file.name].meta.isLoading = false
          draft.uploads[pendingRecord.file.name].meta.error = action.error
        }
        break
      }

      case UPLOAD.CLEAN_UP_UPLOADS:
        draft.pending = {}
        draft.preprocess = {}
        draft.uploads = {}
        break
    }
  })
}
