import styles from '../../styles'

const NotesStyles = {
  badge: {
    bg: '#ec4e38',
    color: 'white',
    fontSize: '.6em',
    fontWeight: 800,
    lineHeight: '.7em',
    padding: '0',
    size: '1.7em',
  },
  border: 'solid 1px #edf0f5',
  buttonDark: {
    bg: '#09143b',
    border: 'solid 1px #09143b',
    color: 'white',
    hover: '#09143b',
  },
  buttonDisabled: {
    bg: '#fafafc !important',
    border: 'solid 1px #fafafc !important',
    color: '#b7c2d8 !important',
  },
  buttonForm: {
    bg: 'transparent',
    border: 'solid 1px #333f57',
    color: '#3a4862',
    hover: '#f1f2f4',
    padding: '.7em 1.2em',
    weight: 600,
  },
  colorAlarm: '#ed3572',
  colorError: 'red',
  colorHover: 'f5f5f9',
  colorPast: '#ed3572',
  colorSelected: '#edf0f5 !important',
  colorWhiteHover: '#fafafa',
  darkText: '#333f57',
  fontSize: '15px',
  formTransition: 'height .5s ease-out 0s',
  fullHeight: '100%',
  fullWidth: '100%',
  input: {
    align: 'center',
    flexDirection: 'row',
    innerBorder: '0 !important',
    innerMargin: '0',
    innerPadding: '.3em',
    justifyContent: 'space-between',
    weight: 800,
  },
  label: {
    color: '#6a758c',
    position: 'relative',
    size: 'inherit',
    transform: 'none',
    weight: 400,
  },
  linkColor: styles.palette.link.color,
  listItem: {
    bgAlarm: '#fef6f8',
    cursor: 'pointer',
    letterSpacing: '.01em',
    overflow: 'hidden',
    padding: '0 0 0 1.6em',
    textOverflow: 'ellipsis',
    weight: 800,
  },
  padding: '1.6em',
  padding2: '1.3em',
  selector: {
    bg: 'transparent !important',
    border: 'solid 1px transparent',
    borderInner: 'none !important',
    boxShadow: '0 5px 5px -1px rgba(0, 0, 0, 0.05)',
    fontWeight: 800,
    letterSpacing: '.01em',
    padding: '.9em 1.6em',
    position: 'fixed',
    zIndex: 100,
  },
  shadow: '0 1px 3px 0 rgba(129, 139, 158, 0.5)',
  shadow2: '0 0 5px 3px rgba(0, 0, 0, 0.1)',
}

export default NotesStyles
