import React, { useCallback } from 'react'
import { AutoLinkedinSections } from '../../store/autolinkedin/types'
import { Field, FieldRenderProps } from 'react-final-form'
import i18n from '../../i18n'
import DateDropdown from './DateDropdown'
import { FormFieldSchema } from './formSchema'
import { Box, createStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import { OnChange } from 'react-final-form-listeners'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'

export interface TimePeriodProps {
  checkboxClass: string
  fieldSchema: FormFieldSchema
  formControlLabelClass: string
  formControlRootClass: string
  inputClass: string
  inputGroupClass: string
  outerName: string
  index: number
  required: boolean
  section: AutoLinkedinSections
  isCurrent?: boolean
  views: ('year' | 'day' | 'month')[]
  labelClass: string
  readOnly: boolean
}

export interface WhenFieldChangesProps {
  fieldName: string
  becomes: null | boolean
  set: string
  to: null | boolean
  hide?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    datesContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > *': {
        width: '48%',
      },
    },
  })
)

const WhenFieldChanges: React.FC<WhenFieldChangesProps> = ({ fieldName, becomes, set, to }) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange, value } }
    ) => (
      <OnChange name={fieldName}>
        {thisValue => {
          if (thisValue === becomes && thisValue !== value) {
            onChange(to)
          }
        }}
      </OnChange>
    )}
  </Field>
)

export const TimePeriodField: React.FC<TimePeriodProps> = ({
  views,
  checkboxClass,
  isCurrent,
  required,
  section,
  fieldSchema,
  formControlLabelClass,
  formControlRootClass,
  outerName,
  index,
  inputClass,
  inputGroupClass,
  labelClass,
  readOnly,
}) => {
  const [current, setCurrent] = React.useState<boolean>(false)
  const [firstRender, setFirstRender] = React.useState<boolean>(!!isCurrent)

  const onEndDateRenderCallback = useCallback(
    (value: number | string | null) => {
      if (!current && firstRender && (value === null || value === '')) {
        setCurrent(true)
      }
      setFirstRender(false)
    },
    [current, firstRender, setCurrent]
  )

  const classes = useStyles()

  return (
    <Box
      className={classNames({
        [classes.root]: true,
      })}
    >
      {isCurrent && (
        <Box>
          <WhenFieldChanges
            fieldName={`${outerName}.current`}
            becomes={true}
            set={`${outerName}.${fieldSchema.name}.endMonthYear`}
            to={null}
          />
          <Field name={`${outerName}.current`} key={`${outerName}.${index}.current`} type={'checkbox'}>
            {(props: FieldRenderProps<boolean>) => {
              return (
                <FormControlLabel
                  classes={{ label: formControlLabelClass, root: formControlRootClass }}
                  control={
                    <Checkbox
                      classes={{ root: checkboxClass, checked: checkboxClass }}
                      color="primary"
                      {...props.input}
                      checked={current}
                      onChange={event => {
                        props.input.onChange(event)
                        setCurrent(event.target.checked)
                      }}
                    />
                  }
                  label={i18n.t(`alink__${section}__current`)}
                />
              )
            }}
          </Field>
        </Box>
      )}
      <Box
        className={classNames({
          [classes.datesContainer]: true,
        })}
      >
        <DateDropdown
          readonly={readOnly}
          inputClass={inputClass}
          inputGroupClass={inputGroupClass}
          label={i18n.t(`alink__${section}__${fieldSchema.name}__startMonthYear`)}
          outerName={`${outerName}.${fieldSchema.name}.startMonthYear`}
          labelClass={labelClass}
          views={views}
        />
        {(!isCurrent || !current) && (
          <DateDropdown
            readonly={readOnly}
            inputClass={inputClass}
            inputGroupClass={inputGroupClass}
            label={i18n.t(`alink__${section}__${fieldSchema.name}__endMonthYear`)}
            outerName={`${outerName}.${fieldSchema.name}.endMonthYear`}
            labelClass={labelClass}
            views={views}
            onEndDateRenderCallback={onEndDateRenderCallback}
          />
        )}
      </Box>
    </Box>
  )
}
