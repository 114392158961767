import React, { useState } from 'react'
import ModalWrapper from '../common/ModalWrapper'
import Header from '../common/Header'
import i18n from '../../i18n'
import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'
import CustomRadio from '../common/CustomRadio'
import { formatDate } from '../../utils/formatting'
import Footer from '../common/Footer'
import Button from '../common/Button'
import {
  getAutoLinkedinByItemID,
  getAutoLinkedinGenerateMeta,
  getAutoLinkRevisions,
} from '../../store/autolinkedin/selectors'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { AutoLinkedin, AutoLinkedinJson, AutoLinkedinRevision } from '../../store/autolinkedin/types'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import useUserInfoState from '../common/useUserInfo'
import { LoadedLoadingErrorState } from '../../utils/state'
import classNames from 'classnames'

export interface RevisionSelectionProps {
  linkedinItemID: number
  autoLinkedinID: number
  handleCancel: () => void
  handleStartFromRevision: (revisionID: number) => void
  handleContinueFromAutosave: (autoLinkedinJson: AutoLinkedinJson) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    cancelButton: {
      marginRight: '1.2rem',
      padding: '.5rem 1.547rem',
    },
    submitButton: {
      padding: '.5rem 1.547rem',
    },
    optionsContainer: {
      paddingTop: '3rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    selectedRevision: {
      backgroundColor: styles.palette.lightPurple.color,
    },
    revision: {
      border: `1px solid ${styles.palette.darkerBlue.color.hex}`,
      width: '90%',
      color: styles.palette.darkerBlue.color.hex,
      borderRadius: '10px',
      padding: '.5rem',
      display: 'flex',
      margin: '.5rem',
    },
    revisionInfo: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      paddingTop: '9px',
    },
    revisionCreatedTime: {
      color: styles.palette.lightGrey.color,
    },
  })
)

export const AutoSaveVersion = 'autosave'

const RevisionSelection: React.FC<RevisionSelectionProps> = ({
  linkedinItemID,
  autoLinkedinID,
  handleCancel,
  handleStartFromRevision,
  handleContinueFromAutosave,
}) => {
  const classes = useStyles()

  const revisions = useSelector<AppState, AutoLinkedinRevision[]>(state =>
    getAutoLinkRevisions(state.autolinkedinReducer, autoLinkedinID)
  )

  const autoLinkedin = useSelector<AppState, AutoLinkedin | null | undefined>(state =>
    getAutoLinkedinByItemID(state.autolinkedinReducer, linkedinItemID)
  )

  const [selectedRevisionID, setSelectedRevisionID] = useState<string>(AutoSaveVersion)

  const meta = useSelector<AppState, LoadedLoadingErrorState | undefined>(state =>
    getAutoLinkedinGenerateMeta(state.autolinkedinReducer, linkedinItemID)
  )

  const { timeZone, locale } = useUserInfoState()

  return (
    <ModalWrapper>
      <Header
        activeStep={0}
        closeButton={true}
        handleClose={handleCancel}
        title={i18n.t('alink__RevisionSelection__title')}
        transparent={true}
      />

      {revisions.length > 0 && !!autoLinkedin && (
        <RadioGroup
          className={classes.optionsContainer}
          aria-label="select-revision-to-start-from"
          value={selectedRevisionID}
          onChange={event => setSelectedRevisionID(event.target.value)}
        >
          <Box
            className={classNames({
              [classes.revision]: true,
              [classes.selectedRevision]: AutoSaveVersion === selectedRevisionID,
            })}
          >
            <CustomRadio value={AutoSaveVersion} id={`auto_linkedin_revision.${AutoSaveVersion}`} />
            <Box className={classes.revisionInfo}>
              <Typography title={i18n.t('alink__RevisionSelection__autosave')}>
                {i18n.t('alink__RevisionSelection__autosave')}
              </Typography>
              <Typography component="time" dateTime={autoLinkedin?.updated_at} className={classes.revisionCreatedTime}>
                {formatDate(
                  autoLinkedin?.updated_at,
                  timeZone,
                  locale,
                  i18n.t('documentItemV2__uploadedAtShortFormat')
                )}
              </Typography>
            </Box>
          </Box>

          {revisions.map(revision => (
            <Box
              className={classNames({
                [classes.revision]: true,
                [classes.selectedRevision]: revision.id.toString() === selectedRevisionID,
              })}
              key={revision.id}
            >
              <CustomRadio value={revision.id.toString()} id={`auto_linkedin_revision.${revision.id}`} />
              <Box className={classes.revisionInfo}>
                <Typography title={i18n.t('alink__RevisionSelection__revision', { revision: revision.revision + 1 })}>
                  {i18n.t('alink__RevisionSelection__revision', { revision: revision.revision + 1 })}
                </Typography>
                <Typography component="time" dateTime={revision.created_at} className={classes.revisionCreatedTime}>
                  {formatDate(revision.created_at, timeZone, locale, i18n.t('documentItemV2__uploadedAtShortFormat'))}
                </Typography>
              </Box>
            </Box>
          ))}
        </RadioGroup>
      )}

      <Footer
        leftColumn={
          <Button type="secondary" className={classes.cancelButton} onClick={handleCancel} id="pick-resume-cancel">
            {i18n.t('alink__RevisionSelection__cancel')}
          </Button>
        }
        rightColumn={
          <Button
            type="contrast"
            showLoader={meta?.isLoading}
            disabled={!selectedRevisionID || meta?.isLoading}
            className={classes.submitButton}
            onClick={() => {
              if (!selectedRevisionID) {
                return
              }
              if (selectedRevisionID === AutoSaveVersion) {
                handleContinueFromAutosave(autoLinkedin?.data_json as AutoLinkedinJson)
              } else {
                handleStartFromRevision(parseInt(selectedRevisionID))
              }
            }}
            id="select-revision-next"
          >
            {i18n.t('alink__RevisionSelection__next')}
          </Button>
        }
      />
    </ModalWrapper>
  )
}

export default RevisionSelection
