import { FieldRenderProps } from 'react-final-form-hooks'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Linkify from 'linkifyjs/react'

import styles from '../../styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      minWidth: '20rem',
      height: '2.5rem',
      '&.large': { width: '16rem' },
      '@media (max-width: 680px)': {
        width: '100% !important',
      },
    },
    control: {
      position: 'relative',
      marginLeft: 0,
      display: 'block',
      '&.left': {
        marginRight: '1.5rem',
      },
      '& .label': {
        position: 'absolute',
        left: 0,
        top: '-.4rem',
        transform: 'translateY(-100%)',
      },
      '& .error': {
        marginTop: '.4rem',
        color: '#f85f49',
      },
      '& .MuiOutlinedInput-input': { padding: '.75rem .85rem .75rem .85rem' },
      '& .MuiSelect-select': { padding: '.75rem 1.8rem .75rem .85rem' },
      '& .MuiSelect-icon': { right: '.5rem' },
      '@media (max-width: 680px)': {
        width: '100%',
        '&.left': { marginRight: 0 },
      },
    },
    dd: {
      fontWeight: 'bold',
      color: styles.palette.lightBlack.color,
      marginBottom: '5.5625rem',
      paddingRight: '4rem',
      '@media (max-width: 768px)': {
        marginBottom: '2rem',
        paddingRight: 0,
      },
    },
    dt: {
      color: '#5b5b64',
    },
    link: {
      color: theme.palette.primary.main,
    },
  })
)

export interface TextFieldProps {
  field: FieldRenderProps<string>
  label: string
  left?: boolean
  htmlType?: 'text' | 'email' | 'tel'
}

export interface ReadonlyTextFieldProps {
  text: string | string[]
  label: string
  left?: boolean
}

export const TextField: React.FC<TextFieldProps> = ({ field, label, left = true, htmlType = 'text' }) => {
  const classes = useStyles()

  return (
    <FormControlLabel
      className={classNames({
        [classes.control]: true,
        left,
        hasError: field.meta.touched && field.meta.error,
      })}
      label={
        <Typography variant={'h6'} className="label">
          {label}
        </Typography>
      }
      control={
        <>
          <OutlinedInput
            inputProps={field.input as React.HTMLAttributes<HTMLInputElement>}
            labelWidth={0}
            disabled={field.meta.submitting}
            className={classes.input}
            type={htmlType}
          />
          {field.meta.error && (
            <Typography display="block" variant={'h6'} className="error">
              {field.meta.error}
            </Typography>
          )}
        </>
      }
    />
  )
}

export const ReadOnlyTextField: React.FC<ReadonlyTextFieldProps> = ({ text, label, left = true }) => {
  const classes = useStyles()

  const value = Array.isArray(text) ? text.map((v, i) => <Typography key={i}>{v}</Typography>) : text

  return (
    <FormControlLabel
      className={classNames({
        [classes.control]: true,
        left,
      })}
      label={
        <Typography variant={'h6'} className="label" area-label="order_source">
          {label}
        </Typography>
      }
      control={
        <Typography variant={'body1'} component={'dd'} className={classes.input} area-label="order_source_active">
          <Linkify
            options={{
              attributes: {
                target: '_blank',
                rel: 'noopener noreferrer',
              },
            }}
          >
            {value}
          </Linkify>
        </Typography>
      }
    />
  )
}
