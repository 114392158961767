import ClientInfo from './ClientInfo'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import * as React from 'react'
import { Client } from '../../store/clients/reducer'
import { clientByIdSelector } from '../../selectors/clients'

interface ClientInfoContainerProps {
  clientID: number
  hideAvatar?: boolean
}

const ClientInfoContainer = (props: ClientInfoContainerProps) => {
  const { clientID, hideAvatar } = props
  const client = useSelector<AppState, Client>(store => clientByIdSelector(store.clientReducer, clientID))

  return client != null ? (
    <ClientInfo
      name={client.full_name}
      unreadMailCount={client.unread_mail_count}
      clientId={clientID}
      hideAvatar={!!hideAvatar}
      area-label="client_info"
    />
  ) : null
}

export default ClientInfoContainer
