import {
  AxiosMiddlewareActionSuccess,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionCreator,
} from '../../utils/axios'
import { Critique } from './types'

export enum CritiqueActions {
  FETCH_CRITIQUE = 'FETCH_CRITIQUE',
  FETCH_CRITIQUE_SUCCESS = 'FETCH_CRITIQUE_SUCCESS',
  FETCH_CRITIQUE_FAIL = 'FETCH_CRITIQUE_FAIL',
}

interface FetchCritique extends AxiosMiddlewareActionCreator {
  type: typeof CritiqueActions.FETCH_CRITIQUE
  fileKey: string | number
}

interface FetchCritiqueSuccess extends AxiosMiddlewareActionSuccess<Critique, FetchCritique> {
  type: typeof CritiqueActions.FETCH_CRITIQUE_SUCCESS
}

interface FetchCritiqueFail extends AxiosMiddlewareActionFail<FetchCritique> {
  type: typeof CritiqueActions.FETCH_CRITIQUE_FAIL
}

export function fetchCritique(fileKey: string | number): FetchCritique {
  return {
    type: CritiqueActions.FETCH_CRITIQUE,
    fileKey,
    payload: {
      request: {
        url: `/v1/files/ra/${fileKey}/critique`,
      },
    },
  }
}

export type CritiqueActionType = FetchCritique | FetchCritiqueSuccess | FetchCritiqueFail
