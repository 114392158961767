import React, { useState } from 'react'
import Button from '../../common/Button'
import Typography from '@material-ui/core/Typography'
import i18n from '../../../i18n'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { wrapperStyles } from './PhoneConsultation'
import { FulfillableOrderItemDisplay } from '../../Orders/OrderItems'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { OrderItemStates } from '../../../store/items/types'
import { utcToZonedTime } from 'date-fns-tz'
import useUserInfoState from '../../common/useUserInfo'

interface ActionProps {
  primary: boolean
  handleClick: () => void
  label: string
  items: FulfillableOrderItemDisplay
  disabled?: boolean
}

const PhoneConsultationAction: React.FC<ActionProps> = ({ primary, handleClick, label, items, disabled }) => {
  const classes = wrapperStyles()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { timeZone } = useUserInfoState()
  const current = utcToZonedTime(new Date(), timeZone)

  const time = differenceInMinutes(utcToZonedTime(items?.due_date || 0, timeZone), current)

  const handleSubmitClick = async (callbackFn: () => void) => {
    setIsSubmitting(true)
    await callbackFn()
    setIsSubmitting(false)
  }
  return (
    <Button
      type={primary ? 'primary' : 'secondary'}
      className={primary ? classes.joinButton : classes.sendButton}
      onClick={() => handleSubmitClick(handleClick)}
      showLoader={isSubmitting}
      disabled={
        !!disabled ||
        ((items.itemState === OrderItemStates.ConductCall || items.itemState === OrderItemStates.ConductInterview) &&
          time > 15) ||
        items.itemState === OrderItemStates.AwaitingScheduling ||
        items.itemState === OrderItemStates.SchedulePhoneCall ||
        items.itemState === OrderItemStates.ScheduleInterview ||
        items.itemState === OrderItemStates.SendSummary
      }
    >
      <Typography variant={'body2'}>{i18n.t(label)}</Typography>
      <ArrowForwardIosIcon className={classes.icon} />
    </Button>
  )
}

export default PhoneConsultationAction
