import React from 'react'
import * as Sentry from '@sentry/browser'

import ErrorNotification from './ErrorNotification'

interface ErrorBoundaryState {
  error: Error | null
  lastSentryEventID: string | null
}

interface ErrorBoundaryProps {
  children: React.ReactElement | React.ReactElement[]
  showChildren?: boolean
  message?: string | null | undefined
}

// React error boundary that will automatically send bug reports to Sentry
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public readonly state: ErrorBoundaryState

  public constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      error: null,
      lastSentryEventID: null,
    }
    this.openErrorReportModal = this.openErrorReportModal.bind(this)
    this.dismissError = this.dismissError.bind(this)
  }

  public componentDidCatch(error: Error) {
    this.setState({
      error,
      lastSentryEventID: Sentry.captureException(error),
    })
  }

  private openErrorReportModal() {
    if (this.state.lastSentryEventID) {
      Sentry.showReportDialog()
    }
  }

  private dismissError() {
    this.setState({ error: null })
  }

  public render() {
    const { showChildren = false, message = null } = this.props

    const notification = !this.state.error ? null : (
      <ErrorNotification
        handleClose={this.dismissError}
        showBugReport={!!this.state.lastSentryEventID}
        handleReportBugClick={this.openErrorReportModal}
        message={message}
      />
    )

    if (showChildren) {
      return (
        <>
          {this.props.children}
          {notification}
        </>
      )
    } else if (this.state.error) {
      return notification
    }

    return this.props.children
  }
}

export default ErrorBoundary
