import { WIPSegments } from './reducer'
import { LoadingErrorState } from '../../utils/state'

export interface NudgeVariables {
  client_name?: string | null
  client_id?: number | null
  message_count?: number | null
  received_at?: string | null
  incentiveTotalEarned?: string
  incentiveEarnedTypes?: string
  wipSegment?: WIPSegments | null
  link?: string | null
  worksInProgress?: number | null
  wipLimit?: number | null
}

export enum NudgeTypes {
  MessageSent = 'MessageSent',
  NewOrders = 'NewOrders',
  MessageReceived = 'MessageReceived',
}

export interface Nudge {
  type: NudgeTypes
  trigger?: NewOrderNudgeTrigger
  copyKey: string
  variables?: NudgeVariables | null
}

export interface NudgeDisplay extends Nudge {
  text: string
}

export interface NudgesState {
  [NudgeTypes.MessageSent]: Nudge | null
  [NudgeTypes.NewOrders]: Nudge | null
  [NudgeTypes.MessageReceived]: {
    meta: LoadingErrorState
    nudges: Nudge[] | null
    lastPoll: Date | null
  }
}

export interface NewOrderNudgeTrigger {
  userID: number
  wipSegment: WIPSegments
  lastTrigger: Date
  nextTrigger: Date
}
