import React from 'react'

const Ellipses = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="3" viewBox="0 0 12 3">
    <path
      fill="#09143B"
      d="M1.5 0C.675 0 0 .675 0 1.5S.675 3 1.5 3 3 2.325 3 1.5 2.325 0 1.5 0zm9 0C9.675 0 9 .675 9 1.5S9.675 3 10.5 3 12 2.325 12 1.5 11.325 0 10.5 0zM6 0c-.825 0-1.5.675-1.5 1.5S5.175 3 6 3s1.5-.675 1.5-1.5S6.825 0 6 0z"
      opacity=".3"
    />
  </svg>
)

export default Ellipses
