import React from 'react'
import Box from '@material-ui/core/Box'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import i18n from '../../../i18n'
import ActionButton from './ActionButton'
import { useSelector } from 'react-redux'
import { AppState } from '../../../store'
import { AutoLinkedin, AutoLinkedinRevision } from '../../../store/autolinkedin/types'
import { getAutoLinkedinByItemID, getLatestAutoLinkRevision } from '../../../store/autolinkedin/selectors'
import { formatDate } from '../../../utils/formatting'
import useUserInfoState from '../../common/useUserInfo'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'white',
      border: '1px solid #D5D9E0',
      borderRadius: '.1875rem',
      color: 'black',
      '@media (max-width: 1024px)': {
        display: 'none',
      },
    },
    titleContainer: {
      padding: '.9375rem 1.5rem',
      borderBottom: '1px solid #D5D9E0',
    },
    title: {
      fontWeight: 600,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1.25rem 1.5rem 1.25rem 1.5rem',
    },
    datesContainer: {
      paddingLeft: '1.25rem',
      paddingBottom: '1.25rem',
    },
    datesText: {
      fontSize: '.8rem',
    },
  })
)

export enum AutoLinkedinMode {
  Start = 'Start',
  Edit = 'Edit',
}

interface AutoLinkedinActionProps {
  mode: AutoLinkedinMode
  handleClick: () => void
  linkedinItemID: number
  autoLinkedinID?: number | null | undefined
}

const AutoLinkedinAction: React.FC<AutoLinkedinActionProps> = ({
  mode,
  handleClick,
  autoLinkedinID,
  linkedinItemID,
}) => {
  const classes = useStyles()

  const userInfo = useUserInfoState()

  const autoLink = useSelector<AppState, AutoLinkedin | null | undefined>(state =>
    getAutoLinkedinByItemID(state.autolinkedinReducer, linkedinItemID)
  )
  const latestAutoLinkRevision = useSelector<AppState, AutoLinkedinRevision | null>(state =>
    getLatestAutoLinkRevision(state.autolinkedinReducer, autoLinkedinID)
  )

  return (
    <Box className={classes.root}>
      <Box className={classes.titleContainer}>
        <Typography variant={'body1'} className={classes.title}>
          {i18n.t('clientPage__side__autolinkedin')}
        </Typography>
      </Box>
      <Box className={classes.buttonContainer}>
        <ActionButton handleClick={handleClick}>
          <Typography variant={'body2'}>
            {mode === AutoLinkedinMode.Start && i18n.t('clientPage__side__autolinkedin_start_cta')}
            {mode === AutoLinkedinMode.Edit && i18n.t('clientPage__side__autolinkedin_edit_cta')}
          </Typography>
        </ActionButton>
      </Box>
      <Box className={classes.datesContainer}>
        <Typography variant={'body1'} className={classes.datesText}>
          {i18n.t('clientPage__side__autolinkedin_last_edited', {
            date: !autoLink
              ? i18n.t('clientPage__side__autolinkedin_date_placeholder')
              : formatDate(
                  autoLink?.updated_at,
                  userInfo.timeZone,
                  userInfo.locale,
                  i18n.t('clientPage__side__autolinkedin_date_format')
                ),
          })}
        </Typography>
        <Typography variant={'body1'} className={classes.datesText}>
          {i18n.t('clientPage__side__autolinkedin_last_published', {
            date: !latestAutoLinkRevision
              ? i18n.t('clientPage__side__autolinkedin_date_placeholder')
              : formatDate(
                  latestAutoLinkRevision?.created_at,
                  userInfo.timeZone,
                  userInfo.locale,
                  i18n.t('clientPage__side__autolinkedin_date_format')
                ),
          })}
        </Typography>
      </Box>
    </Box>
  )
}

export default AutoLinkedinAction
