import React, { FC, useState } from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Typography } from '@material-ui/core'
import i18n from '../../i18n'
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Button from '../common/Button'
import Modal from '@material-ui/core/Modal'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { set } from 'date-fns'
import ErrorNotification from '../common/ErrorNotification'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import classNames from 'classnames'
import { UploadDocTypeMenu } from '../../store/uploads/types'
import MenuItem from '@material-ui/core/MenuItem'

export interface SchedulerProps {
  onSubmit: (date: Date, itemID: number) => void
  options: UploadDocTypeMenu[]
  isOpen?: boolean
  toggleIsOpen: (isOpen: boolean) => void
}

export interface SchedulerState {
  date?: Date | null
  time?: Date | null
  itemID?: number | null
  isScheduling: boolean
  hasError: boolean
}

const useStyles = makeStyles(theme => ({
  modal: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    zIndex: 1000,
    transform: 'translateX(-50%) translateY(-50%)',
    width: '40.375rem',
    height: '24rem',
    padding: '3rem 3rem 3rem 3rem',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px 5px rgba(2, 3, 26, 0.1)',
    borderRadius: '.25rem',
    backgroundColor: '#fff',
    '@media (max-width: 680px)': {
      width: 'calc(100% - 2rem)',
      left: 0,
      margin: '1rem',
      padding: '2rem',
      transform: 'translateY(calc(-50% - 1rem))',
    },
    '@media (max-width: 540px)': {
      height: 'auto',
    },
  },
  modalClose: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    padding: '.5rem',
    '& .icon': {
      padding: '.1rem',
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.54)',
      color: '#fff',
      fontSize: '1rem',
    },
  },
  modalTitle: {
    '@media (max-width: 540px)': {
      marginTop: '1rem',
    },
  },
  modalInfo: {
    marginTop: '.5rem',
    color: '#3f3f3f',
  },
  modalBody: {
    margin: '3.5rem 2.5rem 0 2.5rem',
    '& .content': {
      display: 'flex',
      flexDirection: 'column',
      '& .pickers': {
        display: 'flex',
        justifyContent: 'space-between',
        '& .picker-error': { marginTop: '.15rem', color: '#f85f49' },
      },
      '& .picker': {
        width: '10rem',
      },
    },
    '& .buttons': {
      marginTop: '1.5rem',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  input: {
    width: '100%',
  },
}))

const Scheduler: FC<SchedulerProps> = ({ onSubmit, isOpen, toggleIsOpen, options }) => {
  const classes = useStyles()

  const [state, setState] = useState<SchedulerState>({
    date: null,
    time: null,
    itemID: !!options ? options[0].order_item_id : null,
    isScheduling: false,
    hasError: false,
  })

  const handleItemChange = React.useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      setState({ ...state, itemID: event.target.value as number })
    },
    [setState, state]
  )

  const submitHandler = React.useCallback(async () => {
    const callDate = set(state.time as Date, {
      year: (state.date as Date).getFullYear(),
      month: (state.date as Date).getMonth(),
      date: (state.date as Date).getDate(),
    })
    setState({ ...state, isScheduling: true })
    try {
      await onSubmit(new Date(callDate), state.itemID as number)
    } catch (e) {
      setState({ ...state, hasError: true })
    }
    setState({ ...state, isScheduling: false })
    toggleIsOpen(false)
  }, [state, toggleIsOpen, onSubmit])

  return (
    <>
      <Modal open={!!isOpen ? isOpen : false}>
        <Box className={classes.modal}>
          <IconButton className={classes.modalClose} onClick={() => toggleIsOpen(!isOpen)}>
            <CloseIcon className="icon" />
          </IconButton>
          <Typography display="block" variant={'h2'} className={classes.modalTitle}>
            {i18n.t('order__modal__call__title')}
          </Typography>
          <Typography display="block" variant={'h5'} className={classes.modalInfo}>
            {i18n.t('order__modal__call__info')}
          </Typography>
          <Box className={classes.modalBody}>
            <Box className="content">
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-session-type">
                  <Typography variant={'body2'}>{i18n.t('scheduler__sessionType')}</Typography>
                </InputLabel>
                <Select
                  value={state.itemID}
                  onChange={handleItemChange}
                  input={
                    <OutlinedInput name="session_type" className={classNames([classes.input, 'session-type-select'])} />
                  }
                >
                  {options.map((o: UploadDocTypeMenu) => (
                    <MenuItem key={`doc-type-select-item-${o.order_item_id}`} value={o.order_item_id || ''}>
                      {o.label}
                      {o.duplicateIndex && ` #${o.duplicateIndex}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box className="pickers">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="cc-date-picker"
                    label="Date"
                    format="MM/dd/yyyy"
                    value={state.date}
                    className="picker"
                    onChange={(value: Date | null) => setState({ ...state, date: value })}
                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="cc-time-picker"
                    label="Time"
                    className="picker"
                    value={state.time}
                    onChange={(value: Date | null) => setState({ ...state, time: value })}
                    KeyboardButtonProps={{ 'aria-label': 'change time' }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
            <Box className="buttons">
              <Button
                disabled={state.hasError || !state.time || !state.date || !state.itemID}
                type="primary"
                showLoader={state.isScheduling}
                onClick={async () => await submitHandler()}
              >
                {i18n.t('scheduler__schedule')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {state.hasError && <ErrorNotification handleClose={() => setState({ ...state, hasError: false })} />}
    </>
  )
}

export default Scheduler
