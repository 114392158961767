import { Message, MessageContext, MessageContexts, MessageState, MessageStatuses } from '../store/messages/types'
import { CurrentMessage } from '../store/currentMessage/types'

export const messageByContextIdSelector = (
  state: MessageState,
  context: MessageContext,
  contextId: number
): Message[] => {
  try {
    return Object.values(state[context][contextId])
  } catch (e) {
    return []
  }
}

export const messagesNeedFetching = (state: MessageState): boolean => {
  return !state.fetchingMeta.isLoading && !state.fetchingMeta.loaded
}

export const getLatestMessageByDirection = (
  state: MessageState,
  context: MessageContext,
  contextID: number,
  outbound: boolean
): Message | null => {
  if (!state[context][contextID]) {
    return null
  }

  const sortedMessages = Object.values(state[context][contextID])
    .filter((message: Message) => message.outbound === outbound)
    .sort((a: Message, b: Message) => {
      const aDate = new Date(a.created_at)
      const bDate = new Date(b.created_at)

      if (aDate < bDate) {
        return 1
      }

      if (aDate > bDate) {
        return -1
      }

      return 0
    })

  if (sortedMessages.length === 0) {
    return null
  }

  return sortedMessages[0]
}

export const messageIsUnreadByUser = (message: Message): boolean => {
  const isAutomated = messageIsAutomated(message)
  const fromTalentInc = !isAutomated && messageIsFromTalentInc(message)
  const readableByUser = !message.outbound || fromTalentInc
  if (!readableByUser) {
    return false
  }

  const latestReadStatus = [...message.statuses]
    .sort((a, b) => b.id - a.id)
    .find(s => s.status === MessageStatuses.Read && !s.end_at)

  if (!latestReadStatus) {
    return true
  }

  return !!latestReadStatus.end_at
}

export const messageReadByClientAt = (message: Message): string | null => {
  if (!message.outbound || !message.statuses || !message.statuses.length) {
    return null
  }

  const clientReadStatus = message.statuses.find(s => s.status === MessageStatuses.ClientRead)

  if (!clientReadStatus || !clientReadStatus.start_at) {
    return null
  }

  return clientReadStatus.start_at
}

export const messageIsFromTalentInc = (message: Message): boolean =>
  (message.sender.includes('@talentinc.com') && message.outbound) ||
  (message.statuses && message.statuses.map(s => s.status)).includes(MessageStatuses.TalentInc)

export const messageIsAutomated = (message: Message): boolean => {
  if (!message.outbound || message.context !== MessageContexts.CLIENT) {
    return false
  }

  return message.sender.includes(message.context_id.toString())
}

export const currentMessageNeedsFilledIn = (state: CurrentMessage): boolean => !state.sender || !state.recipients.length

export const currentMessageHasContent = (state: CurrentMessage): boolean =>
  state.editorState.getCurrentContent().hasText() || state.subject.length > 0 || state.attachment_ids.length > 0
