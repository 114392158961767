import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import {
  WeeklyPeriod,
  Preferences,
  PreferenceData,
  SchedulingAccount,
  UserSchedulingData,
  ZoomMeetingData,
} from './types'
import { userId } from '../user/reducers'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AppState } from '../index'
import { AnyAction } from 'redux'

export enum SCHEDULING {
  GENERATE_SCHEDULE = 'GENERATE_SCHEDULE',
  GENERATE_SCHEDULE_SUCCESS = 'GENERATE_SCHEDULE_SUCCESS',
  GENERATE_SCHEDULE_FAIL = 'GENERATE_SCHEDULE_FAIL',

  SET_AVAILABILITY = 'SET_AVAILABILITY',
  SET_AVAILABILITY_SUCCESS = 'SET_AVAILABILITY_SUCCESS',
  SET_AVAILABILITY_FAIL = 'SET_AVAILABILITY_FAIL',

  SET_PREFERENCES = 'SET_PREFERENCES',
  SET_PREFERENCES_SUCCESS = 'SET_PREFERENCES_SUCCESS',
  SET_PREFERENCES_FAIL = 'SET_PREFERENCES_FAIL',

  GET_PREFERENCES = 'GET_PREFERENCES',
  GET_PREFERENCES_SUCCESS = 'GET_PREFERENCES_SUCCESS',
  GET_PREFERENCES_FAIL = 'GET_PREFERENCES_FAIL',

  GET_CALENDAR_EVENTS = 'GET_CALENDAR_EVENTS',
  GET_CALENDAR_EVENTS_SUCCESS = 'GET_CALENDAR_EVENTS_SUCCESS',
  GET_CALENDAR_EVENTS_FAIL = 'GET_CALENDAR_EVENTS_FAIL',

  CREATE_CALENDAR_EVENT = 'CREATE_CALENDAR_EVENT',
  CREATE_CALENDAR_EVENT_SUCCESS = 'CREATE_CALENDAR_EVENT_SUCCESS',
  CREATE_CALENDAR_EVENT_FAIL = 'CREATE_CALENDAR_EVENT_FAIL',

  GET_AVAILABILITY_WIDGET_TOKEN = 'GET_AVAILABILITY_WIDGET_TOKEN',
  GET_AVAILABILITY_WIDGET_TOKEN_SUCCESS = 'GET_AVAILABILITY_WIDGET_TOKEN_SUCCESS',
  GET_AVAILABILITY_WIDGET_TOKEN_FAIL = 'GET_AVAILABILITY_WIDGET_TOKEN_FAIL',

  GENERATE_SIGNATURE = 'GENERATE_SIGNATURE',
  GENERATE_SIGNATURE_SUCCESS = 'GENERATE_SIGNATURE_SUCCESS',
  GENERATE_SIGNATURE_FAIL = 'GENERATE_SIGNATURE_FAIL',

  GET_SCHEDULED_ITEM_MEETING_DETAILS = 'GET_SCHEDULED_ITEM_MEETING_DETAILS',
  GET_SCHEDULED_ITEM_MEETING_DETAILS_SUCCESS = 'GET_SCHEDULED_ITEM_MEETING_DETAILS_SUCCESS',
  GET_SCHEDULED_ITEM_MEETING_DETAILS_FAIL = 'GET_SCHEDULED_ITEM_MEETING_DETAILS_FAIL',

  DISCONNECT_CALENDAR = 'DISCONNECT_CALENDAR',
  DISCONNECT_CALENDAR_SUCCESS = 'DISCONNECT_CALENDAR_SUCCESS',
  DISCONNECT_CALENDAR_FAIL = 'DISCONNECT_CALENDAR_FAIL',
}

export interface GenerateSchedule extends AxiosMiddlewareActionCreator {
  type: typeof SCHEDULING.GENERATE_SCHEDULE
}

export interface GenerateScheduleSuccess extends AxiosMiddlewareActionSuccess<SchedulingAccount, GenerateSchedule> {
  type: typeof SCHEDULING.GENERATE_SCHEDULE_SUCCESS
}

export interface GenerateScheduleFail extends AxiosMiddlewareActionFail<GenerateSchedule> {
  type: typeof SCHEDULING.GENERATE_SCHEDULE_FAIL
}

export interface SetAvailability extends AxiosMiddlewareActionCreator {
  type: typeof SCHEDULING.SET_AVAILABILITY
}

export interface SetAvailabilitySuccess extends AxiosMiddlewareActionSuccess<PreferenceData, SetAvailability> {
  type: typeof SCHEDULING.SET_AVAILABILITY_SUCCESS
}

export interface SetAvailabilityFail extends AxiosMiddlewareActionFail<SetAvailability> {
  type: typeof SCHEDULING.SET_AVAILABILITY_FAIL
}

export interface SetPreferences extends AxiosMiddlewareActionCreator {
  type: typeof SCHEDULING.SET_PREFERENCES
}

export interface SetPreferencesSuccess extends AxiosMiddlewareActionSuccess<PreferenceData, SetPreferences> {
  type: typeof SCHEDULING.SET_PREFERENCES_SUCCESS
}

export interface SetPreferencesFail extends AxiosMiddlewareActionFail<SetPreferences> {
  type: typeof SCHEDULING.SET_PREFERENCES_FAIL
}

export interface GetPreferences extends AxiosMiddlewareActionCreator {
  type: typeof SCHEDULING.GET_PREFERENCES
}

export interface GetPreferencesSuccess extends AxiosMiddlewareActionSuccess<PreferenceData, GetPreferences> {
  type: typeof SCHEDULING.GET_PREFERENCES_SUCCESS
}

export interface GetPreferencesFail extends AxiosMiddlewareActionFail<GetPreferences> {
  type: typeof SCHEDULING.GET_PREFERENCES_FAIL
}

export interface GetCalendarEvents extends AxiosMiddlewareActionCreator {
  type: typeof SCHEDULING.GET_CALENDAR_EVENTS
}

export interface GetCalendarEventsSuccess extends AxiosMiddlewareActionSuccess<UserSchedulingData, GetCalendarEvents> {
  type: typeof SCHEDULING.GET_CALENDAR_EVENTS_SUCCESS
}

export interface GetCalendarEventsFail extends AxiosMiddlewareActionFail<GetCalendarEvents> {
  type: typeof SCHEDULING.GET_CALENDAR_EVENTS_FAIL
}

export interface CreateCalendarEvent extends AxiosMiddlewareActionCreator {
  type: typeof SCHEDULING.CREATE_CALENDAR_EVENT
}

export interface CreateCalendarEventSuccess extends AxiosMiddlewareActionSuccess<SCHEDULING, CreateCalendarEvent> {
  type: typeof SCHEDULING.CREATE_CALENDAR_EVENT_SUCCESS
}

export interface CreateCalendarEventFail extends AxiosMiddlewareActionFail<CreateCalendarEvent> {
  type: typeof SCHEDULING.CREATE_CALENDAR_EVENT_FAIL
}

export interface GetAvailabilityWidgetToken extends AxiosMiddlewareActionCreator {
  type: typeof SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN
}

export interface GetAvailabilityWidgetTokenSuccess
  extends AxiosMiddlewareActionSuccess<UserSchedulingData, GetAvailabilityWidgetToken> {
  type: typeof SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN_SUCCESS
}

export interface GetAvailabilityWidgetTokenFail extends AxiosMiddlewareActionFail<GetAvailabilityWidgetToken> {
  type: typeof SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN_FAIL
}

export interface GetScheduledItemMeetingDetails extends AxiosMiddlewareActionCreator {
  itemID: number
  type: typeof SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS
}

export interface GetScheduledItemMeetingDetailsSuccess
  extends AxiosMiddlewareActionSuccess<ZoomMeetingData, GetScheduledItemMeetingDetails> {
  type: typeof SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS_SUCCESS
}

export interface GetScheduledItemMeetingDetailsFail extends AxiosMiddlewareActionFail<GetScheduledItemMeetingDetails> {
  type: typeof SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS_FAIL
}

export interface DisconnectCalendar extends AxiosMiddlewareActionCreator {
  type: typeof SCHEDULING.DISCONNECT_CALENDAR
}

export interface DisconnectCalendarSuccess extends AxiosMiddlewareActionSuccess<SCHEDULING, DisconnectCalendar> {
  type: typeof SCHEDULING.DISCONNECT_CALENDAR_SUCCESS
}

export interface DisconnectCalendarFail extends AxiosMiddlewareActionFail<DisconnectCalendar> {
  type: typeof SCHEDULING.DISCONNECT_CALENDAR_FAIL
}

export const generateSchedule = (userID: number): GenerateSchedule => {
  return {
    type: SCHEDULING.GENERATE_SCHEDULE,
    payload: {
      request: {
        method: 'POST',
        url: `/v1/users/${userID}/schedule`,
      },
    },
  }
}

export function setAvailabilityRules(userID: number | null, availabilityRules: WeeklyPeriod[]): SetAvailability {
  return {
    type: SCHEDULING.SET_AVAILABILITY,
    payload: {
      request: {
        method: 'POST',
        url: `/v1/users/${userID}/preferences/schedule`,
        data: {
          weekly_periods: availabilityRules,
        },
      },
    },
  }
}

export function setPreferences(userID: number | null, preferences: Preferences): SetPreferences {
  const scheduling_preferences = {
    user_id: userID,
    ...preferences,
  }
  return {
    type: SCHEDULING.SET_PREFERENCES,
    payload: {
      request: {
        method: 'POST',
        url: `/v1/users/${userID}/preferences/schedule`,
        data: {
          scheduling_preferences,
        },
      },
    },
  }
}

export function setTimezone(userID: number | null, weekly_periods: WeeklyPeriod[]): SetPreferences {
  return {
    type: SCHEDULING.SET_PREFERENCES,
    payload: {
      request: {
        method: 'POST',
        url: `/v1/users/${userID}/preferences/settimezone`,
        data: {
          weekly_periods,
        },
      },
    },
  }
}

export function updatePreferencesThunk(
  userID: number | null,
  preferences: Preferences
): ThunkAction<Promise<boolean>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    try {
      await dispatch(updatePreferences(userID, preferences))
      return getState().schedulingReducer.is_available_for_scheduled_items
    } catch (e) {
      return false
    }
  }
}

export function updatePreferences(userID: number | null, preferences: Preferences): SetPreferences {
  const scheduling_preferences = {
    ...preferences,
  }

  return {
    type: SCHEDULING.SET_PREFERENCES,
    payload: {
      request: {
        method: 'PATCH',
        url: `/v1/users/${userID}/preferences/schedule`,
        data: {
          scheduling_preferences,
        },
      },
    },
  }
}

export function fetchSchedulingPreferences(userID: userId = 'me'): GetPreferences {
  return {
    type: SCHEDULING.GET_PREFERENCES,
    payload: {
      request: {
        url: `/v1/users/${userID}/preferences/schedule`,
        method: 'GET',
      },
    },
  }
}

export function fetchCalendarEvents(userID: number): GetCalendarEvents {
  return {
    type: SCHEDULING.GET_CALENDAR_EVENTS,
    payload: {
      request: {
        method: 'GET',
        url: `/v1/users/${userID}/schedule`,
      },
    },
  }
}

export function getAvailabilityWidgetToken(userID: number): GetAvailabilityWidgetToken {
  return {
    type: SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN,
    payload: {
      request: {
        method: 'GET',
        url: `/v1/users/${userID}/preferences/schedule/token`,
      },
    },
  }
}

export function getScheduledItemMeetingDetails(itemID: number): GetScheduledItemMeetingDetails {
  return {
    type: SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS,
    itemID,
    payload: {
      request: {
        method: 'GET',
        url: `/v1/items/${itemID}/meeting`,
      },
    },
  }
}

export function disconnectCalendar(userID: userId = 'me'): DisconnectCalendar {
  return {
    type: SCHEDULING.DISCONNECT_CALENDAR,
    payload: {
      request: {
        method: 'POST',
        url: `/v1/users/${userID}/schedule/disconnect`,
      },
    },
  }
}

export type ScheduleInActionTypes =
  | GenerateSchedule
  | GenerateScheduleSuccess
  | GenerateScheduleFail
  | SetAvailability
  | SetAvailabilitySuccess
  | SetAvailabilityFail
  | SetPreferences
  | SetPreferencesSuccess
  | SetPreferencesFail
  | GetPreferences
  | GetPreferencesSuccess
  | GetPreferencesFail
  | GetCalendarEvents
  | GetCalendarEventsSuccess
  | GetCalendarEventsFail
  | CreateCalendarEvent
  | CreateCalendarEventSuccess
  | CreateCalendarEventFail
  | GetAvailabilityWidgetToken
  | GetAvailabilityWidgetTokenSuccess
  | GetAvailabilityWidgetTokenFail
  | GetScheduledItemMeetingDetails
  | GetScheduledItemMeetingDetailsFail
  | GetScheduledItemMeetingDetailsSuccess
  | DisconnectCalendar
  | DisconnectCalendarSuccess
  | DisconnectCalendarFail
