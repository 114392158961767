import { FeatureFeedbackState, FeatureKeys, FeedbackBoolPayload } from './types'
import { HashMap } from '../../utils/HashMap'
import { filterHashMapAll } from '../../utils/selectors'

export const getFeedbackByFeatureIDs = (
  state: FeatureFeedbackState,
  featureIDs: number[]
): HashMap<HashMap<FeedbackBoolPayload>> | {} => {
  if (state.meta[FeatureKeys.FilePreprocess].isLoading) {
    return {}
  }
  // @ts-ignore
  const filtered = filterHashMapAll(state[FeatureKeys.FilePreprocess], f => featureIDs.includes(f.feature_id))

  const flattened = Object.values(filtered)
    .map(f => Object.values(f))
    .flat()

  const result: HashMap<HashMap<FeedbackBoolPayload>> | {} = {}

  flattened.forEach(f => {
    // @ts-ignore
    if (!result[f.feature_id]) {
      // @ts-ignore
      result[f.feature_id] = {}
    }
    // @ts-ignore
    result[f.feature_id][f.feature_element] = f
  })
  return result
}
