import { HashMap } from '../../utils/HashMap'
import { initialLoadingErrorState, LoadingErrorState } from '../../utils/state'

export interface FeatureFeedback {
  id: number
  feature_id: number
  feature_key: FeatureKeys
  feature_element: string
  feedback_key: string
  user_id: number
  origin: string
  feedback_bool: 1 | 0
  created_at: string
  details?: string
}

export interface CreateFeedbackPayload {
  feature_key: string
  origin: string
  feedback_key: string
  feedback: FeedbackBoolPayload[]
}

export interface FeedbackBoolPayload {
  feature_element: string
  feature_id: number
  feedback_bool: 1 | 0 | null
}

export enum FeatureKeys {
  FilePreprocess = 'file_preprocess',
}

export interface FeatureFeedbackState {
  [FeatureKeys.FilePreprocess]: HashMap<HashMap<FeatureFeedback>>
  meta: {
    [FeatureKeys.FilePreprocess]: LoadingErrorState
  }
}

export const initialState: FeatureFeedbackState = {
  [FeatureKeys.FilePreprocess]: {},
  meta: {
    [FeatureKeys.FilePreprocess]: { ...initialLoadingErrorState },
  },
}
