import produce from 'immer'

import { FetchMessages, MessageActionType, MessageActionTypes, SendMessage } from './actions'
import { MessageState } from './types'
import { USER } from '../user/actions'
import { initialLoadedLoadingErrorState, initialLoadingErrorState } from '../../utils/state'

const initialMessageSendMeta = { ...initialLoadingErrorState, draftEventMeta: null, shoppingEligibility: null }
const initialMalwareScanMeta = {
  ...initialLoadingErrorState,
  clean_result: true,
  found_viruses: [],
  found_urls: [],
  scanner_response: [],
}

export const initialState: MessageState = {
  user: {},
  client: {},
  fetchingMeta: { ...initialLoadedLoadingErrorState },
  sendingMeta: { ...initialMessageSendMeta },
  statusMeta: { ...initialLoadingErrorState },
  malwareMeta: { ...initialMalwareScanMeta },
  loggedInUserID: null,
}

const determineContextID = (state: MessageState, action: FetchMessages | SendMessage): number => {
  if (action.contextID !== 'me') {
    return action.contextID
  } else if (state.loggedInUserID) {
    return state.loggedInUserID
  }

  throw Error('User must be logged in to send or fetch messages')
}

export default function messagesReducer(state: MessageState = initialState, action: MessageActionType): MessageState {
  return produce(state, (draft: MessageState) => {
    switch (action.type) {
      case USER.FETCH_USER_SUCCESS:
        if (action.meta.previousAction.targetUserID === 'me') {
          draft.loggedInUserID = action.payload.data.id
        }
        break

      case MessageActionTypes.FETCH_MESSAGES:
        draft.fetchingMeta = {
          isLoading: true,
          loaded: false,
          error: null,
        }
        break

      case MessageActionTypes.FETCH_MESSAGES_SUCCESS: {
        const messages = action.payload.data
        const { context } = action.meta.previousAction
        const contextID = determineContextID(draft, action.meta.previousAction)

        if (!draft[context][contextID]) {
          draft[context][contextID] = {}
        }

        draft.fetchingMeta = {
          isLoading: false,
          loaded: true,
          error: null,
        }

        if (messages) {
          messages.forEach(message => {
            draft[context][contextID][message.id] = message
          })
        }
        break
      }

      case MessageActionTypes.FETCH_MESSAGES_FAIL:
        draft.fetchingMeta = {
          error: action.error,
          loaded: false,
          isLoading: false,
        }
        break

      case MessageActionTypes.START_SENDING_MESSAGE:
        draft.sendingMeta = {
          ...initialMessageSendMeta,
          isLoading: true,
        }
        break

      case MessageActionTypes.SEND_MESSAGE:
        if (!draft.sendingMeta.isLoading) {
          draft.sendingMeta = {
            ...initialMessageSendMeta,
            isLoading: true,
          }
        }
        break

      case MessageActionTypes.SEND_MESSAGE_SUCCESS: {
        const { message, event_data, shopping_eligibility } = action.payload.data
        const { context } = action.meta.previousAction
        const contextID = determineContextID(draft, action.meta.previousAction)

        if (!draft[context][contextID]) {
          draft[context][contextID] = {}
        }

        draft.sendingMeta = {
          ...initialMessageSendMeta,
          draftEventMeta: event_data,
          shoppingEligibility: shopping_eligibility,
        }

        draft[context][contextID][message.id] = message
        break
      }

      case MessageActionTypes.SEND_MESSAGE_FAIL:
        draft.sendingMeta = {
          ...initialMessageSendMeta,
          error: action.error,
        }
        break

      case MessageActionTypes.SCAN_FOR_VIRUSES:
        if (!draft.sendingMeta.isLoading) {
          draft.sendingMeta = {
            ...initialMessageSendMeta,
            isLoading: true,
          }
        }

        draft.malwareMeta = {
          ...initialMalwareScanMeta,
        }
        break

      case MessageActionTypes.SCAN_FOR_VIRUSES_SUCCESS:
        draft.malwareMeta = action.payload.data
        break

      case MessageActionTypes.SCAN_FOR_VIRUSES_FAIL:
        draft.malwareMeta = {
          ...initialMalwareScanMeta,
        }
        break

      case MessageActionTypes.STATUS_CREATE:
      case MessageActionTypes.STATUS_END:
      case MessageActionTypes.FETCH_MESSAGE_CLIENT_READ:
        draft.statusMeta = {
          isLoading: true,
          error: null,
        }
        break

      case MessageActionTypes.STATUS_CREATE_SUCCESS:
      case MessageActionTypes.FETCH_MESSAGE_CLIENT_READ_SUCCESS:
        {
          draft.statusMeta.isLoading = false
          const response = action.payload.data
          const { messageID, context, contextID } = action.meta.previousAction

          if (response) {
            draft[context][contextID][messageID].statuses.push(response)
          }
        }
        break

      case MessageActionTypes.STATUS_END_SUCCESS:
        {
          draft.statusMeta.isLoading = false
          const response = action.payload.data
          const { messageID, context, contextID, messageStatusID } = action.meta.previousAction
          const statusIndex = state[context][contextID][messageID].statuses.findIndex(s => s.id === messageStatusID)

          if (response) {
            draft[context][contextID][messageID].statuses[statusIndex] = response
          }
        }
        break

      case MessageActionTypes.STATUS_CREATE_FAIL:
      case MessageActionTypes.STATUS_END_FAIL:
      case MessageActionTypes.FETCH_MESSAGE_CLIENT_READ_FAIL:
        draft.statusMeta = {
          isLoading: false,
          error: action.error,
        }
        break

      case MessageActionTypes.META_RESET:
        draft.sendingMeta = { ...initialMessageSendMeta }
        draft.fetchingMeta = { ...initialLoadedLoadingErrorState }
        break
    }
  })
}
