import { Client } from '../../../store/clients/reducer'
import portalURL from './portalURL'

// Helper function that fetches the Onboarding Questionnaire/Account link
export default function(client: Client): string | null {
  if (!client.productsMetadata) {
    return null
  }

  // If the user is in the portal experiment, this URL will be not null and that we should link them there instead of
  // the onboarding delivery page.
  if (client.productsMetadata.portal_url) {
    return portalURL(client)
  }

  return client.productsMetadata.questionnaire_url
}
