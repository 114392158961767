import React from 'react'
import { formatCurrency, formatDate } from '../../utils/formatting'
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import i18n from '../../i18n'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import useUserInfoState from '../common/useUserInfo'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { EarningsGoalProgress, getEarningsGoalProgress } from '../../selectors/user'
import styles from '../../styles'
import classNames from 'classnames'

const EarningsBar = withStyles({
  buffer: {
    height: 10,
    boxShadow: 'inset 0 1px 3px 0 #dbe0eb',
    backgroundColor: '#fafafc',
    animation: '',
  },
  bar1Buffer: {
    backgroundColor: styles.palette.lighterRed,
  },
  bar2Buffer: {
    background: `repeating-linear-gradient(45deg, ${styles.palette.lightRed}, ${styles.palette.lightRed} 2px, ${styles.palette.lighterRed} 3px, ${styles.palette.lighterRed} 3.5px)`,
  },
  dashed: {
    animation: 'unset',
  },
  dashedColorPrimary: {
    backgroundImage: 'unset',
  },
})(LinearProgress)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emoji: {
      fontSize: '2em',
      marginRight: '.3rem',
    },
    emojiLegend: {
      marginRight: '.3rem',
    },
    expandedHide: {
      display: 'none',
    },
    expandedLargestText: {
      fontSize: '1.2rem !important',
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    lineContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '20rem',
      '@media (min-width: 540px)': {
        '&:hover': {
          flexDirection: 'column-reverse',
          '& #titleContainer': {
            display: 'none',
          },
          '& #legendContainer': {
            display: 'flex',
          },
        },
      },
      '@media (max-width: 540px)': {
        flexDirection: 'column-reverse',
      },
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      '@media (max-width: 540px)': {
        alignItems: 'flex-end',
        marginBottom: '.5rem',
        order: 1,
      },
    },
    legendContainer: {
      display: 'none',
      justifyContent: 'space-between',
      alignItems: 'center',
      '@media (max-width: 540px)': {
        display: 'block',
        marginTop: '1.9rem',
      },
    },
    legendGroup: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      '@media (max-width: 540px)': {
        fontSize: '1rem',
        justifyContent: 'flex-start',
        padding: '0 0 .7rem',
      },
    },
    legend: {
      width: '.7em',
      height: '.7em',
      '@media (max-width: 540px)': {
        height: '1.13rem',
        marginRight: '.5rem',
        width: '1.13rem',
      },
    },
    bar: {
      margin: '1.3em 0 .8em',
      height: '.7em',
    },
    text: {
      fontSize: '.9em',
      fontFamily: styles.fonts.primary.family,
      fontWeight: styles.fonts.primary.weights.semiBold,
    },
    legendText: {
      fontSize: '.8em',
      paddingLeft: '.3em',
      fontFamily: styles.fonts.primary.family,
      fontWeight: styles.fonts.primary.weights.semiBold,
      '@media (max-width: 540px)': {
        fontSize: 'inherit',
        paddingLeft: '0',
      },
    },
  })
)

export interface EarningsProgressProps {
  expanded: boolean
}

const EarningsProgress: React.FC<EarningsProgressProps> = ({ expanded }) => {
  const classes = useStyles()
  const { timeZone, locale, id, currency } = useUserInfoState()

  const data = useSelector<AppState, EarningsGoalProgress | null>(state =>
    getEarningsGoalProgress(state.userPreferencesReducer, state.dashboardReducer, id)
  )

  if (!data) {
    return null
  }

  const dateEnd = formatDate(data.periodEnds, timeZone, locale, i18n.t('earnings__dateFormat'))

  const currentAmount = !!data ? data.current.amount : 0
  const goalAmount = !!data && data.goal ? data.goal.amount : 0
  const acceptedAmount = !!data ? data.accepted.amount : 0

  let currPct = 0
  if (!!data.goal && data.goal.amount > 0) {
    if (data.current.amount > data.goal.amount) {
      currPct = 100
    } else {
      currPct = (data.current.amount / data.goal.amount) * 100
    }
  }

  let acceptPct = 0
  if (!!data.goal && data.goal.amount > 0) {
    if (data.accepted.amount > data.goal.amount) {
      // It feels like it should be 100 - currPct. but no
      acceptPct = 100
    } else {
      acceptPct = (data.accepted.amount / data.goal.amount) * 100 + currPct
      if (acceptPct > 100 - currPct) {
        acceptPct = 100
      }
    }
  }

  return (
    <Box className={classes.container}>
      <Typography
        className={classNames({
          [classes.emoji]: true,
          [classes.expandedHide]: expanded,
        })}
      >
        {currPct < 100 && (
          <span role="img" aria-label="bullseye emoji earnings goal tracker">
            🎯
          </span>
        )}
        {currPct >= 100 && goalAmount > 0 && (
          <span role="img" aria-label="trophy emoji because you met your goal">
            🏆
          </span>
        )}
      </Typography>
      <Box
        className={classNames({
          [classes.lineContainer]: true,
        })}
      >
        <Box
          id={'titleContainer'}
          className={classNames({
            [classes.titleContainer]: true,
          })}
        >
          <Typography
            className={classNames({
              [classes.text]: true,
              [classes.expandedLargestText]: expanded,
            })}
          >
            {i18n.t('earnings__topNav__title')}
          </Typography>
          {!!data && data.periodEnds && (
            <Typography className={classes.text}>
              {i18n.t('earnings__topNav__subtitle', { end_date: dateEnd })}
            </Typography>
          )}
        </Box>
        <Box
          id="legendContainer"
          className={classNames({
            [classes.legendContainer]: true,
          })}
        >
          <Box className={classes.legendGroup}>
            <EarningsBar variant="buffer" className={classes.legend} value={100} valueBuffer={0} />
            <Typography className={classes.legendText}>
              {i18n.t('earnings__topNav__legend_earned', { amount: formatCurrency(currentAmount, currency, 0) })}
            </Typography>
          </Box>
          <Box className={classes.legendGroup}>
            <EarningsBar variant="buffer" className={classes.legend} value={0} valueBuffer={100} />
            <Typography className={classes.legendText}>
              {i18n.t('earnings__topNav__legend_accepted', { amount: formatCurrency(acceptedAmount, currency, 0) })}
            </Typography>
          </Box>
          <Typography className={classes.legendText}>
            <span role="img" aria-label="trophy emoji because you met your goal" className={classes.emojiLegend}>
              🏆
            </span>
            {i18n.t('earnings__topNav__legend_goal', { amount: formatCurrency(goalAmount, currency, 0) })}
          </Typography>
        </Box>
        <EarningsBar variant="buffer" value={currPct} valueBuffer={acceptPct} />
      </Box>
    </Box>
  )
}

export default EarningsProgress
