import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../store'
import { fetchTemplates } from '../../store/templates/actions'
import { Template, TemplateList } from '../../store/templates/types'
import { handlebarsTemplateContextSelector, templatesSelector } from '../../selectors/templates'
import { MessageContext } from '../../store/messages/types'
import { HandlebarsContextType } from '../../utils/handlebars'
import { UserInfo } from '../../store/user/reducers'
import { loggedInUserSelector } from '../../selectors/users'
import { Language } from '../../utils/consts'
import { sendUserEvent } from '../../store/events/actions'
import Templates from './Templates'
import useEditorState from './useEditorState'
import { OrderCTA } from '../../store/items/types'
import { EventTypes } from '../../store/events/types'

interface TemplatesContainerProps {
  context: MessageContext
  contextID: number
  orderID: number | null
  CTA: OrderCTA
}

export const TemplatesContainer: React.FC<TemplatesContainerProps> = ({ context, contextID, orderID, CTA }) => {
  const dispatch = useDispatch()
  const { insertTemplate } = useEditorState()
  const user = useSelector<AppState, UserInfo | null>(state => loggedInUserSelector(state.userReducer))
  const handlebarsContext = useSelector<AppState, HandlebarsContextType>(state =>
    handlebarsTemplateContextSelector(state, context, contextID, user ? user.id : null, [], orderID)
  )
  let language: Language = 'en'

  if (!!handlebarsContext.order && !!handlebarsContext.order.language) {
    language = handlebarsContext.order.language
  }

  const [templates, templatesAreLoading] = useSelector<AppState, [TemplateList, boolean]>(state =>
    templatesSelector(state.templatesReducer, {
      context,
      contextID,
      language,
      cta: CTA,
      itemState: state.itemsReducer,
      clientState: state.clientReducer,
      userState: user,
    })
  )

  const handleInsertTemplate = useCallback(
    (template: Template) => {
      insertTemplate(template, handlebarsContext)
      dispatch(
        sendUserEvent({
          event: EventTypes.MessageTemplateUsed,
          variables: {
            template_id: template.id,
            client_id: handlebarsContext.client ? handlebarsContext.client.id : null,
          },
        })
      )
    },
    [handlebarsContext, insertTemplate, dispatch]
  )

  React.useEffect(() => {
    if (!Object.keys(templates).length && !templatesAreLoading) {
      const templatesFilters = { context, language }
      dispatch(fetchTemplates(templatesFilters))
    }
  }, [dispatch, context, language, templates, templatesAreLoading])

  return <Templates templates={templates} handleInsert={handleInsertTemplate} />
}

function propsAreEqual(next: TemplatesContainerProps, prev: TemplatesContainerProps) {
  return (
    prev.context === next.context &&
    prev.contextID === next.contextID &&
    prev.orderID === next.orderID &&
    prev.CTA === next.CTA
  )
}

const memoizedTemplatesContainer = React.memo(TemplatesContainer, propsAreEqual)
// @ts-ignore
memoizedTemplatesContainer.whyDidYouRender = true

export default memoizedTemplatesContainer
