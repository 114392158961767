import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'

import { KnownQuestionnaireKey, QuestionUpdateData, Questionnaire } from '../orders/questionnaire/types'

export enum ClientQuestionnaireActions {
  FETCH_CLIENT_QUESTIONNAIRE = 'FETCH_CLIENT_QUESTIONNAIRE',
  FETCH_CLIENT_QUESTIONNAIRE_SUCCESS = 'FETCH_CLIENT_QUESTIONNAIRE_SUCCESS',
  FETCH_CLIENT_QUESTIONNAIRE_FAIL = 'FETCH_CLIENT_QUESTIONNAIRE_FAIL',

  UPDATE_QUESTIONNAIRE = 'UPDATE_QUESTIONNAIRE',
  UPDATE_QUESTIONNAIRE_SUCCESS = 'UPDATE_QUESTIONNAIRE_SUCCESS',
  UPDATE_QUESTIONNAIRE_FAIL = 'UPDATE_QUESTIONNAIRE_FAIL',
}

export interface FetchClientQuestionnaire extends AxiosMiddlewareActionCreator {
  clientID: number
  type: typeof ClientQuestionnaireActions.FETCH_CLIENT_QUESTIONNAIRE
}

export interface FetchClientQuestionnaireSuccess
  extends AxiosMiddlewareActionSuccess<Questionnaire, FetchClientQuestionnaire> {
  type: typeof ClientQuestionnaireActions.FETCH_CLIENT_QUESTIONNAIRE_SUCCESS
}

export interface FetchClientQuestionnaireFail extends AxiosMiddlewareActionFail<FetchClientQuestionnaire> {
  type: typeof ClientQuestionnaireActions.FETCH_CLIENT_QUESTIONNAIRE_FAIL
}

export function fetchClientQuestionnaire(clientID: number): FetchClientQuestionnaire {
  return {
    type: ClientQuestionnaireActions.FETCH_CLIENT_QUESTIONNAIRE,
    clientID: clientID,
    payload: {
      request: {
        url: `/v1/orders/client/${clientID}/questionnaire?cb=${new Date().toISOString()}`,
      },
    },
  }
}

export interface UpdateQuestionnaire extends AxiosMiddlewareActionCreator {
  clientID: number
  questionName: KnownQuestionnaireKey
  type: typeof ClientQuestionnaireActions.UPDATE_QUESTIONNAIRE
}

export interface UpdateQuestionnaireSuccess extends AxiosMiddlewareActionSuccess<Questionnaire, UpdateQuestionnaire> {
  type: typeof ClientQuestionnaireActions.UPDATE_QUESTIONNAIRE_SUCCESS
}

export interface UpdateQuestionnaireFail extends AxiosMiddlewareActionFail<UpdateQuestionnaire> {
  type: typeof ClientQuestionnaireActions.UPDATE_QUESTIONNAIRE_FAIL
}

export function updateQuestionnaire(
  clientID: number,
  data: QuestionUpdateData,
  questionName: KnownQuestionnaireKey
): UpdateQuestionnaire {
  return {
    type: ClientQuestionnaireActions.UPDATE_QUESTIONNAIRE,
    clientID,
    questionName,
    payload: {
      request: {
        url: `/v1/clients/${clientID}/questionnaire`,
        method: 'PATCH',
        data,
      },
    },
  }
}

export type ClientQuestionnaireAction =
  | FetchClientQuestionnaire
  | FetchClientQuestionnaireSuccess
  | FetchClientQuestionnaireFail
  | UpdateQuestionnaire
  | UpdateQuestionnaireSuccess
  | UpdateQuestionnaireFail
