import diff from 'object-diff'

import { AssignmentPreferences, CommunicationPreferences, UserPreferences, UserGoal, UserGoalPayload } from './reducers'
import { userId } from '../user/reducers'
import { FetchUserSuccess } from '../user/actions'
import {
  AxiosMiddlewareActionSuccess,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionCreator,
} from '../../utils/axios'

export enum UserPreferencesActionTypes {
  FETCH_USER_PREFERENCES = 'FETCH_USER_PREFERENCES',
  FETCH_USER_PREFERENCES_SUCCESS = 'FETCH_USER_PREFERENCES_SUCCESS',
  FETCH_USER_PREFERENCES_FAIL = 'FETCH_USER_PREFERENCES_FAIL',

  UPDATE_USER_COMMUNICATION_PREFERENCES = 'UPDATE_USER_COMMUNICATION_PREFERENCES',
  UPDATE_USER_COMMUNICATION_PREFERENCES_SUCCESS = 'UPDATE_USER_COMMUNICATION_PREFERENCES_SUCCESS',
  UPDATE_USER_COMMUNICATION_PREFERENCES_FAIL = 'UPDATE_USER_COMMUNICATION_PREFERENCES_FAIL',

  UPDATE_USER_ASSIGNMENT_PREFERENCES = 'UPDATE_USER_ASSIGNMENT_PREFERENCES',
  UPDATE_USER_ASSIGNMENT_PREFERENCES_SUCCESS = 'UPDATE_USER_ASSIGNMENT_PREFERENCES_SUCCESS',
  UPDATE_USER_ASSIGNMENT_PREFERENCES_FAIL = 'UPDATE_USER_ASSIGNMENT_PREFERENCES_FAIL',

  UPDATE_EARNINGS_GOAL = 'UPDATE_EARNINGS_GOAL',
  UPDATE_EARNINGS_GOAL_SUCCESS = 'UPDATE_EARNINGS_GOAL_SUCCESS',
  UPDATE_EARNINGS_GOAL_FAIL = 'UPDATE_EARNINGS_GOAL_FAIL',
}

export interface FetchUserPreferences extends AxiosMiddlewareActionCreator {
  type: typeof UserPreferencesActionTypes.FETCH_USER_PREFERENCES
  targetUserID: userId
}

export interface FetchUserPreferencesSuccess
  extends AxiosMiddlewareActionSuccess<UserPreferences, FetchUserPreferences> {
  type: typeof UserPreferencesActionTypes.FETCH_USER_PREFERENCES_SUCCESS
}

export interface FetchUserPreferencesFailure extends AxiosMiddlewareActionFail {
  type: typeof UserPreferencesActionTypes.FETCH_USER_PREFERENCES_FAIL
}

export interface UpdateUserCommunicationPreferences extends AxiosMiddlewareActionCreator {
  type: typeof UserPreferencesActionTypes.UPDATE_USER_COMMUNICATION_PREFERENCES
  targetUserID: userId
}

export interface UpdateUserCommunicationPreferencesSuccess
  extends AxiosMiddlewareActionSuccess<UserPreferences, UpdateUserCommunicationPreferences> {
  type: typeof UserPreferencesActionTypes.UPDATE_USER_COMMUNICATION_PREFERENCES_SUCCESS
}

export interface UpdateUserCommunicationPreferencesFailure extends AxiosMiddlewareActionFail {
  type: typeof UserPreferencesActionTypes.UPDATE_USER_COMMUNICATION_PREFERENCES_FAIL
}

export interface UpdateUserAssignmentPreferences extends AxiosMiddlewareActionCreator {
  type: typeof UserPreferencesActionTypes.UPDATE_USER_ASSIGNMENT_PREFERENCES
  targetUserID: userId
}

export interface UpdateUserAssignmentPreferencesSuccess
  extends AxiosMiddlewareActionSuccess<UserPreferences, UpdateUserAssignmentPreferences> {
  type: typeof UserPreferencesActionTypes.UPDATE_USER_ASSIGNMENT_PREFERENCES_SUCCESS
}

export interface UpdateUserAssignmentPreferencesFailure extends AxiosMiddlewareActionFail {
  type: typeof UserPreferencesActionTypes.UPDATE_USER_ASSIGNMENT_PREFERENCES_FAIL
}

interface UpdateEarningsGoal extends AxiosMiddlewareActionCreator {
  type: typeof UserPreferencesActionTypes.UPDATE_EARNINGS_GOAL
}

interface UpdateEarningsGoalSuccess extends AxiosMiddlewareActionSuccess<UserGoal, UpdateEarningsGoal> {
  type: typeof UserPreferencesActionTypes.UPDATE_EARNINGS_GOAL_SUCCESS
}

interface UpdateEarningsGoalFail extends AxiosMiddlewareActionFail<UpdateEarningsGoal> {
  type: typeof UserPreferencesActionTypes.UPDATE_EARNINGS_GOAL_FAIL
}

export function fetchUserPreferences(userID: userId = 'me'): FetchUserPreferences {
  return {
    type: UserPreferencesActionTypes.FETCH_USER_PREFERENCES,
    targetUserID: userID,
    payload: {
      request: {
        url: `/v1/users/${userID}/preferences`,
      },
    },
  }
}

export function updateUserCommunicationPreferences(
  oldData: CommunicationPreferences | Partial<CommunicationPreferences>,
  newData: CommunicationPreferences | Partial<CommunicationPreferences>,
  userID: userId = 'me'
): UpdateUserCommunicationPreferences | null {
  const delta = diff(oldData, newData)

  if (Object.keys(delta).length === 0) {
    return null
  }

  return {
    type: UserPreferencesActionTypes.UPDATE_USER_COMMUNICATION_PREFERENCES,
    targetUserID: userID,
    payload: {
      request: {
        url: `/v1/users/${userID}/preferences`,
        method: 'PATCH',
        data: {
          communication: delta,
        },
      },
    },
  }
}

export function updateUserAssignmentPreferences(
  oldData: AssignmentPreferences,
  newData: AssignmentPreferences,
  userID: userId = 'me'
): UpdateUserAssignmentPreferences {
  const delta = diff(oldData, newData)

  return {
    type: UserPreferencesActionTypes.UPDATE_USER_ASSIGNMENT_PREFERENCES,
    targetUserID: userID,
    payload: {
      request: {
        url: `/v1/users/${userID}/preferences`,
        method: 'PATCH',
        data: {
          assignment: delta,
        },
      },
    },
  }
}

export function updateEarningsGoal(amount: number, userID: userId = 'me'): UpdateEarningsGoal {
  return {
    type: UserPreferencesActionTypes.UPDATE_EARNINGS_GOAL,
    payload: {
      request: {
        url: `/v1/users/${userID}/goals`,
        method: 'POST',
        data: {
          type: 'earnings',
          cadence: 'week',
          amount,
        } as UserGoalPayload,
      },
    },
  }
}

export type UserPreferencesActionType =
  | FetchUserPreferences
  | FetchUserPreferencesSuccess
  | FetchUserPreferencesFailure
  | UpdateUserCommunicationPreferences
  | UpdateUserCommunicationPreferencesSuccess
  | UpdateUserCommunicationPreferencesFailure
  | FetchUserSuccess
  | UpdateUserAssignmentPreferences
  | UpdateUserAssignmentPreferencesSuccess
  | UpdateUserAssignmentPreferencesFailure
  | UpdateEarningsGoal
  | UpdateEarningsGoalSuccess
  | UpdateEarningsGoalFail
