import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DigitalAnalysis from './DigitalAnalysis'
import { AppState } from '../../../store'
import { Critique } from '../../../store/critique/types'
import { itemNeedsFetching } from '../../../utils/state'
import { fetchCritique } from '../../../store/critique/actions'
import { getCritiqueResults } from '../../../store/critique/selectors'

interface DigitalAnalysisContainerProps {
  documentDigest: string
}

const DigitalAnalysisContainer: React.FC<DigitalAnalysisContainerProps> = ({ documentDigest }) => {
  const dispatch = useDispatch()
  const [critique, needToFetchCritique] = useSelector<AppState, [Critique | null, boolean]>(state => {
    const needsFetching = itemNeedsFetching(documentDigest, state.critiqueReducer.meta)
    const critique = !!state.critiqueReducer.critiques[documentDigest]
      ? state.critiqueReducer.critiques[documentDigest]
      : null

    return [critique, needsFetching]
  })

  React.useEffect(() => {
    if (needToFetchCritique) {
      dispatch(fetchCritique(documentDigest))
    }
  }, [dispatch, documentDigest, needToFetchCritique])

  if (!critique) {
    return null
  }

  const industries = critique.industries.filter(i => !!i && !!i.name)
  const competencies = critique.competencies.filter(c => !!c && !!c.name).map(c => c.name)
  const critiqueResults = getCritiqueResults(critique)

  return <DigitalAnalysis industries={industries} competencies={competencies} critiqueResults={critiqueResults} />
}

export default DigitalAnalysisContainer
