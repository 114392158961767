import React, { useEffect } from 'react'

import auth0 from '../../utils/auth0'
import { clearAllAuthCookies, setPostLoginRedirectFromUrl } from '../../utils/auth'
import Loader from '../common/Loader'
import { Routes } from '../../utils/consts'

export const LogoutPage: React.FC = () => {
  useEffect(() => {
    setPostLoginRedirectFromUrl()
    clearAllAuthCookies()
    auth0.logout({
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      returnTo: window.location.origin + Routes.Login,
    })
  }, [])

  return <Loader fullPage={true} />
}

export default LogoutPage
