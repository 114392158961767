import React, { useCallback, useState } from 'react'
import i18n from '../../../i18n'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CalendarStyles from '../../Scheduling/Styles'
import { useDispatch, useSelector } from 'react-redux'
import { FulfillableOrderItemDisplay } from '../../Orders/OrderItems'
import { addMinutes, format, getDay, isAfter, isSameDay, differenceInMinutes } from 'date-fns'
import { callTime, dayOfWeek } from '../../Scheduling/utils'
import { OrderItemStates } from '../../../store/items/types'
import JoinMeetingDialog from '../../Scheduling/PhoneConsultation/JoinMeetingDialog'
import PhoneConsultationAction from './PhoneConsultationAction'
import { FeatureTags, UserInfo } from '../../../store/user/reducers'
import { getScheduledItemMeetingDetails } from '../../../store/scheduling/actions'
import { AppState } from '../../../store'
import { useHistory } from 'react-router'
import { ZoomMeetingData } from '../../../store/scheduling/types'
import AsyncComponent from '../../common/AsyncComponent'
import useUserInfoState from '../../common/useUserInfo'
import { utcToZonedTime } from 'date-fns-tz'
import ErrorBoundary from '../../common/ErrorBoundary'
import { applyTemplateBasedUponOrderCTA } from '../../../store/templates/actions'
import Link from '@material-ui/core/Link'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import RecordVideoOverIcon from '@material-ui/icons/VideoCall'
import DescriptionIcon from '@material-ui/icons/Description'
import Tooltip from '@material-ui/core/Tooltip'
import { formatDate } from '../../../utils/formatting'

const InBrowserCall = React.lazy(() => import('../../Scheduling/PhoneConsultation/InBrowserCall'))

interface PhoneConsultationProps {
  consultationItems: FulfillableOrderItemDisplay[]
  clientID: number
  showTranscripts: boolean
  showAudio: boolean
  showVideo: boolean
}

export const wrapperStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingBottom: '1em',
      backgroundColor: 'white',
      border: '1px solid #D5D9E0',
      borderRadius: '.1875rem',
      color: 'black',
      '@media (max-width: 1024px)': {
        border: 'none',
        borderBottom: 'none',
        marginBottom: '0 !important',
        width: '75%',
      },
      '@media (max-width: 560px)': {
        width: '100%',
      },
    },
    titleContainer: {
      display: 'flex',
    },

    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      padding: '.9375rem 1.5rem',
      borderBottom: '1px solid #D5D9E0',
      '@media (max-width: 1024px)': {
        display: 'none',
      },
    },
    title: {
      display: 'inline-flex',
      justifyContent: 'center',
      flexDirection: 'column',
      flexGrow: 1,
      fontWeight: 600,
    },

    itemsContainer: {
      marginTop: '1rem',
      padding: '0 1.5rem',
      '@media (max-width: 1024px)': {
        borderBottom: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '.9375rem 1rem',
      },
      '@media (max-width: 560px)': {
        flexDirection: 'column',
        paddingBottom: '0',
      },
    },
    callHistoryContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
    },
    callHistory: {
      width: '100%',
      marginBottom: '.3rem',
      fontSize: '.8rem',
      lineHeight: '1rem',
      fontWeight: 500,
    },
    callHistorySubtitle: {
      fontSize: '.8rem',
      lineHeight: '1.2rem',
    },
    callHistoryLink: {
      fontSize: '.8rem',
      lineHeight: '1.2rem',
      color: CalendarStyles.color.order,
    },
    message: {
      fontSize: '.75rem',
      lineHeight: '1rem',
      marginTop: '1.2rem',
      '@media (max-width: 1024px)': {
        display: 'none',
      },
    },
    ordersContainer: {
      '@media (max-width: 1024px)': {
        width: '55%',
      },
      '@media (max-width: 560px)': {
        marginBottom: '1rem',
        width: '100%',
      },
    },
    icon: {
      width: '1.1rem',
      height: '.8rem',
      paddingTop: '.1rem',
    },
    joinButton: {
      width: '100%',
      padding: '0.5rem',
      backgroundColor: CalendarStyles.color.order,
      color: 'white',
      textTransform: 'none',
      fontSize: '.8rem',
      '&:hover': {
        backgroundColor: CalendarStyles.color.joinHover,
      },
    },
    sendButton: {
      width: '100%',
      padding: '0.5rem',
      backgroundColor: 'white',
      color: CalendarStyles.color.order,
      textTransform: 'none',
      fontSize: '.8rem',
      borderColor: CalendarStyles.color.sendButton,
      '&:hover': {
        backgroundColor: CalendarStyles.color.sendHover,
      },
      '&:active': {
        backgroundColor: CalendarStyles.color.sendActive,
      },
    },
    link: {
      display: 'block',
      textDecoration: 'underline',
    },
    linkText: {
      fontSize: '.8rem',
    },
  })
)

const PhoneConsultation: React.FC<PhoneConsultationProps> = ({
  clientID,
  consultationItems,
  showTranscripts,
  showAudio,
  showVideo,
}) => {
  const classes = wrapperStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { isAdmin, timeZone, locale } = useUserInfoState()
  const current = utcToZonedTime(new Date(), timeZone)
  const [openZoom, setOpenZoom] = useState(false)
  const [inBrowserCall, showInBrowserCall] = useState(false)
  const zoomSdkKey = useSelector<AppState, string>(store =>
    store.configReducer.config ? store.configReducer.config.zoom.sdk_key : ''
  )

  const user = useSelector<AppState, UserInfo | null>(state => state.userReducer.getLoggedInUser())

  const itemsSortedByScheduledFor = consultationItems
    .sort((a, b) => {
      if (!a.due_date) return 1
      if (!b.due_date) return -1
      return new Date(a.due_date).getTime() - new Date(b.due_date).getTime()
    })
    .filter(item => (item as FulfillableOrderItemDisplay).itemState !== OrderItemStates.Closed)

  const firstOpenItem = itemsSortedByScheduledFor[0] as FulfillableOrderItemDisplay

  const completedItems = consultationItems.filter(
    item =>
      (item as FulfillableOrderItemDisplay).history.completed?.some(s => s.state === OrderItemStates.Closed) ||
      (item as FulfillableOrderItemDisplay).itemState === OrderItemStates.Closed
  )

  const zoomMeetingDetails = useSelector<AppState, ZoomMeetingData | null>(state =>
    firstOpenItem?.id ? state.itemsReducer.itemsMeetingInfo[firstOpenItem.id] : null
  )

  const reschedule = useCallback(async () => {
    if (user && firstOpenItem) {
      await dispatch(
        applyTemplateBasedUponOrderCTA(user.id, clientID, firstOpenItem.order_id, OrderItemStates.RescheduleMessage)
      )
    }

    history.push({
      pathname: `/client/${clientID}/messages`,
      search: window.location.search,
      state: {
        reschedule: true,
      },
    })
  }, [firstOpenItem, clientID, dispatch, history, user])

  const handleZoomViaBrowser = useCallback(() => {
    if (firstOpenItem && firstOpenItem.meeting_id) {
      showInBrowserCall(true)
    }
    setOpenZoom(false)
  }, [firstOpenItem])

  const handleOpenZoom = async () => {
    if (firstOpenItem && firstOpenItem.meeting_id && firstOpenItem.id) {
      await dispatch(getScheduledItemMeetingDetails(firstOpenItem.id))
    }
    setOpenZoom(true)
  }
  const itemAction = () => {
    const due_date = utcToZonedTime(firstOpenItem.due_date || 0, timeZone)
    switch (firstOpenItem.itemState) {
      case OrderItemStates.ConductCall:
      case OrderItemStates.ConductInterview:
        if (isAfter(current, addMinutes(due_date, 30))) {
          return (
            <PhoneConsultationAction
              primary={true}
              handleClick={reschedule}
              label={'schedule_order_reschedule_cta'}
              items={firstOpenItem}
              disabled={firstOpenItem.meeting_occurred}
            />
          )
        } else if (isSameDay(current, due_date)) {
          return (
            <PhoneConsultationAction
              primary={true}
              handleClick={handleOpenZoom}
              label={'schedule_order_join_call_cta'}
              items={firstOpenItem}
            />
          )
        } else {
          return (
            <PhoneConsultationAction
              primary={false}
              handleClick={reschedule}
              label={'schedule_order_reschedule_cta'}
              items={firstOpenItem}
            />
          )
        }

      case OrderItemStates.AwaitingScheduling:
        return (
          <PhoneConsultationAction
            primary={false}
            handleClick={reschedule}
            label={'schedule_order_awaiting_customer_cta'}
            items={firstOpenItem}
          />
        )
    }
  }

  const itemTitle = (item: FulfillableOrderItemDisplay) => {
    if (item && item.compound_item_key && item.compound_item_key.includes(FeatureTags.InterviewCoach)) {
      return (
        <Typography variant={'body2'} className={classes.title}>
          {i18n.t('schedule_order_interview')}
        </Typography>
      )
    } else {
      return (
        <Typography variant={'body2'} className={classes.title}>
          {i18n.t('schedule_order_phone_call')}
        </Typography>
      )
    }
  }

  const itemSubTitle = (item: FulfillableOrderItemDisplay) => {
    if (item.itemState === OrderItemStates.ConductCall || item.itemState === OrderItemStates.ConductInterview) {
      return (
        <Typography variant={'body2'} className={classes.callHistoryLink}>
          {item.due_date && !item.meeting_occurred && callTime(current, utcToZonedTime(item.due_date || 0, timeZone))}
        </Typography>
      )
    }
  }

  const itemMessage = () => {
    switch (firstOpenItem.itemState) {
      case OrderItemStates.SchedulePhoneCall:
      case OrderItemStates.ScheduleInterview:
      case OrderItemStates.AwaitingScheduling:
        return <>{i18n.t('schedule_order_send_note')}</>
      case OrderItemStates.SendSummary:
        return <>{i18n.t('schedule_order_transcript_note')}</>
      default:
    }
  }

  return (
    <ErrorBoundary>
      <>
        <Box className={classes.root}>
          <Box className={classes.headerContainer}>
            {firstOpenItem ? itemTitle(firstOpenItem) : itemTitle(completedItems[0])}
            {firstOpenItem ? itemSubTitle(firstOpenItem) : ''}
          </Box>
          {firstOpenItem && (
            <Box className={classes.itemsContainer}>
              <Box className={classes.ordersContainer}>{itemAction()}</Box>
              <Typography className={classes.message} variant={'body2'}>
                {itemMessage()}
              </Typography>
              {isAdmin && !!firstOpenItem.meeting_scheduled_at && !!firstOpenItem.meeting_scheduled_for && (
                <Box>
                  <Typography variant={'body2'} className={classes.callHistory}>
                    {i18n.t('schedule_order_side_call_scheduled')}
                    <Box>
                      <Typography variant={'body2'} className={classes.callHistorySubtitle}>
                        for: {formatDate(firstOpenItem.meeting_scheduled_for, timeZone, locale, 'MM/dd/yyyy HH:mm:ss')}
                      </Typography>
                      <Typography variant={'body2'} className={classes.callHistorySubtitle}>
                        at: {formatDate(firstOpenItem.meeting_scheduled_at, timeZone, locale, 'MM/dd/yyyy HH:mm:ss')}
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {completedItems && completedItems.length > 0 && (
            <Box className={classes.itemsContainer}>
              <Box>
                <Typography variant={'body2'} className={classes.callHistory}>
                  {i18n.t('schedule_order_side_call_history')}
                </Typography>
                {completedItems.map(item => {
                  const completed_date = utcToZonedTime(item?.history?.active[0]?.started_at || 0, timeZone)
                  return (
                    <>
                      <Box key={item.id} className={classes.callHistoryContent}>
                        <Typography variant={'body2'} className={classes.callHistorySubtitle}>
                          {format(completed_date, 'MM/dd/yyyy')} ({dayOfWeek[getDay(completed_date)]}){' '}
                          {isAdmin &&
                            !!item.meeting_started_at &&
                            !!item.meeting_ended_at &&
                            i18n.t('schedule_order_side_call_history_duration', {
                              duration: differenceInMinutes(
                                new Date(item.meeting_ended_at),
                                new Date(item.meeting_started_at)
                              ),
                            })}
                        </Typography>
                        {showTranscripts && item.has_transcript && (
                          <Tooltip title={i18n.t('schedule_order_transcript_cta') || 'Download Transcript'}>
                            <Link href={`/api/v1/items/${item.id}/transcript`} target="_blank" className={classes.link}>
                              <DescriptionIcon />
                            </Link>
                          </Tooltip>
                        )}
                        {showAudio && item.has_audio && (
                          <Tooltip title={i18n.t('schedule_order_audio_cta') || 'Download Audio File'}>
                            <Link href={`/api/v1/items/${item.id}/audio`} target="_blank" className={classes.link}>
                              <RecordVoiceOverIcon />
                            </Link>
                          </Tooltip>
                        )}
                        {showVideo && item.has_video && (
                          <Tooltip title={i18n.t('schedule_order_video_cta') || 'Download Video File'}>
                            <Link href={`/api/v1/items/${item.id}/video`} target="_blank" className={classes.link}>
                              <RecordVideoOverIcon />
                            </Link>
                          </Tooltip>
                        )}
                      </Box>
                    </>
                  )
                })}
              </Box>
            </Box>
          )}
        </Box>
        {zoomMeetingDetails && (
          <JoinMeetingDialog
            isOpen={openZoom}
            setIsOpen={setOpenZoom}
            handleZoomViaBrowser={handleZoomViaBrowser}
            zoomMeetingDetails={zoomMeetingDetails}
          />
        )}
        {zoomMeetingDetails &&
          zoomMeetingDetails.is_scheduled &&
          inBrowserCall &&
          firstOpenItem &&
          user &&
          user.email_signature &&
          user.id && (
            <AsyncComponent>
              <InBrowserCall
                signature={zoomMeetingDetails.zoom_host_signature}
                meetingNumber={zoomMeetingDetails.zoom_meeting_id}
                sdkKey={zoomSdkKey}
                userName={user.email_signature}
                userEmail={`user${user.id}@oms.talentinc.com`}
                password={zoomMeetingDetails.zoom_password}
              />
            </AsyncComponent>
          )}
      </>
    </ErrorBoundary>
  )
}

export default PhoneConsultation
