import { UserPreferencesState } from '../store/user_preferences/reducers'
import { BasePay } from '../store/orders/types'
import {
  UserDashboardState,
  UserDashboardMacro,
  UserDashboardEarnings,
  StringError,
  UserIndustryCount,
  PayHistory,
  YtdAndRelativeStats,
} from '../store/dashboard/types'
import { DashboardVariants } from '../components/Dashboard'
import { UserInfo, Groups } from '../store/user/reducers'
import { Currency } from '../utils/formatting'

export interface Progress<T> {
  currentValue: T | null
  goal: number | null
}

// We want to nudge writers to close orders, not to grab a bunch and sit on them
export const getEarningsProgress = (
  prefState: UserPreferencesState,
  dashState: UserDashboardState,
  userID: number
): Progress<BasePay> | null => {
  try {
    const userPreferences = prefState.users[userID]
    const earnedPay = dashState.earnings.users[userID].earned_pay
    return {
      currentValue: earnedPay,
      goal: !!userPreferences.payment.weekly_earnings_goal ? userPreferences.payment.weekly_earnings_goal.amount : null,
    }
  } catch (e) {
    return null
  }
}

export const getMacroStats = (state: UserDashboardState, userID: number): UserDashboardMacro | null => {
  try {
    return state.macro.users[userID]
  } catch (e) {
    return null
  }
}

export interface OutputDynamicsViewModel {
  output_dynamics: StringError
  isLoaded: boolean
}

export const getOutputDynamics = (state: UserDashboardState, userID: number): OutputDynamicsViewModel => {
  try {
    const loaded = state.relative.meta.global.loaded
    return { output_dynamics: state.relative.users[userID].output_dynamics, isLoaded: loaded }
  } catch (e) {
    // if we error here, the child should render an error card correctly
    return { output_dynamics: { value: '', error: e }, isLoaded: true }
  }
}

export const didMacroErrorForUser = (state: UserDashboardState, userID: number): boolean => {
  const meta = state.macro.meta.individual[userID]
  return !!meta && !meta.isLoading && meta.loaded && !!meta.error
}

export const getUserTimeZone = (user: UserInfo): string => {
  return user.timezone_string
}

export const getUserCurrency = (user: UserInfo): Currency => {
  return user.payment_currency
}

export const getDashboardVariant = (user: UserInfo): DashboardVariants => {
  if (user.groups.includes(Groups.Offshore)) {
    return DashboardVariants.Offshore
  }

  if (user.tag_keys.includes('onboarding-mentee')) {
    return DashboardVariants.Mentee
  }

  if (user.tag_keys.includes('new-hire')) {
    return DashboardVariants.NewHire
  }

  if (user.tag_keys.includes('inactive')) {
    return DashboardVariants.InactiveIC
  }

  return DashboardVariants.ActiveIC
}

export const getUserMentor = (user: UserInfo): string | null => {
  const mentorTag = user.tag_keys.find(key => key.includes('-mentor'))

  if (!!mentorTag) {
    const name = mentorTag.substring(0, mentorTag.indexOf('-mentor'))
    return name.charAt(0).toUpperCase() + name.slice(1)
  }

  return null
}

export const getOrderProgress = (
  dashState: UserDashboardState,
  userPrefState: UserPreferencesState,
  userID: number
): Progress<number> | null => {
  if (!dashState.macro.users[userID]) {
    return null
  }

  if (!userPrefState.users[userID]) {
    return null
  }
  return {
    currentValue: dashState.macro.users[userID].orders_done_this_week,
    goal: userPrefState.users[userID].assignment.assignments_per_week,
  }
}

export const getFirstDraftAvg = (state: UserDashboardState, userID: number): number | null => {
  if (!state.relative.users[userID]) {
    return null
  }

  return state.relative.users[userID].first_draft_last_30d_ave.value
}

export const getResponseTimeAvg = (state: UserDashboardState, userID: number): number | null => {
  if (!state.relative.users[userID]) {
    return null
  }

  return state.relative.users[userID].response_time_last_30d_ave.value
}

export const isUserEarningsLoaded = (state: UserDashboardState, userID: number): boolean => {
  const { loaded = false, isLoading = false } = state.earnings.meta.individual[userID] || {}
  return loaded && !isLoading
}

export const getUserEarnings = (state: UserDashboardState, userID: number): UserDashboardEarnings | null => {
  if (
    !!state.earnings.meta.individual[userID] &&
    !state.earnings.meta.individual[userID].error &&
    state.earnings.meta.individual[userID].loaded
  ) {
    return state.earnings.users[userID]
  }
  return null
}

export const getIndustriesWrittenFor = (state: UserDashboardState, userID: number): UserIndustryCount[] | null => {
  if (!state.industries.users[userID] || !state.industries.users[userID].industries) {
    return null
  }

  return state.industries.users[userID].industries
}

export const getPayHistory = (state: UserDashboardState, userID: number): PayHistory[] => {
  if (!state.earnings.users[userID] || !state.earnings.users[userID].pay_history) {
    return []
  }

  return state.earnings.users[userID].pay_history
}

export const getPayStats = (state: UserDashboardState, userID: number): YtdAndRelativeStats | null => {
  if (!state.earnings.users[userID] || !state.earnings.users[userID].ytd_and_relative_stats) {
    return null
  }

  return state.earnings.users[userID].ytd_and_relative_stats
}
