import React from 'react'
import Input, { InputProps } from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import i18n from '../../i18n'

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      color: '#7a8498',
      backgroundColor: '#edf0f5',
      borderRadius: '3px',
      padding: '2px 5px',
      height: '2em',
    },
  })
)

export interface SearchInputProps extends InputProps {
  onClearClick: () => void
}

const SearchInput: React.FC<SearchInputProps> = props => {
  const classes = useStyles(props)

  return (
    <Input
      type="text"
      autoComplete="off"
      placeholder={i18n.t('searchInput__placeholder')}
      className={classes.input}
      disableUnderline
      fullWidth
      endAdornment={
        <InputAdornment onClick={props.onClearClick} position="end">
          <IconButton aria-label="button search clear" onClick={props.onClearClick}>
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      }
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      {...props}
    />
  )
}

export default SearchInput
