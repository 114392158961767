import every from 'lodash/every'
import some from 'lodash/some'
import { HashMap } from '../store/items/types'

export type filterFunction<S> = (obj: S) => boolean

export function filterHashMap<S>(hashMap: HashMap<S>, filterFunc: filterFunction<S>): HashMap<S> | {} {
  return Object.entries(hashMap)
    .filter(([, value]) => {
      return filterFunc(value)
    })
    .reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {})
}

export function filterHashMapAll<S>(
  hashMap: HashMap<HashMap<S>>,
  filterFunc: filterFunction<S>
): HashMap<HashMap<S>> | {} {
  return Object.entries(hashMap)
    .filter(([, value]) => {
      return every(value, filterFunc)
    })
    .reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {})
}

export function filterHashMapAny<S>(
  hashMap: HashMap<HashMap<S>>,
  filterFunc: filterFunction<S>
): HashMap<HashMap<S>> | {} {
  return Object.entries(hashMap)
    .filter(([, value]) => {
      return some(value, filterFunc)
    })
    .reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {})
}

export function filterPropEquals<T>(propName: keyof T, value: Date | string | number) {
  return function(obj: T) {
    if (typeof value !== typeof obj[propName]) {
      return false
    }
    // @ts-ignore
    return obj[propName] === value
  }
}
