import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import axiosMiddleware from 'redux-axios-middleware'
// import * as Sentry from '@sentry/browser'
import configureMockStore, { MockStoreEnhanced } from 'redux-mock-store'
import MockAdapter from 'axios-mock-adapter'
import { attach as attachRetryMiddleware, RetryConfig, RaxConfig } from 'retry-axios'
import { Action, Dispatch } from 'redux'

import { AppState } from '../store'
import { getGhostID } from './link'
// import { HashMap } from './HashMap'
import { Routes } from './consts'
import { getAuthTokenHeaders, RedirectQueryStringKey } from './auth'

/**
 * getApiClient will return a fully formed axios client with authentication tokens applied.
 */
export default function getApiClient(): AxiosInstance {
  const client = axios.create({
    baseURL: `${window.location.origin}/api`,
    responseType: 'json',
    validateStatus: (status: number): boolean => {
      return status >= 200 && status < 300
    },
  })

  createRetryableAxiosInstance(client)

  return client
}

export type AxiosMiddlewareError = AxiosError | Error | string | null
export interface AxiosMiddlewareInterceptorObject {
  getState: () => AppState
  dispatch: Dispatch
  getSourceAction: (config: AxiosRequestConfig) => AxiosMiddlewareAction
}

export function getAxiosMiddlewareOptions() {
  return {
    returnRejectedPromiseOnError: true,
    interceptors: {
      request: [
        {
          success: async function(
            reduxStuff: AxiosMiddlewareInterceptorObject,
            request: AxiosRequestConfig
          ): Promise<AxiosRequestConfig> {
            // Check if the user is ghosting
            const ghost = getGhostID()

            if (ghost) {
              request.headers.common['OMS-Ghost-ID'] = ghost
            }

            request.headers.common = {
              ...request.headers.common,
              ...(await getAuthTokenHeaders()),
            }

            return request
          },
        },
      ],
      response: [
        {
          error: function(reduxStuff: AxiosMiddlewareInterceptorObject, error: AxiosError): Promise<AxiosError> {
            // If we receive a 401, the user has been logged out and we must deal with that by having the user log back
            // in.
            if (error.response && 401 === error.response.status) {
              const currentLocation = window.location.href
              window.location.href = Routes.Logout + '?' + RedirectQueryStringKey + '=' + encodeURI(currentLocation)
              return Promise.reject(error)
            }

            // If people are using the webpack dev server, redirect them to the dev site
            if (error.response && error.response.status === 404 && window.location.port === '3000') {
              window.location.href = `https://dev.goms.talentinc.com${window.location.pathname}`
            }

            // if (error.response && error.response.config) {
            //   const action = reduxStuff.getSourceAction(error.response.config)

            //   // @ts-ignore
            //   if (actionsToOmitFromSentry.includes(action.type)) {
            //     return Promise.reject(error)
            //   }
            // }

            // Send the error to Sentry
            // Sentry.withScope(scope => {
            //   const { response } = error
            //   const fingerprint = []

            //   if (response) {
            //     const request: XMLHttpRequest | undefined = response.request
            //     const breadcrumb: HashMap<string> = {
            //       response: response.data,
            //     }

            //     const status = response.status.toString()
            //     breadcrumb['statusCode'] = status
            //     fingerprint.push(status)

            //     // If we get a key called `error` back, that is the message
            //     if (response.data && 'error' in response.data) {
            //       fingerprint.push(response.data.error)
            //       error.message = response.data.error
            //     }

            //     if (Array.isArray(response.data) && response.data.length > 0 && 'error' in response.data[0]) {
            //       error.message = response.data.map(r => r.error).join('\n')
            //     }

            //     if (request) {
            //       if (request.responseURL) {
            //         // Remove all IDs from the URL for fingerprinting purposes
            //         const cleanedURL = request.responseURL
            //           .replace(
            //             /(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/g,
            //             '/:token'
            //           )
            //           .replace(/\/\d+/g, '/:id')
            //         const parsedURL = new URL(cleanedURL)
            //         const path = parsedURL.pathname + parsedURL.search.replace(/=\+d/g, '=:id')

            //         breadcrumb['url'] = request.responseURL
            //         breadcrumb['search'] = parsedURL.search
            //         fingerprint.push(path)

            //         // Modify the error message so that we can read it easily
            //         if (error.config.method) {
            //           error.message = `${error.message} ([${status}] ${error.config.method.toUpperCase()} ${path})`
            //         } else {
            //           error.message = `${error.message} ([${status}] ${path})`
            //         }
            //       }
            //     }

            //     if (error.config.method) {
            //       breadcrumb['method'] = error.config.method
            //       fingerprint.push(error.config.method)
            //     }

            //     Sentry.addBreadcrumb({
            //       category: 'axios-response',
            //       data: breadcrumb,
            //       level: Sentry.Severity.Error,
            //     })
            //   }

            //   scope.setFingerprint(fingerprint)
            //   Sentry.captureException(error)
            // })

            return Promise.reject(error)
          },
        },
      ],
    },
  }
}

// Middleware that will retry HTTP requests a limited number of times. It works with the Redux middleware by only
// rejecting the action's promise when it runs out of retries.
function createRetryableAxiosInstance(client: AxiosInstance) {
  // @ts-ignore
  client.defaults.raxConfig = {
    // Only retry idempotent request types
    httpMethodsToRetry: ['GET', 'OPTIONS'],
    retry: 3,
    noResponseRetries: 3,
    // I translated and tweaked this from the source code for retry-axios. I think the Redux middleware might be doing
    // some weird things to this config as it gets passed around, as all arrays become objects so I tweaked the code to
    // accomodate this.
    shouldRetry: err => {
      const config = !err || !err.config ? null : (err.config as RaxConfig).raxConfig

      // If there's no config, or retries are disabled, return.
      if (!config || config.retry === 0) {
        return false
      }

      // Check if this error has no response (ETIMEDOUT, ENOTFOUND, etc)
      if (!err.response && (config.currentRetryAttempt || 0) >= config.noResponseRetries!) {
        return false
      }

      // Only retry with configured HttpMethods.
      if (!err.config.method) {
        return false
      }

      if (
        Array.isArray(config.httpMethodsToRetry) &&
        !config.httpMethodsToRetry.includes(err.config.method.toUpperCase())
      ) {
        return false
      }

      if (
        typeof config.httpMethodsToRetry === 'object' &&
        !Object.values(config.httpMethodsToRetry).includes(err.config.method.toUpperCase())
      ) {
        return false
      }

      // If this wasn't in the list of status codes where we want
      // to automatically retry, return.
      if (err.response && err.response.status) {
        let isInRange = false
        let statusCodesToRetry: number[][] = []

        if (Array.isArray(config.statusCodesToRetry)) {
          statusCodesToRetry = config.statusCodesToRetry
        } else if (typeof config.statusCodesToRetry === 'object') {
          // @ts-ignore
          statusCodesToRetry = Object.values(config.statusCodesToRetry).map(s => Object.values(s))
        }

        for (const [min, max] of statusCodesToRetry) {
          const status = err.response.status
          if (status >= min && status <= max) {
            isInRange = true
            break
          }
        }
        if (!isInRange) {
          return false
        }
      }

      // If we are out of retry attempts, return
      config.currentRetryAttempt = config.currentRetryAttempt || 0
      if (config.currentRetryAttempt >= config.retry!) {
        return false
      }

      return true
    },
  } as RetryConfig

  attachRetryMiddleware(client)
}

export function createAxiosMiddleware() {
  return axiosMiddleware(getApiClient(), getAxiosMiddlewareOptions())
}

export const getTestClient = () => {
  return axios.create({
    baseURL: 'https://example.com',
    responseType: 'json',
  })
}

export const getMockStore = (): [AxiosInstance, MockStoreEnhanced<AppState, AxiosMiddlewareAction>, MockAdapter] => {
  const client = getTestClient()
  const store = configureMockStore<AppState, AxiosMiddlewareAction>([axiosMiddleware(client)])()
  return [client, store, new MockAdapter(client)]
}

export interface AxiosMiddlewareActionCreator {
  type: string
  payload: {
    request: AxiosRequestConfig
  }
}

export interface AxiosMiddlewareAction extends Action {
  type: string
  meta: {
    previousAction: AxiosMiddlewareActionCreator
  }
}

/**
 * Factory function that can be used to generate actions when testing reducers
 * that are using the Axios middleware's actions. This will generate the
 * initial action generated by an AxiosMiddlewareActionCreator.
 *
 * @param actionCreator This is an action created by AxiosMiddlewareActionCreator
 */
export const startActionFactory = (actionCreator: AxiosMiddlewareActionCreator): AxiosMiddlewareAction => {
  return {
    type: actionCreator.type,
    meta: {
      previousAction: actionCreator,
    },
  }
}

// AxiosMiddlewareActionSuccess is typed to match the resonse type of the API response
export interface AxiosMiddlewareActionSuccess<ResponseType, PreviousActionType = AxiosMiddlewareActionCreator> {
  type: string
  payload: AxiosResponse<ResponseType>
  meta: {
    previousAction: PreviousActionType
  }
}

/**
 * Factory function that will generate an action that matches the type from
 * AxiosMiddlewareActionCreator. This is the success action from that request.
 *
 * @param actionCreator
 * @param data
 */
export const successActionFactory = (
  actionCreator: AxiosMiddlewareActionCreator,
  // This is any because it needs to encompass all the API return types.
  // @TODO We might be able to make this into a union once we define all the API return types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
): AxiosMiddlewareActionSuccess<typeof data> => {
  const payload: AxiosResponse<typeof data> = {
    data: data,
    status: 200,
    statusText: 'OK',
    headers: {},
    config: {},
  }

  return {
    type: `${actionCreator.type}_SUCCESS`,
    payload: payload,
    meta: {
      previousAction: actionCreator,
    },
  }
}

export interface AxiosMiddlewareActionFail<PreviousActionType = AxiosMiddlewareActionCreator> {
  type: string
  error: AxiosError
  meta: {
    previousAction: PreviousActionType
  }
}

export interface ApiError {
  error: string
}

/**
 * Factory function that will generate an action that matches the type from
 * AxiosMiddlewareActionCreator. This is the fail action from that request.
 *
 * @param actionCreator
 * @param data
 */
export const failureActionFactory = (
  actionCreator: AxiosMiddlewareActionCreator,
  data: ApiError
): AxiosMiddlewareActionFail<AxiosMiddlewareActionCreator> => {
  const response: AxiosResponse<typeof data> = {
    data: data,
    status: 500,
    statusText: 'Internal Server Error',
    headers: {},
    config: {},
  }
  const error: AxiosError = {
    name: data.error,
    message: data.error,
    config: {},
    response: response,
  }

  return {
    type: `${actionCreator.type}_FAIL`,
    error: error,
    meta: {
      previousAction: actionCreator,
    },
  }
}

export type AxiosMiddlewareActions =
  | AxiosMiddlewareAction
  // This is any because it needs to encompass all the API return types.
  // @TODO We might be able to make this into a union once we define all the API return types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | AxiosMiddlewareActionSuccess<any>
  | AxiosMiddlewareActionFail
