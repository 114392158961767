import { LoadedLoadingErrorState } from '../../utils/state'
import { AutoQAScanOutput } from '../uploads/types'

// (client upload, message attachment, user upload)
export type FileContexts = 'client' | 'message' | 'user' | 'editor-client'
export enum UploadGoal {
  Upload = 'Upload',
  Attach = 'Attach',
  AutoLinkedin = 'AutoLinkedin',
}

export enum EditorDecisions {
  Approve = 'Approve',
  Reject = 'Reject',
}

export interface EditorReview {
  editor_id: number
  verdict: EditorDecisions
}

export interface JudgedDocument {
  fileID: number
  verdict: EditorDecisions
}

interface GenericDocument {
  id: number
  context: FileContexts
  context_id: number
  user_id: number | null
  document_digest: string | null
  mime_type: string
  size: number
  amazon_bucket: string
  amazon_filename: string
  filename: string
  created_at: string
  updated_at: string
  order_id: number | null
  order_item_id: number | null
  rezwiz_id: string | null
  revision: number | null
  page_count: number | null
  edit_time: number | null
  template_tracking: string | null
  customer_name: string | null
  customer_email: string | null
  went_through_auto_qa?: boolean
}

export interface DocumentResponse extends GenericDocument {
  doc_type: string
  doc_type_tag_keys: string | null
}

export interface Document extends GenericDocument {
  type: DocumentTypes
}

export interface DocumentList {
  [contextID: number]: DocsMetaList
}

export interface DocsMetaList {
  meta: LoadedLoadingErrorState
  docs: DocumentMap
}

export interface DocumentMap {
  [id: number]: Document
}

export interface AutoQAList {
  [fileID: number]: {
    meta: LoadedLoadingErrorState
    autoQA: AutoQAScanOutput | null
  }
}

export interface DocumentState {
  client: DocumentList
  message: DocumentList
  user: DocumentList
  'editor-client': DocumentList
  autoQA: AutoQAList
}

export enum DocumentTypes {
  Resume = 'resume',
  CoverLetter = 'cover-letter',
  Linkedin = 'linkedin',
  CV = 'cv',
  None = '',
  ThankYouLetter = 'thank-you-letter',
  Customer = 'customer',
  FollowUpLetter = 'follow-up-letter',
  ResiginationLetter = 'resignation-letter',
  ReferencesPage = 'references-page',
  DeclineLetter = 'decline-letter',
  KSADocument = 'ksa',
  Biography = 'biography',
  ResumeEdit = 'resume-edit',
}

export const AdditionalDocumentTypes = [
  DocumentTypes.ResiginationLetter,
  DocumentTypes.ThankYouLetter,
  DocumentTypes.FollowUpLetter,
  DocumentTypes.ReferencesPage,
  DocumentTypes.DeclineLetter,
  DocumentTypes.KSADocument,
  DocumentTypes.Biography,
] as const
