import React, { useCallback, ReactNode, useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { useInfiniteScroll } from 'react-infinite-scroll-hook'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import { InfiniteListState } from '../../store/events/types'
import DashboardStyles from '../Dashboard/Styles'
import i18n from '../../i18n'
import { FetchUserEvents } from '../../store/events/actions'

export interface InfiniteListRow {
  key: string
  content: ReactNode
}

export interface InfiniteListProps {
  items: InfiniteListRow[]
  loadPage: (page: number) => Promise<FetchUserEvents | undefined>
  scrollContainer: 'window' | 'parent'
  listState: InfiniteListState
}

const useStyles = makeStyles(() =>
  createStyles({
    itemSpan: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    list: {
      padding: '0',
    },
    listItem: {
      justifyContent: 'space-between',
      padding: 0,

      '& a': {
        color: 'inherit',
      },
    },
    loading: {
      padding: DashboardStyles.row.fullPadding,
      fontWeight: 'bold',
    },
  })
)

const InfiniteList: React.FC<InfiniteListProps> = ({ listState, items, loadPage, scrollContainer = 'window' }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useStyles()

  const handleLoadMore = useCallback(async () => {
    if (loading) {
      return
    }
    const page = listState.page + 1
    setLoading(true)
    await loadPage(page)
    setLoading(false)
  }, [listState.page, loadPage, loading])

  const infiniteRef = useInfiniteScroll({
    loading: loading,
    hasNextPage: listState.hasNextPage,
    onLoadMore: handleLoadMore,
    scrollContainer: scrollContainer,
  })

  return (
    // @ts-ignore
    <List ref={infiniteRef} className={classes.list}>
      {items.map(item => (
        <ListItem className={classes.listItem} key={item.key}>
          <div className={classes.itemSpan}>{item.content}</div>
        </ListItem>
      ))}
      {loading && <ListItem className={classes.loading}>{i18n.t('infiniteList__loading')}</ListItem>}
    </List>
  )
}

export default InfiniteList
