import React from 'react'
import moment from 'moment'
import { Calendar, momentLocalizer, DateLocalizer, ToolbarProps } from 'react-big-calendar'
import 'react-big-calendar/lib/sass/styles.scss'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import i18n from '../../../i18n'
import DashboardStyles from '../../Dashboard/Styles'
import CalendarStyle from '../Styles'
import isSameDay from 'date-fns/isSameDay'
import subDays from 'date-fns/subDays'
import addDays from 'date-fns/addDays'
import isThisWeek from 'date-fns/isThisWeek'
import Button from '@material-ui/core/Button'
import SendIcon from '@material-ui/icons/Send'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import { CalendarEvent, EventTypes, CalendarType, WeeklyPeriodNumeric } from '../../../store/scheduling/types'
import CustomCalendarView from './CustomCalendarView'
import CustomCalendarWeekView from './CustomCalendarWeekView'
import { useSelector } from 'react-redux'
import { AppState } from '../../../store'
import { getCalendarTimeBoundaries, getWeeklyPeriod } from '../../../store/scheduling/selectors'
import ErrorBoundary from '../../common/ErrorBoundary'
import { differenceInDays } from 'date-fns'

interface Props {
  onSelectDate: (date: Date) => void
  events: CalendarEvent[]
  selectedView: string
  selectedDate: Date
}

interface LabelType {
  date: Date
  label: string
  localizer: DateLocalizer
}

export interface EventProps {
  event: CalendarEvent
  title: string
}

const useStyles = makeStyles(theme =>
  createStyles({
    schedule: {
      display: 'flex',
      justifyContent: 'flex-start',
      backgroundColor: 'white',
      width: DashboardStyles.fullWidth,
    },
    calendar: {
      flex: 1,
      width: 'calc(100% - 20rem)',
      marginLeft: '1rem',
    },
    calendarView: {
      '& .rbc-time-view': {
        transition: 'all 5s ease-out',
      },
      '& .rbc-allday-cell': { display: 'none' },
      '&&& .rbc-time-slot': {
        display: 'flex',
        minHeight: '10px',
        height: '10px',
      },
      '& .rbc-time-content': {
        border: 'none',
      },
      '& .rbc-label': {
        fontSize: '10px',
      },
      '& .rbc-current-time-indicator': {
        backgroundColor: CalendarStyle.color.time,
      },
      '& .rbc-current-time-indicator:before': {
        content: "''",
        backgroundColor: CalendarStyle.color.time,
        borderRadius: '50%',
        width: '7px',
        height: '7px',
        top: '-3px',
        left: '-4px',
        position: 'absolute',
      },
      '& .rbc-day-slot .rbc-event-content': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&&& .rbc-time-column .rbc-timeslot-group': {
        minHeight: '1.68rem',
        textAlign: 'center',
      },
      '& .rbc-day-slot .rbc-event-label': {
        display: 'none',
      },
      '& .rbc-day-slot .rbc-event': {
        borderWidth: '2px 0 0 0',
        borderStyle: 'solid',
        borderRadius: '0',
        marginLeft: '5px',
        cursor: 'default',
      },
      '& .externalEvent': {
        backgroundColor: CalendarStyle.color.externalRGB,
        color: CalendarStyle.color.external,
        borderColor: CalendarStyle.color.external,
      },
      '& .draftEvent': {
        backgroundColor: CalendarStyle.color.draftRGB,
        color: CalendarStyle.color.draft,
        borderTop: CalendarStyle.color.draft,
      },
      '& .callEvent': {
        backgroundColor: CalendarStyle.color.callsRGB,
        color: CalendarStyle.color.calls,
        borderTop: CalendarStyle.color.calls,
        fontSize: '10px',
      },
      '& .busyEvent': {
        backgroundColor: CalendarStyle.color.busyRGB,
        color: CalendarStyle.color.busy,
        borderTop: CalendarStyle.color.busy,
      },
      '& .blankEvent': {
        backgroundColor: CalendarStyle.color.blank,
      },
      '& .noAvail': {
        backgroundColor: CalendarStyle.color.unavailable,
      },
    },
    selectedDay: {
      backgroundColor: CalendarStyle.color.base,
      color: 'white',
      marginRight: '0px',
    },
    day: {
      marginRight: '0px',
    },
    external: {
      backgroundColor: CalendarStyle.color.external,
    },
    busy: {
      backgroundColor: CalendarStyle.color.busy,
    },
    calls: {
      backgroundColor: CalendarStyle.color.calls,
    },
    draft: {
      backgroundColor: CalendarStyle.color.draft,
    },
    blank: {
      backgroundColor: CalendarStyle.color.blank,
      border: '1px solid',
      borderColor: CalendarStyle.color.legendBorder,
    },
    unavailable: {
      backgroundColor: CalendarStyle.color.unavailable,
      border: '1px solid',
      borderColor: CalendarStyle.color.legendBorder,
    },
    header: {
      padding: '8px 10px',
      textTransform: 'uppercase',
    },
    todayButton: {
      padding: '.5rem 1rem',
      border: '1px solid',
      borderColor: CalendarStyle.color.darkBlue,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '1.5rem',
      marginTop: '1rem',
    },
    labelSm: {
      fontSize: CalendarStyle.fontSizeSm,
      color: 'black',
    },
    labelLink: {
      fontSize: CalendarStyle.fontSizeSm,
      margin: '5px',
      fontWeight: 500,
      cursor: 'pointer',
      color: 'black',
      whiteSpace: 'nowrap',
    },
    icon: {
      width: '.8rem',
      height: '.8rem',
    },
    box: {
      height: '10px',
      width: '10px',
      marginLeft: '2rem',
      marginRight: '8px',
    },
    eventView: {
      display: 'flex',
      justifyContent: 'center',
    },
    iconPadding: {
      paddingTop: '.3rem',
    },
    label: {
      alignSelf: 'flex-end',
    },
  })
)

const isNextWeek = (date: Date): boolean => {
  const d = differenceInDays(date, new Date())
  return d >= 6 && d < 12
}

const isWeekAfterNext = (date: Date): boolean => {
  const d = differenceInDays(date, new Date())
  return d >= 12 && d < 18
}

const CalendarView: React.FC<Props> = ({ events, onSelectDate, selectedView, selectedDate }) => {
  const classes = useStyles()
  const localize = momentLocalizer(moment)
  const today = new Date()

  const weeklyPeriod = useSelector<AppState, WeeklyPeriodNumeric[]>(state => getWeeklyPeriod(state.schedulingReducer))
  const [start, end] = useSelector<AppState, [Date, Date]>(state => getCalendarTimeBoundaries(state.schedulingReducer))
  const Header = (label: LabelType) => {
    return (
      <Box className={classes.header}>
        <div>{label.localizer.format(label.date, 'ddd', '')}</div>
        <div>{label.localizer.format(label.date, '(MM/DD)', '')}</div>
      </Box>
    )
  }
  const DayProp = (date: Date) => {
    return isSameDay(date, selectedDate) ? { className: classes.selectedDay } : { className: classes.day }
  }

  const Event = (props: EventProps) => {
    return (
      <Box className={classes.eventView}>
        {props.event.type === EventTypes.Call ? (
          <Typography variant={'caption'}>{i18n.t('schedule_calendar_call_in')}</Typography>
        ) : props.event.type === EventTypes.Draft ? (
          <SendIcon className={classes.icon} />
        ) : (
          <EventBusyIcon className={classes.icon} />
        )}
      </Box>
    )
  }

  const EventProp = (event: CalendarEvent) => {
    if (event.type === EventTypes.Draft) return { className: 'draftEvent' }
    if (event.type === EventTypes.Call) return { className: 'callEvent' }
    if (event.type === EventTypes.Busy) return { className: 'busyEvent' }
    return {
      className: 'callEvent',
    }
  }

  const OnNavigate = (date: Date) => {
    onSelectDate(date)
  }

  const SlotProp = (date: Date) => {
    let className = 'noAvail'
    const days = weeklyPeriod.filter(weeklyP => weeklyP.day === date.getDay())
    days.forEach(day => {
      const h = date.getHours() === 0 ? '00' : date.getHours() + ''
      const m = date.getMinutes() === 0 ? '00' : date.getMinutes() + ''
      const timeLine = parseInt(h + '' + m)
      if (timeLine >= day.start_time && timeLine < day.end_time) {
        className = 'blankEvent'
      }
    })
    return {
      className: className,
    }
  }

  const Toolbar = (toolbar: ToolbarProps) => {
    const toolbarDate = toolbar.date
    const day = selectedView === CalendarType.WeekView ? 7 : 3

    const goToToday = (date: Date) => {
      toolbar.onNavigate('TODAY', date)
    }
    const goToBack = (date: Date) => {
      toolbar.onNavigate('DATE', subDays(date, day))
    }
    const goToNext = (date: Date) => {
      toolbar.onNavigate('DATE', addDays(date, day))
    }
    return (
      <Box className={classes.toolbar}>
        <Box display={'flex'} flexDirection={'row'}>
          <Button variant="outlined" className={classes.todayButton} onClick={() => goToToday(toolbarDate)}>
            {i18n.t('schedule_calendar_today')}
          </Button>
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={3}>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <div className={clsx([classes.box, classes.busy])} />
                <Typography className={classes.labelSm}>{i18n.t('schedule_calendar_linked')}</Typography>
                <div className={clsx([classes.box, classes.draft])} />
                <Typography className={classes.labelSm}>{i18n.t('schedule_calendar_draft')}</Typography>
              </Box>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <div className={clsx([classes.box, classes.calls])} />
                <Typography className={classes.labelSm}>{i18n.t('schedule_calendar_call')}</Typography>
                <div className={clsx([classes.box, classes.blank])} />
                <Typography className={classes.labelSm}>{i18n.t('schedule_calendar_available')}</Typography>
                <div className={clsx([classes.box, classes.unavailable])} />
                <Typography className={classes.labelSm}>{i18n.t('schedule_calendar_unavailable')}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {selectedView === CalendarType.WeekView && (
          <>
            {isThisWeek(toolbarDate) && (
              <Typography className={classes.label}>
                <Link className={classes.labelLink} onClick={() => goToNext(toolbarDate)}>
                  {
                    <>
                      {i18n.t('schedule_calendar_next_week')}
                      <ArrowForwardIosIcon className={clsx([classes.icon, classes.iconPadding])} />
                    </>
                  }
                </Link>
              </Typography>
            )}
            {isNextWeek(toolbarDate) && (
              <Typography className={classes.label}>
                <Link className={classes.labelLink} onClick={() => goToBack(toolbarDate)}>
                  {
                    <>
                      <ArrowBackIosIcon className={clsx([classes.icon, classes.iconPadding])} />
                      {i18n.t('schedule_calendar_prev_week')}
                    </>
                  }
                </Link>
                <Link className={classes.labelLink} onClick={() => goToNext(toolbarDate)}>
                  {
                    <>
                      {i18n.t('schedule_calendar_next_week')}
                      <ArrowForwardIosIcon className={clsx([classes.icon, classes.iconPadding])} />
                    </>
                  }
                </Link>
              </Typography>
            )}
            {isWeekAfterNext(toolbarDate) && (
              <Typography className={classes.label}>
                <Link className={classes.labelLink} onClick={() => goToBack(toolbarDate)}>
                  {
                    <>
                      <ArrowBackIosIcon className={clsx([classes.icon, classes.iconPadding])} />
                      {i18n.t('schedule_calendar_prev_week')}
                    </>
                  }
                </Link>
              </Typography>
            )}
          </>
        )}
      </Box>
    )
  }

  const showCalendar = () => {
    switch (selectedView) {
      case CalendarType.WeekView:
        return (
          <ErrorBoundary>
            <Calendar
              className={classes.calendarView}
              onSelecting={() => false}
              selectable="ignoreEvents"
              scrollToTime={new Date(1970, 1, 1, 0)}
              step={30}
              timeslots={1}
              localizer={localize}
              events={events}
              defaultDate={today}
              defaultView={'week'}
              dayLayoutAlgorithm="no-overlap"
              min={start}
              max={end}
              views={{ week: true }}
              dayPropGetter={DayProp}
              slotPropGetter={SlotProp}
              eventPropGetter={EventProp}
              onNavigate={OnNavigate}
              showMultiDayTimes={true}
              components={{
                event: Event,
                toolbar: Toolbar,
                header: Header,
              }}
            />
          </ErrorBoundary>
        )
      case CalendarType.WorkWeekView:
        return (
          <ErrorBoundary>
            <Calendar
              className={classes.calendarView}
              selectable="ignoreEvents"
              scrollToTime={new Date(1970, 1, 1, 0)}
              step={30}
              onSelecting={() => false}
              timeslots={1}
              localizer={localize}
              events={events}
              defaultDate={today}
              defaultView={'week'}
              dayLayoutAlgorithm="no-overlap"
              min={start}
              max={end}
              views={{ week: CustomCalendarWeekView }}
              dayPropGetter={DayProp}
              slotPropGetter={SlotProp}
              eventPropGetter={EventProp}
              onNavigate={OnNavigate}
              showMultiDayTimes={true}
              components={{
                event: Event,
                toolbar: Toolbar,
                header: Header,
              }}
            />
          </ErrorBoundary>
        )
      case CalendarType.DayView:
        return (
          <ErrorBoundary>
            <Calendar
              className={classes.calendarView}
              selectable="ignoreEvents"
              scrollToTime={new Date(1970, 1, 1, 0)}
              step={30}
              onSelecting={() => false}
              timeslots={1}
              localizer={localize}
              events={events}
              defaultDate={today}
              defaultView={'week'}
              dayLayoutAlgorithm="no-overlap"
              min={start}
              max={end}
              views={{ week: CustomCalendarView }}
              dayPropGetter={DayProp}
              slotPropGetter={SlotProp}
              eventPropGetter={EventProp}
              onNavigate={OnNavigate}
              showMultiDayTimes={true}
              components={{
                event: Event,
                toolbar: Toolbar,
                header: Header,
              }}
            />
          </ErrorBoundary>
        )
    }
  }

  return (
    <Fade in={true}>
      <Box className={classes.schedule}>
        <Box className={classes.calendar}>{selectedView && showCalendar()}</Box>
      </Box>
    </Fade>
  )
}

export default CalendarView
