import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import i18n from '../../i18n'
import Button from '../common/Button'
import AdvocateImage from '../../assets/svgs/advocate.svg'
import styles from '../../styles'

export interface NewHirePreUploadProps {
  handleSkip: () => void
  handleUploadForReview: () => void
  handleClose: () => void
  orderHasApprovedDocs: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      color: styles.palette.darkerBlue.color.hex,
      padding: '1.9rem 1.9rem 1.7rem',
    },
    title: {
      marginBottom: '1rem',
      fontWeight: 'bold',
    },
    text: {
      fontSize: '.9rem',
      marginBottom: '1rem',
    },
    image: {
      backgroundColor: '#deecf9',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '2rem',
      paddingBottom: '2rem',
    },
  })
)

const NewHirePreUpload: React.FC<NewHirePreUploadProps> = ({
  handleUploadForReview,
  handleSkip,
  handleClose,
  orderHasApprovedDocs,
}) => {
  const classes = useStyles()

  return (
    <Dialog open={true} onClose={handleClose}>
      <>
        <Box className={classes.image}>
          <img src={AdvocateImage} alt="" />
        </Box>
        <Box className={classes.content}>
          <Typography display="block" variant={'h1'} className={classes.title}>
            {i18n.t('uploadsv3__newHirePreUpload__title')}
          </Typography>
          <Typography display="block" variant={'body2'} className={classes.text}>
            {i18n.t('uploadsv3__newHirePreUpload__body1')}
          </Typography>
          {orderHasApprovedDocs && (
            <Typography display="block" variant={'body2'} className={classes.text}>
              {i18n.t('uploadsv3__newHirePreUpload__body2')}
            </Typography>
          )}
          {orderHasApprovedDocs && (
            <DialogActions>
              <Button type="secondary" onClick={handleSkip} id="mentor-doc-check-in-skip">
                {i18n.t('uploadsv3__newHirePreUpload__buttonDocumentReviewed')}
              </Button>
              <Button type="contrast" onClick={handleUploadForReview} id="mentor-doc-check-in-upload">
                {i18n.t('uploadsv3__newHirePreUpload__buttonSendToAdvocate')}
              </Button>
            </DialogActions>
          )}
          {!orderHasApprovedDocs && (
            <DialogActions>
              <Button type="secondary" onClick={handleClose} id="mentor-doc-check-in-cancel">
                {i18n.t('uploadsv3__newHirePreUpload__buttonCancel')}
              </Button>
              <Button type="contrast" onClick={handleUploadForReview} id="mentor-doc-check-in-upload">
                {i18n.t('uploadsv3__newHirePreUpload__buttonSendToAdvocate')}
              </Button>
            </DialogActions>
          )}
        </Box>
      </>
    </Dialog>
  )
}

export default React.memo(NewHirePreUpload)
