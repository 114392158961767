import { cloneDeep, omit, some } from 'lodash'
import { ClientOrdersAggregate, Order, OrderResponse } from '../store/orders/types'
import { AdditionalDocumentTypes, Document, DocumentResponse, DocumentTypes } from '../store/documents/types'
import { OrderCTA, OrderItem, OrderItemDisplayStates, OrderItemStates, OrderItemTypes } from '../store/items/types'
import {
  filterComplete,
  filterEligibleToClose,
  filterPaid,
  filterReassigned,
  filterResumeEditClosableItems,
  sortByDueDateAsc,
} from '../selectors/items'

export interface MacroState {
  CTA: OrderCTA
  dueDate: string | null
  productID: number | null
  orderID: number | null
}

export const macroStateNone: MacroState = {
  CTA: OrderItemDisplayStates.None,
  dueDate: null,
  productID: null,
  orderID: null,
}

export const calculateMacroState = (
  productItems: OrderItem[],
  dueDate: string | Date | null,
  isSchedulerTest: boolean
): MacroState => {
  if (dueDate instanceof Date) {
    dueDate = dueDate.toISOString()
  }
  if (!productItems || productItems.length === 0) {
    return { ...macroStateNone }
  }
  //@TODO: make sure that item objects are never NULL
  const activeStates = productItems.map(item => item.status.active)

  if (!activeStates || activeStates.length === 0) {
    return { ...macroStateNone, dueDate: dueDate }
  }
  const nonNullableStates = activeStates.flat().filter(s => s != null && s.state !== OrderItemStates.Rush)
  if (nonNullableStates.length === 0) {
    return { ...macroStateNone, dueDate: dueDate }
  }

  if (filterReassigned(productItems)) {
    return { ...macroStateNone, CTA: OrderItemStates.Reassigned }
  }

  const sortedStates = nonNullableStates.sort(sortByDueDateAsc)
  let macroState = sortedStates[0]

  if (filterComplete(productItems)) {
    return {
      ...macroStateNone,
      CTA: OrderItemStates.Closed,
      dueDate: macroState.started_at as string,
      orderID: productItems.length > 0 ? Math.min(...productItems.map(p => p.order_id)) : null,
    }
  }

  if (filterPaid(productItems)) {
    return { ...macroStateNone, CTA: OrderItemStates.Paid, dueDate: macroState.started_at as string }
  }

  if (filterEligibleToClose(productItems)) {
    const inRevisions = nonNullableStates.filter(x => x.state === OrderItemStates.Revisions)
    const sorted = inRevisions.sort(sortByDueDateAsc)
    if (sorted.length === 0) {
      return { ...macroStateNone }
    } else {
      return {
        ...macroStateNone,
        CTA: OrderItemDisplayStates.CloseOrder,
        dueDate: sorted[0].due_at as string,
        orderID: productItems.length > 0 ? Math.min(...productItems.map(p => p.order_id)) : null,
      }
    }
  }

  if (filterResumeEditClosableItems(productItems)) {
    const inFirstDraft = nonNullableStates.filter(x => x.state === OrderItemStates.SendFirstDraft)
    const sorted = inFirstDraft.sort(sortByDueDateAsc)

    return {
      ...macroStateNone,
      CTA: OrderItemDisplayStates.CloseOrder,
      dueDate: sorted[0].due_at as string,
      orderID: productItems.length > 0 ? Math.min(...productItems.map(p => p.order_id)) : null,
      productID: sorted[0].product_id,
    }
  }

  const nextItem = productItems.filter(item => item.id === macroState.product_id)
  const nextOrderID = !!nextItem && nextItem.length > 0 ? nextItem[0].order_id : null

  // Send First Draft state on Linkedin items is confusing for writers.
  if (
    macroState.state === OrderItemStates.SendFirstDraft &&
    sortedStates.length >= 2 &&
    sortedStates[1].state !== OrderItemStates.SendFirstDraft &&
    !!nextItem &&
    nextItem.length > 0 &&
    nextItem[0].core_product_key === OrderItemTypes.LinkedIn
  ) {
    return {
      orderID: nextOrderID,
      productID: macroState.product_id,
      CTA: OrderItemStates.SendLinkedin,
      dueDate: !macroState.due_at ? dueDate : (macroState.due_at as string),
    }
  }

  // Since calls and document items can advance in parallel and main order CTA is linear,
  // we're making the choice to keep main order CTA document-centric thus ignoring the Start Call states.
  if (
    isSchedulerTest &&
    (macroState.state === OrderItemStates.ConductCall ||
      macroState.state === OrderItemStates.ConductInterview ||
      macroState.state === OrderItemStates.AwaitingScheduling ||
      macroState.state === OrderItemStates.AwaitingResume) &&
    sortedStates.length > 1 &&
    !!sortedStates.find(
      s =>
        s.state !== OrderItemStates.ConductCall &&
        s.state !== OrderItemStates.ConductInterview &&
        s.state !== OrderItemStates.AwaitingScheduling
    )
  ) {
    const firstNonCallState = sortedStates.find(
      s =>
        s.state !== OrderItemStates.ConductCall &&
        s.state !== OrderItemStates.ConductInterview &&
        s.state !== OrderItemStates.AwaitingScheduling &&
        s.state !== OrderItemStates.AwaitingResume
    )
    if (!!firstNonCallState) {
      const macroStateItem = productItems.find(item => item.id === firstNonCallState.product_id)
      const macroStateOrderID = !!macroStateItem ? macroStateItem.order_id : null
      const inRevisions = nonNullableStates.filter(x => x.state === OrderItemStates.Revisions).sort(sortByDueDateAsc)
      const currentDueDate = firstNonCallState.due_at ?? inRevisions[0]?.due_at

      return {
        orderID: macroStateOrderID,
        productID: firstNonCallState.product_id,
        CTA: firstNonCallState.state,
        dueDate: currentDueDate ?? dueDate,
      }
    }
  }
  switch (macroState.state) {
    case OrderItemStates.AwaitingReply:
    case OrderItemStates.SendMessage:
    case OrderItemStates.Revisions:
      const sendLinkedin = nonNullableStates.filter(x => x.state === OrderItemStates.SendLinkedin)
      const inRevisions = nonNullableStates.filter(x => x.state === OrderItemStates.Revisions).sort(sortByDueDateAsc)
      if (!!inRevisions && inRevisions.length > 0) {
        const revisionPeriodEnded = new Date(inRevisions[0].due_at as string) < new Date()
        if (revisionPeriodEnded && !!sendLinkedin && sendLinkedin.length > 0) {
          macroState = sendLinkedin[0]
        }
        return {
          orderID: nextOrderID,
          productID: macroState.product_id,
          CTA: macroState.state,
          dueDate: inRevisions[0].due_at as string,
        }
      } else {
        return { ...macroStateNone, CTA: macroState.state, dueDate: macroState.due_at ? macroState.due_at : null }
      }
    case OrderItemStates.Closed:
      return {
        ...macroStateNone,
        CTA: macroState.state,
        dueDate: macroState.started_at as string,
      }
    case OrderItemStates.SchedulePhoneCall:
    case OrderItemStates.ScheduleInterview:
    case OrderItemStates.SendSummary:
    case OrderItemStates.AwaitingScheduling:
      return {
        orderID: nextOrderID,
        CTA: macroState.state,
        dueDate: !macroState.due_at ? dueDate : (macroState.due_at as string),
        productID: macroState.product_id,
      }
    default:
      return {
        orderID: nextOrderID,
        productID: macroState.product_id,
        CTA: macroState.state,
        dueDate: !macroState.due_at ? dueDate : (macroState.due_at as string),
      }
  }
}

export const mapOrderResponseToOrder = (
  orderResponse: OrderResponse,
  orderInStore: Order,
  clientID?: number
): Order => {
  const blacklist = ['order_items', 'client']
  const order = cloneDeep(omit(orderResponse, blacklist)) as Order

  order.client_id = !!clientID ? clientID : orderResponse.client.id
  order.order_items_ids = orderResponse.order_items ? orderResponse.order_items.map(i => i.id) : []
  order.order_items_core_product_ids = orderResponse.order_items
    ? orderResponse.order_items.map(i => i.core_product_id)
    : []

  order.macroState = calculateMacroState(orderResponse.order_items, orderResponse.due_date, false)
  if (orderInStore && orderInStore.is_verbose && !order.is_verbose) {
    order.is_verbose = orderInStore.is_verbose
    order.notes = orderInStore.notes
  }
  return order
}

export const calculateClientMacroState = (aggregate: ClientOrdersAggregate) => {
  const productItems = aggregate.orders.map(order => order.order_items).flat()
  const sortedOrders = aggregate.orders.sort((a: OrderResponse, b: OrderResponse) => (a.due_date > b.due_date ? 1 : -1))
  const clientDueDate = sortedOrders[0].due_date
  return calculateMacroState(productItems, clientDueDate, false)
}

export const mapDocumentResponseToDoc = (documentResponse: DocumentResponse): Document => {
  const document = cloneDeep(omit(documentResponse, ['doc_type', 'doc_type_tag_keys'])) as Document
  const doc_type = documentResponse.doc_type

  if (doc_type === OrderItemTypes.AdditionalDocument.toString()) {
    const docTagsString = documentResponse.doc_type_tag_keys

    if (!docTagsString) {
      document.type = DocumentTypes.None
      return document
    }

    const tags = docTagsString.split(',')
    const matchedDocumentTypes = AdditionalDocumentTypes.filter(adt => some(tags, t => t === adt))

    if (matchedDocumentTypes.length > 0) {
      document.type = matchedDocumentTypes[0]
      return document
    }
  }

  if (doc_type === 'customer') {
    document.type = DocumentTypes.Customer
    return document
  }

  // TODO: remove legacy
  if (doc_type === 'linkedin-document') {
    document.type = DocumentTypes.Linkedin
    return document
  }

  // @ts-ignore
  if (Object.values(OrderItemTypes).includes(doc_type)) {
    document.type = doc_type as DocumentTypes
    return document
  }

  if (doc_type === DocumentTypes.ResumeEdit) {
    document.type = DocumentTypes.ResumeEdit
  }

  if (!document.type) {
    document.type = DocumentTypes.None
  }

  return document
}

export const FilenamePatternToDocumentTypesMapping = {
  res: DocumentTypes.Resume,
  cv: DocumentTypes.Resume,
  cov: DocumentTypes.CoverLetter,
  bio: DocumentTypes.Biography,
  follow: DocumentTypes.FollowUpLetter,
  ksa: DocumentTypes.KSADocument,
  linked: DocumentTypes.Linkedin,
  decline: DocumentTypes.DeclineLetter,
  refer: DocumentTypes.ReferencesPage,
  resig: DocumentTypes.ResiginationLetter,
  thank: DocumentTypes.ThankYouLetter,
  vitae: DocumentTypes.CV,
}

export const guessDocumentTypeFromFilename = (filename: string): string => {
  const guesses = Object.entries(FilenamePatternToDocumentTypesMapping)
    .filter(([substring]) => filename.toLowerCase().includes(substring))
    .map(([, documentType]) => documentType)

  if (!guesses.length) {
    return DocumentTypes.None
  }

  return guesses[0]
}
