import React, { useEffect } from 'react'
import { Box, Tab, Tabs } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import * as H from 'history'

import i18n from '../../i18n'
import PageHeader from '../common/PageHeader'
import { AppState } from '../../store'
import { EligibilityReasons, Offer, OfferState, OfferStatus } from '../../store/offers/types'
import {
  canAcceptOffers,
  canNotAcceptOffersDueToReason,
  isUserOfferPageLoading,
  userCurrentOffer,
  userOffers,
  worksInProgress,
} from '../../selectors/offers'
import {
  clearGlobalMeta,
  discardCurrentUserOffer,
  expireCurrentUserOffer,
  fetchOffersForUser,
  removeUserOffer,
} from '../../store/offers/actions'
import OfferRejectionLimit from './OfferRejectionLimit'
import Offers from './Offers'
import OrderShoppingStats from './OrderShoppingStats'
import Loader from '../common/Loader'
import Preferences from './Preferences'
import { AssignmentPreferences } from '../../store/user_preferences/reducers'
import { userAssignmentPreferences } from '../../selectors/users'
import { fetchUserPreferences, updateUserAssignmentPreferences } from '../../store/user_preferences/actions'
import { Routes } from '../../utils/consts'
import { GhostLink } from '../../utils/link'
import StatedCapacityReached from './StatedCapacityReached'
import NewHireCapacityReached from './NewHireCapacityReached'
import NoOrders from './NoOrders'
import useUserInfoState from '../common/useUserInfo'
import useFeatureFlags from '../common/useFeatureFlags'

export interface OrderShoppingContainerProps {
  userID: number
  location?: H.Location
}

const a11yProps = (index: number) => {
  return {
    id: `page-title-tab-${index}`,
    'aria-controls': `page-title-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabLabel: {
      fontSize: '1.25rem',
      textTransform: 'initial',
      marginRight: '1.5rem',
      minWidth: '1rem',
      padding: '.2rem',
    },
    tabContainer: {
      maxWidth: '57.1875rem',
      '@media (max-width: 1050px)': {
        maxWidth: 'none',
        width: '100%',
      },
    },
    container: {
      display: 'flex',
      backgroundColor: '#fff',
      border: '1px solid #dee1e7',
      padding: '2.4375rem 3.75rem',
      '@media (max-width: 1050px)': {
        padding: '2rem 2rem',
      },
    },
  })
)

const OrderShoppingContainer: React.FC<OrderShoppingContainerProps> = ({ userID, location }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { isOffshore, isDeveloper } = useUserInfoState()
  const offerState = useSelector<AppState, OfferState | null>(state => state.offerReducer)
  const assignmentPreferences = useSelector<AppState, AssignmentPreferences | null>(state =>
    userAssignmentPreferences(state.userPreferencesReducer)
  )
  const { showOrderShoppingPreferences } = useFeatureFlags()

  useEffect(() => {
    if (!offerState) {
      dispatch(fetchOffersForUser(userID))
    }
    if (!assignmentPreferences) {
      dispatch(fetchUserPreferences(userID))
    }
  }, [dispatch, userID, assignmentPreferences, offerState])

  const isActiveShopper = useSelector<AppState, boolean>(state => canAcceptOffers(state.offerReducer))

  const rejectionLimitReached = useSelector<AppState, boolean>(state =>
    canNotAcceptOffersDueToReason(state.offerReducer, EligibilityReasons.RejectionsExpirations)
  )
  const capacityLimitReached = useSelector<AppState, boolean>(state =>
    canNotAcceptOffersDueToReason(state.offerReducer, EligibilityReasons.Capacity)
  )
  const newHireCapacityLimitReached = useSelector<AppState, boolean>(state =>
    canNotAcceptOffersDueToReason(state.offerReducer, EligibilityReasons.NewHireCapacity)
  )
  const inventoryBelowPercentile = useSelector<AppState, boolean>(state =>
    canNotAcceptOffersDueToReason(state.offerReducer, EligibilityReasons.LowInventory)
  )

  const wipLimitReached = useSelector<AppState, boolean>(state =>
    canNotAcceptOffersDueToReason(state.offerReducer, EligibilityReasons.WipLimit)
  )

  const suspended = useSelector<AppState, boolean>(state =>
    canNotAcceptOffersDueToReason(state.offerReducer, EligibilityReasons.Suspended)
  )

  const userWorksInProgress = useSelector<AppState, number>(state => worksInProgress(state.offerReducer))

  const isLoading = useSelector<AppState, boolean>(state => isUserOfferPageLoading(state.offerReducer))
  const currentOffer = useSelector<AppState, Offer | null>(state => userCurrentOffer(state.offerReducer))
  const offers = useSelector<AppState, Offer[]>(state => userOffers(state.offerReducer))
  const hasOffers = !!offers && offers.length > 0

  const expireCurrentOffer = () => dispatch(expireCurrentUserOffer(userID))
  const onPreferencesUpdate = async (oldData: AssignmentPreferences, newData: AssignmentPreferences) => {
    await dispatch(updateUserAssignmentPreferences(oldData, newData, userID))
    dispatch(fetchOffersForUser(userID))
  }

  // Reset the current user offer when navigating away from the page
  useEffect(() => {
    return function() {
      if (!!currentOffer && (currentOffer.status !== OfferStatus.Offered || currentOffer.is_expired)) {
        dispatch(removeUserOffer(userID, currentOffer.offer_id))
        dispatch(discardCurrentUserOffer(userID))
      }
      dispatch(clearGlobalMeta(userID))
    }
  }, [userID, currentOffer, dispatch])

  const renderStats = (offerState: OfferState) => {
    if (!offerState.shoppingEligibility) {
      return null
    }

    return (
      <OrderShoppingStats
        variant="wip"
        timerActive={!!currentOffer && currentOffer.status === OfferStatus.Offered}
        onTimerEnd={expireCurrentOffer}
        currentOfferExpiresAt={!!currentOffer ? new Date(currentOffer.expires_at) : null}
        offersPerHour={offerState.shoppingEligibility.offers_per_hour}
        offersRejectedLastHour={offerState.shoppingEligibility.offers_rejected_last_hour}
        wipCount={offerState.shoppingEligibility.works_in_progress}
        wipMax={offerState.shoppingEligibility.wip_limit}
        hideRejections={isDeveloper || isOffshore || wipLimitReached}
      />
    )
  }

  const renderMainPrompt = (): React.ReactNode => {
    if (isActiveShopper || hasOffers) {
      return <Offers userID={userID} />
    } else if (capacityLimitReached) {
      return <StatedCapacityReached />
    } else if (newHireCapacityLimitReached) {
      return <NewHireCapacityReached />
    } else if (rejectionLimitReached) {
      return <OfferRejectionLimit />
    } else if (wipLimitReached) {
      return (
        <NoOrders
          headerText={i18n.t('order__shopping__wip_limit_reached__headline')}
          bodyText={i18n.t('order__shopping__wip_limit_reached__bodytext', { n: userWorksInProgress })}
        />
      )
    } else if (inventoryBelowPercentile) {
      return (
        <NoOrders
          headerText={i18n.t('order__shopping__low_inventory__headline')}
          bodyText={i18n.t('order__shopping__low_inventory__bodytext')}
        />
      )
    } else if (suspended) {
      return (
        <NoOrders
          headerText={i18n.t('order__shopping__suspended__headline')}
          bodyText={i18n.t('order__shopping__suspended__bodytext')}
        />
      )
    }
    return null
  }

  return !isLoading && !!offerState && location ? (
    <>
      <Helmet>
        <title>{i18n.t('order__shopping__pageTitle')}</title>
      </Helmet>
      <PageHeader>
        <Tabs indicatorColor={'primary'} value={location.pathname} aria-label="Order shopping tabs">
          <Tab
            className={classes.tabLabel}
            value={Routes.NewOrder}
            label={i18n.t('order__shopping__tab__title__new__order')}
            {...a11yProps(0)}
            component={GhostLink}
            to={Routes.NewOrder}
          />
          {showOrderShoppingPreferences && (
            <Tab
              className={classes.tabLabel}
              value={Routes.AssignmentPreferences}
              label={i18n.t('order__shopping__tab__title__preferences')}
              {...a11yProps(1)}
              component={GhostLink}
              to={Routes.AssignmentPreferences}
            />
          )}
        </Tabs>
      </PageHeader>
      <Route
        path={Routes.AssignmentPreferences}
        exact
        render={() => {
          if (!showOrderShoppingPreferences) {
            return <Redirect to={Routes.NewOrder} />
          }

          return (
            <Box className={classes.tabContainer}>
              {renderStats(offerState)}
              {!!assignmentPreferences && (
                <Box className={classes.container}>
                  <Preferences preferences={assignmentPreferences} onPreferencesUpdate={onPreferencesUpdate} />
                </Box>
              )}
            </Box>
          )
        }}
      />
      <Route
        exact
        path={Routes.NewOrder}
        render={() => (
          <Box className={classes.tabContainer}>
            {renderStats(offerState)}
            <Box className={classes.container}>{renderMainPrompt()}</Box>
          </Box>
        )}
      />
    </>
  ) : (
    <Loader fullPage={true} />
  )
}

export default OrderShoppingContainer
