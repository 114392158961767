import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import OrderSummary from './OrderSummary'
import { Toolkit } from './Toolkit'
import OrderPay from './OrderPay'
import { AppState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { ordersNeedFetching } from '../../../selectors/orders'
import { fetchOrder } from '../../../store/orders/actions'
import { HashMap, OrderItemStates, OrderItemTypes } from '../../../store/items/types'
import { isAdminSelector, isRezwizElgible } from '../../../selectors/users'
import { ClientOrdersDisplayAggregate, clientOrdersSelector } from '../../../selectors/clientOrderAggregates'
import { useOrderCarousel } from './useOrderCarousel'
import AutoLinkedinContainer from '../../AutoLinkedin/AutoLinkedinContainer'
import { UserInfo } from '../../../store/user/reducers'
import AutoLinkedinAction, { AutoLinkedinMode } from './AutoLinkedinAction'
import { AutoLinkedin } from '../../../store/autolinkedin/types'
import { getAutoLinkedinByItemID } from '../../../store/autolinkedin/selectors'
import useEvents from '../../common/useEvents'
import PhoneConsultation from './PhoneConsultation'
import useUserInfoState from '../../common/useUserInfo'
import { FulfillableOrderItemDisplay } from '../../Orders/OrderItems'

interface SideBarProps {
  clientID: number
  viewOrderPay?: boolean
  viewToolKit?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      '& > :not(:last-child)': {
        marginBottom: '1.25rem',
      },
      '@media (max-width: 1024px)': {
        backgroundColor: 'white',
        border: '1px solid #D5D9E0',
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '100%',
      },
      '@media (max-width: 560px)': {
        flexDirection: 'column-reverse',
        width: '100%',
      },
    },
  })
)

const SideBar: React.FC<SideBarProps> = ({ clientID, viewOrderPay, viewToolKit }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { sendEvent, eventTypes } = useEvents()

  const user = useSelector<AppState, UserInfo | null>(state => state.userReducer.getLoggedInUser())

  const [linkedinVisible, setLinkedinVisible] = useState<boolean>(false)

  const clientOrdersAggregate = useSelector<AppState, ClientOrdersDisplayAggregate | null>(state =>
    clientOrdersSelector(
      {
        ...state.clientReducer,
        ...state.clientOrdersAggregateReducer,
        ...state.itemsReducer,
        ...state.orderReducer,
        ...state.incentivesReducer,
        ...state.userReducer,
      },
      clientID
    )
  )
  const unfetchedOrders = useSelector<AppState, HashMap<boolean>>(state =>
    ordersNeedFetching(state.orderReducer, !clientOrdersAggregate ? [] : clientOrdersAggregate.orderIDs)
  )

  const clientDocumentDigest = useSelector<AppState, string | null>(state => {
    const { document_digest = null } = state.clientQuestionnaireReducer.questionnaires[clientID] || {}
    return document_digest || null
  })
  const resumeLocalizedName = useSelector<AppState, string>(
    state =>
      state.orderReducer.orders[!clientOrdersAggregate ? '' : clientOrdersAggregate.orderIDs[0]].brand
        .resume_localized_name
  )

  const isAdmin = useSelector<AppState, boolean>(state => isAdminSelector(state.userReducer))
  const showRezwiz = useSelector<AppState, boolean>(state => isRezwizElgible(state.userReducer))

  useEffect(() => {
    if (isAdmin && !!clientOrdersAggregate && clientOrdersAggregate.orderIDs) {
      clientOrdersAggregate.orderIDs.forEach(id => {
        if (unfetchedOrders[id]) {
          dispatch(fetchOrder(id, { verbose: true }))
        }
      })
    }
  }, [dispatch, isAdmin, clientOrdersAggregate, unfetchedOrders])

  const handleCTA = (val: boolean) => {
    setLinkedinVisible(val)
    sendEvent({
      event: !!autoLinkedin ? eventTypes.AutolinkedinCTAStart : eventTypes.AutolinkedinCTAEdit,
      variables: {
        client_id: clientID,
        user_id: user?.id,
        item_id: linkedinOrderItem?.id,
      },
    })
  }

  const { currentOrderGroup, moveForward, moveBackwards, hasMoreThanOneGroup } = useOrderCarousel(clientID)
  const linkedinOrderItem = currentOrderGroup.items.find(x => x.compound_item_key === OrderItemTypes.LinkedIn)

  const autoLinkedin = useSelector<AppState, AutoLinkedin | undefined | null>(state =>
    getAutoLinkedinByItemID(state.autolinkedinReducer, linkedinOrderItem?.id)
  )
  const { featureFlags } = useUserInfoState()
  const consultationItems = currentOrderGroup.items.filter(item => {
    const fulfillableItems = item as FulfillableOrderItemDisplay
    return (
      item.type === OrderItemTypes.PhoneConsultation &&
      // For Filtering out the legacy orders
      ![...fulfillableItems.history.completed, ...fulfillableItems.history.active].some(value =>
        [OrderItemStates.ScheduleInterview, OrderItemStates.SchedulePhoneCall].includes(value.state)
      )
    )
  }) as FulfillableOrderItemDisplay[]

  return (
    <>
      <Box className={classes.root}>
        {!!clientOrdersAggregate && (
          <OrderSummary
            clientID={clientID}
            currentOrderGroup={currentOrderGroup}
            moveBackwards={moveBackwards}
            moveForward={moveForward}
            hasMoreThanOneGroup={hasMoreThanOneGroup}
          />
        )}

        {featureFlags.showScheduler && !!consultationItems && consultationItems.length > 0 && (
          <PhoneConsultation
            clientID={clientID}
            consultationItems={consultationItems}
            showTranscripts={featureFlags.showTranscripts}
            showAudio={featureFlags.showAudio}
            showVideo={featureFlags.showVideo}
          />
        )}

        {!!linkedinOrderItem && (
          <AutoLinkedinAction
            autoLinkedinID={autoLinkedin?.id}
            linkedinItemID={linkedinOrderItem.id}
            mode={!!autoLinkedin ? AutoLinkedinMode.Edit : AutoLinkedinMode.Start}
            handleClick={() => handleCTA(true)}
          />
        )}
        {viewOrderPay && <OrderPay currentOrderGroup={currentOrderGroup} />}
        {viewToolKit && (
          <Toolkit
            clientID={clientID}
            documentDigest={clientDocumentDigest}
            resumeLocalizedName={resumeLocalizedName}
            showRezwiz={showRezwiz}
            showAdminSection={isAdmin}
          />
        )}
      </Box>
      {linkedinVisible && !!linkedinOrderItem && (
        <AutoLinkedinContainer
          currentOrderGroup={currentOrderGroup}
          linkedinItemID={linkedinOrderItem.id}
          autoLinkedinID={autoLinkedin?.id}
          clientID={clientID}
          handleCloseModal={() => handleCTA(false)}
        />
      )}
    </>
  )
}

export default SideBar
