import addDays from 'date-fns/addDays'
import format from 'date-fns/format'

import i18n from '../../../i18n'
import { Order } from '../../../store/orders/types'

// Helper function that will format the revision period end date
// @TODO This is only to get the MVP out of the door. In the future, this will be based upon item state. Refactor this
// when that becomes the case.
export default function(order: Order): string {
  let { firstdraft_timestamp } = order
  const { revision_period_days, language } = order

  // If it hasn't had a first draft submitted yet, default it to now for display purposes.
  if (!firstdraft_timestamp) {
    firstdraft_timestamp = new Date()
  }

  const dateFormatStr = i18n.t('date_format', { lng: language })
  const revisionEndDate = addDays(new Date(firstdraft_timestamp), revision_period_days)

  return format(revisionEndDate, dateFormatStr)
}
