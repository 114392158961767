import { getGhostID } from './link'

export const getFileDownloadLink = (fileID: number) => {
  const ghostID = getGhostID()
  let link = `/api/v1/files/id/${fileID}`
  if (!!ghostID) {
    link += `?ghost=${ghostID}`
  }
  return link
}
