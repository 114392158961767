import isEqual from 'lodash/isEqual'

import { useAppSelector } from '../../store'
import { getFeatureFlagsByUserID } from '../../selectors/featureFlags'

export default function useFeatureFlags() {
  const featureFlags = useAppSelector<ReturnType<typeof getFeatureFlagsByUserID>>(
    state => getFeatureFlagsByUserID(state.userReducer, state.userReducer.loggedInUserID || 0),
    isEqual
  )

  return featureFlags
}
