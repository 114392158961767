import { GhostLink } from '../../utils/link'
import Button from '@material-ui/core/Button'
import plusSignImage from '../../assets/svgs/plus-sign.svg'
import i18n from '../../i18n'

import React from 'react'
import DashboardStyles from '../Dashboard/Styles'
import classNames from 'classnames'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store'
import { OfferPromise } from '../../store/promises/types'
import { userCurrentPromise } from '../../selectors/promises'
import { convertUserPromise } from '../../store/promises/actions'

const useStyles = makeStyles(theme =>
  createStyles({
    getOrdersButton: {
      width: '10rem',
      backgroundColor: DashboardStyles.getOrdersButton.regularColor,
      color: 'white',
      textTransform: 'none',
      fontWeight: DashboardStyles.getOrdersButton.weight,
      '&:hover': {
        backgroundColor: DashboardStyles.getOrdersButton.hoverColor,
      },
    },
    expanded: {
      fontSize: '1.1rem',
      width: '100%',
    },
    image: {
      marginRight: '.5em',
    },
  })
)

interface Props {
  userID: number
  expanded?: boolean
}

const GetOrdersButton: React.FC<Props> = ({ userID, expanded }) => {
  const classes = useStyles()

  const [currentPromise] = useSelector<AppState, [OfferPromise | null, boolean]>(state =>
    userCurrentPromise(state.promiseReducer)
  )

  const dispatch = useDispatch()

  const onReviewOrdersClick = () => {
    if (!!currentPromise) {
      dispatch(convertUserPromise(currentPromise.id, currentPromise.auth_token, userID))
    }
  }

  return (
    <GhostLink to="/shopping/new-order" className={classNames()}>
      <Button
        id="get-orders-button"
        className={classNames({
          [classes.getOrdersButton]: true,
          [classes.expanded]: expanded,
        })}
        onClick={onReviewOrdersClick}
      >
        <img src={plusSignImage} className={classes.image} alt="" />
        {i18n.t('dashboard__getNewOrdersButton__title')}
      </Button>
    </GhostLink>
  )
}

export default GetOrdersButton
