import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import Button from '../common/Button'
import i18n from '../../i18n'
import styles from '../../styles'
import shoppingIcon from '../../assets/svgs/shopping.svg'
import Loader from '../common/Loader'
import { LoadedLoadingErrorState } from '../../utils/state'
import WarningIcon from '@material-ui/icons/Warning'
import { GhostLink } from '../../utils/link'
import useUserInfoState from '../common/useUserInfo'

interface DefaultOrderShoppingProps {
  onRequestNewOrderClick: () => void
  meta: LoadedLoadingErrorState | null
  availableForScheduledItems: boolean
  isSchedulingSetup: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    defaultOrderShopping: {
      color: styles.palette.lightGrey.color,
      textAlign: 'center',
    },
    headline: {
      color: styles.palette.lightBlack.color,
      marginBottom: '2.125rem',
    },
    image: {
      marginBottom: '2.5rem',
      '& > :first-child': { width: '7.875rem' },
    },
    bodyText: {
      color: styles.palette.lightBlack.color,
      marginBottom: '3.1125rem',
      padding: '0 7.5rem',
      '@media (max-width: 900px)': {
        padding: '0 4rem',
      },
      '@media (max-width: 768px)': {
        padding: 0,
      },
    },
    warning: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '1.5rem',
    },
    warningText: {
      fontSize: '12px',
      color: styles.palette.pink.hex,
    },
    warningIcon: {
      color: styles.palette.pink.hex,
      height: '.8rem',
      width: '.8rem',
      marginRight: '.8rem',
    },
    warningLink: {
      color: styles.palette.pink.hex,
      textDecoration: 'underline',
    },
  })
)

const DefaultOrderShopping: React.FC<DefaultOrderShoppingProps> = ({
  onRequestNewOrderClick,
  meta,
  availableForScheduledItems,
  isSchedulingSetup,
}) => {
  const classes = useStyles()
  const { featureFlags } = useUserInfoState()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const onSubmitClick = async () => {
    setIsSubmitting(true)
    await onRequestNewOrderClick()
  }

  if (!!meta && meta.isLoading && !isSubmitting) {
    return <Loader fullPage={true} />
  }

  return (
    <Box className={classes.defaultOrderShopping}>
      <Typography display="block" variant={'h1'} className={classes.headline}>
        {meta && meta.loaded
          ? i18n.t('order__shopping__no_offers__headline')
          : i18n.t('order__shopping__default__headline')}
      </Typography>
      <Box className={classes.image}>
        <img src={shoppingIcon} alt="" />
      </Box>
      <Typography display="block" variant={'body1'} className={classes.bodyText}>
        {meta && meta.loaded
          ? i18n.t('order__shopping__no_offers__bodytext')
          : i18n.t('order__shopping__default__bodytext')}
      </Typography>
      {meta && !meta.loaded && (
        <Button
          type={'primary'}
          size={'medium'}
          disabled={isSubmitting}
          onClick={onSubmitClick}
          showLoader={isSubmitting}
          id="button-new-order"
        >
          {i18n.t('order__shopping__request__new__order')}
        </Button>
      )}
      {featureFlags.showScheduler && !availableForScheduledItems && (
        <Box className={classes.warning}>
          <WarningIcon className={classes.warningIcon} />
          <Typography className={classes.warningText}>
            {i18n.t('schedule_order_low_availability')}
            <GhostLink className={classes.warningLink} to={'/'}>
              {isSchedulingSetup ? i18n.t('schedule_order_low_availability_link') : i18n.t('schedule_not_setup_link')}
            </GhostLink>
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default DefaultOrderShopping
