import React from 'react'
import { Helmet } from 'react-helmet'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'

import theme from '../theme'
import i18n from '../i18n'
import Page from './Page/Page'
import AuthPages from './Auth'

const style = {
  layout: {
    backgroundColor: '#fcfcfc',
  },
  menu: {
    backgroundColor: 'red',
    width: '15rem',
  },
}

const App: React.FC = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Helmet titleTemplate={`%s | ${i18n.t('pageTitle__suffix')}`} />
      <Paper style={style.layout}>
        <AuthPages />
        <Page />
      </Paper>
    </MuiThemeProvider>
  )
}

export default App
