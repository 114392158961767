import React, { useCallback, useState } from 'react'
import i18n from '../../i18n'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { Dictionary, groupBy, map } from 'lodash'
import { useHistory } from 'react-router-dom'

import DocumentItemV2 from './DocumentItemV2'
import {
  Document,
  DocumentTypes,
  EditorDecisions,
  FileContexts,
  JudgedDocument,
  UploadGoal,
} from '../../store/documents/types'
import useUserInfoState from '../common/useUserInfo'
import Button from '../common/Button'
import { useOrderCarousel } from '../OrderFamily/SideBar/useOrderCarousel'
import styles from '../../styles'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store'
import { userHasClientReadyDocs } from '../../selectors/documents'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import UploadStyles from '../UploadsV3/styles'
import { deleteDocument, reviewDocumentThunk } from '../../store/documents/actions'
import { OrderItemStates } from '../../store/items/types'
import { FulfillableOrderItemDisplay } from '../Orders/OrderItems'
import UploadsContainer from '../UploadsV3/UploadsContainer'

export interface DocumentsContainerProps {
  editorUploads: Document[]
  clientUploads: Document[]
  writerUploads: Document[]
  clientID: number
  orderIDs: number[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      color: styles.palette.darkerBlue.color.hex,
      display: 'flex',
      background: '#fff',
      border: '1px solid #D5D9E0',
      padding: '2.375rem 2.8125rem',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      '@media (max-width: 1050px)': {
        padding: '2rem 2rem',
      },
      '@media (max-width: 560px)': {
        flexDirection: 'column',
      },
    },
    column: {
      width: '45%',
      boxSizing: 'border-box',
      '@media (max-width: 560px)': {
        width: '100%',
        '&:not(:first-child)': { marginTop: '3rem' },
      },
    },
    row: {
      marginBottom: '2rem',
      width: '100%',
    },
    mainTitle: {
      marginBottom: '1.3125rem',
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '1.5625rem',
    },
    section: {
      '&:not(:last-child)': {
        marginBottom: '2.375rem',
      },
    },
    sectionTitle: {
      marginBottom: '.6rem',
      textTransform: 'uppercase',
      fontSize: '.875rem',
      fontWeight: 600,
      lineHeight: '1.375rem',
    },
    files: {
      '& .file-container': {
        '&:not(:last-child)': { marginBottom: '.625rem' },
        '& .title': {
          marginBottom: '.25rem',
          fontWeight: 500,
          lineHeight: '1.375rem',
        },
      },
    },
    modal: {
      color: UploadStyles.darkBlue.hex,
      padding: '1.8rem 1.9rem 1.6rem',
      width: '100%',
      maxWidth: '487px',
    },
    modalTitle: {
      fontWeight: 'bold',
      fontSize: '1rem',
      marginBottom: '1.2rem',
    },
    modalBody: {
      fontSize: '.9em',
      fontWeight: 300,
      marginBottom: '1em',
    },
  })
)

const OrderDocuments: React.FC<DocumentsContainerProps> = ({
  editorUploads,
  writerUploads,
  clientUploads,
  clientID,
  orderIDs,
}) => {
  const classes = useStyles()

  const history = useHistory()
  const userInfo = useUserInfoState()
  const cta = useOrderCarousel(clientID)
  const dispatch = useDispatch()

  const awaitingReviewItem = cta.currentOrderGroup.items.find(i =>
    (i as FulfillableOrderItemDisplay)?.history?.active.find(s => s.state === OrderItemStates.AwaitingReview)
  )

  const clientContextDocsExist = useSelector<AppState, boolean>(state =>
    userHasClientReadyDocs(state.documentReducer, userInfo.id, clientID)
  )

  const [uploadContext, setUploadContext] = useState<FileContexts>(
    !!history.location.state?.context
      ? history.location.state?.context
      : ((userInfo.isExpertMentee && !clientContextDocsExist ? 'editor-client' : 'client') as FileContexts)
  )
  const uploadOpen = !!history.location.state?.uploadOpen ? history.location.state?.uploadOpen : false

  const [judgingInProgress, setJudgingInProgress] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(uploadOpen)
  const [judgedDoc, setJudgedDoc] = useState<null | JudgedDocument>(null)

  const groupedWriterUploads: Dictionary<Document[]> = groupBy<Document>(writerUploads, d => d.type)
  const groupedEditorUploads: Dictionary<Document[]> = groupBy<Document>(editorUploads, d => d.type)

  const onDeleteDocumentClick = useCallback(
    (fileID: number, context: FileContexts, contextID: number) => {
      dispatch(deleteDocument(fileID, contextID, context))
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [deleteDocument]
  )

  const onAttemptJudgeClick = useCallback((fileID: number, verdict: EditorDecisions) => {
    setJudgedDoc({
      verdict,
      fileID,
    })
  }, [])

  const confirmJudging = useCallback(async () => {
    if (!judgedDoc) {
      return
    }
    setJudgingInProgress(true)
    await dispatch(reviewDocumentThunk(judgedDoc.fileID, clientID, judgedDoc.verdict))
    setJudgingInProgress(false)
    setJudgedDoc(null)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [judgedDoc, setJudgingInProgress])

  const cancelJudging = useCallback(() => {
    setJudgedDoc(null)
  }, [setJudgedDoc])

  const renderFile = (file: Document) => {
    const isUploadedByClient = !file.user_id
    const revisionNumber = file.revision
    const revision = revisionNumber === 0 ? i18n.t('revision_0') : i18n.t('revision_n', { revision: revisionNumber })

    const isReviewable = !!awaitingReviewItem && file.order_item_id === awaitingReviewItem.id

    return (
      <Box key={file.id} className="file-container">
        {!!revision && !isUploadedByClient && (
          <Typography variant={'body2'} className="title">
            {revision}
          </Typography>
        )}
        <DocumentItemV2
          document={file}
          onAttemptJudgeClick={onAttemptJudgeClick}
          awaitingReview={isReviewable}
          onDeleteClick={onDeleteDocumentClick}
        />
      </Box>
    )
  }

  const renderDocumentGroup = (groupKey: DocumentTypes, docs: Document[]) => {
    if (docs.length === 0) {
      return null
    }
    return (
      <Box key={groupKey} className={classes.section}>
        <Typography variant={'body2'} className={classes.sectionTitle}>
          {groupKey}
        </Typography>
        <Box className={classes.files}>{docs.map(doc => renderFile(doc))}</Box>
      </Box>
    )
  }

  const closeWindow = useCallback(
    (attachedDocuments?: boolean) => {
      setShowUploadModal(false)

      if (attachedDocuments) {
        // Upon successful submission, redirect to the messaging page
        history.push({
          pathname: window.location.pathname.replace('documents', 'messages'),
          search: window.location.search,
        })
      }
    },
    [setShowUploadModal, history]
  )

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.row}>
          <Button type="primary" onClick={() => setShowUploadModal(true)}>
            {i18n.t('documents__uploadDocuments')}
          </Button>
        </Box>
        {editorUploads.length > 0 && (
          <Box className={classes.row}>
            <Box className={classes.column}>
              <Typography variant={'body1'} className={classes.mainTitle}>
                {i18n.t('documents__editorUploads')}
              </Typography>
              {map(groupedEditorUploads, (values: Document[], key: DocumentTypes) => renderDocumentGroup(key, values))}
            </Box>
          </Box>
        )}
        <Box className={classes.column}>
          <Typography variant={'body1'} className={classes.mainTitle}>
            {i18n.t('documents__uploads')}
          </Typography>
          {map(groupedWriterUploads, (values: Document[], key: DocumentTypes) => renderDocumentGroup(key, values))}
        </Box>
        <Box className={classes.column}>
          <Typography variant={'body1'} className={classes.mainTitle}>
            {i18n.t('documents__customerUploads')}
          </Typography>
          <Box className={classes.files}>{clientUploads.map(doc => renderFile(doc))}</Box>
        </Box>
      </Box>
      {showUploadModal && (
        <UploadsContainer
          goal={UploadGoal.Upload}
          context={uploadContext}
          setUploadContext={setUploadContext}
          contextID={clientID}
          orderIDs={orderIDs}
          closeWindow={closeWindow}
          isOnboardingMentee={userInfo.isExpertMentee}
          cta={cta.currentOrderGroup.macroState.CTA}
          canSkipAQAFeedback={userInfo.isEditor || userInfo.isAdmin}
        />
      )}
      <Dialog open={!!judgedDoc} classes={{ paper: classes.modal }}>
        <Typography variant="h2" className={classes.modalTitle}>
          {judgedDoc?.verdict === EditorDecisions.Approve && i18n.t('documents__editor_upload__rusure_title_a')}
          {judgedDoc?.verdict === EditorDecisions.Reject && i18n.t('documents__editor_upload__rusure_title_r')}
        </Typography>
        <Typography className={classes.modalBody}>{i18n.t('documents__editor_upload__rusure_body')}</Typography>
        <DialogActions>
          <Button type="secondary" onClick={cancelJudging} disabled={judgingInProgress}>
            {i18n.t('documents__editor_upload__rusure_no')}
          </Button>
          <Button type="contrast" onClick={confirmJudging} disabled={judgingInProgress}>
            {i18n.t('documents__editor_upload__rusure_yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default OrderDocuments
