import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { AppState } from '../../store'
import { AcceptRejectFeedbackOfferResponse, OfferLoadingState, Offer, OfferFeedback } from '../../store/offers/types'
import {
  canAcceptOffers,
  userCurrentOffer,
  userCurrentOfferMeta,
  userOfferState,
  isAvailableForScheduledItems,
} from '../../selectors/offers'
import {
  discardCurrentUserOffer,
  generateOfferForUser,
  promoteNextOfferToCurrent,
  removeUserOffer,
  submitOfferFeedback,
} from '../../store/offers/actions'
import { acceptOfferThunk, rejectOfferThunk } from '../../store/offers/thunks'
import NewOffer from './NewOffer'
import DefaultOrderShopping from './DefaultOrderShopping'
import { AxiosMiddlewareActionCreator, AxiosMiddlewareActionSuccess } from '../../utils/axios'
import { getOrderItemsForDisplay } from '../../selectors/orders'
import Loader from '../common/Loader'
import useUserInfoState from '../common/useUserInfo'
import { useIntercomTracking } from '../common/IntercomContainer'
import { LoadedLoadingErrorState } from '../../utils/state'
import { userSetupScheduling } from '../../store/scheduling/selectors'

export interface OffersProps {
  userID: number
}

export const Offers: React.FC<OffersProps> = ({ userID }) => {
  const dispatch = useDispatch()

  const currentOffer = useSelector<AppState, Offer | null>(state => userCurrentOffer(state.offerReducer))
  const currentOfferMeta = useSelector<AppState, OfferLoadingState | null>(state =>
    userCurrentOfferMeta(state.offerReducer)
  )

  const eligibleForOffers = useSelector<AppState, boolean>(state => canAcceptOffers(state.offerReducer))

  const availableForScheduledItems = useSelector<AppState, boolean>(state =>
    isAvailableForScheduledItems(state.offerReducer)
  )

  const isSchedulingSetup = useSelector<AppState, boolean>(state => userSetupScheduling(state.schedulingReducer))

  const userInfo = useUserInfoState()
  const promisesLoading = useSelector<AppState, boolean>(state => state.promiseReducer.meta.isLoading)
  const offersMetaState = useSelector<AppState, LoadedLoadingErrorState | null>(state =>
    userOfferState(state.offerReducer)
  )
  const requestNewOffer = useCallback(async () => {
    if (!eligibleForOffers) {
      await dispatch(discardCurrentUserOffer(userID))
      return
    }
    await dispatch(generateOfferForUser(userID))
  }, [dispatch, eligibleForOffers, userID])

  const sendOfferAcceptedEventToIntercom = useIntercomTracking('Offer Accepted')

  const offerProductsForDisplay =
    !!currentOffer && !!currentOffer.products
      ? getOrderItemsForDisplay(currentOffer.products, currentOffer.expires_at)
      : []

  const feedbackSubmitClick = useCallback(
    (offerToken: string, offerID: number) => {
      return async (feedback: OfferFeedback) => {
        if (currentOfferMeta && currentOfferMeta.submitFeedback.isLoading) {
          return
        }
        return (dispatch(submitOfferFeedback(userID, offerToken, offerID, feedback)) as unknown) as Promise<
          AxiosMiddlewareActionSuccess<AcceptRejectFeedbackOfferResponse, AxiosMiddlewareActionCreator>
        >
      }
    },
    [dispatch, userID, currentOfferMeta]
  )

  const acceptOfferClick = useCallback(
    async (offerToken: string, offerID: number) => {
      if (currentOfferMeta && currentOfferMeta.accept.isLoading) {
        return
      }
      if (userInfo.isNewHire) {
        const onSuccessfulAccept = () => sendOfferAcceptedEventToIntercom({ offerId: offerID })
        return dispatch(acceptOfferThunk(userID, offerToken, offerID, onSuccessfulAccept))
      }
      return dispatch(acceptOfferThunk(userID, offerToken, offerID))
    },
    [dispatch, userID, currentOfferMeta, userInfo, sendOfferAcceptedEventToIntercom]
  )

  const rejectOfferClick = useCallback(
    async (offerToken: string, offerID: number) => {
      if (currentOfferMeta && currentOfferMeta.reject.isLoading) {
        return
      }
      return dispatch(rejectOfferThunk(userID, offerToken, offerID))
    },
    [dispatch, userID, currentOfferMeta]
  )

  const removeOffer = useCallback(
    (offerID: number) => {
      return dispatch(removeUserOffer(userID, offerID))
    },
    [dispatch, userID]
  )

  const discardCurrentOffer = useCallback(async () => {
    await dispatch(discardCurrentUserOffer(userID))
    dispatch(promoteNextOfferToCurrent(userID))
  }, [dispatch, userID])

  if (promisesLoading) {
    return <Loader fullPage={true} />
  }

  return (
    <>
      {!!currentOffer && !!currentOfferMeta ? (
        <NewOffer
          currentOffer={currentOffer}
          currentOfferMeta={currentOfferMeta}
          onAcceptClick={() => acceptOfferClick(currentOffer.auth_token, currentOffer.offer_id)}
          onRejectClick={() => rejectOfferClick(currentOffer.auth_token, currentOffer.offer_id)}
          onFeedbackSubmit={feedbackSubmitClick(currentOffer.auth_token, currentOffer.offer_id)}
          removeOffer={() => removeOffer(currentOffer.offer_id)}
          discardCurrentUserOffer={discardCurrentOffer}
          isLoading={!!offersMetaState ? offersMetaState.isLoading : false}
          offerItems={offerProductsForDisplay}
          onRequestNewOfferClick={requestNewOffer}
        />
      ) : (
        <DefaultOrderShopping
          onRequestNewOrderClick={requestNewOffer}
          meta={offersMetaState}
          availableForScheduledItems={availableForScheduledItems}
          isSchedulingSetup={isSchedulingSetup}
        />
      )}
    </>
  )
}

const memoizedOffers = React.memo(Offers, isEqual)
// @ts-ignore
memoizedOffers.whyDidYouRender = true

export default memoizedOffers
