import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

import styles from '../../styles'

const LightTooltip = withStyles(() => ({
  tooltip: {
    color: styles.palette.darkBlue.color,
    backgroundColor: '#fff',
    fontSize: '.8rem',
    border: 'solid 1px rgba(46, 91, 255, 0.08)',
    boxShadow: '0 5px 10px 0 rgba(106, 117, 140, 0.05)',
    '& a': {
      color: styles.palette.primary.color,

      '&:hover, &:active, &focus': {
        textDecoration: 'underline',
      },
    },
  },
}))(Tooltip)

export default LightTooltip
