import produce from 'immer'

import { DocumentState } from './types'
import { DOCUMENT, DocumentActionTypes } from './actions'
import { UPLOAD } from '../uploads/actions'
import { mapDocumentResponseToDoc } from '../../utils/mapper'
import { initialLoadedLoadingErrorState, setErrorState, setStartState, setSuccessState } from '../../utils/state'
import { filterUnimportantResults } from '../../selectors/autoQA'

export const initialState: DocumentState = {
  user: {},
  client: {},
  message: {},
  'editor-client': {},
  autoQA: {},
}

export default function documentReducer(
  state: DocumentState = initialState,
  action: DocumentActionTypes
): DocumentState {
  return produce(state, (draft: DocumentState) => {
    switch (action.type) {
      case DOCUMENT.DELETE_DOC: {
        draft[action.context][action.contextID].meta = setStartState(state[action.context][action.contextID].meta)
        break
      }
      case DOCUMENT.DELETE_DOC_SUCCESS: {
        const previousAction = action.meta.previousAction
        if (!!draft[previousAction.context][previousAction.contextID].docs) {
          delete draft[previousAction.context][previousAction.contextID].docs[previousAction.fileID]
        }
        break
      }
      case DOCUMENT.DELETE_DOC_FAIL: {
        const previousAction = action.meta.previousAction
        if (!!draft[previousAction.context][previousAction.contextID]) {
          draft[previousAction.context][previousAction.contextID].meta = setErrorState(
            state[previousAction.context][previousAction.contextID].meta,
            action.error
          )
        }
        break
      }
      case DOCUMENT.REVIEW_DOC: {
        draft[action.context][action.contextID].meta = setStartState(state[action.context][action.contextID].meta)
        break
      }
      case DOCUMENT.REVIEW_DOC_SUCCESS: {
        const previousAction = action.meta.previousAction
        if (!!draft[previousAction.context][previousAction.contextID]) {
          draft[previousAction.context][previousAction.contextID].meta = setSuccessState(
            state[previousAction.context][previousAction.contextID].meta
          )
        }
        break
      }
      case DOCUMENT.REVIEW_DOC_FAIL: {
        const previousAction = action.meta.previousAction
        if (!!draft[previousAction.context][previousAction.contextID]) {
          draft[previousAction.context][previousAction.contextID].meta = setErrorState(
            state[previousAction.context][previousAction.contextID].meta,
            action.error
          )
        }
        break
      }
      case DOCUMENT.FETCH_DOCUMENTS:
        if (!draft[action.context][action.contextID]) {
          draft[action.context][action.contextID] = {
            meta: initialLoadedLoadingErrorState,
            docs: {},
          }
        }
        if (!draft['message']) {
          draft['message'] = {}
        }
        draft[action.context][action.contextID].meta.isLoading = true
        draft[action.context][action.contextID].meta.loaded = false
        draft[action.context][action.contextID].meta.error = null
        break
      case DOCUMENT.FETCH_DOCUMENTS_SUCCESS:
        const previousAction = action.meta.previousAction
        draft[previousAction.context][previousAction.contextID].meta.isLoading = false
        draft[previousAction.context][previousAction.contextID].meta.loaded = true
        const documents = action.payload.data
        if (documents) {
          documents.forEach(doc => {
            if (!draft[doc.context][doc.context_id]) {
              draft[doc.context][doc.context_id] = {
                docs: {},
                meta: {
                  isLoading: false,
                  loaded: true,
                  error: null,
                },
              }
            }
            draft[doc.context][doc.context_id].docs[doc.id] = mapDocumentResponseToDoc(doc)
          })
        }
        break
      case DOCUMENT.FETCH_DOCUMENTS_FAIL: {
        const previousAction = action.meta.previousAction
        draft[previousAction.context][previousAction.contextID].meta.isLoading = false
        draft[previousAction.context][previousAction.contextID].meta.loaded = true
        draft[previousAction.context][previousAction.contextID].meta.error = action.error
        break
      }
      case UPLOAD.UPLOAD_FINALIZE_SUCCESS:
        const document = action.payload.data.file
        if (!draft[document.context][document.context_id]) {
          draft[document.context][document.context_id] = {
            meta: initialLoadedLoadingErrorState,
            docs: {},
          }
        }
        draft[document.context][document.context_id].docs[document.id] = mapDocumentResponseToDoc(document)
        break

      case DOCUMENT.FETCH_AUTOQA_RESULTS:
        draft.autoQA[action.fileID] = {
          meta: setStartState(initialLoadedLoadingErrorState),
          autoQA: null,
        }
        break

      case DOCUMENT.FETCH_AUTOQA_RESULTS_SUCCESS:
        {
          const { fileID } = action.meta.previousAction

          if (action.payload.data && 'total_result' in action.payload.data) {
            // @ts-ignore
            draft.autoQA[fileID].autoQA = filterUnimportantResults(action.payload.data)
          }

          draft.autoQA[fileID].meta = setSuccessState(state.autoQA[fileID].meta)
        }
        break

      case DOCUMENT.FETCH_AUTOQA_RESULTS_FAIL:
        {
          const { fileID } = action.meta.previousAction
          draft.autoQA[fileID].meta = setErrorState(state.autoQA[fileID].meta, action.error)
        }
        break
    }
  })
}
