import {
  AutoLinkdeinState,
  AutoLinkedin,
  AutoLinkedinMeta,
  AutoLinkedinRevision,
  AutoLinkedinSections,
  ClientDataPoint,
  LILocation,
  RelevantClientDataPoints,
  Sections,
  Summary,
} from './types'
import { LoadedLoadingErrorState } from '../../utils/state'
import { PersonalData } from '@talentinc/linkedin-preview/lib/PersonalInfoSection'

export const getAutoLinkedinByItemID = (state: AutoLinkdeinState, itemID?: number): AutoLinkedin | undefined | null => {
  if (!itemID) {
    return null
  }
  return state.byItemID[itemID]?.autoLinkedin
}

export const getAutoLinkedinGenerateMeta = (
  state: AutoLinkdeinState,
  itemID: number
): LoadedLoadingErrorState | undefined => {
  return state.byItemID[itemID]?.generateMeta
}

export const getAutoLinkedinUpdateMeta = (
  state: AutoLinkdeinState,
  itemID: number
): LoadedLoadingErrorState | undefined => {
  return state.byItemID[itemID]?.updateMeta
}

export const getAutoLinkedinPublishMeta = (
  state: AutoLinkdeinState,
  itemID: number
): LoadedLoadingErrorState | undefined => {
  return state.byItemID[itemID]?.publishMeta
}

export const getLatestAutoLinkRevision = (
  state: AutoLinkdeinState,
  ID?: number | null
): AutoLinkedinRevision | null => {
  if (!ID || !state.revisions[ID]) {
    return null
  }
  const allRevisions = Object.values(state.revisions[ID])
  if (allRevisions.length === 0) {
    return null
  }
  return allRevisions.sort((a: AutoLinkedinRevision, b: AutoLinkedinRevision) => (a.id > b.id ? -1 : 1))[0]
}

export const getAutoLinkRevisions = (state: AutoLinkdeinState, ID?: number | null): AutoLinkedinRevision[] => {
  if (!ID || !state.revisions[ID]) {
    return []
  }
  const allRevisions = Object.values(state.revisions[ID])
  if (allRevisions.length === 0) {
    return []
  }
  return allRevisions.sort((a: AutoLinkedinRevision, b: AutoLinkedinRevision) => (a.id > b.id ? -1 : 1))
}

export const getAutoLinkJsonMeta = (state: AutoLinkdeinState, itemID: number): AutoLinkedinMeta | null => {
  try {
    // @ts-ignore
    return state.byItemID[itemID].autoLinkedin.meta
  } catch (e) {
    return null
  }
}

export const resumeHasLinkedin = (state: AutoLinkdeinState, baseDocID: number): boolean => {
  return !!Object.values(state.byItemID)
    .map(d => d.autoLinkedin)
    // @ts-ignore
    .find(a => a?.resume_file_id === baseDocID)
}

export const getAutoLinkedinSummary = (state: AutoLinkdeinState, itemID: number): Summary | null | undefined => {
  try {
    // @ts-ignore
    return state.byItemID[itemID]?.autoLinkedin?.data_json?.summary
  } catch (e) {
    return null
  }
}

export const getAutoLinkedinHeadline = (state: AutoLinkdeinState, itemID: number): string | null | undefined => {
  try {
    // @ts-ignore
    return state.byItemID[itemID]?.autoLinkedin?.data_json?.headline
  } catch (e) {
    return null
  }
}

export const getAutoLinkedinClientData = (state: AutoLinkdeinState, itemID: number): ClientDataPoint[] => {
  try {
    const points: ClientDataPoint[] = []
    const json = state.byItemID[itemID]?.autoLinkedin?.data_json

    if (!!json) {
      Object.values(RelevantClientDataPoints).forEach(key => {
        switch (key) {
          case RelevantClientDataPoints.Skills:
            if (!!json?.skills) {
              points.push({
                titleKey: 'skills',
                content: json?.skills.map(e => e.name).join(', '),
              })
            }
            break
          default:
            if (!!json?.career[key]) {
              points.push({
                titleKey: key,
                content: String(json?.career[key]),
              })
            }
            break
        }
      })
    }
    return points
  } catch (e) {
    return []
  }
}

export const getPersonalInfo = (state: AutoLinkdeinState, itemID: number): PersonalData | null => {
  try {
    // @ts-ignore
    const autoLinkJson = state.byItemID[itemID]?.autoLinkedin?.data_json
    if (!autoLinkJson) {
      return null
    }
    return {
      name: `${autoLinkJson.personalInfo.firstName} ${autoLinkJson.personalInfo.lastName}`,
      location: getLocationCopy(autoLinkJson.personalInfo.location),
      headline: autoLinkJson.headline,
      lastWorkInstitution: autoLinkJson[AutoLinkedinSections.WorkExperience]?.institutions[0].companyName,
      lastEducationInstitution: autoLinkJson[AutoLinkedinSections.Education]?.institutions[0].schoolName,
    }
  } catch (e) {
    return null
  }
}

const getLocationCopy = (location: LILocation): string => {
  if (!location.address) {
    return location.freeForm
  }
  if (!!location.address.state && !!location.address.city) {
    return `${location.address.city}, ${location.address.state}`
  }
  if (!!location.address.city && location.address.countryCode) {
    return `${location.address.city}, ${location.address.countryCode}`
  }
  if (!!location.address.countryCode) {
    return location.address.countryCode
  }
  return ''
}

export const getAutoLinkedinParsedSections = (state: AutoLinkdeinState, itemID: number): Sections[] => {
  try {
    // @ts-ignore
    const autoLinkJson = state.byItemID[itemID]?.autoLinkedin?.data_json
    if (!autoLinkJson) {
      return []
    }

    let sections: Sections[] = []

    Object.values(AutoLinkedinSections).forEach(key => {
      const initValue = autoLinkJson[key]
      switch (key) {
        case AutoLinkedinSections.WorkExperience:
        case AutoLinkedinSections.Education:
          // @ts-ignore
          sections.push({
            section: key,
            fieldArrayName: `${key}.institutions`,
            data: {
              // @ts-ignore
              [key]: !!initValue && !!initValue.institutions ? initValue : { institutions: [] },
            },
            // @ts-ignore
            recordCount: !!initValue && !!initValue.institutions ? initValue.institutions.length : 0,
          })
          break
        default:
          // @ts-ignore
          sections.push({
            section: key,
            fieldArrayName: key,
            data: {
              [key]: !!initValue ? initValue : [],
            },
            // @ts-ignore
            recordCount: !!initValue ? initValue.length : 0,
          })
          break
      }
    })

    sections = [...sections.filter(s => s.recordCount > 0), ...sections.filter(s => s.recordCount === 0)]

    return sections
  } catch (e) {
    return []
  }
}
