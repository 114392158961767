import { OfferLoadingState, Offer, OfferState, EligibilityReasons } from '../store/offers/types'
import { LoadedLoadingErrorState } from '../utils/state'

export const userOffers = (state: OfferState): Offer[] => {
  try {
    return Object.values(state.offers)
  } catch {
    return []
  }
}

export const userCurrentOffer = (state: OfferState): Offer | null => {
  try {
    const currentOfferID = state.currentOfferID
    return !!currentOfferID ? state.offers[currentOfferID] : null
  } catch {
    return null
  }
}

export const userCurrentOfferMeta = (state: OfferState): OfferLoadingState | null => {
  try {
    const currentOfferID = state.currentOfferID as number
    return state.meta.individual[currentOfferID]
  } catch {
    return null
  }
}

export const canAcceptOffers = (state: OfferState): boolean => {
  try {
    const shoppingEligibility = state.shoppingEligibility
    return !!shoppingEligibility && shoppingEligibility.eligible_for_offers
  } catch (e) {
    return false
  }
}

export const worksInProgress = (state: OfferState): number => {
  try {
    const shoppingEligibility = state.shoppingEligibility
    return !!shoppingEligibility ? shoppingEligibility.works_in_progress : 0
  } catch (e) {
    return 0
  }
}

export const wipLimitReached = (state: OfferState): boolean => {
  try {
    // @ts-ignore
    return (
      !!state.shoppingEligibility?.wip_limit &&
      state.shoppingEligibility?.works_in_progress >= state.shoppingEligibility?.wip_limit
    )
  } catch (e) {
    return false
  }
}

export const canNotAcceptOffersDueToReason = (state: OfferState, reason: EligibilityReasons): boolean => {
  try {
    const shoppingEligibility = state.shoppingEligibility
    return (
      !!shoppingEligibility &&
      !shoppingEligibility.eligible_for_offers &&
      shoppingEligibility.eligibility_reason === reason
    )
  } catch (e) {
    return false
  }
}

export const userOfferState = (state: OfferState): LoadedLoadingErrorState | null => {
  try {
    return state.meta.global
  } catch (e) {
    return null
  }
}

export const isUserOfferPageLoading = (state: OfferState): boolean => {
  try {
    return state.meta.global.isLoading && Object.keys(state.meta.individual).length === 0
  } catch (e) {
    return false
  }
}

export const isAvailableForScheduledItems = (state: OfferState): boolean => {
  try {
    const shoppingEligibility = state.shoppingEligibility
    return !!shoppingEligibility ? shoppingEligibility.is_available_for_scheduled_items : false
  } catch (e) {
    return false
  }
}
