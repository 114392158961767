import { Field, FieldRenderProps } from 'react-final-form'
import i18n from '../../i18n'
import React, { useCallback } from 'react'
import { ControlTypes, FormFieldSchema } from './formSchema'
import { LocationField } from './LocationField'
import { AutoLinkedinSections } from '../../store/autolinkedin/types'
import { TimePeriodField } from './TimePeriodField'
import isEqual from 'lodash/isEqual'
import FieldsOfStudyField from './FieldsOfStudyField'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import TeamMembersField from './TeamMembersField'
import DateDropdown from './DateDropdown'
import RichTextField from './RichTextField'
import { ClassNameMap } from '@material-ui/styles/withStyles'

export interface ALIFormFieldProps {
  fieldSchema: FormFieldSchema
  outerName: string
  index: number
  section: AutoLinkedinSections
  required: boolean
  pristine: boolean
  readOnly: boolean
  maxLength?: number | undefined
}

const useStyles = makeStyles(() =>
  createStyles({
    checkbox: {
      color: styles.palette.darkerBlue.color.hex,
    },
    dateField: {
      width: '20%',
    },
    formControlLabel: {
      color: styles.palette.darkerBlue.color.hex,
      fontSize: '.93em',
      fontWeight: 500,
    },
    formControlRoot: {
      marginBottom: '1.31em',
    },
    gradeField: {
      width: '5%',
    },
    inputGroup: {
      marginBottom: '1.3em',
      marginTop: '.71em',
    },
    input: {
      color: styles.palette.darkerBlue.color.hex,
      backgroundColor: styles.palette.inputGray.color,
      borderBottom: `1px solid ${styles.palette.darkerBlue.color.hex}`,
      padding: '.1em',
      '&.Mui-error': {
        borderBottom: `1px solid ${styles.palette.pink.hex}`,
      },
    },
    inputLabel: {
      color: styles.palette.darkerBlue.color.hex,
      fontSize: '.75rem',
      fontWeight: 600,
      marginBottom: '.83em',
      marginTop: '-.71em',
      textTransform: 'uppercase',
      transform: 'translate(0, 1.5px)',
      '&.Mui-error': {
        color: styles.palette.pink.hex,
      },
    },
    noPadding: {
      padding: '0',
    },
  })
)

// @ts-ignore
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

const renderPrimitiveControl = (
  fieldRenderProps: FieldRenderProps<string | boolean>,
  configProps: ALIFormFieldProps,
  classes: ClassNameMap
) => {
  const { fieldSchema, section } = configProps

  switch (fieldSchema.type) {
    case ControlTypes.Text:
      return (
        <TextField
          {...fieldRenderProps.input}
          className={classes.inputGroup}
          InputLabelProps={{ className: classes.inputLabel }}
          InputProps={{
            className: classes.input,
          }}
          inputProps={{ className: classes.noPadding }}
          label={i18n.t(`alink__${section}__${fieldSchema.name}`)}
          error={fieldRenderProps.meta.error}
          helperText={fieldRenderProps.meta.error ? fieldRenderProps.meta.error : null}
        />
      )
    case ControlTypes.Checkbox:
      return (
        <FormControlLabel
          classes={{ label: classes.FormControlLabel, root: classes.formControlRoot }}
          control={
            <Checkbox
              color="default"
              classes={{ root: classes.checkbox, checked: classes.checkbox }}
              {...fieldRenderProps.input}
            />
          }
          label={i18n.t(`alink__${section}__${fieldSchema.name}`)}
        />
      )
    default:
      return null
  }
}

const ALIFormField: React.FC<ALIFormFieldProps> = props => {
  const classes = useStyles()

  const validateMaxLength = useCallback(
    (value: string) =>
      !!props.maxLength && !!value && value.length > props.maxLength
        ? i18n.t('alink__error__limit_exceeded', { diff: value.length - props.maxLength })
        : undefined,
    [props.maxLength]
  )

  const validateSystemException = useCallback(
    (value: string) => (!!value && value.search(/system.?\(+/i) >= 0 ? i18n.t('alink__updateSystemError') : undefined),
    []
  )

  const validateRequired = useCallback(
    (value: string | undefined) => (props.required && !value ? i18n.t('alink__error__required') : undefined),
    [props.required]
  )

  switch (props.fieldSchema.type) {
    case ControlTypes.Location:
      return (
        <LocationField
          {...props}
          inputClass={classes.input}
          inputGroupClass={classes.inputGroup}
          labelClass={classes.inputLabel}
          noPadding={classes.noPadding}
          validate={composeValidators(validateSystemException)}
        />
      )
    case ControlTypes.TimePeriodMonthYearWithCurrent:
      return (
        <TimePeriodField
          {...props}
          views={['year', 'month']}
          checkboxClass={classes.checkbox}
          formControlLabelClass={classes.formControlLabel}
          formControlRootClass={classes.formControlRoot}
          isCurrent={true}
          inputClass={classes.input}
          inputGroupClass={classes.inputGroup}
          labelClass={classes.inputLabel}
        />
      )
    case ControlTypes.TimePeriodMonthYear:
      return (
        <TimePeriodField
          {...props}
          views={['year', 'month']}
          checkboxClass={classes.checkbox}
          formControlLabelClass={classes.formControlLabel}
          formControlRootClass={classes.formControlRoot}
          isCurrent={false}
          inputClass={classes.input}
          inputGroupClass={classes.inputGroup}
          labelClass={classes.inputLabel}
        />
      )
    case ControlTypes.TimePeriodYear:
      return (
        <TimePeriodField
          {...props}
          views={['year']}
          checkboxClass={classes.checkbox}
          formControlLabelClass={classes.formControlLabel}
          formControlRootClass={classes.formControlRoot}
          isCurrent={false}
          inputClass={classes.input}
          inputGroupClass={classes.inputGroup}
          labelClass={classes.inputLabel}
        />
      )
    case ControlTypes.FieldsOfStudy:
      return (
        <FieldsOfStudyField
          {...props}
          inputClass={classes.input}
          inputGroupClass={classes.inputGroup}
          labelClass={classes.inputLabel}
          noPadding={classes.noPadding}
        />
      )
    case ControlTypes.TeamMembers:
      return (
        <TeamMembersField
          {...props}
          inputClass={classes.input}
          inputGroupClass={classes.inputGroup}
          labelClass={classes.inputLabel}
          noPadding={classes.noPadding}
        />
      )
    case ControlTypes.Date:
      return (
        <DateDropdown
          label={i18n.t(`alink__${props.section}__${props.fieldSchema.name}`)}
          inputClass={classes.input}
          inputGroupClass={classes.inputGroup}
          outerName={`${props.outerName}.${props.fieldSchema.name}`}
          readonly={props.readOnly}
          labelClass={classes.inputLabel}
          views={['year', 'month', 'day']}
        />
      )

    case ControlTypes.RichText:
      return (
        <RichTextField
          {...props}
          labelClass={classes.inputLabel}
          inputClass={classes.input}
          inputGroupClass={classes.inputGroup}
          validate={composeValidators(validateRequired, validateMaxLength, validateSystemException)}
        />
      )
    case ControlTypes.Text:
      return (
        <Field
          validate={composeValidators(validateRequired, validateMaxLength, validateSystemException)}
          name={`${props.outerName}.${props.fieldSchema.name}`}
          key={`${props.outerName}.${props.fieldSchema.name}`}
        >
          {(renderProps: FieldRenderProps<string>) => renderPrimitiveControl(renderProps, props, classes)}
        </Field>
      )
    case ControlTypes.Checkbox: {
      return (
        <Field
          name={`${props.outerName}.${props.fieldSchema.name}`}
          key={`${props.outerName}.${props.fieldSchema.name}`}
          required={props.required}
          type={'checkbox'}
        >
          {(renderProps: FieldRenderProps<boolean>) => renderPrimitiveControl(renderProps, props, classes)}
        </Field>
      )
    }
    default:
      return null
  }
}

const memoizedALIFormField = React.memo(ALIFormField, isEqual)
// @ts-ignore
memoizedALIFormField.whyDidYouRender = true
export default memoizedALIFormField
