import React from 'react'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import i18n from '../../i18n'
import { DashboardVariants } from '.'

interface Props {
  name: string
  variant: DashboardVariants
}

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      fontSize: '2em',
      fontWeight: 'normal',
      textAlign: 'center',
      paddingTop: '3.3rem',
    },
  })
)

const Greeting: React.FC<Props> = ({ name, variant }) => {
  const classes = useStyles()
  const hour = new Date().getHours()
  let timeOfDay = i18n.t('dashboard__greeting__timeOfDay__evening')

  if (hour >= 5 && hour < 12) {
    timeOfDay = i18n.t('dashboard__greeting__timeOfDay__morning')
  } else if (hour >= 12 && hour < 17) {
    timeOfDay = i18n.t('dashboard__greeting__timeOfDay__afternoon')
  }

  return (
    <Typography component="h1" variant="h1" className={classes.header}>
      {i18n.t(`dashboard__greeting__${variant}`, { name, timeOfDay })}
    </Typography>
  )
}

const memoized = React.memo(Greeting)
// @ts-ignore
memoized.whyDidYouRender = true

export default memoized
