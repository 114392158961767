import React, { FC, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'

import i18n from '../../i18n'
import MultipleCheck, { MultipleCheckValue } from '../common/MultipleCheck'
import SingleSelect, { SingleSelectValue } from '../common/SingleSelect'
import IndustriesRank, { IndustriesRankValue } from './IndustriesRank'
import Button from '../common/Button'
import { AssignmentPreferences, UserAvailability } from '../../store/user_preferences/reducers'
import { HashMap } from '../../store/items/types'
import useUserInfoState from '../common/useUserInfo'
import useFeatureFlags from '../common/useFeatureFlags'
import styles from '../../styles'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export interface PreferencesProps {
  preferences: AssignmentPreferences
  onPreferencesUpdate: (oldData: AssignmentPreferences, newData: AssignmentPreferences) => Promise<void>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    section: {
      '&:not(:first-child)': {
        marginTop: '3.0625rem',
      },
    },
    title: {
      fontWeight: 600,
    },
    subtitle: {
      marginTop: '1.25rem',
    },
    selectContainer: {
      marginTop: '1.1875rem',
      '&.availability': { width: '100%', maxWidth: '45rem' },
      '&.speed': { width: '100%', maxWidth: '20rem' },
      '&.industries': { width: '100%', maxWidth: '45rem' },
    },
    capacitySelect: {
      width: '100%',
      maxWidth: '21rem',
      height: '2.1125rem',
      '@media (max-width: 540px)': {
        width: '15rem',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderSize: '1px',
        borderColor: styles.palette.select.border,
      },
      '& .MuiSelect-select': {
        padding: '0 2rem 0 1.15625rem',
        lineHeight: '2.1125rem',
        color: styles.palette.select.textColor,
        fontSize: '.84375rem',
        fontWeight: 600,
      },
      '& .MuiSelect-icon': {
        top: 'calc(50% - .65625rem)',
        right: '.625rem',
        height: '1.3125rem',
      },
    },
    help: {
      marginTop: '2.8125rem',
      fontSize: '.875rem',
    },
    submit: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '2.8125rem',
    },
    sendingSpinner: {
      marginLeft: theme.spacing(3),
    },
  })
)

const Preferences: FC<PreferencesProps> = ({ preferences, onPreferencesUpdate }) => {
  const classes = useStyles()
  const maxCapacityValue = 30

  const { availability, prefer_rush, industry_preferences, assignments_per_week, prefer_phone_calls } = preferences

  const userInfo = useUserInfoState()
  const { showScheduler } = useFeatureFlags()

  const uglifiedAvailability: HashMap<boolean> = {}
  availability.forEach(a => {
    uglifiedAvailability[a.day_of_week] = a.enabled
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [capacityValue, setCapacityValue] = useState(assignments_per_week)
  const [availabilityValue, setAvailabilityValue] = useState<MultipleCheckValue>(uglifiedAvailability)
  const [speedValue, setSpeedValue] = useState<SingleSelectValue>(prefer_rush ? 'rush' : 'standard')
  const [industriesValue, setIndustriesValue] = useState<IndustriesRankValue>(industry_preferences)
  const [phoneCallsValue, setPhoneCallsValue] = useState<boolean>(prefer_phone_calls)

  const availabilityData = availability.map(d => ({
    label: i18n.t(`onboarding__days__day${d.day_of_week}`),
    index: d.day_of_week,
  }))

  const speedData = [
    {
      text: i18n.t('order__shopping__preferences__speed__standard'),
      value: 'standard',
    },
    {
      text: i18n.t('order__shopping__preferences__speed__rush'),
      value: 'rush',
    },
  ]

  const handleCapacityChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setCapacityValue(Number(event.target.value))
  }

  const handleAvailabilityChange = (value: MultipleCheckValue) => {
    setAvailabilityValue(value)
  }

  const handleSpeedChange = (value: SingleSelectValue) => {
    setSpeedValue(value)
  }

  const handleIndustriesChange = (value: IndustriesRankValue) => {
    setIndustriesValue(value)
  }

  const handleSubmitClick = async () => {
    setIsSubmitting(true)
    const availability: UserAvailability[] = !!availabilityValue
      ? Object.values(availabilityValue).map((value: boolean, index: number) => {
          return { day_of_week: index + 1, enabled: value }
        })
      : []

    const updatedPreferences = {
      industry_preferences: industriesValue as number[],
      availability: availability,
      prefer_rush: speedValue === 'rush',
      assignments_per_week: capacityValue,
      prefer_phone_calls: phoneCallsValue,
    }
    await onPreferencesUpdate(preferences, updatedPreferences)
    setIsSubmitting(false)
  }

  return (
    <Box className={classes.root}>
      {!userInfo.isNewHire && (
        <Box className={classes.section}>
          <Typography display="block" variant={'h1'} className={classes.title}>
            {i18n.t('order__shopping__preferences__capacity__title')}
          </Typography>
          <Typography display="block" variant={'body2'} className={classes.subtitle}>
            {i18n.t('order__shopping__preferences__capacity__desc')}
          </Typography>
          <Box className={classes.selectContainer}>
            <Select
              className={classes.capacitySelect}
              value={capacityValue}
              onChange={handleCapacityChange}
              input={<OutlinedInput labelWidth={0} />}
            >
              {[...Array(maxCapacityValue + 1)].map((_, i) => (
                <MenuItem value={i} key={i}>
                  {i18n.t(
                    i === 0
                      ? 'order__shopping__preferences__capacity__orders_plural_0'
                      : 'order__shopping__preferences__capacity__orders',
                    { count: i }
                  )}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      )}
      <Box className={classes.section}>
        <Typography display="block" variant={'h1'} className={classes.title}>
          {i18n.t('order__shopping__preferences__availability__title')}
        </Typography>
        <Typography display="block" variant={'body2'} className={classes.subtitle}>
          {i18n.t('order__shopping__preferences__availability__desc')}
        </Typography>
        <Box className={`${classes.selectContainer} availability`}>
          <MultipleCheck
            size="small"
            data={availabilityData}
            value={availabilityValue}
            handleChange={value => handleAvailabilityChange(value)}
          />
        </Box>
      </Box>
      <Box className={classes.section}>
        <Typography display="block" variant={'h1'} className={classes.title}>
          {i18n.t('order__shopping__preferences__speed__title')}
        </Typography>
        <Typography display="block" variant={'body2'} className={classes.subtitle}>
          {i18n.t('order__shopping__preferences__speed__desc')}
        </Typography>
        <Box className={`${classes.selectContainer} speed`}>
          <SingleSelect
            size="small"
            allowEmpty={false}
            data={speedData}
            value={speedValue}
            handleChange={value => handleSpeedChange(value)}
          />
        </Box>
      </Box>
      {showScheduler && (
        <Box className={classes.section}>
          <Typography display="block" variant={'h1'} className={classes.title}>
            {i18n.t('order__shopping__preferences__phone__title')}
          </Typography>
          <FormControlLabel
            label={<Typography variant={'body2'}>{i18n.t('order__shopping__preferences__phone__desc')}</Typography>}
            control={
              <Switch
                checked={phoneCallsValue}
                color="primary"
                onChange={newValue => setPhoneCallsValue(newValue.target.checked)}
                inputProps={{ 'aria-label': 'phone calls availability toggle' }}
              />
            }
          />
        </Box>
      )}
      <Box className={classes.section}>
        <Typography display="block" variant={'h1'} className={classes.title}>
          {i18n.t('order__shopping__preferences__industries__title')}
        </Typography>
        <Typography display="block" variant={'body2'} className={classes.subtitle}>
          {i18n.t('order__shopping__preferences__industries__desc')}
        </Typography>
        {!!industriesValue && (
          <Box className={`${classes.selectContainer} industries`}>
            <IndustriesRank data={industriesValue} handleChange={value => handleIndustriesChange(value)} />
          </Box>
        )}
      </Box>
      <Typography display="block" variant={'body2'} className={classes.help}>
        {i18n.t('order__shopping__preferences__help')}
      </Typography>
      <Box className={classes.submit}>
        <Button type="primary" onClick={handleSubmitClick} showLoader={isSubmitting}>
          {i18n.t('order__shopping__preferences__submit')}
        </Button>
      </Box>
    </Box>
  )
}

export default Preferences
