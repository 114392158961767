import pick from 'lodash/pick'

import { DocsMetaList, Document, DocumentState, DocumentTypes } from '../store/documents/types'
import { Message, MessageContext, MessageState } from '../store/messages/types'
import { HashMap, OrderItem, OrderItemState, OrderItemStates } from '../store/items/types'
import { createSelector } from 'reselect'
import { filter, groupBy, map, some } from 'lodash'
import { ClientsState } from '../store/clients/reducer'
import { filterFulfillableItems } from './items'

function sortDocuments(docs: Document[]): Document[] {
  return docs.sort((a: Document, b: Document) => {
    const left = new Date(b.updated_at).valueOf()
    const right = new Date(a.updated_at).valueOf()
    const diff = left - right
    return diff === 0 ? new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf() : diff
  })
}

export const getDocumentUploadsByClient = (
  state: DocumentState,
  messageState: MessageState,
  clientId: number
): Document[] => {
  try {
    const messageIDsForClient = Object.keys(messageState.client[clientId])
    const documentsFromMessages = Object.entries(state.message)
      .filter(([messageID]: [string, DocsMetaList]) => messageIDsForClient.includes(messageID))
      .flatMap(([, docsList]: [string, DocsMetaList]) => Object.values(docsList.docs)) as Document[]

    const docs = [
      ...Object.values(state.client[clientId].docs).filter((d: Document) => d.type === DocumentTypes.Customer),
      ...documentsFromMessages,
    ]
    return sortDocuments(docs)
  } catch (e) {
    return []
  }
}

export const getDocumentUploadsForClient = (state: DocumentState, clientId: number): Document[] => {
  try {
    const docs = Object.values(state.client[clientId].docs).filter((d: Document) => d.type !== DocumentTypes.Customer)
    return sortDocuments(docs)
  } catch (e) {
    return []
  }
}

export const getDocumentUploadsForOpenOrders = (
  state: DocumentState,
  clientState: ClientsState,
  itemState: OrderItemState,
  clientId: number
): Document[] => {
  try {
    // get order ids
    const orderIDs = clientState.clients[clientId].order_ids
    // get all items for order ids
    let items: OrderItem[] = orderIDs.map(x => Object.values(itemState.itemsByOrderId[x])).flat()
    // okay, now for each item, if the states indicate it's closeable, get the dockey
    items = filterFulfillableItems(items)
    items = filter(items, (item: OrderItem) =>
      some(
        item.status.active,
        status =>
          status.state === OrderItemStates.Revisions && status.due_at != null && new Date(status.due_at) <= new Date()
      )
    )

    const docKeys = items.map(x =>
      x.compound_item_key.includes('|') ? x.compound_item_key.split('|').pop() : x.compound_item_key
    )

    const docs = Object.values(state.client[clientId].docs).filter(
      (d: Document) =>
        (d.type !== DocumentTypes.Customer && docKeys.includes(d.type)) ||
        (d.type === DocumentTypes.Linkedin && docKeys.includes('linkedin-document'))
    )

    return sortDocuments(docs)
  } catch (e) {
    return []
  }
}

export const getDocumentUploadsForClosedOrders = (
  state: DocumentState,
  clientState: ClientsState,
  itemState: OrderItemState,
  clientId: number
): Document[] => {
  try {
    // get order ids
    const orderIDs = clientState.clients[clientId].order_ids
    // get all items for order ids
    let items: OrderItem[] = orderIDs.map(x => Object.values(itemState.itemsByOrderId[x])).flat()

    items = filterFulfillableItems(items)
    items = filter(items, (item: OrderItem) =>
      some(
        item.status.completed,
        status =>
          status.state === OrderItemStates.Revisions && status.due_at != null && new Date(status.due_at) <= new Date()
      )
    )

    const docKeys = items.map(x =>
      x.compound_item_key.includes('|') ? x.compound_item_key.split('|').pop() : x.compound_item_key
    )

    const docs = Object.values(state.client[clientId].docs).filter(
      (d: Document) =>
        (d.type !== DocumentTypes.Customer && docKeys.includes(d.type)) ||
        (d.type === DocumentTypes.Linkedin && docKeys.includes('linkedin-document'))
    )

    return sortDocuments(docs)
  } catch (e) {
    return []
  }
}

export const getDocumentUploadsForEditors = (state: DocumentState, clientId: number): Document[] => {
  try {
    const docs = Object.values(state['editor-client'][clientId].docs)
    return sortDocuments(docs)
  } catch (e) {
    return []
  }
}

export const getLatestDocumentsForOpenOrders = createSelector(getDocumentUploadsForOpenOrders, docs => {
  const groups = groupBy<Document>(docs, d => d.type)
  const latestDocs = map(groups, (values: Document[]) => {
    values.sort((d1, d2) => {
      return d1.created_at < d2.created_at ? 1 : -1
    })
    return values[0]
  })
  return latestDocs.flat()
})

export const getLatestDocumentsForClosedOrders = createSelector(getDocumentUploadsForClosedOrders, docs => {
  const groups = groupBy<Document>(docs, d => d.type)
  const latestDocs = map(groups, (values: Document[]) => {
    values.sort((d1, d2) => {
      return d1.created_at < d2.created_at ? 1 : -1
    })
    return values[0]
  })
  return latestDocs.flat()
})

export const getAttachmentsForMessages = (
  state: DocumentState,
  context: MessageContext,
  contextID: number,
  messages: Message[]
): HashMap<Document[]> => {
  const documents: HashMap<Document[]> = {}

  return messages
    .filter(message => message.attachment_ids.length > 0)
    .reduce((result: HashMap<Document[]>, message: Message) => {
      try {
        result[message.id] = Object.values({
          ...pick(state[context][contextID]?.docs, message.attachment_ids),
          ...pick(state.message[message.id]?.docs, message.attachment_ids),
        })
      } catch (e) {
        result[message.id] = []
      }
      return result
    }, documents)
}

export const getAttachmentsForCurrentMessage = (
  state: DocumentState,
  context: MessageContext,
  contextID: number,
  attachmentIDs: number[]
): Document[] => {
  try {
    return Object.values(pick(state[context][contextID].docs, attachmentIDs))
  } catch (e) {
    return []
  }
}

export const clientDocumentsNeedFetching = (state: DocumentState, clientID: number | null) =>
  !!clientID &&
  (!state.client[clientID] ||
    (!!state.client[clientID] && !state.client[clientID].meta.loaded && !state.client[clientID].meta.isLoading))

export const getDocumentByID = (state: DocumentState, documentID: number): Document | null =>
  Object.values({ ...state.client, ...state.message, ...state.user })
    .flatMap(v => Object.values(v.docs))
    .find(d => d.id === documentID) || null

export const userHasClientReadyDocs = (state: DocumentState, userID: number, clientID: number): boolean => {
  try {
    return !!Object.values(state.client[clientID].docs).find(d => d.user_id === userID)
  } catch (e) {
    return false
  }
}

export const clientHasClientReadyDocs = (state: DocumentState, clientID: number): boolean => {
  try {
    return Object.values(state.client[clientID].docs).length > 0
  } catch (e) {
    return false
  }
}

export const getClientMainDocs = (state: DocumentState, clientID: number): Document[] => {
  try {
    return Object.values(state.client[clientID].docs)
      .filter(d => d.type === DocumentTypes.Resume || d.type === DocumentTypes.CV)
      .sort((res1: Document, res2: Document) => (res1.created_at > res2.created_at ? -1 : 1))
  } catch (e) {
    return []
  }
}
