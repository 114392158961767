import { AnyAction } from 'redux'
import { AppState } from '..'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { IntervalIDs } from './types'
import { getUserNotificationsTimed } from '../notifications/actions'
import { updateConfigTimed, FetchConfigSuccess, UpdateConfigSuccess } from '../config/actions'
import { refreshAuth0TokenTimed } from '../../utils/auth0'
import { getUserEventsTimed, getLatestClientMessagesTimed } from '../events/actions'

export enum ResettableCrons {
  ClientMessages = 'clientMessages',
}

export enum CronActionTypes {
  SET_INTERVAL_IDS = 'SET_INTERVAL_IDS',
  CLEAR_INTERVAL_IDS = 'CLEAR_INTERVAL_IDS',
}

interface SetCronIntervalIDs {
  type: CronActionTypes.SET_INTERVAL_IDS
  intervals: Partial<IntervalIDs>
}

export function setCronIntervalIDs(intervals: Partial<IntervalIDs>): SetCronIntervalIDs {
  return {
    type: CronActionTypes.SET_INTERVAL_IDS,
    intervals,
  }
}

export function initializeCrons(resumeFromSuspend = false): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState): Promise<void> {
    const interval = 1000 * 60 * 5
    const intervals: Partial<IntervalIDs> = {
      notifications: await dispatch(getUserNotificationsTimed(interval)),
      config: await dispatch(updateConfigTimed(1000 * 60, resumeFromSuspend)),
      events: await dispatch(getUserEventsTimed(1000 * 60 * 10)),
      clientMessages: await dispatch(getLatestClientMessagesTimed(1000 * 60 * 2)),
    }

    // This is to hide new order nudges, we can uncomment below code to enable new order nudges whenever needed

    // const { showNewOrderNudges } = getFeatureFlags(getState().userReducer.getLoggedInUser())

    // if (showNewOrderNudges) {
    //   intervals.nudges = await dispatch(getNewOrdersNudge(60 * 1000))
    // }

    if (!getState().cronReducer.intervals.auth0) {
      intervals.auth0 = await refreshAuth0TokenTimed(1000 * 60 * 60 * 60)
    }

    dispatch(setCronIntervalIDs(intervals))
  }
}

interface ClearCronIntervalIDs {
  type: CronActionTypes.CLEAR_INTERVAL_IDS
  intervals: Partial<IntervalIDs>
}

export function clearCronIntervalIDs(intervals: Partial<IntervalIDs>): ClearCronIntervalIDs {
  return {
    type: CronActionTypes.CLEAR_INTERVAL_IDS,
    intervals,
  }
}

export function suspendCrons(): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState): Promise<void> {
    const intervalsToClear = Object.entries(getState().cronReducer.intervals)
      .filter(([key, id]) => key !== 'auth0' && !!id)
      .reduce((acc, [key, interval]) => {
        // @ts-ignore
        acc[key] = interval
        return acc
      }, {} as Partial<IntervalIDs>)

    // Clear all the intervals before nulling them out
    Object.values(intervalsToClear).forEach(internal => !!internal && clearInterval(internal.intervalID))

    for (const key in intervalsToClear) {
      // @ts-ignore
      intervalsToClear[key] = null
    }

    dispatch(clearCronIntervalIDs(intervalsToClear))
  }
}

export function resetCron(intervalKey: ResettableCrons): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState): Promise<void> {
    const intervalToReset = Object.entries(getState().cronReducer.intervals).find(([key]) => key === intervalKey)

    if (!intervalToReset || intervalToReset.length < 2) {
      return
    }

    let newInterval

    const interval = intervalToReset[1]
    clearInterval(interval.intervalID)
    switch (intervalKey) {
      case ResettableCrons.ClientMessages:
        newInterval = await dispatch(getLatestClientMessagesTimed(interval.interval))
        break
    }

    if (!!newInterval) {
      dispatch(
        setCronIntervalIDs({
          ...getState().cronReducer.intervals,
          [intervalKey]: newInterval,
        })
      )
    }
  }
}

export type CronActions = SetCronIntervalIDs | ClearCronIntervalIDs | FetchConfigSuccess | UpdateConfigSuccess
