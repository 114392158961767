import React, { useCallback, useState } from 'react'
import FullScreenDialog from '../UploadsV3/FullScreenDialog'
import DocumentSelection from './DocumentSelection'
import { generateAutoLinkedin, publishLinkedinRevisionThunk, startFromRevision } from '../../store/autolinkedin/actions'
import { useDispatch, useSelector } from 'react-redux'
import UploadsContainer from '../UploadsV3/UploadsContainer'
import { UploadGoal } from '../../store/documents/types'
import { ClientOrdersDisplayAggregate } from '../../selectors/clientOrderAggregates'
import { AppState } from '../../store'
import { AutoLinkedinJson, AutoLinkedinRevision, Summary } from '../../store/autolinkedin/types'
import { getAutoLinkedinSummary, getAutoLinkRevisions } from '../../store/autolinkedin/selectors'
import RevisionSelection from './RevisionSelection'
import LinkedInPreviewWrapper from './LinkedInPreviewWrapper'
import YesNoDialog from './YesNoDialog'
import i18n from '../../i18n'
import PublishedConfirmation from './PublishedConfirmation'
import { addAttachmentsToMessage } from '../../store/currentMessage/actions'
import { OrderItem, OrderItemStates } from '../../store/items/types'
import { applyTemplateBasedUponOrderCTA } from '../../store/templates/actions'
import useUserInfoState from '../common/useUserInfo'
import { getItemByIDSelector } from '../../selectors/items'
import { useHistory } from 'react-router'
import EditableFormWrapper from './EditableFormWrapper'
import ReadOnlyFormWrapper from './ReadOnlyFormWrapper'

enum AutoLinkedinSteps {
  PickResume = 'PickResume',
  PickRevision = 'PickRevision',
  UploadResume = 'UploadResume',
  EditSections = 'EditSections',
  ValidateSections = 'ValidateSections',
  ComposeSummary = 'ComposeSummary',
  Preview = 'Preview',
  Success = 'Success',
}

export interface AutoLinkedinContainerProps {
  handleCloseModal: () => void
  linkedinItemID: number
  clientID: number
  autoLinkedinID?: number | null
  currentOrderGroup: ClientOrdersDisplayAggregate
}

const AutoLinkedinContainer: React.FC<AutoLinkedinContainerProps> = ({
  clientID,
  linkedinItemID,
  autoLinkedinID,
  handleCloseModal,
  currentOrderGroup,
}) => {
  const dispatch = useDispatch()

  const [resetDialogVisible, setResetDialogVisible] = React.useState(false)
  const [abandonDialogVisible, setAbandonDialogVisible] = React.useState(false)

  const { id, isAdmin, isEditor } = useUserInfoState()

  const revisions = useSelector<AppState, AutoLinkedinRevision[]>(state =>
    getAutoLinkRevisions(state.autolinkedinReducer, autoLinkedinID)
  )

  const summary = useSelector<AppState, Summary | undefined | null>(state =>
    getAutoLinkedinSummary(state.autolinkedinReducer, linkedinItemID)
  )

  const autoLinkedinItem = useSelector<AppState, OrderItem | undefined>(state =>
    getItemByIDSelector(state.itemsReducer, linkedinItemID)
  )

  const history = useHistory()

  const [currentStep, setCurrentStep] = useState<AutoLinkedinSteps>(
    (function() {
      if (!autoLinkedinID) {
        return AutoLinkedinSteps.PickResume
      }
      if (revisions.length > 0) {
        return AutoLinkedinSteps.PickRevision
      }
      if (!!summary && summary?.text && summary.text !== '') {
        return AutoLinkedinSteps.ComposeSummary
      }
      return AutoLinkedinSteps.EditSections
    })()
  )

  const handleUploadResume = useCallback(() => setCurrentStep(AutoLinkedinSteps.UploadResume), [])

  const handleGenerateAutoLinkedin = useCallback(
    async (baseDocId: number | null) => {
      await dispatch(generateAutoLinkedin(linkedinItemID, baseDocId))
      setCurrentStep(AutoLinkedinSteps.EditSections)
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [linkedinItemID]
  )

  const handleStartFromRevision = useCallback(
    async (revisionID: number) => {
      await dispatch(startFromRevision(linkedinItemID, revisionID))
      setCurrentStep(AutoLinkedinSteps.EditSections)
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [linkedinItemID]
  )

  const handleContinueFromAutosave = useCallback((autoLinkedinJson: AutoLinkedinJson) => {
    if (
      (!!autoLinkedinJson.headline && autoLinkedinJson.headline?.length > 0) ||
      (!!autoLinkedinJson.summary?.text && autoLinkedinJson.summary?.text.length > 0)
    ) {
      setCurrentStep(AutoLinkedinSteps.ComposeSummary)
    } else {
      setCurrentStep(AutoLinkedinSteps.EditSections)
    }
  }, [])

  const handleToggleReset = useCallback(() => {
    setResetDialogVisible(visible => !visible)
  }, [setResetDialogVisible])

  const handleResetRevision = useCallback(() => {
    setResetDialogVisible(visible => !visible)
    setCurrentStep(AutoLinkedinSteps.PickResume)
  }, [setResetDialogVisible])

  const handleContinueToComposeSummary = useCallback(() => {
    setCurrentStep(AutoLinkedinSteps.ComposeSummary)
    const header = document.getElementById('header')
    if (!!header) {
      header.scrollIntoView()
    }
  }, [])

  const handleContinueToPreview = useCallback(() => {
    setCurrentStep(AutoLinkedinSteps.Preview)
    const header = document.getElementById('header')
    if (!!header) {
      header.scrollIntoView()
    }
  }, [])

  const handlePublish = useCallback(
    async (autoLinkID: number | undefined) => {
      if (!autoLinkID) {
        return
      }

      await dispatch(publishLinkedinRevisionThunk(linkedinItemID, autoLinkID, clientID))
      setCurrentStep(AutoLinkedinSteps.Success)
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [autoLinkedinID, clientID, linkedinItemID]
  )

  const handleProceedToAttach = useCallback(
    async (fileID: number) => {
      if (!!autoLinkedinItem && !!autoLinkedinItem?.order_id) {
        await dispatch(
          applyTemplateBasedUponOrderCTA(id, clientID, autoLinkedinItem?.order_id, OrderItemStates.SendLinkedin)
        )
      }

      await dispatch(addAttachmentsToMessage([fileID]))

      handleCloseModal()

      history.push({
        pathname: `/client/${clientID}/messages`,
        search: window.location.search,
      })
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [autoLinkedinItem, clientID, handleCloseModal, history, id]
  )

  return (
    <FullScreenDialog id="li-top">
      {currentStep === AutoLinkedinSteps.PickResume && (
        <DocumentSelection
          clientID={clientID}
          handleCancel={handleCloseModal}
          handleDocumentSelected={(fileID: number) => handleGenerateAutoLinkedin(fileID)}
          handleStartManually={() => handleGenerateAutoLinkedin(null)}
          handleUploadResume={handleUploadResume}
          linkedinItemID={linkedinItemID}
        />
      )}
      {currentStep === AutoLinkedinSteps.PickRevision && !!autoLinkedinID && (
        <RevisionSelection
          autoLinkedinID={autoLinkedinID}
          handleCancel={handleCloseModal}
          handleContinueFromAutosave={handleContinueFromAutosave}
          handleStartFromRevision={(revisionID: number) => handleStartFromRevision(revisionID)}
          linkedinItemID={linkedinItemID}
        />
      )}
      {currentStep === AutoLinkedinSteps.EditSections && (
        <EditableFormWrapper
          handleClose={() => setAbandonDialogVisible(true)}
          // @ts-ignore
          handleNext={handleContinueToComposeSummary}
          handleBack={() => setAbandonDialogVisible(true)}
          handleReset={handleToggleReset}
          linkedinItemID={linkedinItemID}
          handleValidate={() => setCurrentStep(AutoLinkedinSteps.ValidateSections)}
          validationOn={false}
        />
      )}
      {currentStep === AutoLinkedinSteps.ValidateSections && (
        <EditableFormWrapper
          handleClose={() => setAbandonDialogVisible(true)}
          // @ts-ignore
          handleNext={handleContinueToComposeSummary}
          handleBack={() => setAbandonDialogVisible(true)}
          handleValidate={() => setCurrentStep(AutoLinkedinSteps.ValidateSections)}
          handleReset={handleToggleReset}
          linkedinItemID={linkedinItemID}
          validationOn={true}
        />
      )}
      {currentStep === AutoLinkedinSteps.ComposeSummary && (
        <ReadOnlyFormWrapper
          linkedinItemID={linkedinItemID}
          clientID={clientID}
          handleBack={() => setCurrentStep(AutoLinkedinSteps.EditSections)}
          handleClose={() => setAbandonDialogVisible(true)}
          handleNext={handleContinueToPreview}
          handleReset={handleToggleReset}
        />
      )}
      {currentStep === AutoLinkedinSteps.Preview && (
        <LinkedInPreviewWrapper
          handleClose={handleCloseModal}
          handleBack={() => setCurrentStep(AutoLinkedinSteps.ComposeSummary)}
          handleNext={handlePublish}
          linkedinItemID={linkedinItemID}
        />
      )}
      {currentStep === AutoLinkedinSteps.UploadResume && (
        <UploadsContainer
          goal={UploadGoal.AutoLinkedin}
          context={'client'}
          contextID={clientID}
          orderIDs={currentOrderGroup.orderIDs}
          closeWindow={handleCloseModal}
          isOnboardingMentee={false}
          cta={currentOrderGroup.macroState.CTA}
          handleAutoLinkedinFinalizeUpload={() => setCurrentStep(AutoLinkedinSteps.PickResume)}
          canSkipAQAFeedback={isAdmin || isEditor}
        />
      )}
      {currentStep === AutoLinkedinSteps.Success && revisions.length > 0 && (
        <PublishedConfirmation
          handleClose={handleCloseModal}
          handleProceedToAttach={handleProceedToAttach}
          fileID={revisions[0].file_id as number}
          clientID={clientID}
        />
      )}
      <YesNoDialog
        title={i18n.t('alink__RestartDialog__question')}
        isOpen={resetDialogVisible}
        handleYes={handleResetRevision}
        handleNo={() => setResetDialogVisible(false)}
      />
      <YesNoDialog
        title={i18n.t('alink__AbandonDialog__question')}
        body={i18n.t('alink__AbandonDialog__body')}
        isOpen={abandonDialogVisible}
        handleYes={handleCloseModal}
        handleNo={() => setAbandonDialogVisible(false)}
      />
    </FullScreenDialog>
  )
}

export default AutoLinkedinContainer
