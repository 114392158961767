import { HashMap, OrderItem } from '../items/types'
import { BasePay } from '../orders/types'
import { LoadedLoadingErrorState } from '../../utils/state'
import { Language } from '../../utils/consts'
import { AppliedDeliveryIncentive } from '../events/types'

export interface Offer {
  offer_id: number
  api_user_id: number
  assignment_match_id: number
  industry_id?: number
  offered_at: string
  updated_at: string
  expires_at: string
  responded_at: string
  auth_token: string
  user_id: number
  client_id: number
  offer_seconds: number
  language?: Language
  resume_images?: string[]
  products?: OrderItem[]
  is_request: boolean
  is_expired: boolean
  version: number
  region?: string
  status: OfferStatus
  writer_pay: BasePay
  order_ids: OrderIDDueDate[]
  incentives: {
    dynamic_pay: {
      dynamic_pay_items: AppliedDeliveryIncentive[]
    }
    potential_delivery_incentives: AppliedDeliveryIncentive[]
  }
  roles?: string[]
  test_order: 0 | 1
}

export interface OrderIDDueDate {
  order_id: number
  due_date: string
}

export interface OfferShoppingEligibility {
  offers_per_week: number
  offers_accepted_last_week: number
  eligibility_reason: EligibilityReasons
  eligible_for_offers: boolean
  offers_per_hour: number
  offers_rejected_last_hour: number
  works_in_progress: number
  wip_limit: number | null
  is_available_for_scheduled_items: boolean
}

export enum EligibilityReasons {
  Capacity = 'capacity',
  NewHireCapacity = 'new-hire-capacity',
  RejectionsExpirations = 'too many rejections/expirations',
  LowInventory = 'low-inventory',
  WipLimit = 'wip-limit',
  Suspended = 'suspended',
}

export interface GetUserOffersResponse {
  offers: Offer[]
  shopping_eligibility: OfferShoppingEligibility
}

export interface AcceptRejectFeedbackOfferResponse extends GetUserOffersResponse {
  success: boolean
}

export interface OfferState {
  offers: HashMap<Offer>
  currentOfferID: number | null
  meta: OffersLoadedLoadingErrorState
  shoppingEligibility: null | OfferShoppingEligibility
}

export interface OfferFeedback {
  feedback: string[]
}

export interface OffersLoadedLoadingErrorState {
  global: LoadedLoadingErrorState
  individual: HashMap<OfferLoadingState>
}

export interface OfferLoadingState {
  accept: LoadedLoadingErrorState
  reject: LoadedLoadingErrorState
  submitFeedback: LoadedLoadingErrorState
}

export enum OfferStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
  Offered = 'offered',
  Expired = 'expired',
}
