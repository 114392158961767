import React, { useState } from 'react'
import ModalWrapper from '../common/ModalWrapper'
import i18n from '../../i18n'
import Header from '../common/Header'
import Footer from '../common/Footer'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import useUserInfoState from '../common/useUserInfo'
import { getClientMainDocs } from '../../selectors/documents'
import { Document } from '../../store/documents/types'
import { clientByIdSelector } from '../../selectors/clients'
import { Client } from '../../store/clients/reducer'
import { Box } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '../common/Button'
import Typography from '@material-ui/core/Typography'
import styles from '../../styles'
import { formatDate } from '../../utils/formatting'
import RadioGroup from '@material-ui/core/RadioGroup'
import CustomRadio from '../common/CustomRadio'
import { LoadedLoadingErrorState } from '../../utils/state'
import { getAutoLinkedinGenerateMeta } from '../../store/autolinkedin/selectors'
import NoResumesIcon from '../../../src/assets/svgs/no-resumes-icon.svg'
import classNames from 'classnames'
import WarningIcon from '@material-ui/icons/Warning'

export interface DocumentSelectionProps {
  handleDocumentSelected: (fileID: number) => void
  handleUploadResume: () => void
  handleStartManually: () => void
  handleCancel: () => void
  clientID: number
  linkedinItemID: number
}

const useStyles = makeStyles(() =>
  createStyles({
    cancelButton: {
      marginRight: '1.2rem',
      padding: '.5rem 1.547rem',
    },
    submitButton: {
      padding: '.5rem 1.547rem',
    },
    documentsContainer: {
      paddingTop: '3rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    document: {
      border: `1px solid ${styles.palette.darkerBlue.color.hex}`,
      width: '90%',
      color: styles.palette.darkerBlue.color.hex,
      borderRadius: '10px',
      padding: '.5rem',
      display: 'flex',
      margin: '.5rem',
    },
    selectedDocument: {
      backgroundColor: styles.palette.lightPurple.color,
    },
    documentInfo: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      paddingTop: '9px',
    },
    documentCreatedTime: {
      color: styles.palette.lightGrey.color,
    },
    planB: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      paddingTop: '2rem',
      '& > *': {
        padding: '.5rem 0 0 0',
      },
    },
    planBText: {
      color: styles.palette.smokeBlue.color.hex,
      fontSize: '0.9rem',
      cursor: 'pointer',
    },
    getDocNudgeText: {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: styles.palette.smokeBlue.color.hex,
    },
    getDocNudgeBlock: {
      width: '43%',
      paddingTop: '3rem',
    },
    errorBox: {
      justifyContent: 'center',
      display: 'flex',
      width: '100%',
      margin: 'auto',
      color: styles.palette.pink.hex,
      '& > svg': {
        verticalAlign: 'text-bottom',
        marginRight: '.5rem',
      },
    },
    errorBoxText: {
      fontSize: '.95rem',
    },
  })
)

const DocumentSelection: React.FC<DocumentSelectionProps> = ({
  handleCancel,
  handleDocumentSelected,
  handleUploadResume,
  handleStartManually,
  clientID,
  linkedinItemID,
}) => {
  const classes = useStyles()

  const resumes = useSelector<AppState, Document[]>(state => getClientMainDocs(state.documentReducer, clientID))

  const generateMeta = useSelector<AppState, LoadedLoadingErrorState | undefined>(state =>
    getAutoLinkedinGenerateMeta(state.autolinkedinReducer, linkedinItemID)
  )

  const client = useSelector<AppState, Client>(state => clientByIdSelector(state.clientReducer, clientID))

  const [selectedResumeDocID, setSelectedResumeDocID] = useState<string | undefined>(
    resumes.length > 0 ? resumes[0].id.toString() : undefined
  )

  const { timeZone, locale } = useUserInfoState()

  return (
    <ModalWrapper>
      <Header
        activeStep={0}
        closeButton={true}
        handleClose={handleCancel}
        title={i18n.t('alink__PickResume__title', { clientName: client.full_name })}
        transparent={true}
      />

      {resumes.length === 0 && (
        <Box className={classes.planB}>
          <img src={NoResumesIcon} alt="" />
          <Box className={classes.getDocNudgeBlock}>
            <Typography className={classes.getDocNudgeText} variant={'body2'}>
              {i18n.t('alink__PickResume__getDoc')}
            </Typography>
          </Box>
        </Box>
      )}

      {resumes.length > 0 && (
        <RadioGroup
          className={classes.documentsContainer}
          aria-label="select-resume-to-parse"
          value={selectedResumeDocID}
          onChange={event => setSelectedResumeDocID(event.target.value)}
        >
          {resumes.map(doc => (
            <Box
              className={classNames({
                [classes.selectedDocument]: doc.id.toString() === selectedResumeDocID,
                [classes.document]: true,
              })}
            >
              <CustomRadio value={doc.id.toString()} id={`resume.${doc.id}`} />
              <Box className={classes.documentInfo}>
                <Typography title={doc.filename}>{doc.filename}</Typography>
                <Typography component="time" dateTime={doc.created_at} className={classes.documentCreatedTime}>
                  {formatDate(doc.created_at, timeZone, locale, i18n.t('documentItemV2__uploadedAtShortFormat'))}
                </Typography>
              </Box>
            </Box>
          ))}
        </RadioGroup>
      )}

      <Box className={classes.planB}>
        <Typography className={classes.planBText} variant={'body1'} onClick={handleUploadResume}>
          {i18n.t('alink__PickResume__upload')}
        </Typography>
        <Typography className={classes.planBText} variant={'body1'} onClick={handleStartManually}>
          {i18n.t('alink__PickResume__startManually')}
        </Typography>
      </Box>

      <Footer
        leftColumn={
          <Button type="secondary" className={classes.cancelButton} onClick={handleCancel} id="pick-resume-cancel">
            {i18n.t('alink__PickResume__cancel')}
          </Button>
        }
        rightColumn={
          <Button
            type="contrast"
            showLoader={generateMeta?.isLoading}
            disabled={!selectedResumeDocID || generateMeta?.isLoading}
            className={classes.submitButton}
            onClick={() => {
              if (!selectedResumeDocID) {
                return
              }
              handleDocumentSelected((selectedResumeDocID as unknown) as number)
            }}
            id="pick-resume-next"
          >
            {i18n.t('alink__PickResume__next')}
          </Button>
        }
      >
        {!!generateMeta?.error && (
          <Box className={classes.errorBox}>
            <WarningIcon />
            <Typography className={classes.errorBoxText} component="span">
              {i18n.t('alink__generateError')}
            </Typography>
          </Box>
        )}
      </Footer>
    </ModalWrapper>
  )
}

export default DocumentSelection
