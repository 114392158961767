import React from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {
  AppliedIncentive,
  OrderItemStates,
  OrderItemStatus,
  OrderItemTimeDisplayStates,
  OrderItemTypes,
} from '../../store/items/types'
import OrderItem from './OrderItem'
import { sortByDueDateAsc, sortByEndDateDesc } from '../../selectors/items'
import { BasePay } from '../../store/orders/types'
import { formatCurrency, formatDate } from '../../utils/formatting'
import i18n from '../../i18n'
import { HashMap } from '../../utils/HashMap'
import { some, groupBy } from 'lodash'
import useUserInfoState from '../common/useUserInfo'
import Typography from '@material-ui/core/Typography'

export interface GenericOrderItemDisplay {
  id: number
  order_id: number
  type: OrderItemTypes
  created_at: string
  compound_item_key: string
  name: string
  pay: BasePay[]
  incentives: AppliedIncentive[]
  meeting_id: number | null
  meeting_occurred: boolean
  meeting_scheduled_at: Date | null
  meeting_scheduled_for: Date | null
  meeting_started_at: Date | null
  meeting_ended_at: Date | null
  has_transcript: boolean
  has_audio: boolean
  has_video: boolean
}

export interface FulfillableOrderItemDisplay extends GenericOrderItemDisplay {
  state: OrderItemTimeDisplayStates
  itemState: OrderItemStates
  due_date: string | null
  history: {
    active: OrderItemStatus[]
    completed: OrderItemStatus[] | null
  }
}

export interface OrderItemHistoryEntry {
  type: string
  date: string | null
  isActive: boolean
}

interface OrderItemsProps {
  stackItems: boolean
  items: (GenericOrderItemDisplay | FulfillableOrderItemDisplay)[] | null
  shouldRenderBasePay: boolean
  shouldSeeInternalIncentiveNames: boolean
  shouldRenderHistory: boolean
  shouldApplyColors: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: '.1rem .25rem .1rem 0',
      },
    },
    addedLabel: {
      marginBottom: '.3rem',
      fontSize: '.7rem',
      fontColor: 'black',
      '@media (max-width: 1024px)': {
        display: 'none',
      },
    },
    stackRoot: {
      '@media (max-width: 1024px)': {
        display: 'flex',
      },
    },
  })
)

const TranslationsMap: HashMap<string> = {
  [OrderItemStates.SendIntro]: 'order_item__state__send_intro',
  [OrderItemStates.SendFirstDraft]: 'order_item__state__first_draft',
  [OrderItemStates.Revisions]: 'order_item__state__revision',
  [OrderItemStates.Closed]: 'order_item__state__closed',
  [OrderItemStates.UploadForEditor]: 'order_item__state__upload_editor',
  [OrderItemStates.AwaitingReply]: 'order_item__state__awaiting_reply',
  [OrderItemStates.AwaitingReview]: 'order_item__state__awaiting_review',
  [OrderItemStates.SendMessage]: 'order_item__state__send_message',
  [OrderItemStates.ConductCall]: 'order_item__state__call',
  [OrderItemStates.ConductInterview]: 'order_item__state__interview',
  [OrderItemStates.SendSummary]: 'order_item__state__send_summary',
  [OrderItemStates.SchedulePhoneCall]: 'order_item__state__schedule_phone_call',
  [OrderItemStates.ScheduleInterview]: 'order_item__state__schedule_interview',
  [OrderItemStates.Canceled]: 'order_item__state__canceled',
  [OrderItemStates.Refunded]: 'order_item__state__refunded',
  [OrderItemStates.AwaitingResume]: 'order_item__state__awaiting_resume',
  [OrderItemStates.Paid]: 'order_item__state__paid',
  [OrderItemStates.SendLinkedin]: 'order_item__state__send_linkedin',
  [OrderItemStates.Reassigned]: 'order_item__state__client_reassigned',
  [OrderItemStates.AwaitingScheduling]: 'order_item__state__awaiting_scheduling',
  [OrderItemStates.AwaitingConfirmation]: 'order_item__state__awaiting_confirmation',
}

const OrderItems: React.FC<OrderItemsProps> = ({
  items,
  stackItems,
  shouldRenderBasePay,
  shouldRenderHistory,
  shouldApplyColors,
  shouldSeeInternalIncentiveNames,
}) => {
  const classes = useStyles()

  const unfulfillableItems = !!items
    ? items.filter(item => !(!!item && (item as FulfillableOrderItemDisplay)).itemState)
    : []
  let fulfillableItems = !!items
    ? items.filter(item => !!(!!item && (item as FulfillableOrderItemDisplay)).itemState)
    : []

  fulfillableItems = fulfillableItems.sort((a, b) => (new Date(a.created_at) > new Date(b.created_at) ? 1 : -1))

  const groups = groupBy<GenericOrderItemDisplay | FulfillableOrderItemDisplay>(fulfillableItems, d => {
    const createdDate = new Date(d.created_at)
    return new Date(createdDate.getFullYear(), createdDate.getMonth(), createdDate.getDate()).toISOString()
  })

  const orderActiveStates = !!items
    ? items
        .filter((item): item is FulfillableOrderItemDisplay => !!item)
        .filter(i => !!i.history)
        .map(i => i.history.active)
        .filter(res => !!res)
        .flat()
    : []

  const orderIsLate = some(
    orderActiveStates,
    state =>
      (state.state === OrderItemStates.SendFirstDraft || state.state === OrderItemStates.SendIntro) &&
      !!state.due_at &&
      new Date(state.due_at) < new Date()
  )

  const makeItemPay = (item: GenericOrderItemDisplay) => {
    if (!shouldRenderBasePay || !item.pay) {
      return null
    }
    const sum = item.pay.map(x => x.amount).reduce((acc, y) => acc + y)
    return formatCurrency(sum, item.pay[0].currency)
  }

  const formatStatusNames = (status: OrderItemStatus, orderIsLate: boolean, itemType: OrderItemTypes) => {
    // LinkedIn display magic
    if (status.state === OrderItemStates.SendFirstDraft && itemType === OrderItemTypes.LinkedIn) {
      const sendLinkedInState = OrderItemStates.SendLinkedin
      if (!!status.ended_at) {
        return i18n.t(`${TranslationsMap[sendLinkedInState]}_done`)
      }
      if (!!status.due_at) {
        if (new Date(status.due_at) < new Date()) {
          return i18n.t(`${TranslationsMap[sendLinkedInState]}_late`)
        } else {
          return i18n.t(`${TranslationsMap[sendLinkedInState]}_due`)
        }
      }
      return TranslationsMap[sendLinkedInState]
    }
    if (status.state === OrderItemStates.AwaitingResume && orderIsLate) {
      return !!TranslationsMap[status.state] ? i18n.t(`${TranslationsMap[status.state]}_late`) : status.state
    }

    let otherItemState = ''
    if (itemType === OrderItemTypes.ResumeEdit && status.state === OrderItemStates.SendFirstDraft) {
      otherItemState = '_resume_edit'
    }

    if (!!status.ended_at) {
      return !!TranslationsMap[status.state]
        ? i18n.t(`${TranslationsMap[status.state]}${otherItemState}_done`)
        : status.state
    }
    if (!!status.due_at) {
      if (new Date(status.due_at) < new Date()) {
        return !!TranslationsMap[status.state]
          ? i18n.t(`${TranslationsMap[status.state]}${otherItemState}_late`)
          : status.state
      } else {
        return !!TranslationsMap[status.state]
          ? i18n.t(`${TranslationsMap[status.state]}${otherItemState}_due`)
          : status.state
      }
    }
    return TranslationsMap[status.state] ? i18n.t(TranslationsMap[status.state] as string) : status.state
  }

  const makeItemHistory = (item: FulfillableOrderItemDisplay) => {
    const revisionPeriodEnded = some(
      item.history.active,
      state => state.state === OrderItemStates.Revisions && !!state.due_at && new Date(state.due_at) < new Date()
    )

    const completed = !!item.history.completed
      ? item.history.completed
          .filter(
            s =>
              s.state !== OrderItemStates.AwaitingReply &&
              s.state !== OrderItemStates.SendMessage &&
              s.state !== OrderItemStates.AwaitingResume
          )
          .sort(sortByEndDateDesc)
          .map((s: OrderItemStatus) => {
            const history: OrderItemHistoryEntry = {
              type: formatStatusNames(s, orderIsLate, item.type),
              date: !s.ended_at ? null : s.ended_at,
              isActive: false,
            }
            return history
          })
      : []

    const activeStates = !!item.history.active
      ? revisionPeriodEnded
        ? item.history.active.filter(
            state => state.state !== OrderItemStates.SendMessage && state.state !== OrderItemStates.AwaitingReply
          )
        : item.history.active
      : []

    const active = activeStates.sort(sortByDueDateAsc).map(s => {
      let stateDate: string | null = !!s.due_at ? s.due_at : null
      if ((s.state === OrderItemStates.Paid || s.state === OrderItemStates.Canceled) && !!s.started_at) {
        stateDate = s.started_at
      } else if (s.state === OrderItemStates.AwaitingResume) {
        stateDate = null
      }
      const history: OrderItemHistoryEntry = {
        type: formatStatusNames(s, orderIsLate, item.type),
        date: stateDate,
        isActive: true,
      }
      return history
    })

    return [...active, ...completed]
  }

  const renderFulfillableOrderItem = (item: FulfillableOrderItemDisplay) => {
    const sum = item.pay.map(x => x.amount).reduce((acc, y) => acc + y)
    const hasPay = !!item.pay && sum > 0
    const description = {
      [item.name]: makeItemPay(item),
    }
    return hasPay ? (
      <OrderItem
        compound_item_key={item.compound_item_key}
        isFulfillable={true}
        type={item.type}
        state={item.state}
        due_date={item.due_date}
        description={description}
        history={shouldRenderHistory ? makeItemHistory(item) : undefined}
        applyColors={shouldApplyColors}
        key={item.id}
        name={item.name}
      />
    ) : null
  }

  const getIncentiveName = (incentive: AppliedIncentive) =>
    !shouldSeeInternalIncentiveNames ? incentive.name : `${incentive.name} (${incentive.internal_name})`

  const renderUnfulfillableOrderItem = (item: GenericOrderItemDisplay) => {
    if (!!item.pay) {
      if (item.type === OrderItemTypes.Incentive && !!item.incentives && item.incentives.length > 0) {
        const description = item.incentives.reduce(
          (o: {}, val: AppliedIncentive) => ({
            ...o,
            [getIncentiveName(val)]: formatCurrency(val.amount, item.pay[0].currency),
          }),
          {}
        )
        return (
          <OrderItem
            compound_item_key={item.compound_item_key}
            isFulfillable={false}
            type={item.type}
            description={description}
            key={item.id}
            applyColors={shouldApplyColors}
            name={item.name}
          />
        )
      }
      const sum = item.pay.map(x => x.amount).reduce((acc, y) => acc + y, 0)
      return sum > 0 ? (
        <OrderItem
          compound_item_key={item.compound_item_key}
          isFulfillable={false}
          type={item.type}
          description={{ [item.name]: makeItemPay(item) }}
          key={item.id}
          applyColors={shouldApplyColors}
          name={item.name}
        />
      ) : null
    }
  }

  const { timeZone, locale } = useUserInfoState()
  const originalKey = !!groups && Object.keys(groups).length ? Object.keys(groups)[0] : ''

  if (unfulfillableItems.length > 0 && Object.keys(groups).length > 0) {
    groups[originalKey] = groups[originalKey].concat(unfulfillableItems)
  }

  if (stackItems) {
    return (
      <Box className={classes.stackRoot}>
        {Object.keys(groups).map(key => (
          <Box key={key}>
            {key !== originalKey && (
              <Typography className={classes.addedLabel} display={'block'} variant={'body2'}>
                {i18n.t('order_item__added_on', { date: formatDate(key, timeZone, locale, 'MM/dd/yy') })}
              </Typography>
            )}
            <Box className={classes.root}>
              {groups[key].map(item => {
                if (!item) {
                  return null
                }
                if (!!(item as FulfillableOrderItemDisplay).itemState) {
                  return renderFulfillableOrderItem(item as FulfillableOrderItemDisplay)
                } else {
                  return renderUnfulfillableOrderItem(item)
                }
              })}
            </Box>
          </Box>
        ))}
      </Box>
    )
  } else {
    return (
      <Box className={classes.root}>
        {items &&
          items.map(item => {
            if (!item) {
              return null
            }
            if (!!(item as FulfillableOrderItemDisplay).itemState) {
              return renderFulfillableOrderItem(item as FulfillableOrderItemDisplay)
            } else {
              return renderUnfulfillableOrderItem(item)
            }
          })}
      </Box>
    )
  }
}
export default OrderItems
