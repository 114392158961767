import styles from '../../styles'

const DashboardStyles = {
  breakpoint: '768px',
  card: {
    bg: 'white',
    border: 'solid 1px rgba(46, 91, 255, 0.08)',
    marginMobile: '0 0 1.5em',
    padding: '1.6em',
    shadow: '0 5px 10px 0 rgba(106, 117, 140, 0.05)',
  },
  card2: {
    bg: '#f5f6fa',
    padding: '2.1em',
  },
  chartSecondaryColor: '#71c6f6',
  darkText: '#333f57',
  dashboardMobilePadding: '0 .75em',
  emoji: {
    margin: '0 0 .5em 0',
    size: '2em',
  },
  fontSize: '15px',
  fullWidth: '100%',
  halfWidth: '49%',
  header: {
    color: '#818b9e',
    margin: '0 0 1.2em',
    size: '1.3em',
    width: '100%',
  },
  header2: {
    margin: '0 0 .4em 0',
    size: '1.5em',
    weight: 800,
  },
  header3: {
    size: '1.2em',
  },
  header4: {
    color: '#51648a',
    padding: '0 0 1em',
    size: '1em',
  },
  historyHeight: '32.5em',
  largeText: {
    size: '2.2em',
    weight: 400,
  },
  listHeight: '26.7em',
  quarterWidth: '23%',
  thirdWidth: '32%',
  row: {
    border: 'solid 1px #dbe0eb',
    highlight: '#fef6f8',
    highlightHover: '#fae5eb',
    hover: styles.palette.listHover.color,
    fullPadding: '1.6em',
    padding: '0 1.6em',
    paddingInner: '1.6em 0',
    timeColor: '#99a1b0',
    lineHeight: '1.5em',
  },
  smallText: {
    color: '#b7c2d8',
    size: '1em',
  },
  editingColor: '#0da1ec',
  getOrdersButton: {
    regularColor: '#ec4e38',
    hoverColor: '#ef6653',
    weight: 600,
  },
  party: {
    backgroundGradient: `
      radial-gradient(50% 100%, rgba(28,35,64,0.70) 50%, rgba(28,35,64,0.50) 77%, rgba(28,35,64,0.10) 100%)
      `,
    backgroundColor: '#1c2340',
    textColor: '#fff',
  },
}

export default DashboardStyles
