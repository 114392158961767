import * as stateUtils from '../../utils/state'
import { LoadedLoadingErrorState } from '../../utils/state'
import { HashMap } from '../items/types'
import { calculateClientMacroState, MacroState } from '../../utils/mapper'
import produce from 'immer'
import { ClientOrdersAggregateAction, ClientOrdersAggregateActions } from './actions'
import { initialPaginationState, PaginationState } from '../../utils/pagination'
import { OrderTableDisplayModes } from '../../components/Orders/useOrderFiltering'

export interface ClientOrdersAggregateLoadedLoadingState {
  global: {
    open: LoadedLoadingErrorState
    paid: LoadedLoadingErrorState
  }
  individual: HashMap<LoadedLoadingErrorState>
}

export interface ClientListViewParams {
  searchTerm: string
  filter: OrderTableDisplayModes
}

export interface ClientOrdersAggregateState {
  metaData: ClientOrdersAggregateLoadedLoadingState
  states: HashMap<MacroState>
  unread_reconciled_messages: number
  pagination: {
    open: PaginationState
    paid: PaginationState
  }
  view: ClientListViewParams
}

export const initialState: ClientOrdersAggregateState = {
  states: {},
  unread_reconciled_messages: 0,
  metaData: {
    global: {
      open: { ...stateUtils.initialMultiLoadedLoadingErrorState.global },
      paid: { ...stateUtils.initialMultiLoadedLoadingErrorState.global },
    },
    individual: {
      ...stateUtils.initialMultiLoadedLoadingErrorState.individual,
    },
  },
  pagination: {
    open: { ...initialPaginationState },
    paid: { ...initialPaginationState },
  },
  view: {
    searchTerm: '',
    filter: OrderTableDisplayModes.Open,
  },
}

export default function clientOrdersAggregateReducer(
  state: ClientOrdersAggregateState = initialState,
  action: ClientOrdersAggregateAction
) {
  return produce(state, (draft: ClientOrdersAggregateState) => {
    switch (action.type) {
      case ClientOrdersAggregateActions.FETCH_CLIENT_ORDERS:
        {
          const clientID = action.clientID
          draft.metaData.individual[clientID] = stateUtils.setStartState()
        }
        break

      case ClientOrdersAggregateActions.FETCH_CLIENT_ORDERS_FAIL:
        {
          const clientID = action.meta.previousAction.clientID
          draft.metaData.individual[clientID] = stateUtils.setErrorState(undefined, action.error)
        }
        break

      case ClientOrdersAggregateActions.FETCH_CLIENT_ORDERS_SUCCESS:
        const response = action.payload.data
        const clientID = action.meta.previousAction.clientID
        draft.metaData.individual[clientID] = stateUtils.setSuccessState()

        if (!!response && response.length > 0) {
          draft.states[clientID] = calculateClientMacroState({
            client: response[0].client,
            orders: response,
          })
        }
        break

      case ClientOrdersAggregateActions.FETCH_USER_AGGREGATED_ORDERS:
        {
          const status = action.status
          if (status && status.includes('paid')) {
            draft.metaData.global.paid = stateUtils.setStartState(state.metaData.global.paid)
          }
          if (!status || status.includes('open')) {
            draft.metaData.global.open = stateUtils.setStartState(state.metaData.global.open)
          }
        }
        break
      case ClientOrdersAggregateActions.FETCH_USER_AGGREGATED_ORDERS_FAIL:
        {
          const status = action.meta.previousAction.status
          if (status && status.includes('paid')) {
            draft.metaData.global.paid = stateUtils.setErrorState(state.metaData.global.paid, action.error)
          }
          if (!status || status.includes('open')) {
            draft.metaData.global.open = stateUtils.setErrorState(state.metaData.global.open, action.error)
          }
        }
        break
      case ClientOrdersAggregateActions.FETCH_USER_AGGREGATED_ORDERS_SUCCESS: {
        const response = action.payload.data
        const { previousAction } = action.meta
        const { status } = previousAction
        const { page = 0, page_size = initialPaginationState.page_size } = previousAction.filter
        const isPaidRequest = status && status.includes('paid')
        const isOpenRequest = !status || status.includes('open')
        const paginationKey: 'paid' | 'open' = isPaidRequest ? 'paid' : 'open'

        if (!!response) {
          if (!!response.orders) {
            response.orders.forEach(aggr => {
              const clientID = aggr.client.id
              draft.states[clientID] = calculateClientMacroState(aggr)
            })

            draft.pagination[paginationKey].page = page
            draft.pagination[paginationKey].page_size = page_size
            draft.pagination[paginationKey].total = response.total
            draft.pagination[paginationKey].pages[page] = response.orders.map(o => o.client.id)
          }
          draft.unread_reconciled_messages = action.payload.data.unread_reconciled_messages
        }

        if (isPaidRequest) {
          draft.metaData.global.paid = stateUtils.setSuccessState(state.metaData.global.paid)
          if (!!response) {
            draft.pagination.paid.total = response.total
          }
        }

        if (isOpenRequest) {
          draft.metaData.global.open = stateUtils.setSuccessState(state.metaData.global.open)
          draft.metaData.global.open.lastLoaded = new Date()

          if (!!response) {
            draft.pagination.open.total = response.total
          }
        }
        break
      }

      case ClientOrdersAggregateActions.GO_TO_PAGE:
        const { paginationKey, page } = action
        const pageHasAlreadyBeenLoaded =
          !!state.pagination[paginationKey].pages[page] && state.pagination[paginationKey].pages[page].length > 0
        draft.pagination[paginationKey].page = page
        draft.metaData.global[paginationKey].loaded = pageHasAlreadyBeenLoaded
        break

      case ClientOrdersAggregateActions.SET_VIEW_PARAMS:
        const { filter, searchTerm } = action
        if (!!filter) {
          draft.view.filter = filter
        }
        if (searchTerm != null) {
          draft.view.searchTerm = searchTerm
        }
        break
    }
  })
}
