import React, { useEffect, useCallback } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import isEqual from 'lodash/isEqual'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Settings from '@material-ui/icons/Settings'
import Check from '@material-ui/icons/Check'
import Slider from '@material-ui/core/Slider'
import Box from '@material-ui/core/Box'
import classNames from 'classnames'

import CardWrapper from './CardWrapper'
import { BasePay } from '../../store/orders/types'
import { formatCurrency } from '../../utils/formatting'
import i18n from '../../i18n'
import DashboardStyles from './Styles'
import useGoalSlider from './useGoalSlider'
import useCardInfo from './useCardInfo'
import Info from '@material-ui/icons/Info'
import { DashboardVariants } from './index'

export enum ProgressCardVariants {
  money = 'money',
  earnings = 'earnings',
  orders = 'orders',
  drafts = 'drafts',
  days = 'days',
  blank = 'blank',
}

interface BaseProgressCardProps {
  title: string
  hasProgressBar?: boolean
  hasTooltip?: boolean
  dashVariant?: DashboardVariants
}

interface MoneyProgressCardProps extends BaseProgressCardProps {
  variant: ProgressCardVariants.money
  current?: BasePay
  goal?: BasePay
}

interface EarningsProgressCardProps extends BaseProgressCardProps {
  variant: ProgressCardVariants.earnings
  current?: BasePay
  goal?: BasePay
  updateEarningsGoal: (amount: number) => Promise<void>
}

interface OrderProgressCardProps extends BaseProgressCardProps {
  variant: ProgressCardVariants.orders
  current?: number
  goal?: number
}

interface DayProgessCardProps extends BaseProgressCardProps {
  variant: ProgressCardVariants.days
  current?: number
}

interface BlankProgressCardProps extends BaseProgressCardProps {
  variant: ProgressCardVariants.blank
}

interface DraftsProgressCardProps extends BaseProgressCardProps {
  variant: ProgressCardVariants.drafts
  current?: number
  goal?: number
}

type ProgressCardProps =
  | MoneyProgressCardProps
  | EarningsProgressCardProps
  | OrderProgressCardProps
  | DayProgessCardProps
  | BlankProgressCardProps
  | DraftsProgressCardProps

const useStyles = makeStyles(() =>
  createStyles({
    bar: {
      margin: '1.3em 0 .8em',
      height: '.7em',
    },
    linearProgress: {
      backgroundColor: '#fafafc',
      boxShadow: 'inset 0 1px 3px 0 #dbe0eb',
    },
    slider: {
      padding: 0,
      marginBottom: '.6em',
    },
    progressBar: {
      backgroundColor: DashboardStyles.darkText,
    },
    text1: {
      fontSize: DashboardStyles.largeText.size,
      fontWeight: DashboardStyles.largeText.weight,
      width: '100%',
      '&.editing': {
        color: DashboardStyles.editingColor,
      },
    },
    text1Margin: {
      fontSize: DashboardStyles.largeText.size,
      fontWeight: DashboardStyles.largeText.weight,
      marginTop: '.7em',
      width: '100%',
      '&.editing': {
        color: DashboardStyles.editingColor,
      },
    },
    text2: {
      color: DashboardStyles.smallText.color,
      fontSize: DashboardStyles.smallText.size,
      flexDirection: 'row',
    },
    btn: {
      flexDirection: 'row',
      minWidth: '3em',
      padding: '.5em 0',
      '& > *': {
        color: 'white',
      },
    },
    gearIcon: {
      fontSize: '.8em',
      color: '#b7c2d8',
    },
    cardInfo: {
      margin: '.5em',
    },
    party: {
      color: DashboardStyles.party.textColor,
    },
    partyLine: {
      backgroundColor: DashboardStyles.party.textColor,
    },
    partyBtn: {
      backgroundColor: DashboardStyles.party.textColor,
      '& > *': {
        color: DashboardStyles.party.backgroundColor,
      },
    },
    partyEmoji: {
      marginLeft: '.25em',
      fontSize: '.5em',
    },
  })
)

const ProgressCard: React.FC<ProgressCardProps> = props => {
  const goalSlider = useGoalSlider(
    props.variant === ProgressCardVariants.earnings && props.current ? props.current.amount : 0
  )

  const cardInfo = useCardInfo(
    props.variant === ProgressCardVariants.drafts ? i18n.t('dashboard__draftsProgress__infoText') : ''
  )

  const { hasProgressBar = false } = props
  let primaryText = '-'
  let secondaryText = ''
  let progress = 0
  let goalAmount = 0
  let title = props.title
  const classes = useStyles(props)
  let id = `progress-card-${props.variant}`

  const layoutClass =
    !!props.dashVariant &&
    (props.dashVariant === DashboardVariants.NewHire || props.dashVariant === DashboardVariants.Offshore)
      ? 'third'
      : 'quarter'

  switch (props.variant) {
    case ProgressCardVariants.earnings:
    case ProgressCardVariants.money:
      {
        const { current = { amount: 0, currency: 'USD' }, goal = { amount: 0, currency: 'USD' } } = props
        goalAmount = goal.amount

        // @ts-ignore
        primaryText = formatCurrency(current.amount, current.currency)
        secondaryText = i18n.t('dashboard__moneyProgress_of', {
          // @ts-ignore
          value: formatCurrency(goal.amount, current.currency),
        })

        if (current.amount > 0) {
          progress = Math.min((current.amount / goal.amount) * 100, 100)
        }

        if (goalSlider.active) {
          // @ts-ignore
          primaryText = formatCurrency(goalSlider.amount, current.currency)
          secondaryText = i18n.t('dashboard__moneyPerWeek')
          title = i18n.t('dashboard__goalSliderTitle')
        }
      }
      break

    case ProgressCardVariants.orders:
      {
        const { current = 0, goal = 0 } = props

        primaryText = i18n.t('dashboard__orderProgress__primaryText', { orders: current })
        secondaryText = i18n.t('dashboard__orderProgress__secondaryText', { orders: goal })

        if (goal !== 0) {
          progress = Math.min((current / goal) * 100, 100)
        }
      }
      break

    case ProgressCardVariants.days:
      {
        const { current = 0 } = props
        primaryText = i18n.t('dashboard__daysVariant__primaryText', { days: current })
        // @TODO There is secondary text in the mocks, but I don't know where that value is coming from?
        if (current === 0) {
          secondaryText = i18n.t('dashboard__daysVariant__notEnoughData')
        }

        if (title.toLowerCase().includes('first draft')) {
          id += '-first-draft'
        } else if (title.toLowerCase().includes('response')) {
          id += '-response-time'
        }
      }
      break

    case ProgressCardVariants.drafts: {
      const { current = 0, goal = 0 } = props
      primaryText = i18n.t('dashboard__draftsProgress__primaryText', { drafts: current })
      secondaryText = i18n.t('dashboard__draftsProgress__secondaryText', { drafts_goal: goal })

      if (goal !== 0) {
        progress = Math.min((current / goal) * 100, 100)
      }
    }
  }

  useEffect(() => {
    if (goalAmount !== goalSlider.amount && !goalSlider.active) {
      goalSlider.setAmount(goalAmount)
    }
    /* eslint-disable-next-line */
  }, [goalAmount, goalSlider.amount, goalSlider.active, goalSlider.amount])

  const saveAmount = useCallback(async () => {
    if (goalAmount !== goalSlider.amount) {
      goalSlider.startSaving()
      if (props.variant === ProgressCardVariants.earnings) {
        await props.updateEarningsGoal(goalSlider.amount)
      }
    }

    goalSlider.finishSaving()
  }, [goalSlider, props, goalAmount])

  const infoVisible = props.hasTooltip && cardInfo && cardInfo.isVisible
  // No party unless the user has set a goal
  const party = progress === 100 && (props.variant !== ProgressCardVariants.earnings || goalAmount > 0)

  return (
    <CardWrapper title={title} cardClass={layoutClass} altColor={goalSlider.active && !party} party={party} id={id}>
      {infoVisible && (
        <Typography className={classNames([classes.cardInfo])} display={'block'}>
          {cardInfo.infoText}
        </Typography>
      )}
      {!infoVisible && hasProgressBar && !goalSlider.active && (
        <LinearProgress
          className={classNames({
            [classes.linearProgress]: true,
            [classes.bar]: true,
          })}
          classes={{
            barColorPrimary: party ? classes.partyLine : classes.progressBar,
          }}
          variant="determinate"
          value={progress}
        />
      )}
      {!infoVisible && goalSlider.active && (
        <Slider
          className={classNames([classes.slider, classes.bar])}
          classes={{
            root: party ? classes.party : '',
          }}
          value={[goalSlider.amount, goalAmount]}
          max={2500}
          step={100}
          onChange={(_, value) =>
            goalSlider.setAmount(Array.isArray(value) ? value.find(v => v !== goalAmount) || goalAmount : value)
          }
        />
      )}
      {!infoVisible && hasProgressBar && (
        <Typography
          className={classNames({
            [classes.text1]: true,
            editing: goalSlider.active && !party,
            [classes.party]: party,
          })}
          display={'block'}
        >
          {primaryText}
          {party && !goalSlider.active && (
            <span role="img" aria-label="trophy emoji because you met your goal" className={classes.partyEmoji}>
              🏆
            </span>
          )}
        </Typography>
      )}
      {!infoVisible && !hasProgressBar && (
        <Typography
          className={classNames({
            [classes.text1Margin]: true,
            editing: goalSlider.active && !party,
            [classes.party]: party,
          })}
          display={'block'}
        >
          {primaryText}
          {party && !goalSlider.active && (
            <span role="img" aria-label="trophy emoji because you met your goal" className={classes.partyEmoji}>
              🏆
            </span>
          )}
        </Typography>
      )}
      {!infoVisible && (
        <Box display="flex" justifyContent="space-between">
          {secondaryText.length > 0 && (
            <Typography
              className={classNames({
                [classes.text2]: true,
                [classes.party]: party,
              })}
              display="block"
            >
              {secondaryText}
            </Typography>
          )}
          {props.variant === ProgressCardVariants.earnings && (
            <>
              {goalSlider.active && (
                <Button
                  onClick={saveAmount}
                  disabled={goalSlider.saving}
                  variant="contained"
                  color="primary"
                  className={classNames({
                    [classes.btn]: true,
                    [classes.partyBtn]: party,
                  })}
                >
                  <Check />
                </Button>
              )}
              {!goalSlider.active && (
                <IconButton className={classes.btn} onClick={() => goalSlider.toggle(true)}>
                  <Settings
                    className={classNames({
                      [classes.gearIcon]: true,
                      [classes.party]: party,
                    })}
                  />
                </IconButton>
              )}
            </>
          )}
        </Box>
      )}
      {!!props.hasTooltip && cardInfo && (
        <IconButton
          className={classes.btn}
          aria-label="info"
          onMouseEnter={() => cardInfo.toggle(true)}
          onMouseLeave={() => cardInfo.toggle(false)}
        >
          <Info
            className={classNames({
              [classes.gearIcon]: true,
              [classes.party]: party,
            })}
          />
        </IconButton>
      )}
    </CardWrapper>
  )
}

const memoizedProgressCard = React.memo(ProgressCard, isEqual)
// @ts-ignore
memoizedProgressCard.whyDidYouRender = true

export default memoizedProgressCard
