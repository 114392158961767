import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TrendIcon from '@material-ui/icons/CallMade'
import classNames from 'classnames'

interface Props {
  variant?: 'short' | 'tall'
  delta: string
  percent: number
  positive: boolean
  trendDirection: 'up' | 'down'
  subtitle?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    negative: {
      color: '#ed3572',
      fontSize: '1.2em',
      fontWeight: 'bold',
    },
    positive: {
      color: '#13d764',
      fontSize: '1.2em',
      fontWeight: 'bold',
    },
    icon: {
      marginRight: '.25em',
      verticalAlign: 'text-bottom',
    },
    negativeIconFix: {
      transform: 'rotate(90deg)',
    },
    subtitle: {
      fontSize: '.8em',
      margin: '.5em 0 0 .5em',
      textTransform: 'uppercase',
      '&.tall': {
        display: 'block',
      },
    },
  })
)

const ChangeDisplay: React.FC<Props> = ({ delta, percent, positive, trendDirection, subtitle, variant = 'tall' }) => {
  const classes = useStyles()

  return (
    <span color="secondary">
      <span
        className={classNames({
          [classes.positive]: positive,
          [classes.negative]: !positive,
        })}
      >
        <TrendIcon
          fontSize="inherit"
          className={classNames({
            [classes.icon]: true,
            [classes.negativeIconFix]: trendDirection === 'down',
          })}
        />
        {delta} ({percent}%)
      </span>
      {subtitle && <span className={classNames([classes.subtitle, variant])}>{subtitle}</span>}
    </span>
  )
}

export default ChangeDisplay
