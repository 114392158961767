import { Message } from '../../../store/messages/types'

export default function(message: Message): string {
  // First, we must take the message text and make it into HTML
  const el = document.createElement('div')
  el.innerHTML = message.message_text

  if (!el.textContent) {
    return ''
  }

  // Then, we grab the first 200 characters of the message as the message preview
  return el.textContent.substring(0, 200).replace('\n', ' ')
}
