import React from 'react'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import styles from '../../styles'
import UploadStyles from './styles'

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      marginTop: styles.sizes.topNavHeight,
      backgroundColor: UploadStyles.backgroundColor,
    },
    bg: {
      backgroundColor: UploadStyles.backgroundColor,
    },
    root: {
      'z-index': '100 !important',
    },
  })
)

const FullScreenDialog: React.FC<Partial<DialogProps>> = ({ children, ...props }) => {
  const classes = useStyles(props)

  return (
    <Dialog
      fullScreen
      open={true}
      classes={{ paperScrollPaper: classes.bg, scrollPaper: classes.bg, root: classes.root }}
      className={classes.dialog}
      {...props}
    >
      {children}
    </Dialog>
  )
}

export default FullScreenDialog
