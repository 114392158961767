import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { AutoLinkedin, ClientDataPoint, Sections } from '../../store/autolinkedin/types'
import {
  getAutoLinkedinByItemID,
  getAutoLinkedinClientData,
  getAutoLinkedinParsedSections,
  getAutoLinkedinUpdateMeta,
} from '../../store/autolinkedin/selectors'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import { LoadedLoadingErrorState } from '../../utils/state'
import { isToday } from 'date-fns'
import useUserInfoState from '../common/useUserInfo'
import ModalWrapper from '../common/ModalWrapper'
import Header from '../common/Header'
import i18n from '../../i18n'
import Box from '@material-ui/core/Box'
import NavigationBar from './NavigationBar'
import ResumePreview from './ResumePreview'
import Typography from '@material-ui/core/Typography'
import HistoryIcon from '@material-ui/icons/History'
import SummaryClientDataList from './SummaryClientDataList'
import SummaryForm from './SummaryForm'
import SectionForm from './SectionForm'
import BackToTop from '../common/BackToTop'
import Footer from '../common/Footer'
import Button from '../common/Button'
import WarningIcon from '@material-ui/icons/Warning'
import { formatDate } from '../../utils/formatting'
import { getClientGoals } from '../../selectors/clients'

export interface LinkedinReadOnlyFormProps {
  handleNext: () => void
  handleBack: () => void
  handleClose: () => void
  handleReset: () => void
  linkedinItemID: number
  clientID: number
}

const useStyles = makeStyles(() =>
  createStyles({
    cancelButton: {
      marginRight: '1.2rem',
      padding: '.5rem 1.547rem',
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    controlsBox: {
      marginLeft: '2em',
      marginTop: '25px',
      '& > *': {
        margin: '9px 0',
      },
    },
    errorBox: {
      color: styles.palette.pink.hex,
      '& > svg': {
        verticalAlign: 'text-bottom',
        marginRight: '.5rem',
      },
    },
    errorBoxText: {
      fontSize: '.95rem',
    },
    forms: {
      marginLeft: '19.5em !important',
      width: '80%',
    },
    formUpdateStatus: {
      justifyContent: 'center',
      display: 'flex',
      width: '100%',
      margin: 'auto',
    },
    formUpdateStatusText: {
      fontStyle: 'italic',
      fontSize: '.95rem',
    },
    highlighted: {
      border: `3px solid ${styles.palette.darkerBlue.color.hex} !important`,
    },
    icon: {
      cursor: 'pointer',
      display: 'inline-block',
      marginBottom: '-.2em',
    },
    iconText: {
      cursor: 'pointer',
      color: styles.palette.darkerBlue.color.hex,
      fontSize: '15px',
      fontWeight: 500,
    },
    navigationBox: {
      position: 'fixed',
      top: '13em',
      width: '17em',
    },
    submitButton: {
      padding: '.5rem 1.547rem',
    },
  })
)

const ReadOnlyFormWrapper: React.FC<LinkedinReadOnlyFormProps> = ({
  handleNext,
  handleBack,
  handleClose,
  handleReset,
  linkedinItemID,
  clientID,
}) => {
  const classes = useStyles()

  const autoLinkedinSections = useSelector<AppState, Sections[]>(state =>
    getAutoLinkedinParsedSections(state.autolinkedinReducer, linkedinItemID)
  )

  const updateMeta = useSelector<AppState, LoadedLoadingErrorState | undefined>(state =>
    getAutoLinkedinUpdateMeta(state.autolinkedinReducer, linkedinItemID)
  )

  const autoLinkedin = useSelector<AppState, AutoLinkedin | undefined | null>(state =>
    getAutoLinkedinByItemID(state.autolinkedinReducer, linkedinItemID)
  )

  const autoLinkedinClientData = useSelector<AppState, ClientDataPoint[]>(state =>
    getAutoLinkedinClientData(state.autolinkedinReducer, linkedinItemID).filter(s => s.content !== null)
  )

  const goals = useSelector<AppState, string | undefined>(state =>
    getClientGoals(state.clientQuestionnaireReducer, clientID)
  )

  const lastUpdatedToday = !!autoLinkedin?.updated_at ? isToday(new Date(autoLinkedin?.updated_at)) : false
  const summaryPopulated = !!autoLinkedin?.data_json?.summary?.text
    ? autoLinkedin?.data_json?.summary?.text.length > 0
    : false

  const headLinePopulated = !!autoLinkedin?.data_json?.headline ? autoLinkedin?.data_json?.headline?.length > 0 : false

  const { timeZone, locale } = useUserInfoState()

  return (
    <ModalWrapper>
      <Header
        activeStep={1}
        closeButton={true}
        handleClose={handleClose}
        title={i18n.t('alink__ComposeSummary__title')}
        transparent={true}
      />
      <Box>
        <Box className={classes.navigationBox}>
          <NavigationBar sections={autoLinkedinSections} greyOutEmpty={false} />
          <Box className={classes.controlsBox}>
            <ResumePreview
              imageUrls={autoLinkedin?.resume_image_urls}
              iconClass={classes.icon}
              iconTextClass={classes.iconText}
            />
            <Typography className={classes.iconText} onClick={handleReset}>
              <HistoryIcon className={classes.icon} /> {i18n.t('alink__nav__restart')}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.forms}>
          {(autoLinkedinClientData.length > 0 || (!!goals && goals.length > 0)) && (
            <SummaryClientDataList data={autoLinkedinClientData} questionnaireGoals={goals} />
          )}
          <SummaryForm highlightClass={classes.highlighted} linkedinItemID={linkedinItemID} />
          {autoLinkedinSections
            .filter(s => s.recordCount > 0)
            .map(parsedSection => (
              <SectionForm
                highlightClass={classes.highlighted}
                key={parsedSection.section}
                linkedinItemID={linkedinItemID}
                readOnly={true}
                section={parsedSection}
              />
            ))}
        </Box>
      </Box>

      <BackToTop topElement="header" />

      <Footer
        leftColumn={
          <Button type="secondary" className={classes.cancelButton} onClick={handleBack} id="edit-li-form-cancel">
            {i18n.t('alink__ComposeSummary__cancel')}
          </Button>
        }
        rightColumn={
          <Button
            type="contrast"
            showLoader={updateMeta?.isLoading}
            disabled={updateMeta?.isLoading || !summaryPopulated || !headLinePopulated}
            className={classes.submitButton}
            onClick={handleNext}
            id="edit-li-form-next"
          >
            {i18n.t('alink__ComposeSummary__next')}
          </Button>
        }
      >
        <Box className={classes.formUpdateStatus}>
          {updateMeta?.isLoading && (
            <Typography className={classes.formUpdateStatusText}>
              {i18n.t('alink__EditLIProfileSections__saving')}
            </Typography>
          )}
          {updateMeta?.loaded && !!updateMeta?.error && (
            <Box className={classes.errorBox}>
              <WarningIcon />
              <Typography className={classes.errorBoxText} component="span">
                {i18n.t('alink__updateError')}
              </Typography>
            </Box>
          )}
          {updateMeta?.loaded && !updateMeta?.error && lastUpdatedToday && autoLinkedin?.updated_at && (
            <Typography className={classes.formUpdateStatusText}>
              {i18n.t('alink__EditLIProfileSections__last_saved', {
                time: formatDate(autoLinkedin?.updated_at, timeZone, locale, 'pp'),
              })}
            </Typography>
          )}
        </Box>
      </Footer>
    </ModalWrapper>
  )
}

export default ReadOnlyFormWrapper
