import React from 'react'
import * as Sentry from '@sentry/browser'

interface FallbackErrorBoundaryProps {
  children: React.ReactElement | React.ReactElement[] | string
  fallback: React.ReactElement
}

interface FallbackErrorBoundaryState {
  error: Error | null
}

export default class FallbackErrorBoundary extends React.Component<
  FallbackErrorBoundaryProps,
  FallbackErrorBoundaryState
> {
  public readonly state: FallbackErrorBoundaryState

  public constructor(props: FallbackErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  public componentDidCatch(error: Error) {
    Sentry.captureException(error)
    this.setState({ error })
  }

  public render() {
    return this.state.error ? this.props.fallback : this.props.children
  }
}
