import { HashMap } from './HashMap'

export interface PaginationArgs {
  // Which page to grab
  page?: number
  // Maximum number of items per page
  page_size?: number
}

export interface PaginationState {
  page: number
  page_size: number
  // These are the IDs of the records per page
  pages: HashMap<number[]>
  // Total number of records in the view
  total: number
}

export const initialPaginationState: PaginationState = {
  page: 1,
  page_size: 25,
  pages: {},
  total: 0,
}

export const updatePaginationState = (state: PaginationState, args: Partial<PaginationArgs>): PaginationState => {
  return {
    ...state,
    ...args,
  }
}

export const advancePage = (state: PaginationState): PaginationState => {
  return {
    ...state,
    page: state.page + 1,
  }
}
