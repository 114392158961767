import { CreateFeedbackPayload, FeatureKeys, FeedbackBoolPayload } from '../../store/featureFeedback/types'
import { useCallback, useState } from 'react'
import { HashMap } from '../../utils/HashMap'
import { useDispatch } from 'react-redux'
import { createFeatureFeedback } from '../../store/featureFeedback/actions'

interface UseFeatureFeedback {
  updateFeedback: (payload: FeedbackBoolPayload) => void
  submitFeedback: (feedback: HashMap<HashMap<FeedbackBoolPayload>>, feedbackKey: string) => Promise<void>
  feedback: HashMap<HashMap<FeedbackBoolPayload>>
}

// Use a hook per feature_key, i.e. file_preprocess
const useFeatureFeedback = (
  feature_key: FeatureKeys,
  initialValue?: HashMap<HashMap<FeedbackBoolPayload>> | {}
): UseFeatureFeedback => {
  // The shape is feedback[feature_id][feedback_element] = FeedbackBoolPayload
  const [feedback, setFeedback] = useState<HashMap<HashMap<FeedbackBoolPayload>>>(initialValue || {})

  const dispatch = useDispatch()

  const updateFeedback = useCallback(
    (payload: FeedbackBoolPayload) => {
      setFeedback(feedback => {
        const newFeedback = { ...feedback }
        if (!newFeedback[payload.feature_id]) {
          newFeedback[payload.feature_id] = {}
        }
        newFeedback[payload.feature_id][payload.feature_element] = payload
        return newFeedback
      })
    },
    [setFeedback]
  )

  const submitFeedback = useCallback(
    async (feedback: HashMap<HashMap<FeedbackBoolPayload>>, feedbackKey: string) => {
      const flattenedFeedback = Object.values(feedback)
        .map(f => Object.values(f))
        .flat()
      const payload: CreateFeedbackPayload = {
        feature_key: feature_key,
        feedback_key: feedbackKey,
        origin: 'goms',
        feedback: flattenedFeedback,
      }

      await dispatch(createFeatureFeedback(feature_key, payload))
    },
    [dispatch, feature_key]
  )

  return {
    updateFeedback,
    submitFeedback,
    feedback,
  }
}

export default useFeatureFeedback
