import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { AppState } from '../'
import {
  MessageContext,
  MessageContexts,
  Message,
  OutgoingMessage,
  MessageStatuses,
  MessageStatus,
  MessageStatusCreateRequest,
  MessageSendResponse,
  ScanVirusRequest,
  ScanVirusResponse,
} from './types'
import {
  AxiosMiddlewareActionSuccess,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionCreator,
} from '../../utils/axios'
import { userId } from '../user/reducers'
import { FetchUserSuccess } from '../user/actions'
import { PaginationArgs } from '../../utils/pagination'
import { getLatestMessageByDirection, messageIsUnreadByUser, messageReadByClientAt } from '../../selectors/messages'
import { currentMessageSetReply } from '../currentMessage/actions'

export enum MessageActionTypes {
  FETCH_MESSAGES = 'FETCH_MESSAGES',
  FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS',
  FETCH_MESSAGES_FAIL = 'FETCH_MESSAGES_FAIL',

  START_SENDING_MESSAGE = 'START_SENDING_MESSAGE',

  SEND_MESSAGE = 'SEND_MESSAGE',
  SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL',

  STATUS_CREATE = 'STATUS_CREATE',
  STATUS_CREATE_SUCCESS = 'STATUS_CREATE_SUCCESS',
  STATUS_CREATE_FAIL = 'STATUS_CREATE_FAIL',

  STATUS_END = 'STATUS_END',
  STATUS_END_SUCCESS = 'STATUS_END_SUCCESS',
  STATUS_END_FAIL = 'STATUS_END_FAIL',

  SCAN_FOR_VIRUSES = 'SCAN_FOR_VIRUSES',
  SCAN_FOR_VIRUSES_SUCCESS = 'SCAN_FOR_VIRUSES_SUCCESS',
  SCAN_FOR_VIRUSES_FAIL = 'SCAN_FOR_VIRUSES_FAIL',

  FETCH_MESSAGE_CLIENT_READ = 'FETCH_MESSAGE_CLIENT_READ',
  FETCH_MESSAGE_CLIENT_READ_SUCCESS = 'FETCH_MESSAGE_CLIENT_READ_SUCCESS',
  FETCH_MESSAGE_CLIENT_READ_FAIL = 'FETCH_MESSAGE_CLIENT_READ_FAIL',

  META_RESET = 'META_RESET',
}

export interface FetchMessages extends AxiosMiddlewareActionCreator {
  type: typeof MessageActionTypes.FETCH_MESSAGES
  context: MessageContext
  contextID: number | userId
}

export interface FetchMessagesSuccess extends AxiosMiddlewareActionSuccess<Message[], FetchMessages> {
  type: typeof MessageActionTypes.FETCH_MESSAGES_SUCCESS
}

export interface FetchMessagesFail extends AxiosMiddlewareActionFail {
  type: typeof MessageActionTypes.FETCH_MESSAGES_FAIL
}

export function fetchMessages(
  context: MessageContext,
  contextID: number,
  // @TODO We don't have paginiation setup at all for this, so let's try to grab all of them
  args: PaginationArgs = { page_size: 1000 }
): FetchMessages {
  return {
    type: MessageActionTypes.FETCH_MESSAGES,
    context,
    contextID,
    payload: {
      request: {
        url: `/v1/messages/context/${context}/${contextID}`,
        params: args,
      },
    },
  }
}

export function fetchUserInbox(userID: userId, args: PaginationArgs = {}): FetchMessages {
  return {
    type: MessageActionTypes.FETCH_MESSAGES,
    context: MessageContexts.USER,
    contextID: userID,
    payload: {
      request: {
        url: `/v1/messages/inbox/user/${userID}`,
        params: args,
      },
    },
  }
}

export interface StartSendingMessage {
  type: typeof MessageActionTypes.START_SENDING_MESSAGE
}

export interface SendMessage extends AxiosMiddlewareActionCreator {
  type: typeof MessageActionTypes.SEND_MESSAGE
  context: MessageContexts
  contextID: number | userId
}

export interface SendMessageSuccess extends AxiosMiddlewareActionSuccess<MessageSendResponse, SendMessage> {
  type: typeof MessageActionTypes.SEND_MESSAGE_SUCCESS
}

export interface SendMessageFail extends AxiosMiddlewareActionFail {
  type: typeof MessageActionTypes.SEND_MESSAGE_FAIL
}

export interface ScanForViruses extends AxiosMiddlewareActionCreator {
  type: typeof MessageActionTypes.SCAN_FOR_VIRUSES
}

export interface ScanForVirusesSuccess extends AxiosMiddlewareActionSuccess<ScanVirusResponse, ScanForViruses> {
  type: typeof MessageActionTypes.SCAN_FOR_VIRUSES_SUCCESS
}

export interface ScanForVirusesFail extends AxiosMiddlewareActionFail<ScanForViruses> {
  type: typeof MessageActionTypes.SCAN_FOR_VIRUSES_FAIL
}

export function scanForViruses(data: ScanVirusRequest): ScanForViruses {
  return {
    type: MessageActionTypes.SCAN_FOR_VIRUSES,
    payload: {
      request: {
        url: `/v1/messages/malware/scan`,
        method: 'POST',
        data,
      },
    },
  }
}

export function sendMessage(context: MessageContexts, contextID: number, data: OutgoingMessage): SendMessage {
  return {
    type: MessageActionTypes.SEND_MESSAGE,
    context,
    contextID,
    payload: {
      request: {
        url: `/v1/messages/context/${context}/${contextID}`,
        method: 'POST',
        data,
      },
    },
  }
}

export function startSendingMessage(): StartSendingMessage {
  return {
    type: MessageActionTypes.START_SENDING_MESSAGE,
  }
}

export function sendMessageToUserInbox(userID: userId, data: OutgoingMessage): SendMessage {
  return {
    type: MessageActionTypes.SEND_MESSAGE,
    context: MessageContexts.USER,
    contextID: userID,
    payload: {
      request: {
        url: `/v1/messages/inbox/user/${userID}`,
        method: 'POST',
        data,
      },
    },
  }
}

interface MessageMetaReset {
  type: typeof MessageActionTypes.META_RESET
}

export function messagesMetaReset() {
  return {
    type: MessageActionTypes.META_RESET,
  }
}

export interface MessageStatusCreate extends AxiosMiddlewareActionCreator {
  type: typeof MessageActionTypes.STATUS_CREATE
  messageID: number
  context: MessageContext
  contextID: number
}

export interface MessageStatusCreateSuccess extends AxiosMiddlewareActionSuccess<MessageStatus, MessageStatusCreate> {
  type: typeof MessageActionTypes.STATUS_CREATE_SUCCESS
}

export interface MessageStatusCreateFail extends AxiosMiddlewareActionFail<MessageStatusCreate> {
  type: typeof MessageActionTypes.STATUS_CREATE_FAIL
}

export function createMessageStatus(
  messageID: number,
  context: MessageContext,
  contextID: number,
  data: MessageStatusCreateRequest
): MessageStatusCreate {
  return {
    type: MessageActionTypes.STATUS_CREATE,
    messageID,
    context,
    contextID,
    payload: {
      request: {
        url: `/v1/messages/status/${messageID}`,
        method: 'POST',
        data,
      },
    },
  }
}

export interface MessageStatusEnd extends AxiosMiddlewareActionCreator {
  type: typeof MessageActionTypes.STATUS_END
  context: MessageContext
  contextID: number
  messageID: number
  messageStatusID: number
}

export interface MessageStatusEndSuccess extends AxiosMiddlewareActionSuccess<MessageStatus, MessageStatusEnd> {
  type: typeof MessageActionTypes.STATUS_END_SUCCESS
}

export interface MessageStatusEndFail extends AxiosMiddlewareActionFail<MessageStatusEnd> {
  type: typeof MessageActionTypes.STATUS_END_FAIL
}

export function endMessageStatus(
  messageID: number,
  context: MessageContext,
  contextID: number,
  messageStatusID: number
): MessageStatusEnd {
  return {
    type: MessageActionTypes.STATUS_END,
    context,
    contextID,
    messageID,
    messageStatusID,
    payload: {
      request: {
        url: `/v1/messages/status/${messageID}/id/${messageStatusID}`,
        method: 'DELETE',
      },
    },
  }
}

export function markMessageAsRead(message: Message): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>) {
    if (!messageIsUnreadByUser(message)) {
      return
    }

    await dispatch(
      createMessageStatus(message.id, message.context, message.context_id, {
        status: MessageStatuses.Read,
      })
    )
  }
}

export function markMessageAsUnread(message: Message): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>) {
    const readStatusesID = message.statuses.filter(s => s.status === MessageStatuses.Read && !s.end_at).map(s => s.id)

    if (!readStatusesID.length) {
      return
    }

    await dispatch(endMessageStatus(message.id, message.context, message.context_id, Math.max(...readStatusesID)))
  }
}

export function toggleMessageRead(message: Message): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>) {
    if (messageIsUnreadByUser(message)) {
      await dispatch(markMessageAsRead(message))
    } else {
      await dispatch(markMessageAsUnread(message))
    }
  }
}

export function markTheLatestMessageAsRead(
  context: MessageContext,
  contextID: number
): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    const lastIncomingMessage = getLatestMessageByDirection(getState().messagesReducer, context, contextID, false)
    if (!!lastIncomingMessage) {
      dispatch(markMessageAsRead(lastIncomingMessage))
    }
  }
}

export function replyToLatestMessage(
  context: MessageContext,
  contextID: number
): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    const lastIncomingMessage = getLatestMessageByDirection(getState().messagesReducer, context, contextID, false)
    dispatch(currentMessageSetReply(lastIncomingMessage))
    dispatch(markTheLatestMessageAsRead(context, contextID))
  }
}

export interface FetchMessageClientReadEvent extends AxiosMiddlewareActionCreator {
  type: typeof MessageActionTypes.FETCH_MESSAGE_CLIENT_READ
  context: MessageContext
  contextID: number
  messageID: number
}

export interface FetchMessageClientReadEventSuccess
  extends AxiosMiddlewareActionSuccess<MessageStatus, FetchMessageClientReadEvent> {
  type: typeof MessageActionTypes.FETCH_MESSAGE_CLIENT_READ_SUCCESS
}

export interface FetchMessageClientReadEventFail extends AxiosMiddlewareActionFail<FetchMessageClientReadEvent> {
  type: typeof MessageActionTypes.FETCH_MESSAGE_CLIENT_READ_FAIL
}

export function fetchMessageClientReadEvent(message: Message): FetchMessageClientReadEvent {
  return {
    type: MessageActionTypes.FETCH_MESSAGE_CLIENT_READ,
    context: message.context,
    contextID: message.context_id,
    messageID: message.id,
    payload: {
      request: {
        url: `/v1/messages/status/${message.id}/read`,
      },
    },
  }
}

export type FetchMessageClientReadEventThunk = ThunkAction<Promise<void>, AppState, {}, AnyAction>

export function fetchMessagesAndReadEvent(
  context: MessageContext,
  contextID: number
): FetchMessageClientReadEventThunk {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    await dispatch(fetchMessages(context, contextID))

    // Determine if the last message has a client read status. If it doesn't, check if it has been read
    const latestOutgoingMessage = getLatestMessageByDirection(getState().messagesReducer, context, contextID, true)

    if (!latestOutgoingMessage) {
      return
    }

    if (!messageReadByClientAt(latestOutgoingMessage)) {
      dispatch(fetchMessageClientReadEvent(latestOutgoingMessage))
    }
  }
}

export type MessageActionType =
  | FetchMessages
  | FetchMessagesSuccess
  | FetchMessagesFail
  | SendMessage
  | SendMessageSuccess
  | SendMessageFail
  | ScanForViruses
  | ScanForVirusesSuccess
  | ScanForVirusesFail
  | FetchUserSuccess
  | MessageMetaReset
  | MessageStatusCreate
  | MessageStatusCreateSuccess
  | MessageStatusCreateFail
  | MessageStatusEnd
  | MessageStatusEndSuccess
  | MessageStatusEndFail
  | FetchMessageClientReadEvent
  | FetchMessageClientReadEventSuccess
  | FetchMessageClientReadEventFail
  | StartSendingMessage
