import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Line } from 'react-chartjs-2'
import format from 'date-fns/format'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'

import CardWrapper from './CardWrapper'
import ChangeDisplay from './ChangeDisplay'
import i18n from '../../i18n'
import { PayHistory, YtdAndRelativeStats } from '../../store/dashboard/types'
import { formatCurrency, Currency } from '../../utils/formatting'
import DashboardStyles from './Styles'

interface Props {
  paymentHistory: PayHistory[]
  payStats: YtdAndRelativeStats | null
  currency: Currency
}

const useStyles = makeStyles(theme =>
  createStyles({
    baseOrder: {
      backgroundColor: DashboardStyles.darkText,
    },
    bonusOrder: {
      backgroundColor: DashboardStyles.chartSecondaryColor,
    },
    chartBox: {
      margin: '3em 0 0',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: DashboardStyles.fullWidth,
    },
    leftColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '18em',
    },
    orderTypeBar: {
      color: 'white',
      height: '1.5em',
      marginBottom: '1em',
      width: DashboardStyles.fullWidth,

      '& > span': {
        display: 'inline-block',
        padding: '1%',
        fontSize: '.8em',
        fontWeight: 600,
        height: '100%',
        lineHeight: '1.5em',
      },

      '& > span:nth-child(2)': {
        textAlign: 'right',
      },
    },
    rightColumn: {
      width: 'calc(100% - 20em)',
    },
    typesTitle: {
      color: DashboardStyles.header4.color,
      fontSize: DashboardStyles.header4.size,
      padding: DashboardStyles.header4.padding,
      textTransform: 'uppercase',
    },
    typeDot: {
      content: ' ',
      width: '.9em',
      height: '.9em',
      borderRadius: '50%',
      display: 'inline-block',
      verticalAlign: 'top',
    },
    typeDots: {
      '& > span': {
        display: 'inline-block',
      },
      '& > span:nth-child(2)': {
        float: 'right',
      },
    },
    ytdTotal: {
      fontSize: DashboardStyles.largeText.size,
      fontWeight: DashboardStyles.largeText.weight,
      marginBottom: '.3em',
    },
    [theme.breakpoints.down('sm')]: {
      container: {
        flexDirection: 'column',
      },
      leftColumn: {
        margin: DashboardStyles.card.marginMobile,
        marginBottom: '3em',
        width: DashboardStyles.fullWidth,
      },
      orderTypeBar: {
        '& > span': {
          padding: '.25%',
        },
      },
      rightColumn: {
        width: DashboardStyles.fullWidth,
      },
    },
  })
)

const EarningsChart: React.FC<Props> = ({ paymentHistory, payStats, currency }) => {
  const classes = useStyles()
  const {
    total_ytd = 0,
    ytd_base_percent = 0,
    ytd_bonus_percent = 0,
    this_and_last_ytd_delta = 0,
    this_and_last_ytd_delta_percent = 0,
  } = payStats || {}
  const baseBonusPercentZero = ytd_bonus_percent === 0 && ytd_base_percent === 0
  const showBonusBar = ytd_base_percent < 98

  const data = (canvas: HTMLCanvasElement | HTMLElement) => {
    let backgroundColor: string | CanvasGradient = '#ed3572'

    if ('getContext' in canvas) {
      const ctx = canvas.getContext('2d')

      if (ctx) {
        backgroundColor = ctx.createLinearGradient(150.0, 0.0, 150.0, 300.0)
        backgroundColor.addColorStop(0.0, 'rgba(237, 53, 114, 0.4)')
        backgroundColor.addColorStop(1.0, 'rgba(255, 255, 255, 0.000)')
      }
    }

    return {
      datasets: [
        {
          data: paymentHistory.map(ph => {
            return {
              x: ph.pay_date,
              y: ph.amount,
            }
          }),
          borderColor: '#ed3572',
          pointBackgroundColor: '#ed3572',
          backgroundColor,
          lineTension: 0.1,
        },
      ],
    }
  }

  return (
    <CardWrapper
      title={i18n.t('dashboard__earningsChart__title', { year: new Date().getFullYear() })}
      cardClass="fullwidth"
    >
      <Box className={classes.container}>
        <Box className={classes.leftColumn}>
          <Box>
            <Typography component="h5" area-label="year_to_date_amount" className={classes.ytdTotal}>
              {formatCurrency(total_ytd, currency)}
            </Typography>
            <ChangeDisplay
              delta={formatCurrency(this_and_last_ytd_delta, currency)}
              percent={Math.abs(this_and_last_ytd_delta_percent)}
              positive={this_and_last_ytd_delta > 0}
              trendDirection={this_and_last_ytd_delta > 0 ? 'up' : 'down'}
              subtitle={i18n.t(
                paymentHistory.length === 52
                  ? 'dashboard__earningsChart__changeDisplaySubtitle'
                  : 'dashboard__earningsChart__sinceStarted'
              )}
              variant="short"
            />
          </Box>
          <Box className={classes.chartBox}>
            <Typography className={classes.typesTitle}>Types of Orders</Typography>
            <Box className={classes.orderTypeBar}>
              <span
                style={{ width: `${!baseBonusPercentZero ? ytd_base_percent : 100}%` }}
                className={classes.baseOrder}
              >
                {!baseBonusPercentZero ? ytd_base_percent : 100}%
              </span>
              {showBonusBar && ytd_base_percent > 0 && (
                <span style={{ width: `${ytd_bonus_percent}%` }} className={classes.bonusOrder}>
                  {ytd_bonus_percent}%
                </span>
              )}
            </Box>
            <Box className={classes.typeDots}>
              <span>
                <span className={classNames([classes.baseOrder, classes.typeDot])} />{' '}
                {i18n.t('dashboard__earningsChart__base')}
              </span>
              <span>
                <span className={classNames([classes.bonusOrder, classes.typeDot])} />{' '}
                {i18n.t('dashboard__earningsChart__bonus')}
              </span>
            </Box>
          </Box>
        </Box>
        <Box className={classes.rightColumn}>
          <Line
            data={data}
            options={{
              legend: {
                display: false,
              },
              tooltips: {
                displayColors: false,
                callbacks: {
                  title: points =>
                    i18n.t('dashboard__ytdChart__week', {
                      weekNumber: paymentHistory.length - (points[0].index || 1),
                    }),
                  afterTitle: points => format(new Date(points[0].label as string), i18n.t('date_format')),
                  label: point => formatCurrency(parseFloat(point.value || '0'), currency, 2),
                },
              },
              scales: {
                xAxes: [
                  {
                    type: 'time',
                    time: {
                      unit: 'month',
                    },
                    gridLines: {
                      borderDash: [2, 2],
                      color: 'rgba(128, 151, 177, 0.76)',
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      callback: (value: number) => formatCurrency(value, currency, 0),
                      suggestedMin: 0,
                      suggestedMax: 1000,
                    },
                    gridLines: {
                      borderDash: [2, 2],
                      color: 'rgba(128, 151, 177, 0.76)',
                    },
                  },
                ],
              },
            }}
          />
        </Box>
      </Box>
    </CardWrapper>
  )
}

const memoizedEarningsChart = React.memo(EarningsChart, isEqual)
// @ts-ignore
memoizedEarningsChart.whyDidYouRender = true

export default memoizedEarningsChart
