import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

import { AppState } from '..'
import { acceptOffer, AcceptOfferSuccess, rejectOffer, RejectOfferSuccess, expireCurrentUserOffer } from './actions'
import { fetchUserPreferences } from '../user_preferences/actions'
import { fetchUserClientOrders } from '../clientOrderAggregate/actions'
import { DEFAULT_OPEN_PAGE_SIZE } from '../../components/Orders/OrdersContainer'
import { updateNudgeTrigger } from '../nudges/actions'
import getFeatureFlags from '../../selectors/featureFlags'

export function acceptOfferThunk(
  userID: number,
  offerToken: string,
  offerID: number,
  onSuccessfulAccept?: () => void
): ThunkAction<Promise<AcceptOfferSuccess>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    // Accept the offer
    const result = ((await dispatch(acceptOffer(userID, offerToken, offerID))) as unknown) as AcceptOfferSuccess

    if (!!result && result.payload.status === 410) {
      dispatch(expireCurrentUserOffer(userID))
    } else if (onSuccessfulAccept) {
      onSuccessfulAccept()
    }

    const shoppingEligibility = getState().offerReducer.shoppingEligibility
    const { showNewOrderNudges } = getFeatureFlags(getState().userReducer.getLoggedInUser())
    if (!!shoppingEligibility && showNewOrderNudges) {
      updateNudgeTrigger(shoppingEligibility, userID)
    }

    // Update the user pay amount
    dispatch(fetchUserPreferences(userID))
    // Fetch new orders for the order2order and order list table
    dispatch(
      fetchUserClientOrders({
        status: ['open'],
        page_size: DEFAULT_OPEN_PAGE_SIZE,
        page: 1,
      })
    )

    return result
  }
}

export function rejectOfferThunk(
  userID: number,
  offerToken: string,
  offerID: number
): ThunkAction<Promise<RejectOfferSuccess>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    // Reject the offer
    const result = ((await dispatch(rejectOffer(userID, offerToken, offerID))) as unknown) as RejectOfferSuccess

    if (!!result && result.payload.status === 410) {
      dispatch(expireCurrentUserOffer(userID))
    }

    const shoppingEligibility = getState().offerReducer.shoppingEligibility
    const { showNewOrderNudges } = getFeatureFlags(getState().userReducer.getLoggedInUser())
    if (!!shoppingEligibility && showNewOrderNudges) {
      updateNudgeTrigger(shoppingEligibility, userID)
    }

    // Update the user pay amount
    dispatch(fetchUserPreferences(userID))

    return result
  }
}
