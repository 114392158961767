import { NudgesState, NudgeTypes } from './types'
import { NudgeActionType, NudgeActionTypes } from './actions'
import produce from 'immer'
import { initialLoadingErrorState } from '../../utils/state'
import { getClientUrl } from '../../utils/consts'

const initialState: NudgesState = {
  [NudgeTypes.MessageSent]: null,
  [NudgeTypes.NewOrders]: null,
  [NudgeTypes.MessageReceived]: {
    meta: initialLoadingErrorState,
    nudges: [],
    lastPoll: null,
  },
}

export enum WIPSegments {
  Low = 'Low',
  High = 'High',
  Reached = 'Reached',
}

export default function nudgesReducer(state: NudgesState = initialState, action: NudgeActionType) {
  return produce(state, (draft: NudgesState) => {
    switch (action.type) {
      case NudgeActionTypes.ADD_NUDGE:
        // @ts-ignore
        draft[action.nudge.type] = action.nudge
        break
      case NudgeActionTypes.REMOVE_MESSAGE_SENT_NUDGE:
        draft[NudgeTypes.MessageSent] = null
        break
      case NudgeActionTypes.REMOVE_NEW_ORDERS_NUDGE:
        draft[NudgeTypes.NewOrders] = null
        break
      case NudgeActionTypes.REMOVE_MESSAGE_RECEIVED_NUDGE:
        if (!!draft[NudgeTypes.MessageReceived].nudges) {
          // @ts-ignore
          draft[NudgeTypes.MessageReceived].nudges.shift()
        }
        break
      case NudgeActionTypes.FETCH_LATEST_CLIENT_MESSAGES:
        draft[NudgeTypes.MessageReceived].meta = {
          isLoading: true,
          error: null,
        }
        break
      case NudgeActionTypes.FETCH_LATEST_CLIENT_MESSAGES_SUCCESS:
        draft[NudgeTypes.MessageReceived].meta.isLoading = false
        const nudges = action.payload.data
        draft[NudgeTypes.MessageReceived].lastPoll = new Date()
        if (!!nudges && nudges.length > 0) {
          if (!draft[NudgeTypes.MessageReceived].nudges) {
            draft[NudgeTypes.MessageReceived].nudges = []
          }
          nudges.forEach(n => {
            // @ts-ignore
            draft[NudgeTypes.MessageReceived].nudges.push({
              type: NudgeTypes.MessageReceived,
              copyKey: n.unread_count > 1 ? 'nudge__messages_received' : 'nudge__message_received',
              variables: {
                ...n,
                link: getClientUrl(n.client_id, '/messages'),
              },
            })
          })
        }
        break
      case NudgeActionTypes.FETCH_LATEST_CLIENT_MESSAGES_FAIL: {
        draft[NudgeTypes.MessageReceived].meta = {
          isLoading: false,
          error: action.error,
        }
        break
      }
      case NudgeActionTypes.RESET_LATEST_CLIENT_MESSAGES_LAST_POLL:
        draft[NudgeTypes.MessageReceived].lastPoll = null
        break
    }
  })
}
