import { Note, NoteContexts, NotesState } from './types'
import { AppState } from '../index'
import { ClientsState, Client } from '../../store/clients/reducer'

export const getNotesForClient = (state: AppState, clientID: number): Note[] => {
  const client = state.clientReducer.clients[clientID]
  if (!client) {
    return []
  }

  const orderIDs = client.order_ids

  // Combine the order and client notes and sort them in descending order of created_at timestamp
  return [
    ...Object.values(
      orderIDs
        .map(id => state.notesReducer.order[id])
        .flat()
        .filter(x => x)
    ),
    ...Object.values(state.notesReducer.client[clientID] || {}),
    ...Object.values(state.notesReducer['user-client'][clientID] || {}),
  ].sort((a, b) => {
    const aDate = new Date(a.created_at)
    const bDate = new Date(b.created_at)

    if (aDate > bDate) {
      return -1
    }

    if (aDate < bDate) {
      return 1
    }

    return 0
  })
}

export const getAllNotesForContext = (state: NotesState, context: NoteContexts): Note[] => {
  return Object.values(state[context])
    .flatMap(contextGroup => Object.values(contextGroup))
    .filter(note => !note.deleted_at)
}

export const getCompletedNotes = (notes: Note[]): Note[] => notes.filter(note => !!note.completion_date)

export const getNotesDueSoon = (notes: Note[]): Note[] =>
  notes
    .filter(note => !!note.expiration_date && !note.completion_date)
    .sort((a, b) => {
      if (!a.expiration_date) {
        return 1
      }

      if (!b.expiration_date) {
        return -1
      }

      if (a.expiration_date < b.expiration_date) {
        return -1
      }

      if (a.expiration_date > b.expiration_date) {
        return 1
      }

      return a.id > b.id ? 1 : -1
    })

export const getNotesWithoutDueDates = (notes: Note[]): Note[] =>
  notes.filter(note => !note.expiration_date && !note.completion_date)

interface StructuredNotes {
  dueSoon: Note[]
  notDueSoon: Note[]
  completed: Note[]
}

export const getStructuredNotes = (notes: Note[]): StructuredNotes => {
  return {
    dueSoon: getNotesDueSoon(notes),
    notDueSoon: getNotesWithoutDueDates(notes),
    completed: getCompletedNotes(notes),
  }
}

interface GetNotesForUseNotesFilters {
  context: NoteContexts
  contextID?: number | null
  userID?: number | null
}

export const getNotesForUseNotes = (state: NotesState, filters: GetNotesForUseNotesFilters) => {
  let notes = getAllNotesForContext(state, filters.context)

  if (filters.userID) {
    notes = notes.filter(note => note.author_id === filters.userID)
  }

  // if we are on a client, we want to float all their notes to the top
  if (filters.contextID) {
    notes = notes.reduce((acc, note) => {
      if (note.context_id === filters.contextID) {
        return [note, ...acc]
      }

      return [...acc, note]
    }, [] as Note[])
  }

  return notes
}

export const getNoteByIDForContext = (state: NotesState, context: NoteContexts, id: number | null): Note | null => {
  if (!id) {
    return null
  }

  return getAllNotesForContext(state, context).find(note => note.id === id) || null
}

export interface NotesClientDropdown {
  id: number
  name: string
}

export const notesClientDropdown = (state: ClientsState): NotesClientDropdown[] => {
  return Object.values(state.clients).map((client: Client) => {
    return {
      id: client.id,
      name: client.full_name,
    }
  })
}
