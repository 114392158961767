import {
  AutoQAContactInfoFeature,
  AutoQAFeature,
  AutoQAFeatureKeys,
  AutoQAGrammarFeature,
  AutoQAImprovementStatus,
  AutoQALanguageFeature,
  AutoQAPhraseCollisionsFeature,
  AutoQAScanOutput,
  AutoQAScanResult,
  AutoQASectionType,
  ContactInfoDifference,
  GrammarAnalysisSection,
  LanguageAnalysisSection,
  PhraseAnalysisSection,
  ReadabilityAnalysisResult,
  ScanDetails,
  StemCount,
  UploadOverview,
} from '../store/uploads/types'
import { AutoQAResultProps } from '../components/UploadsV3/AutoQAResult'
import { DocumentState } from '../store/documents/types'
import i18n from '../i18n'
import { HashMap } from '../utils/HashMap'
import { FeatureFeedback } from '../store/featureFeedback/types'

export interface AutoQAResultGroupData {
  feature_id?: number
  feature_key?: string
  title: string
  tooltipText?: string
  content: AutoQAResultProps[]
  theme?: 'regular' | 'inverted'
}

export interface AutoQASectionData {
  title?: string
  count?: number
  score: number
  content: AutoQAResultGroupData[]
}

export const getAutoQAScanSectionsFromScanDetails = (
  autoQAScanResults: ScanDetails<AutoQAScanOutput> | null
): AutoQASectionData[] => {
  if (
    !autoQAScanResults ||
    !autoQAScanResults.result ||
    !autoQAScanResults.result.output ||
    'error' in autoQAScanResults.result.output
  ) {
    return []
  }

  return getAutoQAScanSections(autoQAScanResults.result.output)
}

export const getAutoQAScanTotalResult = (
  autoQAScanResults: ScanDetails<AutoQAScanOutput> | null
): AutoQAScanResult | null => {
  if (
    !autoQAScanResults ||
    !autoQAScanResults.result ||
    !autoQAScanResults.result.output ||
    'error' in autoQAScanResults.result.output
  ) {
    return null
  }

  return autoQAScanResults.result.output.total_result
}

export const getAutoQAImprovementsSectionsFromScanDetails = (
  autoQAScanResults: ScanDetails<AutoQAScanOutput> | null,
  feedback: HashMap<HashMap<FeatureFeedback>>
): AutoQASectionData[] => {
  if (
    !autoQAScanResults ||
    !autoQAScanResults.result ||
    !autoQAScanResults.result.output ||
    'error' in autoQAScanResults.result.output
  ) {
    return []
  }

  return getConfirmedAutoQAScanResults(autoQAScanResults.result.output, feedback)
}

export const getAutoQAScanSections = (output: AutoQAScanOutput): AutoQASectionData[] => {
  const wordAnalysisSection = constructWordChoiceSection(
    output.results.filter(r =>
      [
        AutoQAFeatureKeys.LanguagePersonal,
        AutoQAFeatureKeys.LanguageRepetition,
        AutoQAFeatureKeys.LanguageWeak,
        AutoQAFeatureKeys.LanguageGrammar,
        AutoQAFeatureKeys.LanguageReadability,
      ].includes(r.feature)
    ) as AutoQALanguageFeature[]
  )

  const phraseAnalysisSection = constructPhraseChoiceSection(
    output.results.filter(r =>
      [AutoQAFeatureKeys.LanguageTemplates, AutoQAFeatureKeys.LanguageVerbatim].includes(r.feature)
    ) as AutoQAPhraseCollisionsFeature[]
  )

  const contactInfoSection = constructContactInfoSection(
    output.results.filter(r => AutoQAFeatureKeys.ClientContactInfo === r.feature) as AutoQAContactInfoFeature[]
  )

  const sorted = [...wordAnalysisSection, ...phraseAnalysisSection].sort((a: AutoQASectionData, b: AutoQASectionData) =>
    a.score > b.score ? -1 : 1
  )

  // Contact Info should go first
  return [...contactInfoSection, ...sorted]
}

export const getConfirmedAutoQAScanResults = (
  output: AutoQAScanOutput,
  feedback: HashMap<HashMap<FeatureFeedback>>
): AutoQASectionData[] => {
  const wordAnalysisSection = constructWordChoiceImprovementsSection(
    output.results.filter(r =>
      [
        AutoQAFeatureKeys.LanguagePersonal,
        AutoQAFeatureKeys.LanguageRepetition,
        AutoQAFeatureKeys.LanguageWeak,
      ].includes(r.feature)
    ) as AutoQALanguageFeature[],
    feedback
  )

  const phraseAnalysisSection = constructPhraseChoiceImprovementsSection(
    output.results.filter(r =>
      [AutoQAFeatureKeys.LanguageTemplates, AutoQAFeatureKeys.LanguageVerbatim].includes(r.feature)
    ) as AutoQAPhraseCollisionsFeature[],
    feedback
  )

  const clientInfoSection = constructContactInfoImprovementsSection(
    output.results.filter(r => AutoQAFeatureKeys.ClientContactInfo === r.feature) as AutoQAContactInfoFeature[],
    feedback
  )

  const sorted = [...wordAnalysisSection, ...phraseAnalysisSection].sort((a: AutoQASectionData, b: AutoQASectionData) =>
    a.score > b.score ? -1 : 1
  )

  return [...clientInfoSection, ...sorted]
}

export const getAutoQAScanSectionsFromDocuments = (state: DocumentState, fileID: number): AutoQASectionData[] => {
  if (state.autoQA[fileID]?.autoQA) {
    // @ts-ignore
    return getAutoQAScanSections(state.autoQA[fileID].autoQA)
  }

  return []
}

// This is expected to return 1 group with maximum 3 subgroups (+ 2 dev only), but in case none of the subgroups have issues
// it returns an empty array
export const constructWordChoiceSection = (
  scanResults: (AutoQALanguageFeature | AutoQAGrammarFeature)[]
): AutoQASectionData[] => {
  if (
    scanResults.every(
      r =>
        // @ts-ignore
        (!!r.details.stems && r.details.stems.length === 0) ||
        // @ts-ignore
        (!!r.details.sentences && r.details.sentences.length === 0) ||
        // @ts-ignore
        (!!r.details.sections && r.details.sections.length === 0)
    )
  ) {
    return []
  }

  const groups = scanResults
    .map(scan => {
      return {
        feature_id: scan.id,
        feature_key: `${scan.feature}`,
        tooltipText: i18n.t(`auto_qa__group_tooltip_text__${scan.feature}`),
        title: i18n.t(`auto_qa__group_title__${scan.feature}`),
        content: getFormattedResultBits(scan.details, scan.feature, scan.id),
      }
    })
    .filter(g => g.content.length > 0)

  if (groups.length === 0) {
    return []
  }

  return [
    {
      title: i18n.t('auto_qa__section_title__word_choice'),
      count: scanResults
        // @ts-ignore
        .map(x => x.details?.stems?.length || x.details?.sentences?.length || x.details?.sections?.length || 0)
        .reduce((acc, y) => acc + y),
      content: groups,
      score: scanResults
        .filter(r => !!r && !!r.details)
        .map(x => x.details.total_score)
        .reduce((acc, y) => acc + y),
    },
  ]
}

// This unites verbatim and template usage into one section. Each instance has its own feedback
export const constructPhraseChoiceSection = (scanResults: AutoQAPhraseCollisionsFeature[]): AutoQASectionData[] => {
  if (scanResults.every(scan => !!scan.details && scan.details.error_count === 0)) {
    return []
  }

  const verbatimResults = scanResults.filter(r => r.feature === AutoQAFeatureKeys.LanguageVerbatim)
  const templatesResults = scanResults.filter(r => r.feature === AutoQAFeatureKeys.LanguageTemplates)

  const groups = [
    ...constructPhraseResultGroup(verbatimResults, AutoQAFeatureKeys.LanguageVerbatim),
    ...constructPhraseResultGroup(templatesResults, AutoQAFeatureKeys.LanguageTemplates),
  ]

  if (groups.length === 0) {
    return []
  }

  return [
    {
      title: i18n.t('auto_qa__section_title__originality'),
      count: scanResults.map(x => x.details.error_count).reduce((acc, y) => acc + y),
      content: groups,
      score: scanResults.map(x => x.details.total_score).reduce((acc, y) => acc + y),
    },
  ]
}

export const constructContactInfoSection = (scanResults: AutoQAContactInfoFeature[]): AutoQASectionData[] => {
  if (scanResults.length !== 1) {
    return []
  }
  const scanResult = scanResults[0]
  if (!scanResult.details.differences || scanResult.details.differences.length === 0) {
    return []
  }

  return [
    {
      title: i18n.t('auto_qa__section_title__contact_info'),
      count: scanResult.details.differences.length,
      score: scanResult.details.total_score,
      content: [
        {
          feature_id: scanResult.id,
          feature_key: scanResult.feature,
          title: i18n.t(`auto_qa__group_title__${scanResult.feature}`),
          tooltipText: i18n.t(`auto_qa__group_tooltip_text__${scanResult.feature}`),
          content: scanResult.details.differences.map(r => {
            return {
              feature_id: scanResult.id,
              feature_key: constructAutoQAFeatureFeedbackElement(scanResult.feature, r.key),
              title: r.key.replace('_', ' '),
              content: [
                {
                  text: r.draft,
                  header: i18n.t(`auto_qa__result_title__draft`),
                  leftTabulated: true,
                },
                {
                  text: r.orig,
                  header: i18n.t(`auto_qa__result_title__original`),
                  leftTabulated: true,
                },
              ],
            }
          }),
        },
      ],
    },
  ]
}

export const constructRepetitionResults = (
  section: LanguageAnalysisSection,
  featureKey: AutoQAFeatureKeys,
  feature_id: number
): AutoQAResultProps[] => {
  return section.stems && section.stems.length > 0
    ? section.stems.map(stem => {
        return {
          feature_key: constructAutoQAFeatureFeedbackElement(featureKey, stem.stem),
          feature_id: feature_id,
          title: i18n.t('auto_qa__result_header__repetition', { stem: getStemBasicForm(stem), count: stem.count }),
          content: [
            {
              header: i18n.t('auto_qa__result_bit_header__repetition'),
              text: stem.occurrences.join(', '),
              leftTabulated: true,
            },
          ],
        }
      })
    : []
}

export const constructPronounResults = (
  section: LanguageAnalysisSection,
  featureKey: AutoQAFeatureKeys,
  feature_id: number
): AutoQAResultProps[] => {
  return section.stems && section.stems.length > 0
    ? section.stems.map(stem => {
        return {
          feature_id: feature_id,
          feature_key: constructAutoQAFeatureFeedbackElement(featureKey, stem.stem),
          title: i18n.t('auto_qa__result_header__personal', { score: stem.count }),
          content: [
            {
              header: i18n.t('auto_qa__result_bit_header__personal'),
              text: stem.occurrences.join(', '),
              leftTabulated: true,
            },
          ],
        }
      })
    : []
}

export const constructGrammarResults = (
  section: GrammarAnalysisSection,
  featureKey: AutoQAFeatureKeys,
  feature_id: number
): AutoQAResultProps[] => {
  return section.sentences && section.sentences.length > 0
    ? section.sentences.map((sentence, index) => {
        return {
          feature_id: feature_id,
          feature_key: constructAutoQAFeatureFeedbackElement(featureKey, index),
          title: sentence.reasons.join(', '),
          content: [
            {
              text: sentence.sentence,
            },
          ],
        }
      })
    : []
}

export const constructReadabilityResults = (
  section: ReadabilityAnalysisResult,
  featureKey: AutoQAFeatureKeys,
  feature_id: number
): AutoQAResultProps[] => {
  return section.sections && section.sections.length > 0
    ? section.sections.map((section, index) => {
        return {
          feature_id: feature_id,
          feature_key: constructAutoQAFeatureFeedbackElement(featureKey, index),
          title: i18n.t('auto_qa__readability__score', { score: section.score.toString() }),
          content: [
            {
              text: section.text,
            },
          ],
        }
      })
    : []
}

export const constructWeakResults = (
  section: LanguageAnalysisSection,
  featureKey: AutoQAFeatureKeys,
  feature_id: number
): AutoQAResultProps[] => {
  return section.stems && section.stems.length > 0
    ? section.stems.map(stem => {
        return {
          feature_id: feature_id,
          feature_key: constructAutoQAFeatureFeedbackElement(featureKey, stem.stem),
          title: i18n.t('auto_qa__result_explanation__weak'),
          content: [
            {
              text: getStemBasicForm(stem),
              leftTabulated: true,
            },
          ],
        }
      })
    : []
}
export const constructPhraseResultGroup = (
  section: AutoQAPhraseCollisionsFeature[],
  featureKey: AutoQAFeatureKeys
): AutoQAResultGroupData[] => {
  if (section.every(s => s.details.error_count === 0)) {
    return []
  }

  const instances = section.map(r => r.details.phrases.map(p => p.phrase)).flat()

  return [
    {
      feature_id: section[0].id,
      feature_key: featureKey,
      title: i18n.t(`auto_qa__group_title__${featureKey}`),
      tooltipText: i18n.t(`auto_qa__group_tooltip_text__${featureKey}`),
      content: instances.map((r, index) => {
        return {
          feature_id: section[0].id,
          feature_key: constructAutoQAFeatureFeedbackElement(featureKey, index),
          content: [
            {
              text: r,
              header: i18n.t(`auto_qa__result_title__${featureKey}`),
              leftTabulated: true,
            },
          ],
        }
      }),
    },
  ]
}

export const getFormattedResultBits = (
  section: LanguageAnalysisSection | GrammarAnalysisSection | ReadabilityAnalysisResult,
  featureKey: AutoQAFeatureKeys,
  featureID: number
): AutoQAResultProps[] => {
  if (
    !section ||
    (!(section as LanguageAnalysisSection).stems &&
      !(section as GrammarAnalysisSection).sentences &&
      !(section as ReadabilityAnalysisResult).sections)
  ) {
    return []
  }
  switch (featureKey) {
    case AutoQAFeatureKeys.LanguageRepetition:
      return constructRepetitionResults(section as LanguageAnalysisSection, featureKey, featureID)
    case AutoQAFeatureKeys.LanguageWeak:
      return constructWeakResults(section as LanguageAnalysisSection, featureKey, featureID)
    case AutoQAFeatureKeys.LanguagePersonal:
      return constructPronounResults(section as LanguageAnalysisSection, featureKey, featureID)
    case AutoQAFeatureKeys.LanguageGrammar:
      return constructGrammarResults(section as GrammarAnalysisSection, featureKey, featureID)
    case AutoQAFeatureKeys.LanguageReadability:
      return constructReadabilityResults(section as ReadabilityAnalysisResult, featureKey, featureID)

    default:
      return []
  }
}

export const getSectionResult = (autoQAScanResults: AutoQAFeature[], section: AutoQASectionType): AutoQAScanResult => {
  let sectionFeatures: AutoQAFeatureKeys[] = []
  switch (section) {
    case AutoQASectionType.Readability:
      sectionFeatures = [
        AutoQAFeatureKeys.LanguagePersonal,
        AutoQAFeatureKeys.LanguageRepetition,
        AutoQAFeatureKeys.LanguageWeak,
      ]
      break
    case AutoQASectionType.ContactInfo:
      sectionFeatures = [AutoQAFeatureKeys.ClientContactInfo]
      break
    case AutoQASectionType.Originality:
      sectionFeatures = [AutoQAFeatureKeys.LanguageVerbatim, AutoQAFeatureKeys.LanguageTemplates]
      break
  }

  const results = autoQAScanResults.filter(r => sectionFeatures.includes(r.feature)).map(s => s.result)

  if (results.includes(AutoQAScanResult.Fail)) {
    return AutoQAScanResult.Fail
  }
  if (results.includes(AutoQAScanResult.Warning)) {
    return AutoQAScanResult.Warning
  }

  return AutoQAScanResult.Pass
}

export const getDocImprovements = (autoQAScanResults: AutoQAFeature[]): string[] => {
  const improved = autoQAScanResults.filter(r => r.improvements === AutoQAImprovementStatus.Better)

  if (improved.length === 0) {
    return []
  }
  return improved.map(i => i18n.t(`uploadsv3__autoqa__improvements__${i.feature}`))
}

export const getAutoQAFeatures = (scan: ScanDetails<AutoQAScanOutput> | null): AutoQAFeature[] => {
  let features: AutoQAFeature[] = []
  const output = scan?.result?.output

  if (!!output && 'results' in output) {
    features = output.results
  }

  return features
}

export const getStemBasicForm = (stemCount: StemCount): string => {
  if (stemCount.occurrences.length === 0) {
    return stemCount.stem
  }
  return stemCount.occurrences.reduce(function(a, b) {
    return a.length <= b.length ? a : b
  })
}

export const constructWordChoiceImprovementsSection = (
  scanResults: AutoQALanguageFeature[],
  feedback: HashMap<HashMap<FeatureFeedback>>
): AutoQASectionData[] => {
  if (scanResults.every(r => !!r.details.stems && r.details.stems.length === 0)) {
    return []
  }

  const content = scanResults.map(scan => getFormattedResultBits(scan.details, scan.feature, scan.id)).flat()
  const filtered = content.filter(
    c =>
      c.feature_id &&
      feedback[c.feature_id] &&
      !!Object.values(feedback[c.feature_id]).find(f => f.feature_element === c.feature_key) &&
      // @ts-ignore
      Object.values(feedback[c.feature_id]).find(f => f.feature_element === c.feature_key).feedback_bool === 1
  )

  if (filtered.length === 0) {
    return []
  }

  return [
    {
      score: scanResults.map(x => x.details.total_score).reduce((acc, y) => acc + y),
      content: [
        {
          title: i18n.t(`auto_qa__group_title__readability`),
          content: filtered,
        },
      ],
    },
  ]
}

export const constructPhraseChoiceImprovementsSection = (
  scanResults: AutoQAPhraseCollisionsFeature[],
  feedback: HashMap<HashMap<FeatureFeedback>>
): AutoQASectionData[] => {
  if (scanResults.every(scan => !!scan.details && scan.details.error_count === 0)) {
    return []
  }

  const verbatimResults = scanResults.filter(r => r.feature === AutoQAFeatureKeys.LanguageVerbatim)
  const templatesResults = scanResults.filter(r => r.feature === AutoQAFeatureKeys.LanguageTemplates)

  const verbatims = getConfirmedPhraseResults(verbatimResults, AutoQAFeatureKeys.LanguageVerbatim, feedback)
  const templates = getConfirmedPhraseResults(templatesResults, AutoQAFeatureKeys.LanguageTemplates, feedback)

  const content = [...verbatims, ...templates]

  if (content.length === 0) {
    return []
  }

  return [
    {
      score: scanResults.map(x => x.details.total_score).reduce((acc, y) => acc + y),
      content: [
        {
          title: i18n.t(`auto_qa__group_title__originality`),
          content: content,
        },
      ],
    },
  ]
}

export const getConfirmedPhraseResults = (
  section: AutoQAPhraseCollisionsFeature[],
  featureKey: AutoQAFeatureKeys,
  feedback: HashMap<HashMap<FeatureFeedback>>
): AutoQAResultProps[] => {
  if (section.every(s => s.details.error_count === 0)) {
    return []
  }

  const instances = section.map(r => r.details.phrases.map(p => p.phrase)).flat()

  return instances
    .map((r, index) => {
      return {
        feature_id: section[0].id,
        feature_key: constructAutoQAFeatureFeedbackElement(featureKey, index),
        content: [
          {
            text: r,
            header: i18n.t(`auto_qa__result_title__${featureKey}`),
            leftTabulated: true,
          },
        ],
      }
    })
    .filter(
      c =>
        c.feature_id &&
        feedback[c.feature_id] &&
        !!Object.values(feedback[c.feature_id]).find(f => f.feature_element === c.feature_key) &&
        // @ts-ignore
        Object.values(feedback[c.feature_id]).find(f => f.feature_element === c.feature_key).feedback_bool === 1
    )
}

export const constructContactInfoImprovementsSection = (
  scanResults: AutoQAContactInfoFeature[],
  feedback: HashMap<HashMap<FeatureFeedback>>
): AutoQASectionData[] => {
  if (scanResults.length !== 1) {
    return []
  }
  const scanResult = scanResults[0]
  if (!scanResult.details.differences || scanResult.details.differences.length === 0) {
    return []
  }

  const content = filterContactInfoDifferences(scanResult.details.differences)
    .map(r => {
      return {
        feature_id: scanResult.id,
        feature_key: constructAutoQAFeatureFeedbackElement(scanResult.feature, r.key),
        title: r.key.replace('_', ' '),
        content: [
          {
            text: r.draft,
            header: i18n.t(`auto_qa__result_title__draft`),
            leftTabulated: true,
          },
          {
            text: r.orig,
            header: i18n.t(`auto_qa__result_title__original`),
            leftTabulated: true,
          },
        ],
      }
    })
    .filter(
      c =>
        c.feature_id &&
        feedback[c.feature_id] &&
        !!Object.values(feedback[c.feature_id]).find(f => f.feature_element === c.feature_key) &&
        // @ts-ignore
        Object.values(feedback[c.feature_id]).find(f => f.feature_element === c.feature_key).feedback_bool === 1
    )

  if (content.length === 0) {
    return []
  }

  return [
    {
      score: scanResult.details.total_score,
      content: [
        {
          title: i18n.t(`auto_qa__improvements_group_title__contact_info`),
          content: content,
        },
      ],
    },
  ]
}

export const getAutoQAIDsFromScans = (scans: UploadOverview[]): number[] => {
  try {
    return (
      scans
        .filter(s => !!s.preprocess?.auto_qa_async)
        // @ts-ignore
        .map(s => (s.preprocess?.auto_qa_async.result?.output as AutoQAScanOutput).results.map(r => r.id))
        .flat()
    )
  } catch (e) {
    return []
  }
}

const ImportantVerbatimResultThreshold = 1.3

export const filterUnimportantResults = (output: AutoQAScanOutput): AutoQAScanOutput => {
  const imperfectFeatures = output.results.filter(
    r => r.result === AutoQAScanResult.Fail || r.result === AutoQAScanResult.Warning
  )

  if (
    !!imperfectFeatures.find(f => f.feature === AutoQAFeatureKeys.LanguageVerbatim) &&
    imperfectFeatures.length === 1
  ) {
    const verbatim = imperfectFeatures.find(
      f => f.feature === AutoQAFeatureKeys.LanguageVerbatim
    ) as AutoQAPhraseCollisionsFeature

    const importantVerbatims = verbatim.details.phrases.filter(x => x.score > ImportantVerbatimResultThreshold)

    if (importantVerbatims.length > 0) {
      const adjustedVerbatims: PhraseAnalysisSection = {
        phrases: importantVerbatims,
        total_score: importantVerbatims.reduce((acc, val) => acc + val.score, 0),
        error_count: importantVerbatims.length,
      }

      output = {
        ...output,
        results: [
          ...output.results.filter(x => x.feature !== AutoQAFeatureKeys.LanguageVerbatim),
          {
            ...output.results.find(x => x.feature === AutoQAFeatureKeys.LanguageVerbatim),
            // @ts-ignore
            details: adjustedVerbatims,
          },
        ],
      }
    }
  }

  if (imperfectFeatures.find(f => f.feature === AutoQAFeatureKeys.ClientContactInfo)) {
    const contactInfoFeature = imperfectFeatures.find(
      f => f.feature === AutoQAFeatureKeys.ClientContactInfo
    ) as AutoQAContactInfoFeature

    const diff = filterContactInfoDifferences(contactInfoFeature.details.differences || [])

    output = {
      ...output,
      results: [
        ...output.results.filter(x => x.feature !== AutoQAFeatureKeys.ClientContactInfo),
        {
          ...output.results.find(x => x.feature === AutoQAFeatureKeys.ClientContactInfo),
          // @ts-ignore
          details: {
            total_score: diff.length,
            differences: diff,
          },
        },
      ],
    }
  }

  return output
}

export const constructAutoQAFeatureFeedbackElement = (featureKey: string, resultIdentifier: string | number) => {
  return `${featureKey}.${resultIdentifier}`
}

export const filterContactInfoDifferences = (differences: ContactInfoDifference[]): ContactInfoDifference[] => {
  const hasBothFirstAndLastName =
    differences.find(d => d.key === 'first_name') && differences.find(d => d.key === 'last_name')

  const hasFirstOrLastName = differences.find(d => d.key === 'first_name' || d.key === 'last_name')

  return hasBothFirstAndLastName
    ? differences.filter(d => !['first_name', 'last_name'].includes(d.key))
    : hasFirstOrLastName
    ? differences.filter(d => 'name' !== d.key)
    : differences
}
