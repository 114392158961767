import React from 'react'
import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import CloseButton from './CloseButton'
import FloatingNotesButton from './FloatingNotesButton'
import NoteForm from './NoteForm'
import NotesList from './NotesList'
import useNotes from './useNotes'
import { AppState } from '../../store'
import { NoteContexts } from '../../store/notes/types'
import { getNotesDueSoon } from '../../store/notes/selectors'
import { Client } from '../../store/clients/reducer'
import { clientByIdSelector } from '../../selectors/clients'
import NotesStyles from './Styles'
import styles from '../../styles'

const useStyles = makeStyles(theme =>
  createStyles({
    drawer: {
      color: NotesStyles.darkText,
      fontSize: NotesStyles.fontSize,
      zIndex: 1400,
    },
    drawerPaper: {
      borderTop: NotesStyles.border,
      bottom: '0',
      height: NotesStyles.fullHeight,
      top: styles.sizes.topNavHeight,
      width: '49.1em',
      zIndex: 1400,
    },
    drawerWrapper: {
      display: 'flex',
      height: NotesStyles.fullHeight,
      width: NotesStyles.fullWidth,
    },
    list: {
      borderRight: NotesStyles.border,
      display: 'flex',
      flexDirection: 'column',
      height: NotesStyles.fullHeight,
      width: '19.5em',
    },
    form: {
      color: 'inherit',
      display: 'flex',
      flexDirection: 'column',
      width: NotesStyles.fullWidth,

      '&form': {
        height: NotesStyles.fullHeight,
      },
    },
    [theme.breakpoints.down('sm')]: {
      drawerPaper: {
        width: NotesStyles.fullWidth,
      },
      drawerWrapper: {
        display: 'block',
      },
      form: {
        backgroundColor: 'white',
        borderRadius: '1.6em 1.6em 0 0',
        bottom: '0',
        boxShadow: NotesStyles.shadow2,
        position: 'fixed',
        zIndex: '1400',
      },
      list: {
        width: NotesStyles.fullWidth,
      },
    },
  })
)

const NotesDrawer: React.FC = () => {
  // state
  const classes = useStyles({})
  const {
    openDrawer,
    closeDrawer,
    isOpen,
    saveNote,
    notes,
    selectNote,
    selectedNote,
    deleteSelectedNote,
    addNote,
    completeSelectedNote,
    contextID,
    isFormOpen,
    closeForm,
  } = useNotes(NoteContexts.userClient)

  // Selectors
  const currentClient = useSelector<AppState, Client | null>(
    state => (!!contextID ? clientByIdSelector(state.clientReducer, contextID) : null),
    isEqual
  )

  if (!isOpen) {
    return <FloatingNotesButton onClick={openDrawer} notificationCount={getNotesDueSoon(notes).length} />
  }

  return (
    <Drawer
      open={true}
      onClose={closeDrawer}
      anchor="right"
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      style={{ zIndex: 1400, position: 'absolute' }}
    >
      <Box className={classes.drawerWrapper}>
        <Box className={classes.list}>
          <NotesList
            notes={notes}
            selectedNote={selectedNote}
            selectNote={selectNote}
            addNote={addNote}
            canAddNote={!!contextID}
          />
        </Box>
        <Box className={classes.form}>
          {(currentClient || selectedNote) && (
            <NoteForm
              variant={selectedNote ? 'update-note' : 'new-note'}
              saveNote={saveNote}
              currentClient={currentClient}
              selectedNote={selectedNote}
              deleteSelectedNote={deleteSelectedNote}
              completeSelectedNote={completeSelectedNote}
              isFormOpen={isFormOpen}
              closeForm={closeForm}
            />
          )}
        </Box>
      </Box>
      <CloseButton onClick={closeDrawer} />
    </Drawer>
  )
}

// @ts-ignore
NotesDrawer.whyDidYouRender = true

export default NotesDrawer
