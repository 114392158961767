import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import i18n from '../../i18n'
import MessageAttachments from './MessageAttachments'
import MessageInput from './MessageInput'
import MessagePreview from './MessagePreview'
import MessageToolbox from './MessageToolbox'
import MalwareDialog from './MalwareDialog'
import { AppState } from '../../store'
import { MessageContext } from '../../store/messages/types'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Document } from '../../store/documents/types'
import {
  currentMessageSend,
  currentMessageUpdate,
  fillInEmptyCurrentMessage,
  malwareScan,
} from '../../store/currentMessage/actions'
import { CurrentMessage } from '../../store/currentMessage/types'
import { ScanVirusRequest, ScanVirusResponse } from '../../store/messages/types'
import { messagesMetaReset } from '../../store/messages/actions'
import { getAttachmentsForCurrentMessage } from '../../selectors/documents'
import { currentMessageNeedsFilledIn } from '../../selectors/messages'
import { handlebarsTemplateContextSelector } from '../../selectors/templates'
import { HandlebarsContextType } from '../../utils/handlebars'
import { Box } from '@material-ui/core'
import { markTheLatestMessageAsRead } from '../../store/messages/actions'
import TemplatesContainer from './TemplateContainer'
import StrategyContainer from './StrategyContainer'
import ErrorNotification from '../common/ErrorNotification'
import useEditorState, { MessageDraftStorageKey } from './useEditorState'
import { useOrderCarousel } from '../OrderFamily/SideBar/useOrderCarousel'
import useUserInfoState from '../common/useUserInfo'
import { useIntercomTracking } from '../common/IntercomContainer'
import { DraftEventVars } from '../../store/events/types'
import { ProposeCallTimeContainer } from './ProposeCallTimeContainer'

export interface MessageCompositionWindowProps {
  context: MessageContext
  contextID: number
  orderID?: number | null
  userID?: number
  openMessages?: [boolean, () => void]
  openUploads?: [boolean, () => void]
  openClientStrategy?: [boolean, () => void]
  sendMessageCallback?: (messageID: number) => void
  closeWindow?: () => void
  isClientStrategyHighlighted?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    messageComposition: {},
  })
)

export const MessageCompositionWindow: React.FC<MessageCompositionWindowProps> = ({
  context,
  contextID,
  orderID = null,
  userID = null,
  openUploads,
  openClientStrategy,
  sendMessageCallback,
  closeWindow,
  isClientStrategyHighlighted = false,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const currentMessage = useSelector<AppState, CurrentMessage>(state => state.currentMessageReducer)
  const orderCarousel = useOrderCarousel(contextID)
  const currentAttachments = useSelector<AppState, Document[]>(state =>
    getAttachmentsForCurrentMessage(state.documentReducer, context, contextID, currentMessage.attachment_ids)
  )
  const handlebarsContext = useSelector<AppState, HandlebarsContextType>(state =>
    handlebarsTemplateContextSelector(state, context, contextID, userID, currentAttachments, orderID)
  )

  const { featureFlags } = useUserInfoState()
  const messageNeedsFilledIn = useSelector<AppState, boolean>(state =>
    currentMessageNeedsFilledIn(state.currentMessageReducer)
  )
  const isSending = useSelector<AppState, boolean>((state: AppState) => state.messagesReducer.sendingMeta.isLoading)
  const [toolbarVisible, setToolbarVisible] = useState(false)
  const { clearEditorState, hasText, hasPlaceholderText, editorState } = useEditorState()
  const hasSubject = currentMessage.subject.length > 0
  const isValid = hasText && hasSubject && !hasPlaceholderText
  const [showEmailPreview, setShowEmailPreview] = useState(false)
  const [showMalwareModal, setShowMalwareModal] = useState(false)
  const [maliciousUrls, setMaliciousUrls] = useState<string[]>([])

  const userInfo = useUserInfoState()
  const sendFirstDraftEvent = useIntercomTracking('First Draft Submitted')
  const sendFulfilledEvent = useIntercomTracking('Order Completed')

  const formattedRecpientEmailAddress = handlebarsContext.client
    ? `${handlebarsContext.client.full_name} <${currentMessage.recipients[0]}>`
    : currentMessage.recipients[0]

  const [hasSendingError, setHasSendingError] = React.useState('')

  useEffect(() => {
    // We must set the sender and recpient
    if (messageNeedsFilledIn) {
      dispatch(fillInEmptyCurrentMessage(context, contextID, orderID, userID))
    }

    return function cleanup() {
      clearEditorState(false)
    }
  }, [dispatch, messageNeedsFilledIn, context, contextID, orderID, userID, clearEditorState])

  const sendDraftEventToIntercom = useCallback(
    (meta: DraftEventVars) => {
      if (meta.is_first_draft) {
        sendFirstDraftEvent({ orderID: orderID, clientID: contextID })
      } else if (meta.client_fulfilled) {
        sendFulfilledEvent({ orderID: orderID, clientID: contextID })
      }
    },
    [sendFirstDraftEvent, sendFulfilledEvent, orderID, contextID]
  )

  const setSubject = React.useCallback(
    (subject: string) => {
      dispatch(
        currentMessageUpdate({
          subject,
        })
      )
    },
    [dispatch]
  )

  const handleSubmit = React.useCallback(async () => {
    let messageID: number | null = null

    try {
      const plainText: string = editorState.getCurrentContent().getPlainText()
      if (plainText.search(/system.?\(+/i) >= 0) {
        setHasSendingError(i18n.t('notification__error__messageSend_exceptions') as string)
        return
      }
      const request: ScanVirusRequest = { text: plainText }

      // @ts-ignore
      const scanVirusResponse = (await dispatch(malwareScan(request))) as ScanVirusResponse

      if (!scanVirusResponse.clean_result) {
        await dispatch(messagesMetaReset())

        setMaliciousUrls(scanVirusResponse.found_urls)
        setShowMalwareModal(true)
        return
      }

      if (userInfo.isNewHire) {
        // @ts-ignore
        messageID = await dispatch(currentMessageSend(context, contextID, orderID, userID, sendDraftEventToIntercom))
      } else {
        // @ts-ignore
        messageID = await dispatch(currentMessageSend(context, contextID, orderID, userID))
      }
    } catch (e) {
      setHasSendingError(i18n.t('notification__error__messageSend') as string)
      return
    }

    dispatch(markTheLatestMessageAsRead(context, contextID))

    // @TODO Wire up a success state here
    // Only if the message sent successfully do we clear the composition box
    // Close the window to make room for notifications
    closeWindow && closeWindow()
    clearEditorState()
    localStorage.removeItem(MessageDraftStorageKey)

    if (sendMessageCallback && messageID) {
      sendMessageCallback(messageID)
    }
  }, [
    dispatch,
    context,
    contextID,
    orderID,
    userID,
    sendMessageCallback,
    clearEditorState,
    editorState,
    closeWindow,
    sendDraftEventToIntercom,
    userInfo.isNewHire,
  ])

  const removeAttachment = React.useCallback(
    (attachmentID: number) => {
      dispatch(
        currentMessageUpdate({
          attachment_ids: currentMessage.attachment_ids.filter(aID => aID !== attachmentID),
        })
      )
    },
    [dispatch, currentMessage.attachment_ids]
  )

  const openMessagePreview = React.useCallback(() => setShowEmailPreview(true), [setShowEmailPreview])

  const templatesComponent = React.useMemo(
    () => (
      <TemplatesContainer
        context={context}
        contextID={contextID}
        orderID={orderID}
        CTA={orderCarousel.currentOrderGroup.macroState.CTA}
      />
    ),
    [context, contextID, orderID, orderCarousel.currentOrderGroup.macroState.CTA]
  )

  const strategyComponent = React.useMemo(
    () => (
      <StrategyContainer
        context={context}
        contextID={contextID}
        orderID={orderID}
        isHighlighted={isClientStrategyHighlighted}
      />
    ),
    [context, contextID, orderID, isClientStrategyHighlighted]
  )

  const proposeCallTimeComponent = React.useMemo(
    () => (
      <ProposeCallTimeContainer
        orderID={orderID}
        context={context}
        contextID={contextID}
        showEmailPreview={() => setShowEmailPreview(true)}
        sendMessage={handleSubmit}
      />
    ),
    [orderID, context, contextID, handleSubmit]
  )

  return (
    <>
      <Box className={classes.messageComposition}>
        <MessageInput
          subject={currentMessage.subject}
          setSubject={setSubject}
          toolbarVisible={toolbarVisible}
          emailAddress={formattedRecpientEmailAddress}
          bccEmailAddress={handlebarsContext.client?.bcc_email}
          handleClose={closeWindow}
        />

        <MessageAttachments attachments={currentAttachments} removeAttachment={removeAttachment} />
        <MessageToolbox
          handleSubmit={handleSubmit}
          submitDisabled={!isValid || isSending}
          toolbarVisible={toolbarVisible}
          setToolbarVisible={setToolbarVisible}
          openUploads={openUploads}
          openClientStrategy={openClientStrategy}
          openMessagePreview={openMessagePreview}
          isSending={isSending}
          templatesComponent={templatesComponent}
          strategyComponent={strategyComponent}
          proposeCallTimeComponent={featureFlags.showScheduler ? proposeCallTimeComponent : undefined}
        />
        {showEmailPreview && (
          <MessagePreview
            message={currentMessage}
            handlebarsContext={handlebarsContext}
            closeWindow={() => setShowEmailPreview(false)}
          />
        )}
      </Box>
      {!!hasSendingError && <ErrorNotification message={hasSendingError} handleClose={() => setHasSendingError('')} />}
      <MalwareDialog
        isOpen={showMalwareModal}
        handleClose={() => setShowMalwareModal(false)}
        maliciousUrls={maliciousUrls}
      />
    </>
  )
}

function propsAreEqual(prev: MessageCompositionWindowProps, next: MessageCompositionWindowProps) {
  return (
    prev.context === next.context &&
    prev.contextID === next.contextID &&
    prev.orderID === next.orderID &&
    prev.userID === next.userID
  )
}

const memoizedMessageCompositionWindow = React.memo(MessageCompositionWindow, propsAreEqual)
// @ts-ignore
memoizedMessageCompositionWindow.whyDidYouRender = true

export default memoizedMessageCompositionWindow
