import * as stateUtils from '../../utils/state'
import {
  initialLoadedLoadingErrorState,
  initialMultiLoadedLoadingErrorState,
  setErrorState,
  setErrorStateForItem,
  setStartState,
  setStartStateForItem,
  setSuccessState,
  setSuccessStateForItem,
} from '../../utils/state'
import { AUTO_RESEARCH, AutoResearchActionTypes } from './actions'
import produce from 'immer'
import { ClientResearchList, ManualSearchResult, ONETJobsList, SearchResultList } from './types'
import { filterHashMap } from '../../utils/selectors'

export interface AutoResearchState {
  clients: ClientResearchList
  onetDetails: ONETJobsList
  search: SearchResultList
  meta: {
    [AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA]: stateUtils.MultiLoadedLoadingErrorState
    [AUTO_RESEARCH.FETCH_JOB_DETAILS]: stateUtils.MultiLoadedLoadingErrorState
    [AUTO_RESEARCH.SEARCH]: stateUtils.LoadedLoadingErrorState
    [AUTO_RESEARCH.DELETE_CLIENT_JOB]: stateUtils.MultiLoadedLoadingErrorState
    [AUTO_RESEARCH.RESET_CLIENT_JOB]: stateUtils.MultiLoadedLoadingErrorState
    [AUTO_RESEARCH.REPLACE_CLIENT_JOB]: stateUtils.MultiLoadedLoadingErrorState
    [AUTO_RESEARCH.DELETE_MANUAL_SEARCH]: stateUtils.MultiLoadedLoadingErrorState
    [AUTO_RESEARCH.SAVE_MANUAL_SEARCH]: stateUtils.MultiLoadedLoadingErrorState
    [AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY]: stateUtils.MultiLoadedLoadingErrorState
    [AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA]: stateUtils.MultiLoadedLoadingErrorState
  }
}

export const initialState: AutoResearchState = {
  clients: {},
  onetDetails: {},
  search: {},
  meta: {
    [AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA]: initialMultiLoadedLoadingErrorState,
    [AUTO_RESEARCH.FETCH_JOB_DETAILS]: initialMultiLoadedLoadingErrorState,
    [AUTO_RESEARCH.SEARCH]: initialLoadedLoadingErrorState,
    [AUTO_RESEARCH.DELETE_CLIENT_JOB]: initialMultiLoadedLoadingErrorState,
    [AUTO_RESEARCH.RESET_CLIENT_JOB]: initialMultiLoadedLoadingErrorState,
    [AUTO_RESEARCH.REPLACE_CLIENT_JOB]: initialMultiLoadedLoadingErrorState,
    [AUTO_RESEARCH.DELETE_MANUAL_SEARCH]: initialMultiLoadedLoadingErrorState,
    [AUTO_RESEARCH.SAVE_MANUAL_SEARCH]: initialMultiLoadedLoadingErrorState,
    [AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY]: initialMultiLoadedLoadingErrorState,
    [AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA]: initialMultiLoadedLoadingErrorState,
  },
}

export default function autoResearchReducer(
  state: AutoResearchState = initialState,
  action: AutoResearchActionTypes
): AutoResearchState {
  return produce(state, (draft: AutoResearchState) => {
    switch (action.type) {
      case AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA:
        draft.meta[AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA] = setStartStateForItem(
          action.clientID,
          state.meta[AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA]
        )
        break
      case AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA_SUCCESS:
        const clientID = action.meta.previousAction.clientID
        if (!draft.clients[clientID]) {
          draft.clients[clientID] = {
            jobHistory: {},
            manualSearchResults: {},
          }
        }
        if (!draft.clients[clientID].jobHistory) {
          draft.clients[clientID].jobHistory = {}
        }
        if (!draft.clients[clientID].manualSearchResults) {
          draft.clients[clientID].manualSearchResults = {}
        }

        const data = action.payload.data
        if (!!data && !!data.job_history && data.job_history.length > 0) {
          data.job_history.forEach(job => {
            draft.clients[clientID].jobHistory[job.id] = job
          })
        }
        if (!!data && !!data.custom_searches && data.custom_searches.length > 0) {
          data.custom_searches.forEach(search => {
            draft.clients[clientID].manualSearchResults[search.id] = search
          })
        }
        if (!!data && !!data.job_goal) {
          draft.clients[clientID].jobGoal = data.job_goal
        }
        draft.meta[AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA] = setSuccessStateForItem(
          clientID,
          state.meta[AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA]
        )
        break
      case AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA_FAIL: {
        draft.meta[AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA] = setErrorStateForItem(
          action.meta.previousAction.clientID,
          state.meta[AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA],
          action.error
        )
        break
      }
      case AUTO_RESEARCH.FETCH_JOB_DETAILS:
        draft.meta[AUTO_RESEARCH.FETCH_JOB_DETAILS] = setStartStateForItem(
          action.onetSocCode,
          state.meta[AUTO_RESEARCH.FETCH_JOB_DETAILS]
        )
        break
      case AUTO_RESEARCH.FETCH_JOB_DETAILS_SUCCESS: {
        const ID = action.meta.previousAction.onetSocCode
        draft.meta[AUTO_RESEARCH.FETCH_JOB_DETAILS] = setSuccessStateForItem(
          ID,
          state.meta[AUTO_RESEARCH.FETCH_JOB_DETAILS]
        )
        draft.onetDetails[ID] = action.payload.data

        break
      }
      case AUTO_RESEARCH.FETCH_JOB_DETAILS_FAIL: {
        draft.meta[AUTO_RESEARCH.FETCH_JOB_DETAILS] = setErrorStateForItem(
          action.meta.previousAction.onetSocCode,
          state.meta[AUTO_RESEARCH.FETCH_JOB_DETAILS],
          action.error
        )
        break
      }
      case AUTO_RESEARCH.SEARCH: {
        draft.meta[AUTO_RESEARCH.SEARCH] = setStartState(state.meta[AUTO_RESEARCH.SEARCH])
        break
      }
      case AUTO_RESEARCH.SEARCH_SUCCESS: {
        const keyword = action.meta.previousAction.keyword
        if (!draft.search[keyword] && !!action.payload.data) {
          draft.search[keyword] = {
            keyword: keyword,
            start: action.payload.data.start,
            end: action.payload.data.end,
            occupation: action.payload.data.occupation,
            total: action.payload.data.total,
          }
        } else if (!!draft.search[keyword] && !!action.payload.data) {
          const updatedOccupations = draft.search[keyword].occupation.concat(action.payload.data.occupation)
          draft.search[keyword] = { ...action.payload.data, occupation: updatedOccupations }
        }
        draft.meta[AUTO_RESEARCH.SEARCH] = setSuccessState(state.meta[AUTO_RESEARCH.SEARCH])
        break
      }
      case AUTO_RESEARCH.SEARCH_FAIL: {
        draft.meta[AUTO_RESEARCH.SEARCH] = setErrorState(state.meta[AUTO_RESEARCH.SEARCH], action.error)
        break
      }
      case AUTO_RESEARCH.SAVE_MANUAL_SEARCH: {
        draft.meta[AUTO_RESEARCH.SAVE_MANUAL_SEARCH].global = setStartState(
          state.meta[AUTO_RESEARCH.SAVE_MANUAL_SEARCH].global
        )
        break
      }
      case AUTO_RESEARCH.SAVE_MANUAL_SEARCH_SUCCESS: {
        if (!!action.payload.data) {
          const clientID = action.meta.previousAction.clientID
          if (!draft.clients[clientID].manualSearchResults) {
            draft.clients[clientID].manualSearchResults = {}
          }
          draft.clients[clientID].manualSearchResults[action.payload.data.id] = action.payload.data
        }
        draft.meta[AUTO_RESEARCH.SAVE_MANUAL_SEARCH].global = setSuccessState(
          state.meta[AUTO_RESEARCH.SAVE_MANUAL_SEARCH].global
        )
        break
      }
      case AUTO_RESEARCH.SAVE_MANUAL_SEARCH_FAIL: {
        draft.meta[AUTO_RESEARCH.SAVE_MANUAL_SEARCH].global = setErrorState(
          state.meta[AUTO_RESEARCH.SAVE_MANUAL_SEARCH].global,
          action.error
        )
        break
      }
      case AUTO_RESEARCH.DELETE_MANUAL_SEARCH: {
        draft.meta[AUTO_RESEARCH.DELETE_MANUAL_SEARCH] = setStartStateForItem(
          action.ID,
          state.meta[AUTO_RESEARCH.DELETE_MANUAL_SEARCH]
        )
        break
      }
      case AUTO_RESEARCH.DELETE_MANUAL_SEARCH_FAIL: {
        draft.meta[AUTO_RESEARCH.DELETE_MANUAL_SEARCH] = setErrorStateForItem(
          action.meta.previousAction.ID,
          state.meta[AUTO_RESEARCH.DELETE_MANUAL_SEARCH],
          action.error
        )
        break
      }
      case AUTO_RESEARCH.DELETE_MANUAL_SEARCH_SUCCESS: {
        const ID = action.meta.previousAction.ID
        draft.meta[AUTO_RESEARCH.DELETE_MANUAL_SEARCH] = setSuccessStateForItem(
          ID,
          state.meta[AUTO_RESEARCH.DELETE_MANUAL_SEARCH]
        )

        draft.clients[action.meta.previousAction.clientID].manualSearchResults = filterHashMap<ManualSearchResult>(
          state.clients[action.meta.previousAction.clientID].manualSearchResults,
          result => result.id !== action.meta.previousAction.ID
        )
        break
      }

      case AUTO_RESEARCH.DELETE_CLIENT_JOB: {
        draft.meta[AUTO_RESEARCH.DELETE_CLIENT_JOB] = setStartStateForItem(
          action.jobHistoryId,
          state.meta[AUTO_RESEARCH.DELETE_CLIENT_JOB]
        )
        break
      }

      case AUTO_RESEARCH.DELETE_CLIENT_JOB_SUCCESS:
        const client_ID = action.meta.previousAction.clientID
        const job_ID = action.meta.previousAction.jobHistoryId

        draft.meta[AUTO_RESEARCH.DELETE_CLIENT_JOB] = setSuccessStateForItem(
          job_ID,
          state.meta[AUTO_RESEARCH.DELETE_CLIENT_JOB]
        )

        draft.clients[client_ID].jobHistory = Object.entries(state.clients[client_ID].jobHistory)
          .filter(([, value]) => {
            return value.id !== job_ID
          })
          .reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {})
        break

      case AUTO_RESEARCH.DELETE_CLIENT_JOB_FAIL:
        draft.meta[AUTO_RESEARCH.DELETE_CLIENT_JOB] = setErrorStateForItem(
          action.meta.previousAction.jobHistoryId,
          state.meta[AUTO_RESEARCH.DELETE_CLIENT_JOB],
          action.error
        )
        break

      case AUTO_RESEARCH.RESET_CLIENT_JOB: {
        draft.meta[AUTO_RESEARCH.RESET_CLIENT_JOB] = setStartStateForItem(
          action.jobID,
          state.meta[AUTO_RESEARCH.RESET_CLIENT_JOB]
        )
        break
      }

      case AUTO_RESEARCH.RESET_CLIENT_JOB_SUCCESS: {
        const jobID = action.meta.previousAction.jobID
        draft.meta[AUTO_RESEARCH.RESET_CLIENT_JOB] = setSuccessStateForItem(
          jobID,
          state.meta[AUTO_RESEARCH.RESET_CLIENT_JOB]
        )

        draft.clients[action.meta.previousAction.clientID].jobHistory[jobID] = action.payload.data
        break
      }

      case AUTO_RESEARCH.RESET_CLIENT_JOB_FAIL: {
        draft.meta[AUTO_RESEARCH.RESET_CLIENT_JOB] = setErrorStateForItem(
          action.meta.previousAction.jobID,
          state.meta[AUTO_RESEARCH.RESET_CLIENT_JOB],
          action.error
        )
        break
      }
      case AUTO_RESEARCH.REPLACE_CLIENT_JOB: {
        draft.meta[AUTO_RESEARCH.REPLACE_CLIENT_JOB] = setStartStateForItem(
          action.jobID,
          state.meta[AUTO_RESEARCH.REPLACE_CLIENT_JOB]
        )
        break
      }
      case AUTO_RESEARCH.REPLACE_CLIENT_JOB_SUCCESS: {
        const jobID = action.meta.previousAction.jobID
        draft.meta[AUTO_RESEARCH.REPLACE_CLIENT_JOB] = setSuccessStateForItem(
          jobID,
          state.meta[AUTO_RESEARCH.REPLACE_CLIENT_JOB]
        )
        draft.clients[action.meta.previousAction.clientID].jobHistory[jobID] = action.payload.data
        break
      }
      case AUTO_RESEARCH.REPLACE_CLIENT_JOB_FAIL: {
        draft.meta[AUTO_RESEARCH.REPLACE_CLIENT_JOB] = setErrorStateForItem(
          action.meta.previousAction.jobID,
          state.meta[AUTO_RESEARCH.REPLACE_CLIENT_JOB],
          action.error
        )
        break
      }
      case AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY: {
        draft.meta[AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY] = setStartStateForItem(
          action.clientID,
          state.meta[AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY]
        )
        break
      }
      case AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY_SUCCESS: {
        const clientID = action.meta.previousAction.clientID
        if (!draft.clients[clientID]) {
          draft.clients[clientID] = {
            jobHistory: {},
            manualSearchResults: {},
            workHistory: {},
          }
        }
        if (!draft.clients[clientID].workHistory) {
          draft.clients[clientID].workHistory = {}
        }
        const data = action.payload.data
        if (!!data && Object.keys(data).length > 0) {
          draft.clients[clientID].workHistory = data
        }

        draft.meta[AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY] = setSuccessStateForItem(
          clientID,
          state.meta[AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY]
        )
        break
      }
      case AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY_FAIL: {
        draft.meta[AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY] = setErrorStateForItem(
          action.meta.previousAction.clientID,
          state.meta[AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY],
          action.error
        )
        break
      }
      case AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA: {
        draft.meta[AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA] = setStartStateForItem(
          action.clientID,
          state.meta[AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA]
        )
        break
      }
      case AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA_SUCCESS: {
        const clientID = action.meta.previousAction.clientID
        if (!draft.clients[clientID]) {
          draft.clients[clientID] = {
            summaryData: null,
            jobHistory: {},
            manualSearchResults: {},
          }
        }

        const data = action.payload.data
        if (!!data && Object.keys(data).length > 0) {
          draft.clients[clientID].summaryData = data
        }

        draft.meta[AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA] = setSuccessStateForItem(
          clientID,
          state.meta[AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA]
        )
        break
      }
      case AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA_FAIL: {
        draft.meta[AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA] = setErrorStateForItem(
          action.meta.previousAction.clientID,
          state.meta[AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA],
          action.error
        )
        break
      }
    }
  })
}
