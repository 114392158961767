import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import classnames from 'classnames'

import i18n from '../../../i18n'
import JobCards from './JobCards'
import ResumePreview from '../../common/ResumePreview'
import TemplateLink from './TemplateLink'
import { Order } from '../../../store/orders/types'
import { Client } from '../../../store/clients/reducer'
import * as questionnaireSelectors from '../../../selectors/questionnaire'
import { Questionnaire } from '../../../store/orders/questionnaire/types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
    },
    resume: {
      width: '35%',
      paddingRight: '4.75rem',
      boxSizing: 'border-box',
      '@media (max-width: 1050px)': { paddingRight: '2rem' },
      '@media (max-width: 320px)': { paddingRight: '1rem' },
    },
    details: {
      width: '65%',
    },
    jobs: {
      width: '100%',
      '& .title': { margin: '1.875rem 0 0', fontWeight: 600 },
      '& .cards': {
        '& > *': {
          display: 'inline-flex',
          width: '15rem',
          marginTop: '.875rem',
          verticalAlign: 'top',
          '&:not(:last-child)': { marginRight: '1.75rem' },
        },
      },
      '& .expanded': { marginTop: '.875rem' },
    },
    text: {
      flexGrow: 1,
    },
    goalTitle: {
      color: theme.palette.secondary.main,
      fontSize: '1.125rem',
      marginBottom: '.2rem',
      fontWeight: 'bold',
    },
    goal: { fontSize: '1.4375rem', marginBottom: '0.4rem' },
    categoriesContainer: {
      display: 'flex',
    },
    category: {
      fontSize: '0.9375rem',
      margin: '1.25rem 1rem 2.78125rem 0',
    },
    notesTitle: {
      marginBottom: '.5rem',
      fontSize: '0.9375rem',
      fontWeight: 'bold',
    },
    notesContainer: {
      position: 'relative',
      whiteSpace: 'pre-line',
    },
    hr: {
      background: '#d7dbe1',
      width: '8.875rem',
      height: '0.0625rem',
      border: 0,
      marginLeft: 0,
    },
    notes: {
      lineHeight: '1.5',
    },
    cappedNotes: {
      maxHeight: '4rem',
      overflow: 'hidden',
    },
    expand: {
      display: 'inline-flex',
      color: theme.palette.primary.main,
      cursor: 'pointer',
      marginTop: '.4rem',
    },
  })
)

interface SummaryProps {
  order: Order
  client: Client
  questionnaire_data: Questionnaire | undefined
}

const Summary: React.FC<SummaryProps> = ({ order, client, questionnaire_data }) => {
  const classes = useStyles()
  const [cappedNotes, setCappedNotes] = useState(true)

  const goals = questionnaireSelectors.getGoals(questionnaire_data)
  const notes = questionnaireSelectors.getClientNotes(questionnaire_data)
  const targetJobs = questionnaireSelectors.getTargetJobURLs(questionnaire_data)
  const isInternational = questionnaireSelectors.getIsInternational(questionnaire_data)
  const industry = questionnaireSelectors.getIndustry(questionnaire_data)
  const resumeImages = questionnaireSelectors.getResumeImages(questionnaire_data)
  const resumeDownloadURL = questionnaireSelectors.getResumeDownloadURL(questionnaire_data)
  const showImagePreview = resumeImages.length > 0 && resumeDownloadURL
  const brandName = order.brand.description

  return (
    <>
      <Box className={classes.root}>
        {showImagePreview && (
          <Box className={classes.resume}>
            <ResumePreview imageUrls={resumeImages} downloadUrl={resumeDownloadURL} />
            {brandName === 'The Ladders' && <TemplateLink />}
          </Box>
        )}
        <Box className={classes.details}>
          <Box className={classes.text}>
            <Box className={classes.goalTitle}>
              {i18n.t('order__page__client__details__goals', { name: client.full_name })}
            </Box>
            <Box className={classes.goal}>{goals.join(', ')}</Box>
            <hr className={classes.hr} />
            <Box className={classes.categoriesContainer}>
              <Typography variant={'body2'} className={classes.category}>
                {industry}
              </Typography>
              {isInternational && (
                <Typography variant={'body2'} className={classes.category}>
                  {i18n.t('order__page__summary__internationial')}
                </Typography>
              )}
            </Box>
            {notes && (
              <>
                <Typography variant={'body2'} className={classes.notesTitle}>
                  {i18n.t('order__page__client__details__client_notes')}
                </Typography>
                <Box className={classes.notesContainer}>
                  <Typography
                    variant={'body2'}
                    className={classnames({
                      [classes.notes]: true,
                      [classes.cappedNotes]: cappedNotes,
                    })}
                  >
                    {notes}
                  </Typography>
                </Box>
                <Typography variant={'body2'} className={classes.expand} onClick={() => setCappedNotes(!cappedNotes)}>
                  {cappedNotes ? i18n.t('see__more') : i18n.t('see__less')}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        {targetJobs.length > 0 && <JobCards jobs={targetJobs} />}
      </Box>
    </>
  )
}

export default Summary
