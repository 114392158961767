import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import i18n from '../../i18n'
import styles from '../../styles'
import Button from '../common/Button'
import ResumePreview from '../common/ResumePreview'
import OrderItems, { FulfillableOrderItemDisplay, GenericOrderItemDisplay } from '../Orders/OrderItems'
import OfferRejected from './OfferRejected'
import {
  AcceptRejectFeedbackOfferResponse,
  OfferLoadingState,
  Offer,
  OfferFeedback,
  OfferStatus,
} from '../../store/offers/types'
import OfferAccepted from './OfferAccepted'
import { AxiosMiddlewareActionCreator, AxiosMiddlewareActionSuccess } from '../../utils/axios'
import OfferExpired from './OfferExpired'
import { formatCurrency, formatDateOffset } from '../../utils/formatting'
import useUserInfoState from '../common/useUserInfo'
import { sendUserEvent } from '../../store/events/actions'
import { EventTypes } from '../../store/events/types'

interface NewOfferProps {
  currentOffer: Offer
  currentOfferMeta: OfferLoadingState
  offerItems: (GenericOrderItemDisplay | FulfillableOrderItemDisplay)[] | null
  isLoading: boolean
  onAcceptClick: () => void
  onRejectClick: () => void
  onFeedbackSubmit: (
    feedback: OfferFeedback
  ) => Promise<AxiosMiddlewareActionSuccess<
    AcceptRejectFeedbackOfferResponse,
    AxiosMiddlewareActionCreator
  > | void> | void
  removeOffer: () => void
  discardCurrentUserOffer: () => void
  onRequestNewOfferClick: () => Promise<void>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    newOffer: {
      width: '100%',
      position: 'relative',
      '& .orderResume': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '35%',
        paddingRight: '4.75rem',
        boxSizing: 'border-box',
        '@media (max-width: 1050px)': { paddingRight: '2rem' },
        '@media (max-width: 320px)': { paddingRight: '1rem' },
      },
      '& .orderTitles': {
        width: '65%',
        boxSizing: 'border-box',
        marginLeft: '35%',
        '& .orderItems': {
          marginTop: '1rem',
          '& > *': { flexWrap: 'wrap' },
          '& > * > *': { marginTop: '.1rem', marginBottom: '.1rem' },
        },
        '@media (max-width: 960px)': { minHeight: '17rem' },
        '@media (max-width: 640px)': { minHeight: '14rem' },
      },
      '& .orderDetails': {
        width: '65%',
        boxSizing: 'border-box',
        marginLeft: '35%',
        marginTop: '3.3125rem',
        '@media (max-width: 960px)': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          marginLeft: 0,
          marginTop: '',
        },
      },
      '& .orderButtons': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2.375rem',
        '& > *': { marginTop: '1rem' },
        '& .leftButton': { marginRight: '3.375rem' },
        '@media (max-width: 640px)': {
          '& .leftButton': { marginRight: '1rem' },
        },
        '@media (max-width: 600px)': {
          flexDirection: 'column',
          alignItems: 'center',
          '& .leftButton': { marginRight: 0 },
        },
      },
    },
    title: {
      color: theme.palette.secondary.main,
      marginBottom: '.2rem',
      fontWeight: 'bold',
    },
    role: {
      marginBottom: '0.4rem',
      fontWeight: 'normal',
    },
    categoriesContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
      marginTop: '1.25rem',
      '& .category': {
        margin: '0 1rem 0 0',
        color: styles.palette.lightBlack.color,
        whiteSpace: 'nowrap',
      },
    },
    hr: {
      background: '#d7dbe1',
      width: '8.875rem',
      height: '0.0625rem',
    },
    orderInfo: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      '@media (max-width: 960px)': {
        justifyContent: 'center',
        '& > :first-child': { marginRight: '2rem' },
      },
      '@media (max-width: 400px)': {
        '& > :first-child': { marginRight: '1rem' },
      },
    },
    date: {
      textAlign: 'center',
      fontSize: '1.5rem',
      color: styles.palette.lightBlack.color,
      lineHeight: '4rem',
      '@media (max-width: 600px)': { fontSize: '1rem', lineHeight: '2.5rem' },
      '@media (max-width: 400px)': { fontSize: '.8rem', lineHeight: '2rem' },
    },
    pay: {
      textAlign: 'center',
      fontSize: '3rem',
      color: styles.palette.moneyGreen.color,
      lineHeight: '4rem',
      '@media (max-width: 600px)': { fontSize: '2rem', lineHeight: '2.5rem' },
      '@media (max-width: 400px)': { fontSize: '1.6rem', lineHeight: '2rem' },
    },
    type: {
      color: styles.palette.lightBlack.color,
      textAlign: 'center',
    },
    sendingSpinner: {
      marginLeft: theme.spacing(3),
    },
    incentivesBlock: {
      marginTop: '1rem',
    },
    incentivesGroupBlock: {
      display: 'flex',
      flexFlow: 'row wrap',
    },
    potentialIncentive: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      marginRight: '0.5rem',
      color: '#64cea0',
    },
    appliendIncentive: {
      fontSize: '0.75rem',
      marginRight: '0.5rem',
      color: styles.palette.moneyGreen.color,
    },
    testOrderFlag: {
      fontWeight: 600,
      color: '#f26d6d',
      paddingBottom: '0.75rem',
    },
  })
)

const NewOffer: React.FC<NewOfferProps> = props => {
  const {
    onAcceptClick,
    onRejectClick,
    onFeedbackSubmit,
    removeOffer,
    discardCurrentUserOffer,
    currentOffer,
    currentOfferMeta,
    isLoading,
    offerItems,
    onRequestNewOfferClick,
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { timeZone, locale, shouldSeeBasePay } = useUserInfoState()
  const [btnsDisabled, disableBtns] = useState(false)
  const isSubmitting = currentOfferMeta.accept.isLoading || currentOfferMeta.reject.isLoading

  const onOfferAcceptClick = useCallback(async () => {
    disableBtns(true)
    await onAcceptClick()
  }, [onAcceptClick, disableBtns])

  const onOfferRejectClick = useCallback(async () => {
    disableBtns(true)
    await onRejectClick()
  }, [onRejectClick, disableBtns])

  const discardAndRemoveCurrentOffer = (function() {
    // this should be executed only once!
    let executed = false
    return function() {
      if (!executed) {
        executed = true
        removeOffer()
        discardCurrentUserOffer()
      }
    }
  })()

  const firstOrder = currentOffer.order_ids[0]

  const hasAppliedIncentives =
    currentOffer.incentives &&
    currentOffer.incentives.dynamic_pay &&
    currentOffer.incentives.dynamic_pay.dynamic_pay_items &&
    currentOffer.incentives.dynamic_pay.dynamic_pay_items.length > 0

  const hasPotentialInentives =
    currentOffer.incentives &&
    currentOffer.incentives.potential_delivery_incentives &&
    currentOffer.incentives.potential_delivery_incentives.length > 0

  const onImageLoadError = useCallback(
    (imageURLs: string[]) => {
      let documentDigest = null

      if (imageURLs.length > 0) {
        const matches = imageURLs[0].match(/images\/(.*)\/1/)

        if (matches && matches.length > 0) {
          documentDigest = matches[1]
        }
      }

      dispatch(
        sendUserEvent({
          event: EventTypes.OfferImageLoadError,
          variables: {
            offer_id: currentOffer.offer_id,
            client_id: currentOffer.client_id,
            image_urls: imageURLs,
            document_digest: documentDigest,
          },
        })
      )
    },
    [dispatch, currentOffer.offer_id, currentOffer.client_id]
  )

  useEffect(function recordNoImages() {
    if (!currentOffer.resume_images) {
      dispatch(
        sendUserEvent({
          event: EventTypes.OfferWithoutImage,
          variables: {
            offer_id: currentOffer.offer_id,
            client_id: currentOffer.client_id,
          },
        })
      )
    }
    // We only want this to run once but the variales referenced can mutate like crazy, so I'm not tracking them.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return (
    <Box className={classes.root}>
      {currentOffer.status === OfferStatus.Offered && (
        <Box className={classes.newOffer}>
          <Box className="orderResume">
            <ResumePreview
              imageUrls={currentOffer.resume_images || []}
              renderDownloadIcon={false}
              imageLoadErrorCallback={onImageLoadError}
            />
          </Box>
          <Box className="orderTitles">
            <Typography display="block" variant={'h3'} className={classes.title}>
              {i18n.t('order__shopping__target__roles')}
            </Typography>
            {!!currentOffer.roles &&
              currentOffer.roles.map(r => (
                <Typography display="block" variant={'h1'} key={r} className={classes.role}>
                  {r}
                </Typography>
              ))}
            {currentOffer.test_order === 1 && <Typography className={classes.testOrderFlag}>Test Order</Typography>}
            <div className={classes.hr} />
            <Box className={classes.categoriesContainer}>
              {!!currentOffer.industry_id && (
                <Typography display="inline" variant={'body2'} className="category">
                  {i18n.t(`industry__industry${currentOffer.industry_id}`)}
                </Typography>
              )}
              {!!currentOffer.region && (
                <Typography display="inline" variant={'body2'} className="category">
                  {currentOffer.region}
                </Typography>
              )}
            </Box>
            {(hasAppliedIncentives || hasPotentialInentives) && (
              <Box className={classes.incentivesBlock}>
                {hasAppliedIncentives && (
                  <Box className={classes.incentivesGroupBlock}>
                    {currentOffer.incentives.dynamic_pay.dynamic_pay_items
                      .filter(inc => inc.amount > 0)
                      .map((incentive, index) => (
                        <Typography
                          key={`applied-incentive-${index}`}
                          className={classes.appliendIncentive}
                          variant={'h1'}
                        >
                          {`${incentive.description} - ${formatCurrency(
                            incentive.amount,
                            currentOffer.writer_pay.currency
                          )}`}
                        </Typography>
                      ))}
                  </Box>
                )}
                {hasPotentialInentives && (
                  <Box className={classes.incentivesGroupBlock}>
                    {currentOffer.incentives.potential_delivery_incentives.map((incentive, index) => (
                      <Typography
                        key={`potential-incentive-${index}`}
                        className={classes.potentialIncentive}
                        variant={'h1'}
                      >
                        {`${incentive.description} ${i18n.t(
                          'order__shopping__offer__incentive_eligible'
                        )} - ${formatCurrency(incentive.amount, currentOffer.writer_pay.currency)}`}
                      </Typography>
                    ))}
                  </Box>
                )}
              </Box>
            )}

            {!!currentOffer.products && (
              <Box className="orderItems">
                <OrderItems
                  stackItems={false}
                  items={offerItems}
                  shouldSeeInternalIncentiveNames={false}
                  shouldRenderBasePay={shouldSeeBasePay}
                  shouldRenderHistory={false}
                  shouldApplyColors={false}
                />{' '}
              </Box>
            )}
          </Box>

          <Box className="orderDetails">
            <Box className={classes.orderInfo}>
              <Box>
                <Typography display="block" className={classes.date}>
                  {formatDateOffset(new Date(), firstOrder.due_date, timeZone, locale)}
                </Typography>
                <Typography display="block" variant={'body2'} className={classes.type}>
                  {i18n.t('order__shopping__draft__due')}
                </Typography>
              </Box>
              {shouldSeeBasePay && !!currentOffer.writer_pay && currentOffer.writer_pay.amount > 0 && (
                <Box>
                  <Typography display="block" className={classes.pay}>
                    {formatCurrency(currentOffer.writer_pay.amount, currentOffer.writer_pay.currency)}
                  </Typography>
                  <Typography display="block" variant={'body2'} className={classes.type}>
                    {i18n.t('order__shopping__pay')}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <Box className="orderButtons">
            <Button
              onClick={onOfferAcceptClick}
              className="leftButton"
              size={'medium'}
              type={'primary'}
              showLoader={currentOfferMeta.accept.isLoading}
              id="btn-order-shopping-accpet"
              disabled={btnsDisabled || isSubmitting}
            >
              {i18n.t('order__shopping__accept')}
            </Button>
            <Button
              onClick={onOfferRejectClick}
              size={'medium'}
              type={'secondary'}
              showLoader={currentOfferMeta.reject.isLoading}
              id="btn-order-shopping-reject"
              disabled={btnsDisabled || isSubmitting}
            >
              {i18n.t('order__shopping__reject')}
            </Button>
          </Box>
        </Box>
      )}
      {currentOffer.status === OfferStatus.Rejected && (
        <OfferRejected
          currentOfferMeta={currentOfferMeta}
          onFeedbackSubmit={onFeedbackSubmit}
          onFeedbackCancel={discardAndRemoveCurrentOffer}
          onRequestNewOrderClick={onRequestNewOfferClick}
          isLoading={isLoading}
        />
      )}
      {currentOffer.status === OfferStatus.Accepted && (
        <OfferAccepted
          onCloseOfferAcceptedClick={discardAndRemoveCurrentOffer}
          onRequestNewOrderClick={onRequestNewOfferClick}
          isLoading={isLoading}
          clientID={currentOffer.client_id}
        />
      )}
      {currentOffer.status === OfferStatus.Expired && (
        <OfferExpired
          isLoading={isLoading}
          onCloseClick={discardAndRemoveCurrentOffer}
          onRequestNewOrderClick={onRequestNewOfferClick}
        />
      )}
    </Box>
  )
}

// @ts-ignore
NewOffer.whyDidYouRender = true

export default NewOffer
