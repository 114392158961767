import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'

import IconButton from '@material-ui/core/IconButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

interface BackToTopProps {
  topElement: string
}

const useStyles = makeStyles(() =>
  createStyles({
    iconButton: {
      backgroundColor: styles.palette.darkerBlue.color.hex,
      borderRadius: '.2em',
      bottom: '5.4em',
      boxShadow: '0 .09em .33em 0 rgba(0, 0, 0, 0.12)',
      color: 'white',
      padding: '.35em',
      position: 'fixed',
      right: '2.6em',

      '&:hover': {
        backgroundColor: styles.palette.darkerBlue.color.hex,
      },
    },
  })
)

const BackToTop: React.FC<BackToTopProps> = ({ topElement }) => {
  const classes = useStyles()

  return (
    <IconButton className={classes.iconButton} href={`#${topElement}`}>
      <ArrowUpwardIcon />
    </IconButton>
  )
}

export default BackToTop
