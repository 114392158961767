import React, { useCallback, useReducer } from 'react'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import Button from '../common/Button'
import CustomRadio from '../common/CustomRadio'
import i18n from '../../i18n'
import UploadStyles from './styles'
import { UploadOverview } from '../../store/uploads/types'

interface Props {
  scans: UploadOverview[]
  reuploadFile: (file: File, filenameToRemove: string) => void
  buttonType: 'secondary' | 'contrast'
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelButton: {
      marginRight: '1.2rem',
      padding: '.5rem 1.547rem',
    },
    reuploadInput: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '200%',
      transform: 'translateY(-50%)',
      opacity: 0,
      cursor: 'pointer',
    },
    modal: {
      color: UploadStyles.darkBlue.hex,
      padding: '1.8rem 1.9rem 1.6rem',
      width: '100%',
      maxWidth: '487px',
    },
    modalTitle: {
      fontWeight: 'bold',
      fontSize: '1rem',
      marginBottom: '1rem',
    },
  })
)

interface State {
  showModal: boolean
  selectedFilename: string | null
}

const initialState = {
  showModal: false,
  selectedFilename: null,
}

interface ToggleModal {
  type: 'SHOW_MODAL' | 'HIDE_MODAL'
}

interface SelectFile {
  type: 'SELECT_FILE'
  filename: string
}

interface Submit {
  type: 'SUBMIT'
}

type Action = ToggleModal | SelectFile | Submit

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'SHOW_MODAL':
    case 'HIDE_MODAL':
      return {
        ...state,
        showModal: action.type === 'SHOW_MODAL',
        selectedFilename: action.type === 'SHOW_MODAL' ? state.selectedFilename : null,
      }
    case 'SELECT_FILE':
      return { ...state, selectedFilename: action.filename }
    case 'SUBMIT':
      return { ...initialState }
  }
}

/**
 * Reupload flow notes
 *
 * 1. If the user uploaded one document, clicking on the reupload button will automatically open the browser's file
 *    upload dialog, as we know which file to replace.
 * 2. If the user uploaded two or more documents, clicking on the reupload button will prompt the user on which file
 *    they would like to replace.
 * 3. Only one file can be replaced at a time as the UI does not allow for the user to dropdown select what the new type
 *    is.
 */
const ReuploadButton: React.FC<Props> = ({ scans, reuploadFile, buttonType }) => {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)

  const onReuploadClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (scans.length > 1) {
        event.preventDefault()
        dispatch({ type: 'SHOW_MODAL' })
      }
    },
    [scans]
  )

  const onReuploadChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        if (scans.length === 1) {
          reuploadFile(event.target.files[0], scans[0].pending.file.name)
        } else if (state.selectedFilename) {
          reuploadFile(event.target.files[0], state.selectedFilename)
        }

        dispatch({ type: 'SUBMIT' })
      }
    },
    [scans, reuploadFile, state.selectedFilename]
  )

  const fileInput = <input type="file" className={classes.reuploadInput} onChange={onReuploadChange} />

  return (
    <>
      <Button type={buttonType} className={classes.cancelButton} onClick={onReuploadClick}>
        {fileInput}
        {i18n.t('messaging__docs__btn__reupload')}
      </Button>
      <Dialog open={state.showModal} classes={{ paper: classes.modal }}>
        <Typography variant="h2" className={classes.modalTitle}>
          {i18n.t('uploadsv3__reuploadButton__title')}
        </Typography>
        <RadioGroup
          value={state.selectedFilename}
          onChange={event => dispatch({ type: 'SELECT_FILE', filename: event.target.value })}
        >
          {scans.map(scan => (
            <FormControlLabel
              key={scan.pending.file.name}
              value={scan.pending.file.name}
              control={<CustomRadio />}
              label={scan.pending.file.name}
            />
          ))}
        </RadioGroup>
        <DialogActions>
          <Button type="secondary" onClick={() => dispatch({ type: 'HIDE_MODAL' })}>
            {i18n.t('uploadsv3__reuploadButton__cancel')}
          </Button>
          <Button type="contrast" disabled={!state.selectedFilename}>
            {fileInput}
            {i18n.t('uploadsv3__reuploadButton__next')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ReuploadButton
