import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import queryString from 'query-string'
import { useHotkeys } from 'react-hotkeys-hook'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useDispatch, useSelector } from 'react-redux'
import Badge from '@material-ui/core/Badge'
import { Box } from '@material-ui/core'
import * as H from 'history'
import isEqual from 'lodash/isEqual'

import i18n from '../../i18n'
import Filtering, { FilterTypes } from './Filtering'
import PageHeader from '../common/PageHeader'
import { AppState } from '../../store'
import { UserInfo } from '../../store/user/reducers'
import Loader from '../common/Loader'
import { sortByMacroDueDate } from '../../selectors/items'
import {
  ClientOrdersDisplayAggregate,
  paidOrdersPaginationSelector,
  searchClientOrderAggregate,
} from '../../selectors/clientOrderAggregates'
import { fetchUserClientOrders, goToPageOfClientOrders } from '../../store/clientOrderAggregate/actions'
import useUserInfoState from '../common/useUserInfo'
import OrderTable from './OrderTable'
import { LoadedLoadingErrorState } from '../../utils/state'
import { Route, useHistory } from 'react-router-dom'
import { Routes } from '../../utils/consts'
import { GhostLink } from '../../utils/link'
import { PaginationState } from '../../utils/pagination'
import useOrderFiltering, { OrderTableDisplayModes } from './useOrderFiltering'
import SearchInput from '../common/SearchInput'
import useOrderSearch from './useOrderSearch'
import { addMinutes } from 'date-fns'

export interface OrdersContainerProps {
  user?: UserInfo
  location?: H.Location
}

export const WebMobileFilterBreakPoint = 1000

const useStyles = makeStyles(() => ({
  container: {
    background: 'transparent',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  table: {
    background: '#fafafa',
  },
  cell: {
    padding: '0.6rem 1rem',
    '@media (max-width: 1050px)': {
      fontSize: '0.9375rem',
    },
    '@media (max-width: 540px)': {
      padding: '0.6rem 0.6rem',
    },
  },
  head: {
    backgroundColor: '#f2f2f4',
    marginBottom: '3rem',
  },
  headCell: {
    border: 'none',
    color: '#000',
    '& > :first-child': { fontWeight: 600 },
    '@media (max-width: 540px)': {
      padding: '0.875rem 0 0.875rem 0.6875rem',
    },
  },
  tabLabel: {
    textTransform: 'initial',
    marginRight: '1.5rem',
    minWidth: '1rem',
    padding: '0',
    '@media (max-width: 540px)': {
      fontSize: '1.1rem',
      marginRight: '1rem',
    },
  },
  pageTopContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '3rem',
  },
  tabsContainer: {
    height: '2.4rem',
    minHeight: 'initial',
  },
  headRow: {
    height: '3.1rem',
  },
  orderNameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  orderName: {
    marginLeft: '.5rem',
  },
  notificationsBadge: {
    paddingRight: '10px',
  },
  searchContainer: {
    width: '11em',
    marginTop: '1em',
    marginRight: '1em',
  },
  searchFilterContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const DEFAULT_PAID_PAGE_SIZE = 50
export const DEFAULT_OPEN_PAGE_SIZE = 150

const OrdersContainer: React.FC<OrdersContainerProps> = props => {
  const { user, location } = props
  const classes = useStyles()
  const [filterType, setFilterType] = React.useState<FilterTypes>(
    window.innerWidth < WebMobileFilterBreakPoint ? FilterTypes.Mobile : FilterTypes.Web
  )
  const [filterDialogVisible, setFilterDialogVisible] = React.useState(false)

  const orderFilter = useOrderFiltering()
  const orderSearch = useOrderSearch()

  const dispatch = useDispatch()
  const history = useHistory()

  const { shouldSeeBasePay } = useUserInfoState()

  const paidOrdersMeta = useSelector<AppState, LoadedLoadingErrorState>(
    store => store.clientOrdersAggregateReducer.metaData.global.paid
  )

  const openOrdersMeta = useSelector<AppState, LoadedLoadingErrorState>(
    store => store.clientOrdersAggregateReducer.metaData.global.open
  )

  const paidOrderPagination = useSelector<AppState, PaginationState>(
    state => state.clientOrdersAggregateReducer.pagination.paid
  )

  useEffect(() => {
    const query = location ? location.search : ''
    const values = queryString.parse(query)
    let page = paidOrderPagination.page
    let page_size = DEFAULT_PAID_PAGE_SIZE

    if (values.page && typeof values.page === 'string') {
      page = parseInt(values.page)
    }

    if (values.page_size && typeof values.page_size === 'string') {
      page_size = parseInt(values.page_size)
    }

    const filterValue = orderFilter.getFilterFromQueryString()
    orderFilter.setFilter(filterValue)

    if (filterValue === OrderTableDisplayModes.Paid) {
      if (!paidOrdersMeta.loaded && !paidOrdersMeta.isLoading) {
        dispatch(
          fetchUserClientOrders({
            status: ['paid'],
            page_size,
            page,
          })
        )
      }
      return
    }

    page_size = DEFAULT_OPEN_PAGE_SIZE

    // we don't want to fetch Orders on filter change
    if (
      orderFilter.currentFilter !== OrderTableDisplayModes.Paid &&
      !openOrdersMeta.isLoading &&
      (!openOrdersMeta.loaded || (openOrdersMeta.lastLoaded && openOrdersMeta.lastLoaded < addMinutes(new Date(), -1)))
    ) {
      dispatch(
        fetchUserClientOrders({
          status: ['open'],
          page_size,
          page,
        })
      )
    }
  }, [dispatch, location, paidOrdersMeta, openOrdersMeta, paidOrderPagination.page, orderFilter])

  useLayoutEffect(() => {
    function updateFilterType() {
      if (filterType === FilterTypes.Web && window.innerWidth < WebMobileFilterBreakPoint) {
        setFilterType(FilterTypes.Mobile)
      } else if (filterType === FilterTypes.Mobile && window.innerWidth >= WebMobileFilterBreakPoint) {
        setFilterType(FilterTypes.Web)
      }
    }
    window.addEventListener('resize', updateFilterType)
    return () => window.removeEventListener('resize', updateFilterType)
  }, [filterType, setFilterType])

  const data = useSelector<AppState, (ClientOrdersDisplayAggregate | null)[]>(state => {
    if (orderFilter.currentFilter === OrderTableDisplayModes.Paid) {
      return paidOrdersPaginationSelector({
        ...state.orderReducer,
        ...state.clientOrdersAggregateReducer,
        ...state.clientReducer,
        ...state.itemsReducer,
        ...state.incentivesReducer,
        ...state.userReducer,
      })
    }
    return searchClientOrderAggregate(orderFilter.clients, state.clientOrdersAggregateReducer.view.searchTerm)
  }, isEqual)

  const dataForTable = data.filter(d => !!d) as ClientOrdersDisplayAggregate[]

  const unreadMessagesCountPaid = useSelector<AppState, number>(
    state => state.clientOrdersAggregateReducer.unread_reconciled_messages
  )

  const a11yProps = useCallback((index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }, [])

  const mobileFilterHandler = useCallback(() => setFilterDialogVisible(visible => !visible), [setFilterDialogVisible])

  const handleChangePage = useCallback(
    (page: number) => {
      dispatch(goToPageOfClientOrders('paid', page + 1))

      const searchValues = queryString.parse(!!location ? location.search : '')
      const newSearchValues = { ...searchValues, page: page + 1 }

      history.push({
        search: queryString.stringify(newSearchValues),
      })
    },
    [dispatch, history, location]
  )

  // Function for changing search filter in URL
  const changeFilter = useCallback(
    (filter: string) => {
      let search = `filter=${filter}`
      if (history.location.search.includes('ghost=')) {
        const ghost = history.location.search.slice(
          history.location.search.indexOf('ghost='),
          history.location.search.length
        )
        search = `${search}&${ghost}`
      }

      const path = `${history.location.pathname}?${search}`
      history.push(path)
    },
    [history]
  )

  // Hotkey assignments
  useHotkeys('ctrl+n', () => changeFilter(OrderTableDisplayModes.New))
  useHotkeys('ctrl+m', () => changeFilter(OrderTableDisplayModes.UnreadMessages))
  useHotkeys('ctrl+e', () => changeFilter(OrderTableDisplayModes.EligibleToClose))
  useHotkeys('ctrl+r', () => changeFilter(OrderTableDisplayModes.InRevisions))
  useHotkeys('ctrl+p', () => changeFilter(OrderTableDisplayModes.PhoneCalls))
  useHotkeys('ctrl+c', () => changeFilter(OrderTableDisplayModes.Complete))
  useHotkeys('ctrl+a', () => changeFilter(OrderTableDisplayModes.Open))

  return (
    <>
      {openOrdersMeta.isLoading || paidOrdersMeta.isLoading ? (
        <Loader fullPage={true} />
      ) : (
        <Paper className={classes.container}>
          <PageHeader>
            <Tabs
              indicatorColor={'primary'}
              className={classes.tabsContainer}
              value={!!location ? location.pathname : '/'}
              aria-label="Order type tabs"
            >
              <Tab
                className={classes.tabLabel}
                label={<Typography variant={'h3'}>{i18n.t('orders__tab__title__open__orders')}</Typography>}
                {...a11yProps(0)}
                value={Routes.OpenOrders}
                component={GhostLink}
                to={Routes.OpenOrders}
                onClick={async () => await orderFilter.setFilter(OrderTableDisplayModes.Open)}
              />
              <Tab
                className={classes.tabLabel}
                label={
                  <Box className={classes.notificationsBadge}>
                    <Badge
                      badgeContent={!!unreadMessagesCountPaid ? unreadMessagesCountPaid : 0}
                      color={'primary'}
                      showZero={false}
                    >
                      <Typography variant={'h3'}>{i18n.t('orders__tab__title__paid__orders')}</Typography>
                    </Badge>
                  </Box>
                }
                {...a11yProps(1)}
                value={Routes.PaidOrders}
                component={GhostLink}
                to={Routes.PaidOrders}
              />
            </Tabs>
            {orderFilter.currentFilter !== OrderTableDisplayModes.Paid && (
              <Box className={classes.searchFilterContainer}>
                <Box className={classes.searchContainer}>
                  <SearchInput
                    id="client-search-orders-table"
                    value={orderSearch.localSearchTerm}
                    onClearClick={() => orderSearch.setSearchTerm('')}
                    onChange={value => orderSearch.setSearchTerm(value.target.value)}
                  />
                </Box>
                <Filtering
                  onFilteringControlChange={async (newValue: OrderTableDisplayModes) =>
                    await orderFilter.setFilter(newValue)
                  }
                  filterType={filterType}
                  filterDialogVisible={filterDialogVisible}
                  toggleFilterDialog={setFilterDialogVisible}
                />
              </Box>
            )}
          </PageHeader>
          <Route
            exact
            path={Routes.OpenOrders}
            render={() => (
              <OrderTable
                data={dataForTable.sort(sortByMacroDueDate('asc')).slice()}
                dataIsLoading={openOrdersMeta.isLoading}
                displayMode={orderFilter.currentFilter}
                showMobileFilterIcon={filterType === FilterTypes.Mobile}
                onMobileFilterIconClick={mobileFilterHandler}
                shouldSeeBasePay={shouldSeeBasePay}
                userID={user ? user.id : undefined}
              />
            )}
          />
          <Route
            exact
            path={Routes.PaidOrders}
            render={() => (
              <OrderTable
                data={dataForTable.sort(sortByMacroDueDate('desc')).slice()}
                dataIsLoading={openOrdersMeta.isLoading}
                displayMode={OrderTableDisplayModes.Paid}
                showMobileFilterIcon={filterType === FilterTypes.Mobile}
                onMobileFilterIconClick={mobileFilterHandler}
                shouldSeeBasePay={shouldSeeBasePay}
                userID={user ? user.id : undefined}
                pagination={paidOrderPagination}
                handleChangePage={handleChangePage}
              />
            )}
          />
        </Paper>
      )}
    </>
  )
}

// @ts-ignore
OrdersContainer.whyDidYouRender = true

export default OrdersContainer
