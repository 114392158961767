import GlobalStyles from '../../styles'

const styles = {
  darkBlue: GlobalStyles.palette.darkerBlue.color,
  pink: {
    hex: '#ed3572',
    rgb: '237, 53, 114',
  },
  lightYellow: {
    hex: '#ffcc00',
  },
  lightGreen: {
    hex: '#13d765',
  },
  lightBlue: {
    hex: '#f5f6fa',
    rgb: '245, 246, 250',
  },
  lightPurple: {
    hex: '#f5f5f9',
  },
  backgroundColor: '#fcfcfc',
  autoQAResults: {
    group: {
      bg: '#f5f5f9',
      borderRadius: '.625rem .625rem .625rem .625rem',
      boxShadow: '0 5px 10px 0 rgba(106, 117, 140, 0.05)',
      border: 'solid 1px rgba(46, 91, 255, 0.08)',
    },
  },
}

export default styles
