import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import i18n from '../../i18n'
import Button from '../common/Button'
import styles from '../../styles'
import { GhostLink } from '../../utils/link'
import { getClientDefaultUrl } from '../../utils/consts'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { wipLimitReached } from '../../selectors/offers'

interface OfferAcceptedProps {
  onCloseOfferAcceptedClick: () => void
  onRequestNewOrderClick: () => Promise<void>
  isLoading: boolean
  clientID: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    offerAccepted: {
      position: 'relative',
      width: '100%',
      color: styles.palette.lightGrey.color,
      textAlign: 'center',
    },
    headline: {
      fontSize: '2.25rem',
      color: styles.palette.lightBlack.color,
      marginBottom: '2.125rem',
    },
    bodyText: {
      color: styles.palette.lightBlack.color,
      marginBottom: '3.1125rem',
      padding: '0 9.5rem',
      '@media (max-width: 900px)': {
        padding: '0 4rem',
      },
      '@media (max-width: 768px)': {
        padding: 0,
      },
    },
    buttons: {
      '& .leftButton': { marginRight: '2.1875rem' },
      '@media (max-width: 620px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& .leftButton': { marginRight: 0, marginBottom: '1rem' },
      },
    },
    closeContainer: {
      position: 'absolute',
      right: 0,
      top: 0,
      padding: '.5rem',
      '& .icon': {
        padding: '.1rem',
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.54)',
        color: '#fff',
        fontSize: '1rem',
      },
    },
  })
)

const OfferAccepted: React.FC<OfferAcceptedProps> = ({
  onCloseOfferAcceptedClick,
  onRequestNewOrderClick,
  isLoading,
  clientID,
}) => {
  const classes = useStyles()

  const onMoreOffersClick = async () => {
    await onRequestNewOrderClick()
    onCloseOfferAcceptedClick()
  }

  const wipReached = useSelector<AppState, boolean>(state => wipLimitReached(state.offerReducer))

  return (
    <Box className={classes.offerAccepted}>
      <IconButton className={classes.closeContainer} onClick={onCloseOfferAcceptedClick}>
        <CloseIcon className="icon" />
      </IconButton>
      <Typography display="block" className={classes.headline}>
        {i18n.t('order__shopping__offer__accepted__headline')}
      </Typography>
      <Typography display="block" variant={'body1'} className={classes.bodyText}>
        {i18n.t('order__shopping__offer__accepted__bodytext')}
      </Typography>
      <Box className={classes.buttons}>
        <GhostLink to={getClientDefaultUrl(clientID)}>
          <Button onClick={onCloseOfferAcceptedClick} className="leftButton" type={'primary'} size={'medium'}>
            {i18n.t('order__shopping__offer__accepted__review__new__order')}
          </Button>
        </GhostLink>
        {!wipReached && (
          <Button type={'secondary'} size={'medium'} onClick={onMoreOffersClick} showLoader={isLoading}>
            {i18n.t('order__shopping__offer__accepted__review__more__offers')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default OfferAccepted
