import { HashMap } from '../../utils/HashMap'
import { LoadedLoadingErrorState, LoadingErrorState, initialLoadingErrorState } from '../../utils/state'

export enum NoteContexts {
  order = 'order',
  client = 'client',
  user = 'user',
  userClient = 'user-client',
}

export interface Note {
  id: number
  created_at: string
  updated_at: string
  deleted_at: string | null
  expiration_date: string | null
  completion_date: string | null
  context: NoteContexts
  context_id: number
  author_id: number
  writer_visible: boolean
  writer_flag: number
  note: string
  author: string | null
  expert: string | null
  client: string | null
}

export interface CreateNotePayload {
  writer_visible?: boolean
  writer_flag?: number
  expiration_date?: string | null
  note: string
}

export interface UpdateNotePayload extends Partial<CreateNotePayload> {
  completion_date?: string | null
}

interface ContextMeta {
  [NoteContexts.order]: HashMap<LoadedLoadingErrorState>
  [NoteContexts.client]: HashMap<LoadedLoadingErrorState>
  [NoteContexts.user]: HashMap<LoadedLoadingErrorState>
  [NoteContexts.userClient]: HashMap<LoadedLoadingErrorState>
}

interface NotesMeta {
  fetch: ContextMeta
  delete: LoadingErrorState
  create: LoadingErrorState
  update: LoadingErrorState
}

export interface NotesState {
  [NoteContexts.order]: HashMap<HashMap<Note>>
  [NoteContexts.client]: HashMap<HashMap<Note>>
  [NoteContexts.user]: HashMap<HashMap<Note>>
  [NoteContexts.userClient]: HashMap<HashMap<Note>>
  meta: NotesMeta
}

export const initialState: NotesState = {
  [NoteContexts.order]: {},
  [NoteContexts.client]: {},
  [NoteContexts.user]: {},
  [NoteContexts.userClient]: {},
  meta: {
    fetch: {
      [NoteContexts.order]: {},
      [NoteContexts.client]: {},
      [NoteContexts.user]: {},
      [NoteContexts.userClient]: {},
    },
    delete: { ...initialLoadingErrorState },
    create: { ...initialLoadingErrorState },
    update: { ...initialLoadingErrorState },
  },
}
