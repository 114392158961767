import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import TalentIncLogo from '../../assets/loader.gif'

interface Props {
  fullPage?: boolean
}

const useStyles = makeStyles(() => ({
  '@keyframes logoWipe': {
    '0%': {
      left: 0,
    },
    '100%': {
      left: '100%',
    },
  },
  wrapper: {
    position: 'relative',
  },
  logo: {
    position: 'absolute',
  },
  fullPage: {
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  fullPageWrapper: {
    height: '100%',
  },
}))

const Loader: React.FC<Props> = ({ fullPage = false }) => {
  const classes = useStyles()

  return (
    <>
      <div
        className={classNames({
          [classes.wrapper]: true,
          [classes.fullPage]: fullPage,
          [classes.fullPageWrapper]: fullPage,
        })}
      >
        <img
          className={classNames({
            [classes.logo]: true,
            [classes.fullPage]: fullPage,
          })}
          src={TalentIncLogo}
          alt="Talent Inc. Logo as a loader"
        />
      </div>
    </>
  )
}

export default Loader
