import { HashMap } from '../../utils/HashMap'
import { MultiLoadedLoadingErrorState } from '../../utils/state'

export interface CritiqueState {
  critiques: HashMap<Critique>
  meta: MultiLoadedLoadingErrorState
}

export interface ChildIndustry {
  name: string
  percent_of_overall: string
  percent_of_parent: string
}

export interface Industry {
  name: string
  percent_of_overall: string
  child_industries?: ChildIndustry[]
}

export interface Competency {
  name: string
  months: number
}

export interface FirstJob {
  employer_name: string
  title: string
}

export interface Address {
  address_line?: string | null
  city?: string | null
  region?: string | null
  country?: string | null
  postal_code?: string | null
}

export interface AvgMonthsOfEmployment {
  average_length_of_employment: number
}

export interface FileInfo {
  extension: string
  file_type: string
  name: string
  percentile: number
  size: number
  thumbnails: string[]
}

export interface Border {
  border: boolean
  border_score: number
  error: boolean
  lang: string
  performance: number[]
  version: string
}

export interface Density {
  Actual: number
  Dense: boolean
  Ideal: number
  Sparse: boolean
}

export interface Margin {
  Actual: number
  Ideal: number
  Narrow: boolean
  Wide: boolean
}

export interface MarginProblem {
  Bottom: Margin
  ImageUrl: string
  Left: Margin
  Right: Margin
  Top: Margin
}

export interface UniqueTitle {
  percent_more_common: number
  region: string
  title: string
}

export interface ImageBoundingBox {
  ImageUrl: string
  PhraseBoundingBoxes: unknown[]
  SectionBoundingBoxes?: unknown
}

export interface MonthsOutOfWork {
  months_out_of_work: number
}

export interface WordCount {
  word_count: number
}

export interface TooManyPages {
  page_count: number
  years_of_work_experience: number
}

interface Threshold {
  threshold?: number
}

interface WorkExperience {
  years_of_work_experience: number
}

interface CritiqueAnswer<T = undefined> {
  critique_bit: boolean
  critique_text: string[]
  extras: T
}

export enum AnswerKeys {
  avg_months_of_employment = 'avg_months_of_employment',
  bullet_points_just_right = 'bullet_points_just_right',
  bullet_points_too_few = 'bullet_points_too_few',
  bullet_points_too_many = 'bullet_points_too_many',
  employment_gaps = 'employment_gaps',
  employment_overlap = 'employment_overlap',
  file_info = 'file_info',
  file_size_just_right = 'file_size_just_right',
  file_size_too_long = 'file_size_too_long',
  file_size_too_small = 'file_size_too_small',
  has_border = 'has_border',
  has_both_summary_and_objective = 'has_both_summary_and_objective',
  has_density_problem = 'has_density_problem',
  has_inconsistent_punctuation = 'has_inconsistent_punctuation',
  has_linkedin_profile = 'has_linkedin_profile',
  has_margin_problem = 'has_margin_problem',
  has_monotonous_words = 'has_monotonous_words',
  has_name = 'has_name',
  has_no_objective_and_no_summary = 'has_no_objective_and_no_summary',
  has_objective = 'has_objective',
  has_objective_and_no_summary = 'has_objective_and_no_summary',
  has_old_job = 'has_old_job',
  has_phone = 'has_phone',
  has_postal_address = 'has_postal_address',
  has_references = 'has_references',
  has_unique_title = 'has_unique_title',
  is_doer = 'is_doer',
  is_nearly_perfect = 'is_nearly_perfect',
  is_stale_resume = 'is_stale_resume',
  is_troubled = 'is_troubled',
  just_right_words = 'just_right_words',
  less_than_three_years = 'less_than_three_years',
  resume_score = 'resume_score',
  summary_just_right = 'summary_just_right',
  summary_too_long = 'summary_too_long',
  summary_too_short = 'summary_too_short',
  three_or_more_years = 'three_or_more_years',
  too_few_words = 'too_few_words',
  too_many_pages = 'too_many_pages',
  too_many_words = 'too_many_words',
  word_count_info = 'word_count_info',
}

export type AnswerKey = keyof typeof AnswerKeys

export interface StructuredCritiqueAnswer {
  key: AnswerKey
  value: string[]
}

export const CritiqueCategories: HashMap<AnswerKeys[]> = {
  content: [
    AnswerKeys.employment_gaps,
    AnswerKeys.file_size_too_long,
    AnswerKeys.has_both_summary_and_objective,
    AnswerKeys.has_monotonous_words,
    AnswerKeys.has_no_objective_and_no_summary,
    AnswerKeys.has_references,
    AnswerKeys.is_doer,
    AnswerKeys.less_than_three_years,
    AnswerKeys.three_or_more_years,
  ],
  formatting: [
    AnswerKeys.bullet_points_too_few,
    AnswerKeys.bullet_points_too_many,
    AnswerKeys.too_many_pages,
    AnswerKeys.too_many_words,
  ],
}

export interface Answers {
  [AnswerKeys.avg_months_of_employment]?: CritiqueAnswer<AvgMonthsOfEmployment> | null
  [AnswerKeys.bullet_points_just_right]?: CritiqueAnswer | null
  [AnswerKeys.bullet_points_too_few]?: CritiqueAnswer | null
  [AnswerKeys.bullet_points_too_many]?: CritiqueAnswer | null
  [AnswerKeys.employment_gaps]?: CritiqueAnswer | null
  [AnswerKeys.employment_overlap]?: CritiqueAnswer<string[]> | null
  [AnswerKeys.file_info]?: CritiqueAnswer<FileInfo> | null
  [AnswerKeys.file_size_just_right]?: CritiqueAnswer<Threshold> | null
  [AnswerKeys.file_size_too_long]?: CritiqueAnswer<Threshold> | null
  [AnswerKeys.file_size_too_small]?: CritiqueAnswer<Threshold> | null
  [AnswerKeys.has_border]?: CritiqueAnswer<Border> | null
  [AnswerKeys.has_both_summary_and_objective]?: CritiqueAnswer | null
  [AnswerKeys.has_density_problem]?: CritiqueAnswer<Density> | null
  [AnswerKeys.has_inconsistent_punctuation]?: CritiqueAnswer | null
  [AnswerKeys.has_linkedin_profile]?: CritiqueAnswer | null
  [AnswerKeys.has_margin_problem]?: CritiqueAnswer<MarginProblem> | null
  [AnswerKeys.has_monotonous_words]?: CritiqueAnswer<string[]> | null
  [AnswerKeys.has_name]?: CritiqueAnswer | null
  [AnswerKeys.has_no_objective_and_no_summary]?: CritiqueAnswer | null
  [AnswerKeys.has_objective]?: CritiqueAnswer | null
  [AnswerKeys.has_objective_and_no_summary]?: CritiqueAnswer | null
  [AnswerKeys.has_old_job]?: CritiqueAnswer | null
  [AnswerKeys.has_phone]?: CritiqueAnswer | null
  [AnswerKeys.has_postal_address]?: CritiqueAnswer | null
  [AnswerKeys.has_references]?: CritiqueAnswer | null
  [AnswerKeys.has_unique_title]?: CritiqueAnswer<UniqueTitle> | null
  [AnswerKeys.is_doer]?: CritiqueAnswer<ImageBoundingBox> | null
  [AnswerKeys.is_nearly_perfect]?: CritiqueAnswer | null
  [AnswerKeys.is_stale_resume]?: CritiqueAnswer<MonthsOutOfWork> | null
  [AnswerKeys.is_troubled]?: CritiqueAnswer | null
  [AnswerKeys.just_right_words]?: CritiqueAnswer<Threshold> | null
  [AnswerKeys.less_than_three_years]?: CritiqueAnswer | null
  [AnswerKeys.resume_score]?: CritiqueAnswer | null
  [AnswerKeys.summary_just_right]?: CritiqueAnswer<WordCount> | null
  [AnswerKeys.summary_too_long]?: CritiqueAnswer<WordCount> | null
  [AnswerKeys.summary_too_short]?: CritiqueAnswer<WordCount> | null
  [AnswerKeys.three_or_more_years]?: CritiqueAnswer | null
  [AnswerKeys.too_few_words]?: CritiqueAnswer<Threshold> | null
  [AnswerKeys.too_many_pages]?: CritiqueAnswer<TooManyPages> | null
  [AnswerKeys.too_many_words]?: CritiqueAnswer<Threshold> | null
  [AnswerKeys.word_count_info]?: CritiqueAnswer<WorkExperience> | null
}

interface EmploymentHistory {
  start_date: null | string
  end_date: null | string
  org_name: string
  title: string
  country_code: string
  region: string
  municipality: string
  description: string
}

interface EducationHistory {
  start_date?: null | string
  end_date?: null | string
  school_type: string
  school_name: string
  country_code: string
  region: string
  municipality: string
  degree_type: string
  degree_name: string
  comments: string
  degree_major: string
  degree_minor: string
  degree_date?: string | null
}

export interface Critique {
  parsed: boolean
  references: boolean
  industries: Industry[]
  competencies: Competency[]
  full_name: string
  first_name: string
  last_name: string
  phone_number: string
  email_address?: string | null
  first_job: FirstJob
  ancient: boolean
  first_job_title: string
  first_job_employer_name: string
  first_school_name: string
  address: Address
  description: string
  total_experience_years: string
  management_score: string
  proper_name_casing: boolean
  type: string
  answers: Answers
  management_story?: string | null
  most_recent_degree_type: string
  years_of_management_experience: string
  current_management_level?: string | null
  lead_score: number
  employment_history: EmploymentHistory[]
  education_history: EducationHistory[]
  detected_language: string
}
