import { AxiosError } from 'axios'
import produce from 'immer'

import { USER, UserActionTypes } from './actions'
import { ONBOARDING } from '../onboarding/actions'
import { PaymentTypes } from '../../components/Onboarding'
import { Currency } from '../../utils/formatting'

export interface UserInfo {
  name: string
  name_last: string
  uname: string
  id: number
  admin: boolean
  email_signature: string
  email: string
  enabled: boolean
  payment_currency: Currency
  phone: string
  timezone_string: string
  groups: Groups[]
  // timestamp
  completed_onboarding: string | null
  onboard_date: string
  payment_type: PaymentTypes
  tag_keys: string[]
}

export type userId = number | 'me'
export enum Groups {
  Admin = 'Admin',
  Developer = 'Developer',
  Expert = 'Expert',
  FCC_Admin = 'FCC-Admin',
  FCC_Staff = 'FCC-Staff',
  Ops = 'Ops',
  Offshore = 'Offshore',
  QA = 'QA',
  Staff = 'Staff',
  Nexxt = 'Nexxt',
}

export enum FeatureTags {
  OnboardingMentee = 'onboarding-mentee',
  NewHire = 'new-hire',
  Offshote = 'offshore',
  EnWriter = 'language:en',
  DeWriter = 'language:de',
  StaffWriter = 'staff-writer',
  Offshore = 'offshore',
  Pakistan = 'pakistan',
  SouthAfricaWriter = 'south-africa-writer',
  GermanWriter = 'german-writer',
  Mango5 = 'mango5',
  LowOutput = 'low-output',
  MediumOutput = 'medium-output',
  HighOutput = 'high-output',
  Inactive = 'inactive',
  ZipJobWriter = 'zipjob-writer',
  ZipJobTop30Writer = 'zipjob-top-30',
  ZipJobNewWriter = 'zipjob-new',
  QAEditor = 'qa-editor',
  InterviewCoach = 'interview-prep',
  PhoneCalls = 'phone-call',
  OffshorePhoneCall = 'offshore-phone-call',
  AQASample = 'aqa-sample',
  NoPhoneCalls = 'no-phone-call',
  AutoResearch = 'auto-research',
  AIResume = 'ai-resume',
  VideoCritique = 'video-critique',
  LowCostIc = 'low-cost-ic',
}

export const TagsOnboardingMentee = FeatureTags.OnboardingMentee
export const TagsNewHire = FeatureTags.NewHire
export const TagsOffshore = FeatureTags.Offshore

export const TagsDeWriter = FeatureTags.DeWriter
export const TagsEnWriter = FeatureTags.EnWriter

export const TagsPK = FeatureTags.Pakistan
export const TagsSouthAfricaWriter = FeatureTags.SouthAfricaWriter
export const TagsGermanWriter = FeatureTags.GermanWriter
export const TagsMango5 = FeatureTags.Mango5

export const TagsLowOutput = FeatureTags.LowOutput
export const TagsMediumOutput = FeatureTags.MediumOutput
export const TagsHighOutput = FeatureTags.HighOutput
export const TagsInactive = FeatureTags.Inactive

export const TagsZipJobWriter = FeatureTags.ZipJobWriter
export const TagsZipJobTop30Writer = FeatureTags.ZipJobTop30Writer
export const TagsZipJobNewWriter = FeatureTags.ZipJobNewWriter

export const TagsInterviewCoach = FeatureTags.InterviewCoach
export const TagsPhoneCalls = FeatureTags.PhoneCalls
export const TagsEditor = FeatureTags.QAEditor

export interface UsersList {
  [userID: number]: UserInfo
}

export interface UserState {
  isLoading: boolean
  error: string | AxiosError | null
  loggedInUserID: number | null
  users: UsersList
  getLoggedInUser: () => UserInfo | null
}

const initialState: UserState = {
  isLoading: false,
  error: null,
  loggedInUserID: null,
  users: {},
  getLoggedInUser: function(): UserInfo | null {
    return this.loggedInUserID !== null ? this.users[this.loggedInUserID] : null
  },
}

export default function userReducer(state: UserState = initialState, action: UserActionTypes): UserState {
  return produce(state, (draft: UserState) => {
    switch (action.type) {
      case USER.FETCH_USER:
        draft.isLoading = true
        draft.error = null
        break

      case USER.FETCH_USER_SUCCESS:
        const user = action.payload.data
        draft.isLoading = false
        draft.error = null
        draft.users[user.id] = user

        // If we implicitly fetched the currently logged in user, set the ID in the store
        if (action.meta.previousAction.targetUserID === 'me') {
          draft.loggedInUserID = user.id
        }

        break

      case USER.FETCH_USER_FAILURE:
        draft.isLoading = false
        draft.error = action.error
        break

      case USER.UPDATE_USER:
        draft.isLoading = true
        draft.error = null
        break

      case USER.UPDATE_USER_SUCCESS:
        const updatedUser = action.payload.data
        draft.isLoading = true
        draft.error = null
        draft.users[updatedUser.id] = updatedUser

        if (action.meta.previousAction.targetUserID === 'me' && draft.loggedInUserID === null) {
          draft.loggedInUserID = updatedUser.id
        }

        break

      case USER.UPDATE_USER_FAILURE:
        draft.isLoading = false
        draft.error = action.error
        break

      case ONBOARDING.END_USER_ONBOARDING_SUCCESS:
        const data = action.payload.data
        const userID = action.meta.previousAction.targetUserID as number
        draft.users[userID].completed_onboarding = data.finished_at
        break
    }
  })
}
