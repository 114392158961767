import { AutoResearchState } from './reducer'
import { initialLoadedLoadingErrorState, LoadedLoadingErrorState } from '../../utils/state'
import { AUTO_RESEARCH } from './actions'
import {
  ClientJobTitle,
  ClientSummaryData,
  ManualSearchResult,
  ONETJobsList,
  ONETSearchResult,
  WorkHistory,
} from './types'
import { HashMap } from '../../utils/HashMap'

export const getJobHistory = (state: AutoResearchState, clientID: number): ClientJobTitle[] => {
  try {
    return Object.values(state.clients[clientID].jobHistory)
  } catch (e) {
    return []
  }
}

export const getONETJobDetails = (state: AutoResearchState): ONETJobsList => {
  return state.onetDetails
}

export const getJobHistoryMeta = (state: AutoResearchState, clientID: number): LoadedLoadingErrorState => {
  try {
    return state.meta[AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA].individual[clientID]
  } catch (e) {
    return initialLoadedLoadingErrorState
  }
}

export const getJobsInfoMeta = (state: AutoResearchState, clientID: number): LoadedLoadingErrorState => {
  try {
    return state.meta[AUTO_RESEARCH.FETCH_JOB_DETAILS].individual[clientID]
  } catch (e) {
    return initialLoadedLoadingErrorState
  }
}

export const getJobGoal = (state: AutoResearchState, clientID: number): string | undefined | null => {
  try {
    return state.clients[clientID].jobGoal
  } catch (e) {
    return null
  }
}

export const getCustomSearch = (state: AutoResearchState, clientID: number): ManualSearchResult[] => {
  try {
    return Object.values(state.clients[clientID].manualSearchResults)
  } catch (e) {
    return []
  }
}

export const allSearchResults = (state: AutoResearchState): HashMap<ONETSearchResult> => state.search
export const currentSearchResult = (state: AutoResearchState, keyword: string): ONETSearchResult =>
  state.search[keyword]
export const getSearchMeta = (state: AutoResearchState): LoadedLoadingErrorState => state.meta[AUTO_RESEARCH.SEARCH]

export const getWorkHistory = (state: AutoResearchState, clientID: number): HashMap<WorkHistory> | undefined => {
  try {
    return state.clients[clientID].workHistory
  } catch (e) {
    return {}
  }
}

export const getWorkHistoryMeta = (state: AutoResearchState, clientID: number): LoadedLoadingErrorState => {
  try {
    return state.meta[AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY].individual[clientID]
  } catch (e) {
    return initialLoadedLoadingErrorState
  }
}

export const getSummaryData = (state: AutoResearchState, clientID: number): ClientSummaryData | undefined | null => {
  try {
    return state.clients[clientID].summaryData
  } catch (e) {
    return null
  }
}

export const getSummaryDataMeta = (state: AutoResearchState, clientID: number): LoadedLoadingErrorState => {
  try {
    return state.meta[AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA].individual[clientID]
  } catch (e) {
    return initialLoadedLoadingErrorState
  }
}
