import { UserDashboardState } from './types'
import * as stateUtils from '../../utils/state'
import { DASHBOARD, DashboardActionTypes } from './actions'
import produce from 'immer'
import { initialMultiLoadedLoadingErrorState } from '../../utils/state'

const initialState: UserDashboardState = {
  macro: {
    users: {},
    meta: initialMultiLoadedLoadingErrorState,
  },
  relative: {
    users: {},
    meta: initialMultiLoadedLoadingErrorState,
  },
  earnings: {
    users: {},
    meta: initialMultiLoadedLoadingErrorState,
  },
  industries: {
    users: {},
    meta: initialMultiLoadedLoadingErrorState,
  },
}

export default function dashboardReducer(
  state: UserDashboardState = initialState,
  action: DashboardActionTypes
): UserDashboardState {
  return produce(state, (draft: UserDashboardState) => {
    switch (action.type) {
      case DASHBOARD.FETCH_MACRO_STATS:
        draft.macro.meta = stateUtils.setStartStateForItem(action.targetUserID, state.macro.meta)
        break
      case DASHBOARD.FETCH_MACRO_STATS_SUCCESS:
        const userID = action.payload.data.user_id
        draft.macro.users[userID] = action.payload.data
        draft.macro.meta = stateUtils.setSuccessStateForItem(userID, state.macro.meta)
        break
      case DASHBOARD.FETCH_MACRO_STATS_FAIL: {
        const userID = action.meta.previousAction.targetUserID
        draft.macro.meta = stateUtils.setErrorStateForItem(userID, state.macro.meta, action.error)
        break
      }
      case DASHBOARD.FETCH_EARNINGS_STATS:
        draft.earnings.meta = stateUtils.setStartStateForItem(action.targetUserID, state.earnings.meta)
        break
      case DASHBOARD.FETCH_EARNINGS_STATS_SUCCESS: {
        const userID = action.payload.data.user_id
        draft.earnings.users[userID] = action.payload.data
        draft.earnings.meta = stateUtils.setSuccessStateForItem(userID, state.earnings.meta)
        break
      }
      case DASHBOARD.FETCH_EARNINGS_STATS_FAIL: {
        const userID = action.meta.previousAction.targetUserID
        draft.earnings.meta = stateUtils.setErrorStateForItem(userID, state.earnings.meta, action.error)
        break
      }
      case DASHBOARD.FETCH_INDUSTRIES_STATS: {
        draft.industries.meta = stateUtils.setStartStateForItem(action.targetUserID, state.industries.meta)
        break
      }
      case DASHBOARD.FETCH_INDUSTRIES_STATS_SUCCESS: {
        const userID = action.payload.data.user_id
        draft.industries.users[userID] = action.payload.data
        draft.industries.meta = stateUtils.setSuccessStateForItem(userID, state.industries.meta)
        break
      }
      case DASHBOARD.FETCH_INDUSTRIES_STATS_FAIL: {
        const userID = action.meta.previousAction.targetUserID
        draft.industries.meta = stateUtils.setErrorStateForItem(userID, state.industries.meta, action.error)
        break
      }
      case DASHBOARD.FETCH_RELATIVE_STATS: {
        draft.relative.meta = stateUtils.setStartStateForItem(action.targetUserID, state.relative.meta)
        break
      }
      case DASHBOARD.FETCH_RELATIVE_STATS_SUCCESS: {
        const userID = action.payload.data.user_id
        draft.relative.users[userID] = action.payload.data
        draft.relative.meta = stateUtils.setSuccessStateForItem(userID, state.relative.meta)
        break
      }
      case DASHBOARD.FETCH_RELATIVE_STATS_FAIL: {
        const userID = action.meta.previousAction.targetUserID
        draft.relative.meta = stateUtils.setErrorStateForItem(userID, state.relative.meta, action.error)
        break
      }
    }
  })
}
