import React, { FC, useEffect, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import i18n from '../../i18n'
import { format } from 'date-fns'

export interface OfferTimerProps {
  upperBound: Date
  activeThresholdMs: number
  onTimerEnd?: () => void
}

export const OfferTimer: FC<OfferTimerProps> = ({ upperBound, activeThresholdMs = 1000 * 60 * 5, onTimerEnd }) => {
  // @ts-ignore
  const diff = new Date(upperBound - new Date()).getTime() - 30 * 1000

  const [value, setValue] = useState(activeThresholdMs)
  const [isActive, setIsActive] = useState(false)
  const [isUnderMinute, setIsUnderMinute] = useState(false)

  // If first render, do a setTimeout that will trigger the second setInteral below
  useEffect(() => {
    if (isUnderMinute) {
      return
    }

    const timeout = window.setTimeout(() => {
      setIsUnderMinute(true)
      setIsActive(true)
    }, diff - 1000 * 60)
    return () => clearInterval(timeout)
  }, [diff, isUnderMinute, setIsUnderMinute])

  // If under a minute, update every second
  useEffect(() => {
    if (!isUnderMinute) {
      return
    }

    const interval = window.setInterval(() => {
      setValue(value => value - 1000)
      if (!isActive && value <= activeThresholdMs) {
        setIsActive(true)
      }
    }, 1000)

    if (value <= 0) {
      clearInterval(interval)
      setIsActive(false)
      if (!!onTimerEnd) {
        onTimerEnd()
      }
    }

    return () => clearInterval(interval)
  }, [isActive, value, activeThresholdMs, onTimerEnd, isUnderMinute])

  return isActive ? (
    <Box>
      <Typography variant={'body1'}>
        {i18n.t('order__shopping__stats__time__remaining')} {format(value, 'mm:ss')}
      </Typography>
    </Box>
  ) : null
}
