import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '../common/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import i18n from '../../i18n'
import { Box } from '@material-ui/core'
import styles from '../../styles'

export interface MalwareDialogProps {
  isOpen: boolean
  handleClose: (event: React.SyntheticEvent<EventTarget>) => void
  maliciousUrls: string[]
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '500px',
      padding: '1.5em',
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '0 2em 0 0',
      marginBottom: '1em',
    },
    modalTitle: {
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: '1.25em',
      lineHeight: 1.25,
      color: styles.palette.shadowedSelect.color,
    },
    modalBody: {
      fontSize: '.9em',
      fontWeight: 300,
      marginBottom: '1em',
      color: styles.palette.shadowedSelect.color,
    },
    buttonsRight: {
      float: 'right',
    },
    buttonsCentered: {
      justifyContent: 'space-around',
    },
    closeIcon: {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      width: '1rem',
      height: '1rem',
    },
  })
)

const MalwareDialog: React.FC<MalwareDialogProps> = ({ isOpen, handleClose, maliciousUrls }) => {
  const classes = useStyles()

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Box className={classes.root}>
        <DialogTitle disableTypography={true} classes={{ root: classes.title }}>
          <Typography className={classes.modalTitle}>{<>{i18n.t('messaging__malware_scan_title')}</>}</Typography>
        </DialogTitle>
        <Typography className={classes.modalBody}>{<>{i18n.t('messaging__malware_scan_body')}</>}</Typography>
        {!!maliciousUrls &&
          maliciousUrls.map(url => (
            <Typography key={url} color="error" className={classes.modalBody}>
              {url}
            </Typography>
          ))}
        <DialogActions className={classes.buttonsRight}>
          <Button type="contrast" onClick={handleClose}>
            <Typography>{<>{i18n.t('Close')}</>}</Typography>
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default MalwareDialog
