import { FormFieldSchema } from './formSchema'
import { AutoLinkedinSections } from '../../store/autolinkedin/types'
import React from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import TextField from '@material-ui/core/TextField'
import i18n from '../../i18n'
import { FieldArray } from 'react-final-form-arrays'

export interface FieldsOfStudyFieldProps {
  fieldSchema: FormFieldSchema
  index: number
  inputClass: string
  inputGroupClass: string
  labelClass: string
  noPadding: string
  outerName: string
  required: boolean
  section: AutoLinkedinSections
}

export const FieldsOfStudyField: React.FC<FieldsOfStudyFieldProps> = ({
  fieldSchema,
  inputClass,
  inputGroupClass,
  labelClass,
  noPadding,
  outerName,
  required,
  section,
}) => {
  return (
    <FieldArray name={`${outerName}.${fieldSchema.name}`}>
      {({ fields }) =>
        fields.map(name => {
          return (
            <Field name={`${name}.name`} key={`${name}.name`} required={required}>
              {(props: FieldRenderProps<string>) => (
                <TextField
                  {...props.input}
                  className={inputGroupClass}
                  InputLabelProps={{ className: labelClass }}
                  InputProps={{ className: inputClass }}
                  inputProps={{ className: noPadding }}
                  label={i18n.t(`alink__${section}__${fieldSchema.name}__name`)}
                />
              )}
            </Field>
          )
        })
      }
    </FieldArray>
  )
}

export default FieldsOfStudyField
