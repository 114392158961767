import React from 'react'
import Linkify from 'linkifyjs/react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

import i18n from '../../../i18n'
import styles from '../../../styles'
import DefaultFavicon from '../../common/Icons/BullseyeArrow'
import classNames from 'classnames'

interface CardProps {
  id: number
  link: string
  expanded: boolean
  handleExpandClick: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      height: '3rem',
      border: '1px solid #d5d9e0',
      fontSize: '0.8125rem',
      padding: '0 .875rem',
      boxSizing: 'border-box',
      borderRadius: '.25rem',
      '& > :first-child': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      },
      '& .image': {
        display: 'flex',
        justifyContent: 'center',
        width: '1.75rem',
        minWidth: '1.75rem',
        color: '#000',
        '& > :first-child': {
          width: 'auto',
          maxWidth: '1.75rem',
          maxHeight: '1.75rem',
        },
      },
      '& .link': {
        width: 'calc(100% - 2.2rem)',
        marginLeft: '.4rem',
        flexGrow: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&.underlined': { textDecoration: 'underline' },
      },
      '& .expand': {
        cursor: 'pointer',
        paddingLeft: '.4rem',
        whiteSpace: 'nowrap',
      },
      '& .contract': {
        cursor: 'pointer',
        marginLeft: '.4rem',
      },
      '&.full': {
        height: 'auto',
        padding: '.875rem',
        '& .link': { whiteSpace: 'normal', wordBreak: 'break-word' },
      },
    },
    anchor: {
      color: styles.palette.link.color,
    },
  })
)

const JobCard: React.FC<CardProps> = React.memo(({ id, link, expanded, handleExpandClick }) => {
  const classes = useStyles()
  const maxTextLength = 16
  let parsedURL: URL | null = null
  let isLink = false

  // Because of the freeform nature of this question, it can sometimes be a URL but other times, it might just be  free
  // form text with maybe a URL. We can determine this by casting the value to a URL object. If it is a URL, we will
  // modify the URL to possibly grab the favicon for the website. If it isn't we will just wrap the text with Linkify
  // so that the link is clickable.
  try {
    parsedURL = new URL(link)
    isLink = true
  } catch (e) {
    parsedURL = null
  }

  const [favicon, setFavicon] = React.useState<null | string>(parsedURL ? `${parsedURL.origin}/favicon.ico` : null)

  // @TODO We need to set a default icon here
  const faviconErrorHandler = () => {
    setFavicon('')
  }

  const renderFavicon = () => (
    <Box className="image">
      {favicon && parsedURL ? (
        <img
          src={favicon}
          alt={i18n.t('order__page__jobcard__alt__text', { host: parsedURL.host })}
          onError={faviconErrorHandler}
        />
      ) : (
        <DefaultFavicon />
      )}
    </Box>
  )

  return (
    <Box className={classNames({ [classes.container]: true, full: expanded })}>
      {isLink && !!parsedURL && (
        <a className={classes.anchor} href={link} target="_blank" rel="noopener noreferrer">
          {renderFavicon()}
          <Typography display="block" variant={'h5'} className="link underlined">
            {`${i18n.t('order__page__jobcard__jobTarget')} ${id + 1}`}
          </Typography>
        </a>
      )}
      {!isLink && (
        <Linkify
          options={{
            attributes: { rel: 'noopener noreferrer', target: '_blank' },
          }}
        >
          {renderFavicon()}
          <Typography display="block" variant={'h5'} className="link">
            {link}
            {expanded && (
              <Link onClick={handleExpandClick} className="contract">
                ({i18n.t('see__less')})
              </Link>
            )}
          </Typography>
          {!expanded && link.length > maxTextLength && (
            <Link onClick={handleExpandClick} className="expand">
              {i18n.t('see__more')}
            </Link>
          )}
        </Linkify>
      )}
    </Box>
  )
})

// @ts-ignore
JobCard.whyDidYouRender = true

export default JobCard
