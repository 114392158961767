import { AxiosError } from 'axios'
import { BasePay } from '../orders/types'
import { ZoomMeetingData } from '../scheduling/types'

export interface OrderItemState {
  itemsByOrderId: HashMap<HashMap<OrderItem>>
  itemsMeetingInfo: HashMap<ZoomMeetingData>
  isLoading: boolean
  error: AxiosError | string | null
}

export type HashMap<T> = { [key in string | number]: T }

export interface OrderItem {
  id: number
  created_at: string
  core_product_id: number
  core_product_key: OrderItemTypes
  compound_item_key: string
  order_id: number
  name: string
  writer_pay: BasePay[]
  tag_keys: string[]
  requires_document: boolean
  meeting_id: number | null
  meeting_occurred: boolean
  meeting_scheduled_at: Date | null
  meeting_scheduled_for: Date | null
  meeting_started_at: Date | null
  meeting_ended_at: Date | null
  has_transcript: boolean
  has_audio: boolean
  has_video: boolean
  status: {
    active: OrderItemStatus[]
    completed: OrderItemStatus[]
  }
  incentives: AppliedIncentive[]
}

export interface AppliedIncentive {
  incentive_id: number
  dynamic_payment_id: number
  name: string
  internal_name: string
  amount: number
}

export interface OrderItemStatus {
  id: number
  ended_at?: string
  due_at?: string
  state: OrderItemStates
  product_id: number
  started_at?: string
}

export enum OrderItemStates {
  SendIntro = 'intro-email',
  SendFirstDraft = 'first-draft',
  SendMessage = 'send-message',
  UploadForEditor = 'upload-editor',
  AwaitingReview = 'awaiting-review',
  Revisions = 'revision',
  ConductCall = 'call',
  ConductInterview = 'interview',
  SendSummary = 'send-summary',
  AwaitingScheduling = 'awaiting-scheduling',
  AwaitingConfirmation = 'awaiting-confirmation',
  SchedulePhoneCall = 'schedule-phone',
  ScheduleInterview = 'schedule-interview',
  Closed = 'complete',
  AwaitingReply = 'awaiting-reply',
  SendLinkedin = 'send-linkedin',
  Canceled = 'canceled',
  Refunded = 'refunded',
  AwaitingResume = 'awaiting-resume',
  Paid = 'paid',
  Reassigned = 'cloned',
  Rush = 'rush-item',
  RescheduleMessage = 'reschedule-message',
}

export enum OrderItemDisplayStates {
  CloseOrder = 'close-order',
  None = 'none',
}

export type OrderCTA = OrderItemStates | OrderItemDisplayStates

export const DisplayPriorityStateMap: HashMap<number> = {
  [OrderItemStates.Refunded]: -1,
  [OrderItemStates.SendIntro]: -1,
  [OrderItemStates.UploadForEditor]: 1,
  [OrderItemStates.AwaitingReview]: 1,
  [OrderItemStates.SendFirstDraft]: 2,
  [OrderItemStates.SchedulePhoneCall]: 2,
  [OrderItemStates.SendSummary]: 2,
  [OrderItemStates.SendMessage]: 2,
  [OrderItemStates.ConductCall]: 2,
  [OrderItemStates.ConductInterview]: 3,
  [OrderItemStates.ScheduleInterview]: 4,
  [OrderItemStates.AwaitingReply]: 4,
  [OrderItemStates.Revisions]: 5,
  [OrderItemStates.SendLinkedin]: 6,
  [OrderItemStates.AwaitingResume]: 6,
  [OrderItemStates.Closed]: 10000,
}

export enum OrderItemTypes {
  Resume = 'resume',
  TopInterview = 'top-interview',
  ThankYouNote = 'thank-you-note',
  CoverLetter = 'cover-letter',
  CV = 'cv',
  LinkedIn = 'linkedin-document',
  AdditionalDocument = 'additional-document',
  PostingService = 'posting-service',
  PhoneConsultation = 'personal-consultation',
  Rush = 'rush-order',
  Incentive = 'incentive-payment',
  OrderDetails = 'order-details',
  ManualPayment = 'manual-pay',
  ResumeEdit = 'resume-edit',
  None = '',
}

// @TODO Check & fix
export const OrderItemClassification: HashMap<number> = {
  [OrderItemTypes.Resume]: 1,
  [OrderItemTypes.TopInterview]: 1,
  [OrderItemTypes.ThankYouNote]: 1,
  [OrderItemTypes.CoverLetter]: 1,
  [OrderItemTypes.CV]: 1,
  [OrderItemTypes.AdditionalDocument]: 2,
  [OrderItemTypes.LinkedIn]: 2,
  [OrderItemTypes.PhoneConsultation]: 2,
  [OrderItemTypes.PostingService]: 3,
  [OrderItemTypes.Rush]: 3,
  [OrderItemTypes.Incentive]: 3,
}

// @TODO Check & fix (RevisionsRequested no longer used ?)
export enum OrderItemTimeDisplayStates {
  Done = 'done',
  Due = 'due',
  DueUrgent24Hours = 'urgent',
  RevisionRequested = 'revisions',
  Late = 'late',
}

export const OrderItemDisplayStateMap: HashMap<OrderItemTimeDisplayStates> = {
  [OrderItemStates.SendIntro]: OrderItemTimeDisplayStates.Due,
  [OrderItemStates.SendFirstDraft]: OrderItemTimeDisplayStates.Due,
  [OrderItemStates.ConductCall]: OrderItemTimeDisplayStates.Due,
  [OrderItemStates.ConductInterview]: OrderItemTimeDisplayStates.Due,
  [OrderItemStates.SchedulePhoneCall]: OrderItemTimeDisplayStates.Due,
  [OrderItemStates.ScheduleInterview]: OrderItemTimeDisplayStates.Due,
  [OrderItemStates.AwaitingResume]: OrderItemTimeDisplayStates.Due,
  [OrderItemStates.AwaitingScheduling]: OrderItemTimeDisplayStates.Due,
  [OrderItemStates.SendLinkedin]: OrderItemTimeDisplayStates.Due,
  [OrderItemStates.UploadForEditor]: OrderItemTimeDisplayStates.Due,

  [OrderItemStates.AwaitingReply]: OrderItemTimeDisplayStates.RevisionRequested,
  [OrderItemStates.Revisions]: OrderItemTimeDisplayStates.RevisionRequested,
  [OrderItemStates.SendMessage]: OrderItemTimeDisplayStates.RevisionRequested,
  [OrderItemStates.AwaitingReview]: OrderItemTimeDisplayStates.RevisionRequested,

  [OrderItemStates.Closed]: OrderItemTimeDisplayStates.Done,
  [OrderItemStates.Refunded]: OrderItemTimeDisplayStates.Done,
}
