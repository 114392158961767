import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import { CreateFeedbackPayload, FeatureFeedback, FeatureKeys } from './types'

export enum FeatureFeedbackActionTypes {
  CREATE_FEATURE_FEEDBACK = 'CREATE_FEATURE_FEEDBACK',
  CREATE_FEATURE_FEEDBACK_SUCCESS = 'CREATE_FEATURE_FEEDBACK_SUCCESS',
  CREATE_FEATURE_FEEDBACK_FAIL = 'CREATE_FEATURE_FEEDBACK_FAIL',
}

export interface CreateFeatureFeedback extends AxiosMiddlewareActionCreator {
  type: typeof FeatureFeedbackActionTypes.CREATE_FEATURE_FEEDBACK
  feature_key: FeatureKeys
}

export interface CreateFeatureFeedbackSuccess
  extends AxiosMiddlewareActionSuccess<FeatureFeedback[], CreateFeatureFeedback> {
  type: typeof FeatureFeedbackActionTypes.CREATE_FEATURE_FEEDBACK_SUCCESS
}

export interface CreateFeatureFeedbackFail extends AxiosMiddlewareActionFail<CreateFeatureFeedback> {
  type: typeof FeatureFeedbackActionTypes.CREATE_FEATURE_FEEDBACK_FAIL
}

export function createFeatureFeedback(feature_key: FeatureKeys, data: CreateFeedbackPayload): CreateFeatureFeedback {
  return {
    type: FeatureFeedbackActionTypes.CREATE_FEATURE_FEEDBACK,
    feature_key,
    payload: {
      request: {
        url: `v1/feedback`,
        method: 'POST',
        data,
      },
    },
  }
}

export type FeatureFeedbackActionType = CreateFeatureFeedback | CreateFeatureFeedbackSuccess | CreateFeatureFeedbackFail
