import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'

import { Notification, UINotification } from './types'
import { userId } from '../user/reducers'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AppState } from '../index'
import { AnyAction } from 'redux'
import { ResettableInterval } from '../cron/types'

export enum NotificationsActionTypes {
  FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_FAIL = 'FETCH_NOTIFICATIONS_FAIL',

  PATCH_NOTIFICATION = 'PATCH_NOTIFICATION',
  PATCH_NOTIFICATION_SUCCESS = 'PATCH_NOTIFICATION_SUCCESS',
  PATCH_NOTIFICATION_FAIL = 'PATCH_NOTIFICATION_FAIL',

  FETCH_NOTIFICATION = 'FETCH_NOTIFICATION',
  FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS',
  FETCH_NOTIFICATION_FAIL = 'FETCH_NOTIFICATION_FAIL',

  READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS',
  READ_ALL_NOTIFICATIONS_SUCCESS = 'READ_ALL_NOTIFICATIONS_SUCCESS',
  READ_ALL_NOTIFICATIONS_FAIL = 'READ_ALL_NOTIFICATIONS_FAIL',

  ADD_UI_NOTIFICATION = 'ADD_UI_NOTIFICATION',
  REMOVE_UI_NOTIFICATION = 'REMOVE_UI_NOTIFICATION',
}
export interface FetchNotifications extends AxiosMiddlewareActionCreator {
  type: typeof NotificationsActionTypes.FETCH_NOTIFICATIONS
}
export interface FetchNotificationsSuccess extends AxiosMiddlewareActionSuccess<Notification[], FetchNotifications> {
  type: typeof NotificationsActionTypes.FETCH_NOTIFICATIONS_SUCCESS
}
export interface FetchNotificationsFail extends AxiosMiddlewareActionFail {
  type: typeof NotificationsActionTypes.FETCH_NOTIFICATIONS_FAIL
}
export interface PatchNotification extends AxiosMiddlewareActionCreator {
  id: number
  type: NotificationsActionTypes.PATCH_NOTIFICATION
}
export interface PatchNotificationSuccess extends AxiosMiddlewareActionSuccess<Notification, PatchNotification> {
  type: NotificationsActionTypes.PATCH_NOTIFICATION_SUCCESS
}
export interface PatchNotificationFail extends AxiosMiddlewareActionFail<PatchNotification> {
  type: NotificationsActionTypes.PATCH_NOTIFICATION_FAIL
}
export interface FetchNotification extends AxiosMiddlewareActionCreator {
  id: number
  type: typeof NotificationsActionTypes.FETCH_NOTIFICATION
}
export interface FetchNotificationSuccess extends AxiosMiddlewareActionSuccess<Notification, FetchNotification> {
  type: typeof NotificationsActionTypes.FETCH_NOTIFICATION_SUCCESS
}
export interface FetchNotificationFail extends AxiosMiddlewareActionFail<FetchNotification> {
  type: typeof NotificationsActionTypes.FETCH_NOTIFICATION_FAIL
}

export interface ReadAllNotifications extends AxiosMiddlewareActionCreator {
  userID: userId
  type: typeof NotificationsActionTypes.READ_ALL_NOTIFICATIONS
}

export interface ReadAllNotificationsSuccess
  extends AxiosMiddlewareActionSuccess<Notification[], AxiosMiddlewareActionCreator> {
  type: typeof NotificationsActionTypes.READ_ALL_NOTIFICATIONS_SUCCESS
}

export interface ReadAllNotificationsFail extends AxiosMiddlewareActionFail<AxiosMiddlewareActionFail> {
  type: typeof NotificationsActionTypes.READ_ALL_NOTIFICATIONS_FAIL
}

export interface AddUiNotification {
  type: typeof NotificationsActionTypes.ADD_UI_NOTIFICATION
  notification: UINotification
}

export interface RemoveUiNotification {
  type: typeof NotificationsActionTypes.REMOVE_UI_NOTIFICATION
}

export function addUiNotification(notification: UINotification) {
  return {
    type: NotificationsActionTypes.ADD_UI_NOTIFICATION,
    notification,
  }
}

export function removeUiNotification() {
  return {
    type: NotificationsActionTypes.REMOVE_UI_NOTIFICATION,
  }
}

export type NotificationActionType =
  | FetchNotifications
  | FetchNotificationsSuccess
  | FetchNotificationsFail
  | PatchNotification
  | PatchNotificationSuccess
  | PatchNotificationFail
  | FetchNotification
  | FetchNotificationFail
  | FetchNotificationSuccess
  | ReadAllNotifications
  | ReadAllNotificationsSuccess
  | ReadAllNotificationsFail
  | AddUiNotification
  | RemoveUiNotification

export function fetchUserNotifications(userID: userId): FetchNotifications {
  return {
    type: NotificationsActionTypes.FETCH_NOTIFICATIONS,
    payload: {
      request: {
        url: `v1/notifications/user/${userID}`,
      },
    },
  }
}

export function fetchNotification(notificationID: number): FetchNotification {
  return {
    type: NotificationsActionTypes.FETCH_NOTIFICATION,
    id: notificationID,
    payload: {
      request: {
        url: `v1/notifications/id/${notificationID}`,
      },
    },
  }
}

export function markNotificationAsRead(notificationID: number): PatchNotification {
  return {
    type: NotificationsActionTypes.PATCH_NOTIFICATION,
    id: notificationID,
    payload: {
      request: {
        method: 'PATCH',
        url: `v1/notifications/id/${notificationID}`,
      },
    },
  }
}

export function markAllNotificationsAsRead(userID: userId): ReadAllNotifications {
  return {
    type: NotificationsActionTypes.READ_ALL_NOTIFICATIONS,
    userID: userID,
    payload: {
      request: {
        method: 'PATCH',
        url: `v1/notifications/user/${userID}`,
      },
    },
  }
}

export function getUserNotificationsTimed(
  interval: number
): ThunkAction<Promise<ResettableInterval>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    const loggedInUserID = getState().userReducer.loggedInUserID
    dispatch(fetchUserNotifications(!!loggedInUserID ? loggedInUserID : 'me'))
    const intervalID = window.setInterval(
      () => dispatch(fetchUserNotifications(!!loggedInUserID ? loggedInUserID : 'me')),
      interval
    )
    return {
      intervalID,
      interval,
    }
  }
}
