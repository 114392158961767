import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import Button from '../common/Button'
import i18n from '../../i18n'
import styles from '../../styles'
import { GhostLink } from '../../utils/link'
import { Routes } from '../../utils/consts'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { wipLimitReached } from '../../selectors/offers'

interface OfferFeedbackRecordedProps {
  onCloseRecordedFeedbackClick: () => void
  onContinueShoppingClick: () => void
  isLoading: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    feedbackRecorded: {
      position: 'relative',
      width: '100%',
      color: styles.palette.lightGrey.color,
      textAlign: 'center',
    },
    headline: {
      fontSize: '2.25rem',
      color: styles.palette.lightBlack.color,
      marginBottom: '2.125rem',
    },
    bodyText: {
      color: styles.palette.lightBlack.color,
      marginBottom: '3.1125rem',
      padding: '0 9.5rem',
      '@media (max-width: 900px)': {
        padding: '0 4rem',
      },
      '@media (max-width: 768px)': {
        padding: 0,
      },
    },
    buttons: {
      '& .leftButton': { marginRight: '2.1875rem' },
      '@media (max-width: 620px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& .leftButton': { marginRight: 0, marginBottom: '1rem' },
      },
    },
    closeContainer: {
      position: 'absolute',
      right: 0,
      top: 0,
      padding: '.5rem',
      '& .icon': {
        padding: '.1rem',
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.54)',
        color: '#fff',
        fontSize: '1rem',
      },
    },
    spinner: {
      marginLeft: theme.spacing(3),
    },
  })
)

const OfferFeedbackRecorded: React.FC<OfferFeedbackRecordedProps> = ({
  onCloseRecordedFeedbackClick,
  onContinueShoppingClick,
  isLoading,
}) => {
  const classes = useStyles()

  const wipReached = useSelector<AppState, boolean>(state => wipLimitReached(state.offerReducer))

  return (
    <Box className={classes.feedbackRecorded}>
      <IconButton className={classes.closeContainer} onClick={onCloseRecordedFeedbackClick}>
        <CloseIcon className="icon" />
      </IconButton>
      <Typography display="block" className={classes.headline}>
        {i18n.t('order__shopping__feedback_recorded__headline')}
      </Typography>
      <Box className={classes.buttons}>
        <GhostLink to={Routes.OpenOrders} className="leftButton">
          <Button onClick={onCloseRecordedFeedbackClick} type={'secondary'} size={'medium'}>
            {i18n.t('order__shopping__feedback_recorded__review_assigned_work')}
          </Button>
        </GhostLink>
        {!wipReached && (
          <Button type={'primary'} size={'medium'} onClick={onContinueShoppingClick} showLoader={isLoading}>
            {i18n.t('order__shopping__feedback_recorded__review_more_offers')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default OfferFeedbackRecorded
