import React, { useCallback, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import classNames from 'classnames'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { useDispatch } from 'react-redux'
import isEqual from 'lodash/isEqual'

import i18n from '../../i18n'
import styles from '../../styles'
import { markNotificationAsRead } from '../../store/notifications/actions'

interface NotificationProps {
  read?: boolean
  primaryText: string
  secondaryText: string
  date: string
  id: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notification: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 0.4375rem 0 2rem',
      minHeight: '3.75rem',
      background: '#fff',
      borderBottom: 'solid 1px #cacaca',
    },
    unread: {
      background: '#fddbd6',
    },
    text: {
      width: '80%',
      textAlign: 'left',
    },
    date: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    primaryText: {
      color: styles.palette.lightBlack.color,
    },
    secondaryText: {
      color: '#3f3f3f',
      marginTop: '0.3rem',
    },
  })
)

const NotificationItem: React.FC<NotificationProps> = ({ read, primaryText, secondaryText, date, id }) => {
  const classes = useStyles()

  const [disabled, setDisabled] = useState<boolean>(!!read && read)
  const dispatch = useDispatch()

  const onNotificationClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (disabled) {
        return
      }
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      if (!read) {
        dispatch(markNotificationAsRead(id))
      }
      setDisabled(true)
    },
    [disabled, id, read, dispatch]
  )

  return (
    <Box
      className={classNames({ [classes.notification]: true, [classes.unread]: !read })}
      onClick={onNotificationClick}
    >
      <Box className={classes.text}>
        <Typography variant={'h5'} className={classes.primaryText}>
          {primaryText}
        </Typography>
        <Typography variant={'h6'} className={classes.secondaryText}>
          {secondaryText}
        </Typography>
      </Box>
      <Typography variant={'h6'} className={classes.date}>
        {i18n.t('notifications__ago', {
          date: formatDistanceToNow(new Date(date)),
        })}
      </Typography>
    </Box>
  )
}

const memoizedNotificationItem = React.memo(NotificationItem, isEqual)

// @ts-ignore
NotificationItem.whyDidYouRender = true
export default memoizedNotificationItem
