import React, { useCallback, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CheckIcon from '@material-ui/icons/Check'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import TextField from '@material-ui/core/TextField'
import isEqual from 'lodash/isEqual'
import dateFormat from 'date-fns/format'
import formatISO from 'date-fns/formatISO'
import parseISO from 'date-fns/parseISO'
import differenceInDays from 'date-fns/differenceInDays'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import { useForm, useField, FormConfig } from 'react-final-form-hooks'
import WarningIcon from '@material-ui/icons/Warning'
import classNames from 'classnames'

import i18n from '../../i18n'
import { Note } from '../../store/notes/types'
import { Client } from '../../store/clients/reducer'
import TwoPhaseButton from '../common/TwoPhaseButton'
import { GhostLink } from '../../utils/link'
import NotesStyles from './Styles'

interface NoteFormProps {
  variant: 'new-note' | 'update-note'
  saveNote: (payload: NoteFormPayload) => void
  currentClient: Client | null
  selectedNote: Note | null
  deleteSelectedNote: () => void
  completeSelectedNote: (payload: NoteFormPayload) => void
  isFormOpen: boolean
  closeForm: () => void
}

export interface NoteFormPayload {
  contextID: number
  expirationDate?: string | null
  expirationTime?: string | null
  note: string
}

const note2NoteFormPayload = (note: Note | null): Partial<NoteFormPayload> => {
  if (!note) {
    return {}
  }

  let expirationDate = ''
  let expirationTime = ''

  if (note.expiration_date) {
    const ed = parseISO(note.expiration_date)
    expirationDate = formatISO(ed, { representation: 'date' })
    expirationTime = dateFormat(ed, 'HH:mm')
  }

  return {
    contextID: note.context_id,
    note: note.note,
    expirationDate,
    expirationTime,
  }
}

const useStyles = makeStyles(theme =>
  createStyles({
    buttonContainer: {
      borderTop: NotesStyles.border,
      bottom: '0',
      display: 'flex',
      justifyContent: 'space-between',
      padding: `${NotesStyles.padding2} ${NotesStyles.padding} 7em`,
      position: 'absolute',
      width: NotesStyles.fullWidth,

      '& button': {
        backgroundColor: NotesStyles.buttonForm.bg,
        border: NotesStyles.buttonForm.border,
        color: NotesStyles.buttonForm.color,
        fontWeight: NotesStyles.buttonForm.weight,
        padding: NotesStyles.buttonForm.padding,

        '&:nth-of-type(2)': {
          backgroundColor: NotesStyles.buttonDark.bg,
          border: NotesStyles.buttonDark.border,
          color: NotesStyles.buttonDark.color,

          '&:hover': {
            backgroundColor: NotesStyles.buttonDark.hover,
          },
        },
        '&:hover': {
          backgroundColor: NotesStyles.buttonForm.hover,
        },
      },
    },
    clientName: {
      borderBottom: NotesStyles.border,
      fontWeight: 600,
      padding: NotesStyles.padding,
      paddingLeft: '6.9em',
      textIndent: '-5.4em',

      '& a': {
        color: NotesStyles.linkColor,
      },
    },
    completionText: {
      borderBottom: NotesStyles.border,
      padding: NotesStyles.padding,
      paddingBottom: 'NotesStyles.padding2',
      paddingTop: 'NotesStyles.padding2',
    },
    disabledButton: {
      backgroundColor: NotesStyles.buttonDisabled.bg,
      border: NotesStyles.buttonDisabled.border,
      color: NotesStyles.buttonDisabled.color,
    },
    formToggle: {
      display: 'none',
    },
    noteDate: {
      alignItems: NotesStyles.input.align,
      borderBottom: NotesStyles.border,
      flexDirection: 'row',
      fontWeight: NotesStyles.input.weight,
      justifyContent: NotesStyles.input.justifyContent,
      padding: NotesStyles.padding,
      paddingRight: NotesStyles.input.innerPadding,
      width: '65%',

      '& div': {
        marginTop: NotesStyles.input.innerMargin,

        '&:after': {
          borderBottom: NotesStyles.input.innerBorder,
        },
        '&:before': {
          borderBottom: NotesStyles.input.innerBorder,
        },
      },
      '& input': {
        width: '9.5em',
      },
      '&.error': {
        '& > label': {
          color: NotesStyles.colorError,
        },
      },
      '&.pastDue': {
        '& > label': {
          color: NotesStyles.colorPast,
        },
      },
    },
    noteForm: {
      height: NotesStyles.fullHeight,
      position: 'relative',

      '& input': {
        color: NotesStyles.darkText,
      },
      '& label': {
        color: NotesStyles.label.color,
        fontSize: NotesStyles.label.size,
        fontWeight: NotesStyles.label.weight,
        position: NotesStyles.label.position,
        transform: NotesStyles.label.transform,
      },
    },
    noteFormClosed: {
      height: NotesStyles.fullHeight,

      '& input': {
        color: NotesStyles.darkText,
        margin: '0',
        minWidth: '0',
      },
      '& label': {
        color: NotesStyles.label.color,
        fontSize: NotesStyles.label.size,
        fontWeight: NotesStyles.label.weight,
        position: NotesStyles.label.position,
        transform: NotesStyles.label.transform,
      },
    },
    noteText: {
      color: NotesStyles.darkText,
      fontSize: '15px',
      height: 'calc(100% - 16.5em)',
      padding: '1.6em',

      '& div': {
        height: '84%',

        '& textarea': {
          height: '100% !important',
          overflowY: 'auto !important',
        },
        '&:after': {
          borderBottom: NotesStyles.input.innerBorder,
        },
        '&:before': {
          borderBottom: NotesStyles.input.innerBorder,
        },
      },
    },
    noteTime: {
      alignItems: NotesStyles.input.align,
      borderBottom: NotesStyles.border,
      flexDirection: 'row',
      fontWeight: NotesStyles.input.weight,
      justifyContent: NotesStyles.input.justifyContent,
      padding: NotesStyles.padding,
      paddingLeft: NotesStyles.input.innerPadding,
      width: '35%',

      '& div': {
        marginTop: '0',

        '&:after': {
          borderBottom: '0',
        },
        '&:before': {
          borderBottom: '0 !important',
        },
      },
      '& input': {
        width: '7em',
      },
    },
    orderLabel: {
      color: NotesStyles.label.color,
      fontWeight: NotesStyles.label.weight,
      marginRight: '.8em',
    },
    [theme.breakpoints.down('sm')]: {
      buttonContainer: {
        flexDirection: 'column-reverse',
        height: 'calc(100vh - 44.5em)',
        paddingBottom: NotesStyles.padding,
        position: 'relative',
        width: NotesStyles.fullWidth,

        '& button': {
          width: 'calc(100% - 6em)',
        },
      },
      formToggle: {
        display: 'block',
        height: '.33em',
        margin: '1em calc((100% - 4.1em)/2)',
        padding: '0',
        width: '4.1em',

        '& span': {
          backgroundColor: NotesStyles.darkText,
          borderRadius: '.33em',
          height: '100%',
        },
      },
      noteForm: {
        height: 'calc(100vh - 8em)',
        transition: NotesStyles.formTransition,
      },
      noteFormClosed: {
        height: '0',
        transition: NotesStyles.formTransition,
      },
      noteText: {
        height: 'calc(100% - 21em)',

        '& div': {
          height: NotesStyles.fullHeight,
        },
      },
    },
  })
)

const NoteForm: React.FC<NoteFormProps> = ({
  saveNote,
  currentClient,
  selectedNote,
  variant,
  deleteSelectedNote,
  completeSelectedNote,
  isFormOpen,
  closeForm,
}) => {
  const selectedNoteID = selectedNote ? selectedNote.id : null
  const classes = useStyles()
  const onSubmit = useCallback(
    (values: NoteFormPayload) => {
      let contextID = null

      if (selectedNote) {
        contextID = selectedNote.context_id
      } else if (currentClient) {
        contextID = currentClient.id
      }

      if (!contextID) {
        return
      }

      saveNote({ ...values, contextID })
    },
    [saveNote, selectedNote, currentClient]
  )
  const { form, handleSubmit, pristine, valid, values, errors } = useForm<FormConfig, NoteFormPayload>({
    // @ts-ignore
    onSubmit,
    initialValues: note2NoteFormPayload(selectedNote),
  })

  const expirationDate = useField<string, NoteFormPayload>('expirationDate', form, function validate(value, allValues) {
    if (!value && (allValues as NoteFormPayload).expirationTime) {
      return i18n.t('notes__errors__expirationDateMustBePresent')
    }

    if (value && differenceInDays(new Date(value), new Date()) < -31) {
      return i18n.t('notes__errors__expirationDateTooFarAway')
    }
  })
  const expirationTime = useField<string, NoteFormPayload>('expirationTime', form)
  const noteText = useField<string, NoteFormPayload>('note', form, function validate(value) {
    if (!value || value.length === 0) {
      return 'Required'
    }
  })

  // Date customization
  let pastDue = false
  let dateLabel = i18n.t('notes__dueOn')

  if (selectedNote && selectedNote.completion_date) {
    dateLabel = i18n.t('notes__completedOnLabel')
  } else if (expirationDate.input.value) {
    // If we get an  invalid date, don't render anything
    try {
      const expDate = new Date(`${expirationDate.input.value} ${expirationTime.input.value || ''}`)
      const distance = formatDistanceStrict(expDate, new Date(), { unit: 'day' })

      if (distance.includes('0 days')) {
        dateLabel = i18n.t('notes__dueToday')
      } else {
        pastDue = new Date() > expDate
        dateLabel = i18n.t(!pastDue ? 'notes__dueInDay' : 'notes__dueDaysAgo', {
          distance,
        })
      }
    } catch (e) {}
  }

  if (errors.expirationDate) {
    dateLabel = errors.expirationDate
  }

  // effects
  useEffect(() => {
    return function saveOnNoteSwitch() {
      if (!pristine && valid && selectedNoteID) {
        handleSubmit()
      }
    }
  }, [pristine, handleSubmit, valid, selectedNoteID])

  const saveBtn = (
    <Button
      classes={{ disabled: classes.disabledButton }}
      variant="contained"
      color="primary"
      onClick={handleSubmit}
      startIcon={<SaveOutlinedIcon />}
      disabled={!valid}
    >
      {i18n.t('notes__save')}
    </Button>
  )
  const formClass = isFormOpen ? classes.noteForm : classes.noteFormClosed

  return (
    <form onSubmit={handleSubmit} className={formClass}>
      <Button onClick={closeForm} className={classes.formToggle}>
        <></>
      </Button>
      {!!selectedNote && !currentClient && (
        <div className={classes.clientName}>
          <span className={classes.orderLabel}>{i18n.t('notes__titleOrder')}</span>
          <GhostLink to={`/client/${selectedNote.context_id}`}>
            {i18n.t('notes__newNoteForClient', { name: selectedNote.client, clientID: selectedNote.context_id })}
          </GhostLink>
        </div>
      )}
      {!!selectedNote && currentClient && (
        <div className={classes.clientName}>
          <span className={classes.orderLabel}>{i18n.t('notes__titleOrder')}</span>
          <GhostLink to={`/client/${selectedNote.context_id}`}>
            {i18n.t('notes__existingNotesForClient', { name: selectedNote.client, clientID: selectedNote.context_id })}
          </GhostLink>
        </div>
      )}
      {!selectedNote && currentClient && (
        <div className={classes.clientName}>
          <span className={classes.orderLabel}>{i18n.t('notes__titleOrder')}</span>
          {i18n.t('notes__existingNotesForClient', { name: currentClient.full_name, clientID: currentClient.id })}
        </div>
      )}
      <TextField
        label={dateLabel}
        inputProps={expirationDate.input as React.HTMLAttributes<HTMLInputElement>}
        className={classNames({
          [classes.noteDate]: true,
          error: !!errors.expirationDate,
          pastDue: pastDue,
        })}
        type="date"
      />
      <TextField
        label={i18n.t('notes__label__at')}
        inputProps={expirationTime.input as React.HTMLAttributes<HTMLInputElement>}
        className={classes.noteTime}
        type="time"
      />
      <TextField
        inputProps={noteText.input as React.HTMLAttributes<HTMLInputElement>}
        className={classes.noteText}
        type="textarea"
        fullWidth
        multiline={true}
      />
      {variant === 'new-note' && <Box className={classes.buttonContainer}>{saveBtn}</Box>}
      {variant === 'update-note' && !!selectedNote && (
        <>
          {selectedNote.completion_date && (
            <p className={classes.completionText}>
              {i18n.t('notes__completedOn', {
                date: dateFormat(new Date(selectedNote.completion_date), i18n.t('notes__dateFormat')),
                time: dateFormat(new Date(selectedNote.completion_date), i18n.t('time_format')),
              })}
            </p>
          )}
          {!selectedNote.completion_date && (
            <Box className={classes.buttonContainer}>
              <TwoPhaseButton
                confirmChildren={<>{i18n.t('notes__confirmDelete')}</>}
                onClick={deleteSelectedNote}
                phaseOneIcon={<DeleteOutlineIcon />}
                phaseTwoIcon={<WarningIcon />}
              >
                {i18n.t('notes__delete')}
              </TwoPhaseButton>
              {pristine && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => completeSelectedNote(values)}
                  startIcon={<CheckIcon />}
                >
                  {i18n.t('notes__markAsDone')}
                </Button>
              )}
              {!pristine && saveBtn}
            </Box>
          )}
        </>
      )}
    </form>
  )
}

const memoizedNoteForm = React.memo(NoteForm, isEqual)
// @ts-ignore
memoizedNoteForm.whyDidYouRender = true

export default memoizedNoteForm
