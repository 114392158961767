import { OfferLoadingState, GetUserOffersResponse, Offer, OfferState, OfferStatus } from './types'
import { initialLoadedLoadingErrorState, setErrorState, setSuccessState } from '../../utils/state'
import produce from 'immer'
import {
  AcceptOffer,
  AcceptOfferFail,
  AcceptOfferSuccess,
  ClearGlobalMeta,
  DiscardCurrentUserOffer,
  ExpireCurrentUserOffer,
  FetchUserOffers,
  FetchUserOffersFail,
  FetchUserOffersSuccess,
  GenerateUserOffer,
  GenerateUserOfferFail,
  GenerateUserOfferSuccess,
  OfferActionTypes,
  PromoteNextOfferToCurrent,
  RejectOffer,
  RejectOfferFail,
  RejectOfferSuccess,
  RemoveUserOffer,
  SubmitOfferFeedback,
  SubmitOfferFeedbackFail,
  SubmitOfferFeedbackSuccess,
} from './actions'
import { ConvertUserPromise, ConvertUserPromiseSuccess, PromiseActionTypes } from '../promises/actions'
import { AxiosResponse } from 'axios'
import {
  UserPreferencesActionTypes,
  FetchUserPreferencesSuccess,
  UpdateUserAssignmentPreferencesSuccess,
} from '../user_preferences/actions'
import { MessageActionTypes, SendMessageSuccess } from '../messages/actions'
import { SCHEDULING, SetPreferencesSuccess } from '../scheduling/actions'

export const initialState: OfferState = {
  offers: {},
  currentOfferID: null,
  meta: {
    global: { ...initialLoadedLoadingErrorState },
    individual: {},
  },
  shoppingEligibility: null,
}

export const defaultOfferMeta: OfferLoadingState = {
  accept: { ...initialLoadedLoadingErrorState },
  reject: { ...initialLoadedLoadingErrorState },
  submitFeedback: { ...initialLoadedLoadingErrorState },
}

const handleCurrentOfferOperationsFail = (
  draft: OfferState,
  offerID: number,
  userID: number,
  response: AxiosResponse
) => {
  if (!draft.meta.individual[offerID]) {
    draft.meta.individual[offerID] = { ...defaultOfferMeta }
  }
  const currentOfferID = draft.currentOfferID
  if (!!response && response.status === 410) {
    if (!!currentOfferID) {
      draft.offers[currentOfferID].status = OfferStatus.Expired
    }
  }
}

const updateOffersState = (draft: OfferState, response: GetUserOffersResponse, onlyOffered: boolean) => {
  if (!!response.offers) {
    response.offers.forEach(offer => {
      draft.meta.individual[offer.offer_id] = { ...defaultOfferMeta }
      // This is the offer which will be presented to the user
      if (!draft.currentOfferID && offer.status === OfferStatus.Offered) {
        draft.currentOfferID = offer.offer_id
      }
      if (!onlyOffered || (onlyOffered && offer.status === OfferStatus.Offered)) {
        draft.offers[offer.offer_id] = offer
      }
    })
  }
  draft.shoppingEligibility = response.shopping_eligibility
  draft.meta.global.isLoading = false
  draft.meta.global.error = null
}

export default function offerReducer(state: OfferState = initialState, action: OfferActionType): OfferState {
  return produce(state, (draft: OfferState) => {
    switch (action.type) {
      case OfferActionTypes.GENERATE_USER_OFFER:
      case OfferActionTypes.FETCH_USER_OFFERS: {
        draft.meta.global.isLoading = true
        break
      }
      case OfferActionTypes.GENERATE_USER_OFFER_FAIL: {
        draft.meta.global = {
          error: action.error,
          loaded: true,
          isLoading: false,
        }
        break
      }
      case OfferActionTypes.FETCH_USER_OFFERS_SUCCESS:
      case OfferActionTypes.GENERATE_USER_OFFER_SUCCESS: {
        const response = action.payload.data
        updateOffersState(draft, response, true)
        break
      }
      case OfferActionTypes.ACCEPT_OFFER_SUCCESS: {
        const response = action.payload.data
        updateOffersState(draft, response, false)
        // should it be in the action
        const currentOfferID = draft.currentOfferID as number
        draft.meta.individual[currentOfferID].accept = setSuccessState(draft.meta.individual[currentOfferID].accept)
        break
      }
      case OfferActionTypes.REJECT_OFFER_SUCCESS: {
        const response = action.payload.data
        updateOffersState(draft, response, false)
        const currentOfferID = draft.currentOfferID as number
        draft.meta.individual[currentOfferID].reject = setSuccessState(draft.meta.individual[currentOfferID].reject)
        break
      }
      case OfferActionTypes.SUBMIT_OFFER_FEEDBACK_SUCCESS: {
        const { offerID } = action.meta.previousAction
        const currentOfferID = offerID
        if (!draft.meta.individual[currentOfferID]) {
          draft.meta.individual[currentOfferID] = { ...defaultOfferMeta }
        }
        draft.meta.individual[currentOfferID].submitFeedback = setSuccessState(
          draft.meta.individual[currentOfferID].submitFeedback
        )
        break
      }
      case OfferActionTypes.FETCH_USER_OFFERS_FAIL: {
        draft.meta.global = {
          error: action.error,
          loaded: true,
          isLoading: false,
        }
        break
      }
      case OfferActionTypes.ACCEPT_OFFER: {
        const { offerID } = action
        if (!draft.meta.individual[offerID]) {
          draft.meta.individual[offerID] = { ...defaultOfferMeta }
        }
        draft.meta.individual[offerID].accept.isLoading = true
        break
      }
      case OfferActionTypes.REJECT_OFFER: {
        const { offerID } = action
        if (!draft.meta.individual[offerID]) {
          draft.meta.individual[offerID] = { ...defaultOfferMeta }
        }
        draft.meta.individual[offerID].reject.isLoading = true
        break
      }
      case OfferActionTypes.SUBMIT_OFFER_FEEDBACK: {
        const { offerID } = action
        if (!draft.meta.individual[offerID]) {
          draft.meta.individual[offerID] = { ...defaultOfferMeta }
        }
        draft.meta.individual[offerID].submitFeedback.isLoading = true
        break
      }
      case OfferActionTypes.ACCEPT_OFFER_FAIL: {
        const { userID, offerID } = action.meta.previousAction
        const response = action.error.response
        !!response && handleCurrentOfferOperationsFail(draft, userID, offerID, response)

        draft.meta.individual[offerID].accept = setErrorState(draft.meta.individual[offerID].accept, action.error)

        break
      }
      case OfferActionTypes.REJECT_OFFER_FAIL: {
        const { userID, offerID } = action.meta.previousAction
        const response = action.error.response
        !!response && handleCurrentOfferOperationsFail(draft, userID, offerID, response)
        draft.meta.individual[offerID].reject = setErrorState(draft.meta.individual[offerID].reject, action.error)
        break
      }
      case OfferActionTypes.SUBMIT_OFFER_FEEDBACK_FAIL: {
        const { userID, offerID } = action.meta.previousAction
        const response = action.error.response
        !!response && handleCurrentOfferOperationsFail(draft, userID, offerID, response)
        draft.meta.individual[offerID].submitFeedback = setErrorState(
          draft.meta.individual[offerID].submitFeedback,
          action.error
        )
        break
      }
      case OfferActionTypes.REMOVE_USER_OFFER: {
        if (!!draft.offers[action.offerID]) {
          delete draft.offers[action.offerID]
        }
        break
      }
      case OfferActionTypes.DISCARD_CURRENT_USER_OFFER: {
        if (!!draft.currentOfferID) {
          draft.currentOfferID = null
        }
        break
      }
      case OfferActionTypes.EXPIRE_CURRENT_USER_OFFER:
        const currentOfferID = draft.currentOfferID
        if (!!currentOfferID) {
          draft.offers[currentOfferID].status = OfferStatus.Expired
        }
        break

      case PromiseActionTypes.CONVERT_PROMISE_SUCCESS: {
        const offers = action.payload.data.offers
        offers.forEach((o, i) => {
          if (i === 0 && !draft.currentOfferID) {
            draft.currentOfferID = o.offer_id
          }
          draft.offers[o.offer_id] = o
          draft.meta.individual[o.offer_id] = { ...defaultOfferMeta }
        })
        break
      }
      case OfferActionTypes.CLEAR_GLOBAL_META:
        draft.meta.global = { ...initialLoadedLoadingErrorState }
        break

      case OfferActionTypes.PROMOTE_NEXT_OFFER_TO_CURRENT: {
        if (!!draft && Object.keys(draft.offers).length > 0) {
          const nextOffer = Object.values(draft.offers)
            .filter(o => o.status === OfferStatus.Offered)
            .sort((a: Offer, b: Offer) => {
              return new Date(a.expires_at) < new Date(b.expires_at) ? -1 : 1
            })
          draft.currentOfferID = nextOffer[0].offer_id
        }
        break
      }

      case UserPreferencesActionTypes.FETCH_USER_PREFERENCES_SUCCESS:
      case UserPreferencesActionTypes.UPDATE_USER_ASSIGNMENT_PREFERENCES_SUCCESS: {
        const { assignments_per_week } = action.payload.data.assignment

        if (draft.shoppingEligibility) {
          // @ts-ignore
          draft.shoppingEligibility.offers_per_week = assignments_per_week
        }

        break
      }

      case MessageActionTypes.SEND_MESSAGE_SUCCESS: {
        const { shopping_eligibility } = action.payload.data
        if (!!shopping_eligibility) {
          draft.shoppingEligibility = shopping_eligibility
        }
        break
      }
      case SCHEDULING.SET_PREFERENCES_SUCCESS:
        {
          const response = action.payload.data
          if (!!response && draft.shoppingEligibility) {
            draft.shoppingEligibility.is_available_for_scheduled_items = response.is_available_for_scheduled_items
          }
        }
        break
    }
  })
}

export type OfferActionType =
  | GenerateUserOffer
  | GenerateUserOfferSuccess
  | GenerateUserOfferFail
  | FetchUserOffers
  | FetchUserOffersSuccess
  | FetchUserOffersFail
  | AcceptOffer
  | AcceptOfferSuccess
  | AcceptOfferFail
  | RejectOffer
  | RejectOfferSuccess
  | RejectOfferFail
  | SubmitOfferFeedback
  | SubmitOfferFeedbackSuccess
  | SubmitOfferFeedbackFail
  | RemoveUserOffer
  | DiscardCurrentUserOffer
  | ExpireCurrentUserOffer
  | ConvertUserPromiseSuccess
  | ConvertUserPromise
  | PromoteNextOfferToCurrent
  | FetchUserPreferencesSuccess
  | UpdateUserAssignmentPreferencesSuccess
  | ClearGlobalMeta
  | SendMessageSuccess
  | SetPreferencesSuccess
