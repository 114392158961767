import { FeatureTags, Groups, UserInfo, UserState } from '../store/user/reducers'

export interface FeatureFlags {
  showOrderShopping: boolean
  showOrderShoppingPreferences: boolean
  showNewOrderNudges: boolean
  showAutoResearch: boolean
  showScheduler: boolean
  showTranscripts: boolean
  showAudio: boolean
  showVideo: boolean
  showAIActions: boolean
}

export const shouldShowOrderShopping = (user: UserInfo | null): boolean => !!user

export const shouldShowNewOrderNudges = (user: UserInfo | null): boolean =>
  !!user &&
  !user.tag_keys.some(key =>
    // @ts-ignore
    [FeatureTags.OnboardingMentee, FeatureTags.InterviewCoach, FeatureTags.Offshore].includes(key)
  )
export const showAutoResearch = (user: UserInfo | null): boolean =>
  !!user && (user.groups.includes(Groups.Developer) || user.tag_keys.includes(FeatureTags.AutoResearch))

export const showTranscripts = (user: UserInfo | null): boolean => !!user && user.groups.includes(Groups.Admin)

export const showAudio = (user: UserInfo | null): boolean => !!user && user.groups.includes(Groups.Admin)

export const showVideo = (user: UserInfo | null): boolean => !!user && user.groups.includes(Groups.Admin)

export const showScheduler = (user: UserInfo | null): boolean => {
  const blockedTags = [FeatureTags.OnboardingMentee, FeatureTags.NewHire, FeatureTags.NoPhoneCalls]
  return (
    !!user &&
    ((user.groups.includes(Groups.Offshore) && user.tag_keys.includes(FeatureTags.OffshorePhoneCall)) ||
      !user.groups.includes(Groups.Offshore) ||
      user.groups.includes(Groups.Admin)) &&
    blockedTags.every(tag => !user.tag_keys.includes(tag))
  )
}

export const showAIActions = (user: UserInfo | null): boolean => !!user && user.tag_keys.includes(FeatureTags.AIResume)

export const showVideoCritique = (user: UserInfo | null): boolean =>
  !!user &&
  (user.groups.includes(Groups.Developer) ||
    user.groups.includes(Groups.Admin) ||
    user.tag_keys.includes(FeatureTags.VideoCritique))

export const getFeatureFlags = (user: UserInfo | null): FeatureFlags => {
  return {
    showOrderShopping: shouldShowOrderShopping(user),
    showOrderShoppingPreferences: true,
    showNewOrderNudges: shouldShowNewOrderNudges(user),
    showAutoResearch: showAutoResearch(user),
    showScheduler: showScheduler(user),
    showTranscripts: showTranscripts(user),
    showAudio: showAudio(user),
    showVideo: showVideo(user),
    showAIActions: showAIActions(user),
  }
}

export const getFeatureFlagsByUserID = (state: UserState, userID: number): FeatureFlags =>
  getFeatureFlags(state.users[userID] || null)

export default getFeatureFlags
