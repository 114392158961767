import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../store'
import { OfferPromise } from '../../store/promises/types'
import { userCurrentPromise } from '../../selectors/promises'
import { BasePay } from '../../store/orders/types'
import { userPendingPay } from '../../selectors/user'
import { convertUserPromise } from '../../store/promises/actions'
import { UserInfo } from '../../store/user/reducers'
import ReviewOrders, { ReviewOrderStates } from './ReviewOrders'

export enum PromiseMode {
  Notification = 'Notification',
  Menu = 'Menu',
}

export interface PromisesContainerProps {
  user: UserInfo
  mode: PromiseMode
}

const PromisesContainer: React.FC<PromisesContainerProps> = ({ user, mode }) => {
  const [currentPromise, displayPromises] = useSelector<AppState, [OfferPromise | null, boolean]>(state =>
    userCurrentPromise(state.promiseReducer)
  )

  const userPreferences = useSelector<AppState, BasePay | null>(state =>
    userPendingPay(state.userPreferencesReducer, user.id)
  )

  const dispatch = useDispatch()

  const onReviewOrdersClick = () => {
    if (!!currentPromise) {
      dispatch(convertUserPromise(currentPromise.id, currentPromise.auth_token, user.id))
    }
  }

  const promiseState = !displayPromises
    ? ReviewOrderStates.Disabled
    : !!currentPromise
    ? ReviewOrderStates.Active
    : ReviewOrderStates.Inactive

  if (mode === PromiseMode.Menu) {
    return <ReviewOrders state={promiseState} pendingPay={userPreferences} onReviewOrdersClick={onReviewOrdersClick} />
  }

  return null
}

export default PromisesContainer
