import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'

import i18n from '../../i18n'

interface ErrorNotificationProps {
  handleClose?: () => void
  handleReportBugClick?: () => void
  showBugReport?: boolean | null
  message?: string | null
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    padding: '.5rem 1rem',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#ea4e38',
    color: '#fff',
    '@media (max-width: 560px)': {
      width: 'calc(100% - 2rem)',
    },
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    maxWidth: '26.25rem',
    margin: '0 1rem',
    '@media (max-width: 560px)': {
      flexDirection: 'column',
    },
    '& .text': {
      color: '#fff',
      fontSize: '.8125rem',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      padding: '6px 0',
    },
    '& .links': {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: '1rem',
      '@media (max-width: 560px)': {
        width: '100%',
        marginLeft: 0,
        marginTop: '.5rem',
      },
      '& .link': {
        color: '#fff',
        textDecoration: 'underline',
        textTransform: 'none',
        '&:first-child': { marginRight: '.5rem' },
      },
    },
  },
  close: {
    marginRight: '-.4rem',
    padding: '.4rem',
    '& .icon': {
      fontSize: '1.2rem',
      color: '#fff',
    },
  },
}))

const ErrorNotification: React.FC<ErrorNotificationProps> = ({
  handleClose = () => {},
  showBugReport = false,
  handleReportBugClick = () => {},
  message = i18n.t('notification__error__text'),
}) => {
  const classes = useStyles()

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={true}
      className={`${classes.root} error-notification`}
    >
      <>
        <ErrorIcon />
        <Box className={classes.content}>
          <Typography display="block" variant={'body2'} className="text">
            {message || i18n.t('notification__error__text')}
          </Typography>
          {showBugReport && (
            <Box className="links">
              <Button onClick={handleReportBugClick} className="link">
                <Typography variant={'body2'} className="text" component={'span'}>
                  {i18n.t('notification__error__submitBug')}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
        <IconButton onClick={handleClose} className={classes.close}>
          <CloseIcon className="icon" />
        </IconButton>
      </>
    </Snackbar>
  )
}

export default ErrorNotification
