import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import { Tabs, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import Badge from '@material-ui/core/Badge'

import i18n from '../../i18n'
import { AppState } from '../../store'
import { messagesMetaReset } from '../../store/messages/actions'
import { currentMessageReset } from '../../store/currentMessage/actions'
import TabPanel from '../common/TabPanel'
import { GhostLink } from '../../utils/link'
import SideBar from './SideBar'
import Loader from '../common/Loader'
import PageHeader from '../common/PageHeader'
import ErrorBoundary from '../common/ErrorBoundary'
import { cleanUpUploads } from '../../store/uploads/actions'
import { getClientDefaultUrl, getClientDocumentsUrl, getClientMessagesUrl } from '../../utils/consts'
import { Note } from '../../store/notes/types'
import Header from './OrderDetails/Header'
import { clientByIdSelector, clientDetailsNeedFetching } from '../../selectors/clients'
import ErrorNotification from '../common/ErrorNotification'
import { AxiosError } from 'axios'
import { preloadClient } from '../../store/clientOrderAggregate/actions'
import { getNotesForClient } from '../../store/notes/selectors'
import { MacroState } from '../../utils/mapper'
import { clientStateNeedsLoading } from '../../selectors/clientOrderAggregates'
import { clientQuestionnaireLoaded } from '../../selectors/clients'
import { LoadedLoadingErrorState } from '../../utils/state'
import { Client } from '../../store/clients/reducer'
import { fetchUpsell } from '../../store/clients/actions'
import { fetchIsAIDraftExists } from '../../store/aiDraft/actions'

const useStyles = makeStyles(() =>
  createStyles({
    tabsContainer: {
      height: '2.4rem',
      minHeight: 'initial',
    },
    tabLabel: {
      textTransform: 'initial',
      marginRight: '1.5rem',
      minWidth: '1rem',
      padding: '0',
      '@media (max-width: 540px)': {
        fontSize: '1.1rem',
        marginRight: '1rem',
      },
    },
    tabLink: {
      color: 'black',
    },
    container: {
      height: '100%',
    },
    flexContainer: {
      display: 'flex',
      maxWidth: '76.1875rem',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      position: 'relative',
      '@media (max-width: 540px)': {
        width: '100vw',
      },
    },
    rightColumn: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      right: '0',
      width: '17.5rem',
      '@media (max-width: 1024px)': {
        marginBottom: '1rem',
        position: 'initial',
        width: '100%',
      },
    },
    leftColumn: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100% - 19rem - 1.5rem)',
      marginRight: '1.5rem',
      '@media (max-width: 1024px)': {
        margin: '0',
        order: '2',
        width: '100%',
      },
    },
    notificationsBadge: {
      paddingRight: '10px',
    },
  })
)

const a11yProps = (index: number) => {
  return {
    id: `page-title-tab-${index}`,
    'aria-controls': `page-title-tabpanel-${index}`,
  }
}

export enum OrderContainerTabs {
  Details = 0,
  Messages = 1,
  Documents = 2,
}

export type OrderContainerTabType =
  | OrderContainerTabs.Details
  | OrderContainerTabs.Messages
  | OrderContainerTabs.Documents

export interface ClientContainerProps {
  clientID: number
  children: React.ReactNode
  activeTab: OrderContainerTabType
}

export const ClientContainer: React.FC<ClientContainerProps> = ({ clientID, children, activeTab }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const clientStateMeta = useSelector<AppState, LoadedLoadingErrorState>(
    store => store.clientOrdersAggregateReducer.metaData.individual[clientID]
  )
  const state = useSelector<AppState, MacroState>(store => store.clientOrdersAggregateReducer.states[clientID])
  const notes = useSelector<AppState, Note[]>(store => getNotesForClient(store, clientID))

  const head = (
    <Helmet
      titleTemplate={`${i18n.t('order__page__title', { clientID })} | %s | ${i18n.t('pageTitle__suffix')}`}
      defaultTitle={`${i18n.t('order__page__title', { clientID })} | ${i18n.t('pageTitle__suffix')}`}
    />
  )

  // Client needs loading = at least one of the client's orders is not verbose
  const detailsNeedFetching = useSelector<AppState, boolean>(store =>
    clientDetailsNeedFetching({ ...store.clientReducer, ...store.orderReducer }, clientID)
  )

  const clientAggregateStateNeedsLoading = useSelector<AppState, boolean>(store =>
    clientStateNeedsLoading(store.clientOrdersAggregateReducer, clientID)
  )

  const questionnaireIsLoaded = useSelector<AppState, boolean>(store =>
    clientQuestionnaireLoaded(store.clientQuestionnaireReducer, clientID)
  )

  const showLoader = clientAggregateStateNeedsLoading || detailsNeedFetching || !questionnaireIsLoaded
  const client = useSelector<AppState, Client>(store => clientByIdSelector(store.clientReducer, clientID))
  const productUserId = client?.products_user_id

  useEffect(() => {
    dispatch(preloadClient(clientID))
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [clientID])

  useEffect(() => {
    if (clientID && productUserId) {
      // fetching snippets for upsells with discount token "RWU2023"
      dispatch(fetchUpsell(clientID, productUserId, 'RWU2023', 'writer_snippets'))
    }
  }, [clientID, productUserId, dispatch])

  // fetch if AI draft exits for client
  useEffect(() => {
    dispatch(fetchIsAIDraftExists(clientID))
  }, [clientID, dispatch])

  // Reset the current message & uploads when navigating away from this page
  useEffect(() => {
    return function cleanup() {
      dispatch(currentMessageReset())
      dispatch(messagesMetaReset())
      dispatch(cleanUpUploads())
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [clientID])

  // @ts-ignore
  if (!!clientStateMeta && !!clientStateMeta.error) {
    try {
      // @ts-ignore
      const errorMessage = (clientStateMeta.error as AxiosError).response.data.error
      return (
        <>
          {head}
          <ErrorNotification message={errorMessage} />
        </>
      )
    } catch {
      return (
        <>
          {head}
          <ErrorNotification />
        </>
      )
    }
  }

  if (showLoader) {
    return (
      <>
        {head}
        <Loader fullPage={true} />
      </>
    )
  }

  return (
    <ErrorBoundary>
      {head}
      <Box className={classes.container}>
        <PageHeader>
          <Tabs
            className={classes.tabsContainer}
            indicatorColor={'primary'}
            value={activeTab}
            aria-label="Order type tabs"
          >
            <Tab
              className={classes.tabLabel}
              label={
                <GhostLink to={getClientDefaultUrl(clientID)} className={classes.tabLink}>
                  <Typography variant={'h3'}>{i18n.t('messaging__tab__title__order__details')}</Typography>
                </GhostLink>
              }
              {...a11yProps(0)}
            />

            <Tab
              className={classes.tabLabel}
              label={
                <GhostLink to={getClientMessagesUrl(clientID)} className={classes.tabLink} replace>
                  <Box className={classes.notificationsBadge}>
                    <Badge badgeContent={!!client ? client.unread_mail_count : 0} color={'primary'} showZero={false}>
                      <Typography variant={'h3'}>{i18n.t('messaging__tab__title__message')}</Typography>
                    </Badge>
                  </Box>
                </GhostLink>
              }
              {...a11yProps(1)}
            />

            <Tab
              className={classes.tabLabel}
              label={
                <GhostLink to={getClientDocumentsUrl(clientID)} className={classes.tabLink} replace>
                  <Typography variant={'h3'}>{i18n.t('messaging__tab__title__documents')}</Typography>
                </GhostLink>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </PageHeader>
        <Box className={classes.flexContainer}>
          {state && <Header clientID={clientID} notes={notes} isSticky={activeTab === OrderContainerTabs.Messages} />}
          <Box className={classes.leftColumn}>
            <TabPanel index={0} value={0}>
              {children}
            </TabPanel>
          </Box>
          <Box className={classes.rightColumn}>
            <SideBar clientID={clientID} viewToolKit={activeTab !== OrderContainerTabs.Messages} viewOrderPay={true} />
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>
  )
}

// @ts-ignore
ClientContainer.whyDidYouRender = true

export default ClientContainer
