import React, { ReactNode } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Button from '../../common/Button'

interface ActionButtonProps {
  handleClick?: React.EventHandler<React.SyntheticEvent>
  children: React.ReactNode
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '2.5rem',
      boxSizing: 'border-box',
    },
  })
)

const ActionButton: React.FC<ActionButtonProps> = props => {
  const classes = useStyles()

  return (
    <Button disabled={!!props.disabled} outline type="primary" className={classes.root} onClick={props.handleClick}>
      {props.children}
    </Button>
  )
}

export default ActionButton
