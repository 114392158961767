import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { HashMap, OrderItemTimeDisplayStates, OrderItemTypes } from '../../store/items/types'
import { formatDateOffset } from '../../utils/formatting'
import { OrderItemHistoryEntry } from './OrderItems'
import TopInterviewIcon from '../common/Icons/TopInterview'
import ThankYouNoteIcon from '../common/Icons/ThankYouNote'
import ExtendedRevisionsIcon from '../common/Icons/ExtendedRevisions'
import LinkedInIcon from '../common/Icons/LinkedIn'
import CoverLetterIcon from '../common/Icons/CoverLetter'
import CvIcon from '../common/Icons/Cv'
import ResumeIcon from '../common/Icons/Resume'
import PhoneConsultationIcon from '../common/Icons/Phone'
import PostingServiceIcon from '../common/Icons/PostingService'
import RushIcon from '../common/Icons/Rush'
import IncentiveIcon from '../common/Icons/Incentive'
import useUserInfoState from '../common/useUserInfo'
import SvgStarBonus from '../common/Icons/StarBonus'
import VideoCritiqueIcon from '../common/Icons/VideoCritique'

interface OrderItemProps {
  type: OrderItemTypes
  state?: OrderItemTimeDisplayStates
  compound_item_key: string
  due_date?: string | null
  description: HashMap<string | null>
  history?: OrderItemHistoryEntry[]
  isFulfillable: boolean
  applyColors: boolean
  name: string
}

export interface OrderItemStyle {
  main: string
  tooltip: string | null
}

const stateToClassListMap: HashMap<OrderItemStyle> = {
  [OrderItemTimeDisplayStates.Done]: {
    main: 'gray',
    tooltip: 'gray',
  },
  [OrderItemTimeDisplayStates.Due]: {
    main: 'green',
    tooltip: 'green',
  },
  [OrderItemTimeDisplayStates.DueUrgent24Hours]: {
    main: 'yellow',
    tooltip: 'yellow',
  },
  [OrderItemTimeDisplayStates.RevisionRequested]: {
    main: 'purple',
    tooltip: 'purple',
  },
  [OrderItemTimeDisplayStates.Late]: {
    main: 'red',
    tooltip: 'red',
  },
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'relative',
      width: '2.5rem',
      height: '2.5rem',
      borderRadius: '0.8rem',
      alignItems: 'center',
      justifyContent: 'center',
      '&.purple': { backgroundColor: '#F1ECFF', color: '#7742FE' },
      '&.red': { backgroundColor: '#FED4D7', color: '#FC5769' },
      '&.green': { backgroundColor: '#D0F4E1', color: '#52C88D' },
      '&.yellow': { backgroundColor: '#F9EEC9', color: '#FDB20E' },
      '&.gray': { backgroundColor: '#EDEFF1', color: '#AEBDCD' },
      '&.rush': { backgroundColor: '#EDEFF1', color: '#000000' },
      '&.incentivePay': { backgroundColor: '#EDEFF1', color: '#000000' },
      '&.shopping': { backgroundColor: '#D0F4E1', color: '#52C88D' },
      '&.stateless': { backgroundColor: '#EDEFF1', color: '#000000' },
      '&.starBonus': { backgroundColor: '#EDEFF1', color: '#F7CC40' },
    },
    tooltip: {
      position: 'absolute',
      top: '-.625rem',
      transform: 'translateY(-100%)',
      background: 'transparent',
      borderRadius: '.375rem',
      boxShadow: '.3125rem .3125rem 1.25rem -.4375rem #999',
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 'calc(50% - .9375rem)',
        width: '1.25rem',
        height: '.625rem',
        borderRadius: '.25rem',
        zIndex: 1,
        backgroundColor: 'white',
        transform: 'rotate(40deg) skew(40deg)',
      },
      '& .content': {
        position: 'relative',
        padding: '.4375rem 1.375rem .4375rem .8rem',
        backgroundColor: 'white',
        borderRadius: '.375rem',
        overflow: 'hidden',
        color: '#000',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        minWidth: '15.625rem',
        zIndex: 2,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '.4375rem',
          height: '100%',
        },
        '& .title': {
          whiteSpace: 'nowrap',
          fontSize: '0.8125rem',
          fontWeight: 600,
          lineHeight: '1.125rem',
        },
        '& .amount': { marginLeft: '.2rem' },
        '& .history': {
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '.3125rem',
          marginLeft: '.625rem',
          '&.main > *': { fontWeight: 600 },
          '& .item': {
            fontSize: '.6875rem',
            lineHeight: '1.125rem',
          },
          '& .date': {
            marginLeft: '1rem',
            fontSize: '.6875rem',
            lineHeight: '1.125rem',
          },
        },
      },
      '&.purple': {
        '& .content::before': { backgroundColor: '#7742FE' },
        '& .content .amount, & .content .history.main': { color: '#7742FE' },
      },
      '&.red': {
        '& .content::before': { backgroundColor: '#FC5769' },
        '& .content .amount, & .content .history.main': { color: '#FC5769' },
      },
      '&.green': {
        '& .content::before': { backgroundColor: '#52C88D' },
        '& .content .amount, & .content .history.main': { color: '#52C88D' },
      },
      '&.yellow': {
        '& .content::before': { backgroundColor: '#FDB20E' },
        '& .content .amount, & .content .history.main': { color: '#FDB20E' },
      },
      '&.gray': {
        '& .content::before': { backgroundColor: '#AEBDCD' },
        '& .content .amount, & .content .history.main': { color: '#AEBDCD' },
      },
      '&.rush': {
        '& .content::before': { backgroundColor: '#000000' },
      },
      '&.incentivePay': {
        '& .content::before': { backgroundColor: '#000000' },
      },
      '&.stateless': {
        '& .content::before': { backgroundColor: '#000000' },
      },
      '&.shopping': {
        '& .content::before': { backgroundColor: '#52C88D' },
      },
      '&.starBonus': {
        '& .content::before': { backgroundColor: '#F7CC40' },
      },
    },
  })
)

const renderItemIcon = (type: OrderItemTypes, compound_item_key: string, name: string) => {
  switch (type) {
    case OrderItemTypes.TopInterview:
      return <TopInterviewIcon />
    case OrderItemTypes.ThankYouNote:
      return <ThankYouNoteIcon />
    case OrderItemTypes.AdditionalDocument:
      return <ExtendedRevisionsIcon />
    case OrderItemTypes.LinkedIn:
      return <LinkedInIcon />
    case OrderItemTypes.CoverLetter:
      return <CoverLetterIcon />
    case OrderItemTypes.CV:
      return <CvIcon />
    case OrderItemTypes.Resume:
      return <ResumeIcon />
    case OrderItemTypes.PhoneConsultation:
      return <PhoneConsultationIcon />
    case OrderItemTypes.PostingService:
      return <PostingServiceIcon />
    case OrderItemTypes.Rush:
      return <RushIcon />
    case OrderItemTypes.Incentive:
      return <IncentiveIcon />
    case OrderItemTypes.ResumeEdit:
      return <ResumeIcon />
    default:
      if (compound_item_key.includes('rush')) {
        return <RushIcon />
      }
      if (
        compound_item_key.includes('four-star-bonus') ||
        compound_item_key.includes('five-star-bonus') ||
        compound_item_key.includes('manual-pay')
      ) {
        return <SvgStarBonus />
      }
      if (name === 'Video Critique') {
        return <VideoCritiqueIcon />
      }
      return <ResumeIcon />
  }
}

const OrderItem: React.FC<OrderItemProps> = ({
  type,
  state,
  isFulfillable,
  description,
  history,
  applyColors,
  compound_item_key,
  name,
}) => {
  const classes = useStyles()
  const [showTooltip, setShowTooltip] = useState(false)
  const { timeZone, locale } = useUserInfoState()

  const itemClass = () => {
    switch (type) {
      case OrderItemTypes.Rush:
        return 'rush'
      case OrderItemTypes.Incentive:
        return 'incentivePay'
      default:
        if (compound_item_key.includes('rush')) {
          return 'rush'
        }
        if (
          compound_item_key.includes('four-star-bonus') ||
          compound_item_key.includes('five-star-bonus') ||
          compound_item_key.includes('manual-pay')
        ) {
          return 'starBonus'
        }
        if (!applyColors) {
          return 'shopping'
        }
        if (!state || !stateToClassListMap[state]) {
          return 'stateless'
        }
        return stateToClassListMap[state].main
    }
  }

  const tooltipClass = () => {
    switch (type) {
      case OrderItemTypes.Rush:
        return 'rush'
      case OrderItemTypes.Incentive:
        return 'incentivePay'
      default:
        if (compound_item_key.includes('rush')) {
          return 'rush'
        }
        if (
          compound_item_key.includes('four-star-bonus') ||
          compound_item_key.includes('five-star-bonus') ||
          compound_item_key.includes('manual-pay')
        ) {
          return 'starBonus'
        }
        if (!applyColors) {
          return 'shopping'
        }
        if (!state || !stateToClassListMap[state]) {
          return 'stateless'
        }
        return stateToClassListMap[state].tooltip
    }
  }

  const renderTooltip = () => {
    return (
      <Box className={`${classes.tooltip} ${tooltipClass()}`}>
        <Box className="content">
          {Object.entries(description).map(([key, value]) => (
            <Box key={key}>
              <Typography display="inline" variant={'body2'} className="title">
                {`${key} `}
              </Typography>
              {!!value && (
                <Typography display="inline" variant={'body2'} className="title amount">
                  {value}
                </Typography>
              )}
            </Box>
          ))}

          {isFulfillable &&
            history &&
            history.map((h, i) => (
              <Box className={`history ${h.isActive ? 'main' : ''}`} key={i}>
                <Typography display="inline" variant={'body2'} className="item">
                  {h.type}
                </Typography>
                {h.date && (
                  <Typography display="inline" variant={'body2'} className="date">
                    {formatDateOffset(new Date(), h.date, timeZone, locale)}
                  </Typography>
                )}
              </Box>
            ))}
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box
        className={`${classes.root} ${itemClass()}`}
        onMouseOver={() => setShowTooltip(true)}
        onMouseOut={() => setShowTooltip(false)}
      >
        {renderItemIcon(type, compound_item_key, name)}
        {showTooltip && renderTooltip()}
      </Box>
    </>
  )
}

export default OrderItem
