import {
  AxiosMiddlewareAction,
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import { Document, DocumentResponse, EditorDecisions, EditorReview, FileContexts } from './types'
import { UploadFinalizeSuccess } from '../uploads/actions'
import { OrderCTA, OrderItemDisplayStates, OrderItemStates } from '../items/types'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AppState } from '../index'
import { AnyAction } from 'redux'
import { currentMessageUpdate } from '../currentMessage/actions'
import {
  clientDocumentsNeedFetching,
  getLatestDocumentsForClosedOrders,
  getLatestDocumentsForOpenOrders,
} from '../../selectors/documents'
import { AutoQAScanOutput } from '../uploads/types'
import { fetchClientOrderDetailsThunk } from '../clientOrderAggregate/actions'

export enum DOCUMENT {
  FETCH_DOCUMENTS = 'FETCH_DOCUMENTS',
  FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS',
  FETCH_DOCUMENTS_FAIL = 'FETCH_DOCUMENTS_FAIL',

  FETCH_AUTOQA_RESULTS = 'FETCH_AUTOQA_RESULTS',
  FETCH_AUTOQA_RESULTS_SUCCESS = 'FETCH_AUTOQA_RESULTS_SUCCESS',
  FETCH_AUTOQA_RESULTS_FAIL = 'FETCH_AUTOQA_RESULTS_FAIL',

  REVIEW_DOC = 'REVIEW_DOC',
  REVIEW_DOC_SUCCESS = 'REVIEW_DOC_SUCCESS',
  REVIEW_DOC_FAIL = 'REVIEW_DOC_FAIL',

  DELETE_DOC = 'DELETE_DOC',
  DELETE_DOC_SUCCESS = 'DELETE_DOC_SUCCESS',
  DELETE_DOC_FAIL = 'DELETE_DOC_FAIL',
}

export interface DeleteDoc extends AxiosMiddlewareActionCreator {
  type: typeof DOCUMENT.DELETE_DOC
  context: FileContexts
  contextID: number
  fileID: number
}

export interface DeleteDocSuccess extends AxiosMiddlewareActionSuccess<void, DeleteDoc> {
  type: typeof DOCUMENT.DELETE_DOC_SUCCESS
}

export interface DeleteDocFail extends AxiosMiddlewareActionFail<DeleteDoc> {
  type: typeof DOCUMENT.DELETE_DOC_FAIL
}

export interface ReviewDoc extends AxiosMiddlewareActionCreator {
  type: typeof DOCUMENT.REVIEW_DOC
  context: FileContexts
  contextID: number
}

export interface ReviewDocSuccess extends AxiosMiddlewareActionSuccess<void, ReviewDoc> {
  type: typeof DOCUMENT.REVIEW_DOC_SUCCESS
  context: FileContexts
  contextID: number
}

export interface ReviewDocFail extends AxiosMiddlewareActionFail<ReviewDoc> {
  type: typeof DOCUMENT.REVIEW_DOC_FAIL
  context: FileContexts
  contextID: number
}

export interface FetchDocuments extends AxiosMiddlewareActionCreator {
  type: typeof DOCUMENT.FETCH_DOCUMENTS
  context: FileContexts
  contextID: number
}

export interface FetchDocumentsStart extends AxiosMiddlewareAction {
  type: typeof DOCUMENT.FETCH_DOCUMENTS
  context: FileContexts
  contextID: number
}

export interface FetchDocumentsSuccess extends AxiosMiddlewareActionSuccess<DocumentResponse[], FetchDocuments> {
  type: typeof DOCUMENT.FETCH_DOCUMENTS_SUCCESS
}

export interface FetchDocumentsFail extends AxiosMiddlewareActionFail<FetchDocuments> {
  type: typeof DOCUMENT.FETCH_DOCUMENTS_FAIL
}

export function fetchClientDocuments(contextID: number): FetchDocuments {
  return {
    type: DOCUMENT.FETCH_DOCUMENTS,
    contextID,
    context: 'client',
    payload: {
      request: {
        url: `v1/clients/${contextID}/files`,
      },
    },
  }
}

export function reviewDocument(fileID: number, contextID: number, review: EditorReview): ReviewDoc {
  return {
    type: DOCUMENT.REVIEW_DOC,
    contextID,
    context: 'editor-client',
    payload: {
      request: {
        method: 'POST',
        url: `v1/files/id/${fileID}/review`,
        data: review,
      },
    },
  }
}

export function deleteDocument(fileID: number, contextID: number, context: FileContexts): DeleteDoc {
  return {
    type: DOCUMENT.DELETE_DOC,
    context,
    contextID,
    fileID,
    payload: {
      request: {
        method: 'DELETE',
        url: `v1/files/id/${fileID}`,
      },
    },
  }
}

export function applyAttachmentsBasedUponOrderCTA(
  clientID: number,
  cta: OrderCTA
): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    if (cta !== OrderItemDisplayStates.CloseOrder && cta !== OrderItemStates.Closed) {
      return
    }

    if (clientDocumentsNeedFetching(getState().documentReducer, clientID)) {
      await dispatch(fetchClientDocuments(clientID))
    }

    const state = getState()

    let latestClientDocs = []
    if (cta === OrderItemStates.Closed) {
      latestClientDocs = getLatestDocumentsForClosedOrders(
        state.documentReducer,
        state.clientReducer,
        state.itemsReducer,
        clientID
      )
    } else {
      latestClientDocs = getLatestDocumentsForOpenOrders(
        state.documentReducer,
        state.clientReducer,
        state.itemsReducer,
        clientID
      )
    }

    if (latestClientDocs) {
      await dispatch(currentMessageUpdate({ attachment_ids: latestClientDocs.map((d: Document) => d.id) }))
    }
  }
}

export interface FetchAutoQAResults extends AxiosMiddlewareActionCreator {
  type: typeof DOCUMENT.FETCH_AUTOQA_RESULTS
  fileID: number
}

export interface FetchAutoQAResultsSuccess extends AxiosMiddlewareActionSuccess<AutoQAScanOutput, FetchAutoQAResults> {
  type: typeof DOCUMENT.FETCH_AUTOQA_RESULTS_SUCCESS
}

export interface FetchAutoQAResultsFailure extends AxiosMiddlewareActionFail<FetchAutoQAResults> {
  type: typeof DOCUMENT.FETCH_AUTOQA_RESULTS_FAIL
}

export function fetchAutoQAResults(fileID: number): FetchAutoQAResults {
  return {
    type: DOCUMENT.FETCH_AUTOQA_RESULTS,
    fileID,
    payload: {
      request: {
        url: `/v1/files/id/${fileID}/auto-qa`,
      },
    },
  }
}

export function reviewDocumentThunk(
  fileID: number,
  clientID: number,
  verdict: EditorDecisions
): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    const editor_id = getState().userReducer.loggedInUserID
    if (!editor_id) {
      return
    }
    await dispatch(
      reviewDocument(fileID, clientID, {
        editor_id,
        verdict,
      })
    )

    const promiseArray = [fetchClientOrderDetailsThunk(clientID, { verbose: true })]

    // approve creates a new doc record, let's fetch it.
    if (verdict === EditorDecisions.Approve) {
      // @ts-ignore
      promiseArray.push(fetchClientDocuments(clientID))
    }

    // @ts-ignore
    await Promise.all(promiseArray.map(action => dispatch(action)))
  }
}

export type DocumentActionTypes =
  | FetchDocuments
  | FetchDocumentsSuccess
  | FetchDocumentsFail
  | UploadFinalizeSuccess
  | FetchDocumentsStart
  | FetchAutoQAResults
  | FetchAutoQAResultsSuccess
  | FetchAutoQAResultsFailure
  | ReviewDoc
  | ReviewDocSuccess
  | ReviewDocFail
  | DeleteDoc
  | DeleteDocSuccess
  | DeleteDocFail
