import React from 'react'
import Badge from '@material-ui/core/Badge'
import { Button, SvgIcon } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import NotesStyles from './Styles'

interface FloatingNotesButtonProps {
  onClick: () => void
  notificationCount: number
}

const useStyles = makeStyles(() =>
  createStyles({
    badgeSecondary: {
      backgroundColor: NotesStyles.badge.bg,
      color: NotesStyles.badge.color,
      fontSize: NotesStyles.badge.fontSize,
      fontWeight: NotesStyles.badge.fontWeight,
      height: NotesStyles.badge.size,
      lineHeight: NotesStyles.badge.lineHeight,
      minWidth: NotesStyles.badge.size,
      padding: NotesStyles.badge.padding,
      width: NotesStyles.badge.size,
    },
    button: {
      backgroundColor: 'white',
      boxShadow: NotesStyles.shadow,
      fontSize: NotesStyles.fontSize,
      minWidth: '0',
      padding: '.7em .4em .5em .7em',
      position: 'fixed',
      right: 0,
      top: '11em',
      zIndex: 1400,

      '&:hover': {
        backgroundColor: NotesStyles.colorWhiteHover,
        boxShadow: NotesStyles.shadow,
      },
    },
    buttonSvg: {
      fontSize: 'inherit',
      height: '1.2em',
      width: '1.2em',
    },
  })
)

const FloatingNotesButton: React.FC<FloatingNotesButtonProps> = ({ onClick, notificationCount }) => {
  const classes = useStyles()

  return (
    <Button onClick={onClick} aria-label="view and edit notes" className={classes.button} variant="contained">
      <Badge
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        badgeContent={notificationCount}
        color="secondary"
        classes={{ colorSecondary: classes.badgeSecondary }}
      >
        <SvgIcon className={classes.buttonSvg}>
          <path
            fill="#333F57"
            fillRule="evenodd"
            stroke="#333F57"
            d="M17.489 1.536c-.714-.715-1.958-.715-2.672 0l-1.242 1.24-.006.008-7.394 7.392a.527.527 0 00-.15.3l-.48 3.36a.531.531 0 00.6.603l3.361-.48a.53.53 0 00.3-.15l7.392-7.392 1.248-1.248c.357-.357.554-.831.554-1.336 0-.506-.195-.981-.551-1.338l-.96-.96zM9.18 12.93l-2.483.355.355-2.484 9.195-9.187 2.128 2.129L9.18 12.93zm8.826-2.561v4.428a4.079 4.079 0 01-4.075 4.074H5.074A4.079 4.079 0 011 14.798V5.94a4.079 4.079 0 014.074-4.075h4.428a.531.531 0 110 1.063H5.074a3.015 3.015 0 00-3.011 3.012v8.857c0 1.66 1.35 3.011 3.011 3.011h8.857c1.661 0 3.012-1.35 3.012-3.011V10.37a.531.531 0 111.063 0z"
          />
        </SvgIcon>
      </Badge>
    </Button>
  )
}

export default FloatingNotesButton
