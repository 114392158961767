import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import { userId } from '../user/reducers'
import { ConvertUserPromiseResponse, OfferPromise, UserActivePromisesResponse } from './types'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AppState } from '../index'
import { AnyAction } from 'redux'
import { addUiNotification, removeUiNotification } from '../notifications/actions'
import { getGhostID } from '../../utils/link'
import { isOffshoreSelector } from '../../selectors/users'

export enum PromiseActionTypes {
  GET_USER_PROMISES = 'GET_USER_PROMISES',
  GET_USER_PROMISES_FAIL = 'GET_USER_PROMISES_FAIL',
  GET_USER_PROMISES_SUCCESS = 'GET_USER_PROMISES_SUCCESS',

  MAKE_USER_PROMISE = 'MAKE_USER_PROMISE',
  MAKE_USER_PROMISE_FAIL = 'MAKE_USER_PROMISE_FAIL',
  MAKE_USER_PROMISE_SUCCESS = 'MAKE_USER_PROMISE_SUCCESS',

  CONVERT_PROMISE = 'CONVERT_PROMISE',
  CONVERT_PROMISE_FAIL = 'CONVERT_PROMISE_FAIL',
  CONVERT_PROMISE_SUCCESS = 'CONVERT_PROMISE_SUCCESS',

  DISMISS_PROMISE = 'DISMISS_PROMISE',
  DISMISS_PROMISE_FAIL = 'DISMISS_PROMISE_FAIL',
  DISMISS_PROMISE_SUCCESS = 'DISMISS_PROMISE_SUCCESS',

  CLEAN_UP_PROMISES = 'CLEAN_UP_PROMISES',
}

export interface GetUserPromises extends AxiosMiddlewareActionCreator {
  type: typeof PromiseActionTypes.GET_USER_PROMISES
  userID: userId
}

export interface GetUserPromisesSuccess
  extends AxiosMiddlewareActionSuccess<UserActivePromisesResponse, GetUserPromises> {
  type: typeof PromiseActionTypes.GET_USER_PROMISES_SUCCESS
}

export interface GetUserPromisesFail extends AxiosMiddlewareActionFail<GetUserPromises> {
  type: typeof PromiseActionTypes.GET_USER_PROMISES_FAIL
}

export interface MakeUserPromise extends AxiosMiddlewareActionCreator {
  type: typeof PromiseActionTypes.MAKE_USER_PROMISE
  userID: userId
}

export interface MakeUserPromiseSuccess extends AxiosMiddlewareActionSuccess<OfferPromise[], MakeUserPromise> {
  type: typeof PromiseActionTypes.MAKE_USER_PROMISE_SUCCESS
}

export interface MakeUserPromiseFail extends AxiosMiddlewareActionFail<MakeUserPromise> {
  type: typeof PromiseActionTypes.MAKE_USER_PROMISE_FAIL
}

export interface DismissUserPromise extends AxiosMiddlewareActionCreator {
  type: typeof PromiseActionTypes.DISMISS_PROMISE
  promiseID: number
  userID: userId
}

export interface DismissUserPromiseSuccess extends AxiosMiddlewareActionSuccess<{}, DismissUserPromise> {
  type: typeof PromiseActionTypes.DISMISS_PROMISE_SUCCESS
}

export interface DismissUserPromiseFail extends AxiosMiddlewareActionFail<DismissUserPromise> {
  type: typeof PromiseActionTypes.DISMISS_PROMISE_FAIL
}

export interface ConvertUserPromise extends AxiosMiddlewareActionCreator {
  type: typeof PromiseActionTypes.CONVERT_PROMISE
  promiseToken: string
  promiseID: number
  userID: userId
}

export interface ConvertUserPromiseSuccess
  extends AxiosMiddlewareActionSuccess<ConvertUserPromiseResponse, ConvertUserPromise> {
  type: typeof PromiseActionTypes.CONVERT_PROMISE_SUCCESS
}

export interface ConvertUserPromiseError extends AxiosMiddlewareActionFail<ConvertUserPromise> {
  type: typeof PromiseActionTypes.CONVERT_PROMISE_FAIL
}

export interface CleanUpPromises {
  type: typeof PromiseActionTypes.CLEAN_UP_PROMISES
}

export function getUserPromises(userID: userId): GetUserPromises {
  return {
    type: PromiseActionTypes.GET_USER_PROMISES,
    userID,
    payload: {
      request: {
        url: `v1/assignment/promises/user/${userID}`,
      },
    },
  }
}

export function makeUserPromise(userID: userId): MakeUserPromise {
  return {
    type: PromiseActionTypes.MAKE_USER_PROMISE,
    userID,
    payload: {
      request: {
        method: 'POST',
        url: `v1/assignment/promises/user/${userID}`,
      },
    },
  }
}

export function convertUserPromise(promiseID: number, promiseToken: string, userID: number): ConvertUserPromise {
  return {
    type: PromiseActionTypes.CONVERT_PROMISE,
    promiseID,
    userID,
    promiseToken,
    payload: {
      request: {
        method: 'POST',
        url: `v1/assignment/promises/convert/${promiseToken}`,
      },
    },
  }
}

export function dismissUserPromise(promiseID: number, userID: number): DismissUserPromise {
  return {
    type: PromiseActionTypes.DISMISS_PROMISE,
    promiseID,
    userID,
    payload: {
      request: {
        method: 'POST',
        url: `v1/assignment/promises/promise/${promiseID}/dismiss`,
      },
    },
  }
}

export function getUserPromisesTimed(interval: number): ThunkAction<Promise<number | null>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    // Only do promises if you are not ghosting and not an offshore user
    const isOffshore = isOffshoreSelector(getState().userReducer)
    if (!!getGhostID() || isOffshore) {
      return null
    }

    checkForPromises(dispatch, getState)
    return window.setInterval(() => {
      checkForPromises(dispatch, getState)
    }, interval)
  }
}

const checkForPromises = async (dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) => {
  const loggedInUserID = getState().userReducer.loggedInUserID
  await dispatch(getUserPromises(!!loggedInUserID ? loggedInUserID : 'me'))

  const promisesState = getState().promiseReducer
  const currentPromise = promisesState.currentPromiseID && promisesState.promises[promisesState.currentPromiseID]

  if (currentPromise) {
    dispatch(addUiNotification({ meta: currentPromise, type: 'promise_generated' }))
  } else {
    const currentUINotification = getState().notificationReducer.uiNotification
    if (currentUINotification && currentUINotification.type === 'promise_generated') {
      dispatch(removeUiNotification())
    }
  }
}

export const cleanupPromises = (): CleanUpPromises => {
  return {
    type: PromiseActionTypes.CLEAN_UP_PROMISES,
  }
}
