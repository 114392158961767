import {
  initialLoadedLoadingErrorState,
  LoadedLoadingErrorState,
  setErrorState,
  setStartState,
  setSuccessState,
} from '../../utils/state'
import { ONBOARDING, OnboardingActionTypes } from './actions'
import produce from 'immer'
import { HashMap } from '../../utils/HashMap'

export interface UserOnboarding {
  user_id: number
  started_at: string
  finished_at: string
  data: UserOnboardingData
}

export interface UserOnboardingData {
  hoursPerWeek?: string
  industries?: number[]
  calendar?: HashMap<boolean>
}

export interface OnboardingState {
  meta: LoadedLoadingErrorState
  info?: UserOnboarding
}

const initialState: OnboardingState = {
  meta: initialLoadedLoadingErrorState,
}

export default function onboardingReducer(state: OnboardingState = initialState, action: OnboardingActionTypes) {
  return produce(state, (draft: OnboardingState) => {
    switch (action.type) {
      case ONBOARDING.FETCH_USER_ONBOARDING:
      case ONBOARDING.UPDATE_USER_ONBOARDING:
      case ONBOARDING.END_USER_ONBOARDING:
        draft.meta = setStartState(draft.meta)
        break
      case ONBOARDING.FETCH_USER_ONBOARDING_SUCCESS:
      case ONBOARDING.UPDATE_USER_ONBOARDING_SUCCESS:
      case ONBOARDING.END_USER_ONBOARDING_SUCCESS:
        const data = action.payload.data
        draft.meta = setSuccessState(draft.meta)
        if (!!data) {
          draft.info = data
        }
        break
      case ONBOARDING.FETCH_USER_ONBOARDING_FAIL:
      case ONBOARDING.UPDATE_USER_ONBOARDING_FAIL:
      case ONBOARDING.END_USER_ONBOARDING_FAIL:
        const error = action.error
        draft.meta = setErrorState(draft.meta, error)
        break

      case ONBOARDING.START_USER_ONBOARDING:
        draft.meta = setStartState(draft.meta)
        break

      case ONBOARDING.START_USER_ONBOARDING_SUCCESS:
        {
          const data = action.payload.data
          draft.meta = setSuccessState(draft.meta)
          if (!!data) {
            draft.info = data
          }
        }
        break
      case ONBOARDING.START_USER_ONBOARDING_FAIL:
        {
          const error = action.error
          draft.meta = setErrorState(draft.meta, error)
        }
        break
    }
  })
}
