import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import Button from '../common/Button'
import i18n from '../../i18n'
import styles from '../../styles'
import { GhostLink } from '../../utils/link'
import { Routes } from '../../utils/consts'

const useStyles = makeStyles(() =>
  createStyles({
    offerRejectionLimit: {
      color: styles.palette.lightGrey.color,
      textAlign: 'center',
    },
    headline: {
      fontSize: '2.25rem',
      color: styles.palette.lightBlack.color,
      marginBottom: '2.125rem',
    },
    bodyText: {
      color: styles.palette.lightBlack.color,
      marginBottom: '3.1125rem',
    },
    buttons: {
      '& .leftButton': { marginRight: '2.1875rem' },
      '@media (max-width: 620px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& .leftButton': { marginRight: 0, marginBottom: '1rem' },
      },
    },
  })
)

const NewHireCapacityReached: React.FC = () => {
  const classes = useStyles()

  return (
    <Box className={classes.offerRejectionLimit}>
      <Typography display="block" className={classes.headline}>
        {i18n.t('order__shopping__offer__stated_capacity_reached__headline')}
      </Typography>
      <Typography display="block" variant={'body1'} className={classes.bodyText}>
        {i18n.t('order__shopping__offer__new_hire_capacity_reached__bodyText')}
      </Typography>
      <Box className={classes.buttons}>
        <GhostLink to={Routes.OpenOrders}>
          <Button className="leftButton" type={'primary'} size={'medium'}>
            {i18n.t('order__shopping__offer__stated_capacity_reached__review_assigned_work')}
          </Button>
        </GhostLink>
      </Box>
    </Box>
  )
}

export default NewHireCapacityReached
