import React from 'react'
import { Button } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

interface CloseButtonProps {
  onClick: () => void
}

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      backgroundColor: 'transparent',
      display: 'none',
      minWidth: '0',
      padding: '1em',
      position: 'fixed',
      right: 0,
      top: '.8rem',
      zIndex: 100,
    },
    [theme.breakpoints.down('sm')]: {
      button: {
        display: 'block',
      },
    },
  })
)

const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  const classes = useStyles()

  return (
    <Button
      onClick={onClick}
      aria-label="close notes"
      className={classes.button}
      variant="contained"
      startIcon={<CloseIcon />}
    >
      <></>
    </Button>
  )
}

export default CloseButton
