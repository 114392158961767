import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../store'
import { initializeCrons, suspendCrons } from '../../store/cron/actions'
import i18n from '../../i18n'
import { cleanupPromises } from '../../store/promises/actions'
import { removeUiNotification } from '../../store/notifications/actions'
import FloatingNudge from '../Nudges/FloatingNudge'

export const CronContainer: React.FC = () => {
  const dispatch = useDispatch()
  const appNeedsUpgrading = useSelector<AppState, boolean>(store => store.cronReducer.versions.needsUpgrade)

  useEffect(() => {
    dispatch(initializeCrons(false))

    return function cleanup() {
      dispatch(suspendCrons())
    }
  }, [dispatch])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        dispatch(suspendCrons())
      } else {
        dispatch(cleanupPromises())
        dispatch(removeUiNotification())
        dispatch(initializeCrons(true))
      }
    }

    window.addEventListener('visibilitychange', handleVisibilityChange)

    return function cleanup() {
      window.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [dispatch])

  if (appNeedsUpgrading) {
    return <FloatingNudge text={i18n.t('notification__upgradeApp')} onClick={() => window.location.reload(true)} />
  }

  return null
}

export default CronContainer
