import React, { FC, useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import NoteAdd from '@material-ui/icons/NoteAdd'
import Tooltip from '@material-ui/core/Tooltip'
import MessageIcon from '@material-ui/icons/Message'
import OpenInBrowser from '@material-ui/icons/OpenInBrowser'
import isEqual from 'lodash/isEqual'
import { off, on } from 'delegated-events'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import { useWindowSize } from 'react-use'

import i18n from '../../i18n'
import styles from '../../styles'
import ChatMessage, { ChatMessageActions } from './ChatMessage'
import { Message, MessageContext } from '../../store/messages/types'
import MessageCompositionWindow from './MessageCompositionWindow'
import MessageUploadsContainer from './MessageUploadsContainer'
import { HashMap, OrderCTA, OrderItemDisplayStates, OrderItemStates } from '../../store/items/types'
import { Document } from '../../store/documents/types'
import useScrollToMessage from './useScrollToMessage'
import { EditorStateProvider } from './useEditorState'
import { messageIsAutomated, messageIsUnreadByUser } from '../../selectors/messages'
import useUserInfoState from '../common/useUserInfo'
import { useOrderCarousel } from '../OrderFamily/SideBar/useOrderCarousel'
import { SidebarContext } from '../Order2OrderNavigation/useSidebarState'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#fff',
      border: '1px solid #D5D9E0',
      '&.offsetSmall': { paddingBottom: '4rem' },
      '&.offsetLarge': { paddingBottom: '45vh' },
    },
    tabLabel: {
      fontSize: '1.25rem',
      textTransform: 'initial',
    },
    body: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '0.9rem 2rem',
      '@media (max-width: 1024px)': {
        position: 'relative',
      },
      '@media (max-width: 540px)': {
        padding: '0.9rem 1rem 5rem 1rem',
      },
      '& pre': {
        margin: '0',
        padding: '0',
        fontFamily: styles.fonts.primary.family,
        fontWeight: 'normal',
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
      },
    },
    emptyState: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
    },
    messages: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
    },
    buttons: {
      flexGrow: 1,
      padding: '0.875rem 0',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      fontSize: '0.9375rem',
      textTransform: 'initial',
      padding: '0 1.583rem',
      height: '2.5rem',
    },
    darkButton: {
      '&$button': {
        color: '#000',
        marginRight: '.5rem',
        borderColor: '#484444',
      },
    },
    lightButton: {
      '&$button': {
        color: '#fff',
      },
    },
    icons: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: '1rem',
      cursor: 'pointer',
      color: '#bcc9d6',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    activeIcon: {
      color: theme.palette.primary.main,
    },
    letterIcon: {
      position: 'relative',
      top: '2px',
      fontSize: '1.6rem',
    },
    footer: {
      padding: '0 2rem',
      borderTop: 'solid 1px #d5d9e0',
      boxSizing: 'border-box',
      backgroundColor: '#fff',
      '&.sticky': {
        position: 'fixed',
        zIndex: 10,
        bottom: 0,
        border: 'solid 1px #d5d9e0',
        '@media (max-width: 540px)': {
          padding: '0 .8rem 0 ',
        },
      },
    },
    messagingActions: {
      display: 'flex',
      borderTop: 'solid 1px #d5d9e0',
      padding: '1.0625rem 1.0625rem 0.6875rem',
      boxSizing: 'border-box',
      backgroundColor: '#fff',
      '&.sticky': {
        position: 'fixed',
        zIndex: 10,
        bottom: 0,
        border: 'solid 1px #d5d9e0',
      },
    },
    actionIcon: {
      cursor: 'pointer',
      marginRight: '0.9rem',
    },
  })
)

export interface ChatProps {
  dueDate: string | Date | null
  messages: Message[]
  context: MessageContext
  contextID: number
  activeOrderID: number | null
  orderIDs: number[]
  clientID: number
  initialState?: OrderCTA
  attachments: HashMap<Document[]>
  currentMessageReplyID?: number | null
  replyHandler?: (message: Message | null) => void
  chatMessageActions?: ChatMessageActions
  messageCompositionVisible: boolean
}

enum VisibleWindows {
  CLIENT_STRATEGY = 'CLIENT_STRATEGY',
  UPLOADS = 'UPLOADS',
}

type VisibleWindow = typeof VisibleWindows.UPLOADS | typeof VisibleWindows.CLIENT_STRATEGY

const clientStrategyHighlightedStates: OrderCTA[] = [OrderItemStates.SendIntro, OrderItemStates.SendMessage]
const uploadVisibleStates: OrderCTA[] = [
  OrderItemStates.SendFirstDraft,
  OrderItemStates.SendSummary,
  OrderItemStates.SendLinkedin,
]

const Chat: FC<ChatProps> = ({
  messages,
  context,
  contextID,
  activeOrderID,
  orderIDs,
  initialState,
  attachments = {},
  replyHandler = () => {},
  currentMessageReplyID = null,
  chatMessageActions,
  messageCompositionVisible,
}) => {
  const classes = useStyles()
  const [activeCTA, setActiveCTA] = useState(initialState)
  const isClientStrategyHighlighted = !!activeCTA && clientStrategyHighlightedStates.includes(activeCTA)
  const isUploadVisible = !!activeCTA && uploadVisibleStates.includes(activeCTA)
  const [uploadVisible, setUploadVisible] = React.useState(isUploadVisible)
  const [clientStrategyVisible, setClientStrategyVisible] = React.useState(isClientStrategyHighlighted)
  const [isFirstLoad, setIsFirstLoad] = React.useState(true)
  const [justSentMessage, setJustSentMessage] = React.useState(false)
  const [justClickedReply, setJustClickedReply] = React.useState(false)
  const userInfo = useUserInfoState()
  const currentOrderGroup = useOrderCarousel(contextID)
  const {
    scrollToMessage,
    setMessageRef,
    getMessageState,
    toggleMessageExpansion,
    collapseAllMessages,
    expandedMessageCount,
  } = useScrollToMessage()

  const [showMessageCompositionWindow, setShowMessageCompositionWindow] = React.useState(messageCompositionVisible)
  const reallyShowMessageCompositonWindow = showMessageCompositionWindow || messageCompositionVisible
  const orderReassignedFromWriter = currentOrderGroup.currentOrderGroup.macroState.CTA === OrderItemStates.Reassigned
  const [stickyFooterWidth, setStickyFooterWidth] = useState<string>('inherit')
  const windowSize = useWindowSize()
  const hasNoMessages = !messages || !messages.length

  // Make the sticky footer's width match the parent container's. Also, have it respond to Order2Order navigation
  // toggling.
  const sideMenuState = useContext(SidebarContext)
  useLayoutEffect(
    debounce(() => {
      const messageContainerElm = document.getElementById('messages-container')

      if (messageContainerElm) {
        setStickyFooterWidth(`${messageContainerElm.clientWidth}px`)
      } else {
        setStickyFooterWidth('inherit')
      }
    }, 10),
    [
      setStickyFooterWidth,
      windowSize.width,
      windowSize.height,
      sideMenuState.open,
      reallyShowMessageCompositonWindow,
      expandedMessageCount,
    ]
  )

  // Handle various scrolling logic
  useLayoutEffect(() => {
    async function scrollEffects() {
      if (messages.length > 0) {
        const latestMessage = messages[messages.length - 1]

        if (isFirstLoad) {
          const firstUnreadMessage = messages.filter(m => messageIsUnreadByUser(m) && !messageIsAutomated(m))
          if (firstUnreadMessage && firstUnreadMessage.length > 0) {
            setIsFirstLoad(!(await scrollToMessage(firstUnreadMessage[0].id)))
          } else {
            setIsFirstLoad(!(await scrollToMessage(latestMessage.id)))
          }
        }

        if (justSentMessage) {
          setJustSentMessage(!(await scrollToMessage(latestMessage.id, 'smooth')))
        }

        if (justClickedReply && !!currentMessageReplyID) {
          setJustClickedReply(!(await scrollToMessage(currentMessageReplyID, 'smooth')))
        }
      }
    }
    scrollEffects()
  }, [
    isFirstLoad,
    justSentMessage,
    currentMessageReplyID,
    messages,
    scrollToMessage,
    toggleMessageExpansion,
    justClickedReply,
  ])

  // The order CTA button is outside of the immediate tree of this component and that makes it tricky to pass down the
  // initialState when on the messaging page. We get around this by listening for a custom event that tracks it's click.
  useEffect(() => {
    const ctaEventHandler = (event: CustomEvent) => {
      const { cta } = event.detail

      if (!cta) {
        return
      }

      setActiveCTA(cta)

      if (uploadVisibleStates.includes(cta)) {
        setUploadVisible(true)
      }

      if (clientStrategyHighlightedStates.includes(cta)) {
        setClientStrategyVisible(true)
      }
    }

    on('cta:click', 'body', ctaEventHandler)

    return function cleanup() {
      off('cta:click', 'body', ctaEventHandler)
    }
  }, [setActiveCTA, setUploadVisible, setClientStrategyVisible])

  const setActiveWindow = useCallback(
    (visibleWindow: VisibleWindow | null) => {
      setUploadVisible(visibleWindow === VisibleWindows.UPLOADS)
      setClientStrategyVisible(visibleWindow === VisibleWindows.CLIENT_STRATEGY)
    },
    [setUploadVisible, setClientStrategyVisible]
  )

  const sendMessageCallback = useCallback(
    (messageID: number) => {
      toggleMessageExpansion(messageID)
      setJustSentMessage(true)
    },
    [toggleMessageExpansion, setJustSentMessage]
  )

  const openUploads = React.useMemo<[boolean, () => void]>(
    () => [uploadVisible, () => setActiveWindow(VisibleWindows.UPLOADS)],
    [setActiveWindow, uploadVisible]
  )

  const openClientStrategy = React.useMemo<[boolean, () => void]>(
    () => [clientStrategyVisible, () => setActiveWindow(VisibleWindows.CLIENT_STRATEGY)],
    [setActiveWindow, clientStrategyVisible]
  )

  const closeWindow = useCallback(() => setActiveWindow(null), [setActiveWindow])

  return (
    <Box
      className={classNames({
        [classes.container]: true,
        offsetSmall: !reallyShowMessageCompositonWindow && !hasNoMessages,
        offsetLarge: reallyShowMessageCompositonWindow && !hasNoMessages,
      })}
      id="messages-container"
    >
      <Box className={classes.body}>
        {(!messages || !messages.length) && (
          <Box className={classes.emptyState}>
            <MessageIcon color="primary" fontSize="large" />
            <Typography variant="h3">{i18n.t('messaging__emptyState')}</Typography>
          </Box>
        )}
        {messages.map(m => {
          const state = getMessageState(m.id)
          const expanded = state ? state.expanded : false

          return (
            <ChatMessage
              key={`chat-message-${m.id}`}
              message={m}
              attachments={attachments[m.id]}
              expanded={expanded}
              onReplyClick={() => {
                currentMessageReplyID === null || currentMessageReplyID !== m.id ? replyHandler(m) : replyHandler(null)
                setJustClickedReply(!!currentMessageReplyID)
              }}
              isBeingRepliedTo={currentMessageReplyID === m.id}
              setMessageRef={setMessageRef}
              toggleExpansion={async (changeStatus = true) => {
                toggleMessageExpansion(m.id)
                chatMessageActions && changeStatus && (await chatMessageActions.markMessageAsRead(m))
              }}
              actions={chatMessageActions}
            />
          )
        })}
        {uploadVisible && (
          <MessageUploadsContainer
            orderIDs={orderIDs}
            contextID={contextID}
            context={context}
            closeWindow={closeWindow}
            isOnboardingMentee={userInfo.isExpertMentee}
            canSkipAQAFeedback={userInfo.isEditor || userInfo.isAdmin}
            cta={
              !!activeCTA && activeCTA !== OrderItemDisplayStates.None
                ? activeCTA
                : currentOrderGroup.currentOrderGroup.macroState.CTA
            }
          />
        )}
      </Box>

      {!reallyShowMessageCompositonWindow && !orderReassignedFromWriter && (
        <Box
          className={classNames({
            [classes.messagingActions]: true,
            sticky: true,
          })}
          style={{
            width: stickyFooterWidth,
          }}
        >
          <Tooltip
            title={i18n.t('messaging__tooltip__composeMessage') || 'Compose Message'}
            area-label="message_compose"
          >
            <NoteAdd
              onClick={() => setShowMessageCompositionWindow(!messageCompositionVisible)}
              className={classes.actionIcon}
            />
          </Tooltip>
          <Tooltip
            title={i18n.t('messaging__tooltip__collapseAllMessages') || 'Collapse All Messages'}
            area-label="collapse_all_messages"
          >
            <OpenInBrowser className={classes.actionIcon} onClick={() => collapseAllMessages()} />
          </Tooltip>
        </Box>
      )}
      {reallyShowMessageCompositonWindow && (
        <Box
          component="footer"
          className={classNames({
            [classes.footer]: true,
            sticky: true,
          })}
          style={{
            width: stickyFooterWidth,
          }}
        >
          <EditorStateProvider>
            <MessageCompositionWindow
              context={context}
              contextID={contextID}
              orderID={activeOrderID}
              openUploads={openUploads}
              isClientStrategyHighlighted={isClientStrategyHighlighted}
              openClientStrategy={openClientStrategy}
              sendMessageCallback={sendMessageCallback}
              closeWindow={() => setShowMessageCompositionWindow(false)}
            />
          </EditorStateProvider>
        </Box>
      )}
    </Box>
  )
}

const memoizedChat = React.memo(Chat, isEqual)
// @ts-ignore
memoizedChat.whyDidYouRender = true

export default memoizedChat
