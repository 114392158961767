import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, Route, RouteProps, Switch, useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useHotkeys } from 'react-hotkeys-hook'
import classNames from 'classnames'
import { useLocation } from 'react-use'

import Messaging from '../Messaging'
import Documents from '../Documents'
import ClientContainer, { OrderContainerTabs } from '../OrderFamily/ClientContainer'
import { MessageContexts } from '../../store/messages/types'
import OrderShoppingContainer from '../OrderShopping/OrderShoppingContainer'
import PromisesContainer, { PromiseMode } from '../Promises/PromisesContainer'
import { UserInfo } from '../../store/user/reducers'
import ErrorBoundary from '../common/ErrorBoundary'
import IntercomContainer from '../common/IntercomContainer'
import AsyncComponent from '../common/AsyncComponent'
import { Routes } from '../../utils/consts'
import OrderDetailsContainer from '../OrderFamily/OrderDetails/OrderDetailsContainer'
import OrdersContainer from '../Orders/OrdersContainer'
import { OrderItemDisplayStates } from '../../store/items/types'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import OnboardingRouter from '../common/OnboardingRouter'
import CronContainer from '../common/CronContainer'
import Loader from '../common/Loader'
import { AppState } from '../../store'
import { preloadApp } from '../../store/user/actions'
import { sendUserEvent } from '../../store/events/actions'
import NotesDrawer from '../Notes/NotesDrawer'
import { EventTypes } from '../../store/events/types'
import { getFeatureFlags } from '../../selectors/featureFlags'
import TopNavBar from '../TopNavBar'
import Order2OrderNavigation from '../Order2OrderNavigation'
import styles from '../../styles'
import useSidebarState, { SidebarContext } from '../Order2OrderNavigation/useSidebarState'
import NudgeContainer from '../Nudges/NudgeContainer'

const Account = React.lazy(() => import('../Account'))
const Dashboard = React.lazy(() => import('../Dashboard'))
const OnboardingContainer = React.lazy(() => import('../Onboarding/OnboardingContainer'))
const AutoQAResultsPage = React.lazy(() => import('../UploadsV3/AutoQAResultsPage'))
const AutoLinkedinFormPage = React.lazy(() => import('../AutoLinkedin/AutoLinkedinFormPage'))

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      minHeight: 'calc(100vh - 3.625rem)',
    },
    content: {
      flexGrow: 1,
      position: 'relative',
      minHeight: 'calc(100vh - 3.625rem - 0.4rem)',
      margin: `${styles.sizes.topNavHeight} 2.8rem 0.4rem`,
      '@media (max-width: 1050px)': {
        width: 'calc(100% - 5.6rem)',
      },
      '@media (max-width: 560px)': {
        width: '100%',
        margin: '0',
      },
    },
    order2orderNavOpen: {
      paddingLeft: styles.sizes.order2orderNavWidth,
    },
    menu: {},
  })
)

const Page: React.FC = () => {
  const user = useSelector<AppState, UserInfo | null>(state => state.userReducer.getLoggedInUser())
  const userIsLoading = useSelector<AppState, boolean>(state => state.userReducer.isLoading)

  const [isFirstLoad, setHasLoaded] = useState(false)

  const dispatch = useDispatch()
  const classes = useStyles()

  const { pathname } = useLocation()

  const showOrderToOrderNavIcon = pathname !== Routes.OpenOrders && pathname !== Routes.PaidOrders

  const [sideMenuState, toggleSideMenu] = useSidebarState()

  const history = useHistory()

  const { showOrderShopping } = getFeatureFlags(user)

  // Change client views
  const changeClientView = useCallback(
    view => {
      if (pathname && pathname.includes('/client')) {
        const pagePath = history.location.pathname.replace('/client/', '')
        let clientId
        if (pagePath.indexOf('/') === -1) {
          clientId = pagePath
        } else {
          clientId = pagePath.slice(0, pagePath.indexOf('/'))
        }
        const viewPath = view.replace(':clientID', clientId)
        history.push(`${viewPath}${history.location.search}`)
      }
    },
    [history, pathname]
  )

  // Hotkey assignments
  useHotkeys('ctrl+m', () => changeClientView(Routes.ClientMessages))
  useHotkeys('ctrl+d', () => changeClientView(Routes.ClientDocuments))
  useHotkeys('ctrl+i', () => changeClientView(Routes.Client))

  useEffect(() => {
    const isNotAuthPage = !window.location.pathname.includes('/auth')

    if (!user && !userIsLoading && !isFirstLoad && isNotAuthPage) {
      dispatch(preloadApp())
      setHasLoaded(true)
    }
  }, [user, dispatch, userIsLoading, setHasLoaded, isFirstLoad])

  // Send an event for each page view
  useEffect(() => {
    history.listen(location => {
      if (!user) {
        return
      }

      dispatch(
        sendUserEvent({
          event: EventTypes.ViewedPage,
          variables: {
            pathname: location.pathname,
            search: location.search,
          },
        })
      )
    })
  }, [history, user, dispatch])

  if (!user) {
    if (!userIsLoading) {
      return null
    }

    return <Loader fullPage={true} />
  }

  const notesDrawer = <NotesDrawer />

  return (
    <SidebarContext.Provider value={sideMenuState}>
      <TopNavBar showOrderToOrderNavIcon={showOrderToOrderNavIcon} onMenuClick={toggleSideMenu} user={user} />
      <Box className={classes.container} id="full-page-container" component="main">
        <Box className={classes.menu}>
          {showOrderToOrderNavIcon && (
            <Order2OrderNavigation expanded={sideMenuState.open} toggleNavigation={toggleSideMenu} />
          )}
        </Box>
        <Box
          className={classNames({
            [classes.content]: true,
            [classes.order2orderNavOpen]: sideMenuState.open && showOrderToOrderNavIcon && !sideMenuState.mobile,
          })}
        >
          <ErrorBoundary>
            <Switch>
              <Route
                path={Routes.Account}
                render={() => (
                  <>
                    {notesDrawer}
                    <AsyncComponent>
                      <ScrollToTopOnMount />
                      <Account user={user} />
                    </AsyncComponent>
                  </>
                )}
              />
              <Route
                exact
                path={Routes.Dashboard}
                render={() => (
                  <>
                    {notesDrawer}
                    <AsyncComponent>
                      <ScrollToTopOnMount />
                      <Dashboard user={user} />
                    </AsyncComponent>
                  </>
                )}
              />
              <Route
                path={Routes.Shopping}
                render={(routeProps: RouteProps) =>
                  showOrderShopping ? (
                    <>
                      {notesDrawer}
                      <ScrollToTopOnMount />
                      <OrderShoppingContainer userID={user.id} location={routeProps.location} />
                    </>
                  ) : (
                    <Redirect to={Routes.Root} />
                  )
                }
              />
              <Route
                path={Routes.Client}
                render={routeProps => {
                  const clientID = parseInt(routeProps.match.params.clientID)
                  let activeTab = OrderContainerTabs.Details

                  if (routeProps.location.pathname.includes('documents')) {
                    activeTab = OrderContainerTabs.Documents
                  } else if (routeProps.location.pathname.includes('messages')) {
                    activeTab = OrderContainerTabs.Messages
                  }

                  const state = !!routeProps.location.state
                    ? routeProps.location.state.CTA
                    : OrderItemDisplayStates.None

                  return (
                    <>
                      {notesDrawer}
                      <ClientContainer clientID={clientID} activeTab={activeTab}>
                        <Route
                          exact
                          path={Routes.Client}
                          render={() => (
                            <>
                              <ScrollToTopOnMount />
                              <OrderDetailsContainer clientID={clientID} />
                            </>
                          )}
                        />
                        <Route
                          exact
                          path={Routes.ClientMessages}
                          render={() => (
                            <>
                              <ScrollToTopOnMount />
                              <Messaging
                                clientID={clientID}
                                context={MessageContexts.CLIENT}
                                initialState={state}
                                user={user}
                              />
                            </>
                          )}
                        />
                        <Route
                          exact
                          path={Routes.ClientDocuments}
                          render={() => (
                            <>
                              <ScrollToTopOnMount />
                              <Documents clientID={clientID} />
                            </>
                          )}
                        />
                      </ClientContainer>
                    </>
                  )
                }}
              />
              <Route
                exact
                path={Routes.Orders}
                render={(routeProps: RouteProps) => (
                  <>
                    {notesDrawer}
                    <ScrollToTopOnMount />
                    <OrdersContainer user={user} location={routeProps.location} />
                  </>
                )}
              />
              <Route
                exact
                path={Routes.OpenOrders}
                render={(routeProps: RouteProps) => (
                  <>
                    {notesDrawer}
                    <ScrollToTopOnMount />
                    <OrdersContainer user={user} location={routeProps.location} />
                  </>
                )}
              />
              <Route
                exact
                path={Routes.OnboardingStep}
                component={() => (
                  <AsyncComponent>
                    <ScrollToTopOnMount />
                    <OnboardingContainer user={user} />
                  </AsyncComponent>
                )}
              />
              <Route
                exact
                path={Routes.AutoQAResults}
                component={() => (
                  <AsyncComponent>
                    <AutoQAResultsPage />
                  </AsyncComponent>
                )}
              />
              <Route
                exact
                path={Routes.AutoLinkedin}
                component={() => (
                  <AsyncComponent>
                    <AutoLinkedinFormPage />
                  </AsyncComponent>
                )}
              />
            </Switch>
          </ErrorBoundary>
        </Box>
      </Box>
      <PromisesContainer user={user} mode={PromiseMode.Notification} />
      <IntercomContainer />
      <OnboardingRouter />
      <NudgeContainer />
      <CronContainer />
    </SidebarContext.Provider>
  )
}

export default Page
