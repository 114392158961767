import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { WebMobileFilterBreakPoint } from './OrdersContainer'
import { Dialog, DialogTitle, ListItem, ListItemText } from '@material-ui/core'
import List from '@material-ui/core/List'
import classNames from 'classnames'
import useOrderFiltering, { OrderTableDisplayModes } from './useOrderFiltering'
import { Routes } from '../../utils/consts'
import NavigationItem from '../TopNavBar/NavigationItem'

export enum FilterTypes {
  Mobile = 'Mobile',
  Web = 'Web',
}

interface FilteringProps {
  onFilteringControlChange: (newValue: OrderTableDisplayModes) => void
  filterType?: keyof typeof FilterTypes
  filterDialogVisible: boolean
  toggleFilterDialog: (value: boolean) => void
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '1.6375rem',
    display: 'flex',
    [`@media (max-width: ${WebMobileFilterBreakPoint}px)`]: {
      flexDirection: 'column',
      marginTop: '0',
    },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    marginRight: '1rem',
    fontSize: '0.875rem',
    '&:hover': {
      textDecoration: 'underline',
    },
    '@media (max-width: 540px)': {
      marginBottom: '1rem',
    },
  },
  active: {
    '&$status': {
      color: theme.palette.primary.main,
    },
  },
  number: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '1.4375rem',
    height: '1.1875rem',
    fontSize: '0.8125',
    background: theme.palette.secondary.main,
    marginLeft: '.2rem',
    borderRadius: '2px',
    color: '#fff',
  },
  activeNumber: {
    '&$number': {
      background: theme.palette.primary.main,
    },
  },
}))

export interface FilterOption {
  id: number
  label: string
  count: number
  key: OrderTableDisplayModes
  isVisible: boolean
}

const Filtering: React.FC<FilteringProps> = ({
  onFilteringControlChange,
  filterType = FilterTypes.Web,
  filterDialogVisible,
  toggleFilterDialog,
}) => {
  const classes = useStyles()

  const orderFiltering = useOrderFiltering()

  const onFilteringControlClick = (item: FilterOption) => {
    onFilteringControlChange(item.key)
    toggleFilterDialog(!filterDialogVisible)
  }

  return (
    <>
      {filterType === FilterTypes.Web && (
        <Box className={classes.root}>
          {orderFiltering.options
            .filter(option => option.isVisible)
            .map(option => (
              <NavigationItem
                to={{
                  pathname: Routes.OpenOrders,
                  search: `filter=${option.key}`,
                }}
              >
                <span
                  onClick={() => onFilteringControlClick(option)}
                  key={option.key}
                  className={classNames({
                    [classes.status]: true,
                    [classes.active]: option.id === orderFiltering.currentOption.id,
                  })}
                >
                  {option.label}
                  <span
                    className={classNames({
                      [classes.number]: true,
                      [classes.activeNumber]: option.id === orderFiltering.currentOption.id,
                    })}
                  >
                    {option.count}
                  </span>
                </span>
              </NavigationItem>
            ))}
        </Box>
      )}
      {filterType === FilterTypes.Mobile && (
        <Dialog aria-labelledby="simple-dialog-title" open={filterDialogVisible}>
          <DialogTitle id="simple-dialog-title">Select orders to display</DialogTitle>
          <List>
            <Box className={classes.root}>
              {orderFiltering.options
                .filter(option => option.isVisible)
                .map(option => (
                  <ListItem button onClick={() => onFilteringControlClick(option)} key={option.key}>
                    <ListItemText>
                      <span
                        className={classNames({
                          [classes.status]: true,
                          [classes.active]: option.id === orderFiltering.currentOption.id,
                        })}
                      >
                        {option.label}
                        <span
                          className={classNames({
                            [classes.number]: true,
                            [classes.activeNumber]: option.id === orderFiltering.currentOption.id,
                          })}
                        >
                          {option.count}
                        </span>
                      </span>
                    </ListItemText>
                  </ListItem>
                ))}
            </Box>
          </List>
        </Dialog>
      )}
    </>
  )
}

export default Filtering
