import { LoadedLoadingErrorState } from '../../utils/state'
import { SCHEDULING } from './actions'

export interface SchedulingMeta {
  [SCHEDULING.GENERATE_SCHEDULE]: LoadedLoadingErrorState
  [SCHEDULING.SET_AVAILABILITY]: LoadedLoadingErrorState
  [SCHEDULING.SET_PREFERENCES]: LoadedLoadingErrorState
  [SCHEDULING.GET_PREFERENCES]: LoadedLoadingErrorState
  [SCHEDULING.GET_CALENDAR_EVENTS]: LoadedLoadingErrorState
  [SCHEDULING.CREATE_CALENDAR_EVENT]: LoadedLoadingErrorState
  [SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN]: LoadedLoadingErrorState
  [SCHEDULING.GET_SCHEDULED_ITEM_MEETING_DETAILS]: LoadedLoadingErrorState
  [SCHEDULING.DISCONNECT_CALENDAR]: LoadedLoadingErrorState
}

export interface SchedulingState {
  accounts: AccountList
  weeklyPeriods: WeeklyPeriod[]
  preferences: Preferences | null
  calendarEvents: CalendarEvent[]
  elementToken: string | null
  metaData: SchedulingMeta
  is_available_for_scheduled_items: boolean
}

export interface AccountList {
  [type: string]: {
    [id: number]: SchedulingAccount
  }
}

export interface SchedulingAccount {
  id: number
  account_sub: string
  type: AccountType
  default_calendar_id: string | null
}

export enum AccountType {
  default = 'default',
  user = 'user',
}

export enum CalendarLinkType {
  None,
  Primary,
  Secondary,
}

export interface Preferences {
  primary_scheduling_account_id?: number
  email_reminder: boolean
  sms_reminder: boolean
  buffer: number
  min_notice: number
}

export interface WeeklyPeriod {
  day: string
  start_time: string
  end_time: string
}

export interface CalendarEvent {
  context_id: string
  event_id: string
  client_id: string
  order_id: string
  title: string
  description?: string
  attendees?: Attendees[] | null
  start: Date
  end: Date
  brand?: Brand | string
  type: EventTypes
  status?: EventStatus | string
}

export interface Attendees {
  email?: string
  display_name: string
  status?: string
}

export enum EventStatus {
  Free = 'free',
  Busy = 'busy',
}

export enum Brand {
  TopInterview = 'TopInterview',
  TopResume = 'TopResume',
}

export enum EventTypes {
  Call = 'managed',
  External = 'external',
  Busy = 'free_busy',
  Draft = 'first_draft',
}

export enum OnboardingStates {
  Trigger = 'Trigger',
  Availability = 'Availability',
  Preferences = 'Preferences',
  Completed = 'Completed',
}

export interface PreferenceData {
  weekly_periods: WeeklyPeriod[]
  scheduling_preferences: Preferences
  scheduling_accounts: SchedulingAccount[]
  is_available_for_scheduled_items: boolean
}

export interface UserSchedulingData {
  calendar_events: CalendarEvent[]
  element_token: string | null
}

export enum CalendarType {
  WeekView = 'WeekView',
  DayView = 'DayView',
  WorkWeekView = 'WorkWeekView',
}

export interface WeeklyPeriodNumeric {
  day: number
  start_time: number
  end_time: number
}

export enum Direction {
  Dec = 0,
  Inc,
}

export interface ZoomMeetingData {
  is_scheduled: boolean
  start_time: string
  zoom_meeting_id: string
  zoom_host_signature: string
  zoom_participant_signature: string
  zoom_join_url: string
  zoom_start_url: string
  zoom_phone_numbers: ZoomPhoneNumbers[]
  zoom_password: string
}

export interface ZoomPhoneNumbers {
  country: string
  country_name: string
  number: string
  type: string
}

export interface PhoneConsultationDetails {
  item_id: number
  item_status: string
  item_due_at: string | undefined
  order_id: number
  client_name: string
  client_id: number
  brand: string
  meeting_id: number | null
  meeting_occurred: boolean
}

export interface AvailabilityRule {
  availability_rule_id: string
  tzid: string
  calendar_ids: string[]
  weekly_periods: WeeklyPeriod[]
}
