export interface File {
  s3_bucket: string
  s3_key: string
  file: string
  originalName: string
  download_url?: string
}

export interface PhoneNumber {
  type: string
  number: string
}

export interface JobPosting {
  url: string
  error: boolean
  description?: string
}

export interface Address {
  street1?: string | string[] | null
  city?: string | null
  state?: string | null
  zip?: string | null
  country?: string | null
  nonUsAddress: boolean
  location?: string | null
  relocation?: boolean
}

export interface Industry {
  id: number
  other_answer?: string | null
  answer?: string
}

export interface Name {
  firstName: string | null
  middleName: string | null
  lastName: string | null
  fullName: string | null
}

export interface Accomplishment {
  title: string
  org_name: string
  list?: string[]
}

export interface Answer<Type = 'text', Structure = string> {
  type: Type
  answer: Structure
}

// The string Type here allows for type descrimination at runtime
export interface QuestionnaireItem<Type = 'text', Structure = string> {
  question_id: number
  question: string
  explanation: string
  lastUpdated: string
  answers: Answer<Type, Structure>[]
  files: File[]
}

export interface QuestionAnswer<Structure = string> {
  question: string
  answer: Structure
}

export interface QuestionUpdateData {
  question_id: number
  answer: string
}

export enum KnownQuestionnaireKeys {
  documents = 'documents',
  otherDocuments = 'otherDocuments',
  name = 'name',
  email = 'email',
  phone = 'phone',
  linkedinLink = 'linkedinLink',
  concerns = 'concerns',
  concerns_info = 'concerns_info',
  concerns_linked_info = 'concerns_linked_info',
  goals = 'goals',
  sampleJobDoc = 'sampleJobDoc',
  job_title = 'job_title',
  obstacles = 'obstacles',
  accomplishments_list = 'accomplishments_list',
  accomplishment_text = 'accomplishment_text',
  accomplishments_text = 'accomplishments_text',
  attributes = 'attributes',
  possitiveThings = 'possitiveThings',
  address = 'address',
  industry = 'industry',
  writerNote = 'writerNote',
}

export type KnownQuestionnaireKey = keyof typeof KnownQuestionnaireKeys

// @TODO There are questions in the onboarding DB that are not described here. In an ideal world, we would be able to
// set this interface up to be able to describe unlisted keys, but Typescript makes this very difficult.
// https://github.com/microsoft/TypeScript/issues/17867
export interface Questions {
  [KnownQuestionnaireKeys.documents]?: QuestionnaireItem
  [KnownQuestionnaireKeys.otherDocuments]?: QuestionnaireItem
  [KnownQuestionnaireKeys.name]?: QuestionnaireItem | QuestionnaireItem<'name', Name>
  [KnownQuestionnaireKeys.email]?: QuestionnaireItem
  [KnownQuestionnaireKeys.phone]?: QuestionnaireItem<'phone', PhoneNumber>
  [KnownQuestionnaireKeys.linkedinLink]?: QuestionnaireItem
  [KnownQuestionnaireKeys.concerns]?: QuestionnaireItem
  [KnownQuestionnaireKeys.concerns_info]?: QuestionnaireItem
  [KnownQuestionnaireKeys.concerns_linked_info]?: QuestionnaireItem
  [KnownQuestionnaireKeys.goals]?: QuestionnaireItem<'jobPosting', JobPosting>
  [KnownQuestionnaireKeys.sampleJobDoc]?: QuestionnaireItem
  [KnownQuestionnaireKeys.job_title]?: QuestionnaireItem
  [KnownQuestionnaireKeys.obstacles]?: QuestionnaireItem
  [KnownQuestionnaireKeys.accomplishments_list]?: QuestionnaireItem<'accomplishments_list', Accomplishment>
  [KnownQuestionnaireKeys.accomplishment_text]?: QuestionnaireItem
  [KnownQuestionnaireKeys.accomplishments_text]?: QuestionnaireItem<'text', string | number>
  [KnownQuestionnaireKeys.attributes]?: QuestionnaireItem
  [KnownQuestionnaireKeys.possitiveThings]?: QuestionnaireItem
  [KnownQuestionnaireKeys.writerNote]?: QuestionnaireItem
  [KnownQuestionnaireKeys.address]?: QuestionnaireItem<'address', Address>
  [KnownQuestionnaireKeys.industry]?: QuestionnaireItem<'industry', Industry>
}

export interface Questionnaire {
  questions?: Questions
  document_digest?: string | null
  resume_images?: string[] | null
}

export const ConcernKeys = [
  KnownQuestionnaireKeys.concerns,
  KnownQuestionnaireKeys.concerns_info,
  KnownQuestionnaireKeys.concerns_linked_info,
  KnownQuestionnaireKeys.possitiveThings,
  KnownQuestionnaireKeys.obstacles,
  KnownQuestionnaireKeys.attributes,
] as const

export type ConcernKey = typeof ConcernKeys[number]

export const AdditionalDocumentsKeys = [
  KnownQuestionnaireKeys.documents,
  KnownQuestionnaireKeys.otherDocuments,
  KnownQuestionnaireKeys.sampleJobDoc,
] as const

export type AdditionalDocumentsKey = typeof AdditionalDocumentsKeys[number]
