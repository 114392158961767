import { OrderResponse } from './types'
import {
  AxiosMiddlewareAction,
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import { PaginationArgs } from '../../utils/pagination'
import { FetchClientOrdersSuccess, FetchUserAggregatedOrdersSuccess } from '../clientOrderAggregate/actions'

export enum OrdersActionTypes {
  FETCH_ORDERS = 'FETCH_ORDERS',
  FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS',
  FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL',

  FETCH_ORDER = 'FETCH_ORDER',
  FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS',
  FETCH_ORDER_FAIL = 'FETCH_ORDER_FAIL',
}
export type OrderStatus = 'open' | 'paid'

export interface OrderFilter extends PaginationArgs {
  status?: OrderStatus[]
  verbose?: boolean
  userID?: number
}

export interface FetchOrders extends AxiosMiddlewareActionCreator {
  type: typeof OrdersActionTypes.FETCH_ORDERS
}

export interface FetchOrdersStart extends AxiosMiddlewareAction {
  type: typeof OrdersActionTypes.FETCH_ORDERS
}

export interface FetchOrdersSuccess extends AxiosMiddlewareActionSuccess<OrderResponse[]> {
  type: typeof OrdersActionTypes.FETCH_ORDERS_SUCCESS
}

export interface FetchOrdersFail extends AxiosMiddlewareActionFail {
  type: typeof OrdersActionTypes.FETCH_ORDERS_FAIL
}

export interface FetchOrder extends AxiosMiddlewareActionCreator {
  type: typeof OrdersActionTypes.FETCH_ORDER
  id: number
}

export interface FetchOrderSuccess extends AxiosMiddlewareActionSuccess<OrderResponse, FetchOrder> {
  type: typeof OrdersActionTypes.FETCH_ORDER_SUCCESS
}

export interface FetchOrderFail extends AxiosMiddlewareActionFail<FetchOrder> {
  type: typeof OrdersActionTypes.FETCH_ORDER_FAIL
}

export function fetchOrders(filter: OrderFilter = {}): FetchOrders {
  return {
    type: OrdersActionTypes.FETCH_ORDERS,
    payload: {
      request: {
        url: '/v1/orders/user/me',
        params: filter,
      },
    },
  }
}
export function fetchOrder(id: number, filter: OrderFilter = {}): FetchOrder {
  return {
    type: OrdersActionTypes.FETCH_ORDER,
    id,
    payload: {
      request: {
        url: `/v1/orders/order/${id}`,
        params: filter,
      },
    },
  }
}

export type OrdersActionType =
  | FetchOrdersStart
  | FetchOrdersSuccess
  | FetchOrdersFail
  | FetchOrder
  | FetchOrderSuccess
  | FetchOrderFail
  | FetchUserAggregatedOrdersSuccess
  | FetchClientOrdersSuccess
