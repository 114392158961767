import React from 'react'
import i18n from '../../i18n'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import UploadStyles from '../UploadsV3/styles'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import NavigationList from './NavigationList'
import { AutoLinkedinSections, Sections } from '../../store/autolinkedin/types'

export interface Props {
  sections: Sections[]
  greyOutEmpty: boolean
}
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '1.5em 1.5em 1em',
    },
    h6: {
      backgroundColor: styles.palette.darkerBlue.color.hex,
      borderRadius: '.19em .19em 0 0',
      color: 'white',
      fontWeight: 600,
      height: '3.7em',
      lineHeight: '3.7em',
      padding: '0 2em',
      textTransform: 'uppercase',
      width: '100%',
    },
    paper: {
      border: UploadStyles.autoQAResults.group.border,
      borderRadius: '.19em',
      boxShadow: UploadStyles.autoQAResults.group.boxShadow,
    },
  })
)

const backgroundList = Object.freeze(['workExperience', 'education', 'licenses', 'certifications'])

const order = Object.freeze({
  [AutoLinkedinSections.WorkExperience]: 1,
  [AutoLinkedinSections.Education]: 2,
  [AutoLinkedinSections.Certifications]: 3,
  [AutoLinkedinSections.Volunteering]: 4,
  [AutoLinkedinSections.Publications]: 5,
  [AutoLinkedinSections.Patents]: 6,
  [AutoLinkedinSections.Honors]: 7,
  [AutoLinkedinSections.Organizations]: 8,
  [AutoLinkedinSections.Languages]: 9,
})

const NavigationBar: React.FC<Props> = ({ sections, greyOutEmpty }) => {
  const classes = useStyles()

  const sortedSections = sections.sort((s1: Sections, s2: Sections) => (order[s1.section] > order[s2.section] ? 1 : -1))

  const backgroundSections = sortedSections.map(s => s).filter(s => backgroundList.includes(s.section))
  const accomplishmentsSections = sortedSections.map(s => s).filter(s => !backgroundList.includes(s.section))

  return (
    <Paper className={classes.paper} elevation={4}>
      <Typography className={classes.h6} variant="h6">
        {i18n.t('alink__nav__title')}
      </Typography>
      <Container className={classes.container}>
        <NavigationList name={'background'} sections={backgroundSections} greyOutEmpty={greyOutEmpty} />
        <NavigationList name={'accomplishments'} sections={accomplishmentsSections} greyOutEmpty={greyOutEmpty} />
      </Container>
    </Paper>
  )
}

export default NavigationBar
