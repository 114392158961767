import React from 'react'
import Box from '@material-ui/core/Box'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import styles from '../../styles'

interface Props {
  children?: React.ReactNode
  leftColumn?: React.ReactNode
  rightColumn?: React.ReactNode
}

const useStyles = makeStyles(() =>
  createStyles({
    footer: {
      zIndex: 1,
      position: 'fixed',
      left: 0,
      bottom: 0,
      display: 'flex',
      padding: '1em 0',
      boxShadow: '0px -3px 9px 0px rgba(0, 0, 0, 0.2)',
      width: '100%',
      backgroundColor: styles.palette.modalWrapper.backgroundColor,
    },
    innerWrapper: {
      display: 'flex',
      maxWidth: styles.sizes.modalWrapperMaxWidth,
      margin: '0 auto',
      width: '100%',
    },
    column: {
      display: 'flex',
      alignItems: 'center',
    },
    leftColumn: {},
    rightColumn: {
      marginLeft: 'auto',
    },
    '@media (max-width: 800px)': {
      innerWrapper: {
        flexDirection: 'column',
        paddingLeft: '3rem',
      },
      rightColumn: {
        marginLeft: 0,
      },
    },
  })
)

const Footer: React.FC<Props> = ({ children, leftColumn, rightColumn }) => {
  const classes = useStyles()

  return (
    <Box className={classes.footer} component="footer">
      <Box className={classes.innerWrapper}>
        {leftColumn && <Box className={classNames(classes.leftColumn, classes.column)}>{leftColumn}</Box>}
        {children && children}
        {rightColumn && <Box className={classNames(classes.rightColumn, classes.column)}>{rightColumn}</Box>}
      </Box>
    </Box>
  )
}

export default Footer
