import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { createStyles, makeStyles } from '@material-ui/core/styles'

interface Props {
  children: string | React.ReactNode | React.ReactNodeArray
  confirmChildren: string | React.ReactNode | React.ReactNodeArray
  confirmationTimeout?: number
  onClick: () => void
  phaseOneIcon?: string | React.ReactNode | React.ReactNodeArray
  phaseTwoIcon?: string | React.ReactNode | React.ReactNodeArray
}

const useStyles = makeStyles(() =>
  createStyles({
    phaseOneWithIcon: {
      '& span': {
        '& svg': {
          marginLeft: '-4px',
          marginRight: '8px',
        },
      },
    },
    phaseTwoWithIcon: {
      backgroundColor: '#ed3572 !important',
      border: 'solid 1px #ed3572 !important',
      color: 'white !important',

      '& span': {
        '& svg': {
          marginLeft: '-4px',
          marginRight: '8px',
        },
      },
    },
  })
)

const TwoPhaseButton: React.FC<Props> = ({
  children,
  confirmChildren,
  confirmationTimeout = 2000,
  onClick,
  phaseOneIcon,
  phaseTwoIcon,
}) => {
  const [isClicked, setIsClicked] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    let id: number | null = null

    function clearConfirmation() {
      setIsClicked(false)
    }

    if (isClicked) {
      id = window.setTimeout(clearConfirmation, confirmationTimeout)
    }

    return function cleanup() {
      if (id) {
        window.clearTimeout(id)
      }
    }
  }, [isClicked, setIsClicked, confirmationTimeout])

  if (isClicked) {
    if (phaseTwoIcon) {
      return (
        <Button
          className={classes.phaseTwoWithIcon}
          onClick={() => {
            onClick()
            setIsClicked(false)
          }}
          variant="contained"
          color="primary"
        >
          {phaseTwoIcon}
          {confirmChildren}
        </Button>
      )
    } else {
      return (
        <Button
          onClick={() => {
            onClick()
            setIsClicked(false)
          }}
          variant="contained"
          color="primary"
        >
          {confirmChildren}
        </Button>
      )
    }
  }

  if (phaseOneIcon) {
    return (
      <Button
        onClick={() => setIsClicked(true)}
        variant="contained"
        color="primary"
        className={classes.phaseOneWithIcon}
      >
        {phaseOneIcon}
        {children}
      </Button>
    )
  } else {
    return (
      <Button onClick={() => setIsClicked(true)} variant="contained" color="primary">
        {children}
      </Button>
    )
  }
}

export default TwoPhaseButton
