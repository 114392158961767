import { LoadedLoadingErrorState } from '../../utils/state'

export interface AutoLinkdeinState {
  byItemID: AutoLinkedinList
  revisions: AutoLinkedinRevisions
}

export interface AutoLinkedin {
  id: number
  user_id: number | null
  payment_product_id: number
  resume_file_id: number | null
  resume_image_urls?: string[] | undefined
  version: number
  created_at: string
  updated_at: string
  deleted_at: string
  data_json: AutoLinkedinJson
  meta: AutoLinkedinMeta | null
}

export interface AutoLinkedinRevision {
  id: number
  auto_linkedin_id: number
  file_id: number | null
  revision: number
  bucket: string
  key: string
  created_at: string
  updated_at: string
}

export interface AutoLinkedinUpdatePayload {
  meta?: AutoLinkedinMeta
  talentLinkedin?: Partial<AutoLinkedinJson>
}

export interface Language {
  language: string
  proficiency: string
}

export enum AutoLinkedinSections {
  WorkExperience = 'workExperience',
  Education = 'education',
  Certifications = 'certifications',
  Volunteering = 'volunteeringExperiences',
  Publications = 'publications',
  Patents = 'patents',
  Honors = 'honors',
  Organizations = 'organizations',
  Languages = 'languages',
}

export enum RelevantClientDataPoints {
  Objective = 'objective',
  Seniority = 'seniority',
  YearsOfExperience = 'yearsOfExperience',
  Skills = 'skills',
}

export interface ClientDataPoint {
  titleKey: string
  content: string
}

export type Sections =
  | WorkExperienceSection
  | EducationSection
  | PublicationsSection
  | OrganizationsSection
  | VolunteeringSection
  | PatentsSection
  | CertificationsSection
  | LanguagesSection
  | HonorsSection

export interface Section<T> {
  data: {
    // @ts-ignore
    [AutoLinkedinSections]: T
  }
  section: AutoLinkedinSections
  fieldArrayName: string
  recordCount: number
}

export type WorkExperienceSection = Section<WorkExperienceSectionShape>
export type EducationSection = Section<EducationSectionShape>
export type PublicationsSection = Section<Publication[]>
export type OrganizationsSection = Section<Organization[]>
export type PatentsSection = Section<Patent[]>
export type VolunteeringSection = Section<VolunteeringExperience[]>
export type CertificationsSection = Section<Certification[]>
export type LanguagesSection = Section<Language[]>
export type HonorsSection = Section<Honor[]>

export interface SummarySection {
  summary: Summary
}

export interface WorkExperienceSectionShape {
  institutions: EmploymentInstitution[]
}

export interface EducationSectionShape {
  institutions: EducationInstitution[]
}

export interface AutoLinkedinJson {
  [AutoLinkedinSections.WorkExperience]?: WorkExperienceSectionShape | undefined
  [AutoLinkedinSections.Education]?: EducationSectionShape | undefined
  [AutoLinkedinSections.Publications]?: Publication[] | undefined
  [AutoLinkedinSections.Organizations]?: Organization[] | undefined
  [AutoLinkedinSections.Patents]?: Patent[] | undefined
  [AutoLinkedinSections.Volunteering]?: VolunteeringExperience[] | undefined
  [AutoLinkedinSections.Certifications]?: Certification[] | undefined
  [AutoLinkedinSections.Languages]?: Language[] | undefined
  [AutoLinkedinSections.Honors]?: Honor[] | undefined
  summary?: Summary | undefined
  headline?: string | undefined
  personalInfo: {
    firstName: string
    lastName: string
    birthDate: string | null
    location: LILocation
    phoneNumbers: PhoneNumber[]
  }
  career: {
    industry: {
      name: string
    }
    yearsOfExperience?: number | undefined
    seniority?: string | undefined
    objective?: string | undefined
  }
  locale: string
  skills: TalentSkill[] | undefined
  goals: string | undefined
}

export interface TalentSkill {
  name: string
}
export interface PhoneNumber {
  number: string
  type: string
}

export interface Summary {
  text: string
}

export interface VolunteeringExperience {
  cause: string
  companyName: string
  description: string
  role: string
  timePeriod: TimePeriod
}

export interface Certification {
  authority: string
  timePeriod: TimePeriod
  licenseNumber: string
  name: string
  url: string
}

export interface Patent {
  title: string
  pending: boolean
  applicationNumber: string
  description: string
  issuers: PatentIssuer[]
  number: string
  inventors: TeamMember[]
  url: string
}

export interface PatentIssuer {
  issuer: string
  countryCode: string
  id: string
  status: string
  filingDate: DayMonthYear | null
  issueDate: DayMonthYear | null
}

export interface Organization {
  name: string
  position: string
  description: string
  timePeriod: TimePeriod
}

export interface Publication {
  title: string
  date: DayMonthYear | null
  publisher: string
  description: string
  url: string
  authors: TeamMember[]
}

export interface Honor {
  title: string
  issueDate: DayMonthYear | null
  issuer: string
  description: string
}

export interface TeamMember {
  name: string
  website: string
}

export interface EmploymentInstitution {
  title: string
  companyName: string
  description: string
  timePeriod: TimePeriod
  location: LILocation
}

export interface TimePeriod {
  startMonthYear: DayMonthYear | null
  endMonthYear: DayMonthYear | null
}

export interface DayMonthYear {
  day: number | null
  month: number | null
  year: number | null
}

export interface LILocation {
  freeForm: string
  address: StructuredAddress
}

export interface StructuredAddress {
  addressLines: string[]
  city: string
  postCode: string
  countryCode: string
  state: string
}
export interface FieldOfStudy {
  name: string
}

export interface EducationInstitution {
  activities: string
  degreeName: string
  timePeriod: TimePeriod
  fieldsOfStudy: FieldOfStudy[]
  grade: number
  notes: string
  schoolName: string
}

export interface AutoLinkedinMeta {
  progress: {
    section: AutoLinkedinSections
    record_index: number
  } | null
  parsed_work_experience_validated: boolean
}

export interface AutoLinkedinList {
  [itemID: number]: {
    generateMeta: LoadedLoadingErrorState
    getMeta: LoadedLoadingErrorState
    updateMeta: LoadedLoadingErrorState
    publishMeta: LoadedLoadingErrorState
    autoLinkedin?: AutoLinkedin
  }
}

export interface AutoLinkedinRevisions {
  [autoLinkID: number]: AutoLinkedinRevisionList
}

export interface AutoLinkedinRevisionList {
  [ID: number]: AutoLinkedinRevision
}
