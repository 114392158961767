import { LoadedLoadingErrorState, LoadingErrorState } from '../../utils/state'
import { DraftEventVars } from '../events/types'
import { OfferShoppingEligibility } from '../offers/types'
import { DirectSchedulingMeta } from '../currentMessage/types'

export enum MessageContexts {
  CLIENT = 'client',
  USER = 'user',
}
export type MessageContext = MessageContexts.CLIENT | MessageContexts.USER

export enum MessageStatuses {
  Read = 'read',
  Unread = 'unread',
  Pinned = 'pinned',
  ClientRead = 'client-read',
  TalentInc = 'talentinc',
}

export type MessageStatusType =
  | MessageStatuses.Read
  | MessageStatuses.Unread
  | MessageStatuses.Pinned
  | MessageStatuses.ClientRead
  | MessageStatuses.TalentInc

export interface MessageState {
  user: MessageList
  client: MessageList
  fetchingMeta: LoadedLoadingErrorState
  sendingMeta: MessageSendingMeta
  statusMeta: LoadingErrorState
  malwareMeta: ScanVirusResponse
  loggedInUserID: number | null
}

export interface MessageSendingMeta extends LoadingErrorState {
  draftEventMeta: DraftEventVars | null
  shoppingEligibility: OfferShoppingEligibility | null
}

export interface MessageList {
  [context_id: number]: MessageContextMap
}

export interface MessageContextMap {
  [id: number]: Message
}

export interface OutgoingMessage {
  sender: string
  recipients: string[]
  bcc_email: string | null
  subject: string
  // html
  body: string
  variables: Record<string, boolean | number>
  message_text: string
  attachment_ids: number[]
  reply_message_id: string | null
  statuses: MessageStatus[]
  omit_attachments_in_email?: boolean
  direct_scheduling?: DirectSchedulingMeta
}

// Message represents a sent message returned by the API
export interface Message extends Omit<OutgoingMessage, 'omit_attachments_in_email'> {
  id: number
  // This is the Shelley GUID
  message_id: string | null
  sent: string
  sent_by: number | null
  created_at: string
  updated_at: string
  outbound: boolean
  context: MessageContext
  context_id: number
}

export interface MessageSendResponse {
  message: Message
  event_data: DraftEventVars
  shopping_eligibility: OfferShoppingEligibility
}

export interface MessageStatusCreateRequest {
  status: MessageStatusType
  start_at?: string
  end_at?: string
}

export interface MessageStatus extends MessageStatusCreateRequest {
  id: number
  user_id: number
  created_at: string
  message_id: number
  updated_at: string
}

export interface ScanVirusRequest {
  text: string
}

export interface FoundViruses {
  file: string
  virus: string
}

export interface ScannerResponse {
  id: string
  clean_result: boolean
  website_http_response_code: number
  website_threat_type: string
  found_viruses: FoundViruses[]
}

export interface ScanVirusResponse {
  clean_result: boolean
  found_viruses: string[]
  found_urls: string[]
  scanner_response: ScannerResponse[]
}
