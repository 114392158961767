import { NavLinkProps } from 'react-router-dom'
import * as React from 'react'

import { GhostLink } from '../../utils/link'

const styles: React.CSSProperties = {
  textDecoration: 'none',
  height: '100%',
}

interface Props extends NavLinkProps {
  children: React.ReactNode
}

export const NavigationItem: React.FC<Props> = props => {
  const style = {
    ...props.style,
    ...styles,
  }

  return (
    <GhostLink {...props} style={style} exact to={props.to}>
      {props.children}
    </GhostLink>
  )
}

export default NavigationItem
