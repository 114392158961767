import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useState } from 'react'
import debounce from 'lodash/debounce'
import { setClientViewSearchTerm } from '../../store/clientOrderAggregate/actions'
import { AppState } from '../../store'

interface UseOrderSearch {
  localSearchTerm: string
  setLocalSearchTerm: (term: string) => void
  globalSearchTerm: string
  setGlobalSearchTerm: (term: string) => void
  setSearchTerm: (term: string) => void
}
const useOrderSearch = (): UseOrderSearch => {
  const dispatch = useDispatch()

  const globalSearchTerm = useSelector<AppState, string>(state => state.clientOrdersAggregateReducer.view.searchTerm)

  const [localSearchTerm, setLocalSearchTerm] = useState<string>(globalSearchTerm)

  const setGlobalSearchTerm = useCallback(
    debounce<(searchTerm: string) => void>(searchTerm => {
      dispatch(setClientViewSearchTerm(searchTerm))
    }),
    [dispatch]
  )

  const setSearchTerm = useCallback(
    (term: string) => {
      setLocalSearchTerm(term)
      setGlobalSearchTerm(term)
    },
    [setLocalSearchTerm, setGlobalSearchTerm]
  )

  return {
    localSearchTerm,
    setGlobalSearchTerm,
    globalSearchTerm,
    setLocalSearchTerm,
    setSearchTerm,
  }
}

export default useOrderSearch
