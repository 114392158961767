import { HashMap } from '../../utils/HashMap'
import { Critique, CritiqueCategories, StructuredCritiqueAnswer } from './types'

export const getCritiqueResults = (critique: Critique): HashMap<StructuredCritiqueAnswer[]> => {
  const answers: HashMap<StructuredCritiqueAnswer[]> = {}

  return Object.entries(CritiqueCategories).reduce(
    (acc: HashMap<StructuredCritiqueAnswer[]>, [category, keys]): HashMap<StructuredCritiqueAnswer[]> => {
      keys.forEach(key => {
        const critiqueAnswer = critique.answers[key]

        if (!!critiqueAnswer && critiqueAnswer.critique_bit) {
          const answer: StructuredCritiqueAnswer = {
            key,
            value: critiqueAnswer.critique_text,
          }
          acc[category] = [...(acc[category] || []), answer]
        }
      })

      return acc
    },
    answers
  )
}
