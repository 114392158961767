import { Client } from '../../../store/clients/reducer'

// Helper function that fetches the Onboarding Questionnaire/Account link
export default function(client: Client, path = '/'): string | null {
  // If the user is in the portal experiment, this URL will be not null and that we should link them there instead of
  // the onboarding delivery page.
  if (!client?.productsMetadata?.portal_url) {
    return null
  }

  // If we are in a non-production environment, append the ghost variable so that we can test the links easier.
  let ghost = ''

  if (window.location.host.includes('staging') || window.location.host.includes('dev')) {
    ghost = `?ghost=${client.productsMetadata.user_id}`
  }

  return client.productsMetadata.portal_url + path + ghost
}
