import React from 'react'
import classNames from 'classnames'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import i18n from '../../i18n'
import { findPlaceholderEntities, findHiddenLinkEntities } from '../../utils/draftjs'

const useStyles = makeStyles(() =>
  createStyles({
    placeholder: {
      color: 'red',
      fontWeight: 'bold',
    },
    hiddenLink: {
      color: 'blue',
    },
  })
)

export const Placeholder: React.FC = props => {
  const classes = useStyles(props)

  return (
    <span
      title={i18n.t('message__input__placeholderWarning')}
      className={classNames([classes.placeholder, 'placeholder-blocker'])}
    >
      {props.children}
    </span>
  )
}

export const HiddenLink: React.FC = props => {
  const classes = useStyles(props)

  return <span className={classes.hiddenLink}>{props.children}</span>
}

export const decorators = [
  {
    strategy: findPlaceholderEntities,
    component: Placeholder,
  },
  {
    strategy: findHiddenLinkEntities,
    component: HiddenLink,
  },
]
