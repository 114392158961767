import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { enGB, enUS } from 'date-fns/locale'
import differenceInDays from 'date-fns/differenceInDays'
import isEqual from 'lodash/isEqual'

import i18n from '../../i18n'
import IndustriesChart from './IndustriesChart'
import EarningsChart from './EarningsChart'
import { UserIndustryCount, PayHistory, YtdAndRelativeStats } from '../../store/dashboard/types'
import { PaymentHistoryCard } from './PaymentHistoryCard'
import { StatCard } from './StatCard'
import { Currency, formatCurrency, formatDate } from '../../utils/formatting'
import DashboardStyles from './Styles'
import { PaymentItemWithDate } from '../../store/user_preferences/reducers'

interface Props {
  userID: number
  isLoaded: boolean
  industriesWrittenFor: UserIndustryCount[] | null
  paymentHistory: PayHistory[]
  nextPay: PaymentItemWithDate | null
  userTimeZone: string
  currency: Currency
  payStats: YtdAndRelativeStats | null
}

const useStyles = makeStyles(theme =>
  createStyles({
    column: {
      margin: DashboardStyles.row.paddingInner,
      maxHeight: DashboardStyles.historyHeight,
      maxWidth: DashboardStyles.halfWidth,
    },
    container: {
      backgroundColor: DashboardStyles.card.bg,
      border: DashboardStyles.card.border,
      boxShadow: DashboardStyles.card.shadow,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      padding: DashboardStyles.card.padding,
      width: DashboardStyles.fullWidth,
    },
    header: {
      color: DashboardStyles.header.color,
      fontSize: DashboardStyles.header.size,
      margin: 0,
      width: DashboardStyles.header.width,
    },
    parentContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    statBox: {
      backgroundColor: DashboardStyles.card.bg,
      border: DashboardStyles.card.border,
      boxShadow: DashboardStyles.card.shadow,
      justifyContent: 'space-between',
      margin: DashboardStyles.card.marginMobile,
      padding: DashboardStyles.card.padding,
    },
    [theme.breakpoints.down('sm')]: {
      column: {
        height: 'initial',
        margin: DashboardStyles.card.marginMobile,
        maxHeight: 'initial',
        maxWidth: DashboardStyles.fullWidth,

        '&:nth-of-type(2)': {
          height: DashboardStyles.historyHeight,
        },
      },
      parentContainer: {
        flexDirection: 'column',
      },
      statBox: {
        flexDirection: 'column',
      },
    },
  })
)

const FinancialActivity: React.FC<Props> = ({
  userID,
  isLoaded,
  industriesWrittenFor,
  paymentHistory,
  nextPay,
  userTimeZone,
  currency,
  payStats,
}) => {
  const classes = useStyles()
  if (!isLoaded) {
    return (
      <Box>
        <Typography>Loading...</Typography>
      </Box>
    )
  }

  const locale = userTimeZone.includes('Europe') ? enGB : enUS

  let lastPayAmt = formatCurrency(0, currency)
  let lastPayDate = '-'

  if (paymentHistory.length > 0) {
    lastPayAmt = formatCurrency(paymentHistory[0].amount, currency)
    lastPayDate = formatDate(
      paymentHistory[0].pay_date,
      userTimeZone,
      locale,
      i18n.t('account__payment__payment_date_format')
    )
  }

  const payDayText = (daysTill: number) => {
    switch (daysTill) {
      case 1: {
        return i18n.t('dashboard__paycard__nextpaydateTomorrow')
      }
      case 0: {
        return i18n.t('dashboard__paycard__nextpaydateToday')
      }
      default: {
        return i18n.t('dashboard__paycard__nextpaydate', {
          daysTill: !!nextPay ? daysTill : '-',
          payDate: !!nextPay
            ? formatDate(nextPay.date, userTimeZone, locale, i18n.t('account__payment__payment_date_format'))
            : '-',
        })
      }
    }
  }

  return (
    <div>
      <Box className={classes.parentContainer} display="flex" flexWrap="wrap" flexDirection="row">
        <Typography className={classes.header} variant="h3">
          Financial Activity
        </Typography>
        <Box className={classes.column} flexGrow={1} display="flex" flexDirection="column">
          <Box className={classes.statBox} display="flex" flexDirection="row" id="dashboard-payment-stat-cards">
            <StatCard
              title={i18n.t('dashboard__paycard__next')}
              primary={!!nextPay ? formatCurrency(nextPay.amount, nextPay.currency) : formatCurrency(0, currency)}
              subtitle={!!nextPay ? payDayText(differenceInDays(new Date(nextPay.date), new Date())) : '-'}
            />
            <StatCard title={i18n.t('dashboard__paycard__last')} primary={lastPayAmt} subtitle={lastPayDate} />
          </Box>
          <IndustriesChart industries={industriesWrittenFor} />
        </Box>
        <Box className={classes.column} flexGrow={2}>
          <PaymentHistoryCard
            userID={userID}
            paymentRows={paymentHistory}
            userTimezone={userTimeZone}
            currency={currency}
          />
        </Box>
      </Box>

      <Box className={classes.container} id="dashboard-earnings-chart">
        <EarningsChart paymentHistory={paymentHistory} payStats={payStats} currency={currency} />
      </Box>
    </div>
  )
}

const memoizedFinancialHistory = React.memo(FinancialActivity, isEqual)
// @ts-ignore
memoizedFinancialHistory.whyDidYouRender = true

export default memoizedFinancialHistory
