import { NotificationsState } from './types'
import { NotificationActionType, NotificationsActionTypes } from './actions'
import produce from 'immer'
import * as stateUtils from '../../utils/state'
import { setErrorState, setErrorStateForItem } from '../../utils/state'

const initialState: NotificationsState = {
  notifications: {},
  meta: { ...stateUtils.initialMultiLoadedLoadingErrorState },
}

export default function notificationReducer(state: NotificationsState = initialState, action: NotificationActionType) {
  return produce(state, (draft: NotificationsState) => {
    switch (action.type) {
      case NotificationsActionTypes.FETCH_NOTIFICATIONS:
        draft.meta.global = stateUtils.setStartState(state.meta.global)
        break
      case NotificationsActionTypes.FETCH_NOTIFICATIONS_SUCCESS:
      case NotificationsActionTypes.READ_ALL_NOTIFICATIONS_SUCCESS:
        draft.meta.global = stateUtils.setSuccessState(state.meta.global)
        const notifications = action.payload.data
        notifications && notifications.forEach(notification => (draft.notifications[notification.id] = notification))
        break
      case NotificationsActionTypes.FETCH_NOTIFICATIONS_FAIL:
        draft.meta.global = stateUtils.setErrorState(state.meta.global, action.error)
        break
      case NotificationsActionTypes.FETCH_NOTIFICATION:
        draft.meta = stateUtils.setStartStateForItem(action.id, state.meta)
        break
      case NotificationsActionTypes.FETCH_NOTIFICATION_SUCCESS: {
        const notificationID = action.meta.previousAction.id
        const notification = action.payload.data
        draft.meta = stateUtils.setSuccessStateForItem(notificationID, state.meta)
        draft.notifications[notificationID] = notification
        break
      }
      case NotificationsActionTypes.FETCH_NOTIFICATION_FAIL: {
        const notificationID = action.meta.previousAction.id
        draft.meta = stateUtils.setErrorStateForItem(notificationID, state.meta, action.error)
        break
      }
      case NotificationsActionTypes.PATCH_NOTIFICATION:
        draft.meta = stateUtils.setStartStateForItem(action.id, state.meta)
        break
      case NotificationsActionTypes.PATCH_NOTIFICATION_SUCCESS: {
        const result = action.payload.data
        const notificationID = action.meta.previousAction.id
        draft.meta = stateUtils.setSuccessStateForItem(notificationID, state.meta)
        draft.notifications[notificationID] = result
        break
      }
      case NotificationsActionTypes.PATCH_NOTIFICATION_FAIL: {
        const notificationID = action.meta.previousAction.id
        draft.meta = setErrorStateForItem(notificationID, state.meta, action.error)
        break
      }
      case NotificationsActionTypes.READ_ALL_NOTIFICATIONS:
        draft.meta.global.isLoading = true
        break
      case NotificationsActionTypes.READ_ALL_NOTIFICATIONS_FAIL:
        draft.meta.global = setErrorState(draft.meta.global, action.error)
        break
      case NotificationsActionTypes.ADD_UI_NOTIFICATION:
        draft.uiNotification = action.notification
        break
      case NotificationsActionTypes.REMOVE_UI_NOTIFICATION:
        delete draft.uiNotification
        break
    }
  })
}
