import { Groups, TagsZipJobNewWriter, UserInfo, UserState } from '../store/user/reducers'
import { createSelector } from 'reselect'
import { UserPreferences, UserPreferencesState } from '../store/user_preferences/reducers'

export const shouldSeeBasePay = (user?: UserInfo | null) => {
  if (!user) return true
  return !user.groups.includes(Groups.Offshore)
}

export const loggedInUserSelector = (state: UserState) => state.getLoggedInUser()

export const isAdminSelector = createSelector(
  loggedInUserSelector,
  loggedInUser =>
    !!loggedInUser && (loggedInUser.groups.includes(Groups.Admin) || loggedInUser.groups.includes(Groups.FCC_Admin))
)

export const isOffshoreSelector = createSelector(
  loggedInUserSelector,
  loggedInUser => !!loggedInUser && loggedInUser.groups.includes(Groups.Offshore)
)

export const completedOnboardingSelector = createSelector(
  loggedInUserSelector,
  loggedInUser => !!loggedInUser && !!loggedInUser.completed_onboarding
)

export const isOnboardingMenteeSelector = createSelector(
  loggedInUserSelector,
  loggedInUser => !!loggedInUser && loggedInUser.tag_keys.some(key => key === 'onboarding-mentee')
)

export const isNewZipJobWriter = createSelector(
  loggedInUserSelector,
  loggedInUser => !!loggedInUser && loggedInUser.tag_keys.some(key => key === TagsZipJobNewWriter)
)

export const userPreferencesSelector = (state: UserPreferencesState) => state.getLoggedInUserPreferences()

export const userAssignmentPreferences = createSelector(
  userPreferencesSelector,
  (preferences: UserPreferences | null) => {
    return !!preferences ? preferences.assignment : null
  }
)
export const userPreferencesLoaded = (state: UserPreferencesState, userID: number) => {
  try {
    return !!state.users[userID].payment
  } catch (e) {
    return false
  }
}

export const isRezwizElgible = createSelector(
  loggedInUserSelector,
  loggedInUser => !!loggedInUser && !loggedInUser.tag_keys.includes('no-rezwiz')
)
