import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import keyBy from 'lodash/keyBy'

import { AppState } from '../../store'
import { loggedInUserSelector } from '../../selectors/users'
import { UserInfo } from '../../store/user/reducers'
import { HandlebarsContextType } from '../../utils/handlebars'
import { handlebarsTemplateContextSelector, snippetsWithContextSelector } from '../../selectors/templates'
import { MessageContext } from '../../store/messages/types'
import { Snippet, SnippetFilters, SnippetList } from '../../store/templates/types'
import { fetchSnippets } from '../../store/templates/actions'
import { Language } from '../../utils/consts'
import { sendUserEvent } from '../../store/events/actions'
import Strategy from './Strategy'
import useEditorState from './useEditorState'
import { EventTypes } from '../../store/events/types'
import { Upsell } from '../../store/clients/reducer'
import { getUpsells } from '../../selectors/clients'

interface StrategyContainerProps {
  context: MessageContext
  contextID: number
  orderID: number | null
  isHighlighted?: boolean
}

export const StrategyContainer: React.FC<StrategyContainerProps> = ({
  context,
  contextID,
  orderID,
  isHighlighted = false,
}) => {
  const dispatch = useDispatch()
  const { insertSnippetAtCursor } = useEditorState()
  const user = useSelector<AppState, UserInfo | null>(state => loggedInUserSelector(state.userReducer))
  const handlebarsContext = useSelector<AppState, HandlebarsContextType>(state =>
    handlebarsTemplateContextSelector(state, context, contextID, user ? user.id : null, [], orderID)
  )
  let language: Language = 'en'
  let userID = -1

  if (!!handlebarsContext.order && !!handlebarsContext.order.language) {
    language = handlebarsContext.order.language
  }

  if (user) {
    userID = user.id
  }

  const [snippets, snippetsAreLoading] = useSelector<AppState, [SnippetList, boolean]>(state =>
    snippetsWithContextSelector(state.templatesReducer, {
      language,
      context,
      contextID,
      clientState: state.clientReducer,
      itemState: state.itemsReducer,
    })
  )

  const upsells = useSelector<AppState, Upsell[]>(state => getUpsells(state.clientReducer, contextID))

  const filteredSnippets = useMemo(
    () =>
      keyBy(
        Object.values(snippets)?.filter(item => {
          if ((item?.type as string) !== 'Upsell Email') {
            return true
          }
          if (upsells?.some(upsell => upsell.name === item.name && upsell.upsell_eligible)) {
            return true
          }
          return false
        }),
        'id'
      ),
    [snippets, upsells]
  )

  const handleInsertSnippet = React.useCallback(
    (snippet: Snippet) => {
      insertSnippetAtCursor(snippet, { ...handlebarsContext, snippetName: snippet.name })

      // Log that the user used a snippet
      dispatch(
        sendUserEvent({
          event: EventTypes.MessageSnippetInserted,
          variables: {
            snippet_id: snippet.id,
            client_id: handlebarsContext.client ? handlebarsContext.client.id : null,
          },
        })
      )
    },
    [handlebarsContext, insertSnippetAtCursor, dispatch]
  )

  React.useEffect(() => {
    if (!Object.keys(snippets).length && !snippetsAreLoading) {
      const snippetFilters: SnippetFilters = { context, language }

      if (userID > 0) {
        snippetFilters.user_id = userID
      }

      dispatch(fetchSnippets(snippetFilters))
    }
  }, [dispatch, userID, context, language, snippets, snippetsAreLoading])

  return (
    <Strategy
      snippets={filteredSnippets}
      insertSnippet={handleInsertSnippet}
      handlebarsContext={handlebarsContext}
      isHighlighted={isHighlighted}
    />
  )
}

function propsAreEqual(prev: StrategyContainerProps, next: StrategyContainerProps) {
  return prev.context === next.context && prev.contextID === next.contextID && prev.orderID === next.orderID
}

const memoizedStrategyContainer = React.memo(StrategyContainer, propsAreEqual)
// @ts-ignore
memoizedStrategyContainer.whyDidYouRender = true

export default memoizedStrategyContainer
