import React from 'react'
import Modal from '@material-ui/core/Modal'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
// @ts-ignore
import Frame from 'react-frame-component'

import { CurrentMessage } from '../../store/currentMessage/types'
import { HandlebarsContextType, renderEmail } from '../../utils/handlebars'
import useUserInfoState from '../common/useUserInfo'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      top: '5%',
      left: '25%',
      width: '800px',
    },
    preview: {
      width: '100%',
      height: '100%',
      border: 0,
    },
  })
)

interface MessagePreviewProps {
  message: CurrentMessage
  handlebarsContext: HandlebarsContextType
  closeWindow: () => void
}

export const MessagePreview: React.FC<MessagePreviewProps> = ({ message, handlebarsContext, closeWindow }) => {
  const classes = useStyles()
  const [messageBody, setMessageBody] = React.useState('')
  const { isAdmin } = useUserInfoState()

  React.useEffect(() => {
    async function renderMessageBody() {
      setMessageBody(await renderEmail(handlebarsContext, message, { disableLinks: !isAdmin }))
    }

    renderMessageBody()
  }, [message, handlebarsContext, isAdmin])

  // The HTML email must be inserted into an iframe so that styles from the application do not leak in.
  return (
    <Modal open={true} onClose={() => closeWindow()} className={classes.modal} style={{ top: '8%', left: '25%' }}>
      <Frame children={<div dangerouslySetInnerHTML={{ __html: messageBody }} />} className={classes.preview} />
    </Modal>
  )
}

export default React.memo(MessagePreview)
