import React, { useMemo } from 'react'
import { Page, Text, View, Document, StyleSheet, Image, Svg, Line } from '@react-pdf/renderer'

import SuggestionStar from '../../../assets/suggestionStar.png'
import Briefcase from '../../../assets/briefcase.png'
import TiLogo from '../../../assets/ti-logo.png'
import * as questionnaireSelectors from '../../../selectors/questionnaire'
import { Client } from '../../../store/clients/reducer'
import { HashMap } from '../../../utils/HashMap'
import { ClientSummaryData, WorkHistory as IWorkHistory } from '../../../store/autoresearch/types'
import { Questionnaire } from '../../../store/orders/questionnaire/types'
import { Order } from '../../../store/orders/types'
import i18n from '../../../i18n'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: '40px',
  },
  pageHeading: {
    fontSize: '21px',
    marginBottom: '16px',
    fontFamily: 'Helvetica-Bold',
  },
  detailCardContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  detailCardBox: {
    width: '40%',
  },
  detailCardLabelText: {
    fontSize: '12px',
    marginBottom: '6px',
    color: '#828BA2',
  },
  detailCardText: {
    marginBottom: '10px',
    fontSize: '12px',
  },
  jobPositions: {
    fontSize: '12px',
    color: 'grey',
    marginTop: '6px',
    marginLeft: '8px',
  },
  briefcaseImage: {
    width: '16px',
    height: '16px',
    marginTop: '4px',
  },
  image: {
    width: '14px',
    height: '12px',
    fontSize: '12px',
    marginLeft: '4px',
    marginTop: '10px',
    marginRight: '6px',
  },
  imageSkill: {
    width: '14px',
    height: '12px',
    marginRight: '8px',
    marginTop: '6px',
  },
  tiLogo: {
    width: '14px',
    fontSize: '12px',
    marginLeft: '4px',
    marginTop: '10px',
    marginRight: '6px',
  },
  clientText: {
    margin: '10px 0',
    fontSize: '14px',
    fontFamily: 'Helvetica-Bold',
  },
  clientInfoDetailText: {
    fontSize: '14px',
    marginVertical: '8px',
    color: 'grey',
  },
  listItem: {
    fontSize: '12px',
    marginLeft: '4px',
  },
  listItemText: {
    marginHorizontal: 6,
    fontSize: '12px',
  },
  summaryTextLabel: {
    marginTop: '10px',
    marginBottom: '5px',
    fontSize: '14px',
    color: 'grey',
  },
  suggestedData: {
    fontSize: '14px',
    fontFamily: 'Helvetica-Bold',
    marginVertical: '4px',
  },
  skillImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '12px',
  },
  text: {
    fontSize: '12px',
  },
  skillText: {
    fontSize: '12px',
    marginTop: '8px',
    marginBottom: '24px',
  },
  pageNumber: {
    fontSize: '12px',
    marginTop: '4px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    marginTop: '20px',
    left: 30,
    bottom: 10,
    right: 30,
  },
  primaryConcernsHeading: {
    fontSize: '16px',
    fontFamily: 'Helvetica-Bold',
    marginBottom: '24px',
  },
  primaryConcernQus: {
    fontSize: '12px',
    fontFamily: 'Helvetica-Bold',
    marginBottom: '10px',
  },
  primaryConcernAns: {
    fontSize: '12px',
    marginBottom: '24px',
  },
})

interface DocumentProps {
  clientData: Client
  workHistoryData: HashMap<IWorkHistory>
  quest: Questionnaire | undefined
  order: Order
  summaryData: ClientSummaryData | undefined | null
}

const MyDocument = ({ clientData, workHistoryData, quest, order, summaryData }: DocumentProps) => {
  const { full_name, primary_email } = clientData
  const industry = questionnaireSelectors.getIndustry(quest)
  const address = questionnaireSelectors.getAddress(quest)
  const phoneNumbers = questionnaireSelectors.getPhoneNumbers(quest)
  const linkedinURL = questionnaireSelectors.getLinkedInURL(quest)
  const country = questionnaireSelectors.getCountry(quest)
  const concerns = questionnaireSelectors.getConcerns(quest)
  const brandName = order.brand.name

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const sortedWorkHistory = useMemo(
    () =>
      Object.values(workHistoryData).sort((a, b) => {
        const aEndDate = Number(a?.end_date ? new Date(a.end_date).getTime() : null)
        const bEndDate = Number(b?.end_date ? new Date(b.end_date).getTime() : null)
        const aStartDate = Number(a?.start_date ? new Date(a.start_date).getTime() : null)
        const bStartDate = Number(b?.start_date ? new Date(b.start_date).getTime() : null)
        return aEndDate !== bEndDate ? bEndDate - aEndDate : bStartDate - aStartDate
      }),
    [workHistoryData]
  )

  const getDate = (start: string, end = '') => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const jobDuration = {
      jobStartYear: Number.isNaN(startDate.getFullYear()) ? '' : startDate.getFullYear(),
      jobStartMonth: Number.isNaN(startDate.getMonth()) ? '' : months[startDate.getMonth()],
      jobEndYear: Number.isNaN(endDate.getFullYear()) ? '' : endDate.getFullYear(),
      jobEndMonth: Number.isNaN(endDate.getMonth()) ? '' : months[endDate.getMonth()],
    }
    return jobDuration
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.pageHeading}>{i18n.t(`order_page_download_client_pdf_client_overview`)}</Text>
        <View>
          <View style={styles.detailCardContainer}>
            <View style={styles.detailCardBox}>
              {full_name ? (
                <View>
                  <Text style={styles.detailCardLabelText}>{i18n.t(`order__page__client__details__name`)}:</Text>
                  <Text style={styles.detailCardText}>{full_name}</Text>
                </View>
              ) : null}

              {primary_email ? (
                <View>
                  <Text style={styles.detailCardLabelText}>{i18n.t(`order__page__client__details__email`)}:</Text>
                  <Text style={styles.detailCardText}>{primary_email}</Text>
                </View>
              ) : null}

              {address ? (
                <View>
                  <Text style={styles.detailCardLabelText}>{i18n.t(`order__page__client__details__address`)}:</Text>
                  <Text style={styles.detailCardText}>{address}</Text>
                </View>
              ) : null}

              {linkedinURL ? (
                <View>
                  <Text style={styles.detailCardLabelText}>{i18n.t(`order_page_download_client_pdf_linkedIn`)}:</Text>
                  <Text style={styles.detailCardText}>{linkedinURL}</Text>
                </View>
              ) : null}
            </View>

            <View style={styles.detailCardBox}>
              {country ? (
                <View>
                  <Text style={styles.detailCardLabelText}>{i18n.t(`order__page__client__details__country`)}:</Text>
                  <Text style={styles.detailCardText}>{country}</Text>
                </View>
              ) : null}

              {phoneNumbers ? (
                <View>
                  <Text style={styles.detailCardLabelText}>{i18n.t(`order__page__client__details__phone`)}:</Text>
                  <Text style={styles.detailCardText}>
                    {phoneNumbers
                      ?.filter(pn => !!pn.number)
                      .map(pn => `${i18n.t(`order__page__client__details__phone__type__${pn.type}`)}: ${pn.number}`)
                      .join(', ')}
                  </Text>
                </View>
              ) : null}

              {industry ? (
                <View>
                  <Text style={styles.detailCardLabelText}>{i18n.t(`order__page__client__details__industry`)}:</Text>
                  <Text style={styles.detailCardText}>{industry}</Text>
                </View>
              ) : null}

              {brandName ? (
                <View>
                  <Text style={styles.detailCardLabelText}>{i18n.t(`order__page__client__details__source`)}:</Text>
                  <Text style={styles.detailCardText}>{brandName}</Text>
                </View>
              ) : null}
            </View>
          </View>
          {concerns?.length ? (
            <View>
              <Svg height="10" width="520" style={{ margin: '14px 0 24px' }}>
                <Line x1="0" y1="5" x2="520" y2="5" strokeWidth={2} stroke="#d7dbe1" />
              </Svg>
              <Text style={styles.primaryConcernsHeading}>
                {i18n.t('order__page__client__details__primary__concerns')}
              </Text>
              {concerns.map((concern, index) => {
                return (
                  <View key={index}>
                    <Text style={styles.primaryConcernQus}>{concern.question}</Text>
                    {concern.answer.map(answer => (
                      <Text style={styles.primaryConcernAns}>{answer}</Text>
                    ))}
                  </View>
                )
              })}
            </View>
          ) : null}
        </View>

        <Svg height="10" width="520" style={{ margin: '14px 0 24px' }}>
          <Line x1="0" y1="5" x2="520" y2="5" strokeWidth={2} stroke="rgb(13, 161, 236)" />
        </Svg>

        {sortedWorkHistory?.length ? (
          <View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={[styles.pageHeading, { marginRight: '14px' }]}>
                {i18n.t(`order__page__tab__title__work_history`)}
              </Text>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <Image style={styles.briefcaseImage} src={Briefcase} />
                <Text style={styles.jobPositions}>
                  {sortedWorkHistory.length} {i18n.t(`order__page__work_history__positions`)}
                </Text>
              </View>
            </View>
            {sortedWorkHistory.map(item => (
              <>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                >
                  <Text
                    style={{
                      fontSize: '16px',
                      fontFamily: 'Helvetica-Bold',
                      width: '65%',
                    }}
                  >
                    {item?.title}
                  </Text>
                  <Text style={[styles.text, { marginTop: '4x' }]}>
                    {`${getDate(item?.start_date, item?.end_date).jobStartMonth} ${
                      getDate(item?.start_date, item?.end_date).jobStartYear
                    } - ${getDate(item?.start_date, item?.end_date).jobEndMonth} ${getDate(
                      item?.start_date,
                      item?.end_date
                    ).jobEndYear || 'Present'}`}
                  </Text>
                </View>
                {item?.org_name ? <Text style={[styles.text, { marginBottom: '8px' }]}>{item?.org_name}</Text> : null}
                {item?.auto_research_skill_suggestions?.soft_skills ||
                item?.auto_research_skill_suggestions?.hard_skills ? (
                  <View>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <Image style={styles.image} src={SuggestionStar} />
                      <Text
                        style={{
                          fontSize: '14px',
                          fontFamily: 'Helvetica-Bold',
                          marginTop: '8px',
                        }}
                      >
                        {i18n.t(`order__page__work_history__suggested_data`)}:
                      </Text>
                    </View>
                    <View
                      style={{
                        margin: '12px 0 16px',
                      }}
                    >
                      {item?.auto_research_skill_suggestions?.soft_skills ? (
                        <Text
                          style={{
                            fontSize: '12px',
                            marginBottom: '8px',
                          }}
                        >
                          <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                            {i18n.t(`order__page__work_history__soft_skills`)}:
                          </Text>
                          &nbsp;
                          {item?.auto_research_skill_suggestions?.soft_skills?.join?.(', ')}
                        </Text>
                      ) : null}
                      {item?.auto_research_skill_suggestions?.hard_skills ? (
                        <Text
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                            {i18n.t(`order__page__work_history__hard_skills`)}:
                          </Text>
                          &nbsp;
                          {item?.auto_research_skill_suggestions?.hard_skills?.join?.(', ')}
                        </Text>
                      ) : null}
                    </View>
                  </View>
                ) : null}

                {item.proudest_achievements?.length ? (
                  <View style={{ marginVertical: '16px 4px' }}>
                    <Text
                      style={{
                        fontSize: '14px',
                        marginVertical: '8px',
                        fontFamily: 'Helvetica-Bold',
                      }}
                    >
                      {i18n.t(`order__page__work_history__onboarding_questionnaire`)}
                    </Text>
                    {item.proudest_achievements.map(item => (
                      <View style={{ flexDirection: 'row', marginVertical: '4px' }}>
                        <Text style={styles.listItem}>•</Text>
                        <Text style={styles.listItemText}>{item}</Text>
                      </View>
                    ))}
                  </View>
                ) : null}

                {item.auto_research_suggestions.length ? (
                  <View>
                    <Text
                      style={{
                        fontSize: '12px',
                        marginVertical: '8px',
                        fontFamily: 'Helvetica-Bold',
                      }}
                    >
                      {i18n.t(`order_page_download_client_pdf_suggested_job_recommendations`)}:
                    </Text>
                    {item.auto_research_suggestions.map(item => (
                      <View style={{ flexDirection: 'row', marginBottom: '8px' }}>
                        <Text style={styles.listItem}>•</Text>
                        <Text style={styles.listItemText}>{item}</Text>
                      </View>
                    ))}
                  </View>
                ) : null}

                {item.description ? (
                  <View>
                    <Text style={{ marginVertical: '12px', fontSize: '14px', fontFamily: 'Helvetica-Bold' }}>
                      {i18n.t(`order__page__work_history__client_provided_info`)}:
                    </Text>
                    {item.description.split('\n').map(item => (
                      <Text style={[styles.text, { marginBottom: '8px' }]}>{item}</Text>
                    ))}
                  </View>
                ) : null}

                <Svg height="10" width="520" style={{ margin: '24px 0' }}>
                  <Line x1="0" y1="5" x2="520" y2="5" strokeWidth={2} stroke="rgb(13, 161, 236)" />
                </Svg>
              </>
            ))}
          </View>
        ) : null}

        {summaryData?.suggested_summary_sentences?.length || summaryData?.client_supplied_summary ? (
          <View>
            <View>
              <Text style={styles.pageHeading}>{i18n.t(`order__page__tab__title__summary`)}</Text>
              {summaryData?.suggested_summary_sentences?.length ? (
                <View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: '12px',
                    }}
                  >
                    <Image style={[styles.image, { marginTop: '4px' }]} src={SuggestionStar} />
                    <Text style={styles.suggestedData}>{i18n.t(`order__page__summaryTab__suggested__data`)}:</Text>
                  </View>
                  {summaryData?.suggested_summary_sentences?.map?.(item => (
                    <View style={{ flexDirection: 'row', marginBottom: '4px' }}>
                      <Text style={styles.listItem}>•</Text>
                      <Text style={styles.listItemText}>{item}</Text>
                    </View>
                  ))}
                </View>
              ) : null}

              {summaryData?.client_supplied_summary ? (
                <View>
                  <Text style={styles.clientText}>{i18n.t(`order__page__summaryTab__supplied__by__client`)}:</Text>
                  <View>
                    <Text style={styles.text}>{summaryData?.client_supplied_summary}</Text>
                  </View>
                </View>
              ) : null}
            </View>
            <Svg height="10" width="520" style={{ margin: '24px 0' }}>
              <Line x1="0" y1="5" x2="520" y2="5" strokeWidth={2} stroke="rgb(13, 161, 236)" />
            </Svg>
          </View>
        ) : null}

        {summaryData?.suggested_skills?.soft_skills || summaryData?.suggested_skills?.hard_skills ? (
          <View>
            <Text style={styles.pageHeading}>{i18n.t(`order__page__summaryTab__skill__and__keywords`)}</Text>
            <View>
              <View style={styles.skillImageContainer}>
                <Image style={styles.imageSkill} src={SuggestionStar} />
                <Text style={styles.suggestedData}>{i18n.t(`order__page__summaryTab__suggested__data`)}:</Text>
              </View>
              {summaryData?.suggested_skills?.soft_skills ? (
                <View>
                  <Text style={{ fontSize: '12px', fontFamily: 'Helvetica-Bold' }}>
                    {i18n.t(`order__page__summaryTab__soft_skills`)}
                  </Text>
                  <Text style={styles.skillText}>{summaryData?.suggested_skills?.soft_skills?.join?.(', ')}</Text>
                </View>
              ) : null}
              {summaryData?.suggested_skills?.hard_skills ? (
                <View>
                  <Text style={{ fontSize: '12px', fontFamily: 'Helvetica-Bold' }}>
                    {i18n.t(`order__page__summaryTab__hard_skills`)}
                  </Text>
                  <Text style={styles.skillText}>{summaryData?.suggested_skills?.hard_skills?.join?.(', ')}</Text>
                </View>
              ) : null}
            </View>

            {summaryData?.client_supplied_skills ? (
              <View>
                <Text style={styles.suggestedData}>{i18n.t(`order__page__summaryTab__supplied__by__client`)}: </Text>
                <Text style={styles.skillText}>{summaryData?.client_supplied_skills?.join?.(', ')}</Text>
              </View>
            ) : null}
          </View>
        ) : null}

        <View fixed style={styles.footer}>
          <Image style={styles.tiLogo} src={TiLogo} fixed />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages} `}
            fixed
          />
        </View>
      </Page>
    </Document>
  )
}

export default MyDocument
