import React from 'react'
import Select, { SelectProps } from '@material-ui/core/Select'
import clsx from 'clsx'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'

interface Props {
  initValue: number | null
  onValueChange: (newDate: number | null) => void
  readonly: boolean
  inputClass: string
  inputGroupClass: string
  selectClass: string
  renderValue: (value: SelectProps['value']) => React.ReactNode
}

const useStyles = makeStyles(() =>
  createStyles({
    menu: {
      backgroundColor: styles.palette.inputGray.color,
    },
    select: {
      backgroundColor: styles.palette.inputGray.color,
      padding: '0 .63em',
    },
    selectElement: {
      paddingBottom: '0',
      paddingTop: '0',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  })
)

const DatePartSelect: React.FC<Props> = ({
  inputClass,
  inputGroupClass,
  selectClass,
  initValue,
  onValueChange,
  readonly,
  renderValue,
  children,
}) => {
  const classes = useStyles()
  const [value, setValue] = React.useState<number | null>(initValue)

  return (
    <Select
      classes={{ select: classes.selectElement }}
      className={clsx(classes.select, selectClass, inputClass, inputGroupClass)}
      MenuProps={{ classes: { paper: classes.menu } }}
      onChange={e => {
        setValue(e.target.value as number | null)
        onValueChange(e.target.value as number | null)
      }}
      inputProps={{ readOnly: readonly }}
      value={value}
      renderValue={renderValue}
    >
      {children}
    </Select>
  )
}

export default DatePartSelect
