import React, { FC, useCallback, useState } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import NotificationItem from './NotificationItem'
import i18n from '../../i18n'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store'
import { unReadNotificationsCount, userNotifications } from '../../selectors/notifications'
import { Notification } from '../../store/notifications/types'
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined'
import TopMenuItem from '../TopMenu/TopMenuItem'
import { markAllNotificationsAsRead } from '../../store/notifications/actions'
import { userId } from '../../store/user/reducers'
import Badge from '@material-ui/core/Badge'
import classNames from 'classnames'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fill: '#CACFD8',
    },
    iconActive: {
      composes: '$icon',
      fill: '#000',
    },
    topBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '0.5rem 0.4375rem 0.5rem 1rem',
      color: '#fff',
      background: '#f74527',
    },
    notifications: {
      position: 'relative',
      '@media (max-width:540px)': {
        '& div': {
          borderRight: 'none !important',
        },
      },
    },
    notificationsContainer: {
      position: 'absolute',
      width: '29.25rem',
      top: '3.5rem',
      right: '0.5rem',
      background: '#fafafa',
      marginTop: '1rem',
      zIndex: 1000,
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, .5)',
      '@media (max-width:768px)': {
        position: 'fixed',
        width: '100vw',
        top: '0',
        left: '0',
        marginTop: '0',
      },
    },
    list: {
      minHeight: '10vh',
      maxHeight: '27.5vh',
      overflowY: 'auto',
      '@media (max-width:768px)': {
        maxHeight: 'initial',

        height: 'calc(100vh - 2rem)',
      },
    },
    arrowUp: {
      position: 'absolute',
      top: '-0.6rem',
      right: '1rem',
      width: '0',
      height: '0',
      borderLeft: '8px solid transparent',
      borderRight: '8px solid transparent',
      borderBottom: '10px solid #f74527',
    },
    button: {
      cursor: 'pointer',
    },
    badge: {
      color: '#f74527',
    },
    notificationIconContainer: {
      backgroundColor: 'rgba(247, 69, 39, 0.07)',
    },
    close: {
      cursor: 'pointer',
      display: 'none',
      '@media(max-width:768px)': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '1.5rem',
        fontSize: '.9rem',
        border: '2px solid #fff',
        height: '1.5rem',
        borderRadius: '50%',
      },
    },
  })
)

export enum NotificationsMode {
  Menu = 'Menu',
}

export interface NotificationsContainerProps {
  mode: NotificationsMode
  userID: userId
}

const NotificationsContainer: FC<NotificationsContainerProps> = ({ mode, userID }) => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState<boolean>(false)

  const dispatch = useDispatch()

  const notifications = useSelector<AppState, Notification[]>(state => userNotifications(state.notificationReducer))
  const unreadNotificationsCount = useSelector<AppState, number>(state =>
    unReadNotificationsCount(state.notificationReducer)
  )

  const [disabled, setDisabled] = useState<boolean>(false)

  const markAllAsRead = useCallback(
    (e: React.MouseEvent<HTMLElement>, userID: userId) => {
      if (disabled) {
        return
      }
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      dispatch(markAllNotificationsAsRead(userID))
      setDisabled(true)
    },
    [dispatch, disabled, setDisabled]
  )

  if (mode === NotificationsMode.Menu) {
    return (
      <Box className={classes.notifications} area-label="dashboard_notification_badge">
        <TopMenuItem
          className={classNames({ [classes.notificationIconContainer]: unreadNotificationsCount > 0 })}
          handleClick={() => setExpanded(!expanded)}
        >
          <Badge color={'error'} variant="dot" invisible={unreadNotificationsCount === 0}>
            <NotificationsNoneOutlinedIcon
              className={classNames({
                [classes.iconActive]: unreadNotificationsCount > 0,
              })}
            />
          </Badge>
        </TopMenuItem>
        {expanded && (
          <Box className={classes.notificationsContainer}>
            <Box className={classes.topBar}>
              <Box className={classes.arrowUp} />
              <Typography variant={'h5'}>{i18n.t('notifications__headline')}</Typography>
              {unreadNotificationsCount > 0 && (
                <Typography
                  className={classes.button}
                  variant={'h6'}
                  onClick={(e: React.MouseEvent<HTMLElement>) => markAllAsRead(e, userID)}
                >
                  {i18n.t('notifications__mark__all__as__read')}
                </Typography>
              )}
              <Box className={classes.close} onClick={() => setExpanded(!expanded)}>
                X
              </Box>
            </Box>
            <Box className={classes.list}>
              {notifications.map(n => (
                <NotificationItem
                  key={`notification-${n.id}`}
                  primaryText={n.subject}
                  secondaryText={n.text}
                  date={n.created_at}
                  read={!!n.read_at}
                  id={n.id}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    )
  }
  return null
}

const memoizedNotificationsContainer = React.memo(NotificationsContainer, (prev, next) => {
  return prev.userID === next.userID && prev.mode === next.mode
})

// @ts-ignore
memoizedNotificationsContainer.whyDidYouRender = true
export default memoizedNotificationsContainer
