import produce from 'immer'

import { CritiqueState } from './types'
import { CritiqueActions, CritiqueActionType } from './actions'
import * as stateUtils from '../../utils/state'

const initialState = {
  critiques: {},
  meta: { ...stateUtils.initialMultiLoadedLoadingErrorState },
}

export default function critiqueReducer(
  state: CritiqueState = initialState,
  action: CritiqueActionType
): CritiqueState {
  return produce(state, (draft: CritiqueState) => {
    switch (action.type) {
      case CritiqueActions.FETCH_CRITIQUE: {
        const { fileKey } = action
        draft.meta = stateUtils.setStartStateForItem(fileKey, state.meta)
        break
      }

      case CritiqueActions.FETCH_CRITIQUE_SUCCESS: {
        const { fileKey } = action.meta.previousAction
        draft.meta = stateUtils.setSuccessStateForItem(fileKey, state.meta)
        draft.critiques[fileKey] = action.payload.data
        break
      }

      case CritiqueActions.FETCH_CRITIQUE_FAIL: {
        const { fileKey } = action.meta.previousAction
        draft.meta = stateUtils.setErrorStateForItem(fileKey, state.meta, action.error)
        break
      }
    }
  })
}
