import { applyMiddleware, combineReducers, createStore, compose, Action } from 'redux'
import thunk, { ThunkAction } from 'redux-thunk'
import omit from 'lodash/omit'
import { useSelector, TypedUseSelectorHook } from 'react-redux'

// thin library for ttl caching
import { cacheEnhancer } from 'redux-cache'

// integrate react router with redux
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory, History } from 'history'

// integrate with Sentry
import * as Sentry from '@sentry/browser'
import createSentryMiddleware from 'redux-sentry-middleware'

// set settings for immer
import { setAutoFreeze } from 'immer'

import { createAxiosMiddleware } from '../utils/axios'
import clientsReducer from './clients/reducer'
import configReducer from './config/reducer'
import critiqueReducer from './critique/reducer'
import currentMessageReducer from './currentMessage/reducer'
import documentReducer from './documents/reducer'
import itemsReducer from './items/reducer'
import messagesReducer from './messages/reducer'
import notesReducer from './notes/reducer'
import notificationReducer from './notifications/reducer'
import offerReducer from './offers/reducer'
import orderReducer from './orders/reducer'
import promiseReducer from './promises/reducer'
import templatesReducer from './templates/reducer'
import uploadsReducer from './uploads/reducers'
import userPreferencesReducer from './user_preferences/reducers'
import userReducer from './user/reducers'
import clientOrdersAggregateReducer from './clientOrderAggregate/reducer'
import onboardingReducer from './onboarding/reducer'
import cronReducer from './cron/reducer'
import incentivesReducer from './incentives/reducer'
import dashboardReducer from './dashboard/reducers'
import eventsReducer from './events/reducer'
import clientQuestionnaireReducer from './questionnaire/reducer'
import { ClientQuestionnaireActions } from './questionnaire/actions'
import { CritiqueActions } from './critique/actions'
import featureFeedbackReducer from './featureFeedback/reducer'
import nudgesReducer from './nudges/reducer'
import autolinkedinReducer from './autolinkedin/reducer'
import autoResearchReducer from './autoresearch/reducer'
import schedulingReducer from './scheduling/reducer'
import clientAIDraftReducer from './aiDraft/reducer'

// Since we using immer and immer does some freezing of objects that come with an overhead, we must disable that
// freezing in production.
// https://github.com/immerjs/immer#auto-freezing
setAutoFreeze(process.env.NODE_ENV !== 'production')

// Note that we do not have to explicitly declare a new interface for AppState.
// We can use ReturnType to infer state shape from the rootReducer.
const createRootReducer = (history: History) => {
  return combineReducers({
    router: connectRouter(history),
    autolinkedinReducer,
    autoResearchReducer,
    clientOrdersAggregateReducer,
    clientReducer: clientsReducer,
    clientQuestionnaireReducer,
    configReducer,
    critiqueReducer,
    cronReducer,
    currentMessageReducer,
    dashboardReducer,
    documentReducer,
    eventsReducer,
    featureFeedbackReducer,
    incentivesReducer,
    itemsReducer,
    messagesReducer,
    notesReducer,
    notificationReducer,
    nudgesReducer,
    offerReducer,
    onboardingReducer,
    orderReducer,
    promiseReducer,
    schedulingReducer,
    templatesReducer,
    uploadsReducer,
    userPreferencesReducer,
    userReducer,
    clientAIDraftReducer,
  })
}

export const history = createBrowserHistory()

// There are some actions that we don't care about failing with regards to sending to Sentry
export const actionsToOmitFromSentry = [
  ClientQuestionnaireActions.FETCH_CLIENT_QUESTIONNAIRE,
  CritiqueActions.FETCH_CRITIQUE,
]

// Put all the redux middleware in this array.
const middleware = [
  thunk,
  createAxiosMiddleware(),
  createSentryMiddleware(Sentry, {
    breadcrumbDataFromAction: action =>
      omit(action, [
        // omitting this because it's the breadcrumb name
        'type',
        // omitting this because it can be huge
        'payload',
        // omitting this because it can be huge
        'meta',
        // omitting this because it can be huge
        'error',
      ]),
  }),
]

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore(defaultState?: AppState) {
  return createStore(
    createRootReducer(history),
    defaultState,
    composeEnhancers(applyMiddleware(...middleware), cacheEnhancer({ log: true }))
  )
}

// This is the shape of our global state object.
export type AppState = ReturnType<ReturnType<typeof createRootReducer>>

// This is a generic type for Thunk actions in this app.
export type AppThunkAction<ThunkReturnType = void, ExtraArgument = unknown> = ThunkAction<
  Promise<ThunkReturnType>,
  AppState,
  ExtraArgument,
  Action<string>
>

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
