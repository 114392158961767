import React from 'react'
// @ts-ignore
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import addDays from 'date-fns/addDays'
import startOfDay from 'date-fns/startOfDay'
import isBefore from 'date-fns/isBefore'
import isEqual from 'date-fns/isEqual'
import { NavigateAction, WeekProps } from 'react-big-calendar'

export class CustomCalendarWeekView extends React.Component<WeekProps> {
  static range: (date: Date) => Date[]
  static navigate: (date: Date, action: NavigateAction) => Date
  static title: (date: Date) => string
  render() {
    const { date } = this.props
    const range = CustomCalendarWeekView.range(date)

    return <TimeGrid {...this.props} range={range} eventOffset={15} />
  }
}

CustomCalendarWeekView.title = (date: Date) => date.toString()
CustomCalendarWeekView.range = (date: Date) => {
  const start = new Date()
  const day = start.getDay()
  const diff = start.getDate() - day + (day === 0 ? -6 : 1)

  let current = startOfDay(new Date(start.setDate(diff)))
  const end = addDays(current, 4)

  const range = []
  while (isBefore(current, end) || isEqual(current, end)) {
    range.push(current)
    current = addDays(current, 1)
  }
  return range
}

export default CustomCalendarWeekView
