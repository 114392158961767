import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import isEqual from 'lodash/isEqual'

import { GhostLink } from '../../utils/link'
import i18n from '../../i18n'
import DashboardStyles from './Styles'
import { UnreadClientMessages } from '../../store/dashboard/types'
import EmptyActivityCard from './EmptyActivityCard'
import { getClientMessagesUrl } from '../../utils/consts'
import { formatEventDateOffset } from '../../utils/formatting'
import useUserInfoState from '../common/useUserInfo'

export interface UnreadMessagesProps {
  client_messages: UnreadClientMessages[]
  load_state: boolean
}

const useStyles = makeStyles(theme =>
  createStyles({
    header: {
      color: DashboardStyles.header.color,
      fontSize: DashboardStyles.header.size,
      margin: DashboardStyles.header.margin,
      padding: '0',
    },
    row: {
      '&:hover': {
        backgroundColor: DashboardStyles.row.hover,
      },
    },
    lateRow: {
      background: DashboardStyles.row.highlight,

      '&:hover': {
        backgroundColor: DashboardStyles.row.highlightHover,
      },
    },
    paper: {
      color: 'inherit',
      height: DashboardStyles.listHeight,
      overflow: 'auto',
    },
    cardContent: {
      backgroundColor: DashboardStyles.card.bg,
      border: DashboardStyles.card.border,
      boxShadow: DashboardStyles.card.shadow,
      color: 'inherit',
      padding: '0',
      width: DashboardStyles.fullWidth,
      height: DashboardStyles.listHeight,

      '&:last-child': {
        paddingBottom: '1.6em',
      },
    },
    table: {
      padding: DashboardStyles.card.padding,
      '& td': {
        borderBottom: DashboardStyles.row.border,
        color: 'inherit',
        fontSize: 'inherit',
        padding: 0,
        width: DashboardStyles.fullWidth,

        '& a': {
          color: 'inherit',
        },

        '& time': {
          color: DashboardStyles.row.timeColor,
        },
      },
      '& tr': {
        display: 'block',
        padding: DashboardStyles.row.padding,
        lineHeight: DashboardStyles.row.lineHeight,

        '&:last-of-type': {
          '& td': {
            borderBottom: 'none',
          },
        },
      },
    },
    tableBody: {
      padding: '0 1.6em',
    },
    title: {
      fontSize: 'inherit',
    },
    unreadMessages: {
      backgroundColor: 'transparent',
      color: 'inherit',
      overflow: 'visible',
      width: DashboardStyles.halfWidth,
    },
    cellLink: {
      display: 'block',
      padding: DashboardStyles.row.paddingInner,
    },
    [theme.breakpoints.down('sm')]: {
      unreadMessages: {
        margin: DashboardStyles.card.marginMobile,
        width: DashboardStyles.fullWidth,
      },
    },
  })
)

const UnreadMessages: React.FC<UnreadMessagesProps> = ({ load_state, client_messages }) => {
  const classes = useStyles()
  const { timeZone } = useUserInfoState()

  if (!load_state) {
    return <Typography>Loading...</Typography>
  }

  return (
    <Card className={classes.unreadMessages} id="unread-messages">
      <CardHeader
        className={classes.header}
        classes={{ title: classes.title }}
        title={i18n.t('dashboard__unreadMessages__title')}
      />
      <CardContent className={classes.cardContent}>
        <Paper className={classes.paper}>
          {client_messages.length === 0 && (
            <EmptyActivityCard
              emoji="💪"
              emojiLabel="flexing arm emoji"
              heading={i18n.t('dashboard__unreadMessages__emptytitle')}
              subtitle={i18n.t('dashboard__unreadMessages__emptysubtitle')}
            />
          )}
          {client_messages.length > 0 && (
            <Table aria-label="unread message count tables" className={classes.table}>
              <TableBody className={classes.tableBody}>
                {client_messages.map(function(row, index) {
                  return (
                    <TableRow key={index} className={row.is_late ? classes.lateRow : classes.row}>
                      <TableCell>
                        <GhostLink to={getClientMessagesUrl(row.client_id)} className={classes.cellLink}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: i18n.t('dashboard__unreadMessages__row', {
                                client_name: row.client_name,
                                msg_count: row.unread_count,
                                clientID: row.client_id,
                              }),
                            }}
                          />
                        </GhostLink>
                      </TableCell>
                      <TableCell>
                        <Typography component="time" dateTime={row.received_at}>
                          {formatEventDateOffset(new Date(), new Date(row.received_at).toISOString(), timeZone)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
        </Paper>
      </CardContent>
    </Card>
  )
}

const memoizedUnreadMessages = React.memo(UnreadMessages, isEqual)
//@ts-ignore
memoizedUnreadMessages.whyDidYouRender = true

export default memoizedUnreadMessages
