const styles = {
  fonts: {
    baseSize: 18,
    primary: {
      family: 'proxima-nova, sans-serif',
      weights: {
        normal: 'normal',
        semiBold: 600,
        bold: 800,
      },
    },
  },
  palette: {
    primary: {
      color: '#1ba4f1',
      contrast: '#0b143b',
      contrastHover: '#0d1d54',
    },
    contrast: {
      color: '#0b143b',
      hover: '#0d1d54',
    },
    secondary: {
      color: '#c0c4ca',
      contrast: '#39397C',
      hover: '#f1f2f4',
    },
    link: {
      color: '#0077b5',
    },
    darkBlue: {
      color: '#42516d',
    },
    textPrimary: {
      color: '#000',
      contrast: '#fff',
    },
    lightBlack: {
      color: '#25252b',
    },
    lightGrey: {
      color: '#b9bec8',
    },
    inputGray: {
      color: '#fafafc',
    },
    brandRed: '#f74527',
    lighterRed: '#f38f81',
    lightRed: '#f8c1b9',
    select: {
      border: '#000',
      textColor: '#6f737c',
    },
    checkbox: {
      boxColor: '',
      textColor: '',
    },
    moneyGreen: {
      color: '#64cea0',
    },
    listHover: {
      color: '#f5f5f9',
    },
    shadowedSelect: {
      color: '#333f57',
    },
    darkerBlue: {
      color: {
        hex: '#09143b',
        rgb: '9, 20, 59',
      },
    },
    smokeBlue: {
      color: {
        hex: '#3c4a66',
      },
    },
    lightPurple: {
      color: '#f6f7fe',
    },
    pink: {
      hex: '#ed3572',
      rgb: '237, 53, 114',
    },
    modalWrapper: {
      backgroundColor: '#fcfcfc',
    },
  },
  sizes: {
    order2orderNavWidth: '15rem',
    topNavHeight: '3.5rem',
    modalWrapperMaxWidth: '952px',
  },
}

export default styles
