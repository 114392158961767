import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useSelector } from 'react-redux'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'

import ModalWrapper from '../common/ModalWrapper'
import Header from '../common/Header'
import Button from '../common/Button'
import Footer from '../common/Footer'
import i18n from '../../i18n'
import ReuploadButton from './ReuploadButton'
import { AutoQAScanOutput, FileScanOptionKeys, ScanDetails, UploadOverview } from '../../store/uploads/types'
import AutoQAResults from './AutoQAResults'
import { getAutoQAImprovementsSectionsFromScanDetails } from '../../selectors/autoQA'
import { AppState } from '../../store'
import { HashMap } from '../../utils/HashMap'
import { FeatureFeedback, FeatureKeys } from '../../store/featureFeedback/types'
import styles from '../../styles'
import UploadStyles from './styles'
import useEvents from '../common/useEvents'
import some from 'lodash/some'

interface ImprovementsProps {
  onFinalizeUploadClick: () => void
  onBackClick: () => void
  reuploadFile: (file: File, filenameToRemove: string) => void
  scans: UploadOverview[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginBottom: '1rem',
      },
    },
    cancelButton: {
      marginRight: '1.2rem',
      padding: '.5rem 1.547rem',
    },
    submitButton: {
      padding: '.5rem 1.547rem',
    },
    title: {
      fontWeight: 500,
      marginTop: '2em',
      marginBottom: '1.5em',
      color: styles.palette.primary.contrast,
    },
    modal: {
      color: UploadStyles.darkBlue.hex,
      padding: '1.8rem 1.9rem 1.6rem',
      width: '100%',
      maxWidth: '487px',
    },
    modalTitle: {
      fontWeight: 'bold',
      fontSize: '1rem',
      marginBottom: '1.2rem',
    },
    modalBody: {
      fontSize: '.9em',
      fontWeight: 300,
      marginBottom: '1em',
    },
  })
)

interface ScanImprovementsWrapper {
  autoQAOutput: ScanDetails<AutoQAScanOutput> | null
  feedback: HashMap<HashMap<FeatureFeedback>>
  fileName: string
}

const ScanImprovementsWrapper: React.FC<ScanImprovementsWrapper> = ({ fileName, autoQAOutput, feedback }) => {
  const autoQAResults = getAutoQAImprovementsSectionsFromScanDetails(autoQAOutput, feedback)
  const classes = useStyles()

  if (autoQAResults.length === 0) {
    return null
  }

  return (
    <Box>
      <Typography component="h3" align="center" variant="h1" className={classes.title}>
        {i18n.t('auto_qa__improvements__item_header', { fileName: fileName })}
      </Typography>
      <AutoQAResults results={autoQAResults} theme={'inverted'} />
    </Box>
  )
}

const Improvements: React.FC<ImprovementsProps> = ({ scans, onBackClick, onFinalizeUploadClick, reuploadFile }) => {
  const classes = useStyles()
  const [showModal, setShowModal] = useState<boolean>(false)
  const feedback = useSelector<AppState, HashMap<HashMap<FeatureFeedback>>>(
    state => state.featureFeedbackReducer[FeatureKeys.FilePreprocess]
  )

  const someFilesAreLoading = useSelector<AppState, boolean>(state =>
    some(Object.values(state.uploadsReducer.uploads), i => i.meta.isLoading)
  )
  const { sendEvent, eventTypes } = useEvents()

  const handleAttachClick = () => {
    const { context, context_id } = scans[0].pending

    sendEvent({
      event: eventTypes.UploadBypassImprovementsYes,
      variables: {
        context,
        contextID: context_id,
        preprocessIDs: scans.map(uo => uo.pending.preprocessID).filter(ppid => !!ppid),
      },
    })

    onFinalizeUploadClick()
  }

  const handleNotSure = () => {
    const { context, context_id } = scans[0].pending

    setShowModal(false)
    sendEvent({
      event: eventTypes.UploadBypassImprovementsNo,
      variables: {
        context,
        contextID: context_id,
        preprocessIDs: scans.map(uo => uo.pending.preprocessID).filter(ppid => !!ppid),
      },
    })
  }

  return (
    <ModalWrapper>
      <Header activeStep={1} title={i18n.t('uploadsv3__improvements__title')} />

      <Box className={classes.body}>
        {scans.map(
          scan =>
            !!scan &&
            !!scan.preprocess &&
            !!scan.preprocess[FileScanOptionKeys.AutoQA] && (
              <ScanImprovementsWrapper
                fileName={scan.pending.file.name}
                autoQAOutput={scan.preprocess[FileScanOptionKeys.AutoQA] || null}
                feedback={feedback}
              />
            )
        )}
      </Box>

      <Footer
        leftColumn={
          <Button type="secondary" className={classes.cancelButton} onClick={onBackClick}>
            {i18n.t('messaging__docs__btn__back')}
          </Button>
        }
        rightColumn={
          <>
            <Button type="secondary" className={classes.cancelButton} onClick={() => setShowModal(true)}>
              {i18n.t('messaging__docs__btn__next')}
            </Button>
            <ReuploadButton scans={scans} reuploadFile={reuploadFile} buttonType="contrast" />
          </>
        }
      />
      <Dialog open={showModal} classes={{ paper: classes.modal }}>
        <Typography variant="h2" className={classes.modalTitle}>
          {i18n.t('uploadsv3__bypass__title')}
        </Typography>
        <Typography className={classes.modalBody}>{i18n.t('uploadsv3__bypass__body')}</Typography>
        <DialogActions>
          <Button type="secondary" onClick={handleAttachClick} disabled={someFilesAreLoading}>
            {i18n.t('uploadsv3__bypass__yes')}
          </Button>
          <Button type="contrast" onClick={handleNotSure}>
            {i18n.t('uploadsv3__bypass__no')}
          </Button>
        </DialogActions>
      </Dialog>
    </ModalWrapper>
  )
}

export default Improvements
