import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import i18n from '../../i18n'
import { Document } from '../../store/documents/types'
import { formatDate } from '../../utils/formatting'
import useUserInfoState from '../common/useUserInfo'
import CustomCheckbox from '../common/CustomCheckbox'
import UploadStyles from './styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '13.625rem',
      border: `1px solid ${UploadStyles.darkBlue.hex}`,
      borderRadius: '10px',
    },
    control: {
      width: '100%',
      height: '100%',
      margin: 0,
      padding: '1rem .2rem',
      boxSizing: 'border-box',
      fontSize: '.6875rem',
      '& > *': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    filename: {
      color: UploadStyles.darkBlue.hex,
    },
    time: {
      color: `rgba(${UploadStyles.darkBlue.rgb}, 0.4)`,
    },
  })
)

interface AttachmentsItemProps {
  checked: boolean
  doc: Document
  toggleChecked: () => void
}

const AttachmentsItem: React.FC<AttachmentsItemProps> = ({ doc, checked, toggleChecked }) => {
  const classes = useStyles()
  // @TODO Implement sent at time stamp logic
  // const fileInfo = 'Sent : 09/03 03:20 pm'
  const { timeZone, locale } = useUserInfoState()

  return (
    <Box className={classes.root}>
      <FormControlLabel
        className={classNames([classes.control, 'upload-attachments-item'])}
        control={<CustomCheckbox checked={checked} onChange={toggleChecked} />}
        label={
          <>
            <Typography display="block" variant="body2" component="span" className={classes.filename}>
              {doc.filename}
            </Typography>
            <Typography display="block" variant="body2" component="span" className={classes.time}>
              {formatDate(doc.created_at, timeZone, locale, i18n.t('messaging__docs__uploadedAtFormat'))}
            </Typography>
          </>
        }
        labelPlacement="end"
      />
    </Box>
  )
}

export default React.memo(AttachmentsItem)
