import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import i18n from '../../i18n'
import { OfferTimer } from './OfferTimer'
import useUserInfoState from '../common/useUserInfo'

interface BaseProps {
  offersPerHour: number
  onTimerEnd: () => void
  offersRejectedLastHour: number
  currentOfferExpiresAt: Date | null
  timerActive: boolean
  hideRejections: boolean
}

interface StatedCapacityProps extends BaseProps {
  variant: 'stated-capcity'
  offersPerWeek: number
  offersAcceptedLastWeek: number
}

interface WorkInProgressProps extends BaseProps {
  variant: 'wip'
  wipCount: number
  wipMax: number | null
}

type OrderShoppingStatsProps = StatedCapacityProps | WorkInProgressProps

const useStyles = makeStyles(() =>
  createStyles({
    orderStats: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '4.4375rem',
      padding: '1.532rem 3rem',
      backgroundColor: '#fff',
      boxSizing: 'border-box',
      border: '1px solid #dee1e7',
      marginBottom: '0.9125rem',
      '@media (max-width: 1050px)': {
        padding: '1.532rem 2rem',
      },
      '@media (max-width: 800px)': {
        flexDirection: 'column',
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '.5rem 1rem',
        '& > *:not(:last-child)': { marginBottom: '.4rem' },
      },
    },
    text: {
      fontSize: '0.875rem',
    },
    orange: {
      color: '#ff9550',
    },
    green: {
      color: '#00a87b',
    },
    accepted: {
      '@media (max-width: 800px)': { display: 'none' },
    },
  })
)

const OrderShoppingStats: React.FC<OrderShoppingStatsProps> = ({
  onTimerEnd,
  currentOfferExpiresAt,
  timerActive,
  offersPerHour,
  offersRejectedLastHour,
  hideRejections,
  ...props
}) => {
  const classes = useStyles()
  const userInfo = useUserInfoState()
  const offersLeft = offersPerHour - offersRejectedLastHour

  return (
    <>
      <Box className={classes.orderStats}>
        <Box className={classes.accepted}>
          {props.variant === 'stated-capcity' && (
            <>
              <Typography display="inline" variant={'body1'} className={classes.green}>
                {i18n.t('order__shopping__stats__accepted__last_week', {
                  offersAcceptedLastWeek: props.offersAcceptedLastWeek,
                  offersPerWeek: !userInfo.isNewHire ? props.offersPerWeek : 8,
                })}
              </Typography>
              <Typography display="inline" variant={'body1'}>
                {` ${i18n.t('order__shopping__stats__accepted__this__week')}`}
              </Typography>
            </>
          )}
          {props.variant === 'wip' && props.wipMax !== null && (
            <>
              <Typography display="inline" variant={'body1'} className={classes.green}>
                {i18n.t('order__shopping__stats__wip', {
                  wipCount: props.wipCount,
                  wipMax: props.wipMax,
                })}
              </Typography>
              <Typography display="inline" variant={'body1'}>
                {` ${i18n.t('order__shopping__stats__wip__subtitle')}`}
              </Typography>
            </>
          )}
        </Box>
        {timerActive && !!currentOfferExpiresAt ? (
          <Box className={classes.orange}>
            <OfferTimer upperBound={currentOfferExpiresAt} onTimerEnd={onTimerEnd} activeThresholdMs={60 * 1000} />
          </Box>
        ) : null}
        {!hideRejections && (
          <Box>
            <Typography display="inline" variant={'body1'}>
              {i18n.t('order__shopping__stats__hourly__rejections__remaining')}
            </Typography>
            <Typography display="inline" variant={'body1'} className={classes.green}>
              {` ${offersLeft}/${offersPerHour}`}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  )
}

export default OrderShoppingStats
