import React, { useCallback, useState } from 'react'
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import Chip from '@material-ui/core/Chip'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'
import RemoveIcon from '@material-ui/icons/Remove'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'

import i18n from '../../i18n'
import UploadStyles from './styles'
import Ellipses from '../common/Icons/Ellipses'
import { AutoQAScanResult, AutoQASectionType, FileScanOptionKeys, UploadOverview } from '../../store/uploads/types'
import { getCurrentScanKey, getScanErrorsV3, getScanProgress, ScanErrors } from '../../selectors/uploads'
import {
  getAutoQAFeatures,
  getAutoQAScanSectionsFromScanDetails,
  getAutoQAScanTotalResult,
  getDocImprovements,
  getSectionResult,
} from '../../selectors/autoQA'
import AutoQAResults from './AutoQAResults'
import { FeedbackBoolPayload } from '../../store/featureFeedback/types'
import { HashMap } from '../../utils/HashMap'

interface Props {
  scan: UploadOverview
  removeItem: () => void
  feedback: HashMap<HashMap<FeedbackBoolPayload>>
  updateFeedback: (payload: FeedbackBoolPayload) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '8.25rem',
      color: UploadStyles.darkBlue.hex,
    },
    progress: {},
    sections: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '.8rem 1.5rem .8rem 1.9rem',
      minHeight: 'calc(8.25rem - 5px)',
    },
    section: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(8.25rem - 5px)',
    },
    filenameSection: {
      width: '50%',
    },
    improvementsSection: {
      width: '25%',
    },
    assessmentSection: {
      width: '20%',
    },
    genericSection: {
      width: '45%',
    },
    uploadSection: {
      textAlign: 'right',
      fontStyle: 'italic',
      '& > span': {
        marginTop: 'auto',
        marginBottom: 'auto',
      },
    },
    sectionTitle: {
      textTransform: 'uppercase',
      marginBottom: '0.8rem',
    },
    sectionBottom: {
      marginTop: 'auto',
    },
    scanningDetailText: {
      fontStyle: 'italic',
    },
    sectionAssesmentItem: {
      marginBottom: '0.3125rem',

      '& svg': {
        marginTop: '-2px',
        verticalAlign: 'middle',
      },
    },
    pending: {
      color: 'rgba(9, 20, 59, 0.3)',
    },
    [AutoQAScanResult.Pass]: {
      color: UploadStyles.lightGreen.hex,
    },
    [AutoQAScanResult.Warning]: {
      color: UploadStyles.lightYellow.hex,
    },
    [AutoQAScanResult.Fail]: {
      color: UploadStyles.pink.hex,
    },
    removeButton: {
      color: UploadStyles.darkBlue.hex,
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    scanError: {
      color: UploadStyles.pink.hex,
      display: 'flex',
      '&.autoQA': {
        color: 'black',
      },

      '& > svg': {
        marginRight: '5px',
      },
    },
  })
)

/**
 * Quick design note!
 * I setup as many of the custom components as material-ui components wrapped in a `withStyles` call.
 * To differentiate between a success and error state, primary = success/default, secondary = error.
 */

const Progress = withStyles({
  root: {
    height: 5,
  },
  colorPrimary: {
    backgroundColor: 'transparent',
  },
  colorSecondary: {
    backgroundColor: 'transparent',
  },
  barColorPrimary: {
    backgroundColor: UploadStyles.darkBlue.hex,
  },
  barColorSecondary: {
    backgroundColor: UploadStyles.pink.hex,
  },
})(LinearProgress)

const CompletedChip = withStyles({
  root: {
    borderRadius: '5px',
    fontSize: '.9rem',
    fontWeight: 'bold',
  },
  colorPrimary: {
    backgroundColor: '#f5f6fa',
    color: UploadStyles.darkBlue.hex,
  },
  colorSecondary: {
    backgroundColor: `rgba(${UploadStyles.pink.rgb}, 0.1)`,
    color: UploadStyles.pink.hex,
  },
  label: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
})(Chip)

const GiantReviewButton = withStyles({
  root: {
    width: '100%',
    color: UploadStyles.darkBlue.hex,
    backgroundColor: UploadStyles.lightBlue.hex,
    textTransform: 'none',
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: '1rem',
    transition: 'opacity 0.4s ease-in-out',

    '&:hover': {
      backgroundColor: `rgba(${UploadStyles.lightBlue.rgb}, 0.7)`,
    },
  },
})(Button)

const getScanMessage = (key: FileScanOptionKeys | null) => {
  switch (key) {
    case FileScanOptionKeys.DraftComparison:
      return i18n.t('uploadsv3__scanItem__scanningDetailDraftComparison')
    default:
      return i18n.t('uploadsv3__scanItem__scanningDetailDefault')
  }
}

interface ScanErrorDisplayProps {
  errors: Partial<ScanErrors>
}

const ScanErrorDisplay: React.FC<ScanErrorDisplayProps> = ({ errors }) => {
  const classes = useStyles()

  return (
    <>
      {Object.entries(errors).map(([key, err]) => (
        <Box className={classNames({ [classes.scanError]: true, autoQA: key === FileScanOptionKeys.AutoQA })}>
          <WarningIcon />
          <Typography component="span">{i18n.t(`uploadsv3__scanItem__scanError__${key}`, { scan: err })}</Typography>
        </Box>
      ))}
    </>
  )
}

interface ProgressWrapperProps extends Props {
  children: React.ReactNode
  hasQAConcerns: boolean
  autoQAStatus?: AutoQAScanResult | null | undefined
}

const ProgressWrapper: React.FC<ProgressWrapperProps> = props => {
  const classes = useStyles(props)
  const { children, scan, hasQAConcerns, autoQAStatus } = props
  const progress = getScanProgress(scan)
  const currentScan = getCurrentScanKey(props.scan)
  const completed = progress === 100
  const errors = getScanErrorsV3(props.scan.preprocess)
  const hasErrors = Object.values(errors).length > 0
  const hasAutoQAErrors = !!errors[FileScanOptionKeys.AutoQA]

  return (
    <Paper className={classes.root} variant="outlined" elevation={2}>
      <Progress
        className={classes.progress}
        variant="determinate"
        value={progress}
        color={!hasErrors || hasAutoQAErrors ? 'primary' : 'secondary'}
      />
      <Box className={classes.sections}>
        <Box className={classNames([classes.section, classes.filenameSection])}>
          <Typography variant="h6" className={classes.sectionTitle}>
            {scan.pending.doc_type?.replace('-', ' ') || 'Document'}
          </Typography>
          <Typography>{scan.pending.file.name}</Typography>
          <Box className={classes.sectionBottom}>
            {!completed && !hasErrors && (
              <Typography className={classes.scanningDetailText}>{getScanMessage(currentScan)}</Typography>
            )}
            {completed && !hasErrors && !hasQAConcerns && (
              <CompletedChip color="primary" label={i18n.t('uploadsv3__scanItem__pass_no_results')} />
            )}
            {completed && !hasErrors && !!autoQAStatus && autoQAStatus === AutoQAScanResult.Pass && hasQAConcerns && (
              <CompletedChip color="primary" label={i18n.t('uploadsv3__scanItem__pass_has_results')} />
            )}
            {completed && !hasErrors && !!autoQAStatus && autoQAStatus === AutoQAScanResult.Warning && (
              <CompletedChip color="secondary" label={i18n.t('uploadsv3__scanItem__warn')} />
            )}
            {completed && !hasErrors && !!autoQAStatus && autoQAStatus === AutoQAScanResult.Fail && (
              <CompletedChip color="secondary" label={i18n.t('uploadsv3__scanItem__fail')} />
            )}
            {hasErrors && <ScanErrorDisplay errors={errors} />}
          </Box>
        </Box>
        {children}
        <Box className={classes.section}>
          <IconButton className={classes.removeButton} onClick={props.removeItem} aria-label="remove upload">
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  )
}

interface SectionAssessmentItemProps {
  text: string
  variant: 'pending' | AutoQAScanResult
}

const SectionAssessmentItem: React.FC<SectionAssessmentItemProps> = ({ text, variant }) => {
  const classes = useStyles()
  let icon = <Ellipses />

  switch (variant) {
    case AutoQAScanResult.Pass:
      icon = <CheckIcon />
      break
    case AutoQAScanResult.Warning:
    case AutoQAScanResult.Fail:
      icon = <WarningIcon />
      break
  }

  return (
    <Typography className={classNames([classes.sectionAssesmentItem, classes[variant]])}>
      {icon} {text}
    </Typography>
  )
}

const ResumeSection: React.FC<Props> = props => {
  const classes = useStyles(props)
  const completed = getScanProgress(props.scan) === 100

  const autoQAScan = props.scan.preprocess?.[FileScanOptionKeys.AutoQA] || null
  const autoQAResults = getAutoQAScanSectionsFromScanDetails(autoQAScan)

  const hasAutoQAConcerns = !!autoQAResults && !autoQAResults.every(s => s.count === 0)
  const autoQAStatus = getAutoQAScanTotalResult(autoQAScan)

  const autoQAFeatures = getAutoQAFeatures(autoQAScan)
  const hasAutoQAFeatures = autoQAFeatures.length > 0
  const improvements = getDocImprovements(autoQAFeatures)

  const [autoQAExpanded, setAutoQAExpanded] = useState(Object.values(props.feedback).length > 0)
  const handleClose = useCallback(() => setAutoQAExpanded(false), [setAutoQAExpanded])

  return (
    <Box>
      <ProgressWrapper hasQAConcerns={hasAutoQAConcerns} autoQAStatus={autoQAStatus} {...props}>
        <Box className={classNames([classes.section, classes.improvementsSection])}>
          <Typography variant="h6" className={classes.sectionTitle}>
            {i18n.t('uploadsv3__scanItem__improvementsSectionTitle')}
          </Typography>
          {!completed && <Ellipses />}
          {completed && improvements.length === 0 && <RemoveIcon />}
          {completed &&
            improvements.length > 0 &&
            improvements.map(i => (
              <Typography className={classNames([classes.sectionAssesmentItem])}>
                <AddIcon />
                {i}
              </Typography>
            ))}
        </Box>
        <Box className={classNames([classes.section, classes.assessmentSection])}>
          <Typography variant="h6" className={classes.sectionTitle}>
            {i18n.t('uploadsv3__scanItem__sectionAssesmentTitle')}
          </Typography>
          <SectionAssessmentItem
            text={i18n.t('uploadsv3__scanItem__contactInformation')}
            variant={hasAutoQAFeatures ? getSectionResult(autoQAFeatures, AutoQASectionType.ContactInfo) : 'pending'}
          />
          <SectionAssessmentItem
            text={i18n.t('uploadsv3__scanItem__verbatim')}
            variant={hasAutoQAFeatures ? getSectionResult(autoQAFeatures, AutoQASectionType.Originality) : 'pending'}
          />
          <SectionAssessmentItem
            text={i18n.t('uploadsv3__scanItem__wordChoice')}
            variant={hasAutoQAFeatures ? getSectionResult(autoQAFeatures, AutoQASectionType.Readability) : 'pending'}
          />
        </Box>
      </ProgressWrapper>
      {!autoQAExpanded && completed && hasAutoQAConcerns && (
        <GiantReviewButton onClick={() => setAutoQAExpanded(true)}>
          {autoQAStatus === AutoQAScanResult.Pass &&
            i18n.t('uploadsv3__scanItem__giant_review_button__pass_has_results')}
          {autoQAStatus !== AutoQAScanResult.Pass && i18n.t('uploadsv3__scanItem__giant_review_button__fail')}
        </GiantReviewButton>
      )}
      {autoQAExpanded && (
        <AutoQAResults
          feedback={props.feedback}
          updateFeedback={props.updateFeedback}
          results={autoQAResults}
          onClose={handleClose}
        />
      )}
    </Box>
  )
}

const GenericDocumentSection: React.FC<Props> = props => {
  const classes = useStyles(props)
  const completed = getScanProgress(props.scan) === 100

  return (
    <ProgressWrapper {...props} hasQAConcerns={false}>
      <Typography
        className={classNames([classes.section, classes.genericSection, classes.uploadSection])}
        component="div"
      >
        {!completed && <span>{i18n.t('uploadsv3__scanItem__uploading')}</span>}
      </Typography>
    </ProgressWrapper>
  )
}

const NewScanItem: React.FC<Props> = props => {
  const variant = props.scan.preprocess?.order.includes(FileScanOptionKeys.AutoQA) ? 'resume' : 'generic-document'

  if (variant === 'resume') {
    return <ResumeSection {...props} />
  }

  return <GenericDocumentSection {...props} />
}

export default NewScanItem
