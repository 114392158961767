import React, { useCallback, useState } from 'react'
import i18n from '../../../i18n'
import DashboardStyles from '../../Dashboard/Styles'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import CalendarView from './CalendarView'
import StyledSelect from '../../common/StyledSelect'
import { CalendarEvent, CalendarType, Preferences } from '../../../store/scheduling/types'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../store'
import { getPreferences, calendarEventsSelector } from '../../../store/scheduling/selectors'
import useUserInfoState from '../../common/useUserInfo'
import ParticipantList from './ParticipantList'
import AvailabilityWidget from './AvailabilityWidget'
import UserPreferences from './UserPreferences'
import { updatePreferences, updatePreferencesThunk } from '../../../store/scheduling/actions'
import YesNoDialog from '../../AutoLinkedin/YesNoDialog'
import ErrorBoundary from '../../common/ErrorBoundary'

const useStyles = makeStyles(theme =>
  createStyles({
    schedule: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-start',
      backgroundColor: 'white',
      paddingTop: '1rem',
      width: DashboardStyles.fullWidth,
    },
    calendarContainer: {
      flex: 1,
      width: 'calc(100% - 20rem)',
      marginLeft: '1rem',
      marginBottom: '2.6rem',
    },
    participantsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      paddingLeft: '1rem',
      minWidth: '20rem',
    },
    selectCalendar: {
      width: '8rem',
      alignSelf: 'flex-end',
      marginRight: '2rem',
      marginBottom: '1.5rem',
      marginTop: '1rem',
      backgroundColor: '#fafafc',
      borderBottom: '.9px solid #09143b',
    },
    toolIconBase: {
      position: 'absolute',
      bottom: '0.2rem',
      right: '1.4rem',
      width: '9.5rem',
      height: '2.25rem',
      border: 'solid 1px #333f57',
      borderRadius: '3px',
    },
    root: {
      padding: '1rem 2rem 2rem 1rem',
    },
    drawer: {
      marginTop: '5rem',
    },
    closeIcon: {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      width: '1rem',
      height: '1rem',
    },
  })
)

const CalendarContainer: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [editView, setEditView] = useState<boolean>(false)
  const [lowAvailability, setLowAvailability] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [selectedView, setSelectedView] = useState<CalendarType>(CalendarType.WeekView)
  const [availabilitySaved, setAvailabilitySaved] = useState(false)

  const userInfo = useUserInfoState()

  const events = useSelector<AppState, CalendarEvent[]>(state =>
    calendarEventsSelector({
      ...state.clientReducer,
      ...state.schedulingReducer,
    })
  )

  const preferences = useSelector<AppState, Preferences | null>(state => getPreferences(state.schedulingReducer))

  const onPreferencesUpdate = useCallback(
    async preferences => {
      const availableForScheduledItems = await dispatch(updatePreferencesThunk(userInfo.id, preferences))
      if (!availableForScheduledItems) {
        setLowAvailability(true)
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [userInfo.id, updatePreferences]
  )

  const saveAvailability = async () => {
    const save = document.querySelector('.availability__submit') as HTMLElement
    if (save) save.click()
  }

  return (
    <ErrorBoundary>
      <Box className={classes.schedule}>
        <Box className={classes.calendarContainer}>
          {!editView ? (
            <CalendarView
              events={events}
              onSelectDate={setSelectedDate}
              selectedDate={selectedDate}
              selectedView={selectedView}
            />
          ) : (
            <AvailabilityWidget
              userID={userInfo.id}
              userTimezone={userInfo.timeZone}
              setAvailabilitySaved={setAvailabilitySaved}
            />
          )}
        </Box>
        <Box className={classes.participantsContainer}>
          <Box className={classes.selectCalendar}>
            <StyledSelect
              onChange={e => setSelectedView(e.target.value as CalendarType)}
              value={selectedView}
              items={[
                {
                  label: <>{i18n.t('schedule_calendar_weekly')}</>,
                  value: CalendarType.WeekView,
                },
                {
                  label: <>{i18n.t('schedule_calendar_workweek')}</>,
                  value: CalendarType.WorkWeekView,
                },
                {
                  label: <>{i18n.t('schedule_calendar_day')}</>,
                  value: CalendarType.DayView,
                },
              ]}
            />
          </Box>
          {editView ? (
            preferences && (
              <UserPreferences
                preferences={preferences}
                setEditView={setEditView}
                userID={userInfo.id}
                onPreferencesUpdate={onPreferencesUpdate}
                saveAvailability={saveAvailability}
                availabilitySaved={availabilitySaved}
              />
            )
          ) : (
            <ParticipantList selectedDate={selectedDate} setEditView={setEditView} />
          )}
        </Box>
        {lowAvailability && (
          <YesNoDialog
            title={i18n.t('schedule_calendar_low_availability')}
            body={i18n.t('schedule_calendar_low_availability_body')}
            isOpen={lowAvailability}
            setIsOpen={setLowAvailability}
            handleNo={() => {
              setEditView(true)
              setLowAvailability(false)
            }}
            handleYes={() => setLowAvailability(false)}
            showCloseIcon={true}
          />
        )}
      </Box>
    </ErrorBoundary>
  )
}

export default CalendarContainer
