import * as stateUtils from '../../utils/state'
import { HashMap } from '../items/types'
import produce from 'immer'
import { ClientQuestionnaireAction, ClientQuestionnaireActions } from './actions'
import { LoadedLoadingErrorState } from '../../utils/state'
import { Questionnaire } from '../orders/questionnaire/types'

export interface ClientQuestionnaireLoadedLoadingState {
  individual: HashMap<LoadedLoadingErrorState>
}

export interface ClientQuestionnaireState {
  meta: ClientQuestionnaireLoadedLoadingState
  questionnaires: HashMap<Questionnaire>
}

export const initialState: ClientQuestionnaireState = {
  questionnaires: {},
  meta: {
    individual: {
      ...stateUtils.initialMultiLoadedLoadingErrorState.individual,
    },
  },
}

export default function clientQuestionnaireReduceer(
  state: ClientQuestionnaireState = initialState,
  action: ClientQuestionnaireAction
) {
  return produce(state, (draft: ClientQuestionnaireState) => {
    switch (action.type) {
      case ClientQuestionnaireActions.FETCH_CLIENT_QUESTIONNAIRE:
        {
          const clientID = action.clientID

          draft.meta.individual[clientID] = stateUtils.setStartState()
        }
        break

      case ClientQuestionnaireActions.FETCH_CLIENT_QUESTIONNAIRE_SUCCESS:
        {
          const response = action.payload.data
          const clientID = action.meta.previousAction.clientID

          draft.meta.individual[clientID] = stateUtils.setSuccessState()

          if (!!response) {
            draft.questionnaires[clientID] = response
          }
        }
        break

      case ClientQuestionnaireActions.FETCH_CLIENT_QUESTIONNAIRE_FAIL:
        {
          const clientID = action.meta.previousAction.clientID
          const status = !!action.error.response ? action.error.response.status : -1
          if (status === 404) {
            draft.meta.individual[clientID] = stateUtils.setSuccessState()
          } else {
            draft.meta.individual[clientID] = stateUtils.setErrorState(state.meta.individual[clientID], action.error)
          }
        }
        break

      case ClientQuestionnaireActions.UPDATE_QUESTIONNAIRE_SUCCESS:
        {
          const clientID = action.meta.previousAction.clientID
          const name = action.meta.previousAction.questionName
          const data = action.meta.previousAction.payload.request.data

          draft.questionnaires[clientID].questions = {
            ...(draft.questionnaires[clientID].questions || {}),
            [name]: {
              ...(draft.questionnaires[clientID].questions?.[name] || {}),
              answers: [
                {
                  ...(draft.questionnaires[clientID].questions?.[name]?.answers?.[0] || {}),
                  answer: data.answer,
                },
              ],
            },
          }
        }
        break
    }
  })
}
