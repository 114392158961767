import React from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import AutoQASection from './AutoQASection'
import UploadStyles from './styles'
import { FeedbackBoolPayload } from '../../store/featureFeedback/types'
import isEqual from 'lodash/isEqual'
import { AutoQASectionData } from '../../selectors/autoQA'
import { HashMap } from '../../utils/HashMap'

interface Props {
  results: AutoQASectionData[]
  updateFeedback?: (payload: FeedbackBoolPayload) => void
  feedback?: HashMap<HashMap<FeedbackBoolPayload>>
  onClose?: () => void
  theme?: 'regular' | 'inverted'
}

const useStyles = makeStyles(() =>
  createStyles({
    closeButton: {
      position: 'relative',
      backgroundColor: UploadStyles.lightPurple.hex,
      color: UploadStyles.darkBlue.hex,
      width: '70px',
      left: '50%',
      top: '57px',

      '&:hover, &:active': {
        backgroundColor: UploadStyles.lightPurple.hex,
      },
    },
  })
)

const AutoQAResults: React.FC<Props> = ({ theme, feedback, results, onClose, updateFeedback }) => {
  const classes = useStyles()

  return (
    <Box>
      {onClose && (
        <IconButton onClick={onClose} className={classes.closeButton} disableRipple>
          <ExpandLessIcon fontSize="large" />
        </IconButton>
      )}
      {results.map(r => (
        <AutoQASection
          theme={theme}
          feedback={feedback}
          key={`auto_qa_section_${r.title}`}
          score={r.score}
          updateFeedback={updateFeedback}
          title={r.title}
          content={r.content}
          count={r.count}
        />
      ))}
    </Box>
  )
}

const memoizedAutoQAResults = React.memo(AutoQAResults, isEqual)
// @ts-ignore
memoizedAutoQAResults.whyDidYouRender = true

export default memoizedAutoQAResults
