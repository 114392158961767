import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import './index.css'
import App from './components/App'
import './i18n'
import configureStore, { history } from './store'
import ErrorBoundary from './components/common/ErrorBoundary'

if (process.env.NODE_ENV !== 'production') {
  /* eslint-disable-next-line @typescript-eslint/no-var-requires */
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React)
}

const router = (
  <ErrorBoundary>
    <Provider store={configureStore()}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>
)

ReactDOM.render(router, document.getElementById('root'))
