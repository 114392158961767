import React from 'react'
import { ReactIntercom } from 'react-intercom'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { ConfigState } from '../../store/config/types'

export const useIntercomTracking = (name: string) => {
  const [hasTracked, setHasTracked] = React.useState(false)
  const trackEvent = React.useCallback(
    (metadata: object) => {
      if (!window.Intercom || hasTracked) {
        return
      }

      window.Intercom('trackEvent', name, metadata)
      setHasTracked(true)
    },
    [hasTracked, setHasTracked, name]
  )

  return trackEvent
}

export const IntercomContainer: React.FC = () => {
  const { config } = useSelector<AppState, ConfigState>(state => state.configReducer)

  if (!config) {
    return null
  }

  return <ReactIntercom {...config.intercom} />
}

export default React.memo(IntercomContainer)
