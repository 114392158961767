import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import { userId } from '../user/reducers'
import { UserDashboardEarnings, UserDashboardMacro, UserDashboardRelative, UserTopIndustries } from './types'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AppState } from '../index'
import { AnyAction } from 'redux'

export enum DASHBOARD {
  FETCH_MACRO_STATS = 'FETCH_MACRO_STATS',
  FETCH_MACRO_STATS_SUCCESS = 'FETCH_MACRO_STATS_SUCCESS',
  FETCH_MACRO_STATS_FAIL = 'FETCH_MACRO_STATS_FAIL',

  FETCH_RELATIVE_STATS = 'FETCH_RELATIVE_STATS',
  FETCH_RELATIVE_STATS_SUCCESS = 'FETCH_RELATIVE_STATS_SUCCESS',
  FETCH_RELATIVE_STATS_FAIL = 'FETCH_RELATIVE_STATS_FAIL',

  FETCH_EARNINGS_STATS = 'FETCH_EARNINGS_STATS',
  FETCH_EARNINGS_STATS_SUCCESS = 'FETCH_EARNINGS_STATS_SUCCESS',
  FETCH_EARNINGS_STATS_FAIL = 'FETCH_EARNINGS_STATS_FAIL',

  FETCH_INDUSTRIES_STATS = 'FETCH_INDUSTRIES_STATS',
  FETCH_INDUSTRIES_STATS_SUCCESS = 'FETCH_INDUSTRIES_STATS_SUCCESS',
  FETCH_INDUSTRIES_STATS_FAIL = 'FETCH_INDUSTRIES_STATS_FAIL',
}

export interface FetchMacroStats extends AxiosMiddlewareActionCreator {
  type: typeof DASHBOARD.FETCH_MACRO_STATS
  targetUserID: userId
}

export interface FetchMacroStatsSuccess extends AxiosMiddlewareActionSuccess<UserDashboardMacro, FetchMacroStats> {
  type: typeof DASHBOARD.FETCH_MACRO_STATS_SUCCESS
}

export interface FetchMacroStatsFailure extends AxiosMiddlewareActionFail<FetchMacroStats> {
  type: typeof DASHBOARD.FETCH_MACRO_STATS_FAIL
}

export interface FetchRelativeStats extends AxiosMiddlewareActionCreator {
  type: typeof DASHBOARD.FETCH_RELATIVE_STATS
  targetUserID: userId
}

export interface FetchRelativeStatsSuccess
  extends AxiosMiddlewareActionSuccess<UserDashboardRelative, FetchRelativeStats> {
  type: typeof DASHBOARD.FETCH_RELATIVE_STATS_SUCCESS
}

export interface FetchRelativeStatsFailure extends AxiosMiddlewareActionFail<FetchRelativeStats> {
  type: typeof DASHBOARD.FETCH_RELATIVE_STATS_FAIL
}

export interface FetchEarningsStats extends AxiosMiddlewareActionCreator {
  type: typeof DASHBOARD.FETCH_EARNINGS_STATS
  targetUserID: userId
}

export interface FetchEarningsStatsSuccess
  extends AxiosMiddlewareActionSuccess<UserDashboardEarnings, FetchEarningsStats> {
  type: typeof DASHBOARD.FETCH_EARNINGS_STATS_SUCCESS
}

export interface FetchEarningsStatsFailure extends AxiosMiddlewareActionFail<FetchEarningsStats> {
  type: typeof DASHBOARD.FETCH_EARNINGS_STATS_FAIL
}

export interface FetchIndustriesStats extends AxiosMiddlewareActionCreator {
  type: typeof DASHBOARD.FETCH_INDUSTRIES_STATS
  targetUserID: userId
}

export interface FetchIndustriesSuccess extends AxiosMiddlewareActionSuccess<UserTopIndustries, FetchIndustriesStats> {
  type: typeof DASHBOARD.FETCH_INDUSTRIES_STATS_SUCCESS
}

export interface FetchIndustriesFailure extends AxiosMiddlewareActionFail<FetchIndustriesStats> {
  type: typeof DASHBOARD.FETCH_INDUSTRIES_STATS_FAIL
}

export function fetchMacroStats(userID: userId = 'me'): FetchMacroStats {
  return {
    type: DASHBOARD.FETCH_MACRO_STATS,
    targetUserID: userID,
    payload: {
      request: {
        url: `/v1/dashboard/${userID}/macro`,
      },
    },
  }
}

export function fetchRelativeStats(userID: userId = 'me'): FetchRelativeStats {
  return {
    type: DASHBOARD.FETCH_RELATIVE_STATS,
    targetUserID: userID,
    payload: {
      request: {
        url: `/v1/dashboard/${userID}/relative`,
      },
    },
  }
}

export function fetchEarningsStats(userID: userId = 'me'): FetchEarningsStats {
  return {
    type: DASHBOARD.FETCH_EARNINGS_STATS,
    targetUserID: userID,
    payload: {
      request: {
        url: `/v1/dashboard/${userID}/earnings`,
      },
    },
  }
}

export function fetchIndustriesStats(userID: userId = 'me'): FetchIndustriesStats {
  return {
    type: DASHBOARD.FETCH_INDUSTRIES_STATS,
    targetUserID: userID,
    payload: {
      request: {
        url: `/v1/dashboard/${userID}/industries`,
      },
    },
  }
}

type LoadDashboard = FetchIndustriesStats | FetchRelativeStats | FetchMacroStats | FetchEarningsStats

export function loadDashboard(
  userID: number,
  isOffshore: boolean
): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    let loadDashboardActions: LoadDashboard[] = [fetchMacroStats(userID), fetchRelativeStats(userID)]

    if (!isOffshore) {
      loadDashboardActions = loadDashboardActions.concat([fetchIndustriesStats(userID), fetchEarningsStats(userID)])
    }

    loadDashboardActions.map(action => dispatch(action))
  }
}

export type DashboardActionTypes =
  | FetchMacroStats
  | FetchMacroStatsSuccess
  | FetchMacroStatsFailure
  | FetchRelativeStats
  | FetchRelativeStatsSuccess
  | FetchRelativeStatsFailure
  | FetchIndustriesStats
  | FetchIndustriesSuccess
  | FetchIndustriesFailure
  | FetchEarningsStats
  | FetchEarningsStatsSuccess
  | FetchEarningsStatsFailure
