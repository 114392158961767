import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import DashboardStyles from './Styles'

interface Props {
  emoji: string
  emojiLabel: string
  heading: string
  subtitle: string
}

const useStyles = makeStyles(theme =>
  createStyles({
    empty: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      height: '86%',
    },
    h3: {
      fontSize: DashboardStyles.header3.size,
    },
    h4: {
      fontSize: DashboardStyles.header2.size,
      fontWeight: DashboardStyles.header2.weight,
      margin: DashboardStyles.header2.margin,
    },
    emoji: {
      fontSize: DashboardStyles.emoji.size,
      margin: DashboardStyles.emoji.margin,
    },
  })
)

const EmptyActivityCard: React.FC<Props> = ({ emoji, emojiLabel, heading, subtitle }) => {
  const classes = useStyles()

  return (
    <Box className={classes.empty}>
      <Typography className={classes.emoji}>
        <span role="img" aria-label={emojiLabel}>
          {emoji}
        </span>
      </Typography>
      <Typography className={classes.h4} variant="h4">
        {heading}
      </Typography>
      <Typography className={classes.h3} variant="h3">
        {subtitle}
      </Typography>
    </Box>
  )
}

export default EmptyActivityCard
