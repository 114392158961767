import React from 'react'
import { useSelector } from 'react-redux'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import LinkedInPreview from '@talentinc/linkedin-preview'
import { PersonalData } from '@talentinc/linkedin-preview/lib/PersonalInfoSection'

import i18n from '../../i18n'
import styles from '../../styles'
import { AppState } from '../../store'
import { LoadedLoadingErrorState } from '../../utils/state'
import { AutoLinkedin, Sections, Summary } from '../../store/autolinkedin/types'
import {
  getAutoLinkedinByItemID,
  getAutoLinkedinParsedSections,
  getAutoLinkedinPublishMeta,
  getAutoLinkedinSummary,
  getPersonalInfo,
} from '../../store/autolinkedin/selectors'
import BackToTop from '../common/BackToTop'
import Button from '../common/Button'
import Footer from '../common/Footer'
import Header from '../common/Header'
import ModalWrapper from '../common/ModalWrapper'
import NavigationBar from './NavigationBar'

interface Props {
  handleBack: () => void
  handleClose: () => void
  handleNext: (autoLinkID: number | undefined) => Promise<void>
  linkedinItemID: number
}

const useStyles = makeStyles(() =>
  createStyles({
    cancelButton: {
      marginRight: '1.2rem',
      padding: '.5rem 1.547rem',
    },
    icon: {
      cursor: 'pointer',
      display: 'inline-block',
      marginBottom: '-.2em',
    },
    iconText: {
      cursor: 'pointer',
      color: styles.palette.darkerBlue.color.hex,
      fontSize: '15px',
      fontWeight: 500,
    },
    navigationBox: {
      position: 'fixed',
      top: '13em',
      width: '17em',
    },
    preview: {
      marginLeft: '20em !important',
      marginTop: '1.5em',
    },
    submitButton: {
      padding: '.5rem 1.547rem',
    },
    formUpdateStatus: {
      justifyContent: 'center',
      display: 'flex',
      width: '100%',
      margin: 'auto',
    },
    formUpdateStatusText: {
      fontStyle: 'italic',
      fontSize: '.95rem',
    },
    errorBox: {
      color: styles.palette.pink.hex,
      '& > svg': {
        verticalAlign: 'text-bottom',
        marginRight: '.5rem',
      },
    },
    errorBoxText: {
      fontSize: '.95rem',
    },
  })
)

export const LinkedInPreviewWrapper: React.FC<Props> = ({ handleBack, handleNext, handleClose, linkedinItemID }) => {
  const classes = useStyles()

  const autoLinkedin = useSelector<AppState, AutoLinkedin | undefined | null>(state =>
    getAutoLinkedinByItemID(state.autolinkedinReducer, linkedinItemID)
  )
  const meta = useSelector<AppState, LoadedLoadingErrorState | undefined>(state =>
    getAutoLinkedinPublishMeta(state.autolinkedinReducer, linkedinItemID)
  )
  const autoLinkedinSections = useSelector<AppState, Sections[]>(state =>
    getAutoLinkedinParsedSections(state.autolinkedinReducer, linkedinItemID)
  )
  const autoLinkedinSummary = useSelector<AppState, Summary | null | undefined>(state =>
    getAutoLinkedinSummary(state.autolinkedinReducer, linkedinItemID)
  )

  const personalData = useSelector<AppState, PersonalData | null>(state =>
    getPersonalInfo(state.autolinkedinReducer, linkedinItemID)
  )

  const publishMeta = useSelector<AppState, LoadedLoadingErrorState | undefined>(state =>
    getAutoLinkedinPublishMeta(state.autolinkedinReducer, linkedinItemID)
  )

  return (
    <ModalWrapper>
      <Header
        activeStep={1}
        closeButton={true}
        handleClose={handleClose}
        title={i18n.t('alink__Preview__title')}
        transparent={true}
      />
      <Box>
        <Box className={classes.navigationBox}>
          <NavigationBar sections={autoLinkedinSections} greyOutEmpty={true} />
        </Box>
        <Box className={classes.preview}>
          <LinkedInPreview sections={autoLinkedinSections} summary={autoLinkedinSummary} personal={personalData} />
        </Box>
      </Box>
      <BackToTop topElement="header" />
      <Footer
        leftColumn={
          <Button type="secondary" className={classes.cancelButton} onClick={handleBack} id="preview-li-back">
            {i18n.t('alink__Preview__back')}
          </Button>
        }
        rightColumn={
          <Button
            type="contrast"
            showLoader={meta?.isLoading}
            disabled={meta?.isLoading}
            className={classes.submitButton}
            onClick={() => handleNext(autoLinkedin?.id)}
            id="preview-li-attach"
          >
            {i18n.t('alink__Preview__next')}
          </Button>
        }
      >
        <Box className={classes.formUpdateStatus}>
          {publishMeta?.isLoading && (
            <Typography className={classes.formUpdateStatusText}>
              {i18n.t('alink__Preview__next__publishing')}
            </Typography>
          )}
          {publishMeta?.loaded && !!publishMeta?.error && (
            <Box className={classes.errorBox}>
              <WarningIcon />
              <Typography className={classes.errorBoxText} component="span">
                {i18n.t('alink__publishError')}
              </Typography>
            </Box>
          )}
        </Box>
      </Footer>
    </ModalWrapper>
  )
}

export default LinkedInPreviewWrapper
