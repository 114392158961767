import React from 'react'
import { useSelector } from 'react-redux'

import i18n from '../../../i18n'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { formatDate } from '../../../utils/formatting'
import OrderActionContainer from '../../Orders/OrderActionContainer'
import OrderItems from '../../Orders/OrderItems'
import { IconButton } from '@material-ui/core'
import { ArrowLeftIcon } from '@material-ui/pickers/_shared/icons/ArrowLeftIcon'
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon'
import useUserInfoState from '../../common/useUserInfo'
import { ClientOrdersDisplayAggregate } from '../../../selectors/clientOrderAggregates'
import { AppState } from '../../../store'
import { getDocumentUploadsForClient } from '../../../selectors/documents'
import { Document, DocumentTypes } from '../../../store/documents/types'

interface OrderSummaryProps {
  clientID: number
  hasMoreThanOneGroup: boolean
  currentOrderGroup: ClientOrdersDisplayAggregate
  moveForward: () => void
  moveBackwards: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: 'white',
      border: '1px solid #D5D9E0',
      borderRadius: '.1875rem',
      color: 'black',
      '@media (max-width: 1024px)': {
        border: 'none',
        borderBottom: 'none',
        marginBottom: '0 !important',
        width: '75%',
      },
      '@media (max-width: 560px)': {
        width: '100%',
      },
    },
    titleContainer: {
      display: 'flex',
    },
    assignedWriterContainer: {
      paddingTop: '0.5rem',
    },
    headerContainer: {
      display: 'block',
      padding: '.9375rem 1.5rem',
      borderBottom: '1px solid #D5D9E0',
      '@media (max-width: 1024px)': {
        display: 'none',
      },
    },
    title: {
      display: 'inline-flex',
      justifyContent: 'center',
      flexDirection: 'column',
      flexGrow: 1,
      fontWeight: 600,
    },
    arrows: {
      display: 'inline-flex',
      lineHeight: 0,
      marginRight: '-.5rem',
      '& > *': {
        padding: 0,
        width: '1.375rem',
        height: '1.375rem',
      },
    },
    itemsContainer: {
      padding: '.9375rem 1.5rem',
      borderBottom: '1px solid #D5D9E0',
      '@media (max-width: 1024px)': {
        borderBottom: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '.9375rem 1rem',
      },
      '@media (max-width: 560px)': {
        flexDirection: 'column',
        paddingBottom: '0',
      },
    },
    itemsSubtitle: {
      marginBottom: '.75rem',
      fontSize: '.8125rem',
      lineHeight: '1.125rem',
      '@media (max-width: 1024px)': {
        fontSize: '.875rem',
        fontWeight: 'bold',
      },
    },
    items: {
      color: '#626262',
      '& > *': { flexWrap: 'wrap' },
      '& > * > *': { margin: '.1rem .35rem .1rem 0' },
      '@media (max-width: 1024px)': {
        marginRight: '0',
        '& > * > *': { margin: '0' },
      },
    },
    sendDraft: {
      width: '100%',
      marginTop: '2rem',
      marginBottom: '.4375rem',
      '@media (max-width: 1024px)': {
        margin: '0',
        width: '35%',
      },
      '@media (max-width: 560px)': {
        marginBottom: '1rem',
        width: '100%',
      },
    },
    sendDraftSubtitle: {
      display: 'none',
      marginBottom: '.9rem',
      fontSize: '.8125rem',
      lineHeight: '1.125rem',
      '@media (max-width: 1024px)': {
        display: 'block',
        fontSize: '.875rem',
        fontWeight: 'bold',
      },
    },
    ordersContainer: {
      '@media (max-width: 1024px)': {
        width: '55%',
      },
      '@media (max-width: 560px)': {
        marginBottom: '1rem',
        width: '100%',
      },
    },
    paymentContainer: {
      display: 'flex',
    },
    paymentSubtitle: {
      marginBottom: '.25rem',
      fontSize: '.8125rem',
      lineHeight: '1.125rem',
      whiteSpace: 'nowrap',
    },
    paymentLeft: {
      display: 'inline-flex',
      flexDirection: 'column',
      width: '50%',
      padding: '.9375rem 1.5rem',
      borderRight: '1px solid #D5D9E0',
    },
    paymentRight: {
      display: 'inline-flex',
      flexDirection: 'column',
      width: '50%',
      padding: '.9375rem 1.5rem',
    },
    paymentText: {
      fontSize: '1.25rem',
      lineHeight: '1.6875rem',
    },
  })
)

const OrderSummary: React.FC<OrderSummaryProps> = ({
  clientID,
  hasMoreThanOneGroup,
  currentOrderGroup,
  moveForward,
  moveBackwards,
}) => {
  const classes = useStyles()
  const { shouldSeeBasePay, timeZone, locale, isAdmin } = useUserInfoState()

  const dueDateString = currentOrderGroup.macroState.dueDate
  const dueDate = !!dueDateString ? new Date(dueDateString) : null
  const assignedDateString = !!currentOrderGroup.lastAssignedDate
    ? formatDate(currentOrderGroup.lastAssignedDate, timeZone, locale, 'MM/dd/yy hh:mm a')
    : ''

  const writerUploads = useSelector<AppState, Document[]>(store =>
    getDocumentUploadsForClient(store.documentReducer, clientID)
  )

  const hasResumeEditDoc = writerUploads.some(doc => doc.type === DocumentTypes.ResumeEdit)

  return (
    <Box className={classes.root}>
      <Box className={classes.headerContainer}>
        <Box className={classes.titleContainer}>
          <Typography variant={'body2'} className={classes.title}>
            {isAdmin
              ? i18n.t('messaging__side__order__admin', {
                  orderID: currentOrderGroup.orderIDs.join(', '),
                  dueDate: dueDate ? `(${formatDate(dueDate, timeZone, locale, 'MM/dd/yy')})` : '',
                })
              : i18n.t('messaging__side__order__user')}
          </Typography>
          {hasMoreThanOneGroup && (
            <Box className={classes.arrows}>
              <IconButton onClick={moveBackwards}>
                <ArrowLeftIcon />
              </IconButton>
              <IconButton onClick={moveForward}>
                <ArrowRightIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        {isAdmin && !!currentOrderGroup.assignedWriter && (
          <Box className={classes.assignedWriterContainer}>
            <Typography variant={'body2'} className={classes.title}>
              {i18n.t('messaging__side__order__assigned_writer', {
                uname: currentOrderGroup.assignedWriter,
                assignedDate: assignedDateString,
              })}
            </Typography>
          </Box>
        )}
      </Box>

      <Box className={classes.itemsContainer}>
        <Box className={classes.ordersContainer}>
          <Typography variant={'body2'} className={classes.itemsSubtitle}>
            {i18n.t('messaging__side__orderItems')}
          </Typography>
          <Box className={classes.items}>
            <OrderItems
              stackItems={currentOrderGroup.isOpen}
              items={currentOrderGroup.items}
              shouldSeeInternalIncentiveNames={isAdmin}
              shouldRenderBasePay={shouldSeeBasePay}
              shouldApplyColors={true}
              shouldRenderHistory={true}
            />
          </Box>
        </Box>
        <Box className={classes.sendDraft}>
          <Typography variant={'body2'} className={classes.sendDraftSubtitle}>
            {i18n.t('messaging__side__nextStep')}
          </Typography>
          <OrderActionContainer
            macroState={currentOrderGroup.macroState}
            size="full"
            clientID={clientID}
            hasResumeEditDoc={hasResumeEditDoc}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default OrderSummary
