import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { sendUserEvent } from '../../store/events/actions'
import { UserEvent, EventTypes } from '../../store/events/types'

const useEvents = () => {
  const dispatch = useDispatch()
  const sendEvent = useCallback(
    (event: UserEvent) => dispatch(sendUserEvent(event)),
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    []
  )

  return {
    eventTypes: EventTypes,
    sendEvent,
  }
}

export default useEvents
