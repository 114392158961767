import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import omit from 'lodash/omit'

import { AppState } from '..'
import { CurrentMessageUpdatePayload } from './types'
import { draftjsEditorStateToHTML } from '../../utils/draftjs'
import { renderEmail } from '../../utils/handlebars'
import {
  defaultReplyTemplateSelector,
  defaultTemplateSelector,
  handlebarsTemplateContextSelector,
} from '../../selectors/templates'
import { getLatestMessageByDirection } from '../../selectors/messages'
import {
  Message,
  MessageContext,
  MessageContexts,
  MessageSendingMeta,
  OutgoingMessage,
  ScanVirusRequest,
  ScanVirusResponse,
} from '../messages/types'
import { sendMessage, startSendingMessage, scanForViruses } from '../messages/actions'
import { fetchClientOrderDetailsThunk } from '../clientOrderAggregate/actions'
import { applyTemplateToCurrentMessage } from '../templates/actions'
import { getEmailAddressesForContext } from '../../selectors/context'
import { AppliedDeliveryIncentive, DraftEventVars } from '../events/types'
import { addNudge, updateNudgeTrigger } from '../nudges/actions'
import { formatCurrency } from '../../utils/formatting'
import { NudgeTypes, NudgeVariables } from '../nudges/types'
import { UserInfo } from '../user/reducers'
import { Routes } from '../../utils/consts'
import { getFeatureFlags } from '../../selectors/featureFlags'
import { getAttachmentsForCurrentMessage } from '../../selectors/documents'
import { DeleteDocSuccess } from '../documents/actions'
import { clientIDCanGetDocsInEmail } from '../../selectors/clients'

export enum CurrentMessageActionTypes {
  CURRENT_MESSAGE_RESET = 'CURRENT_MESSAGE_RESET',
  CURRENT_MESSAGE_UPDATE = 'CURRENT_MESSAGE_UPDATE',
  META_RESET = 'META_RESET',
}

export interface CurrentMessageReset {
  type: typeof CurrentMessageActionTypes.CURRENT_MESSAGE_RESET
}

export function currentMessageReset(): CurrentMessageReset {
  return {
    type: CurrentMessageActionTypes.CURRENT_MESSAGE_RESET,
  }
}

export interface CurrentMessageUpdate {
  type: typeof CurrentMessageActionTypes.CURRENT_MESSAGE_UPDATE
  messageUpdate: CurrentMessageUpdatePayload
}

export function currentMessageUpdate(messageUpdate: CurrentMessageUpdatePayload): CurrentMessageUpdate {
  return {
    type: CurrentMessageActionTypes.CURRENT_MESSAGE_UPDATE,
    messageUpdate,
  }
}

export function currentMessageSend(
  context: MessageContext,
  contextID: number,
  orderID: number | null = null,
  userID: number | null = null,
  onSuccessfulMessageSend?: (draftMeta: DraftEventVars) => void
): ThunkAction<Promise<number | null>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState): Promise<number | null> {
    const state = getState()

    // In case of bad connection the button doesn't get disabled fast enough to prevent smashing
    dispatch(startSendingMessage())
    const currentMessage = state.currentMessageReducer
    const currentAttachments = getAttachmentsForCurrentMessage(
      state.documentReducer,
      context,
      contextID,
      currentMessage.attachment_ids
    )
    const handlebarsContext = handlebarsTemplateContextSelector(
      state,
      context,
      contextID,
      userID,
      currentAttachments,
      orderID
    )

    const msg: OutgoingMessage = omit(currentMessage, [
      'editorState',
      'replyActualMessageID',
      'client_id',
    ]) as OutgoingMessage

    if (context === MessageContexts.CLIENT && !clientIDCanGetDocsInEmail(state.clientReducer, contextID)) {
      msg.omit_attachments_in_email = true
      msg.variables.attachments_omitted_in_email = true
    }

    // We have to convert the editorState to HTML
    msg.message_text = draftjsEditorStateToHTML(currentMessage.editorState, { disableLinks: false })

    // Render the HTML message
    msg.body = await renderEmail(handlebarsContext, currentMessage)

    await dispatch(sendMessage(context, contextID, msg))

    const reduxState = getState()

    const messageSendMeta = reduxState.messagesReducer.sendingMeta
    const eventData = messageSendMeta.draftEventMeta
    const user = state.userReducer.getLoggedInUser()

    if (!messageSendMeta.error && messageSendMeta.shoppingEligibility && !!eventData && !!user) {
      const clientName =
        context === MessageContexts.CLIENT ? reduxState.clientReducer.clients[contextID].full_name : null

      if (clientName) {
        sendNudges(messageSendMeta, user, clientName, dispatch)
      }

      const { showNewOrderNudges } = getFeatureFlags(getState().userReducer.getLoggedInUser())
      if (showNewOrderNudges) {
        // Update WIP limit upon first draft submission
        updateNudgeTrigger(messageSendMeta.shoppingEligibility, user.id)
      }

      if (!!onSuccessfulMessageSend && messageSendMeta.draftEventMeta) {
        onSuccessfulMessageSend(messageSendMeta.draftEventMeta)
      }
    }
    // @ts-ignore
    dispatch(fetchClientOrderDetailsThunk(contextID, { verbose: true }))

    const latestMessage = getLatestMessageByDirection(getState().messagesReducer, context, contextID, true)

    if (!latestMessage) {
      return null
    }

    return latestMessage.id
  }
}

const sendNudges = (
  messageSendMeta: MessageSendingMeta,
  user: UserInfo,
  clientName: string,
  dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
  const { draftEventMeta, shoppingEligibility } = messageSendMeta

  if (!draftEventMeta || !shoppingEligibility) {
    return
  }

  // defaults
  let copyKey = 'nudge__message_sent'
  const variables: NudgeVariables = {
    client_name: clientName,
  }

  if (draftEventMeta.applied_incentives && draftEventMeta.applied_incentives.length > 0) {
    const earnedAmount = draftEventMeta.applied_incentives
      .map((x: AppliedDeliveryIncentive) => x.amount)
      .reduce((sum: number, amount: number) => sum + amount, 0)

    const description = draftEventMeta.applied_incentives
      .map((x: AppliedDeliveryIncentive) => x.description)
      .join(' & ')
    if (earnedAmount && description) {
      copyKey = shoppingEligibility.eligible_for_offers
        ? 'nudge__incentive_earned__eligible'
        : 'nudge__incentive_earned'

      variables.incentiveTotalEarned = formatCurrency(earnedAmount, user.payment_currency)
      variables.incentiveEarnedTypes = description
    }
  } else if (draftEventMeta.is_first_draft) {
    copyKey = shoppingEligibility.eligible_for_offers ? 'nudge__first_draft_sent__eligible' : 'nudge__first_draft_sent'
  } else if (draftEventMeta.client_fulfilled) {
    copyKey = shoppingEligibility.eligible_for_offers ? 'nudge__order_completed__eligible' : 'nudge__order_completed'
  } else if (!draftEventMeta.client_fulfilled && draftEventMeta.fulfilled_count > 0) {
    copyKey = 'nudge__items_complete'
  }

  if (
    shoppingEligibility.eligible_for_offers &&
    (draftEventMeta.client_fulfilled || draftEventMeta.is_first_draft || draftEventMeta.applied_incentives)
  ) {
    variables.link = Routes.NewOrder
    variables.worksInProgress = shoppingEligibility.works_in_progress
    variables.wipLimit = shoppingEligibility.wip_limit
  }

  dispatch(
    addNudge({
      type: NudgeTypes.MessageSent,
      copyKey,
      variables,
    })
  )
}

export function currentMessageSetReply(
  message: Message | null
): ThunkAction<Promise<CurrentMessageUpdate>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    const updatePayload: CurrentMessageUpdatePayload = {
      replyActualMessageID: null,
      reply_message_id: null,
    }

    if (!!message) {
      updatePayload.subject = `Re: ${message.subject}`
      updatePayload.reply_message_id = message.message_id
      updatePayload.replyActualMessageID = message.id

      const state = getState()
      const handlebarsContext = handlebarsTemplateContextSelector(
        state,
        message.context,
        message.context_id,
        state.userReducer.loggedInUserID,
        []
      )
      const replyTemplate = defaultReplyTemplateSelector(
        state.templatesReducer,
        handlebarsContext.order ? handlebarsContext.order.language : 'en'
      )

      if (replyTemplate) {
        dispatch(applyTemplateToCurrentMessage(replyTemplate, handlebarsContext))
      }
    }

    return dispatch(currentMessageUpdate(updatePayload))
  }
}

export function replyToLatestMessage(
  context: MessageContext,
  contextID: number
): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    const lastIncomingMessage = getLatestMessageByDirection(getState().messagesReducer, context, contextID, false)
    const { replyActualMessageID } = getState().currentMessageReducer
    dispatch(
      // @ts-ignore
      currentMessageSetReply(
        lastIncomingMessage && lastIncomingMessage.id !== replyActualMessageID ? lastIncomingMessage : null
      )
    )
  }
}

export function addAttachmentsToMessage(attachmentIDs: number[]): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    const newAttachmentIDs = new Set([...getState().currentMessageReducer.attachment_ids, ...attachmentIDs])
    dispatch(
      currentMessageUpdate({
        attachment_ids: [...newAttachmentIDs],
      })
    )
  }
}

export function fillInEmptyCurrentMessage(
  context: MessageContext,
  contextID: number,
  orderID?: number | null,
  userID?: number | null
) {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    const state = getState()
    const [recpientEmail, senderEmail] = getEmailAddressesForContext(state, context, contextID, orderID)
    const handlebarsContext = handlebarsTemplateContextSelector(
      state,
      context,
      contextID,
      userID || state.userReducer.loggedInUserID,
      [],
      orderID
    )

    if (!state.currentMessageReducer.editorState.getCurrentContent().hasText()) {
      const defaultMessageTemplate = defaultTemplateSelector(
        state.templatesReducer,
        handlebarsContext.order ? handlebarsContext.order.language : 'en'
      )

      if (defaultMessageTemplate) {
        dispatch(applyTemplateToCurrentMessage(defaultMessageTemplate, handlebarsContext))
      }
    }

    return dispatch(
      currentMessageUpdate({
        recipients: [recpientEmail],
        bcc_email: handlebarsContext.client?.bcc_email,
        sender: senderEmail,
        client_id: contextID,
      })
    )
  }
}

export function malwareScan(
  text: ScanVirusRequest
): ThunkAction<Promise<ScanVirusResponse | null>, AppState, {}, AnyAction> {
  return async function(
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => AppState
  ): Promise<ScanVirusResponse | null> {
    // If we are currently sending, do not attempt to send again. The cost of double sending a message is far too high.
    if (getState().messagesReducer.sendingMeta.isLoading) {
      return null
    }

    const normalized: string = text.text.replace(/[\r\n]+/g, ' ')
    const request: ScanVirusRequest = { text: normalized }

    await dispatch(scanForViruses(request))
    return getState().messagesReducer.malwareMeta
  }
}

export type CurrentMessageActionType = CurrentMessageUpdate | CurrentMessageReset | DeleteDocSuccess
