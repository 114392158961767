import { useSelector } from 'react-redux'
import enUS from 'date-fns/locale/en-US'
import enGB from 'date-fns/locale/en-GB'
import deDE from 'date-fns/locale/de'

import { AppState } from '../../store'
import { Groups, TagsEditor, TagsNewHire, TagsOnboardingMentee, UserInfo } from '../../store/user/reducers'
import { Currency, getLocale } from '../../utils/formatting'
import getFeatureFlags, { FeatureFlags } from '../../selectors/featureFlags'

//@ts-ignore
enUS.code = 'en-US'
//@ts-ignore
enGB.code = 'en-GB'
//@ts-ignore
deDE.code = 'de-DE'

interface UseUserInfo {
  id: number
  isAdmin: boolean
  isExpertMentee: boolean
  isEditor: boolean
  isNewHire: boolean
  isOffshore: boolean
  isDeveloper: boolean
  shouldSeeBasePay: boolean
  timeZone: string
  locale: Locale
  currency: Currency
  featureFlags: FeatureFlags
}

export const useUserInfoState = (): UseUserInfo => {
  const user = useSelector<AppState, UserInfo | null>(state => state.userReducer.getLoggedInUser())

  return {
    id: !!user ? user.id : 0,
    isAdmin: !!user && (user.groups.includes(Groups.Admin) || user.groups.includes(Groups.FCC_Admin)),
    shouldSeeBasePay: !user || !user.groups.includes(Groups.Offshore),
    isExpertMentee: !!user && user.tag_keys.includes(TagsOnboardingMentee),
    isDeveloper: !!user && user.groups.includes(Groups.Developer),
    isNewHire: !!user && user.tag_keys.includes(TagsNewHire),
    isOffshore: !!user && user.groups.includes(Groups.Offshore),
    timeZone: !user ? 'America/New_York' : user.timezone_string,
    locale: getLocale(user),
    currency: !!user ? user.payment_currency : 'USD',
    featureFlags: getFeatureFlags(user),
    isEditor: !!user && user.tag_keys.includes(TagsEditor),
  }
}

export default useUserInfoState
