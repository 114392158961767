import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import { userId } from '../user/reducers'
import { UserOnboarding, UserOnboardingData } from './reducer'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AppState } from '../index'
import { AnyAction } from 'redux'
import { fetchUserPreferences } from '../user_preferences/actions'

export enum ONBOARDING {
  FETCH_USER_ONBOARDING = 'FETCH_USER_ONBOARDING',
  FETCH_USER_ONBOARDING_SUCCESS = 'FETCH_USER_ONBOARDING_SUCCESS',
  FETCH_USER_ONBOARDING_FAIL = 'FETCH_USER_ONBOARDING_FAIL',

  UPDATE_USER_ONBOARDING = 'UPDATE_USER_ONBOARDING',
  UPDATE_USER_ONBOARDING_SUCCESS = 'UPDATE_USER_ONBOARDING_SUCCESS',
  UPDATE_USER_ONBOARDING_FAIL = 'UPDATE_USER_ONBOARDING_FAIL',

  START_USER_ONBOARDING = 'START_USER_ONBOARDING',
  START_USER_ONBOARDING_SUCCESS = 'START_USER_ONBOARDING_SUCCESS',
  START_USER_ONBOARDING_FAIL = 'START_USER_ONBOARDING_FAIL',

  END_USER_ONBOARDING = 'END_USER_ONBOARDING',
  END_USER_ONBOARDING_SUCCESS = 'END_USER_ONBOARDING_SUCCESS',
  END_USER_ONBOARDING_FAIL = 'END_USER_ONBOARDING_FAIL',
}

export interface FetchUserOnboarding extends AxiosMiddlewareActionCreator {
  type: typeof ONBOARDING.FETCH_USER_ONBOARDING
  targetUserID: userId
}

export interface FetchUserOnboardingSuccess
  extends AxiosMiddlewareActionSuccess<UserOnboarding | null, FetchUserOnboarding> {
  type: typeof ONBOARDING.FETCH_USER_ONBOARDING_SUCCESS
}

export interface FetchUserOnboardingFail extends AxiosMiddlewareActionFail<FetchUserOnboarding> {
  type: typeof ONBOARDING.FETCH_USER_ONBOARDING_FAIL
}

export interface UpdateUserOnboarding extends AxiosMiddlewareActionCreator {
  type: typeof ONBOARDING.UPDATE_USER_ONBOARDING
  targetUserID: userId
  data: UserOnboardingData
}

export interface UpdateUserOnboardingSuccess
  extends AxiosMiddlewareActionSuccess<UserOnboarding, UpdateUserOnboarding> {
  type: typeof ONBOARDING.UPDATE_USER_ONBOARDING_SUCCESS
}

export interface UpdateUserOnboardingFail extends AxiosMiddlewareActionFail<UpdateUserOnboarding> {
  type: typeof ONBOARDING.UPDATE_USER_ONBOARDING_FAIL
}

export interface StartUserOnboarding extends AxiosMiddlewareActionCreator {
  type: typeof ONBOARDING.START_USER_ONBOARDING
  targetUserID: userId
}

export interface StartUserOnboardingSuccess extends AxiosMiddlewareActionSuccess<UserOnboarding, StartUserOnboarding> {
  type: typeof ONBOARDING.START_USER_ONBOARDING_SUCCESS
}

export interface StartUserOnboardingFail extends AxiosMiddlewareActionFail<StartUserOnboarding> {
  type: typeof ONBOARDING.START_USER_ONBOARDING_FAIL
}

export interface EndUserOnboarding extends AxiosMiddlewareActionCreator {
  type: typeof ONBOARDING.END_USER_ONBOARDING
  targetUserID: userId
}

export interface EndUserOnboardingSuccess extends AxiosMiddlewareActionSuccess<UserOnboarding, EndUserOnboarding> {
  type: typeof ONBOARDING.END_USER_ONBOARDING_SUCCESS
}

export interface EndUserOnboardingFail extends AxiosMiddlewareActionFail<EndUserOnboarding> {
  type: typeof ONBOARDING.END_USER_ONBOARDING_FAIL
}

export function fetchOnboarding(userID: userId = 'me'): FetchUserOnboarding {
  return {
    type: ONBOARDING.FETCH_USER_ONBOARDING,
    targetUserID: userID,
    payload: {
      request: {
        url: `/v1/users/${userID}/onboarding`,
      },
    },
  }
}

export function updateOnboardingData(userID: userId = 'me', data: Partial<UserOnboardingData>) {
  return {
    type: ONBOARDING.UPDATE_USER_ONBOARDING,
    targetUserID: userID,
    payload: {
      request: {
        url: `/v1/users/${userID}/onboarding`,
        method: 'PATCH',
        data,
      },
    },
  }
}

export function startOnboarding(userID: userId = 'me') {
  return {
    type: ONBOARDING.START_USER_ONBOARDING,
    targetUserID: userID,
    payload: {
      request: {
        url: `/v1/users/${userID}/onboarding`,
        method: 'POST',
      },
    },
  }
}

export function completeOnboarding(userID: userId = 'me') {
  return {
    type: ONBOARDING.END_USER_ONBOARDING,
    targetUserID: userID,
    payload: {
      request: {
        url: `/v1/users/${userID}/onboarding/end`,
        method: 'POST',
      },
    },
  }
}

export function completeOnboardingThunk(userID: userId = 'me'): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    await dispatch(completeOnboarding(userID))

    const state = getState().onboardingReducer.info

    if (!!state && state.finished_at) {
      dispatch(fetchUserPreferences(userID))
    }
  }
}

export type OnboardingActionTypes =
  | FetchUserOnboarding
  | FetchUserOnboardingSuccess
  | FetchUserOnboardingFail
  | UpdateUserOnboarding
  | UpdateUserOnboardingSuccess
  | UpdateUserOnboardingFail
  | StartUserOnboarding
  | StartUserOnboardingSuccess
  | StartUserOnboardingFail
  | EndUserOnboarding
  | EndUserOnboardingSuccess
  | EndUserOnboardingFail
