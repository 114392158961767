import { NewOrderNudgeTrigger, Nudge } from './types'
import { WIPSegments } from './reducer'
import { addHours, addMinutes } from 'date-fns'
import { EligibilityReasons, OfferShoppingEligibility } from '../offers/types'
import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'

import { FetchMessages } from '../messages/actions'
import { userId } from '../user/reducers'

export interface LatestClientMessagesResponse {
  unread_count: number
  client_name: string
  client_id: number
  received_at: string
}

export const NewOrdersNudgeTriggerKey = 'new-orders-nudge-trigger'

export enum NudgeActionTypes {
  ADD_NUDGE = 'ADD_NUDGE',

  REMOVE_MESSAGE_SENT_NUDGE = 'REMOVE_MESSAGE_SENT_NUDGE',
  REMOVE_NEW_ORDERS_NUDGE = 'REMOVE_NEW_ORDERS_NUDGE',
  REMOVE_MESSAGE_RECEIVED_NUDGE = 'REMOVE_MESSAGE_RECEIVED_NUDGE',

  FETCH_LATEST_CLIENT_MESSAGES = 'FETCH_LATEST_CLIENT_MESSAGES',
  FETCH_LATEST_CLIENT_MESSAGES_SUCCESS = 'FETCH_LATEST_CLIENT_MESSAGES_SUCCESS',
  FETCH_LATEST_CLIENT_MESSAGES_FAIL = 'FETCH_LATEST_CLIENT_MESSAGES_FAIL',

  RESET_LATEST_CLIENT_MESSAGES_LAST_POLL = 'RESET_LATEST_CLIENT_MESSAGES_LAST_POLL',
}

export interface AddNudge {
  type: typeof NudgeActionTypes.ADD_NUDGE
  nudge: Nudge
}

export interface RemoveMessageSentNudge {
  type: typeof NudgeActionTypes.REMOVE_MESSAGE_SENT_NUDGE
}

export interface RemoveNewOrdersNudge {
  type: typeof NudgeActionTypes.REMOVE_NEW_ORDERS_NUDGE
}

export interface RemoveMessageReceivedNudge {
  type: typeof NudgeActionTypes.REMOVE_MESSAGE_RECEIVED_NUDGE
}

export interface FetchLatestClientMessages extends AxiosMiddlewareActionCreator {
  type: typeof NudgeActionTypes.FETCH_LATEST_CLIENT_MESSAGES
}

export interface FetchLatestClientMessagesSuccess
  extends AxiosMiddlewareActionSuccess<LatestClientMessagesResponse[], FetchMessages> {
  type: typeof NudgeActionTypes.FETCH_LATEST_CLIENT_MESSAGES_SUCCESS
}

export interface FetchLatestClientMessagesFail extends AxiosMiddlewareActionFail {
  type: typeof NudgeActionTypes.FETCH_LATEST_CLIENT_MESSAGES_FAIL
}

export interface ResetLatestClientMessagesLastPoll {
  type: typeof NudgeActionTypes.RESET_LATEST_CLIENT_MESSAGES_LAST_POLL
}

export type NudgeActionType =
  | AddNudge
  | RemoveMessageSentNudge
  | RemoveNewOrdersNudge
  | RemoveMessageReceivedNudge
  | FetchLatestClientMessages
  | FetchLatestClientMessagesSuccess
  | FetchLatestClientMessagesFail
  | ResetLatestClientMessagesLastPoll

export function resetLatestClientMessagesLastPoll(): ResetLatestClientMessagesLastPoll {
  return {
    type: NudgeActionTypes.RESET_LATEST_CLIENT_MESSAGES_LAST_POLL,
  }
}

export function addNudge(nudge: Nudge): AddNudge {
  return {
    type: NudgeActionTypes.ADD_NUDGE,
    nudge,
  }
}

export function removeMessageSentNudge(): RemoveMessageSentNudge {
  return {
    type: NudgeActionTypes.REMOVE_MESSAGE_SENT_NUDGE,
  }
}

export function removeNewOrdersNudge(): RemoveNewOrdersNudge {
  return {
    type: NudgeActionTypes.REMOVE_NEW_ORDERS_NUDGE,
  }
}

export function removeMessageReceivedNudge(): RemoveMessageReceivedNudge {
  return {
    type: NudgeActionTypes.REMOVE_MESSAGE_RECEIVED_NUDGE,
  }
}

export function fetchLatestClientMessages(since: Date, userID: userId): FetchLatestClientMessages {
  return {
    type: NudgeActionTypes.FETCH_LATEST_CLIENT_MESSAGES,
    payload: {
      request: {
        url: `/v1/messages/user/${userID}/latest-incoming`,
        params: {
          since,
        },
      },
    },
  }
}

export const getNextTrigger = (segment: WIPSegments): Date | null => {
  switch (segment) {
    case WIPSegments.Low:
      return addMinutes(new Date(), 30)
    case WIPSegments.High:
      return addMinutes(new Date(), 60)
    case WIPSegments.Reached:
      return addHours(new Date(), 12)
  }
}

export const getWipSegment = (worksInProgress: number, wipLimit: number): WIPSegments | null => {
  if (wipLimit <= 0) {
    return null
  }
  const wipPercentile = worksInProgress / wipLimit
  return wipPercentile < 0.6 ? WIPSegments.Low : wipPercentile >= 1 ? WIPSegments.Reached : WIPSegments.High
}

// this should be called every time shopping eligibility changes
export const updateNudgeTrigger = (shoppingEligibility: OfferShoppingEligibility, userID: number) => {
  if (shoppingEligibility.wip_limit == null) {
    return
  }
  const nudgeTrigger = localStorage.getItem(NewOrdersNudgeTriggerKey)
  const newWipSegment = getWipSegment(shoppingEligibility.works_in_progress, shoppingEligibility.wip_limit)

  if (!newWipSegment) {
    if (!!nudgeTrigger) {
      localStorage.removeItem(NewOrdersNudgeTriggerKey)
    }
    return
  }

  if (
    !shoppingEligibility.eligible_for_offers &&
    shoppingEligibility.eligibility_reason !== EligibilityReasons.WipLimit
  ) {
    if (!!nudgeTrigger) {
      localStorage.removeItem(NewOrdersNudgeTriggerKey)
    }
    return
  }

  if (!!nudgeTrigger) {
    const trigger = JSON.parse(nudgeTrigger) as NewOrderNudgeTrigger

    if (newWipSegment !== trigger.wipSegment) {
      const newTrigger = {
        userID: userID,
        wipSegment: newWipSegment,
        lastTrigger: trigger.nextTrigger,
        nextTrigger: getNextTrigger(newWipSegment),
      }
      localStorage.setItem(NewOrdersNudgeTriggerKey, JSON.stringify(newTrigger))
    }
  } else {
    const newTrigger = {
      userID: userID,
      wipSegment: newWipSegment,
      nextTrigger: addMinutes(new Date(), 1),
    }
    localStorage.setItem(NewOrdersNudgeTriggerKey, JSON.stringify(newTrigger))
  }
}

export const scheduleNextNudge = (lastNudge: NewOrderNudgeTrigger) => {
  const newTrigger = {
    userID: lastNudge.userID,
    wipSegment: lastNudge.wipSegment,
    lastTrigger: lastNudge.nextTrigger,
    nextTrigger: getNextTrigger(lastNudge.wipSegment),
  }
  localStorage.setItem(NewOrdersNudgeTriggerKey, JSON.stringify(newTrigger))
}
