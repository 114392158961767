import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import shoppingIcon from '../../assets/svgs/shopping.svg'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      textAlign: 'center',
      color: styles.palette.lightGrey.color,
      padding: '0 7.5rem',
    },
    headline: {
      fontSize: '2.25rem',
      color: styles.palette.lightBlack.color,
      marginBottom: '2.125rem',
    },
    bodyText: {
      color: styles.palette.lightBlack.color,
      marginBottom: '3.1125rem',
    },
    image: {
      marginBottom: '2.5rem',
      '& > :first-child': { width: '7.875rem' },
    },
  })
)

export interface NoOrdersProps {
  headerText: string
  bodyText: string
}

const NoOrders: React.FC<NoOrdersProps> = ({ headerText, bodyText }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Typography display="block" variant={'h1'} className={classes.headline}>
        {headerText}
      </Typography>
      <Box className={classes.image}>
        <img src={shoppingIcon} alt="" />
      </Box>
      <Typography display="block" variant={'body1'} className={classes.bodyText}>
        {bodyText}
      </Typography>
    </Box>
  )
}

export default NoOrders
