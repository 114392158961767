import ModalWrapper from '../common/ModalWrapper'
import Header from '../common/Header'
import i18n from '../../i18n'
import Box from '@material-ui/core/Box'
import Footer from '../common/Footer'
import Button from '../common/Button'
import React from 'react'
import SuccessIcon from '../../assets/svgs/linkedin-success-icon.svg'
import Typography from '@material-ui/core/Typography'
import { getFileDownloadLink } from '../../utils/files'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { clientByIdSelector } from '../../selectors/clients'
import { Client } from '../../store/clients/reducer'

interface PublishedConfirmationProps {
  handleProceedToAttach: (fileID: number) => void
  handleClose: () => void
  fileID: number
  clientID: number
}

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      paddingTop: '5em',
    },
    buttons: {
      width: '40%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    text: {
      width: '40%',
      fontWeight: 600,
      padding: '2em 0 2em',
      color: styles.palette.shadowedSelect.color,
      fontSize: '1.1em',
    },
  })
)

export const PublishedConfirmation: React.FC<PublishedConfirmationProps> = ({
  handleClose,
  handleProceedToAttach,
  fileID,
  clientID,
}) => {
  const classes = useStyles()

  const client = useSelector<AppState, Client>(state => clientByIdSelector(state.clientReducer, clientID))

  return (
    <ModalWrapper>
      <Header
        activeStep={3}
        closeButton={true}
        handleClose={handleClose}
        title={i18n.t('alink__PublishedConfirmation__title')}
        transparent={true}
      />
      <Box className={classes.content}>
        <img src={SuccessIcon} alt="" />
        <Typography className={classes.text}>
          {i18n.t('alink__PublishedConfirmation__body', { client_name: client.full_name })}
        </Typography>
        <Box className={classes.buttons}>
          <Button type="secondary" id="success-li-download" href={getFileDownloadLink(fileID)}>
            {i18n.t('alink__PublishedConfirmation__download')}
          </Button>
          <Button type="contrast" onClick={() => handleProceedToAttach(fileID)} id="success-li-attach">
            {i18n.t('alink__PublishedConfirmation__attach')}
          </Button>
        </Box>
      </Box>
      <Footer />
    </ModalWrapper>
  )
}

export default PublishedConfirmation
