import React, { useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import classNames from 'classnames'

import Button from '../common/Button'
import i18n from '../../i18n'
import styles from '../../styles'
import { AcceptRejectFeedbackOfferResponse, OfferLoadingState, OfferFeedback } from '../../store/offers/types'
import { HashMap } from '../../utils/HashMap'
import OfferFeedbackRecorded from './OfferFeedbackRecorded'
import { AxiosMiddlewareActionCreator, AxiosMiddlewareActionSuccess } from '../../utils/axios'

interface OfferRejectedProps {
  onFeedbackSubmit: (
    feedback: OfferFeedback
  ) => Promise<AxiosMiddlewareActionSuccess<
    AcceptRejectFeedbackOfferResponse,
    AxiosMiddlewareActionCreator
  > | void> | void
  onFeedbackCancel: () => void
  onRequestNewOrderClick: () => void
  isLoading: boolean
  currentOfferMeta: OfferLoadingState
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: styles.palette.lightGrey.color,
    },
    title: {
      fontSize: '2.25rem',
      marginBottom: '1.25rem',
    },
    text: {
      maxWidth: '32.1875rem',
      color: styles.palette.lightBlack.color,
      marginBottom: '1.25rem',
    },
    bold: {
      fontWeight: 'bold',
    },
    checkboxList: {
      marginBottom: '1.2rem',
    },
    textarea: {
      display: 'flex',
      width: '100%',
      maxWidth: '43.3125rem',
      height: '5.125rem',
      border: `1px solid ${styles.palette.lightGrey.color}`,
      marginBottom: '1.25rem',
      borderRadius: '2px',
    },
    buttons: {
      '& .leftButton': { marginRight: '2.5rem' },
      '@media (max-width: 620px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& .leftButton': { marginRight: 0, marginBottom: '1rem' },
      },
    },
    sendingSpinner: {
      marginLeft: theme.spacing(3),
    },
  })
)

// The keys must be this way to preserve consistency with the previous form.
const reasonsStateValueMap: HashMap<string> = {
  industry: 'The industry',
  items: 'The items in the order',
  dueDate: 'The due date',
  region: 'The region',
  unavailable: "I'm unavailable",
}

const OfferRejected: React.FC<OfferRejectedProps> = ({
  onFeedbackSubmit,
  onFeedbackCancel,
  onRequestNewOrderClick,
  isLoading,
  currentOfferMeta,
}) => {
  const classes = useStyles()

  const [state, setState] = React.useState({
    industry: false,
    items: false,
    dueDate: false,
    region: false,
    unavailable: false,
  })
  const [textarea, setTextarea] = React.useState('')
  const [disabled, setDisabled] = React.useState(false)
  const isValid = Object.values(state).some(v => v)

  const { industry, items, dueDate, region, unavailable } = state

  const handleChange = useCallback(
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setState(currentState => ({ ...currentState, [name]: event.target.checked }))
    },
    [setState]
  )

  const onSubmitClick = useCallback(async () => {
    const reasons: string[] = []
    industry && reasons.push(reasonsStateValueMap['industry'])
    items && reasons.push(reasonsStateValueMap['items'])
    dueDate && reasons.push(reasonsStateValueMap['dueDate'])
    region && reasons.push(reasonsStateValueMap['region'])
    unavailable && reasons.push(reasonsStateValueMap['unavailable'])
    !!textarea && reasons.push(`Other: ${textarea}`)

    if (reasons.length === 0) {
      return
    }

    setDisabled(true)
    await onFeedbackSubmit({ feedback: reasons })
  }, [setDisabled, dueDate, industry, items, region, unavailable, textarea, onFeedbackSubmit])

  const onMoreOffersClick = useCallback(async () => {
    await onRequestNewOrderClick()
    onFeedbackCancel()
  }, [onRequestNewOrderClick, onFeedbackCancel])

  if (!currentOfferMeta.submitFeedback.loaded)
    return (
      <Box>
        <Typography display="block" className={classes.title}>
          {i18n.t('order__shopping__reject__title')}
        </Typography>
        <Typography display="block" variant={'body1'} className={classes.text}>
          {i18n.t('order__shopping__reject__intro')}
        </Typography>
        <Typography
          display="block"
          variant={'body1'}
          className={classNames({
            [classes.text]: true,
            [classes.bold]: true,
          })}
        >
          {i18n.t('order__shopping__reject__feedback__question')}
        </Typography>
        <Box className={classes.checkboxList}>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    className={classes.root}
                    checked={industry}
                    onChange={handleChange('industry')}
                    value="industry"
                    id="order-shopping-reject-reason-industry"
                  />
                }
                label={i18n.t('order__shopping__reject__feedback__option__industry')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    className={classes.root}
                    checked={items}
                    onChange={handleChange('items')}
                    value="items"
                    id="order-shopping-reject-reason-items"
                  />
                }
                label={i18n.t('order__shopping__reject__feedback__option__items')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    className={classes.root}
                    checked={dueDate}
                    onChange={handleChange('dueDate')}
                    value="dueDate"
                    id="order-shopping-reject-reason-due-date"
                  />
                }
                label={i18n.t('order__shopping__reject__feedback__option__due__date')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    className={classes.root}
                    checked={region}
                    onChange={handleChange('region')}
                    value="region"
                    id="order-shopping-reject-reason-region"
                  />
                }
                label={i18n.t('order__shopping__reject__feedback__option__region')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    className={classes.root}
                    checked={unavailable}
                    onChange={handleChange('unavailable')}
                    value="unavailable"
                    id="order-shopping-reject-reason-unavailable"
                  />
                }
                label={i18n.t('order__shopping__reject__feedback__option__unavailability')}
              />
            </FormGroup>
          </FormControl>
        </Box>
        <Typography display="block" variant={'body1'} className={classes.text}>
          {i18n.t('order__shopping__reject__feedback__textarea')}
        </Typography>
        <Box>
          <TextareaAutosize
            className={classes.textarea}
            aria-label="minimum height"
            rows={5}
            value={textarea}
            onChange={e => setTextarea(e.target.value)}
            id="order-shopping-reject-reason-other"
          />
        </Box>
        <Box className={classes.buttons}>
          <Button
            onClick={onSubmitClick}
            className={classNames({
              disabled: disabled || !isValid,
              leftButton: true,
            })}
            disabled={disabled || !isValid}
            size={'medium'}
            type={'primary'}
            showLoader={currentOfferMeta.submitFeedback.isLoading}
            id="order-shopping-feedback-submit"
          >
            {i18n.t('order__shopping__reject__feedback__submit')}
          </Button>
          <Button onClick={onFeedbackCancel} size={'medium'} type={'secondary'}>
            {i18n.t('order__shopping__reject__feedback__close')}
          </Button>
        </Box>
      </Box>
    )
  if (currentOfferMeta.submitFeedback.loaded)
    return (
      <OfferFeedbackRecorded
        onCloseRecordedFeedbackClick={onFeedbackCancel}
        onContinueShoppingClick={onMoreOffersClick}
        isLoading={isLoading}
      />
    )
  return null
}

export default OfferRejected
