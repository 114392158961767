import React from 'react'
import { formatCurrency } from '../../utils/formatting'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { BasePay } from '../../store/orders/types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import i18n from '../../i18n'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { userAcceptedPay } from '../../selectors/user'
import { UserInfo } from '../../store/user/reducers'
import styles from '../../styles'

interface ProjectedEarningsWidgetProps {
  user: UserInfo
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emoji: {
      fontSize: '2em',
      margin: '0 0 .5em 0',
    },
    text: {
      fontSize: '.9rem',
      fontFamily: styles.fonts.primary.family,
      fontWeight: styles.fonts.primary.weights.semiBold,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    leftColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '3em',
    },
    rightColumn: {
      width: 'calc(100% - 3em)',
    },
  })
)

const ProjectedEarningsWidget: React.FC<ProjectedEarningsWidgetProps> = ({ user }) => {
  const classes = useStyles()

  const acceptedPay = useSelector<AppState, BasePay | null>(state =>
    userAcceptedPay(state.userPreferencesReducer, user.id)
  )

  return (
    <Box className={classes.container} area-label="accepted">
      <Box className={classes.leftColumn}>
        <Typography align="center">
          <span className={classes.emoji} aria-label="emoji" role="img">
            💸
          </span>
        </Typography>
      </Box>
      <Box className={classes.rightColumn}>
        <Typography className={classes.text}> {i18n.t('projectedEarnings__text')} </Typography>
        <Typography className={classes.text} area-label="accepted_amount">
          {formatCurrency(!!acceptedPay ? acceptedPay.amount : 0, !!acceptedPay ? acceptedPay.currency : 'USD')}{' '}
        </Typography>
      </Box>
    </Box>
  )
}

export default ProjectedEarningsWidget
