import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import EmailIcon from '@material-ui/icons/Email'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import isEqual from 'lodash/isEqual'

import i18n from '../../i18n'
import { GhostLink } from '../../utils/link'
import MacroTaskLine from './MacroTaskLine'
import UpcomingPhoneCalls from './UpcomingPhoneCalls'
import { PhoneCallsDue, TasksDue, UserDashboardMacro } from '../../store/dashboard/types'
import { DashboardVariants } from '.'
import DashboardStyles from './Styles'
import { Routes } from '../../utils/consts'
import GetOrdersButton from '../common/GetOrdersButton'
import useUserInfoState from '../common/useUserInfo'

interface MacroSummaryCardProps {
  variant: DashboardVariants
  macro: UserDashboardMacro | null
  mentorName: string | null
}

function renderTaskLine(tasks: TasksDue): boolean {
  return tasks.late > 0 || tasks.soon > 0 || tasks.this_week > 0 || tasks.urgent > 0
}

const renderPhoneCallsDue = (phone_calls_due: PhoneCallsDue): boolean => {
  return phone_calls_due.today > 0 || phone_calls_due.tomorrow > 0 || phone_calls_due.this_week > 0
}

const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      color: 'inherit',
    },
    cardContent: {
      padding: '0',

      '&:last-child': {
        paddingBottom: '0',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    icon: {
      color: 'inherit',
      minWidth: '1.8em',
    },
    image: {
      marginRight: '.5em',
    },
    summaryCard: {
      backgroundColor: DashboardStyles.card2.bg,
      maxWidth: DashboardStyles.halfWidth,
      padding: DashboardStyles.card2.padding,
      width: DashboardStyles.halfWidth,
      '& div': {
        backgroundColor: 'transparent',
      },
    },
    header: {
      fontSize: DashboardStyles.header2.size,
      fontWeight: DashboardStyles.header2.weight,
      margin: DashboardStyles.header2.margin,
      width: '100%',
    },
    emoji: {
      fontSize: DashboardStyles.emoji.size,
      margin: DashboardStyles.emoji.margin,
    },
    ul: {
      paddingBottom: '0',

      '& li': {
        fontSize: DashboardStyles.header3.size,
        paddingLeft: '0',
        paddingRight: '0',

        '& p': {
          fontSize: 'inherit',
        },
      },
    },
    link: {
      fontWeight: 'bold',
      color: 'inherit',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
    h5: {
      fontSize: DashboardStyles.header2.size,
      fontWeight: DashboardStyles.header2.weight,
      margin: DashboardStyles.header2.margin,
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      summaryCard: {
        margin: DashboardStyles.card.marginMobile,
        maxWidth: DashboardStyles.fullWidth,
        width: DashboardStyles.fullWidth,
      },
    },
  })
)

const containerID = 'dashboard-macro-summary-card'

const EmptyStateCard: React.FC = () => {
  const classes = useStyles()

  const user = useUserInfoState()

  return (
    <Box flexGrow="1" className={classes.summaryCard} id={containerID}>
      <Card className={classes.card}>
        <Box flexDirection="row">
          <Typography className={classes.emoji} align="center">
            <span aria-label="emoji" role="img">
              💸
            </span>
          </Typography>
          <Typography display="block" variant="h5" className={classes.h5} align="center">
            {i18n.t('dashboard__macro__start')}
          </Typography>
          <Typography align="center">
            <GetOrdersButton userID={user.id} />
          </Typography>
        </Box>
      </Card>
    </Box>
  )
}

interface MenteeEmptyStateCardProps {
  mentorName: string | null
}

const MenteeEmptyStateCard: React.FC<MenteeEmptyStateCardProps> = ({ mentorName }) => {
  const classes = useStyles()

  return (
    <Box flexGrow="1" className={classes.summaryCard} id={containerID}>
      <Card className={classes.card}>
        <Card className={classes.card}>
          <CardContent>
            <Box flexDirection="row">
              <Typography className={classes.emoji} align="center">
                <span aria-label="emoji" role="img">
                  👩‍🏫
                </span>
              </Typography>
              <Typography variant="h5">{i18n.t('dashboard__macro__mentorwelcome')}</Typography>
              {!!mentorName && <Typography>{i18n.t('dashboard__macro__mentor', { mentorName })}</Typography>}
            </Box>
          </CardContent>
        </Card>
      </Card>
    </Box>
  )
}

export const MacroSummaryCard: React.FC<MacroSummaryCardProps> = ({ macro, variant, mentorName }) => {
  const classes = useStyles()
  const { featureFlags } = useUserInfoState()

  let totalUnread = 0

  if (!!macro && macro.unread_client_messages && macro.unread_client_messages.length > 0) {
    totalUnread = macro.unread_client_messages
      .map(x => x.unread_count)
      .reduce(function(a: number, b: number): number {
        return a + b
      })
  }

  const isEmpty = !!macro && !renderTaskLine(macro.tasks_due) && totalUnread === 0

  if (isEmpty) {
    switch (variant) {
      case DashboardVariants.Mentee:
        return <MenteeEmptyStateCard mentorName={mentorName} />
      default:
        return <EmptyStateCard />
    }
  }

  return (
    <Box flexGrow="1" className={classes.summaryCard} id={containerID}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.header}> {i18n.t('dashboard__macro__title')}</Typography>
          <List className={classes.ul}>
            {!!macro && macro.unread_client_messages.length > 0 && (
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <EmailIcon />
                </ListItemIcon>

                <Typography variant={'body1'}>
                  <a className={classes.link} href="#unread-messages">
                    {i18n.t('dashboard__macro__messagecount__linkText', {
                      messageCount: totalUnread,
                    })}
                  </a>{' '}
                  {i18n.t('dashboard__macro__messagecount__bodyCopy', {
                    clientCount: macro.unread_client_messages.length,
                  })}
                </Typography>
              </ListItem>
            )}

            {!!macro && renderTaskLine(macro.tasks_due) && <MacroTaskLine tasks={macro.tasks_due} />}

            {!!macro && macro.orders_due_this_week > 0 && (
              <ListItem>
                <ListItemIcon className={classes.icon}>
                  <CheckCircleOutlineIcon />
                </ListItemIcon>

                <Typography variant={'body1'}>
                  <GhostLink
                    to={{
                      pathname: Routes.OpenOrders,
                      search: 'filter=close',
                    }}
                    className={classes.link}
                  >
                    {i18n.t('dashboard__macro__ordercloseclause__linkText', {
                      orderCloseCount: macro.orders_due_this_week,
                    })}
                  </GhostLink>{' '}
                  {i18n.t('dashboard__macro__ordercloseclause__bodyText')}
                </Typography>
              </ListItem>
            )}

            {featureFlags.showScheduler && !!macro && renderPhoneCallsDue(macro.phone_calls_due) && (
              <UpcomingPhoneCalls phoneCallsDue={macro.phone_calls_due} />
            )}
          </List>
        </CardContent>
      </Card>
    </Box>
  )
}

const memoziedMacroSummaryCard = React.memo(MacroSummaryCard, isEqual)
// @ts-ignore
memoziedMacroSummaryCard.whyDidYouRender = true

export default memoziedMacroSummaryCard
