import React from 'react'

const SvgIncentive = props => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="currentColor" {...props}>
    <g transform="translate(8.5, 12.5)">
      <path d="M15.5284553,7.5 C15.5284553,6.28235294 16.6017886,5.29411765 17.9242886,5.29411765 C19.2467886,5.29411765 20.320122,6.28235294 20.320122,7.5 C20.320122,8.71764706 19.2467886,9.70588235 17.9242886,9.70588235 C16.6017886,9.70588235 15.5284553,8.71764706 15.5284553,7.5 L15.5284553,7.5 Z M12.6534553,0 L12.6534553,15 L23.195122,15 L23.195122,0 L12.6534553,0 L12.6534553,0 Z M21.2784553,11.4705882 C20.2242886,11.4705882 19.3617886,12.2647059 19.3617886,13.2352941 L16.4867886,13.2352941 C16.4867886,12.2647059 15.633872,11.4705882 14.570122,11.4705882 L14.570122,3.52941176 C15.633872,3.52941176 16.4867886,2.74411765 16.4867886,1.76470588 L19.3617886,1.76470588 C19.3617886,2.74411765 20.2242886,3.52941176 21.2784553,3.52941176 L21.2784553,11.4705882 L21.2784553,11.4705882 Z M6.90345528,2.64705882 C7.95762195,2.64705882 8.82012195,3.44117647 8.82012195,4.41176471 C8.82012195,5.38235294 7.95762195,6.17647059 6.90345528,6.17647059 C5.84928862,6.17647059 4.98678862,5.38235294 4.98678862,4.41176471 C4.98678862,3.44117647 5.84928862,2.64705882 6.90345528,2.64705882 L6.90345528,2.64705882 Z M6.90345528,0.882352941 C4.78553862,0.882352941 3.07012195,2.46176471 3.07012195,4.41176471 C3.07012195,6.36176471 4.78553862,7.94117647 6.90345528,7.94117647 C9.02137195,7.94117647 10.7367886,6.36176471 10.7367886,4.41176471 C10.7367886,2.46176471 9.02137195,0.882352941 6.90345528,0.882352941 L6.90345528,0.882352941 Z M6.90345528,9.70588235 C3.19470528,9.70588235 0.195121951,11.2852941 0.195121951,13.2352941 L0.195121951,15 L10.7367886,15 L10.7367886,13.2352941 L2.11178862,13.2352941 C2.11178862,12.7235294 3.78887195,11.4705882 6.90345528,11.4705882 C8.65720528,11.4705882 9.94137195,11.8676471 10.7367886,12.3088235 L10.7367886,10.3411765 C9.65387195,9.94411765 8.34095528,9.70588235 6.90345528,9.70588235 Z" />
    </g>
  </svg>
)

export default SvgIncentive
