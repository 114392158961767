export interface IntervalIDs {
  notifications: ResettableInterval | null
  config: ResettableInterval | null
  auth0: ResettableInterval | null
  events: ResettableInterval | null
  nudges: ResettableInterval | null
  clientMessages: ResettableInterval | null
}

export interface ResettableInterval {
  intervalID: number
  interval: number
}

export interface Verisons {
  running: string | null
  latest: string | null
  needsUpgrade: boolean
}

export interface CronState {
  intervals: IntervalIDs
  versions: Verisons
}

export const initialState: CronState = {
  intervals: {
    notifications: null,
    config: null,
    auth0: null,
    events: null,
    nudges: null,
    clientMessages: null,
  },
  versions: {
    running: null,
    latest: null,
    needsUpgrade: false,
  },
}
