import React, { useCallback, useReducer, useLayoutEffect } from 'react'
import debounce from 'lodash/debounce'
import * as Sentry from '@sentry/browser'

interface UseStickyHeader {
  enabled: boolean
  sticky: boolean
  setEnabled: (enabled: boolean) => void
  headerRef: React.RefObject<HTMLDivElement>
}

interface ReducerState {
  sticky: boolean
  enabled: boolean
  stickyThreshold: null | number
  headerHeight: null | number
  bodyMinHeight: null | string
  headerRef: React.RefObject<HTMLDivElement>
}

const initialState: ReducerState = {
  sticky: false,
  enabled: false,
  stickyThreshold: null,
  headerHeight: null,
  bodyMinHeight: null,
  headerRef: React.createRef<HTMLDivElement>(),
}

interface SetEnabledAction {
  type: 'SET_ENABLED'
  enabled: boolean
}

interface SetStickyAction {
  type: 'SET_STICKY'
  sticky: boolean
}

interface SetDimensionsAction {
  type: 'SET_DIMENSIONS'
  stickyThreshold: number
  headerHeight: number
  bodyMinHeight: string | null
}

interface ResetAction {
  type: 'RESET'
}

type Actions = SetDimensionsAction | ResetAction | SetEnabledAction | SetStickyAction

function reducer(state: ReducerState, action: Actions): ReducerState {
  switch (action.type) {
    case 'SET_DIMENSIONS':
      return {
        ...state,
        stickyThreshold: action.stickyThreshold,
        headerHeight: action.headerHeight,
        bodyMinHeight: action.bodyMinHeight,
      }

    case 'SET_ENABLED':
      return {
        ...state,
        enabled: action.enabled,
      }

    case 'SET_STICKY':
      return {
        ...state,
        sticky: action.sticky,
      }

    case 'RESET':
      return { ...initialState }
  }
}

export const useStickyHeader = (): UseStickyHeader => {
  const [state, dispatch] = useReducer(reducer, initialState)

  // First thing; we need to grab a bunch of dimensions to determine sticky boundary.
  useLayoutEffect(() => {
    const body = document.getElementById('full-page-container') as HTMLDivElement
    if (state.headerRef.current && !state.headerHeight && body) {
      const bodyStyles = window.getComputedStyle(body)

      dispatch({
        type: 'SET_DIMENSIONS',
        headerHeight: state.headerRef.current.offsetHeight,
        stickyThreshold: state.headerRef.current.offsetTop,
        bodyMinHeight: bodyStyles.minHeight || null,
      })
    }
  }, [dispatch, state.headerHeight, state.headerRef])

  // Next, we need to listen to the window's scroll to determine when to make the header sticky.
  useLayoutEffect(() => {
    const onScroll = debounce(() => {
      const body = document.getElementById('full-page-container') as HTMLDivElement
      if (!state.stickyThreshold) {
        return
      }

      const passedScrollThreshold = window.scrollY >= state.stickyThreshold
      const isNowSticky = passedScrollThreshold && window.scrollY > 0

      // We need to add the height of the header to the min height of the container div so that we don't get
      // a jitterbug!
      if (body) {
        if (isNowSticky && state.headerHeight) {
          if (!body.style.minHeight || !body.style.minHeight.includes('calc')) {
            body.style.minHeight = `calc(${state.bodyMinHeight} + ${state.headerHeight}px)`
          }
        } else {
          body.style.minHeight = ''
        }
      }

      if (isNowSticky !== state.sticky) {
        dispatch({ type: 'SET_STICKY', sticky: isNowSticky })

        Sentry.addBreadcrumb({
          category: 'messaging',
          message: JSON.stringify({
            msg: 'Toggling header stickiness',
            isNowSticky,
            windowDimensions: [window.innerWidth, window.innerHeight],
            scrollY: window.scrollY,
          }),
          level: Sentry.Severity.Info,
        })
      }
    }, 25)

    if (state.enabled) {
      window.addEventListener('scroll', onScroll)
    }

    return () => {
      window.removeEventListener('scroll', onScroll)

      // We don't need to clean up min-height effect because the user must scroll to the top of the page (unsetting the
      // stickiness and the min-height) in order to navigate away from this page. If we add more navigation around the
      // sticky header, it should probably be cleaned up.
    }
  }, [state.bodyMinHeight, state.headerHeight, state.stickyThreshold, state.enabled, state.sticky])

  const setEnabled = useCallback((enabled: boolean) => dispatch({ type: 'SET_ENABLED', enabled }), [dispatch])

  return {
    enabled: state.enabled,
    sticky: state.sticky && state.enabled,
    setEnabled,
    headerRef: state.headerRef,
  }
}

export default useStickyHeader
