import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuIcon from '@material-ui/icons/Menu'

import { UserInfo } from '../../store/user/reducers'
import i18n from '../../i18n'
import NotificationsContainer, { NotificationsMode } from '../Notifications/NotificationsContainer'
import { Routes } from '../../utils/consts'
import TalentIncLogo from '../common/Icons/TalentIncLogo'
import { GhostLink } from '../../utils/link'
import styles from '../../styles'
import UserMenu from '../TopMenu/UserMenu'
import EarningsProgress from './EarningProgress'
import ProjectedEarningsWidget from './ProjectedEarningsWidget'
import GetOrdersButton from '../common/GetOrdersButton'
import classNames from 'classnames'
import { useLocation } from 'react-use'
import NavBarItem from './NavBarItem'
import { getFeatureFlags } from '../../selectors/featureFlags'
import useUserInfoState from '../common/useUserInfo'
import useTopNavBarState, { TopNavBarContext } from './useTopNavBarState'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { userPreferencesLoaded } from '../../selectors/users'
import NavigationItem from './NavigationItem'

interface TopNavBarProps {
  onMenuClick: (open?: boolean) => void
  user: UserInfo
  showOrderToOrderNavIcon: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    dashboardLink: {
      '@media (max-width: 500px)': {
        display: 'none',
      },
    },
    earningsGoal: {
      '@media (max-width: 1150px)': {
        display: 'none',
      },
      '@media (max-width: 540px)': {
        display: 'block',
      },
    },
    earningsGoalExpanded: {
      paddingTop: '5rem',
      order: -1,
    },
    expandedColumn: {
      flexDirection: 'column',
    },
    expandedHide: {
      display: 'none',
    },
    first: {
      marginLeft: '1em',
    },
    getOrderExpanded: {
      paddingBottom: '2.5rem',
      '& a': {
        width: '100%',
      },
    },
    kbLink: {
      '@media (max-width: 700px)': {
        display: 'none',
      },
    },
    left: {
      display: 'flex',
      alignItems: 'center',
    },
    leftExpanded: {
      '@media (max-width: 540px)': {
        backgroundColor: '#fafafc',
        borderBottom: 'solid 1px #e4e8f0',
        color: styles.palette.primary.contrast,
        fontSize: '1.1rem',
        paddingBottom: '2rem',
        padding: '0 2.5rem',
        width: '100%',

        '& a': {
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 600,
          width: '100%',
        },
      },
    },
    links: {
      fontSize: '.9rem',
      color: styles.palette.textPrimary.color,
      fontWeight: styles.fonts.primary.weights.semiBold,
    },
    logo: {
      lineHeight: 1,
      '@media (max-width: 540px)': {
        margin: '0 0 0 1rem',
      },
      '& a': { display: 'inline-block' },
      '& svg': {
        verticalAlign: 'middle',
        marginRight: '.4rem',
      },
    },
    menuIcon: {
      fill: 'black',
    },
    menuIconContainer: {
      width: '5rem',
      textAlign: 'center',
      '@media (max-width: 540px)': {
        marginRight: '-1rem',
        width: 'initial',
      },
    },
    moreButton: {
      color: 'black',
      display: 'none',
      '@media (max-width: 540px)': {
        display: 'block',
      },
    },
    moreButtonExpanded: {
      color: styles.palette.primary.contrast,
      position: 'absolute',
      right: '1rem',
      top: '1rem',
    },
    navItem: {
      height: '100%',
    },
    projectedEarnings: {
      '@media (max-width: 1280px)': {
        display: 'none',
      },
    },
    right: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    rightExpanded: {
      order: -1,
      width: '100%',
    },
    root: {
      maxHeight: styles.sizes.topNavHeight,
      flexGrow: 1,
      backgroundColor: 'white',
      borderBottom: '1px solid #CACFD8',
      fontFamily: styles.fonts.primary.family,
      fontSize: styles.fonts.primary.weights.normal,
      position: 'fixed',
      zIndex: 1301,
    },
    rootExpanded: {
      maxHeight: 'initial',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: '3.5625rem',
      '@media (min-width: 600px)': {
        height: '4rem',
      },
      '@media (min-width: 0px) and (orientation: landscape)': {
        height: '3rem',
      },
    },
    typographyExpanded: {
      '@media (max-width: 540px)': {
        fontSize: 'inherit',
        fontWeight: 'inherit',
        marginTop: '0 !important',
        width: '100%',
      },
    },
    underlined: {
      marginTop: '.25em',
    },
  })
)

const TopNavBar: React.FC<TopNavBarProps> = ({ user, showOrderToOrderNavIcon, onMenuClick }) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const { showOrderShopping } = getFeatureFlags(user)
  const [topNavBarState, toggleTopNavBar] = useTopNavBarState()
  const { shouldSeeBasePay, isExpertMentee, isNewHire } = useUserInfoState()

  const userPreferencesFetched = useSelector<AppState, boolean>(state =>
    userPreferencesLoaded(state.userPreferencesReducer, user.id)
  )

  return (
    <TopNavBarContext.Provider value={topNavBarState}>
      <AppBar
        position="static"
        className={classNames({
          [classes.root]: true,
          [classes.rootExpanded]: topNavBarState.open,
        })}
      >
        <Toolbar
          className={classNames({
            [classes.toolbar]: true,
            [classes.expandedColumn]: topNavBarState.open,
          })}
          disableGutters
        >
          <Box
            className={classNames({
              [classes.left]: true,
              [classes.expandedColumn]: topNavBarState.open,
              [classes.leftExpanded]: topNavBarState.open,
            })}
          >
            {showOrderToOrderNavIcon && (
              <Box
                className={classNames({
                  [classes.menuIconContainer]: true,
                  [classes.expandedHide]: topNavBarState.open,
                })}
              >
                <IconButton aria-label="Menu" onClick={() => onMenuClick()}>
                  <MenuIcon classes={{ root: classes.menuIcon }} />
                </IconButton>
              </Box>
            )}
            <Box
              className={classNames({
                [classes.logo]: true,
                [classes.first]: !showOrderToOrderNavIcon,
                [classes.expandedHide]: topNavBarState.open,
              })}
            >
              <NavigationItem to={Routes.Dashboard} children={<TalentIncLogo />} />
            </Box>
            <NavBarItem
              underlined={pathname === Routes.Dashboard}
              spacing={true}
              collapsed={!topNavBarState.open}
              menuExpanded={topNavBarState.open}
              handleClick={toggleTopNavBar}
            >
              <GhostLink to={Routes.Dashboard}>
                <Typography
                  className={classNames({
                    [classes.links]: true,
                    [classes.underlined]: pathname === Routes.Dashboard,
                    [classes.typographyExpanded]: topNavBarState.open,
                  })}
                >
                  {i18n.t('nav__main__dashboard')}
                </Typography>
              </GhostLink>
            </NavBarItem>
            <NavBarItem
              underlined={pathname === Routes.PaidOrders || pathname === Routes.OpenOrders}
              spacing={true}
              collapsed={!topNavBarState.open}
              menuExpanded={topNavBarState.open}
              handleClick={toggleTopNavBar}
            >
              <GhostLink to={Routes.OpenOrders}>
                <Typography
                  className={classNames({
                    [classes.links]: true,
                    [classes.underlined]: pathname === Routes.PaidOrders || pathname === Routes.OpenOrders,
                    [classes.typographyExpanded]: topNavBarState.open,
                  })}
                >
                  {i18n.t('nav__main__orders')}
                </Typography>
              </GhostLink>
            </NavBarItem>
            <NavBarItem
              spacing={true}
              collapsed={!topNavBarState.open}
              menuExpanded={topNavBarState.open}
              handleClick={toggleTopNavBar}
            >
              <a href="http://help.experts.talentinc.com/en">
                <Typography
                  className={classNames({
                    [classes.links]: true,
                    [classes.typographyExpanded]: topNavBarState.open,
                  })}
                >
                  {i18n.t('nav__main__kb_ext')}
                </Typography>
              </a>
            </NavBarItem>
          </Box>
          <Box
            className={classNames({
              [classes.right]: true,
              [classes.rightExpanded]: topNavBarState.open,
              [classes.expandedColumn]: topNavBarState.open,
            })}
          >
            {shouldSeeBasePay && (
              <>
                <NavBarItem className={classes.projectedEarnings} delimiter={'both'} spaceInside={true}>
                  <ProjectedEarningsWidget user={user} />
                </NavBarItem>
                {!isExpertMentee && !isNewHire && (
                  <NavBarItem
                    className={classNames({
                      [classes.earningsGoal]: true,
                      [classes.earningsGoalExpanded]: topNavBarState.open,
                    })}
                    delimiter={'right'}
                    spaceInside={userPreferencesFetched}
                    collapsed={!topNavBarState.open}
                  >
                    <EarningsProgress expanded={topNavBarState.open} />
                  </NavBarItem>
                )}
              </>
            )}
            {showOrderShopping && (
              <NavBarItem
                spaceInside={true}
                delimiter={'right'}
                collapsed={!topNavBarState.open}
                className={classNames({
                  [classes.getOrderExpanded]: topNavBarState.open,
                })}
                handleClick={toggleTopNavBar}
              >
                <GetOrdersButton userID={user.id} expanded={topNavBarState.open} />
              </NavBarItem>
            )}
            {!topNavBarState.open && <NotificationsContainer mode={NotificationsMode.Menu} userID={user.id} />}
            <UserMenu
              collapsed={!topNavBarState.open}
              expanded={topNavBarState.open}
              toggleTopNavBar={toggleTopNavBar}
            />
            <IconButton
              onClick={toggleTopNavBar}
              className={classNames({
                [classes.moreButton]: true,
                [classes.moreButtonExpanded]: topNavBarState.open,
              })}
              color="inherit"
              aria-label="more"
              component="span"
            >
              {topNavBarState.open ? <CloseIcon /> : <MoreVertIcon />}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </TopNavBarContext.Provider>
  )
}

export default TopNavBar
