import React from 'react'
import { useEffectOnce } from 'react-use'

import { refreshToken } from '../../utils/auth0'

export const AuthIframePage: React.FC = () => {
  useEffectOnce(() => {
    refreshToken()
  })

  return null
}

export default AuthIframePage
