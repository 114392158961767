// This is inlined from handlebars-i18next. I could not use the node package as the handlebars-loader does not provide
// an easy way to register it as a helper on the runtime module.
// https://github.com/UUDigitalHumanitieslab/handlebars-i18next/blob/develop/handlebars-i18next.es6

import Handlebars from 'handlebars'
import i18n from '../../../i18n'

// These are options to i18n.t that may take array or object values.
const jsonKeys = ['lngs', 'fallbackLng', 'ns', 'postProcess', 'interpolation']

// Quick polyfill for Object.assign, because IE11 doesn't have that method.
function extend(target, ...sources) {
  sources.forEach(source => {
    if (source)
      for (const key in source) {
        target[key] = source[key]
      }
  })
  return target
}

export default function(key, { hash, data, fn }) {
  const parsed = {}
  jsonKeys.forEach(key => {
    if (hash[key]) {
      // Everything in jsonKeys is known not to belong in options.replace.
      // Everything in hash ends up in options.replace, so we limit
      // interpolation replacement polution by transferring these keys
      // to parsed.
      parsed[key] = JSON.parse(hash[key])
      delete hash[key]
    }
  })
  const options = extend({}, data.root.i18next, hash, parsed, { returnObjects: false })
  const replace = (options.replace = extend({}, this, options.replace, hash))
  delete replace.i18next // may creep in if this === data.root
  if (fn) options.defaultValue = fn(replace)
  return new Handlebars.SafeString(i18n.t(key, options))
}
