import React, { useCallback, useEffect, useState } from 'react'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { pdf } from '@react-pdf/renderer'

import ActionButton from './ActionButton'
import i18n from '../../../i18n'
import { AppState } from '../../../store'
import { HashMap } from '../../../utils/HashMap'
import { ClientSummaryData, WorkHistory as IWorkHistory } from '../../../store/autoresearch/types'
import { getSummaryData, getWorkHistory } from '../../../store/autoresearch/selectors'
import { fetchClientSummaryData, fetchClientWorkHistory } from '../../../store/autoresearch/actions'
import { Client } from '../../../store/clients/reducer'
import MyDocument from '../OrderDetails/ClientDetailPdf'
import { getClientOrders, getClientQuestionnaire } from '../../../selectors/clients'
import { Questionnaire } from '../../../store/orders/questionnaire/types'
import { Order } from '../../../store/orders/types'
import AIDraftPopup from './AIDraftPopup'
import { getAIDraftMagicLink, getIsAIDraftExists, getIsAIFeedbackExists } from '../../../store/aiDraft/selectors'
import { fetchAIDraftMagicLink, fetchIsAIFeedbackExists, submitOrSkipAIFeedback } from '../../../store/aiDraft/actions'
import useUserInfoState from '../../common/useUserInfo'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      border: '1px solid #D5D9E0',
      borderRadius: '.1875rem',
      color: 'black',
      '@media (max-width: 1024px)': {
        display: 'none',
      },
    },
    titleContainer: {
      padding: '.9375rem 1.5rem',
      borderBottom: '1px solid #D5D9E0',
    },
    title: {
      fontWeight: 600,
    },
    newDocument: {
      padding: '1.25rem 1.5rem 0 1.5rem',
    },
    container: {
      padding: '1.25rem 1.5rem',
      '&.top': {
        borderBottom: '1px solid #d5d9e0',
      },
      '&.files': {
        paddingBottom: 0,
      },
    },
    subtitle: {
      marginBottom: '.625rem',
      fontSize: '.8125rem',
      lineHeight: '1.125rem',
    },
    link: {
      display: 'block',
      textDecoration: 'underline',
      '&:not(:last-child)': {
        marginBottom: '.625rem',
      },
    },
    aiContainer: {
      paddingBottom: '1.25rem',
      marginTop: '1.25rem',
      borderBottom: '1px solid #d5d9e0',
      borderTop: '1px solid #d5d9e0',
    },
    aiTitle: {
      fontSize: '.8125rem',
      lineHeight: '1.125rem',
      padding: '1.25rem 1.5rem 0 1.5rem',
    },
  })
)

interface ToolkitProps {
  clientID: number
  documentDigest?: string | null
  resumeLocalizedName?: string
  showRezwiz?: boolean
  showAdminSection?: boolean
}

function getOMSURL() {
  const { host } = window.location

  if (host.includes('staging')) {
    return 'https://staging-oms.talentinc.com'
  } else if (host.includes('dev')) {
    return 'https://dev.oms.talentinc.com'
  }

  return 'https://oms.talentinc.com'
}

export const Toolkit: React.FC<ToolkitProps> = ({
  clientID,
  documentDigest,
  resumeLocalizedName = 'Resume',
  showRezwiz = true,
  showAdminSection = false,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const store = useStore()
  const { featureFlags } = useUserInfoState()

  const rezwizClick = () => {
    if (clientID && documentDigest) {
      const region = resumeLocalizedName === 'Resume' ? 'North America' : 'International'
      const settings = new URLSearchParams({
        cid: clientID.toString(),
        cs: documentDigest,
        def_region: region,
      })
      const windowSettings = {
        resizable: 1,
        scrollbars: 1,
        // The max dimensions of Rezwiz is 1600x900
        height: Math.min(Math.round(window.screen.height * 0.9), 900),
        width: Math.min(Math.round(window.screen.width * 0.9), 1600),
      }

      window.open(
        `${getOMSURL()}/rezwiz/#/settings?${settings.toString()}`,
        '_blank',
        Object.entries(windowSettings)
          .map(([key, val]) => `${key}=${val}`)
          .join(',')
      )
    }
  }

  const [pdfLoading, setPdfLoading] = useState(false)
  const [openAIDraftPopup, setOpenAIDraftPopup] = useState(false)
  const [resumeBuilderLoading, setResumeBuilderLoading] = useState(false)
  const [skipFeedbackLoading, setSkipFeedbackLoading] = useState(false)
  const [submitFeedbackLoading, setSubmitFeedbackLoading] = useState(false)

  const aiResumeEnqueued = JSON.parse(localStorage.getItem('aiResumeEnqueued') as string) ?? {}
  const currentDate = new Date()
  Object.entries(aiResumeEnqueued).forEach(([key, value]) => {
    const date = new Date(value as string)
    if (date.getTime() < currentDate.getTime()) {
      delete aiResumeEnqueued[key]
    }
  })
  localStorage.setItem('aiResumeEnqueued', JSON.stringify({ ...aiResumeEnqueued }))

  const quest = useSelector<AppState, Questionnaire | undefined>(store =>
    getClientQuestionnaire(store.clientQuestionnaireReducer, clientID)
  )

  const clientData = useSelector<AppState, Client>(state => state.clientReducer.clients[clientID])

  const orders = useSelector<AppState, Order[]>(store =>
    getClientOrders({ ...store.clientReducer, ...store.orderReducer }, clientID)
  ).filter(o => !!o)

  const isAIDraftExists = useSelector<AppState, boolean | null>(state =>
    getIsAIDraftExists(state.clientAIDraftReducer, clientID)
  )

  const isAIFeedbackExists = useSelector<AppState, boolean | null>(state =>
    getIsAIFeedbackExists(state.clientAIDraftReducer, clientID)
  )

  const isFirstDraftSent = orders.some(item => item.firstdraft_timestamp)
  const isActiveAndHasResumeItem = orders.some(
    item => item.status === 'open' && [1, 6, 14].some(id => item.order_items_core_product_ids.includes(id))
  )

  const workHistoryData = useSelector<AppState, HashMap<IWorkHistory>>(
    state => getWorkHistory(state.autoResearchReducer, clientID) ?? {}
  )

  const summaryData = useSelector<AppState, ClientSummaryData | undefined | null>(state =>
    getSummaryData(state.autoResearchReducer, clientID)
  )

  // fetch AI draft magic link if AI draft is generated
  useEffect(() => {
    if (isAIDraftExists) {
      dispatch(fetchAIDraftMagicLink(clientID))
    }
  }, [isAIDraftExists, clientID, dispatch])

  // fetch AI draft feedback exists if AI draft is generated
  useEffect(() => {
    if (isAIDraftExists) {
      dispatch(fetchIsAIFeedbackExists(clientID))
    }
  }, [isAIDraftExists, clientID, dispatch])

  const onDownloadInfo = useCallback(async () => {
    setPdfLoading(true)
    const sortedOrders = orders?.sort((a: Order, b: Order) => (a.due_date > b.due_date ? 1 : -1))
    const mostRecentOrder = sortedOrders[0]

    if (!Object.keys(workHistoryData).length) {
      try {
        await dispatch(fetchClientWorkHistory(clientID))
      } catch (error) {}
    }

    if (!Object.keys(summaryData ?? {}).length) {
      try {
        await dispatch(fetchClientSummaryData(clientID))
      } catch (error) {}
    }

    const state = store.getState()
    const currentWorkHistoryData = getWorkHistory(state.autoResearchReducer, clientID) ?? {}
    const currentSummaryData = getSummaryData(state.autoResearchReducer, clientID)

    const pdfDoc = pdf(
      <MyDocument
        clientData={clientData}
        workHistoryData={currentWorkHistoryData}
        quest={quest}
        order={mostRecentOrder}
        summaryData={currentSummaryData}
      />
    )

    const pdfBlob = await pdfDoc.toBlob()

    const a = document.createElement('a')
    a.setAttribute('download', `${clientData?.full_name ?? 'client_info'}.pdf`)
    const href = URL.createObjectURL(pdfBlob)
    a.href = href
    a.click()
    URL.revokeObjectURL(href)
    setPdfLoading(false)
  }, [workHistoryData, summaryData, clientData, quest, orders, clientID, store, dispatch])

  const onSubmitOrSkipAIFeedback = useCallback(
    async (skip: boolean) => {
      try {
        if (skip) {
          setSkipFeedbackLoading(true)
        } else {
          setSubmitFeedbackLoading(true)
        }
        await dispatch(submitOrSkipAIFeedback(clientID, skip))
      } catch (error) {
      } finally {
        setSkipFeedbackLoading(false)
        setSubmitFeedbackLoading(false)
      }
    },
    [dispatch, clientID]
  )

  const onOpenResumeBuilder = useCallback(async () => {
    setResumeBuilderLoading(true)
    try {
      await dispatch(fetchAIDraftMagicLink(clientID))
      const state = store.getState()
      const aiDraftMagicLink = getAIDraftMagicLink(state.clientAIDraftReducer, clientID)
      const a = document.createElement('a')
      a.setAttribute('target', '_blank')
      a.href = aiDraftMagicLink as string
      a.click()
    } catch (error) {
    } finally {
      setResumeBuilderLoading(false)
    }
  }, [clientID, dispatch, store])

  return (
    <Box className={classes.root}>
      <Box className={classes.titleContainer}>
        <Typography variant={'body1'} className={classes.title}>
          {i18n.t('messaging__side__toolKit')}
        </Typography>
      </Box>
      {showRezwiz && !!(clientID && documentDigest) && (
        <Box className={classes.newDocument}>
          <ActionButton handleClick={rezwizClick}>
            <Typography variant={'body2'}>{i18n.t('messaging__side__newDocument')}</Typography>
          </ActionButton>
        </Box>
      )}

      <Box className={classes.newDocument}>
        <ActionButton handleClick={onDownloadInfo} disabled={pdfLoading}>
          {pdfLoading ? (
            <CircularProgress color="primary" size="1rem" />
          ) : (
            <Typography variant={'body2'}>{i18n.t('order_page_download_client_info_btn')}</Typography>
          )}
        </ActionButton>
      </Box>

      {featureFlags.showAIActions && openAIDraftPopup ? (
        <AIDraftPopup clientID={clientID} onClose={() => setOpenAIDraftPopup(false)} />
      ) : null}
      {featureFlags.showAIActions &&
      (isAIDraftExists || (isAIDraftExists === false && !aiResumeEnqueued[clientID] && isActiveAndHasResumeItem)) ? (
        <Box className={classes.aiContainer}>
          <Typography variant={'body2'} className={classes.aiTitle}>
            {i18n.t('messaging__side__aiDraft')}
          </Typography>

          {isAIDraftExists === false && !aiResumeEnqueued[clientID] && isActiveAndHasResumeItem ? (
            <Box className={classes.newDocument}>
              <ActionButton handleClick={() => setOpenAIDraftPopup(true)}>
                <Typography variant={'body2'}>{i18n.t('messaging__side__generate_aiDraft_btn')}</Typography>
              </ActionButton>
            </Box>
          ) : null}

          {isAIDraftExists ? (
            <Box className={classes.newDocument}>
              <ActionButton handleClick={onOpenResumeBuilder} disabled={resumeBuilderLoading}>
                {resumeBuilderLoading ? (
                  <CircularProgress color="primary" size="1rem" />
                ) : (
                  <Typography variant={'body2'}>{i18n.t('messaging__side__open__resume__editor')}</Typography>
                )}
              </ActionButton>
            </Box>
          ) : null}

          {showAdminSection && isAIDraftExists && isAIFeedbackExists === false && isFirstDraftSent ? (
            <>
              <Box className={classes.newDocument}>
                <ActionButton
                  handleClick={() => onSubmitOrSkipAIFeedback(false)}
                  disabled={submitFeedbackLoading || skipFeedbackLoading}
                >
                  {submitFeedbackLoading ? (
                    <CircularProgress color="primary" size="1rem" />
                  ) : (
                    <Typography variant={'body2'}>{i18n.t('messaging__side__submit__aiFeedback')}</Typography>
                  )}
                </ActionButton>
              </Box>

              <Box className={classes.newDocument}>
                <ActionButton
                  handleClick={() => onSubmitOrSkipAIFeedback(true)}
                  disabled={submitFeedbackLoading || skipFeedbackLoading}
                >
                  {skipFeedbackLoading ? (
                    <CircularProgress color="primary" size="1rem" />
                  ) : (
                    <Typography variant={'body2'}>{i18n.t('messaging__side__skip__aiFeedback')}</Typography>
                  )}
                </ActionButton>
              </Box>
            </>
          ) : null}
        </Box>
      ) : null}

      <Box className={`${classes.container} top`}>
        <Typography variant={'body2'} className={classes.subtitle}>
          {i18n.t('messaging__side__template')}
        </Typography>
        <Link
          href="http://help.experts.talentinc.com/en/collections/2502793-formats-samples"
          target="_blank"
          className={classes.link}
        >
          <Typography variant={'body1'}>{i18n.t('messaging__side__link__suggestedResumes')}</Typography>
        </Link>
        <Link
          href="http://help.experts.talentinc.com/en/articles/4404342-linkedin-how-to"
          target="_blank"
          className={classes.link}
        >
          <Typography variant={'body1'}>{i18n.t('messaging__side__link__linkedinProfile')}</Typography>
        </Link>
      </Box>
      <Box className={classNames([classes.container, showAdminSection && 'top'])}>
        <Typography variant={'body2'} className={classes.subtitle}>
          {i18n.t('messaging__side__knowledgeShortcut')}
        </Typography>
        <Link
          href="http://help.experts.talentinc.com/en/articles/3991555-optimizing-word-choice"
          target="_blank"
          className={classes.link}
        >
          <Typography variant={'body1'}>{i18n.t('messaging__side__link__activeVerbs')}</Typography>
        </Link>
        <Link
          href="http://help.experts.talentinc.com/en/articles/4472135-strategy-scripts"
          target="_blank"
          className={classes.link}
        >
          <Typography variant={'body1'}>{i18n.t('messaging__side__link__strategyScripts')}</Typography>
        </Link>
      </Box>
      {showAdminSection && clientID && (
        <Box className={classes.container}>
          <Typography variant={'body2'} className={classes.subtitle}>
            {i18n.t('messaging__side__adminShortcuts')}
          </Typography>
          <Link href={`${getOMSURL()}/?client=${clientID}`} target="_blank" className={classes.link}>
            <Typography variant={'body1'}>{i18n.t('messaging__side__link__clasicoLink')}</Typography>
          </Link>
        </Box>
      )}
    </Box>
  )
}

export default React.memo(Toolkit)
