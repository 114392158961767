import { FormFieldSchema } from './formSchema'
import { AutoLinkedinSections } from '../../store/autolinkedin/types'
import React from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import classNames from 'classnames'
import { decorators } from '../Messaging/Decorators'
import { Editor } from 'react-draft-wysiwyg'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import { cleanEditorState, fromPlainTextWithBullets, toPlainTextWithBullets } from '../../utils/draftjs'
import i18n from '../../i18n'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { ALIFormFieldProps } from './ALIFormField'
import clsx from 'clsx'
import useLocalEditorState from './useLocalEditorState'
import { FieldValidator } from 'final-form'

export interface RichTextFieldProps extends ALIFormFieldProps {
  fieldSchema: FormFieldSchema
  outerName: string
  index: number
  section: AutoLinkedinSections
  pristine: boolean
  labelClass: string
  inputClass: string
  inputGroupClass: string
  readOnly: boolean
  validate?: FieldValidator<string>
}

const useStyles = makeStyles(() =>
  createStyles({
    editor: {
      padding: '0 0.625rem',
      fontFamily: styles.fonts.primary.family,
      fontWeight: 'normal',
      maxHeight: '25vh',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    toolbar: {
      marginTop: '.5rem',
    },
    error: {
      color: `${styles.palette.pink.hex} !important`,
    },
    labels: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
)

export const RichTextField: React.FC<RichTextFieldProps> = ({
  fieldSchema,
  outerName,
  section,
  labelClass,
  inputClass,
  inputGroupClass,
  readOnly,
  validate,
}) => {
  const classes = useStyles()

  const editorConfig = useLocalEditorState()

  return (
    <Field validate={validate} name={`${outerName}.${fieldSchema.name}`} key={`${outerName}.${fieldSchema.name}`}>
      {(props: FieldRenderProps<string>) => {
        if (!editorConfig.editorState) {
          editorConfig.setEditorState(
            EditorState.createWithContent(convertFromRaw(fromPlainTextWithBullets(props.input.value)))
          )
        }
        return (
          <>
            <Box className={classes.labels}>
              <Typography
                className={classNames({
                  [labelClass]: true,
                  [classes.error]: !!props.meta.error,
                })}
              >
                {i18n.t(`alink__${section}__${fieldSchema.name}`)}
              </Typography>
              {!!props.meta.error && (
                <Typography
                  className={classNames({
                    [labelClass]: true,
                    [classes.error]: true,
                  })}
                >
                  {props.meta.error}
                </Typography>
              )}
            </Box>
            <Editor
              onFocus={() => {
                if (editorConfig.toolbarHidden) {
                  editorConfig.setToolbarHidden(false)
                }
              }}
              onBlur={() => {
                if (!editorConfig.toolbarHidden) {
                  editorConfig.setToolbarHidden(true)
                }
              }}
              editorClassName={clsx([
                classes.editor,
                inputClass,
                inputGroupClass,
                !!props.meta.error ? 'Mui-error' : null,
                `auto-linkedin-${outerName}-${fieldSchema.name}`,
              ])}
              readOnly={readOnly}
              toolbarClassName={classes.toolbar}
              editorState={editorConfig.editorState}
              onEditorStateChange={(newValue: EditorState) => {
                editorConfig.setEditorState(previousEditorState => {
                  const lastChange = newValue.getLastChangeType()
                  const shouldFilterCopyPaste =
                    (!previousEditorState ||
                      previousEditorState.getCurrentContent() !== newValue.getCurrentContent()) &&
                    (lastChange === 'insert-fragment' || lastChange === 'insert-characters')

                  if (shouldFilterCopyPaste) {
                    return cleanEditorState(newValue)
                  }
                  return newValue
                })
                props.input.onChange(toPlainTextWithBullets(convertToRaw(newValue.getCurrentContent())))
              }}
              toolbarHidden={editorConfig.toolbarHidden}
              toolbar={{
                options: ['list'],
                inline: {
                  options: [],
                },
              }}
              spellCheck={true}
              handlePastedText={editorConfig.handlePastedText}
              customDecorators={decorators}
            />
          </>
        )
      }}
    </Field>
  )
}

export default RichTextField
