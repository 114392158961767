import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import DocumentIcon from '@material-ui/icons/InsertDriveFileOutlined'

import i18n from '../../../i18n'
import styles from '../../../styles'

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      fontFamily: styles.fonts.primary.family,
      fontSize: '.9375rem',
      color: '#5b5b64',
      textAlign: 'center',
      lineHeight: '1.2rem',
      display: 'block',
    },
    icon: {
      color: styles.palette.link.color,
      verticalAlign: 'bottom',
      height: '1.2rem',
    },
  })
)

const TemplateLink: React.FC = () => {
  const classes = useStyles()
  const url = 'https://kb.talentinc.com/knowledge-base/the-ladders-formats/'

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className={classes.link}>
      <DocumentIcon className={classes.icon} />
      {i18n.t('templateLinks__suggestedTemplate')}
    </a>
  )
}

export default TemplateLink
