// Helper function that takes in a full customer name and will trim it so that only the first name is returned
export default function(name: string): string {
  // If there isn't a space, then that's all we have
  if (name.indexOf(' ') === -1) {
    return name.trim()
  }

  const firstName = name.split(' ')[0].trim()

  if (firstName.toUpperCase() === firstName || firstName.toLowerCase() === firstName) {
    return firstName.toUpperCase()
  }

  return firstName
}
