import React, { useCallback } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'

import i18n from '../../i18n'
import Button from '../common/Button'
import ScanItem from './ScanItem'
import Header from '../common/Header'
import Footer from '../common/Footer'
import ModalWrapper from '../common/ModalWrapper'
import UploadStyles from './styles'
import ReuploadButton from './ReuploadButton'
import { AutoQAScanResult, UploadOverview } from '../../store/uploads/types'
import { HashMap } from '../../utils/HashMap'
import { allScansCompleted, ScanErrors } from '../../selectors/uploads'
import { FeatureKeys, FeedbackBoolPayload } from '../../store/featureFeedback/types'
import useFeatureFeedback from '../common/useFeatureFeedback'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { getFeedbackByFeatureIDs } from '../../store/featureFeedback/selectors'
import { constructAutoQAFeatureFeedbackElement, getAutoQAIDsFromScans } from '../../selectors/autoQA'
import { UploadGoal } from '../../store/documents/types'
import { LoadingErrorState } from '../../utils/state'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginBottom: '1rem',
      },
    },
    cancelButton: {
      marginRight: '1.2rem',
      padding: '.5rem 1.547rem',
    },
    submitButton: {
      padding: '.5rem 1.547rem',
    },
    reuploadInput: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '200%',
      transform: 'translateY(-50%)',
      opacity: 0,
      cursor: 'pointer',
    },
    alerts: {
      color: UploadStyles.pink.hex,
      textTransform: 'uppercase',
      fontWeight: 600,

      '& > svg': {
        verticalAlign: 'text-bottom',
        marginRight: '0.9rem',
      },
    },
  })
)

interface ScanProps {
  scans: UploadOverview[]
  goal: UploadGoal
  scansErrors: HashMap<ScanErrors>
  onAdvanceToImprovementsClick: () => void
  removeUpload: (filename: string) => void
  onCancel: () => void
  onFinalizeUploadClick: () => void
  reuploadFile: (file: File, filenameToRemove: string) => void
  canSkipAQAFeedback: boolean
}

const Scan: React.FC<ScanProps> = ({
  scans,
  goal,
  onCancel,
  onAdvanceToImprovementsClick,
  onFinalizeUploadClick,
  removeUpload,
  reuploadFile,
  canSkipAQAFeedback,
}) => {
  const classes = useStyles()

  const initialFeedback = useSelector<AppState, HashMap<HashMap<FeedbackBoolPayload>>>(state =>
    getFeedbackByFeatureIDs(state.featureFeedbackReducer, getAutoQAIDsFromScans(scans))
  )

  const feedbackLoadingMeta = useSelector<AppState, LoadingErrorState>(
    state => state.featureFeedbackReducer.meta[FeatureKeys.FilePreprocess]
  )

  const { updateFeedback, submitFeedback, feedback } = useFeatureFeedback(FeatureKeys.FilePreprocess, initialFeedback)
  const valid = isValid(scans, feedback)

  const showImprovementCompilation = Object.values(feedback)
    .map(f => Object.values(f))
    .flat()
    .some(f => f.feedback_bool === 1)

  const onNextClick = useCallback(async () => {
    if (!valid) {
      return
    }
    if (
      Object.values(feedback)
        .map(f => Object.values(f))
        .flat()
        .some(f => f.feedback_bool !== null)
    ) {
      await submitFeedback(feedback, 'needs_editing')
    }
    if (!showImprovementCompilation) {
      onFinalizeUploadClick()
    } else {
      onAdvanceToImprovementsClick()
    }
  }, [feedback, onFinalizeUploadClick, submitFeedback, valid, showImprovementCompilation, onAdvanceToImprovementsClick])

  const scanning = !allScansCompleted(scans)

  const removeItem = useCallback(
    (filename: string) => {
      removeUpload(filename)

      if (scans.length - 1 === 0) {
        onCancel()
      }
    },
    [removeUpload, onCancel, scans.length]
  )

  return (
    <ModalWrapper>
      <Header
        activeStep={1}
        title={i18n.t(scanning ? 'uploadsv3__scan__scanningTitle' : 'uploadsv3__scan__blockingTitle')}
      />
      <Box className={classes.body}>
        {scans.map(scan => (
          <ScanItem
            feedback={feedback}
            updateFeedback={updateFeedback}
            key={`scan-item-${scan.pending.file.name}`}
            scan={scan}
            removeItem={() => removeItem(scan.pending.file.name)}
          />
        ))}
      </Box>
      {!scanning && (
        <Footer
          leftColumn={
            <>
              {valid.warningCount > 0 && (
                <Typography className={classes.alerts}>
                  <WarningIcon />
                  {i18n.t('uploadsv3__scan__totalAlerts', { count: valid.warningCount })}
                </Typography>
              )}
            </>
          }
          rightColumn={
            <>
              <ReuploadButton scans={scans} reuploadFile={reuploadFile} buttonType="secondary" />
              {!scanning && (
                <Button
                  type="contrast"
                  className={classes.submitButton}
                  onClick={onNextClick}
                  disabled={(!canSkipAQAFeedback && !valid.isValid) || feedbackLoadingMeta.isLoading}
                >
                  {showImprovementCompilation && i18n.t('messaging__docs__btn__next')}
                  {!showImprovementCompilation &&
                    goal === UploadGoal.Attach &&
                    i18n.t('messaging__docs__btn__attach', { count: scans.length })}
                  {!showImprovementCompilation &&
                    (goal === UploadGoal.Upload || goal === UploadGoal.AutoLinkedin) &&
                    i18n.t('messaging__docs__btn__finalize')}
                </Button>
              )}
            </>
          }
        />
      )}
    </ModalWrapper>
  )
}

export default Scan

interface ScanValidation {
  isValid: boolean
  shouldValidate: boolean
  warningCount: number
}

interface ResultBit {
  featureID: number
  featureElement: string
}
const isValid = (scans: UploadOverview[], feedback: HashMap<HashMap<FeedbackBoolPayload>>): ScanValidation => {
  let valid = true
  let warningCount = 0
  let shouldValidate = false

  const scansWithAutoQAResults = scans.filter(scan => !!scan.preprocess?.auto_qa_async)

  if (
    scansWithAutoQAResults &&
    scansWithAutoQAResults.length > 0 &&
    // @ts-ignore
    scansWithAutoQAResults.every(scan => scan.preprocess?.auto_qa_async.meta.loaded)
  ) {
    const failedAutoQAScans = scansWithAutoQAResults.filter(
      scan =>
        // @ts-ignore
        !!scan.preprocess?.auto_qa_async?.result?.output?.total_result &&
        // @ts-ignore
        scan.preprocess?.auto_qa_async?.result?.output?.total_result !== AutoQAScanResult.Pass
    )

    if (failedAutoQAScans.length === 0) {
      return {
        shouldValidate: shouldValidate,
        isValid: valid,
        warningCount,
      }
    }

    shouldValidate = true

    const requiredAutoQAResults = failedAutoQAScans
      .map(s =>
        // @ts-ignore
        s.preprocess?.auto_qa_async?.result?.output.results
          // @ts-ignore
          .filter(r => !!r.details && r.details.total_score > 0)
      )
      .flat()

    const requiredAutoQAResultBits = Object.values(requiredAutoQAResults)
      .flat()
      .map(x => {
        return {
          feature_key: x.feature,
          feature_id: x.id,
          data:
            x.details.stems ||
            x.details.differences ||
            x.details.phrases ||
            x.details.sentences ||
            x.details.sections ||
            [],
        }
      })
      .reduce((acc: ResultBit[], val) => {
        // @ts-ignore
        val.data.forEach((d, i) => {
          acc.push({
            featureElement: constructAutoQAFeatureFeedbackElement(val.feature_key, d.stem || d.key || i),
            featureID: val.feature_id,
          })
        })
        return acc
      }, [])

    if (requiredAutoQAResults.length > 0) {
      if (!feedback) {
        warningCount = requiredAutoQAResults.length
      } else {
        const invalidFields = requiredAutoQAResultBits.filter(
          r =>
            (!!r && !feedback[r.featureID]) ||
            !feedback[r.featureID][r.featureElement] ||
            !(
              feedback[r.featureID][r.featureElement].feedback_bool === 0 ||
              feedback[r.featureID][r.featureElement].feedback_bool === 1
            )
        )
        warningCount = invalidFields.length
      }
      valid = warningCount === 0
    }
  }
  return {
    shouldValidate: shouldValidate,
    isValid: valid,
    warningCount,
  }
}
