import React from 'react'
import differenceInDays from 'date-fns/differenceInDays'
import isAfter from 'date-fns/isAfter'
import differenceInHours from 'date-fns/differenceInHours'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import i18n from '../../i18n'

export const week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
export const dayOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']

export const setWeeklyPeriod = (start_time: string, end_time: string) => {
  const weekDay = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
  return weekDay.map(a => ({
    day: a,
    start_time: start_time,
    end_time: end_time,
  }))
}

export const callTime = (date: Date, start_time: Date) => {
  const day = differenceInDays(start_time, date)
  let hr = differenceInHours(start_time, date)
  let min = differenceInMinutes(start_time, date)

  if (isAfter(start_time, date)) {
    if (day >= 1) {
      hr = hr - day * 24
      const dayMsg =
        day > 1 ? `${day} ${i18n.t('schedule_calendar_in_days')}` : `${day} ${i18n.t('schedule_calendar_in_day')}`
      const hrMsg = hr > 0 ? `${hr} ${i18n.t('schedule_calendar_in_hr')}` : null
      return (
        <>
          {i18n.t('schedule_calendar_in')} {dayMsg} {hrMsg}
        </>
      )
    } else if (hr < 1) {
      return (
        <>
          {i18n.t('schedule_calendar_in')} {min} {i18n.t('schedule_calendar_in_min')}
        </>
      )
    } else {
      min = min - hr * 60
      const minMsg = min > 0 ? `${min} ${i18n.t('schedule_calendar_in_min')}` : null
      return (
        <>
          {i18n.t('schedule_calendar_in')} {hr} {i18n.t('schedule_calendar_in_hr')} {minMsg}
        </>
      )
    }
  } else if (hr === 0 && min > -30) {
    return <>{i18n.t('schedule_calendar_start_now')}</>
  } else {
    return <>{i18n.t('schedule_calendar_missed')}</>
  }
}
