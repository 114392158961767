import React, { useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { fire } from 'delegated-events'

import { applyTemplateBasedUponOrderCTA } from '../../store/templates/actions'
import OrderAction from './OrderAction'
import { applyAttachmentsBasedUponOrderCTA } from '../../store/documents/actions'
import { OrderItem, OrderItemDisplayStates, OrderItemStates } from '../../store/items/types'
import { MacroState } from '../../utils/mapper'
import { CTAToEventTypeMap, VulcanEventPayload, VulcanEventContext } from '../../store/events/types'
import { sendEventThunk } from '../../store/events/actions'
import { HashMap } from '../../utils/HashMap'
import { getItemByIDSelector, schedulerItemTypesSelector } from '../../selectors/items'
import { AppState } from '../../store'
import { UploadDocTypeMenu } from '../../store/uploads/types'
import { productsOrderIDSelector } from '../../selectors/orders'
import { fetchCIOMagicLinkInIntervals } from '../../store/clients/actions'
import { clientByIdSelector, getCIOMagicLink } from '../../selectors/clients'
import { closeOrderByProductsOrderID, fetchClientOrderDetailsThunk } from '../../store/clientOrderAggregate/actions'

interface OrderActionContainerProps {
  userID?: number | null
  size?: string
  clientID: number
  macroState: MacroState
  hideFarewell?: boolean
  hasResumeEditDoc?: boolean
}

const OrderActionContainer: React.FC<OrderActionContainerProps> = props => {
  const { userID, size, clientID, macroState, hideFarewell, hasResumeEditDoc } = props
  const dispatch = useDispatch()
  const store = useStore()

  const { orderID, CTA, productID } = macroState

  const [loading, setLoading] = useState(false)

  const options = useSelector<AppState, UploadDocTypeMenu[]>(state =>
    schedulerItemTypesSelector(state.itemsReducer, [orderID ? orderID : 0])
  )

  const productsOrderID = useSelector<AppState, number | null>(state =>
    productsOrderIDSelector(state.orderReducer, orderID)
  )

  const item = useSelector<AppState, OrderItem | undefined>(state =>
    getItemByIDSelector(state.itemsReducer, !!macroState.productID ? macroState.productID : 0)
  )

  const sendEvent = React.useCallback(
    async (variables: HashMap<null | number | number[] | Date>) => {
      const eventType = CTAToEventTypeMap[CTA]
      if (!eventType) {
        return
      }
      const event: VulcanEventPayload = {
        context: VulcanEventContext.Client,
        context_id: clientID,
        event: eventType,
        origin: 'internal',
        variables: variables,
      }
      await dispatch(sendEventThunk(event))
    },
    [clientID, CTA, dispatch]
  )

  const scheduleHandler = React.useCallback(
    async (date: Date, itemID: number) => {
      const variables = {
        item_id: itemID,
        scheduled_date: date,
        products_order_ids: !!productsOrderID ? [productsOrderID] : [],
      }
      await sendEvent(variables)
    },
    [sendEvent, productsOrderID]
  )

  const completeHandler = React.useCallback(async () => {
    const variables = {
      item_id: productID,
      products_order_ids: !!productsOrderID ? [productsOrderID] : [],
    }
    await sendEvent(variables)
  }, [productID, sendEvent, productsOrderID])

  const clickHandler = React.useCallback(async () => {
    if (CTA === OrderItemStates.ConductCall || CTA === OrderItemStates.ConductInterview) {
      await completeHandler()
      return
    }

    setLoading(true)
    if (CTA === OrderItemDisplayStates.CloseOrder && productsOrderID) {
      try {
        await dispatch(closeOrderByProductsOrderID(clientID, productsOrderID as number))
      } catch (error) {
        setLoading(false)
        return
      }
      try {
        await dispatch(fetchClientOrderDetailsThunk(clientID, { verbose: true }))
      } catch (error) {}
    }

    if ((CTA === OrderItemStates.Closed || CTA === OrderItemDisplayStates.CloseOrder) && productsOrderID) {
      const state = store.getState()
      const clientData = clientByIdSelector(state.clientReducer, clientID)
      const productUserID = clientData?.products_user_id

      try {
        await dispatch(fetchCIOMagicLinkInIntervals(clientID, productUserID, productsOrderID as number))
      } catch (error) {}
    }

    const state = store.getState()
    const cioMagicLink = getCIOMagicLink(state.clientReducer, clientID)

    if (!((CTA === OrderItemStates.Closed || CTA === OrderItemDisplayStates.CloseOrder) && cioMagicLink)) {
      await dispatch(applyAttachmentsBasedUponOrderCTA(clientID, CTA))
    }

    if (!!orderID) {
      await dispatch(applyTemplateBasedUponOrderCTA(userID || null, clientID, orderID, CTA))
    }

    setLoading(false)

    const body = document.querySelector('body')
    if (body) {
      fire(body, 'cta:click', { cta: CTA })
    }
  }, [clientID, userID, orderID, CTA, completeHandler, dispatch, productsOrderID, store])

  return (
    <OrderAction
      meetingID={item?.meeting_id}
      macroState={macroState}
      schedulerOptions={options}
      clientID={clientID}
      onClick={async () => await clickHandler()}
      onSchedule={async (date: Date, itemID: number) => await scheduleHandler(date, itemID)}
      size={size}
      loading={loading}
      hideFarewell={!!hideFarewell}
      isResumeEdit={item?.core_product_id === 14}
      hasResumeEditDoc={!!hasResumeEditDoc}
    />
  )
}

export default OrderActionContainer
