import React, { Dispatch, SetStateAction } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '../../common/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import i18n from '../../../i18n'
import { Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { ZoomMeetingData } from '../../../store/scheduling/types'

export interface JoinMeetingDialogProps {
  isOpen: boolean
  handleZoomViaBrowser: (event: React.SyntheticEvent<EventTarget>) => void
  setIsOpen?: Dispatch<SetStateAction<boolean>>
  zoomMeetingDetails: ZoomMeetingData
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '1.5em',
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '0 2em 0 0',
      marginBottom: '1em',
    },
    modalTitle: {
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: '1.25em',
      lineHeight: 1.25,
    },
    modalBody: {
      fontSize: '.9em',
      fontWeight: 300,
      marginBottom: '1em',
      paddingRight: '12rem',
    },
    modalSub: {
      fontSize: '.9em',
      fontWeight: 600,
    },
    modalSubNo: {
      color: 'black',
      fontSize: '.9em',
    },
    buttonsRight: {
      float: 'right',
    },
    closeIcon: {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      width: '1rem',
      height: '1rem',
    },
  })
)

const JoinMeetingDialog: React.FC<JoinMeetingDialogProps> = ({
  isOpen,
  handleZoomViaBrowser,
  setIsOpen,
  zoomMeetingDetails,
}) => {
  const classes = useStyles()
  return (
    <Dialog maxWidth={'xl'} open={isOpen}>
      <IconButton className={classes.closeIcon} onClick={() => (setIsOpen ? setIsOpen(false) : null)}>
        <CloseIcon />
      </IconButton>

      <Box className={classes.root}>
        <DialogTitle disableTypography={true} classes={{ root: classes.title }}>
          <Typography className={classes.modalTitle}>{i18n.t('schedule_zoom_call_join')}</Typography>
        </DialogTitle>

        <Typography className={classes.modalBody}>{i18n.t('schedule_zoom_call_join_sub')}</Typography>
        <Typography className={classes.modalBody}>
          <Typography className={classes.modalSub}>{i18n.t('schedule_zoom_call_phone')}</Typography>
          <a
            className={classes.modalSubNo}
            href={`tel:${zoomMeetingDetails.zoom_phone_numbers[0].number},${zoomMeetingDetails.zoom_meeting_id}#`}
          >
            {zoomMeetingDetails.zoom_phone_numbers[0].country}: {zoomMeetingDetails.zoom_phone_numbers[0].number}
            {'  '}
            PIN: {zoomMeetingDetails.zoom_meeting_id}
          </a>
        </Typography>
        <DialogActions className={classes.buttonsRight}>
          <Button
            type="secondary"
            href={zoomMeetingDetails.zoom_start_url}
            // @ts-ignore
            target="_blank"
          >
            <Typography>{i18n.t('schedule_zoom_call_app')}</Typography>
          </Button>
          <Button type="contrast" onClick={handleZoomViaBrowser}>
            <Typography>{i18n.t('schedule_zoom_call_browser')}</Typography>
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default JoinMeetingDialog
