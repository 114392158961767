import { Client, ClientsState, Upsell } from '../store/clients/reducer'
import { ClientQuestionnaireState } from '../store/questionnaire/reducer'
import { Selector } from 'reselect/src'
import { createSelector, ParametricSelector } from 'reselect'
import { ordersSelector } from './items'
import { OrdersState } from '../store/orders/reducer'
import { HashMap } from '../utils/HashMap'
import { Order } from '../store/orders/types'
import { ClientOrdersAggregateState } from '../store/clientOrderAggregate/reducer'
import { MacroState } from '../utils/mapper'
import { Questionnaire } from '../store/orders/questionnaire/types'
import { isUrl } from '../utils/isUrl'
export const clientsSelector: Selector<ClientsState, Client[]> = state => Object.values(state.clients)

export const clientStatesSelector: Selector<ClientOrdersAggregateState, HashMap<MacroState>> = state => state.states

export const clientByIdSelector: ParametricSelector<ClientsState, number, Client> = (state, id) => state.clients[id]

export const clientStateByIdSelector: ParametricSelector<ClientOrdersAggregateState, number, MacroState> = (
  state,
  id
) => state.states[id]

// This selector will return this user's client orders
export const getClientOrders = createSelector<ClientsState & OrdersState, number, Client, HashMap<Order>, Order[]>(
  [clientByIdSelector, ordersSelector],
  (client, orders) => (!client || Object.values(orders).length === 0 ? [] : client.order_ids.map(id => orders[id]))
)

export const clientLoaded = (state: ClientsState, clientID: number) => {
  if (state.clients[clientID]) {
    return true
  }

  const meta = state.meta.individual[clientID]
  return !!meta && !meta.isLoading && !meta.error && meta.loaded
}
export const clientDetailsNeedFetching = createSelector(
  getClientOrders,
  orders => orders.length === 0 || !orders.every(order => !!order && order.is_verbose)
)

export const clientQuestionnaireLoaded = (state: ClientQuestionnaireState, clientID: number) => {
  if (state.questionnaires[clientID]) {
    return true
  }

  const meta = state.meta.individual[clientID]
  return !!meta && !meta.isLoading && !meta.error && meta.loaded
}

export const getClientQuestionnaire = (
  state: ClientQuestionnaireState,
  clientID: number
): Questionnaire | undefined => {
  try {
    return state.questionnaires[clientID]
  } catch (e) {
    return undefined
  }
}

export const getClientGoals = (state: ClientQuestionnaireState, clientID: number): string | undefined => {
  return !!state.questionnaires[clientID]
    ? state.questionnaires[clientID].questions?.goals?.answers
        .filter(goal => !!goal.answer.description || (!!!goal.answer.description && !isUrl(goal.answer.url)))
        .map(realGoal => (!!realGoal.answer.description ? realGoal.answer.description : realGoal.answer.url))
        .join(', ')
    : undefined
}

export const clientCanGetDocsInEmail = (client: Client): boolean => client.productsMetadata?.docs_in_email !== false

export const clientIDCanGetDocsInEmail = (state: ClientsState, clientID: number) => {
  if (!state.clients?.[clientID]) {
    return true
  }

  return clientCanGetDocsInEmail(state.clients[clientID])
}

export const getUpsells = (state: ClientsState, clientID: number): Upsell[] => {
  try {
    return state.upsells[clientID] ?? []
  } catch (e) {
    return []
  }
}

export const getCIOMagicLink = (state: ClientsState, clientID: number): string => {
  return state.clients?.[clientID]?.CIOMagicLink ?? ''
}
