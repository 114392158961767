import { FetchOrdersSuccess, FetchOrderSuccess } from '../orders/actions'
import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareAction,
  AxiosMiddlewareActionSuccess,
  AxiosMiddlewareActionFail,
} from '../../utils/axios'
import { OrderItem } from './types'
import { FetchClientOrdersSuccess, FetchUserAggregatedOrdersSuccess } from '../clientOrderAggregate/actions'
import {
  GetScheduledItemMeetingDetails,
  GetScheduledItemMeetingDetailsFail,
  GetScheduledItemMeetingDetailsSuccess,
} from '../scheduling/actions'

export enum ItemsActionTypes {
  FETCH_ITEM = 'FETCH_ITEM',
  FETCH_ITEM_SUCCESS = 'FETCH_ITEM_SUCCESS',
  FETCH_ITEM_FAIL = 'FETCH_ITEM_FAIL',

  GET_SCHEDULED_ITEM_MEETING_DETAILS = 'GET_SCHEDULED_ITEM_MEETING_DETAILS',
  GET_SCHEDULED_ITEM_MEETING_DETAILS_SUCCESS = 'GET_SCHEDULED_ITEM_MEETING_DETAILS_SUCCESS',
  GET_SCHEDULED_ITEM_MEETING_DETAILS_FAIL = 'GET_SCHEDULED_ITEM_MEETING_DETAILS_FAIL',
}

export interface FetchItem extends AxiosMiddlewareActionCreator {
  type: typeof ItemsActionTypes.FETCH_ITEM
}
export interface FetchItemStart extends AxiosMiddlewareAction {
  type: typeof ItemsActionTypes.FETCH_ITEM
}
export interface FetchItemSuccess extends AxiosMiddlewareActionSuccess<OrderItem> {
  type: typeof ItemsActionTypes.FETCH_ITEM_SUCCESS
}
export interface FetchItemFail extends AxiosMiddlewareActionFail {
  type: typeof ItemsActionTypes.FETCH_ITEM_FAIL
}

export function fetchItem(itemID: number): FetchItem {
  return {
    type: ItemsActionTypes.FETCH_ITEM,
    payload: {
      request: {
        url: `/v1/items/${itemID}`,
      },
    },
  }
}
export type ItemsActionType =
  | FetchOrdersSuccess
  | FetchItemSuccess
  | FetchItemFail
  | FetchItemStart
  | FetchOrderSuccess
  | FetchUserAggregatedOrdersSuccess
  | FetchClientOrdersSuccess
  | GetScheduledItemMeetingDetails
  | GetScheduledItemMeetingDetailsFail
  | GetScheduledItemMeetingDetailsSuccess
