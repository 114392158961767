import React, { useEffect } from 'react'

import auth0 from '../../utils/auth0'
import Loader from '../common/Loader'
import { setPostLoginRedirectFromUrl } from '../../utils/auth'

export const LoginPage: React.FC = () => {
  setPostLoginRedirectFromUrl() // handle redirect options

  useEffect(() => {
    auth0.authorize()
  }, [])

  return <Loader fullPage={true} />
}

export default LoginPage
