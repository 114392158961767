import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'

import Button from '../common/Button'
import i18n from '../../i18n'
import styles from '../../styles'
import { GhostLink } from '../../utils/link'

const useStyles = makeStyles(() =>
  createStyles({
    offerRejectionLimit: {
      color: styles.palette.lightGrey.color,
      textAlign: 'center',
    },
    headline: {
      fontSize: '2.25rem',
      color: styles.palette.lightBlack.color,
      marginBottom: '2.125rem',
    },
    bodyText: {
      color: styles.palette.lightBlack.color,
      marginBottom: '3.1125rem',
      padding: '0 7.5rem',
      '@media (max-width: 900px)': {
        padding: '0 4rem',
      },
      '@media (max-width: 768px)': {
        padding: 0,
      },
    },
    leftButton: {
      marginRight: '2.1875rem',
    },
  })
)

const OfferRejectionLimit: React.FC = () => {
  const classes = useStyles()

  return (
    <Box className={classes.offerRejectionLimit}>
      <Typography display="block" className={classes.headline}>
        {i18n.t('order__shopping__offer__rejections__limit__headline')}
      </Typography>
      <Typography display="block" variant={'body1'} className={classes.bodyText}>
        {i18n.t('order__shopping__offer__rejections__limit__bodyText')}
      </Typography>
      <GhostLink to={`/`} replace>
        <Button type={'primary'} size={'medium'}>
          {i18n.t('order__shopping__offer__rejections__limit__review__assigned__work')}
        </Button>
      </GhostLink>
    </Box>
  )
}

export default OfferRejectionLimit
