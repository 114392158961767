import { AutoLinkedinSections } from '../../store/autolinkedin/types'
import { HashMap } from '../../utils/HashMap'

export interface FormSchema {
  properties: FormFieldSchema[]
  required: string[]
  maxLength?: Record<string, number>
}

export enum ControlTypes {
  Location = 'location',
  Text = 'text',
  TimePeriodMonthYearWithCurrent = 'timePeriodMonthYearWithCurrent',
  TimePeriodMonthYear = 'timePeriodMonthYear',
  TimePeriodYear = 'timePeriodYear',
  FieldsOfStudy = 'fieldsOfStudy',
  RichText = 'richText',
  TeamMembers = 'teamMembers',
  Checkbox = 'checkBox',
  Date = 'date',
}

export interface FormFieldSchema {
  name: string
  type: ControlTypes
  propertyOrder: number
}

export const WorkExperienceEntrySchema: FormSchema = Object.freeze({
  properties: [
    {
      name: 'title',
      type: ControlTypes.Text,
      propertyOrder: 1,
    },
    {
      name: 'companyName',
      type: ControlTypes.Text,
      propertyOrder: 2,
    },
    {
      name: 'location',
      type: ControlTypes.Location,
      propertyOrder: 3,
    },
    {
      name: 'timePeriod',
      type: ControlTypes.TimePeriodMonthYearWithCurrent,
      propertyOrder: 4,
    },
    {
      name: 'description',
      type: ControlTypes.RichText,
      propertyOrder: 5,
    },
  ],
  required: ['companyName', 'title'],
  maxLength: {
    title: 100,
    description: 2000,
  },
})

export const EducationEntrySchema: FormSchema = Object.freeze({
  properties: [
    {
      name: 'schoolName',
      type: ControlTypes.Text,
      propertyOrder: 1,
    },
    {
      name: 'degreeName',
      type: ControlTypes.Text,
      propertyOrder: 2,
    },
    {
      name: 'fieldsOfStudy',
      type: ControlTypes.FieldsOfStudy,
      propertyOrder: 3,
    },
    {
      name: 'timePeriod',
      type: ControlTypes.TimePeriodYear,
      propertyOrder: 4,
    },
    {
      name: 'grade',
      type: ControlTypes.Text,
      propertyOrder: 4,
    },
    {
      name: 'activities',
      type: ControlTypes.RichText,
      propertyOrder: 5,
    },
    {
      name: 'notes',
      type: ControlTypes.RichText,
      propertyOrder: 6,
    },
  ],
  required: ['schoolName'],
})

export const PublicationEntrySchema: FormSchema = Object.freeze({
  properties: [
    {
      name: 'title',
      type: ControlTypes.Text,
      propertyOrder: 1,
    },
    {
      name: 'date',
      type: ControlTypes.Date,
      propertyOrder: 3,
    },
    {
      name: 'publisher',
      type: ControlTypes.Text,
      propertyOrder: 2,
    },
    {
      name: 'description',
      type: ControlTypes.RichText,
      propertyOrder: 6,
    },
    {
      name: 'url',
      type: ControlTypes.Text,
      propertyOrder: 5,
    },
    {
      name: 'authors',
      type: ControlTypes.TeamMembers,
      propertyOrder: 4,
    },
  ],
  required: ['title'],
})

export const OrganizationEntrySchema: FormSchema = Object.freeze({
  properties: [
    {
      name: 'name',
      type: ControlTypes.Text,
      propertyOrder: 1,
    },
    {
      name: 'position',
      type: ControlTypes.Text,
      propertyOrder: 2,
    },
    {
      name: 'description',
      type: ControlTypes.RichText,
      propertyOrder: 3,
    },
    {
      name: 'timePeriod',
      type: ControlTypes.TimePeriodMonthYear,
      propertyOrder: 4,
    },
  ],
  required: ['name'],
})

export const PatentEntrySchema: FormSchema = Object.freeze({
  properties: [
    {
      name: 'title',
      type: ControlTypes.Text,
      propertyOrder: 1,
    },
    {
      name: 'issuer',
      type: ControlTypes.Text,
      propertyOrder: 2,
    },
    {
      name: 'pending',
      type: ControlTypes.Checkbox,
      propertyOrder: 6,
    },
    {
      name: 'applicationNumber',
      type: ControlTypes.Text,
      propertyOrder: 3,
    },
    {
      name: 'number',
      type: ControlTypes.Text,
      propertyOrder: 4,
    },
    {
      name: 'description',
      type: ControlTypes.RichText,
      propertyOrder: 10,
    },

    {
      name: 'inventors',
      type: ControlTypes.TeamMembers,
      propertyOrder: 5,
    },
    {
      name: 'url',
      type: ControlTypes.Text,
      propertyOrder: 9,
    },
    {
      name: 'filing_date',
      type: ControlTypes.Date,
      propertyOrder: 7,
    },
    {
      name: 'issue_date',
      type: ControlTypes.Date,
      propertyOrder: 8,
    },
  ],
  required: ['title'],
})

export const VolunteeringEntrySchema: FormSchema = Object.freeze({
  properties: [
    {
      name: 'cause',
      type: ControlTypes.Text,
      propertyOrder: 3,
    },
    {
      name: 'companyName',
      type: ControlTypes.Text,
      propertyOrder: 1,
    },
    {
      name: 'description',
      type: ControlTypes.RichText,
      propertyOrder: 5,
    },
    {
      name: 'role',
      type: ControlTypes.Text,
      propertyOrder: 2,
    },
    {
      name: 'timePeriod',
      type: ControlTypes.TimePeriodMonthYear,
      propertyOrder: 4,
    },
  ],
  required: [],
})

export const CertificationsEntrySchema: FormSchema = Object.freeze({
  properties: [
    {
      name: 'authority',
      type: ControlTypes.Text,
      propertyOrder: 3,
    },
    {
      name: 'timePeriod',
      type: ControlTypes.TimePeriodMonthYearWithCurrent,
      propertyOrder: 5,
    },
    {
      name: 'licenseNumber',
      type: ControlTypes.Text,
      propertyOrder: 2,
    },
    {
      name: 'name',
      type: ControlTypes.Text,
      propertyOrder: 1,
    },
    {
      name: 'url',
      type: ControlTypes.Text,
      propertyOrder: 4,
    },
  ],
  required: ['name'],
})

export const LanguagesSchema: FormSchema = Object.freeze({
  properties: [
    {
      name: 'name',
      type: ControlTypes.Text,
      propertyOrder: 1,
    },
    {
      name: 'proficiency',
      type: ControlTypes.Text,
      propertyOrder: 2,
    },
  ],
  required: ['name'],
})

export const HonorsSchema: FormSchema = Object.freeze({
  properties: [
    {
      name: 'title',
      type: ControlTypes.Text,
      propertyOrder: 1,
    },
    {
      name: 'issueDate',
      type: ControlTypes.Date,
      propertyOrder: 2,
    },
    {
      name: 'issuer',
      type: ControlTypes.Text,
      propertyOrder: 3,
    },
    {
      name: 'description',
      type: ControlTypes.RichText,
      propertyOrder: 4,
    },
  ],
  required: [],
})

export const SectionSchemaMap: HashMap<FormSchema> = Object.freeze({
  [AutoLinkedinSections.WorkExperience]: WorkExperienceEntrySchema,
  [AutoLinkedinSections.Education]: EducationEntrySchema,
  [AutoLinkedinSections.Publications]: PublicationEntrySchema,
  [AutoLinkedinSections.Patents]: PatentEntrySchema,
  [AutoLinkedinSections.Organizations]: OrganizationEntrySchema,
  [AutoLinkedinSections.Volunteering]: VolunteeringEntrySchema,
  [AutoLinkedinSections.Certifications]: CertificationsEntrySchema,
  [AutoLinkedinSections.Languages]: LanguagesSchema,
  [AutoLinkedinSections.Honors]: HonorsSchema,
})
