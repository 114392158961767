import React from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box, { BoxProps } from '@material-ui/core/Box'
import classNames from 'classnames'

interface NavBarItemProps extends BoxProps {
  delimiter?: string
  underlined?: boolean
  spacing?: boolean
  spaceInside?: boolean
  collapsed?: boolean
  menuExpanded?: boolean
  children: React.ReactNode
  handleClick?: React.EventHandler<React.SyntheticEvent>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      minHeight: '3.5625rem',
      display: 'flex',
      alignItems: 'center',
      '&.both, &.left': { borderLeft: '1px solid #CACFD8' },
      '&.both, &.right': { borderRight: '1px solid #CACFD8' },
    },
    collapsed: {
      '@media (max-width: 540px)': {
        display: 'none !important',
      },
    },
    expanded: {
      '@media (max-width: 540px)': {
        border: 'none !important',
        paddingBottom: '1.25rem',
        paddingLeft: '2.5rem !important',
        paddingRight: '2.5rem !important',
        paddingTop: '1.25rem',
        width: '100%',
      },
    },
    button: {
      height: '100%',
      minHeight: '3.5625rem',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      textTransform: 'initial',
      borderRadius: 0,
      '@media (max-width: 400px)': {
        paddingLeft: '.25rem',
        paddingRight: '.25rem',
        minWidth: '2.5rem',
      },
    },
    menuExpanded: {
      borderBottom: 'solid 1px #e4e8f0 !important',
      marginLeft: '0 !important',
      marginRight: '0 !important',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',

      '&:last-of-type': {
        borderBottom: 'none !important',
      },
    },
    underlined: {
      borderBottom: '.2em solid #000',
      height: '100%',
    },
    spacing: {
      marginRight: '.8rem',
      marginLeft: '.8rem',
    },
    spaceInside: {
      paddingRight: '.8rem',
      paddingLeft: '.8rem',
    },
  })
)

const NavBarItem: React.FC<NavBarItemProps> = props => {
  const classes = useStyles()
  const { delimiter, handleClick, underlined, spacing, spaceInside, className, collapsed, menuExpanded } = props
  const edge = delimiter ? (delimiter === 'none' ? '' : delimiter) : ''

  return (
    <Box
      onClick={handleClick}
      className={classNames({
        [classes.container]: true,
        [edge]: edge,
        [classes.underlined]: underlined,
        [classes.spacing]: spacing,
        [classes.spaceInside]: spaceInside,
        [className || '']: !!className,
        [classes.expanded]: !collapsed,
        [classes.collapsed]: collapsed,
        [classes.menuExpanded]: menuExpanded,
      })}
    >
      {props.children}
    </Box>
  )
}

export default NavBarItem
