import { createMuiTheme } from '@material-ui/core/styles'
import { defaults as chartjsDefaults } from 'react-chartjs-2'
import merge from 'lodash/merge'

import styles from './styles'

const theme = createMuiTheme({
  typography: {
    fontFamily: styles.fonts.primary.family,
    htmlFontSize: styles.fonts.baseSize,
    fontWeightLight: 100,
    fontWeightRegular: 400,
    body1: {
      fontFamily: styles.fonts.primary.family,
      fontSize: 16,
      lineHeight: 1.4,
      '@media (max-width: 1050px)': {
        fontSize: '0.9375rem',
      },
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.2,
      textTransform: 'initial',
    },
    h1: {
      fontSize: 21,
      fontWeight: 400,
      lineHeight: 1.31,
    },
    h2: {
      fontWeight: styles.fonts.primary.weights.semiBold,
      fontSize: 20,
      lineHeight: 1.31,
    },
    h3: {
      fontSize: 18,
      lineHeight: 1.31,
    },
    h5: {
      fontSize: 14,
      lineHeight: 1.31,
    },
    h6: {
      fontSize: 12,
      lineHeight: 1.31,
    },
  },
  // @ts-ignore
  shadows: Array<string>(25).fill('none'),
  spacing: value => value ** 2,
  palette: {
    primary: {
      main: styles.palette.primary.color,
      contrastText: styles.palette.primary.contrast,
    },
    secondary: {
      main: styles.palette.secondary.color,
      contrastText: styles.palette.secondary.contrast,
    },
    text: {
      primary: styles.palette.textPrimary.color,
    },
  },
})

// Do the global styling for chartjs here
merge(chartjsDefaults, {
  global: {
    defaultFontFamily: 'proxima-nova, sans-serif',
    defaultFontSize: 16,
    tooltips: {
      backgroundColor: '#fff',
      titleFontColor: '#09143b',
      bodyFontColor: '#09143b',
      borderColor: 'rgba(0, 0, 0, .2)',
      borderWidth: 1,
    },
  },
})

export default theme
