import React from 'react'
import Box from '@material-ui/core/Box'
import FilterList from '@material-ui/icons/FilterList'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import isEqual from 'lodash/isEqual'

import i18n from '../../i18n'
import classNames from 'classnames'
import TableBody from '@material-ui/core/TableBody'
import ClientOrdersRow from './ClientOrdersRow'
import { ClientOrdersDisplayAggregate } from '../../selectors/clientOrderAggregates'
import Loader from '../common/Loader'
import { PaginationState } from '../../utils/pagination'
import { OrderTableDisplayModes } from './useOrderFiltering'

const useStyles = makeStyles(() => ({
  table: {
    background: '#fafafa',
  },
  cell: {
    padding: '0.6rem 1rem',
    '@media (max-width: 1050px)': {
      fontSize: '0.9375rem',
    },
    '@media (max-width: 540px)': {
      padding: '0.6rem 0.6rem',
    },
  },
  head: {
    backgroundColor: '#f2f2f4',
    marginBottom: '3rem',
  },
  headCell: {
    border: 'none',
    color: '#000',
    '& > :first-child': { fontWeight: 600 },
    '@media (max-width: 540px)': {
      padding: '0.875rem 0 0.875rem 0.6875rem',
    },
  },
  tabLabel: {
    textTransform: 'initial',
    marginRight: '1.5rem',
    minWidth: '1rem',
    padding: '0',
    '@media (max-width: 540px)': {
      fontSize: '1.1rem',
      marginRight: '1rem',
    },
  },
  pageTopContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '3rem',
  },
  tabsContainer: {
    height: '2.4rem',
    minHeight: 'initial',
  },
  headRow: {
    height: '3.1rem',
  },
  orderNameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  orderName: {
    marginLeft: '.5rem',
  },
  pagination: {
    '& .MuiTablePagination-spacer': {
      flex: '0 0',
    },
  },
}))

export interface OrderTableProps {
  data: ClientOrdersDisplayAggregate[]
  shouldSeeBasePay: boolean
  showMobileFilterIcon?: boolean
  onMobileFilterIconClick?: () => void
  dataIsLoading: boolean
  displayMode: OrderTableDisplayModes
  userID?: number
  pagination?: PaginationState
  handleChangePage?: (page: number) => void
}

const OrderTable: React.FC<OrderTableProps> = ({
  data,
  shouldSeeBasePay,
  userID,
  dataIsLoading,
  showMobileFilterIcon,
  displayMode,
  pagination,
  handleChangePage,
  onMobileFilterIconClick = () => {},
}) => {
  const classes = useStyles()

  if (dataIsLoading && displayMode === OrderTableDisplayModes.Paid) {
    return <Loader fullPage={true} />
  }

  return (
    <Table className={classes.table} area-label="orderlist">
      <TableHead className={classes.head}>
        <TableRow className={classes.headRow}>
          <TableCell className={classes.headCell}>
            <Typography variant={'body2'} component="div" area-label="order_name">
              <Box className={classes.orderNameCell}>
                {showMobileFilterIcon && <FilterList onClick={onMobileFilterIconClick} />}
                {i18n.t('orders__table__name')}
              </Box>
            </Typography>
          </TableCell>
          {displayMode !== OrderTableDisplayModes.Paid && (
            <TableCell className={classes.headCell} align="left">
              <Typography variant={'body2'} area-label="next_step">
                {i18n.t('orders__table__next__step')}
              </Typography>
            </TableCell>
          )}
          <TableCell className={classes.headCell} align="left">
            <Typography variant={'body2'} area-label="order_due">
              {i18n.t('orders__table__due')}
            </Typography>
          </TableCell>
          <TableCell
            className={classNames({ [classes.headCell]: true, hideTablet: true, hideMobile: true })}
            align="left"
          >
            <Typography variant={'body2'} area-label="order_item">
              {i18n.t('orders__table__order__items')}
            </Typography>
          </TableCell>
          {shouldSeeBasePay && (
            <TableCell className={classNames({ [classes.headCell]: true, hideMobile: true })} align="left">
              <Typography variant={'body2'} area-label="order_pay">
                {i18n.t('orders__table__pay')}
              </Typography>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {!!data &&
          data.map(row => <ClientOrdersRow displayMode={displayMode} key={row.client.id} data={row} userID={userID} />)}
      </TableBody>
      {pagination && handleChangePage && pagination.page_size < pagination.total && (
        <TableFooter>
          <TableRow>
            <TablePagination
              className={classes.pagination}
              count={pagination.total}
              rowsPerPage={pagination.page_size}
              page={Math.max(pagination.page - 1, 0)}
              rowsPerPageOptions={[]}
              onChangePage={(_, page) => handleChangePage(page)}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  )
}

const memoizedOrderTable = React.memo(OrderTable, isEqual)
// @ts-ignore
memoizedOrderTable.whyDidYouRender = true

export default memoizedOrderTable
