import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { EditorState } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'

import { AppState } from '..'
import { Snippet, SnippetFilters, Template, TemplateFilters } from './types'
import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionSuccess,
  AxiosMiddlewareActionFail,
} from '../../utils/axios'
import { MessageContext, MessageContexts } from '../messages/types'
import { currentMessageUpdate, CurrentMessageUpdate } from '../currentMessage/actions'
import { HandlebarsContextType, renderEmailTemplate } from '../../utils/handlebars'
import { insertHtmlAtCursor, stateFromHTMLOptions } from '../../utils/draftjs'
import { OrderCTA } from '../items/types'
import * as templatesSelectors from '../../selectors/templates'
import { DirectSchedulingMenu } from '../../selectors/items'
import { handlebarsTemplateContextSelector } from '../../selectors/templates'
import { Language } from '../../utils/consts'
import { directSchedulingTemplateSelector } from '../../selectors/templates'
import { clientByIdSelector, getCIOMagicLink } from '../../selectors/clients'
import { FetchCIOMagicLinkSuccess, fetchAutoApplyStartMagicLink, fetchTRDocumentsMagicLink } from '../clients/actions'
import { productsOrderIDSelector } from '../../selectors/orders'

export enum TemplateActionTypes {
  FETCH_TEMPLATES = 'FETCH_TEMPLATES',
  FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS',
  FETCH_TEMPLATES_FAIL = 'FETCH_TEMPLATES_FAIL',
  FETCH_SNIPPETS = 'FETCH_SNIPPETS',
  FETCH_SNIPPETS_SUCCESS = 'FETCH_SNIPPETS_SUCCESS',
  FETCH_SNIPPETS_FAIL = 'FETCH_SNIPPETS_FAIL',
}

export interface FetchTemplates extends AxiosMiddlewareActionCreator {
  type: typeof TemplateActionTypes.FETCH_TEMPLATES
}

export interface FetchTemplatesSuccess extends AxiosMiddlewareActionSuccess<Template[], FetchTemplates> {
  type: typeof TemplateActionTypes.FETCH_TEMPLATES_SUCCESS
}

export interface FetchTemplatesFail extends AxiosMiddlewareActionFail {
  type: typeof TemplateActionTypes.FETCH_TEMPLATES_FAIL
}

export function fetchTemplates(params: TemplateFilters = {}): FetchTemplates {
  const defaultFilters: TemplateFilters = { language: 'en' }

  return {
    type: TemplateActionTypes.FETCH_TEMPLATES,
    payload: {
      request: {
        url: '/v1/messages/templates',
        params: { ...defaultFilters, ...params },
      },
    },
  }
}

export interface FetchSnippets extends AxiosMiddlewareActionCreator {
  type: typeof TemplateActionTypes.FETCH_SNIPPETS
}

export interface FetchSnippetsSuccess extends AxiosMiddlewareActionSuccess<Snippet[], FetchSnippets> {
  type: typeof TemplateActionTypes.FETCH_SNIPPETS_SUCCESS
}

export interface FetchSnippetsFail extends AxiosMiddlewareActionFail {
  type: typeof TemplateActionTypes.FETCH_SNIPPETS_FAIL
}

export function fetchSnippets(params: SnippetFilters = {}): FetchSnippets {
  const defaultFilters: SnippetFilters = { page_size: 100, language: 'en' }

  return {
    type: TemplateActionTypes.FETCH_SNIPPETS,
    payload: {
      request: {
        url: '/v1/messages/templates/snippets',
        params: { ...defaultFilters, ...params },
      },
    },
  }
}

export function applyTemplateToCurrentMessage(template: Template, handlebarsContext: HandlebarsContextType) {
  const renderedSubject = renderEmailTemplate(template.subject, handlebarsContext)
  const renderedMessage = renderEmailTemplate(template.body, handlebarsContext)
  const contentState = stateFromHTML(renderedMessage, stateFromHTMLOptions)

  return currentMessageUpdate({
    subject: renderedSubject,
    editorState: EditorState.createWithContent(contentState),
    variables: {
      template_id: template.id,
    },
  })
}

export function applyDirectSchedulingThunk(
  selectedDate: Date,
  selectedItem: DirectSchedulingMenu,
  customerNote: string,
  context: MessageContext,
  contextID: number,
  userID: number
): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    await dispatch(
      currentMessageUpdate({
        direct_scheduling: {
          date_time: selectedDate,
          item_id: selectedItem.order_item_id,
          order_id: selectedItem.order_id,
          customerNote: customerNote,
          serviceName: selectedItem.service_name,
        },
      })
    )

    const handlebarsContext = handlebarsTemplateContextSelector(
      getState(),
      context,
      contextID,
      userID,
      [],
      selectedItem.order_id
    )

    let language: Language = 'en'

    if (!!handlebarsContext.order && !!handlebarsContext.order.language) {
      language = handlebarsContext.order.language
    }

    const template = directSchedulingTemplateSelector(getState().templatesReducer, language)
    if (!template) {
      return
    }
    dispatch(applyTemplateToCurrentMessage(template, handlebarsContext))
  }
}

export function insertSnippetAtCursor(
  snippet: Snippet,
  handlebarsContext: HandlebarsContextType
): ThunkAction<Promise<CurrentMessageUpdate>, AppState, {}, CurrentMessageUpdate> {
  return async function(dispatch: ThunkDispatch<{}, {}, CurrentMessageUpdate>, getState: () => AppState) {
    return new Promise<CurrentMessageUpdate>(() => {
      const renderedSnippetText = renderEmailTemplate(snippet.body, handlebarsContext)
      const { editorState } = getState().currentMessageReducer

      return dispatch(
        currentMessageUpdate({
          editorState: insertHtmlAtCursor(editorState, renderedSnippetText),
        })
      )
    })
  }
}

export function applyTemplateBasedUponOrderCTA(
  userID: number | null,
  clientID: number,
  orderID: number,
  cta: OrderCTA
): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    let state = getState()
    const order = state.orderReducer.orders[orderID]

    // Ensure that the templates are loaded
    const [templates] = templatesSelectors.templatesSelector(state.templatesReducer, {
      language: order.language,
    })

    if (!Object.keys(templates).length) {
      await dispatch(fetchTemplates({ language: order.language }))
      state = getState() // Update state after fetching templates
    }

    // Initialize cioMagicLink with error handling
    let cioMagicLink: string | undefined
    try {
      cioMagicLink = getCIOMagicLink(state.clientReducer, clientID)
    } catch (error) {
      console.warn('Failed to get cioMagicLink:', error)
      cioMagicLink = undefined // Proceed without cioMagicLink
    }

    const clientData = clientByIdSelector(state.clientReducer, clientID)

    const productsOrderID = productsOrderIDSelector(state.orderReducer, orderID)
    const productUserID = clientData?.products_user_id

    // Initialize magic link variables
    let trDocumentsMagicLink = ''
    let autoApplyStartMagicLink = ''

    // Fetch magic links with error handling
    if (productsOrderID && productUserID) {
      try {
        const trDocumentsMagicLinkAction = ((await dispatch(
          fetchTRDocumentsMagicLink(clientID, productUserID, productsOrderID)
        )) as unknown) as FetchCIOMagicLinkSuccess | null
        trDocumentsMagicLink = trDocumentsMagicLinkAction?.payload?.data?.url || ''
      } catch (error) {
        console.warn('Failed to fetch trDocumentsMagicLink:', error)
        trDocumentsMagicLink = '' // Ensure variable is set
      }

      try {
        const autoApplyStartMagicLinkAction = ((await dispatch(
          fetchAutoApplyStartMagicLink(clientID, productUserID, productsOrderID)
        )) as unknown) as FetchCIOMagicLinkSuccess | null
        autoApplyStartMagicLink = autoApplyStartMagicLinkAction?.payload?.data?.url || ''
      } catch (error) {
        console.warn('Failed to fetch autoApplyStartMagicLink:', error)
        autoApplyStartMagicLink = '' // Ensure variable is set
      }
    }

    // Get the brand name
    const brandName = order.brand.name

    // Get the appropriate template
    const targetTemplate = templatesSelectors.getTemplateForOrderCTA(
      state.templatesReducer,
      cta,
      order.language,
      cioMagicLink, // Provide empty string if undefined
      order.order_items_core_product_ids,
      brandName // Pass brandName here
    )

    if (targetTemplate) {
      const handlebarsContext = templatesSelectors.handlebarsTemplateContextSelector(
        state,
        MessageContexts.CLIENT,
        clientID,
        userID,
        [],
        orderID
      )

      // Add magic links to the Handlebars context if they exist
      if (handlebarsContext) {
        if (trDocumentsMagicLink) {
          handlebarsContext.CIOMagicLinkTRDocuments = trDocumentsMagicLink
        }
        if (autoApplyStartMagicLink) {
          handlebarsContext.CIOMagicLinkAutoApplyStart = autoApplyStartMagicLink
        }
      }

      // Apply the template to the current message
      dispatch(applyTemplateToCurrentMessage(targetTemplate, handlebarsContext))
    } else {
      console.warn('No template found for the given type and language.')
    }
  }
}

export type TemplateActionType =
  | FetchTemplates
  | FetchTemplatesSuccess
  | FetchTemplatesFail
  | FetchSnippets
  | FetchSnippetsSuccess
  | FetchSnippetsFail
