import { FeatureFeedbackState, initialState } from './types'
import { FeatureFeedbackActionType, FeatureFeedbackActionTypes } from './actions'
import produce from 'immer'

export default function featureFeedbackReducer(
  state: FeatureFeedbackState = initialState,
  action: FeatureFeedbackActionType
): FeatureFeedbackState {
  return produce(state, (draft: FeatureFeedbackState) => {
    switch (action.type) {
      case FeatureFeedbackActionTypes.CREATE_FEATURE_FEEDBACK: {
        const { feature_key } = action
        draft.meta[feature_key] = {
          isLoading: true,
          error: null,
        }
        break
      }
      case FeatureFeedbackActionTypes.CREATE_FEATURE_FEEDBACK_SUCCESS: {
        const feedback = action.payload.data
        const { feature_key } = action.meta.previousAction

        if (feedback && feedback.length > 0) {
          feedback.forEach(f => {
            if (!draft[feature_key][f.feature_id]) {
              draft[feature_key][f.feature_id] = {}
            }
            draft[feature_key][f.feature_id][f.feature_element] = f
          })
        }
        draft.meta[feature_key] = {
          isLoading: false,
          error: null,
        }
        break
      }
      case FeatureFeedbackActionTypes.CREATE_FEATURE_FEEDBACK_FAIL: {
        const { feature_key } = action.meta.previousAction
        draft.meta[feature_key] = {
          isLoading: false,
          error: action.error,
        }
        break
      }
    }
  })
}
