import { EditorState, Modifier, ContentBlock, ContentState, RawDraftContentState, convertToRaw } from 'draft-js'
import { stateFromHTML, Options as StateFromHTMLOptions } from 'draft-js-import-html'
import { stateToHTML, Options as StateToHTMLOptions } from 'draft-js-export-html'
// @ts-ignore
import { filterEditorState } from 'draftjs-filters'

export const stateFromHTMLOptions: StateFromHTMLOptions = {
  customBlockFn: element => {
    if (element.classList.contains('cursor-default')) {
      return { type: 'cursor-default', text: 'Please just work' }
    }

    return null
  },
  customInlineFn: (element, { Entity }) => {
    // If the block is wrapped in this, we must block sending until it's been replaced
    if (element.classList.contains('placeholder')) {
      return Entity('PLACEHOLDER')
    }

    // For the account page URL, we need to hide it from the writer so that they can't give themselves a 5 star review
    // and trigger a bonus payment.
    if (element.classList.contains('account-page-url')) {
      return Entity('HIDDENLINK', {
        hiddenHref: element.getAttribute('href'),
        href: '#',
      })
    }

    return null
  },
}

export interface StateToHTMLOptionsArgs {
  disableLinks?: boolean
}

export const stateToHTMLOptions = ({ disableLinks = false }: StateToHTMLOptionsArgs): StateToHTMLOptions => {
  return {
    entityStyleFn: entity => {
      const entityData = entity.getData()

      if ('hiddenHref' in entityData && !disableLinks) {
        return {
          element: 'a',
          attributes: {
            href: entityData.hiddenHref,
          },
        }
      }

      return undefined
    },
  }
}

export const draftjsEditorStateToHTML = (
  editorState: EditorState,
  htmlConversionArgs?: StateToHTMLOptionsArgs
): string => {
  return stateToHTML(editorState.getCurrentContent(), stateToHTMLOptions(htmlConversionArgs || {}))
}

// https://github.com/facebook/draft-js/issues/852#issuecomment-274963795
export const insertHtmlAtCursor = (editorState: EditorState, html: string): EditorState => {
  const htmlState = stateFromHTML(html, stateFromHTMLOptions)
  const currentContent = editorState.getCurrentContent()
  const selection = editorState.getSelection()
  const textWithEntity = Modifier.replaceWithFragment(currentContent, selection, htmlState.getBlockMap())

  return EditorState.push(editorState, textWithEntity, 'insert-characters')
}

export const cleanEditorState = (editorState: EditorState): EditorState => {
  return filterEditorState(
    {
      blocks: ['unordered-list-item', 'ordered-list-item', 'cursor-default'],
      styles: ['BOLD', 'ITALIC'],
      entities: [
        {
          type: 'HIDDENLINK',
          attributes: ['url', 'href', 'hiddenHref'],
        },
        {
          type: 'LINK',
          attributes: ['url', 'href', 'hiddenHref'],
        },
        {
          type: 'PLACEHOLDER',
          attributes: ['title'],
        },
      ],
      maxNesting: 1,
      whitespacedCharacters: [],
    },
    editorState
  )
}

// This is used to block sending of messages with placeholder text in them.
export const editorStateHasPlaceholderText = (editorState: EditorState): boolean => {
  const currentContent = editorState.getCurrentContent()
  let hasPlaceholderEntity = false

  return !!currentContent.getBlockMap().find(block => {
    if (!block) {
      return false
    }

    block.findEntityRanges(
      charMeta => {
        const entityKey = charMeta.getEntity()
        return !!entityKey && currentContent.getEntity(entityKey).getType() === 'PLACEHOLDER'
      },
      () => {
        hasPlaceholderEntity = true
      }
    )

    return hasPlaceholderEntity
  })
}

export function findPlaceholderEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()
    return !!entityKey && contentState.getEntity(entityKey).getType() === 'PLACEHOLDER'
  }, callback)
}

export function findHiddenLinkEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()
    return !!entityKey && contentState.getEntity(entityKey).getType() === 'HIDDENLINK'
  }, callback)
}

const bulletSymbols = Object.freeze(['■', '▪', '▫', '●', '•', '◦', '⁃', '◆', '◇'])
const nestedBulletSymbolsMap = Object.freeze({
  0: '•',
  1: '◦',
  2: '▪',
} as Record<number, string>)

const getBlockPrefixByDepth = (depth: number) => {
  if (!nestedBulletSymbolsMap[depth]) {
    return `${generateOffset(depth)}▪`
  }
  return `${generateOffset(depth)}${nestedBulletSymbolsMap[depth]}`
}

const generateOffset = (n: number) => {
  let result = ''
  for (let i = 0; i < n; i++) {
    result += '  '
  }
  return result
}

export const toPlainTextWithBullets = (rawState: RawDraftContentState): string => {
  let text = ''
  let orderedList: string[] = []
  rawState.blocks.forEach((b, i) => {
    if (b.type === 'ordered-list-item') {
      orderedList.push(b.text)
    } else {
      if (orderedList.length !== 0) {
        orderedList.forEach((item, index) => (text += `\n ${index + 1}. ${item}`))
        orderedList = []
      }
      if (b.type === 'unordered-list-item') {
        if (i > 0) {
          text += `\n${getBlockPrefixByDepth(b.depth)} ${b.text}`
        } else {
          text += `${getBlockPrefixByDepth(b.depth)} ${b.text}`
        }
      } else {
        if (i > 0) {
          text += `\n${b.text}`
        } else {
          text += `${b.text}`
        }
      }
    }
  })
  return text
}

export const fromPlainTextWithBullets = (text: string): RawDraftContentState => {
  const rawState = convertToRaw(ContentState.createFromText(text))
  rawState.blocks.forEach((b, i) => {
    const trimmedText = b.text.trim()
    if (trimmedText.length > 0 && b.text.startsWith('  ') && bulletSymbols.includes(trimmedText[0])) {
      // it's a nested bullet! find out the depth
      let depth = 1
      for (let i = 1; i < b.text.length; i++) {
        if (b.text[i] === ' ') {
          depth += 1
        } else {
          break
        }
      }
      b.depth = depth / 2
    }
    if (trimmedText.length > 0 && bulletSymbols.includes(trimmedText[0])) {
      const bulletSymbol = trimmedText[0]
      // usually bullets are followed by a space. since we're removing the original bullet (to be replaced by draft.js bullet),
      // let's get rid of the space too
      if (trimmedText.length > 1 && trimmedText[1] === ' ') {
        b.text = trimmedText.replace(`${bulletSymbol} `, '')
      } else {
        b.text = trimmedText.replace(bulletSymbol, '')
      }
      b.type = 'unordered-list-item'
    }
  })
  return rawState
}

// This will correctly handle pasting from Word
// https://github.com/facebook/draft-js/issues/728#issuecomment-271040946
export const onPaste = (previousEditorState: EditorState, text: string, html: string) => {
  // Default to HTML, but fall back to plain text if the HTML is undefined. This happens when one pastes from
  // a text editor or uses Grammarly to fix a typo.
  const htmlState = stateFromHTML(html || text)
  const newState = Modifier.replaceWithFragment(
    previousEditorState.getCurrentContent(),
    previousEditorState.getSelection(),
    htmlState.getBlockMap()
  )

  return cleanEditorState(EditorState.push(previousEditorState, newState, 'insert-fragment'))
}
