import React, { useCallback, useState } from 'react'
import Box from '@material-ui/core/Box'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import i18n from '../../i18n'
import ButtonBase from '@material-ui/core/Button'
import classNames from 'classnames'
import AddIcCallIcon from '@material-ui/icons/AddIcCall'
import Typography from '@material-ui/core/Typography'
import { DirectSchedulingMenu } from '../../selectors/items'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '../common/Button'
import { formatDate } from '../../utils/formatting'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import styles from '../../styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import { useField, useForm } from 'react-final-form-hooks'
import { utcToZonedTime } from 'date-fns-tz'
import { isFuture } from 'date-fns'
import isEqual from 'lodash/isEqual'
import { FormLabel } from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup'

interface ProposeCallTimeProps {
  items: DirectSchedulingMenu[]
  showEmailPreview: () => void
  updateScheduling: (selectedDate: Date, selectedItem: DirectSchedulingMenu, customerNote: string) => Promise<void>
  userLocale: Locale
  userTimezone: string
  sendMessage: () => void
  cleanUp: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      cursor: 'pointer',
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column',
      },
      '& .icon-img': {
        display: 'flex',
        alignItems: 'center',
        height: '1.5rem',
        '@media (max-width: 540px)': { display: 'none' },
      },
      '& .icon-text': {
        marginTop: '.1rem',
        fontSize: '.5625rem',
        color: '#3f3f3f',
      },
    },
    modalRoot: {
      maxWidth: '800px',
      padding: '1.5em',
    },
    proposeCallTimeTooltip: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      alignItems: 'center',
    },
    closeIcon: {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      width: '1rem',
      height: '1rem',
    },
    head: {
      display: 'flex',
      justifyContent: 'center',
      padding: '1.125rem 0',
      textAlign: 'center',
      borderBottom: '1px solid #979797',
    },
    dateTimeBlock: {
      display: 'flex',
      width: '80%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    noteBlock: {
      paddingTop: '.5rem',
      width: '100%',
      paddingBottom: '.5rem',
    },
    fullWidth: {
      width: '90%',
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '40%',
      float: 'right',
      paddingTop: '2.4rem',
      marginRight: '1.6rem',
    },
    label: {
      alignItems: 'baseline',
      paddingTop: '.5rem',
      width: '100%',
    },
    formControlLabel: {
      textTransform: 'uppercase',
      color: styles.palette.darkerBlue.color.hex,
      fontWeight: 600,
      fontSize: '.75rem',
    },
    formControlRoot: {
      marginBottom: '1.31em',
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '0 2em 0 0',
      marginBottom: '1em',
    },
    modalTitle: {
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: '1.25em',
      lineHeight: 1.25,
      color: styles.palette.shadowedSelect.color,
    },
    selectElement: {
      width: '90%',
      paddingBottom: '0',
      paddingTop: '0',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    select: {
      backgroundColor: styles.palette.inputGray.color,
      padding: '0 .63em',
    },
    inputGroup: {
      marginBottom: '.7em',
      marginTop: '.71em',
    },
    input: {
      color: styles.palette.darkerBlue.color.hex,
      backgroundColor: styles.palette.inputGray.color,
      borderBottom: `1px solid ${styles.palette.darkerBlue.color.hex}`,
      padding: '8px',
      marginTop: '.7rem',
      lineHeight: '1.8rem',
    },
    menu: {
      backgroundColor: styles.palette.inputGray.color,
    },
    itemSelect: {
      width: '100%',
    },
    dialogPaper: {
      overflowY: 'initial',
    },
    error: {
      color: `${styles.palette.pink.hex} !important`,
    },
    timeField: {
      width: '9rem',
      marginLeft: '1rem',
    },
    dateField: {
      marginRight: '1rem',
    },
    textField: {
      paddingTop: '.5rem',
    },
    labelPlacementTop: {
      marginLeft: '16px',
    },
  })
)

interface ProposeCallTimeForm {
  selectedItemID: number
  selectedDay: string
  selectedTime: string
  note: string
}

const ProposeCallTime: React.FC<ProposeCallTimeProps> = ({
  items,
  updateScheduling,
  userLocale,
  userTimezone,
  sendMessage,
  showEmailPreview,
  cleanUp,
}) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const updateCurrentMessage = useCallback(
    async (formData: ProposeCallTimeForm) => {
      const selectedItem = items.find(i => i.order_item_id === formData.selectedItemID)
      if (!selectedItem) {
        return
      }
      const scheduledDate = new Date(`${formData.selectedDay} ${formData.selectedTime}`)
      await updateScheduling(scheduledDate, selectedItem, formData.note)
    },
    [items, updateScheduling]
  )

  const onUpdateClick = useCallback(
    async (formData: ProposeCallTimeForm) => {
      await updateCurrentMessage(formData)
      setOpen(false)
      sendMessage()
    },
    [updateCurrentMessage, setOpen, sendMessage]
  )
  const validate = useCallback(
    (values: ProposeCallTimeForm) => {
      const errors: Record<string, object> = {}
      try {
        if (values.selectedDay) {
          const writerSelectedDateTime = utcToZonedTime(
            new Date(`${values.selectedDay} ${values.selectedTime}`),
            userTimezone
          )
          if (!isFuture(writerSelectedDateTime)) {
            errors.selectedDay = {
              text: i18n.t('directSched__error__date'),
            }
            return errors
          }
        } else {
          errors.selectedDay = {
            text: i18n.t('directSched__error__date'),
          }
          return errors
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(values, e)
        errors.selectedDay = {
          text: i18n.t('directSched__error__date_invalid'),
        }
      }
      return errors
    },
    [userTimezone]
  )
  const { form, handleSubmit, submitting, valid } = useForm({
    onSubmit: onUpdateClick,
    validate: validate,
    initialValues: {
      selectedItemID: items[0].order_item_id,
      selectedDay: '',
      selectedTime: '',
      note: '',
    },
  })

  const day = useField('selectedDay', form)
  const time = useField('selectedTime', form)
  const itemID = useField('selectedItemID', form)
  const note = useField('note', form)

  const onPreviewClick = useCallback(async () => {
    await updateCurrentMessage({
      selectedDay: day.input.value,
      selectedTime: time.input.value,
      note: note.input.value,
      selectedItemID: itemID.input.value,
    })
    showEmailPreview()
  }, [updateCurrentMessage, day, time, note, itemID, showEmailPreview])

  const onCloseClick = useCallback(() => {
    cleanUp()
    setOpen(false)
  }, [cleanUp])

  return (
    <form className={classes.proposeCallTimeTooltip} onSubmit={handleSubmit}>
      <Tooltip title={i18n.t('messaging__proposeCallTime__icon__text') || 'Pick Call Time'}>
        <ButtonBase
          className={classNames({ [classes.icon]: true })}
          aria-controls="propose-call-time-dialog"
          aria-haspopup="true"
          id="message-toolbox-propose-call-time"
          onClick={() => setOpen(true)}
        >
          <Box className="icon-img">
            <AddIcCallIcon />
          </Box>
          <Typography display="block" variant={'body2'} className="icon-text">
            {i18n.t('messaging__tool__proposeCallTime')}
          </Typography>
        </ButtonBase>
      </Tooltip>
      <Dialog open={open} classes={{ paper: classes.dialogPaper }}>
        <IconButton className={classes.closeIcon} onClick={onCloseClick}>
          <CloseIcon />
        </IconButton>
        <Box className={classes.modalRoot}>
          <DialogTitle disableTypography={true} classes={{ root: classes.title }}>
            <Typography className={classes.modalTitle}>{i18n.t('messaging__scheduling__header')}</Typography>
          </DialogTitle>
          <FormControlLabel
            labelPlacement="top"
            className={classes.itemSelect}
            label={i18n.t('messaging__scheduling__sessionLabel')}
            classes={{ label: classes.formControlLabel, root: classes.label }}
            control={
              <Select
                classes={{ select: classes.selectElement }}
                className={clsx(classes.select, classes.input, classes.inputGroup, classes.fullWidth)}
                MenuProps={{ classes: { paper: classes.menu } }}
                inputProps={itemID.input as React.HTMLAttributes<HTMLInputElement>}
              >
                {items.map(item => (
                  <MenuItem value={item.order_item_id.toString()} key={`item-${item.order_item_id}`}>
                    {item.scheduledAt &&
                      i18n.t('messaging__scheduling__scheduled_label', {
                        dateTime: formatDate(item.scheduledAt, userTimezone, userLocale, 'MM/dd/yy hh:mm a'),
                      })}
                    {item.proposedAt &&
                      i18n.t('messaging__scheduling__proposed_label', {
                        dateTime: formatDate(item.proposedAt, userTimezone, userLocale, 'MM/dd/yy hh:mm a'),
                      })}
                    {!item.scheduledAt && !item.proposedAt && item.label}
                  </MenuItem>
                ))}
              </Select>
            }
          />
          <FormGroup className={clsx(classes.inputGroup, classes.labelPlacementTop)}>
            <FormLabel className={classes.formControlLabel}>{i18n.t('messaging__scheduling__dateTimeLabel')}</FormLabel>
            <>
              {!!day.meta.error && !!day.meta.touched && !!time.meta.touched && (
                <Typography
                  display={'block'}
                  className={classNames({
                    [classes.error]: true,
                  })}
                >
                  {day.meta.error.text}
                </Typography>
              )}
              <Box className={classes.dateTimeBlock}>
                <TextField
                  id="startDay"
                  type="date"
                  disabled={submitting}
                  InputProps={{ className: classes.input }}
                  inputProps={day.input as React.HTMLAttributes<HTMLInputElement>}
                  className={classes.dateField}
                  size={'small'}
                />
                <div className={classes.textField}>{i18n.t('messaging__scheduling__at')}</div>
                <TextField
                  id="startTime"
                  name="startTime"
                  type="time"
                  InputProps={{ className: classes.input }}
                  inputProps={time.input as React.HTMLAttributes<HTMLInputElement>}
                  className={classes.timeField}
                />
              </Box>
            </>
          </FormGroup>
          <FormControlLabel
            control={
              <TextField
                id="note"
                name="note"
                type="textarea"
                multiline={true}
                classes={{ root: classes.fullWidth }}
                className={classes.textField}
                InputProps={{
                  className: classes.input,
                }}
                rows={5}
                inputProps={note.input as React.HTMLAttributes<HTMLInputElement>}
              />
            }
            labelPlacement="top"
            label={i18n.t('messaging__scheduling__noteLabel')}
            classes={{ label: classes.formControlLabel, root: classes.label }}
          />
          <Box className={classes.footer}>
            <Button type="secondary" disabled={!valid || submitting} onClick={() => onPreviewClick()}>
              {i18n.t('messaging__scheduling__preview')}
            </Button>
            <Button
              disabled={!valid || submitting}
              showLoader={submitting}
              type="contrast"
              // @ts-ignore
              onClick={handleSubmit}
            >
              {i18n.t('messaging__scheduling__send')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </form>
  )
}

const memoizedProposeCallTime = React.memo(ProposeCallTime, isEqual)
export default memoizedProposeCallTime
