import { useReducer, useCallback } from 'react'

interface UpdateWeeklyGoal {
  type: 'UPDATE_GOAL'
  amount: number
}

interface ToggleActive {
  type: 'TOGGLE_ACTIVE'
  active: boolean
}

interface StartSavingWeeklyGoal {
  type: 'START_SAVING_GOAL'
}

interface FinishSavingWeeklyGoal {
  type: 'FINISH_SAVING_GOAL'
}

type Actions = UpdateWeeklyGoal | StartSavingWeeklyGoal | FinishSavingWeeklyGoal | ToggleActive

interface GoalState {
  active: boolean
  amount: number
  saving: boolean
}

function goalSliderReducer(state: GoalState, action: Actions): GoalState {
  switch (action.type) {
    case 'UPDATE_GOAL':
      state = {
        ...state,
        amount: action.amount,
      }
      break

    case 'TOGGLE_ACTIVE':
      state = {
        ...state,
        active: action.active,
      }
      break

    case 'START_SAVING_GOAL':
      state = {
        ...state,
        saving: true,
      }
      break

    case 'FINISH_SAVING_GOAL':
      state = {
        ...state,
        saving: false,
        active: false,
      }
      break
  }

  return state
}

interface UseGoalSlider {
  active: boolean
  saving: boolean
  amount: number
  setAmount: (amount: number) => void
  startSaving: () => void
  finishSaving: () => void
  toggle: (acitve: boolean) => void
}

function useGoalSlider(initialGoalAmount: number): UseGoalSlider {
  const [state, dispatch] = useReducer(goalSliderReducer, {
    active: false,
    saving: false,
    amount: initialGoalAmount,
  })

  const setAmount = useCallback((amount: number) => dispatch({ type: 'UPDATE_GOAL', amount }), [])
  const toggle = useCallback((active: boolean) => dispatch({ type: 'TOGGLE_ACTIVE', active }), [])
  const startSaving = useCallback(() => dispatch({ type: 'START_SAVING_GOAL' }), [])
  const finishSaving = useCallback(() => dispatch({ type: 'FINISH_SAVING_GOAL' }), [])

  return {
    active: state.active,
    saving: state.saving,
    amount: state.amount,
    toggle,
    setAmount,
    startSaving,
    finishSaving,
  }
}

export default useGoalSlider
