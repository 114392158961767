import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'

import styles from '../../styles'
import { AppState } from '../../store'
import { searchClientOrderAggregate } from '../../selectors/clientOrderAggregates'
import { GhostLink } from '../../utils/link'
import { getClientDefaultUrl } from '../../utils/consts'
import useOrderFiltering from '../Orders/useOrderFiltering'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      overflowY: 'scroll',
      // @TODO This isn't quite right
      maxHeight: 'calc(100vh - 150px)',
      '@media (max-width: 560px)': {
        height: 'calc(100% - 148px)',
      },
    },
    row: {
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,

      '&:hover, &:active': {
        backgroundColor: styles.palette.listHover.color,
      },
    },
    linkContainer: {
      alignItems: 'center',
      borderBottom: '1px solid #edf0f5',
      color: styles.palette.darkBlue.color,
      display: 'flex',
      height: '50px',
      flexDirection: 'row',
      fontWeight: 500,
      overflow: 'hidden',
      width: '100%',

      '&:hover, &:active': {
        backgroundColor: styles.palette.listHover.color,
      },
    },
    late: {
      color: '#f95e3f',
    },
    name: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
    },
    clientID: {
      textAlign: 'right',
      marginRight: '16px',
    },
  })
)
export interface ClientListProps {
  onClientLinkClick: () => void
}

const ClientList: React.FC<ClientListProps> = ({ onClientLinkClick }) => {
  const classes = useStyles()

  const orderFilter = useOrderFiltering()
  const searchTerm = useSelector<AppState, string>(state => state.clientOrdersAggregateReducer.view.searchTerm)
  const data = searchClientOrderAggregate(orderFilter.clients, searchTerm)

  return (
    <List className={classes.root}>
      {data.map(d => (
        <ListItem key={`client-${d.client.id}`} className={classes.row} onClick={onClientLinkClick}>
          <GhostLink
            to={getClientDefaultUrl(d.client.id)}
            className={classNames({
              [classes.linkContainer]: true,
              [classes.late]: !!d.macroState.dueDate && new Date(d.macroState.dueDate) < new Date() && d.isOpen,
            })}
          >
            <Typography component="span" className={classes.name}>
              {d.client.full_name}
            </Typography>
            <Typography component="span" className={classes.clientID}>
              (#{d.client.id})
            </Typography>
          </GhostLink>
        </ListItem>
      ))}
    </List>
  )
}

export default ClientList
