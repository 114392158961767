import React, { useCallback, useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import isEqual from 'lodash/isEqual'
import CardContent from '@material-ui/core/CardContent'
import RefreshIcon from '@material-ui/icons/Refresh'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInSeconds from 'date-fns/differenceInSeconds'

import { AppState } from '../../store'
import { getUserEvents } from '../../store/events/actions'
import { useDispatch, useSelector } from 'react-redux'
import { UserInfo } from '../../store/user/reducers'
import InfiniteList from '../common/InfiniteList'
import { InfiniteListState, VulcanBeautifiedEvent } from '../../store/events/types'
import { getEventsMeta, getEventsPaging, getOrderedUserEvents } from '../../selectors/events'
import { LoadedLoadingErrorState } from '../../utils/state'
import { formatEventDateOffset } from '../../utils/formatting'
import useUserInfoState from '../common/useUserInfo'
import { GhostLink } from '../../utils/link'
import { getClientUrl } from '../../utils/consts'
import { PaginationState } from '../../utils/pagination'
import i18n from '../../i18n'
import DashboardErrorCard from './DashboardErrorCard'
import DashboardStyles from './Styles'
import EmptyActivityCard from './EmptyActivityCard'

export interface RecentActivityProps {
  user: UserInfo
}

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      color: 'inherit',
      height: '1em',
      padding: '0',
      width: '1em',
      marginLeft: '10px',
    },
    cardContent: {
      backgroundColor: DashboardStyles.card.bg,
      border: DashboardStyles.card.border,
      boxShadow: DashboardStyles.card.shadow,
      color: 'inherit',
      padding: '0',
      width: DashboardStyles.fullWidth,
      height: DashboardStyles.listHeight,

      '&:last-child': {
        paddingBottom: '1.6em',
      },
    },
    header: {
      color: DashboardStyles.header.color,
      fontSize: DashboardStyles.header.size,
      margin: DashboardStyles.header.margin,
      padding: '0',
    },
    headerAction: {
      alignSelf: 'initial',
      margin: '0',
    },
    headerContent: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    paper: {
      color: 'inherit',
      height: DashboardStyles.listHeight,
      overflow: 'auto',
    },
    recentActivity: {
      backgroundColor: 'transparent',
      color: 'inherit',
      fontSize: 'inherit',
      overflow: 'visible',
      width: DashboardStyles.halfWidth,
    },
    subheader: {
      fontSize: '.7em',
    },
    title: {
      fontSize: 'inherit',
    },
    linkRow: {
      display: 'table-row',
      width: '100%',
      padding: DashboardStyles.row.padding,
      lineHeight: DashboardStyles.row.lineHeight,

      '& > *': {
        padding: DashboardStyles.row.paddingInner,
        display: 'table-cell',
        width: '100%',
        borderBottom: DashboardStyles.row.border,
      },

      '& > time': {
        color: DashboardStyles.row.timeColor,
        paddingLeft: '.5em',
      },

      '&:hover': {
        backgroundColor: DashboardStyles.row.hover,
      },
    },
    [theme.breakpoints.down('sm')]: {
      button: {
        padding: '0 0 0 .25em',
      },
      recentActivity: {
        margin: DashboardStyles.card.marginMobile,
        width: DashboardStyles.fullWidth,
      },
    },
  })
)

const RecentActivity: React.FC<RecentActivityProps> = ({ user }) => {
  const dispatch = useDispatch()

  const [lastRefreshedNotification, setLastRefreshedNotification] = useState<string>('')

  const listState = useSelector<AppState, InfiniteListState>(state => state.eventsReducer.activityStream)
  const eventsAreLoading = useSelector<AppState, boolean>(state => state.eventsReducer.meta.isLoading)

  useEffect(() => {
    setLastRefreshedNotification(getWhenLastRefreshed(listState.lastRefreshed))
    const interval = window.setInterval(() => {
      setLastRefreshedNotification(getWhenLastRefreshed(listState.lastRefreshed))
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [listState])

  const { timeZone } = useUserInfoState()

  const userEvents = useSelector<AppState, VulcanBeautifiedEvent[]>(
    state => getOrderedUserEvents(state.eventsReducer),
    isEqual
  )
  const eventsMeta = useSelector<AppState, LoadedLoadingErrorState>(
    state => getEventsMeta(state.eventsReducer),
    isEqual
  )
  const eventsPaging = useSelector<AppState, PaginationState>(state => getEventsPaging(state.eventsReducer))

  const loadEventsPage = useCallback(
    async (page: number) => {
      if (eventsAreLoading) {
        return
      }
      return dispatch(getUserEvents(user.id, { page: page, page_size: eventsPaging.page_size }))
    },
    [dispatch, user.id, eventsPaging.page_size, eventsAreLoading]
  )

  const classes = useStyles()

  return (
    <Card className={classes.recentActivity} id="dashboard-recent-activity">
      <CardHeader
        action={
          <IconButton
            aria-label="refresh"
            onClick={() => loadEventsPage(1)}
            className={classes.button}
            disableRipple={true}
          >
            <RefreshIcon />
          </IconButton>
        }
        classes={{ action: classes.headerAction, content: classes.headerContent, title: classes.title }}
        className={classes.header}
        title={i18n.t('dashboard__recent_activity__title')}
        subheader={<Typography className={classes.subheader}>{lastRefreshedNotification}</Typography>}
      />
      <CardContent className={classes.cardContent}>
        <Paper className={classes.paper}>
          {eventsMeta && eventsMeta.loaded && !eventsMeta.error && userEvents.length === 0 && (
            <EmptyActivityCard
              emoji="🍃"
              emojiLabel="leaf fluttering in wind emoji"
              heading={i18n.t('dashboard__recent_activity__empty_title')}
              subtitle={i18n.t('dashboard__recent_activity__empty_wording')}
            />
          )}
          {eventsMeta && !eventsMeta.isLoading && eventsMeta.error && <DashboardErrorCard />}
          {eventsMeta && !eventsMeta.error && userEvents.length > 0 && (
            <InfiniteList
              aria-label="recent activity table"
              listState={listState}
              items={userEvents.map(ev => {
                return {
                  key: ev.id.toString(),
                  content: (
                    <>
                      {!!ev.client_id ? (
                        <GhostLink to={getClientUrl(ev.client_id, '')} className={classes.linkRow}>
                          <span dangerouslySetInnerHTML={{ __html: ev.text }} />
                          <Typography component="time" dateTime={ev.created_at}>
                            {formatEventDateOffset(new Date(), ev.created_at, timeZone)}
                          </Typography>
                        </GhostLink>
                      ) : (
                        <div className={classes.linkRow}>
                          <span dangerouslySetInnerHTML={{ __html: ev.text }} />
                          <Typography component="time" dateTime={ev.created_at}>
                            {formatEventDateOffset(new Date(), ev.created_at, timeZone)}
                          </Typography>
                        </div>
                      )}
                    </>
                  ),
                }
              })}
              loadPage={loadEventsPage}
              scrollContainer={'parent'}
            />
          )}
        </Paper>
      </CardContent>
    </Card>
  )
}

const getWhenLastRefreshed = (lastRefreshed?: Date): string => {
  if (!lastRefreshed) {
    return ''
  }
  const now = new Date()
  const secDiff = differenceInSeconds(now, lastRefreshed)

  if (secDiff < 3) {
    return i18n.t('dashboard__recent_activity__last_refreshed_now')
  }

  if (secDiff < 60) {
    return i18n.t('dashboard__recent_activity__last_refreshed_min')
  }

  const minDiff = differenceInMinutes(now, lastRefreshed)
  return i18n.t('dashboard__recent_activity__last_refreshed_min_ago', { minute: minDiff })
}

const memoizedRecentActivity = React.memo(RecentActivity, isEqual)
// @ts-ignore
memoizedRecentActivity.whyDidYouRender = true
export default memoizedRecentActivity
