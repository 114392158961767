import { Client } from '../../../store/clients/reducer'
import { OrderItem } from '../../../store/items/types'

// Helper function that will create a URL to a phone call
export default function(client: Client, scheduledItem: OrderItem): string | null {
  if (
    !client?.productsMetadata?.portal_url_for_purchase_brand ||
    !client?.productsMetadata?.auth_token ||
    !scheduledItem?.id
  ) {
    return null
  }

  return `${client.productsMetadata.portal_url_for_purchase_brand}/call/${scheduledItem.id}?pat=${client.productsMetadata?.auth_token}`
}
