import React, { useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Search from '@material-ui/icons/Search'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

import i18n from '../../i18n'
import styles from '../../styles'
import Download from '../common/Icons/Download'
import resumeDefault from '../../assets/svgs/resume-default.svg'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '13.3125rem',
    },
    imageContainer: {
      position: 'relative',
      width: '100%',
      paddingTop: '129%',
    },
    image: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.35)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    description: {
      width: '100%',
      marginTop: '.6625rem',
      textAlign: 'center',
      color: '#5b5b64',
      cursor: 'pointer',
      '& .description-item': {
        display: 'inline-block',
        margin: '.4375rem',
        whiteSpace: 'nowrap',
      },
      '& .icon': {
        color: styles.palette.link.color,
        width: '1.2rem',
        height: '1.2rem',
        verticalAlign: 'middle',
      },
      '& .text': {
        color: '#5b5b64',
        fontSize: '.9375rem',
        lineHeight: '1.2rem',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
      },
      '@media (max-width: 440px)': {
        '& .description-item': { whiteSpace: 'normal', margin: '.4375rem 0' },
        '& .icon, & .text': { display: 'block', margin: '0 auto' },
      },
    },
    '@global .ril__image': {
      backgroundColor: 'white',
    },
    '@global .ril__image.ril-not-loaded': {
      backgroundColor: 'transparent',
    },
    downloadIcon: {
      color: '#888',
      width: '32px',
      height: '30px',
      verticalAlign: 'middle',
      marginTop: '1px',
      '&:hover, &:active': {
        color: '#b8b7b7',
      },
    },
  })
)

interface ResumePreviewProps {
  imageUrls: string[]
  downloadUrl?: string | null
  renderDownloadIcon?: boolean
  imageLoadErrorCallback?: (imageURLS: string[]) => void
}

const ResumePreview: React.FC<ResumePreviewProps> = ({
  imageUrls,
  downloadUrl = '#',
  renderDownloadIcon = true,
  imageLoadErrorCallback,
}) => {
  const classes = useStyles()
  const [photoIndex, setPhotoIndex] = useState(0)
  const [visible, setVisible] = useState(false)
  const [imageLoadError, setImageLoadError] = useState(imageUrls.length === 0)
  const toolbarButtons: React.ReactNode[] = []

  if (renderDownloadIcon && !!downloadUrl && downloadUrl !== '#') {
    toolbarButtons.push(
      <a href={downloadUrl}>
        <Download className={classes.downloadIcon} />
      </a>
    )
  }

  const onImageLoadError = () => {
    setImageLoadError(true)
    !!imageLoadErrorCallback && imageLoadErrorCallback(imageUrls)
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.imageContainer}>
        {!imageLoadError && (
          <>
            <img
              src={imageUrls[0]}
              className={classes.image}
              alt={i18n.t('resume__preview__alt__text')}
              onClick={() => setVisible(true)}
              onError={() => onImageLoadError()}
            />
            {visible && (
              <Lightbox
                mainSrc={imageUrls[photoIndex]}
                nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
                prevSrc={imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]}
                onCloseRequest={() => {
                  setVisible(false)
                  setPhotoIndex(0)
                }}
                onMovePrevRequest={() => setPhotoIndex((photoIndex + imageUrls.length - 1) % imageUrls.length)}
                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % imageUrls.length)}
                toolbarButtons={toolbarButtons}
              />
            )}
          </>
        )}
        {imageLoadError && <img src={resumeDefault} className={classes.image} alt="" />}
      </Box>

      {!imageLoadError && (
        <Box className={classes.description}>
          <Box onClick={() => setVisible(true)} className="description-item">
            <Search className="icon" />
            <Typography variant={'body2'} className="text" component={'span'}>
              {i18n.t(`resume__preview__page__count`, { count: imageUrls.length })}
            </Typography>
          </Box>
          {renderDownloadIcon && !!downloadUrl && downloadUrl !== '#' && (
            <a href={downloadUrl} className="description-item file-download-link">
              <Download className="icon" />
              <Typography variant={'body2'} className="text" component={'span'}>
                {i18n.t('resume__preview__download')}
              </Typography>
            </a>
          )}
        </Box>
      )}
    </Box>
  )
}

export default ResumePreview
