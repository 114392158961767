import React from 'react'
import Card from '@material-ui/core/Card'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { StringError } from '../../store/dashboard/types'
import DashboardErrorCard from './DashboardErrorCard'
import i18n from '../../i18n'
import { DashboardVariants } from '.'
import DashboardStyles from './Styles'

interface OutputHeroCardProps {
  loading: boolean
  output_stats: StringError
  variant: DashboardVariants
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emojiLine: {
      fontSize: DashboardStyles.emoji.size,
      margin: DashboardStyles.emoji.margin,
    },
    h5: {
      fontSize: DashboardStyles.header2.size,
      fontWeight: DashboardStyles.header2.weight,
      margin: DashboardStyles.header2.margin,
    },
    h6: {
      fontSize: DashboardStyles.header3.size,
      lineHeight: '1.28em',
      width: '95%',
    },
    heroCard: {
      backgroundColor: DashboardStyles.card2.bg,
      color: 'inherit',
      padding: DashboardStyles.card2.padding,
      width: DashboardStyles.halfWidth,
    },
    [theme.breakpoints.down('sm')]: {
      heroCard: {
        margin: DashboardStyles.card.marginMobile,
        maxWidth: DashboardStyles.fullWidth,
        width: DashboardStyles.fullWidth,
      },
    },
  })
)

export const OutputHeroCard: React.FC<OutputHeroCardProps> = q => {
  const classes = useStyles()
  if (!!q.output_stats && q.output_stats.error != null && !q.loading) {
    return <DashboardErrorCard />
  }

  let lineString = i18n.t('dashboard__relative__output__none')
  let comparison = i18n.t('dashboard__relative__output__child__none')
  let emojiCode = '🥇'

  if (q.variant !== DashboardVariants.NewHire) {
    switch (q.output_stats.value) {
      case 'positive':
        lineString = i18n.t('dashboard__relative__output__high')
        comparison = i18n.t('dashboard__relative__output__more')
        emojiCode = '🤩'
        break
      case 'negative':
        lineString = i18n.t('dashboard__relative__output__low')
        comparison = i18n.t('dashboard__relative__output__less')
        emojiCode = '😣'
        break
      default:
        lineString = i18n.t('dashboard__relative__output__avg')
        comparison = i18n.t('dashboard__relative__output__same')
        emojiCode = '🤓'
        break
    }
  }

  return (
    <Card className={classes.heroCard} id="dashboard-output-hero-card">
      <Typography className={classes.emojiLine} align="center">
        {emojiCode}
      </Typography>

      <Typography display="block" variant="h5" className={classes.h5}>
        {lineString}
      </Typography>

      <Typography display="block" variant="h6" className={classes.h6}>
        {comparison}
      </Typography>
    </Card>
  )
}

// @ts-ignore
OutputHeroCard.WhyDidYouRender = true

export default OutputHeroCard
