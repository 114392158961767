import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import i18n from '../../../i18n'
import Button from '../../common/Button'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import DashboardStyles from '../../Dashboard/Styles'
import FormGroup from '@material-ui/core/FormGroup'
import Box from '@material-ui/core/Box'
import { Preferences as PreferenceType } from '../../../store/scheduling/types'

interface Props {
  onSetPreferences: (preferences: PreferenceType) => void
}
export const useStyles = makeStyles(() =>
  createStyles({
    formGroupAll: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    formGroup: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      margin: '2rem 1rem 0rem 1rem',
    },
    label: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      color: 'black',
      textAlign: 'center',
    },
    formCheck: {
      textAlign: 'center',
      fontSize: '.8rem',
      color: 'black',
    },
    setUpButton: {
      width: '10rem',
      backgroundColor: DashboardStyles.getOrdersButton.regularColor,
      color: 'white',
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: DashboardStyles.getOrdersButton.weight,
      '&:hover': {
        backgroundColor: DashboardStyles.getOrdersButton.hoverColor,
      },
      marginTop: '2.3rem',
    },
    textField: {
      marginTop: '1rem',
      marginLeft: '1rem',
    },
    numberField: {
      width: '4rem',
      marginTop: '1rem',
      marginLeft: '2rem',
      textAlign: 'center',
    },
    '& .MuiTextField-root': {
      textAlign: 'center',
    },
    formCheckNo: {
      borderColor: DashboardStyles.getOrdersButton.regularColor,
      color: DashboardStyles.getOrdersButton.regularColor,
      textTransform: 'none',
      fontSize: '1rem',
      marginRight: '.5rem',
      fontWeight: DashboardStyles.getOrdersButton.weight,
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    formCheckYes: {
      backgroundColor: DashboardStyles.getOrdersButton.regularColor,
      color: 'white',
      textTransform: 'none',
      fontSize: '1rem',
      marginLeft: '.5rem',
      fontWeight: DashboardStyles.getOrdersButton.weight,
      '&:hover': {
        backgroundColor: DashboardStyles.getOrdersButton.hoverColor,
      },
    },
  })
)

const Preferences: React.FC<Props> = ({ onSetPreferences }) => {
  const classes = useStyles()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const preferences = {
    min_notice: 4,
    buffer: 30,
    email_reminder: false,
    sms_reminder: false,
  }

  const handleReminderChange = async (email_reminder: boolean) => {
    setIsSubmitting(true)
    const updatedPreferences = { ...preferences, email_reminder }
    await onSetPreferences(updatedPreferences)
  }

  return (
    <React.Fragment>
      <Typography className={classes.label} component={'h2'}>
        {i18n.t('schedule_calendar_reminder')}
      </Typography>
      <FormGroup className={classes.formGroupAll}>
        <Box display={'flex'} flexDirection={'column'}>
          <FormGroup className={classes.formGroup} row>
            <Button
              className={classes.formCheckNo}
              type="secondary"
              onClick={() => handleReminderChange(false)}
              showLoader={isSubmitting}
            >
              <Typography>{i18n.t('no')} </Typography>
            </Button>
            <Button
              className={classes.formCheckYes}
              type="primary"
              onClick={() => handleReminderChange(true)}
              showLoader={isSubmitting}
            >
              <Typography>{i18n.t('yes')} </Typography>
            </Button>
          </FormGroup>
        </Box>
      </FormGroup>
    </React.Fragment>
  )
}

export default Preferences
