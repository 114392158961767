import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import i18n from '../../../i18n'
import Button from '../../common/Button'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import DashboardStyles from '../../Dashboard/Styles'
import CalendarLogo from '../../../assets/calendar.png'

interface Props {
  onGenerateSchedule: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    calendarIcon: {
      width: '1.9rem',
      height: 'auto',
      marginBottom: '.9rem',
    },
    label: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      color: 'black',
      textAlign: 'center',
    },
    setUpButton: {
      width: '10rem',
      backgroundColor: DashboardStyles.getOrdersButton.regularColor,
      color: 'white',
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: DashboardStyles.getOrdersButton.weight,
      '&:hover': {
        backgroundColor: DashboardStyles.getOrdersButton.hoverColor,
      },
      marginTop: '2.3rem',
    },
  })
)

const Trigger: React.FC<Props> = ({ onGenerateSchedule }) => {
  const classes = useStyles()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmitClick = async () => {
    setIsSubmitting(true)
    await onGenerateSchedule()
  }
  return (
    <React.Fragment>
      <img src={CalendarLogo} className={classes.calendarIcon} alt="Calendar logo" />
      <Typography className={classes.label} component={'h2'}>
        {i18n.t('schedule_setup_message')}
      </Typography>
      <Button
        type="primary"
        className={classes.setUpButton}
        onClick={() => handleSubmitClick()}
        showLoader={isSubmitting}
      >
        {i18n.t('schedule_setup_now')}
      </Button>
    </React.Fragment>
  )
}

export default Trigger
