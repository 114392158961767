import { pickBy } from 'lodash'

import { AppState } from '../store'
import { Snippet, SnippetList, Template, TemplateList, TemplateState, TemplateTypes } from '../store/templates/types'
import { MessageContext, MessageContexts } from '../store/messages/types'
import { HandlebarsContextType, HandlebarsOrderContext } from '../utils/handlebars'
import { OrderCTA, OrderItemDisplayStates, OrderItemStates, OrderItemState } from '../store/items/types'
import { clientByIdSelector, clientCanGetDocsInEmail, getUpsells } from './clients'
import { ClientsState } from '../store/clients/reducer'
import { Language } from '../utils/consts'
import { Document } from '../store/documents/types'
import { formatDate, getLocale } from '../utils/formatting'
import { nextScheduledItem, firstAwaitingSchedulingPhoneCall } from './items'
import { UserInfo } from '../store/user/reducers'
import { showVideoCritique } from './featureFlags'

interface TemplateSelectorOptions {
  context?: undefined
  contextID?: undefined
  clientState?: undefined
  itemState?: undefined
  language: Language
  cta?: OrderCTA
  userState?: UserInfo | null
}

interface TemplateSelectorOptionsWithContext
  extends Omit<TemplateSelectorOptions, 'context' | 'contextID' | 'clientState' | 'itemState'> {
  context: MessageContext
  contextID: number
  clientState: ClientsState
  itemState: OrderItemState
}

export const templatesSelector = (
  state: TemplateState,
  {
    language,
    cta,
    context,
    contextID,
    clientState,
    itemState,
    userState,
  }: TemplateSelectorOptions | TemplateSelectorOptionsWithContext
): [TemplateList, boolean] => {
  let availableTemplates = pickBy(state.templates, (template: Template): boolean => {
    return (
      template.language === language &&
      !template.type.toLowerCase().includes('default') &&
      !template.type.toLowerCase().includes('direct scheduling')
    )
  })

  // remove CloseOrder and CloseOrderCIO templates from templates list
  availableTemplates = pickBy(availableTemplates, (template: Template): boolean => {
    return ![TemplateTypes.CloseOrder, TemplateTypes.CloseOrderCIO].includes(template.type as TemplateTypes)
  })

  if (!showVideoCritique(userState as UserInfo)) {
    availableTemplates = pickBy(availableTemplates, (template: Template): boolean => {
      return ![TemplateTypes.ResumeEditIntro, TemplateTypes.ResumeEditFarewell].includes(template.type as TemplateTypes)
    })
  }

  if (
    context !== MessageContexts.CLIENT ||
    !contextID ||
    !itemState ||
    !clientState ||
    !nextScheduledItem(itemState, clientState, contextID)
  ) {
    availableTemplates = pickBy(availableTemplates, template => !template.type.toLowerCase().includes('call reminder'))
  }

  return [availableTemplates, state.templatesMeta.isLoading || !!state.templatesMeta.error]
}

export const directSchedulingTemplateSelector = (state: TemplateState, language: Language): Template | null => {
  return Object.values(state.templates).find(
    t => t.language === language && t.type.toLowerCase().includes('direct scheduling')
  )
}

export const defaultTemplateSelector = (state: TemplateState, language: Language): Template | null => {
  return Object.values(state.templates).find(t => t.type === 'Default' && t.language === language)
}

export const defaultReplyTemplateSelector = (state: TemplateState, language: Language): Template | null => {
  return Object.values(state.templates).find(t => t.type === 'Default Reply' && t.language === language) || null
}

export const snippetsSelector = (state: TemplateState, language: Language): [SnippetList, boolean] => {
  const snippetsByLanguage = pickBy(state.snippets, snippet => {
    return snippet.language === language
  })
  return [snippetsByLanguage, state.snippetsMeta.isLoading || !!state.snippetsMeta.error]
}

interface SnippetsWithContextSelectorOptions {
  context: MessageContext
  contextID: number
  clientState: ClientsState
  itemState: OrderItemState
  language: Language
}

export const snippetsWithContextSelector = (
  state: TemplateState,
  { context, contextID, clientState, itemState, language }: SnippetsWithContextSelectorOptions
): [SnippetList, boolean] => {
  const snippetsArray = snippetsSelector(state, language)
  const needsLoading = snippetsArray[1]
  let snippets = snippetsArray[0]

  if (context === MessageContexts.CLIENT) {
    const scheduledItem = nextScheduledItem(itemState, clientState, contextID)

    if (!scheduledItem) {
      snippets = pickBy(snippets, snippet => !snippet.name.toLowerCase().includes('call reminder'))
    }
  }

  return [snippets, needsLoading]
}

export const snippetsByTypeSelector = (snippets: Snippet[], type: string | null): Snippet[] => {
  if (type === null) {
    return []
  }

  return snippets.filter(snippet => snippet.type === type)
}

export const allSnippetTypesSelector = (snippets: Snippet[]): string[] => {
  return Array.from(new Set(snippets.map(snippet => snippet.type)))
}

export const handlebarsTemplateContextSelector = (
  state: AppState,
  context: MessageContext,
  contextID: number,
  userID: number | null,
  attachments: Document[],
  orderID?: number | null
): HandlebarsContextType => {
  let user = userID ? state.userReducer.users[userID] : null

  if (!user) {
    user = state.userReducer.getLoggedInUser()
  }

  switch (context) {
    case MessageContexts.USER: {
      const expert = state.userReducer.users[contextID]
      const order = orderID ? state.orderReducer.orders[orderID] : null
      const client = order ? clientByIdSelector(state.clientReducer, order.client_id) : null
      const upsells = order ? getUpsells(state.clientReducer, order.client_id) : []
      const brand = order ? order.brand : null
      return {
        expert,
        client,
        order,
        user,
        brand,
        hasAccountLink: false,
        inPortal: false,
        i18next: { lng: 'en' },
        upsells,
        snippetName: '',
      }
    }
    case MessageContexts.CLIENT: {
      const client = clientByIdSelector(state.clientReducer, contextID)
      const upsells = getUpsells(state.clientReducer, contextID)
      let order = orderID ? state.orderReducer.orders[orderID] : null
      let brand = null
      let lng = 'en'

      const fileAttachmentDomains = [
        'gmail.com',
        'yahoo.com',
        'icloud.com',
        'aol.com',
        'comcast.net',
        'me.com',
        'yahoo.co.uk',
        'btinternet.com',
        'sky.com',
        'live.com',
        'att.net',
        'sbcglobal.com',
        'live.co.uk',
        'mac.com',
        'gmx.de',
        'verizon.net',
        'bellsouth.net',
        'ymail.com',
        'web.de',
        'bigpond.com',
        'cox.net',
      ]

      const docsInEmail = clientCanGetDocsInEmail(client)
      const lowerCaseEmail = client.primary_email.toLowerCase()
      let useFileURL =
        !fileAttachmentDomains.find(domain => lowerCaseEmail.includes(domain)) && attachments.length > 0 && docsInEmail

      let useStagingFileURL = false

      // If we have a client and no provided order, grab the latest one from the client.
      if (!order && client) {
        order = state.orderReducer.orders[Math.max(...client.order_ids)] || null
      }

      if (order) {
        brand = order.brand
        lng = order.language

        if (brand.name === 'Nexxt') {
          useFileURL = false
        }

        // for the url generation, let's use the staging endpoint if we're in staging.
        if (window.location.host.includes('dev') || window.location.host.includes('staging')) {
          useStagingFileURL = true
        }
      }

      const meta: HandlebarsOrderContext = {
        order,
        client,
        user,
        brand,
        scheduledItem: nextScheduledItem(state.itemsReducer, state.clientReducer, client.id),
        awaitingSchedulingPhoneCall: firstAwaitingSchedulingPhoneCall(
          state.itemsReducer,
          state.clientReducer,
          client.id
        ),
        hasAccountLink: !!client.productsMetadata?.portal_url || !!client.productsMetadata?.questionnaire_url,
        inPortal: !!client?.productsMetadata?.portal_url,
        i18next: { lng },
        attachments,
        useFileURL,
        useStagingFileURL,
        docsInEmail,
        upsells,
        snippetName: '',
      }

      if (!!state.currentMessageReducer.direct_scheduling && !!user) {
        const directScheduling = state.currentMessageReducer.direct_scheduling
        const userLocale = getLocale(user)
        meta.directScheduling = {
          ...directScheduling,
          day: formatDate(directScheduling.date_time, user.timezone_string, userLocale, 'PPPP'),
          time: formatDate(directScheduling.date_time, user.timezone_string, userLocale, 'h:mm a'),
        }
      }
      return meta
    }
  }
}

export const getTemplateForOrderCTA = (
  state: TemplateState,
  cta: OrderCTA | null,
  language: Language = 'en',
  cioMagicLink: string | undefined, // Adjusted parameter type
  itemsCoreProductIds: number[] = [],
  brandName: string // Added brandName parameter
): Template | null => {
  let type: TemplateTypes | undefined

  switch (cta) {
    case OrderItemStates.SendFirstDraft:
      type = TemplateTypes.FirstDraft
      break

    case OrderItemStates.SendIntro:
      // core product id 14 is for `resume-edit`
      type = itemsCoreProductIds.includes(14) ? TemplateTypes.ResumeEditIntro : TemplateTypes.Introduction
      break

    case OrderItemStates.SendSummary:
      type = TemplateTypes.CallSummary
      break

    case OrderItemDisplayStates.CloseOrder: // Restored this case
      // core product id 14 is for `resume-edit`
      if (itemsCoreProductIds.includes(14)) {
        type = TemplateTypes.ResumeEditFarewell
      } else {
        type = !!cioMagicLink ? TemplateTypes.CloseOrderCIO : TemplateTypes.CloseOrder
      }
      break

    case OrderItemStates.Closed: // Handled 'closed' state
      // core product id 14 is for `resume-edit`
      if (itemsCoreProductIds.includes(14)) {
        type = TemplateTypes.ResumeEditFarewell
      } else if (brandName === 'TopResume' && !!cioMagicLink) {
        // Use 'Close Order Cio With Upsell' template when brand is TopResume and we have a magic link
        type = TemplateTypes.CloseOrderCIOWithUpsell
      } else if (!!cioMagicLink) {
        // Use 'Close Order CIO' template when we have a magic link
        type = TemplateTypes.CloseOrderCIO
      } else {
        // Use 'Close Order' template when we don't have a magic link
        type = TemplateTypes.CloseOrder
      }
      break

    case OrderItemStates.SendLinkedin:
      type = TemplateTypes.LinkedIn
      break

    case OrderItemStates.RescheduleMessage:
      type = TemplateTypes.Reschedule
      break

    default:
      return null
  }

  // Return the found template based on the type and language.
  return (
    Object.values(state.templates).find(template => template.type === type && template.language === language) || null
  )
}
