import React from 'react'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  lazy?: boolean
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, lazy = false, ...other }) => {
  const hidden = value !== index

  if (hidden && lazy) {
    return null
  }

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={hidden}
      id={`order-type-tabpanel-${index}`}
      aria-labelledby={`order-type-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  )
}

export default TabPanel
