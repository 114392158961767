import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import DashboardStyles from './Styles'
import PartyBackground from '../../assets/svgs/party.svg'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

type CardClass = 'fullwidth' | 'quarter' | 'inner' | 'third'

interface Props {
  cardClass?: CardClass
  altColor?: boolean
  title: string
  party?: boolean
  id?: string
}

const useStyles = makeStyles(theme =>
  createStyles({
    content: {
      padding: '0',

      '&:last-child': {
        paddingBottom: '0',
      },
    },
    default: {
      backgroundColor: DashboardStyles.card.bg,
      border: DashboardStyles.card.border,
      boxShadow: DashboardStyles.card.shadow,
      padding: DashboardStyles.card.padding,
      width: DashboardStyles.fullWidth,
    },
    fullWidth: {
      color: 'inherit',
      padding: '0',
      width: DashboardStyles.fullWidth,
    },
    inner: {
      width: '45%',
    },
    third: {
      border: DashboardStyles.card.border,
      boxShadow: DashboardStyles.card.shadow,
      color: 'inherit',
      padding: DashboardStyles.card.padding,
      width: DashboardStyles.thirdWidth,

      '& div': {
        padding: '0',
      },

      '& > div': {
        minHeight: '146px',
      },
    },
    quarter: {
      border: DashboardStyles.card.border,
      boxShadow: DashboardStyles.card.shadow,
      color: 'inherit',
      padding: DashboardStyles.card.padding,
      width: DashboardStyles.quarterWidth,

      '& div': {
        padding: '0',
      },

      '& > div': {
        minHeight: '146px',
      },
    },
    title: {
      color: DashboardStyles.header4.color,
      fontSize: DashboardStyles.header4.size,
      padding: DashboardStyles.header4.padding,
      textTransform: 'uppercase',

      '&.altColor': {
        color: DashboardStyles.editingColor,
      },

      '& div': {
        '& span': {
          fontSize: 'inherit',
        },
      },
    },
    party: {
      backgroundColor: DashboardStyles.party.backgroundColor,
      backgroundImage: `
        ${DashboardStyles.party.backgroundGradient},
        url(${PartyBackground})
      `,
      color: DashboardStyles.party.textColor,
    },
    partyText: {
      color: DashboardStyles.party.textColor,
    },
    [theme.breakpoints.down('sm')]: {
      inner: {
        margin: DashboardStyles.card.marginMobile,
        width: DashboardStyles.fullWidth,
      },
      quarter: {
        margin: DashboardStyles.card.marginMobile,
        width: DashboardStyles.fullWidth,
      },
      third: {
        margin: DashboardStyles.card.marginMobile,
        width: DashboardStyles.fullWidth,
      },
    },
  })
)

const cardClasses = (cardClass: CardClass | undefined, classes: ClassNameMap<string>) => {
  switch (cardClass) {
    case 'fullwidth':
      return [classes.fullWidth, classes.fullWidth]
    case 'quarter':
      return [classes.quarter, classes.content]
    case 'third':
      return [classes.third, classes.content]
    case 'inner':
      return [classes.inner, classes.content]
    default:
      return [classes.default, classes.content]
  }
}

const CardWrapper: React.FC<Props> = props => {
  const classes = useStyles(props)
  const [cardClass, cardContentClass] = cardClasses(props.cardClass, classes)

  return (
    <Card
      className={classNames({
        [cardClass]: true,
        [classes.party]: props.party,
      })}
      color="primary"
      id={props.id}
      area-label="year_to_date"
    >
      <CardContent className={cardContentClass} color="primary">
        <CardHeader
          title={props.title}
          className={classNames({
            [classes.title]: true,
            altColor: props.altColor,
            [classes.partyText]: props.party,
          })}
          color="secondary"
        />
        {props.children}
      </CardContent>
    </Card>
  )
}

export default CardWrapper
