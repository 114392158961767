import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import Box from '@material-ui/core/Box'
import i18n from '../../i18n'
import Chat from './Chat'
import { AppState } from '../../store'
import { HashMap, OrderCTA } from '../../store/items/types'
import { Message, MessageContext, MessageContexts } from '../../store/messages/types'
import { currentMessageHasContent, messageByContextIdSelector, messagesNeedFetching } from '../../selectors/messages'
import { fetchMessages, markMessageAsRead, markMessageAsUnread, toggleMessageRead } from '../../store/messages/actions'
import { currentMessageSetReply } from '../../store/currentMessage/actions'
import { getAttachmentsForMessages } from '../../selectors/documents'
import { UserInfo } from '../../store/user/reducers'
import { Document } from '../../store/documents/types'
import { Client } from '../../store/clients/reducer'
import { clientByIdSelector } from '../../selectors/clients'
import { ChatMessageActions } from './ChatMessage'
import { ClientOrdersDisplayAggregate, clientOrdersSelector } from '../../selectors/clientOrderAggregates'
import { fetchClientOrderDetailsThunk } from '../../store/clientOrderAggregate/actions'

const useStyles = makeStyles(() =>
  createStyles({
    leftColumn: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
  })
)

interface MessagingProps {
  clientID: number
  initialState?: OrderCTA
  context: MessageContext
  user: UserInfo
}

const Messaging: React.FC<MessagingProps> = ({ clientID, initialState, context, user }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const client = useSelector<AppState, Client>(state => clientByIdSelector(state.clientReducer, clientID))

  const clientOrdersAggregate = useSelector<AppState, ClientOrdersDisplayAggregate | null>(state =>
    clientOrdersSelector(
      {
        ...state.clientReducer,
        ...state.clientOrdersAggregateReducer,
        ...state.itemsReducer,
        ...state.orderReducer,
        ...state.incentivesReducer,
        ...state.userReducer,
      },
      clientID
    )
  )

  const activeOrderID =
    !!clientOrdersAggregate && !!clientOrdersAggregate.macroState.orderID
      ? clientOrdersAggregate.macroState.orderID
      : client.order_ids[0]

  const messages = useSelector<AppState, Message[]>(state =>
    messageByContextIdSelector(state.messagesReducer, MessageContexts.CLIENT, clientID)
  )
  const messagesNeedLoading = useSelector<AppState, boolean>(state => messagesNeedFetching(state.messagesReducer))
  const messageAttachments = useSelector<AppState, HashMap<Document[]>>(state =>
    getAttachmentsForMessages(state.documentReducer, context, clientID, messages)
  )

  const currentMessageReplyID = useSelector<AppState, number | null>(
    state => state.currentMessageReducer.replyActualMessageID
  )
  const dueDate = !!clientOrdersAggregate ? clientOrdersAggregate.macroState.dueDate : null
  const messageCompositionVisible = useSelector<AppState, boolean>(state =>
    currentMessageHasContent(state.currentMessageReducer)
  )

  const replyHandler = React.useCallback(
    (message: Message | null) => {
      dispatch(currentMessageSetReply(message))
      if (!!message) {
        dispatch(markMessageAsRead(message))
      }
    },
    [dispatch]
  )

  const messageActions: ChatMessageActions = React.useMemo(() => {
    return {
      markMessageAsRead: async (message: Message) => await dispatch(markMessageAsRead(message)),
      markMessageAsUnread: async (message: Message) => await dispatch(markMessageAsUnread(message)),
      toggleMessageRead: async (message: Message) => await dispatch(toggleMessageRead(message)),
    }
  }, [dispatch])

  useEffect(() => {
    if (!client) {
      dispatch(fetchClientOrderDetailsThunk(clientID, { verbose: true }))
    }
  }, [dispatch, user, client, clientID])

  useEffect(() => {
    if (messagesNeedLoading) {
      dispatch(fetchMessages(context, clientID))
    }
  }, [dispatch, context, clientID, messagesNeedLoading])

  return (
    <>
      <Helmet>
        <title>{i18n.t('messaging__page_title')}</title>
      </Helmet>
      <Box className={classes.leftColumn}>
        <Chat
          context={context}
          contextID={clientID}
          dueDate={dueDate}
          messages={messages}
          activeOrderID={activeOrderID}
          orderIDs={!!clientOrdersAggregate ? clientOrdersAggregate.client.order_ids : []}
          clientID={clientID}
          initialState={initialState}
          attachments={messageAttachments}
          replyHandler={replyHandler}
          currentMessageReplyID={currentMessageReplyID}
          chatMessageActions={messageActions}
          messageCompositionVisible={messageCompositionVisible}
        />
      </Box>
    </>
  )
}

export default Messaging
