import React from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box, { BoxProps } from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

interface TopMenuProps extends BoxProps {
  delimiter?: string
  children: React.ReactNode
  handleClick?: React.EventHandler<React.SyntheticEvent>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      minHeight: '3.5625rem',
      display: 'flex',
      alignItems: 'center',
      '&.both, &.left': { borderLeft: '1px solid #CACFD8' },
      '&.both, &.right': { borderRight: '1px solid #CACFD8' },
    },
    button: {
      height: '100%',
      minHeight: '3.5625rem',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      textTransform: 'initial',
      borderRadius: 0,
      '@media (max-width: 400px)': {
        paddingLeft: '.25rem',
        paddingRight: '.25rem',
        minWidth: '2.5rem',
      },
    },
  })
)

const TopMenuItem: React.FC<TopMenuProps> = props => {
  const classes = useStyles()
  const { delimiter, handleClick } = props
  const edge = delimiter ? (delimiter === 'none' ? '' : delimiter) : 'right'

  return (
    <Box className={`${classes.container} ${edge} ${props.className}`}>
      <Button onClick={handleClick} className={classes.button}>
        {props.children}
      </Button>
    </Box>
  )
}

export default TopMenuItem
