import { every, groupBy, some } from 'lodash'
import { createSelector, ParametricSelector, Selector } from 'reselect'
import compareDateAsc from 'date-fns/compareAsc'
import { filterHashMap, filterHashMapAll, filterHashMapAny, filterPropEquals } from '../utils/selectors'
import {
  DisplayPriorityStateMap,
  HashMap,
  OrderItem,
  OrderItemState,
  OrderItemStates,
  OrderItemStatus,
  OrderItemTypes,
} from '../store/items/types'
import { UploadDocTypeMenu } from '../store/uploads/types'
import { Document } from '../store/documents/types'
import { ClientOrdersDisplayAggregate } from './clientOrderAggregates'
import { OrdersState } from '../store/orders/reducer'
import { Order } from '../store/orders/types'
import { ClientsState } from '../store/clients/reducer'

export const itemsByOrderIdSelector = (state: OrderItemState) => state.itemsByOrderId

export const ordersSelector: Selector<OrdersState, HashMap<Order> | {}> = state => state.orders

const filterOrdersActive = filterPropEquals('status', 'open')

export const activeOrdersSelector = createSelector<OrdersState, HashMap<Order> | {}, HashMap<Order> | {}>(
  ordersSelector,
  orders => filterHashMap(orders, filterOrdersActive)
)

export const orderItemsSelector = (state: OrderItemState, orderId: number): HashMap<OrderItem> | null => {
  return state.itemsByOrderId ? state.itemsByOrderId[orderId] : null
}

export const getItemByIDSelector: ParametricSelector<OrderItemState, number, OrderItem | undefined> = (state, id) => {
  const allItemsArr = Object.values(state.itemsByOrderId)
    .map(e => Object.values(e))
    .flat()
  return allItemsArr.find(i => i.id === id)
}

export const orderItemsArraySelector = (state: OrderItemState, orderId: number): OrderItem[] => {
  try {
    return Object.values(state.itemsByOrderId[orderId])
  } catch (e) {
    return []
  }
}

export const getCurrentItemDisplayState = (item: OrderItem) => {
  if ([OrderItemTypes.Incentive, OrderItemTypes.Rush, OrderItemTypes.OrderDetails].includes(item.core_product_key)) {
    return null
  }
  const activeStates = item.status.active
  if (!activeStates) return activeStates

  // ensure that if an item has active Send LinkedIn state it is selected as the current display state
  const sendLinkedInState = activeStates.filter(s => s.state === OrderItemStates.SendLinkedin)
  if (sendLinkedInState && sendLinkedInState.length > 0) {
    return sendLinkedInState[0]
  }

  const sortedStates = activeStates.sort((s1, s2) => {
    // then compare by start date?
    if (!s1.due_at && !s2.due_at) return 1
    if (!s1.due_at) return -1
    if (!s2.due_at) return 1
    return s1.due_at && s2.due_at && Date.parse(s1.due_at) > Date.parse(s2.due_at) ? 1 : -1
  })

  return sortedStates[0]
}

export const sortByDueDateAsc = (s1: OrderItemStatus, s2: OrderItemStatus) => {
  if (DisplayPriorityStateMap[s1.state] === DisplayPriorityStateMap[s2.state]) {
    if (!s1.due_at || !s2.due_at) {
      return 1
    }
    return new Date(s1.due_at) > new Date(s2.due_at) ? 1 : -1
  }
  return DisplayPriorityStateMap[s1.state] > DisplayPriorityStateMap[s2.state] ? 1 : -1
}

export const sortByEndDateDesc = (s1: OrderItemStatus, s2: OrderItemStatus) => {
  if (!s1.ended_at || !s2.ended_at) {
    return 1
  }
  return new Date(s2.ended_at) > new Date(s1.ended_at) ? 1 : -1
}

export type SortingDirection = 'asc' | 'desc'

export const sortByMacroDueDate = (dir: SortingDirection) => {
  return function(x: ClientOrdersDisplayAggregate, y: ClientOrdersDisplayAggregate) {
    // push closed down, refunded even further down if asc, up otherwise
    switch (x.macroState.CTA) {
      case OrderItemStates.Refunded:
        return dir === 'asc' ? 1 : -1
      case OrderItemStates.Closed:
        if (y.macroState.CTA === OrderItemStates.Closed) {
          return sortByDateOnly(x, y, dir)
        }
        if (dir === 'desc') {
          return y.macroState.CTA === OrderItemStates.Refunded ? 1 : -1
        }
        return y.macroState.CTA === OrderItemStates.Refunded ? -1 : 1
      default:
        if (y.macroState.CTA === OrderItemStates.Refunded || y.macroState.CTA === OrderItemStates.Closed) {
          return dir === 'asc' ? -1 : 1
        }
        return sortByDateOnly(x, y, dir)
    }
  }
}

const sortByDateOnly = (x: ClientOrdersDisplayAggregate, y: ClientOrdersDisplayAggregate, dir: SortingDirection) => {
  if (!x.macroState.dueDate || !y.macroState.dueDate) {
    return dir === 'asc' ? 1 : -1
  }
  if (dir === 'desc') {
    return new Date(x.macroState.dueDate) > new Date(y.macroState.dueDate) ? -1 : 1
  }
  return new Date(x.macroState.dueDate) > new Date(y.macroState.dueDate) ? 1 : -1
}

export const filterFulfillableItems = function(orderItems: HashMap<OrderItem> | OrderItem[]) {
  const items = Object.values(orderItems)
  return items.filter(
    i =>
      i.core_product_key !== OrderItemTypes.Incentive &&
      i.core_product_key !== OrderItemTypes.OrderDetails &&
      i.core_product_key !== OrderItemTypes.Rush &&
      i.core_product_key !== OrderItemTypes.None
  )
}

export const filterEligibleToClose = function(orderItems: HashMap<OrderItem> | OrderItem[]) {
  const fulfillableItems = filterFulfillableItems(orderItems)
  const grouped = groupBy(fulfillableItems, (item: OrderItem) => item.order_id)
  return some(
    grouped,
    fulfillableItems =>
      every(
        fulfillableItems,
        (item: OrderItem) =>
          some(
            item.status.active,
            status =>
              status.state === OrderItemStates.Revisions &&
              status.due_at != null &&
              new Date(status.due_at) <= new Date()
          ) || some(item.status.active, status => status.state === OrderItemStates.Closed)
      ) &&
      some(fulfillableItems, (item: OrderItem) =>
        some(item.status.active, status => status.state !== OrderItemStates.Closed)
      ) &&
      !some(fulfillableItems, (item: OrderItem) =>
        some(item.status.active, status => status.state === OrderItemStates.SendLinkedin)
      )
  )
}

export const filterComplete = function(orderItems: OrderItem[]) {
  const fulfillableItems = filterFulfillableItems(orderItems)

  return every<OrderItem>(fulfillableItems, item =>
    some(item.status.active, status => status.state === OrderItemStates.Closed)
  )
}

export const filterPaid = function(orderItems: OrderItem[]) {
  const fulfillableItems = filterFulfillableItems(orderItems)

  return every<OrderItem>(fulfillableItems, item =>
    some(item.status.active, status => status.state === OrderItemStates.Paid)
  )
}

// Order Paid event spans paid states and closes all other states. That being said, a reassigned order is an order
// with items that all have reassigned state either in active OR complete
export const filterReassigned = function(orderItems: OrderItem[]) {
  const fulfillableItems = filterFulfillableItems(orderItems)
  if (fulfillableItems.length === 0) {
    return false
  }
  return every<OrderItem>(
    fulfillableItems,
    item =>
      some(item.status.active, status => status.state === OrderItemStates.Reassigned) ||
      some(item.status.completed, status => status.state === OrderItemStates.Reassigned)
  )
}

// Eligible to close are orders that have all their items in revisions and the due date for all of those items is in the past.
// Orders with all the items completed should not get to this bucket.
export const eligibleToCloseOrderIdsSelector = createSelector(itemsByOrderIdSelector, orderItemsMap =>
  filterHashMap(orderItemsMap, itemsMap => filterEligibleToClose(itemsMap))
)

// New orders are orders which no real work has been done for yet. Meaning all the items in such orders don't have any
// completed states other than SendIntro or AwaitingScheduling
export const newOrderIdsSelector = createSelector<
  OrderItemState & OrdersState,
  HashMap<HashMap<OrderItem>>,
  HashMap<Order>,
  HashMap<HashMap<OrderItem>>
>(itemsByOrderIdSelector, activeOrdersSelector, (items, openOrders) => {
  const activeOrderIds = Object.keys(openOrders).map(id => Number(id))
  return filterHashMapAll(
    items,
    item =>
      activeOrderIds.includes(item.order_id) &&
      (item.status.completed.length === 0 ||
        item.status.completed.every(
          status => status.state === OrderItemStates.SendIntro || status.state === OrderItemStates.AwaitingScheduling
        )) &&
      (!item.status.active || !item.status.active.some(status => status.state === OrderItemStates.Closed))
  )
})

// Orders in revisions are orders that have at least one item with the active 'revision' state providing that this item is not a
// LinkedIn document or orders that have only one item which is a LinkedIn document and is in revisions.
export const revisionsOrderIdsSelector = createSelector(itemsByOrderIdSelector, itemsDict => {
  const linkedIn = filterHashMapAll(
    itemsDict,
    item =>
      item.core_product_key === OrderItemTypes.LinkedIn &&
      some(item.status.active, status => status.state === OrderItemStates.Revisions)
  )

  const notOnlyLinkedIn = filterHashMapAny(
    itemsDict,
    item =>
      item.core_product_key !== OrderItemTypes.LinkedIn &&
      some(item.status.active, status => status.state === OrderItemStates.Revisions)
  )

  return { ...linkedIn, ...notOnlyLinkedIn }
})
export const phoneCallOrderIdsSelector = createSelector(itemsByOrderIdSelector, itemsDict =>
  filterHashMapAny(itemsDict, item =>
    some(
      item.status.active,
      status =>
        status.state === OrderItemStates.ScheduleInterview ||
        status.state === OrderItemStates.SchedulePhoneCall ||
        status.state === OrderItemStates.SendSummary ||
        status.state === OrderItemStates.ConductInterview ||
        status.state === OrderItemStates.ConductCall ||
        status.state === OrderItemStates.AwaitingScheduling
    )
  )
)

export const toScheduleOrderIdsSelector = createSelector(itemsByOrderIdSelector, itemsDict =>
  filterHashMapAny(itemsDict, item =>
    some(
      item.status.active,
      status => status.state === OrderItemStates.ScheduleInterview || status.state === OrderItemStates.SchedulePhoneCall
    )
  )
)

export const completeOrderIdsSelector = createSelector<
  OrderItemState & OrdersState,
  HashMap<HashMap<OrderItem>>,
  HashMap<Order>,
  HashMap<HashMap<OrderItem>>
>(itemsByOrderIdSelector, activeOrdersSelector, (items, openOrders) => {
  const activeOrderIds = Object.keys(openOrders).map(id => Number(id))
  return filterHashMapAll(
    items,
    item =>
      activeOrderIds.includes(item.order_id) &&
      (item.status.active == null ||
        item.status.active.length === 0 ||
        some(item.status.active, status => status.state === OrderItemStates.Closed))
  )
})

export const sendSummaryOrderIdsSelector = createSelector(itemsByOrderIdSelector, itemsDict =>
  filterHashMapAny(itemsDict, item => some(item.status.active, status => status.state === OrderItemStates.SendSummary))
)

export const scheduledOrderIdsSelector = createSelector(itemsByOrderIdSelector, itemsDict =>
  filterHashMapAny(itemsDict, item =>
    some(
      item.status.active,
      status => status.state === OrderItemStates.ConductCall || status.state === OrderItemStates.ConductInterview
    )
  )
)

export const ordersItemTypesSelector = (state: OrderItemState, orderIds: number[]): UploadDocTypeMenu[] => {
  try {
    const items = orderIds.map(id => Object.values(state.itemsByOrderId[id])).flat()
    const orderItems = Object.values(items).filter(oi => oi.requires_document)
    return getLabeledOrderItemTypes(orderItems)
  } catch (e) {
    return []
  }
}

export const orderItemTypesSelectorWithRevision = (
  orderItems: UploadDocTypeMenu[],
  documents: Document[]
): UploadDocTypeMenu[] => {
  try {
    orderItems.forEach(oi => {
      const revisionsForOrder = documents
        .filter(d => d.order_item_id === oi.order_item_id)
        .map(d => d.revision)
        .filter((r): r is number => r !== null)

      if (revisionsForOrder.length === 0) {
        return
      }

      oi.revision = Math.max(...revisionsForOrder) + 1
    })
    return orderItems
  } catch (e) {
    return orderItems
  }
}

const getLabeledOrderItemTypes = (items: OrderItem[]): UploadDocTypeMenu[] => {
  const sortedItems = items
    .map((i: OrderItem) => {
      const parts = i.compound_item_key.split('|')
      return {
        order_id: i.order_id,
        order_item_id: i.id,
        doc_type: parts.length > 0 ? (parts[0] as OrderItemTypes) : i.core_product_key,
        doc_type_tag_keys: parts.length > 1 ? parts[1] : '',
        label: i.name,
        duplicateIndex: null,
        revision: 0,
      }
    })
    .sort((aItem, bItem) => {
      if (aItem.label < bItem.label) {
        return -1
      }

      if (aItem.label > bItem.label) {
        return 1
      }

      return aItem.order_item_id > bItem.order_item_id ? 1 : 0
    })

  // Because some items might have the same label, we need to mark them as duplicate with an index so that they can be
  // displayed properly in the drop down.
  const seenLabels = new Set()
  sortedItems
    .filter(oi => seenLabels.size === seenLabels.add(oi.label).size)
    .map(oi => oi.label)
    .forEach(label => {
      // @ts-ignore
      sortedItems.filter(oi => oi.label === label).forEach((oi, i) => (oi.duplicateIndex = i + 1))
    })

  return sortedItems
}

export const schedulerItemTypesSelector = (state: OrderItemState, orderIds: number[]): UploadDocTypeMenu[] => {
  try {
    const items = orderIds.map(id => Object.values(state.itemsByOrderId[id])).flat()
    const orderItems = Object.values(items).filter(
      oi =>
        (oi.core_product_key === OrderItemTypes.TopInterview ||
          oi.core_product_key === OrderItemTypes.PhoneConsultation) &&
        !!oi.status.active &&
        !!oi.status.active.find(
          s => s.state === OrderItemStates.SchedulePhoneCall || s.state === OrderItemStates.ScheduleInterview
        )
    )
    return getLabeledOrderItemTypes(orderItems)
  } catch (e) {
    return []
  }
}

export interface DirectSchedulingMenu {
  order_id: number
  order_item_id: number
  doc_type: OrderItemTypes
  doc_type_tag_keys: string
  label: string
  duplicateIndex: number | null
  scheduledAt?: string
  proposedAt?: string
  service_name: string
}

export const directSchedulingOptions = (items: OrderItem[]): DirectSchedulingMenu[] => {
  const sortedItems = items
    .map(i => {
      const parts = i.compound_item_key.split('|')
      const scheduledAt = i.status.active.find(
        s => s.state === OrderItemStates.ConductCall || s.state === OrderItemStates.ConductInterview
      )?.due_at

      const proposedAt = i.status.active.find(s => s.state === OrderItemStates.AwaitingConfirmation)?.due_at

      return {
        order_id: i.order_id,
        order_item_id: i.id,
        doc_type: parts.length > 0 ? (parts[0] as OrderItemTypes) : i.core_product_key,
        doc_type_tag_keys: parts.length > 1 ? parts[1] : '',
        service_name: i.name,
        label: i.name,
        duplicateIndex: null,
        scheduledAt: scheduledAt,
        proposedAt: proposedAt,
      }
    })
    .sort((aItem, bItem) => {
      return aItem.order_item_id > bItem.order_item_id ? 1 : 0
    })

  // Because some items might have the same label, we need to mark them as duplicate with an index so that they can be
  // displayed properly in the drop down.
  const seenLabels = new Set()
  sortedItems
    .filter(oi => seenLabels.size === seenLabels.add(oi.label).size)
    .map(oi => oi.label)
    .forEach(label => {
      // @ts-ignore
      sortedItems.filter(oi => oi.label === label).forEach((oi, i) => (oi.duplicateIndex = i + 1))
    })

  return sortedItems
}

export const itemsForClientID = (
  itemState: OrderItemState,
  clientsState: ClientsState,
  clientID: number
): OrderItem[] => {
  return clientsState.clients[clientID].order_ids.flatMap(orderID => Object.values(itemState.itemsByOrderId[orderID]))
}

export const firstAwaitingSchedulingPhoneCall = (
  orderItemState: OrderItemState,
  clientsState: ClientsState,
  clientID: number
): OrderItem | null => {
  const phoneCallItems = itemsForClientID(orderItemState, clientsState, clientID)
    .filter(
      item =>
        item.core_product_key === OrderItemTypes.PhoneConsultation &&
        !!item.status.active?.find(item => item.state === OrderItemStates.AwaitingScheduling)
    )
    .sort((item1, item2) => (item1.id < item2.id ? -1 : 1))

  return !!phoneCallItems ? phoneCallItems[0] : null
}

export const nextScheduledItem = (
  orderItemState: OrderItemState,
  clientsState: ClientsState,
  clientID: number
): OrderItem | null =>
  itemsForClientID(orderItemState, clientsState, clientID)
    .filter(
      item =>
        !!item.status.active?.find(
          ({ state, due_at: dueAt }) =>
            (state === OrderItemStates.ConductInterview || state === OrderItemStates.ConductCall) && !!dueAt
        )
    )
    .sort((itemA, itemB) => {
      const aDate = new Date(
        itemA.status.active?.find(
          ({ state }) => state === OrderItemStates.ConductInterview || state === OrderItemStates.ConductCall
        )?.due_at || ''
      )
      const bDate = new Date(
        itemB.status.active?.find(
          ({ state }) => state === OrderItemStates.ConductInterview || state === OrderItemStates.ConductCall
        )?.due_at || ''
      )
      return compareDateAsc(aDate, bDate)
    })?.[0] || null

export const filterResumeEditClosableItems = (orderItems: HashMap<OrderItem> | OrderItem[]) => {
  const fulfillableItems = filterFulfillableItems(orderItems)

  const resumeEditItem = fulfillableItems.find(item => item.core_product_id === 14)
  const isResumeEditItemClosable =
    resumeEditItem?.status?.completed?.some(status => status.state === OrderItemStates.SendIntro) &&
    resumeEditItem?.status?.active?.some(status => status.state === OrderItemStates.SendFirstDraft)

  return !!isResumeEditItemClosable
}
