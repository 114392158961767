import React from 'react'
import { OrderCTA } from '../../store/items/types'
import { UploadGoal } from '../../store/documents/types'
import UploadsContainer from '../UploadsV3/UploadsContainer'

export interface MessageUploadsContainerProps {
  orderIDs: number[]
  contextID: number
  context: 'client' | 'user'
  closeWindow: () => void
  isOnboardingMentee: boolean
  canSkipAQAFeedback: boolean
  cta: OrderCTA
}

export const MessageUploadsContainer: React.FC<MessageUploadsContainerProps> = ({
  context,
  contextID,
  orderIDs,
  closeWindow,
  isOnboardingMentee,
  canSkipAQAFeedback,
  cta,
}) => {
  return (
    <UploadsContainer
      goal={UploadGoal.Attach}
      context={context}
      contextID={contextID}
      orderIDs={orderIDs}
      closeWindow={closeWindow}
      isOnboardingMentee={isOnboardingMentee}
      cta={cta}
      canSkipAQAFeedback={canSkipAQAFeedback}
    />
  )
}

export default MessageUploadsContainer
