import auth0Lib, { Auth0DecodedHash } from 'auth0-js'
import Cookies from 'js-cookie'

import { CookieName, clearAllAuthCookies, RedirectQueryStringKey } from './auth'
import { Routes } from './consts'
import { Groups } from '../store/user/reducers'
import { ResettableInterval } from '../store/cron/types'

if (!process.env.REACT_APP_AUTH0_DOMAIN) {
  throw Error('The environment variable REACT_APP_AUTH0_DOMAIN must be set for Auth0 to work')
}

if (!process.env.REACT_APP_AUTH0_CLIENT_ID) {
  throw Error('The environment variable REACT_APP_AUTH0_CLIENT_ID must be set for Auth0 to work')
}

export interface FullfillmentJWT {
  'https://experts.talentinc.com/groups': Groups[]
  'https://experts.talentinc.com/oms_user_id': number
  given_name: string
  family_name: string
  nickname: string
  picture: string | null
  updated_at: string
  email: string
  email_verified: boolean
  iss: 'https://login.experts.talentinc.com/'
  sub: string
  aud: string
  exp: number
  nonce: string
}

export const auth0 = new auth0Lib.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: window.location.origin + Routes.Auth0Callback,
  scope: 'openid profile name email identities',
  responseType: 'id_token',
})

export function setAuth0Cookie(authResult: Auth0DecodedHash): boolean {
  if (!authResult || !authResult.idToken) {
    return false
  }

  // Clear out all the previous auth cookies
  clearAllAuthCookies()

  // Put the id_token in a cookie so that the app can read it
  Cookies.set(CookieName, authResult.idToken, {
    path: '/',
    domain: 'talentinc.com',
    expires: new Date(authResult.idTokenPayload.exp * 1000),
    secure: true,
  })

  return true
}

export function refreshToken() {
  return new Promise((resolve, reject) => {
    auth0.checkSession({}, (err, authResult) => {
      if (err || !authResult || !authResult.idTokenPayload || !authResult.idToken) {
        clearAllAuthCookies()
        window.location.href = '/auth/logout?' + RedirectQueryStringKey + '=' + encodeURI(window.location.href)
        reject(err)
        return
      }

      setAuth0Cookie(authResult)
      resolve(authResult)
    })
  })
}

export async function refreshAuth0TokenTimed(interval: number): Promise<ResettableInterval | null> {
  const intervalID = window.setInterval(refreshToken, interval)
  return {
    intervalID,
    interval,
  }
}

export default auth0
