import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ButtonBase from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'

import { TemplateList, Template } from '../../store/templates/types'
import i18n from '../../i18n'
import TemplatesIcon from '../../../src/assets/svgs/templates.svg'

interface TemplatesProps {
  templates: TemplateList
  handleInsert: (template: Template) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      cursor: 'pointer',
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column',
      },
      '& .icon-img': {
        display: 'flex',
        alignItems: 'center',
        height: '1.5rem',
        color: '#bcc9d6',
        '@media (max-width: 540px)': { display: 'none' },
      },
      '& .icon-text': {
        marginTop: '.1rem',
        fontSize: '.5625rem',
        color: '#3f3f3f',
      },
    },
    root: {
      background: 'red',
    },
    option: {
      padding: '0 3rem 0 1.5rem',
      borderBottom: '1px solid #ebedf0',
      cursor: 'pointer',
      lineHeight: '2.5',
      fontSize: '0.8125rem',
      minHeight: 'initial',
      '&:hover': {
        background: 'rgba(216, 216, 216, 0.25);',
      },
    },
    container: {
      '& .MuiList-root': {
        border: '1px solid #ebedf0',
        borderRadius: '4px',
        padding: '0',
      },
    },
  })
)

const Templates: React.FC<TemplatesProps> = ({ templates, handleInsert }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (template?: Template) => {
    if (template) {
      handleInsert(template)
    }

    setAnchorEl(null)
  }

  return (
    <>
      <Tooltip title={i18n.t('messaging__templates__icon__text') || 'Templates'}>
        <ButtonBase
          className={classes.icon}
          aria-controls="template-selection-menu"
          aria-haspopup="true"
          onClick={handleClick}
          id="message-toolbox-templates-selector"
        >
          <Box className="icon-img">
            <img src={TemplatesIcon} alt="" />
          </Box>
          <Typography display="block" variant={'body2'} className="icon-text">
            {i18n.t('messaging__tool__templates')}
          </Typography>
        </ButtonBase>
      </Tooltip>
      <Menu
        className={classes.container}
        id="template-selection-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
      >
        {Object.values(templates).map((t: Template) => (
          <MenuItem
            className={classNames([classes.option, 'template-selection-option'])}
            key={`template-${t.id}`}
            value={t.id}
            onClick={() => handleClose(t)}
          >
            {t.type}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

function propsAreEqual(prev: TemplatesProps, next: TemplatesProps) {
  return Object.keys(prev.templates).length === Object.keys(next.templates).length
}

const memoizedTemplates = React.memo(Templates, propsAreEqual)
// @ts-ignore
memoizedTemplates.whyDidYouRender = true

export default memoizedTemplates
