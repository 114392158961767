export enum DragAndDropTypes {
  Document = 'Document',
}

export enum Routes {
  Dashboard = '/',
  Root = '/',
  Account = '/account',
  Onboarding = '/onboarding',

  Client = '/client/:clientID',
  ClientMessages = '/client/:clientID/messages',
  ClientDocuments = '/client/:clientID/documents',

  Orders = '/orders/:orderFilter',
  OpenOrders = '/orders',
  PaidOrders = '/orders/paid',

  Shopping = '/shopping/:tab',
  NewOrder = '/shopping/new-order',
  AssignmentPreferences = '/shopping/preferences',

  OnboardingStep = '/onboarding/:step',

  Login = '/auth/login',
  Logout = '/auth/logout',
  Auth0Callback = '/auth/callback',
  AuthIframe = '/auth/iframe',

  AutoQAResults = '/auto-qa/:fileID',
  AutoLinkedin = '/auto-linkedin/:itemID',
}

export type Language = 'en' | 'de'

export const getClientUrl = (clientID: number, route: '/messages' | '/documents' | '') => `/client/${clientID}${route}`
export const getClientDefaultUrl = (clientID: number) => getClientUrl(clientID, '')
export const getClientMessagesUrl = (clientID: number) => `/client/${clientID}/messages`
export const getClientDocumentsUrl = (clientID: number) => `/client/${clientID}/documents`
export const getAutoQAResultsUrl = (fileID: number) => `/auto-qa/${fileID}`
