import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box, Tabs, Tab } from '@material-ui/core'

import Summary from './Summary'
import TabPanel from '../../common/TabPanel'
import i18n from '../../../i18n'
import ClientDetails from './ClientDetails'
import DigitalAnalysisContainer from './DigitalAnalysisContainer'
import { Order } from '../../../store/orders/types'
import { Client } from '../../../store/clients/reducer'
import { useSelector } from 'react-redux'
import { AppState } from '../../../store'
import { clientByIdSelector, getClientOrders, getClientQuestionnaire } from '../../../selectors/clients'
import { Questionnaire } from '../../../store/orders/questionnaire/types'
import WorkHistory from './WorkHistory'
import OrderSumaryTab from './OrderSummayTab'
import styles from '../../../styles'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      background: '#fff',
      border: '1px solid #D5D9E0',
      marginBottom: '1.5rem',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: '2.4375rem 3.75rem',
      '@media (max-width: 1050px)': {
        padding: '2rem 2rem',
      },
    },
    tabs: {
      display: 'flex',
      background: '#fff',
      borderBottom: '1px solid #D5D9E0',
      padding: '0.15rem .5rem 0',
    },
    tabLabel: {
      fontSize: '.9rem',
      textTransform: 'uppercase',
    },
    leftColumn: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      transform: 'translateZ(0)',
    },
    tabLabelBeta: {
      fontSize: '12px',
      padding: '2px 4px 2px 4px',
      marginLeft: '10px',
      border: '1px solid',
      borderColor: styles.palette.brandRed,
      color: styles.palette.brandRed,
      borderRadius: '3px',
    },
  })
)

export interface OrderDetailsContainerProps {
  clientID: number
}

const OrderDetailsContainer: React.FC<OrderDetailsContainerProps> = ({ clientID }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  let documentDigest: string | null = null

  const orders = useSelector<AppState, Order[]>(store =>
    getClientOrders({ ...store.clientReducer, ...store.orderReducer }, clientID)
  ).filter(o => !!o)

  const client = useSelector<AppState, Client>(state => clientByIdSelector(state.clientReducer, clientID))
  const quest = useSelector<AppState, Questionnaire | undefined>(store =>
    getClientQuestionnaire(store.clientQuestionnaireReducer, clientID)
  )

  if (orders.length === 0) {
    return null
  }

  const sortedOrders = orders.sort((a: Order, b: Order) => (a.due_date > b.due_date ? 1 : -1))
  const mostRecentOrder = sortedOrders[0]

  if (!!quest) {
    documentDigest = !!quest.document_digest ? quest.document_digest : null
  }

  const a11yProps = (index: number) => {
    return {
      id: `page-title-tab-${index}`,
      'aria-controls': `page-title-tabpanel-${index}`,
    }
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  if (orders.length === 0) {
    return null
  }

  return (
    <div className={classes.leftColumn}>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Summary order={mostRecentOrder} client={client} questionnaire_data={quest} />
        </Box>
      </Box>

      <Box className={classes.container}>
        <Box className={classes.tabs}>
          <Tabs indicatorColor={'primary'} value={value} onChange={handleChange} aria-label="Order type tabs">
            <Tab
              className={classes.tabLabel}
              label={`${i18n.t('order__page__tab__title__client__details')}`}
              value={0}
              {...a11yProps(0)}
            />
            <Tab
              className={classes.tabLabel}
              label={`${i18n.t('order__page__tab__title__work_history')}`}
              value={1}
              {...a11yProps(1)}
            />
            <Tab
              className={classes.tabLabel}
              label={`${i18n.t('order__page__tab__title__summary')}`}
              value={2}
              {...a11yProps(2)}
            />
            {!!documentDigest && (
              <Tab
                className={classes.tabLabel}
                label={`${i18n.t('order__page__tab__title__digital_analysis')}`}
                value={3}
                {...a11yProps(3)}
              />
            )}
          </Tabs>
        </Box>

        <Box className={classes.content}>
          <TabPanel value={value} index={0} lazy={true}>
            <ClientDetails order={mostRecentOrder} questionnaire={quest} />
          </TabPanel>
          <TabPanel value={value} index={1} lazy={true}>
            <WorkHistory clientID={clientID} />
          </TabPanel>
          <TabPanel value={value} index={2} lazy={true}>
            <OrderSumaryTab clientID={clientID} />
          </TabPanel>
          {!!documentDigest && (
            <TabPanel value={value} index={3} lazy={true}>
              <DigitalAnalysisContainer documentDigest={documentDigest} />
            </TabPanel>
          )}
        </Box>
      </Box>
    </div>
  )
}

export default OrderDetailsContainer
