import { createSelector } from 'reselect'
import { addHours, differenceInHours } from 'date-fns'

import { filterHashMap, filterPropEquals } from '../utils/selectors'
import {
  activeOrdersSelector,
  DirectSchedulingMenu,
  directSchedulingOptions,
  getCurrentItemDisplayState,
  orderItemsSelector,
  ordersSelector,
} from './items'
import { Order } from '../store/orders/types'
import { OrdersState } from '../store/orders/reducer'
import {
  HashMap,
  OrderItem,
  OrderItemDisplayStateMap,
  OrderItemState,
  OrderItemStates,
  OrderItemStatus,
  OrderItemTimeDisplayStates,
  OrderItemTypes,
} from '../store/items/types'
import { itemNeedsFetching } from '../utils/state'
import { some } from 'lodash'
import { FulfillableOrderItemDisplay, GenericOrderItemDisplay } from '../components/Orders/OrderItems'
import { ClientsState } from '../store/clients/reducer'

const filterOrdersPaid = filterPropEquals('status', 'paid')

export const paidOrdersSelector = createSelector(ordersSelector, orders => filterHashMap(orders, filterOrdersPaid))

export const unreadMessagesOrdersSelector = createSelector(activeOrdersSelector, orders =>
  filterHashMap<Order>(orders, order => order.unread_mail_count > 0)
)

export const unreadMessagesOrderCountSelector = createSelector(
  unreadMessagesOrdersSelector,
  orders => Object.keys(orders).length
)

export const getOrderDueDateById = (state: OrdersState, orderId: number) => {
  try {
    return state.orders[orderId].due_date
  } catch (e) {
    return null
  }
}

export const getOrdersByOrderIds = (orders: HashMap<Order>, ids: number[]) => {
  return filterHashMap<Order>(orders, order => ids.includes(order.order_id))
}

const getTimeAwareOrderDisplayState = (status: OrderItemStatus, allItems: OrderItem[]) => {
  if ([OrderItemStates.AwaitingReply, OrderItemStates.Revisions, OrderItemStates.SendMessage].includes(status.state)) {
    return OrderItemTimeDisplayStates.RevisionRequested
  }

  if (!status.due_at || status.state === OrderItemStates.SendLinkedin) {
    if (status.state === OrderItemStates.AwaitingResume || status.state === OrderItemStates.SendLinkedin) {
      const orderIsLate = some(
        allItems
          .map(x => x.status.active)
          .filter(s => !!s)
          .flat(),
        state =>
          (state.state === OrderItemStates.SendFirstDraft || state.state === OrderItemStates.SendIntro) &&
          !!state.due_at &&
          new Date(state.due_at) < new Date()
      )
      return orderIsLate ? OrderItemTimeDisplayStates.Late : OrderItemDisplayStateMap[status.state]
    }
    return OrderItemDisplayStateMap[status.state]
  }

  const dueDateParsed = new Date(status.due_at)

  if (dueDateParsed < new Date()) {
    return OrderItemTimeDisplayStates.Late
  }

  if (addHours(dueDateParsed, -24) <= new Date()) {
    return OrderItemTimeDisplayStates.DueUrgent24Hours
  }

  return OrderItemDisplayStateMap[status.state]
}

const sortByCoreProductID = (left: OrderItem, right: OrderItem) => {
  // specific core product ranking
  const coreProductOrder = [1, 6, 3, 4, 9, 5, 8]
  if (coreProductOrder.indexOf(left.core_product_id) === -1) {
    return 1
  }
  if (coreProductOrder.indexOf(right.core_product_id) === -1) {
    return -1
  }
  return coreProductOrder.indexOf(left.core_product_id) > coreProductOrder.indexOf(right.core_product_id) ? 1 : -1
}

export function getOrderItemsForDisplay(
  items: OrderItem[],
  orderDueDate: string | null | Date
): (FulfillableOrderItemDisplay | GenericOrderItemDisplay)[] {
  return items
    .filter(
      i =>
        Object.values(OrderItemTypes).includes(i.core_product_key) &&
        i.writer_pay.map(pay => pay.amount).reduce((sum, amt) => sum + amt, 0) > 0
    )
    .sort(sortByCoreProductID)
    .map(value => {
      const currentState = getCurrentItemDisplayState(value)
      return !!currentState
        ? {
            state: getTimeAwareOrderDisplayState(currentState, items),
            itemState: currentState.state,
            type: value.core_product_key,
            due_date: !!currentState.due_at ? currentState.due_at : orderDueDate,
            history: value.status,
            pay: value.writer_pay,
            ...value,
          }
        : {
            type: value.core_product_key,
            pay: value.writer_pay,
            ...value,
          }
    })
}

export const itemsForDisplaySelector = createSelector(
  [orderItemsSelector, getOrderDueDateById],
  (items, orderDueDate) => {
    return items && getOrderItemsForDisplay(Object.values(items), orderDueDate)
  }
)

export const ordersNeedFetching = (state: OrdersState, ids: number[]): HashMap<boolean> => {
  const boolMap: HashMap<boolean> = {}

  ids.reduce((acc: HashMap<boolean>, id: number): HashMap<boolean> => {
    boolMap[id] = !state.orders[id] && itemNeedsFetching(id, state.meta)
    return boolMap
  }, boolMap)

  return boolMap
}

export const productsOrderIDSelector = (state: OrdersState, orderID: null | number) => {
  return state.orders[!!orderID ? orderID : 0]?.products_order_id
}

export const itemsForDirectScheduling = (
  clientState: ClientsState,
  itemState: OrderItemState,
  ordersState: OrdersState,
  clientID: number,
  isAdmin: boolean
): DirectSchedulingMenu[] => {
  try {
    const orderIDs = clientState.clients[clientID].order_ids
    const orders = getOrdersByOrderIds(ordersState.orders, orderIDs)
    if (Object.keys(orders).length === 0) {
      return []
    }
    const items = orderIDs.map(id => Object.values(itemState.itemsByOrderId[id])).flat()
    const orderItems = Object.values(items).filter(oi => {
      // @ts-ignore
      const order = orders[oi.order_id]
      return (
        (oi.core_product_key === OrderItemTypes.TopInterview ||
          oi.core_product_key === OrderItemTypes.PhoneConsultation) &&
        (differenceInHours(new Date(), new Date(order.last_assigned_date)) >= 12 || isAdmin) &&
        !!oi.status.active &&
        !!oi.status.active.find(s => s.state === OrderItemStates.AwaitingScheduling)
      )
    })
    return directSchedulingOptions(orderItems)
  } catch (e) {
    return []
  }
}
