import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import styles from '../../styles'
import UploadsV3Styles from './styles'
import InfoTooltip from '../common/InfoTooltip'

export interface AutoQAResultGroupProps {
  title: string
  tooltipText?: string
  children?: React.ReactNode
  theme?: 'regular' | 'inverted'
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: '1.875rem',
      display: 'flex',
      flexDirection: 'column',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
    },
    title: {
      fontWeight: styles.fonts.primary.weights.bold,
      fontSize: '1.25rem',
      lineHeight: '1.25rem',
      marginRight: '.7rem',
    },
    item: {
      width: '100%',
      '& > :not(:last-child)': {
        marginBottom: '1.875rem',
      },
    },
    inverted: {
      backgroundColor: UploadsV3Styles.darkBlue.hex,
      '& > :first-child': {
        color: 'white',
      },
    },
  })
)

const AutoQAResultGroup: React.FC<AutoQAResultGroupProps> = ({ children, title, theme, tooltipText }) => {
  const classes = useStyles()

  return (
    <Box
      className={classNames({
        [classes.root]: true,
        [classes.inverted]: theme === 'inverted',
      })}
    >
      <Box className={classes.titleContainer}>
        <Typography display={'inline'} className={classes.title}>
          {title}
        </Typography>
        {tooltipText && <InfoTooltip text={tooltipText} />}
      </Box>
      <Box className={classes.item}>{children}</Box>
    </Box>
  )
}

export default AutoQAResultGroup
