import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { completedOnboardingSelector, isNewZipJobWriter, isOnboardingMenteeSelector } from '../../selectors/users'
import { Redirect } from 'react-router'
import React from 'react'
import { UserOnboarding } from '../../store/onboarding/reducer'
import { LoadedLoadingErrorState } from '../../utils/state'
import { UserState } from '../../store/user/reducers'
import { AxiosError } from 'axios'

const OnboardingRouter = () => {
  const userInfo = useSelector<AppState, UserState>(state => state.userReducer)

  const onboardingMeta = useSelector<AppState, LoadedLoadingErrorState>(state => state.onboardingReducer.meta)
  const onboardingInfo = useSelector<AppState, UserOnboarding | undefined>(state => state.onboardingReducer.info)

  const isNewWriter = isOnboardingMenteeSelector(userInfo) || isNewZipJobWriter(userInfo)

  if (userInfo && (!isNewWriter || completedOnboardingSelector(userInfo))) {
    // no need to redirect, the user is all set to use the app
    return null
  }
  // const loggedInUser = userInfo.getLoggedInUser()
  if (onboardingMeta.loaded && !onboardingMeta.isLoading) {
    // the onboarding record for this user doesn't exist - we have to create one
    // the onboarding data is an empty object - this means the user has just started the onboarding process
    if (!onboardingInfo || (!!onboardingInfo && Object.keys(onboardingInfo.data).length === 0)) {
      return <Redirect to={'/onboarding/welcome'} />
    }
    // if (
    //   !!onboardingInfo.data.industries &&
    //   !onboardingInfo.finished_at &&
    //   userInfo &&
    //   loggedInUser &&
    //   loggedInUser.payment_type !== PaymentTypes.Internal
    // ) {
    //   return <Redirect to={'/onboarding/billing'} />
    // }
    // if (!!onboardingInfo.data.industries && loggedInUser && loggedInUser.payment_type === PaymentTypes.Internal) {
    //   return <Redirect to={'/onboarding/complete'} />
    // }
    // if (!!onboardingInfo.data.calendar) {
    //   return <Redirect to={'/onboarding/industries'} />
    // }
    // if (!!onboardingInfo.data.hoursPerWeek) {
    //   return <Redirect to={'/onboarding/weekly-availability'} />
    // }
  }
  if (onboardingMeta.error) {
    // @ts-ignore
    const status = ((onboardingMeta.error as unknown) as AxiosError).response.status
    if (status === 404) {
      return <Redirect to={'/onboarding/welcome'} />
    }
  }
  return null
}

export default OnboardingRouter
