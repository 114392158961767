import React, { useState, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'

import i18n from '../../../i18n'
import JobCard from './JobCard'
import { JobPosting } from '../../../store/orders/questionnaire/types'

const useStyles = makeStyles(() =>
  createStyles({
    jobs: {
      width: '100%',
    },
    title: {
      margin: '1.875rem 0 0',
      fontWeight: 600,
    },
    cards: {
      '& > *': {
        display: 'inline-flex',
        width: '15rem',
        marginTop: '.875rem',
        verticalAlign: 'top',
        '&:not(:last-child)': { marginRight: '1.75rem' },
      },
    },
    expanded: {
      '& > *': {
        display: 'flex',
        width: '100%',
      },
    },
  })
)

interface JobCardsProps {
  jobs: JobPosting[]
}

export const JobCards: React.FC<JobCardsProps> = props => {
  const classes = useStyles(props)
  const [expanded, setExpanded] = useState(false)
  const { jobs } = props
  const handleExpandClick = useCallback(() => setExpanded(current => !current), [setExpanded])

  return (
    <Box className={classes.jobs}>
      <Typography variant={'body2'} className={classes.title}>
        {i18n.t('order__page__client__details__target__jobs')}
      </Typography>
      <Box
        className={classNames({
          [classes.cards]: true,
          [classes.expanded]: expanded,
        })}
      >
        {jobs.map((jobPosting, i) => (
          <JobCard
            id={i}
            link={jobPosting.url}
            key={`job-card-${i}`}
            handleExpandClick={handleExpandClick}
            expanded={expanded}
          />
        ))}
      </Box>
    </Box>
  )
}

const memoizedJobCards = React.memo(JobCards, isEqual)
// @ts-ignore
memoizedJobCards.whyDidYouRender = true
export default memoizedJobCards
