import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import styles from '../../styles'

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: '100%',
      maxWidth: styles.sizes.modalWrapperMaxWidth,
      alignSelf: 'center',
      marginTop: '2rem',
      // Account for scrolling and the footer
      marginBottom: '6rem',
    },
  })
)

const ModalWrapper: React.FC = ({ children }) => {
  const classes = useStyles()

  return <Box className={classes.wrapper}>{children}</Box>
}

export default ModalWrapper
