import React, { useState, useCallback, useLayoutEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface SidebarContextState {
  open: boolean
  mobile: boolean
}

const defaultState: SidebarContextState = {
  open: false,
  mobile: false,
}

export const SidebarContext = React.createContext<SidebarContextState>(defaultState)

export default function useSidebar(): [SidebarContextState, () => void] {
  const [state, setState] = useState<SidebarContextState>(defaultState)
  const toggleExpansion = useCallback(
    (isOpen?: boolean) =>
      setState(s => {
        return {
          ...s,
          open: isOpen === undefined ? !s.open : isOpen,
        }
      }),
    [setState]
  )

  // Determine if this is a mobile device, as the sidebar will function slightly differently for them
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  useLayoutEffect(() => {
    if (mobile !== state.mobile) {
      setState(s => {
        return { ...s, mobile }
      })
    }
  }, [mobile, state.mobile, setState])

  return [state, toggleExpansion]
}
