import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import isEqual from 'lodash/isEqual'

import i18n from '../../i18n'
import styles from '../../styles'
import ProgressCard, { ProgressCardVariants } from './ProgressCard'
import { Progress } from '../../selectors/dashboard'
import { DashboardVariants } from './index'
import { BasePay } from '../../store/orders/types'
import DashboardStyles from './Styles'
import { NewHireBonusProgress } from '../../store/dashboard/types'

interface Props {
  variant: DashboardVariants
  earningsProgress: Progress<BasePay> | null
  orderProgress: Progress<number> | null
  firstDraftAvg: number | null
  responseTimeAvg: number | null
  newHireBonusProgress: NewHireBonusProgress | null
  updateEarningsGoal: (amount: number) => Promise<void>
  isLowCostIc: boolean
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: DashboardStyles.fullWidth,
    },
    header: {
      color: DashboardStyles.header.color,
      fontFamily: styles.fonts.primary.family,
      fontSize: DashboardStyles.header.size,
      margin: DashboardStyles.header.margin,
      width: DashboardStyles.header.width,
    },
    [theme.breakpoints.down('sm')]: {
      container: {
        flexDirection: 'column',
      },
    },
  })
)

const blankProgressCard = (title: string) => {
  return <ProgressCard variant={ProgressCardVariants.blank} title={title} />
}

const OverallPerformance: React.FC<Props> = ({
  variant,
  earningsProgress,
  orderProgress,
  firstDraftAvg,
  responseTimeAvg,
  updateEarningsGoal,
  newHireBonusProgress,
  isLowCostIc,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Typography className={classes.header} component="h3">
        {i18n.t('dashboard__overallPerformance__title')}
      </Typography>
      {variant !== DashboardVariants.Offshore &&
        variant !== DashboardVariants.NewHire &&
        (!earningsProgress ? (
          blankProgressCard(i18n.t('dashboard__earningsProgress_title'))
        ) : (
          <ProgressCard
            dashVariant={variant}
            title={i18n.t('dashboard__earningsProgress_title')}
            hasProgressBar={true}
            variant={ProgressCardVariants.earnings}
            current={earningsProgress.currentValue ? earningsProgress.currentValue : undefined}
            goal={earningsProgress.goal ? { amount: earningsProgress.goal, currency: 'USD' } : undefined}
            updateEarningsGoal={updateEarningsGoal}
          />
        ))}
      {variant === DashboardVariants.NewHire && !!newHireBonusProgress && newHireBonusProgress.bonus_eligible && (
        <ProgressCard
          dashVariant={variant}
          title={i18n.t(
            isLowCostIc
              ? 'dashboard__new_hire_bonus_progress__title__low_cost'
              : 'dashboard__new_hire_bonus_progress__title'
          )}
          hasProgressBar={true}
          variant={ProgressCardVariants.drafts}
          current={newHireBonusProgress.total_drafts_done}
          goal={newHireBonusProgress.drafts_goal}
          hasTooltip={true}
        />
      )}
      {(variant !== DashboardVariants.NewHire ||
        (variant === DashboardVariants.NewHire &&
          !!newHireBonusProgress &&
          !newHireBonusProgress.bonus_eligible &&
          !newHireBonusProgress.bonus_reconciled)) && (
        <ProgressCard
          dashVariant={variant}
          title={i18n.t('dashboard__orderProgress__title')}
          hasProgressBar={true}
          hasTooltip={false}
          current={orderProgress && orderProgress.currentValue ? orderProgress.currentValue : undefined}
          goal={orderProgress && orderProgress.goal ? orderProgress.goal : undefined}
          variant={ProgressCardVariants.orders}
        />
      )}
      <ProgressCard
        dashVariant={variant}
        title={i18n.t('dashboard__avgFirstDraft__title')}
        current={firstDraftAvg || undefined}
        variant={ProgressCardVariants.days}
      />
      <ProgressCard
        dashVariant={variant}
        title={i18n.t('dashboard__avgResponseTime__title')}
        current={responseTimeAvg || undefined}
        variant={ProgressCardVariants.days}
      />
    </Box>
  )
}

const memoizedOverallPerformance = React.memo(OverallPerformance, isEqual)
// @ts-ignore
memoizedOverallPerformance.whyDidYouRender = true

export default memoizedOverallPerformance
