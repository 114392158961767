import React from 'react'
import ButtonBase, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import classNames from 'classnames'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import omit from 'lodash/omit'

import styles from '../../styles'

// @ts-ignore
interface ButtonProps extends MuiButtonProps {
  type: 'primary' | 'secondary' | 'contrast' | 'inherit'
  outline?: boolean
  showLoader?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#fff',
      textTransform: 'initial',
      fontSize: theme.typography.body2.fontSize,
      fontWeight: 600,
      padding: '0.33rem 1.547rem',
      cursor: 'pointer',
      boxSizing: 'border-box',
      '&.medium': {
        padding: '0.6rem 0',
        minWidth: '14rem',
        fontSize: '1.125rem',
      },
      '&.disabled': {
        backgroundColor: '#ebedf0',
        border: 'none',
        color: '#414146',
        boxShadow: 'none',
        '&:hover': {
          cursor: 'initial',
          backgroundColor: '#ebedf0',
          color: '#414146',
        },
      },
      '&.outline': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      '&.outline&.disabled': {
        backgroundColor: '#ebedf0',
        border: 'none',
        color: '#414146',
      },
    },
    primary: {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 2px 24px 0 rgba(72, 128, 255, 0.2)',
      '&.outline': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
      },
      '&:hover': {
        backgroundColor: '#038cd9',
        color: '#fff',
      },
    },
    secondary: {
      backgroundColor: 'transparent',
      color: '#3a4862',
      border: `1px solid #3a4862`,
      '&.disabled': {
        border: '1px solid #fafafc',
        backgroundColor: '#fafafc',
        color: '#b7c2d8',
      },
      '&:hover, &:active, &:focus': {
        backgroundColor: '#f1f2f4',
      },
    },
    contrast: {
      backgroundColor: styles.palette.contrast.color,
      color: '#fff',
      '&:hover, &:active, &:focus': {
        backgroundColor: styles.palette.contrast.hover,
      },
      '&.disabled': {
        border: '1px solid #fafafc',
        backgroundColor: '#fafafc',
        color: '#b7c2d8',
      },
    },
    loader: {
      height: theme.typography.body2.fontSize,
      width: theme.typography.body2.fontSize,
      marginLeft: '0.4rem',
      '&.medium': {
        height: '1.125rem',
        width: '1.125rem',
      },
    },
  })
)

const Button: React.FC<ButtonProps> = props => {
  const classes = useStyles(props)
  const { className = '', showLoader = false } = props
  const buttonClasses = {
    [classes.root]: true,
    [classes.primary]: props.type === 'primary',
    [classes.secondary]: props.type === 'secondary',
    [classes.contrast]: props.type === 'contrast',
    outline: props.outline,
    disabled: props.disabled,
    medium: props.size === 'medium',
    [className]: !!className,
  }

  return (
    <ButtonBase
      {...omit(props, ['type', 'outline', 'showLoader'])}
      className={classNames(buttonClasses, props.className)}
    >
      {props.children}
      {showLoader && (
        <CircularProgress
          className={classNames({
            [classes.loader]: true,
            medium: props.size === 'medium',
          })}
          size={props.size === 'medium' ? '1.125rem' : '14px'}
          color={props.type === 'primary' ? 'secondary' : 'primary'}
        />
      )}
    </ButtonBase>
  )
}

export default Button
