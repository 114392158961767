import produce from 'immer'

import { initialState, NotesState, NoteContexts } from './types'
import { NotesActionType, NotesActionTypes } from './actions'
import { OrdersActionTypes } from '../orders/actions'
import * as stateUtils from '../../utils/state'
import { ClientOrdersAggregateActions } from '../clientOrderAggregate/actions'

export default function notesReducer(state: NotesState = initialState, action: NotesActionType): NotesState {
  return produce(state, (draft: NotesState) => {
    switch (action.type) {
      case NotesActionTypes.FETCH_NOTES: {
        const { context, contextID } = action
        draft.meta.fetch[context][contextID] = stateUtils.setStartState(state.meta.fetch[context][contextID])
        break
      }

      case NotesActionTypes.FETCH_NOTES_SUCCESS: {
        const notes = action.payload.data
        const { context, contextID } = action.meta.previousAction

        if (notes && notes.length > 0) {
          notes.forEach(note => {
            if (!state[note.context][note.context_id]) {
              draft[note.context][note.context_id] = {}
            }
            draft[note.context][note.context_id][note.id] = note
          })
        }

        draft.meta.fetch[context][contextID] = stateUtils.setSuccessState(state.meta.fetch[context][contextID])

        break
      }

      case NotesActionTypes.FETCH_NOTES_FAIL: {
        const { context, contextID } = action.meta.previousAction
        draft.meta.fetch[context][contextID] = stateUtils.setErrorState(
          state.meta.fetch[context][contextID],
          action.error
        )
        break
      }

      case NotesActionTypes.FETCH_USER_NOTES: {
        const { userID } = action
        draft.meta.fetch[NoteContexts.userClient][userID] = stateUtils.setStartState(
          state.meta.fetch[NoteContexts.userClient][userID]
        )
        break
      }

      case NotesActionTypes.FETCH_USER_NOTES_SUCCESS: {
        const notes = action.payload.data
        const { userID } = action.meta.previousAction

        if (notes && notes.length > 0) {
          notes.forEach(note => {
            if (!draft[note.context][note.context_id]) {
              draft[note.context][note.context_id] = {}
            }

            draft[note.context][note.context_id][note.id] = note
          })
        }

        draft.meta.fetch[NoteContexts.userClient][userID] = stateUtils.setSuccessState(
          state.meta.fetch[NoteContexts.userClient][userID]
        )
        break
      }

      case NotesActionTypes.FETCH_USER_NOTES_FAIL: {
        const { userID } = action.meta.previousAction
        draft.meta.fetch[NoteContexts.userClient][userID] = stateUtils.setErrorState(
          state.meta.fetch[NoteContexts.userClient][userID],
          action.error
        )
        break
      }

      case NotesActionTypes.CREATE_NOTE: {
        draft.meta.create.isLoading = true
        draft.meta.create.error = null
        break
      }

      case NotesActionTypes.CREATE_NOTE_SUCCESS: {
        const note = action.payload.data
        const { context, contextID } = action.meta.previousAction

        if (!state[context][contextID]) {
          draft[context][contextID] = {}
        }

        draft[context][contextID][note.id] = note
        draft.meta.create.isLoading = false
        draft.meta.create.error = null

        break
      }

      case NotesActionTypes.CREATE_NOTE_FAIL: {
        draft.meta.create.isLoading = false
        draft.meta.create.error = action.error
        break
      }

      case NotesActionTypes.DELETE_NOTE: {
        draft.meta.delete.isLoading = true
        draft.meta.delete.error = null
        break
      }

      case NotesActionTypes.DELETE_NOTE_SUCCESS: {
        const note = action.payload.data
        const { context, contextID } = action.meta.previousAction

        if (!state[context][contextID]) {
          draft[context][contextID] = {}
        }

        draft[context][contextID][note.id] = note
        draft.meta.delete.isLoading = false
        draft.meta.delete.error = null

        break
      }

      case NotesActionTypes.DELETE_NOTE_FAIL: {
        draft.meta.delete.isLoading = false
        draft.meta.delete.error = action.error
        break
      }

      case NotesActionTypes.UPDATE_NOTE: {
        draft.meta.update.isLoading = true
        draft.meta.update.error = null
        break
      }

      case NotesActionTypes.UPDATE_NOTE_SUCCESS: {
        const note = action.payload.data

        if (note) {
          draft[note.context][note.context_id][note.id] = note
        }

        draft.meta.update.isLoading = false
        draft.meta.update.error = null
        break
      }

      case NotesActionTypes.UPDATE_NOTE_FAIL: {
        draft.meta.update.isLoading = false
        draft.meta.update.error = action.error
        break
      }

      case OrdersActionTypes.FETCH_ORDERS_SUCCESS:
      case OrdersActionTypes.FETCH_ORDER_SUCCESS:
      case ClientOrdersAggregateActions.FETCH_CLIENT_ORDERS_SUCCESS: {
        if (!action.payload.data) {
          return draft
        }

        const orders = Array.isArray(action.payload.data) ? action.payload.data : [action.payload.data]

        if (orders && orders.length > 0) {
          orders.forEach(order => {
            const { notes = [] } = order

            if (notes.length > 0) {
              notes.forEach(note => {
                const { context, context_id, id } = note

                if (!draft[context][context_id]) {
                  draft[context][context_id] = {}
                }

                draft[context][context_id][id] = note
              })
            }
          })
        }
        break
      }
    }
  })
}
