import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  differenceInCalendarYears,
  differenceInHours,
} from 'date-fns'
import { OptionsWithTZ, format, utcToZonedTime } from 'date-fns-tz'

import { HashMap, OrderCTA, OrderItemDisplayStates, OrderItemStates } from '../store/items/types'
import i18n from '../i18n'
import { BasePay } from '../store/orders/types'
import enUS from 'date-fns/locale/en-US'
import { UserInfo } from '../store/user/reducers'
import enGB from 'date-fns/locale/en-GB'

export type Currency = 'USD' | 'GBP' | 'EUR'

export const currencyLocaleMapping = {
  USD: 'en-US',
  GBP: 'en-GB',
  EUR: 'de-DE',
}

export const formatCurrency = (amount: number, currency: Currency, minimumFractionDigits?: number): string => {
  const locale = !currencyLocaleMapping[currency] ? currencyLocaleMapping['USD'] : currencyLocaleMapping[currency]
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: !currencyLocaleMapping[currency] ? 'USD' : currency,
    minimumFractionDigits: minimumFractionDigits != null ? minimumFractionDigits : 2,
  })

  return formatter.format(amount)
}

export const formatDate = (utcDate: Date | string, timeZone: string, locale: Locale, formatString: string): string =>
  `${format(utcToZonedTime(utcDate, timeZone), formatString, { timeZone, locale })}${
    formatString.includes('h:mm') ? ` ${getUserTimezoneAbbrv(timeZone)}` : ''
  }`

export const formatWithTz = (date: number | Date, formatString: string, { timeZone, locale }: OptionsWithTZ): string =>
  `${format(date, formatString, { timeZone, locale })} ${getUserTimezoneAbbrv(timeZone ?? '')}`

export const formatUserPaymentItem = (item: BasePay): string => {
  return formatCurrency(item.amount, item.currency)
}

export enum DateOffset {
  Today = 'today',
  Tomorrow = 'tomorrow',
}

const DateTranslationMap: HashMap<string> = {
  [DateOffset.Today]: 'today',
  [DateOffset.Tomorrow]: 'tomorrow',
}

export const formatDateOffset = (
  baseDate: Date,
  targetDate: Date | string,
  timeZone: string,
  locale: Locale
): string => {
  if (!targetDate) return ''
  targetDate = utcToZonedTime(targetDate, timeZone)
  const diff = differenceInCalendarDays(targetDate, utcToZonedTime(baseDate, timeZone))

  let prefix
  let dateFormat

  // Switch on the number of days
  switch (diff) {
    case 0:
      prefix = i18n.t(DateTranslationMap[DateOffset.Today])
      dateFormat = 'hh:mm a'
      break
    case 1:
      prefix = i18n.t(DateTranslationMap[DateOffset.Tomorrow])
      dateFormat = 'hh:mm a'
      break
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      dateFormat = 'EEEE hh:mm a'
      break
    default:
      dateFormat = 'MM/dd hh:mm a'
  }
  const pref = prefix ? `${prefix} ` : ''
  return `${pref}${formatWithTz(targetDate, dateFormat, { locale, timeZone })}`
}

export const formatOrderDueDate = (
  date: Date,
  userTimezone: string,
  locale: Locale,
  macroState: OrderCTA
): [string, string] => {
  const baseDate = new Date()

  switch (macroState) {
    case OrderItemStates.SendIntro:
    case OrderItemStates.SendFirstDraft:
    case OrderItemStates.SchedulePhoneCall:
    case OrderItemStates.SendMessage:
    case OrderItemStates.SendSummary:
      const diff = differenceInCalendarDays(date, baseDate)
      if (diff === 1) {
        return [
          i18n.t(DateTranslationMap[DateOffset.Tomorrow]),
          `${formatWithTz(date, 'hh:mm a', { timeZone: userTimezone, locale })}`,
        ]
      }
      if (diff === 0) {
        return [
          i18n.t(DateTranslationMap[DateOffset.Today]),
          `${formatWithTz(date, 'hh:mm a', { timeZone: userTimezone, locale })}`,
        ]
      }
      return [
        format(date, 'MMMM do'),
        date < baseDate
          ? i18n.t('orders__due_date__details_late')
          : `${formatWithTz(date, 'hh:mm a', { timeZone: userTimezone, locale })}`,
      ]
    case OrderItemDisplayStates.CloseOrder:
      return [
        format(date, 'MMMM do', { timeZone: userTimezone, locale }),
        i18n.t('orders__due_date__details_revision_ended'),
      ]
    case OrderItemStates.Closed:
      return [
        format(date, 'MMMM do', { timeZone: userTimezone, locale }),
        i18n.t('orders__due_date__details_order_closed'),
      ]
    case OrderItemStates.Paid:
      let paidDateFormat = 'MMMM do'

      if (new Date(date).getFullYear() !== baseDate.getFullYear()) {
        paidDateFormat = 'MMMM do, yyyy'
      }

      return [
        format(date, paidDateFormat, { timeZone: userTimezone, locale }),
        i18n.t('orders__due_date__details_order_paid'),
      ]
    default:
      if (date < baseDate) {
        return [
          format(date, 'MMMM do', { timeZone: userTimezone, locale }),
          i18n.t('orders__due_date__details_revision_ended'),
        ]
      }
      return [
        format(date, 'MMMM do', { timeZone: userTimezone, locale }),
        i18n.t('orders__due_date__details_revision_ends'),
      ]
  }
}

export const formatOrderDueDateForDetails = (
  date: Date,
  userTimezone: string,
  locale: Locale,
  macroState: OrderCTA
): [string, boolean] => {
  const formattedDate = formatDate(date, userTimezone, locale, 'MM/dd hh:mm a')

  switch (macroState) {
    case OrderItemStates.Revisions:
    case OrderItemStates.AwaitingReply:
    case OrderItemStates.SendMessage:
    case OrderItemStates.SendLinkedin: {
      const isLate = date < new Date()
      if (isLate) {
        return [i18n.t(`orders__due_date__details_view__${macroState}_late`, { date: formattedDate }), false]
      }
      return [i18n.t(`orders__due_date__details_view__${macroState}`, { date: formattedDate }), false]
    }
    case OrderItemStates.Refunded:
    case OrderItemStates.Canceled:
    case OrderItemStates.Closed:
    case OrderItemStates.Paid:
    case OrderItemDisplayStates.CloseOrder:
      return [i18n.t(`orders__due_date__details_view__${macroState}`, { date: formattedDate }), false]
    default:
      const isLate = date < new Date()
      return [i18n.t(`orders__due_date__details_view__${macroState}`, { date: formattedDate }), isLate]
  }
}

export const formatEventDateOffset = (baseDate: Date, targetDate: Date | string, timeZone: string): string => {
  if (!targetDate) return ''
  const targetDateInTZ = utcToZonedTime(targetDate, timeZone)
  const baseDateInTZ = utcToZonedTime(baseDate, timeZone)
  let diff = differenceInHours(baseDateInTZ, targetDateInTZ)

  if (diff < 24) {
    return `${diff}h`
  }

  diff = differenceInCalendarDays(baseDateInTZ, targetDateInTZ)
  if (diff < 7) {
    return `${diff}d`
  }

  diff = differenceInCalendarWeeks(baseDateInTZ, targetDateInTZ)

  if (diff < 4) {
    return `${diff}w`
  }

  diff = differenceInCalendarMonths(baseDateInTZ, targetDateInTZ)
  if (diff < 12) {
    return `${diff}m`
  }

  diff = differenceInCalendarYears(baseDateInTZ, targetDateInTZ)
  return `${diff}y`
}

export const getDocumentsString = (missingDocs: string[], specific: boolean): string => {
  if (missingDocs.length === 1) {
    return `${specific ? 'the' : 'a'} ${missingDocs[0]}`
  }
  const count = missingDocs.length
  return missingDocs.reduce((result, doc, docIndex) => {
    if (docIndex === count - 1) {
      result += docIndex === 1 ? ` and ${doc}` : `, and ${doc}`
      return result
    }
    if (docIndex === 0) {
      result += doc
    } else {
      result += `, ${doc}`
    }
    return result
  }, '')
}

export const getLocale = (user: UserInfo | null): Locale =>
  !user ? enUS : user.timezone_string.includes('Europe') ? enGB : enUS

export const getUserTimezoneAbbrv = (timeZone: string) =>
  format(new Date(), 'zzzz', { timeZone })
    .split(' ')
    .map(word => word[0])
    .join('')
