import React from 'react'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'

import i18n from '../../i18n'
import AutoQAResultGroup from './AutoQAResultGroup'
import UploadStyles from './styles'
import AutoQAResult from './AutoQAResult'
import { FeedbackBoolPayload } from '../../store/featureFeedback/types'
import { AutoQASectionData } from '../../selectors/autoQA'
import isEqual from 'lodash/isEqual'
import { HashMap } from '../../utils/HashMap'
import classNames from 'classnames'

export interface AutoQASectionProps extends AutoQASectionData {
  updateFeedback?: (payload: FeedbackBoolPayload) => void
  feedback?: HashMap<HashMap<FeedbackBoolPayload>>
  theme?: 'regular' | 'inverted'
}
const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    section: {
      backgroundColor: UploadStyles.lightPurple.hex,
      borderRadius: UploadStyles.autoQAResults.group.borderRadius,
      boxShadow: UploadStyles.autoQAResults.group.boxShadow,
    },
  })
)

const SectionHeader = withStyles({
  root: {
    color: 'white',
    backgroundColor: UploadStyles.darkBlue.hex,
    fontSize: '1rem',
    borderRadius: '5px 5px 0 0',
    fontWeight: 'bold',
  },
})(Chip)

const AutoQASection: React.FC<AutoQASectionProps> = ({ theme, feedback, content, title, count, updateFeedback }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {title && count && <SectionHeader label={i18n.t('auto_qa__sectionHeader', { title, count })} />}
      <Box
        className={classNames({
          [classes.section]: true,
        })}
      >
        {content.map(group => (
          <AutoQAResultGroup
            theme={theme}
            key={`auto_qa_group_${group.title}`}
            title={group.title}
            tooltipText={group.tooltipText}
            children={group.content.map(result => {
              return (
                <AutoQAResult
                  feedback={feedback}
                  updateFeedback={updateFeedback}
                  key={`auto_qa_result_${result.feature_key}`}
                  title={result.title}
                  feature_id={group.feature_id}
                  feature_key={result.feature_key}
                  content={result.content}
                  withFeedback={true}
                />
              )
            })}
          />
        ))}
      </Box>
    </Box>
  )
}

const memoizedAutoQASection = React.memo(AutoQASection, isEqual)
// @ts-ignore
memoizedAutoQASection.whyDidYouRender = true

export default memoizedAutoQASection
