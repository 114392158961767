import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import Box from '@material-ui/core/Box'
import isEqual from 'lodash/isEqual'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'

import Greeting from './Greeting'
import OverallPerformance from './OverallPerformance'
import OutputHeroCard from './OutputHeroCard'
import { loadDashboard } from '../../store/dashboard/actions'
import MacroSummaryCard from './MacroSummaryCard'
import DashboardErrorCard from './DashboardErrorCard'
import { AppState } from '../../store'
import i18n from '../../i18n'
import { FeatureTags, Groups, UserInfo } from '../../store/user/reducers'
import * as dashboardSelectors from '../../selectors/dashboard'
import { PayHistory, UserDashboardMacro, UserIndustryCount, YtdAndRelativeStats } from '../../store/dashboard/types'
import { BasePay } from '../../store/orders/types'
import RecentActivity from './RecentActivity'
import FinancialActivity from './FinancialActivity'
import UnreadMessages from './UnreadMessages'
import DashboardStyles from './Styles'
import { updateEarningsGoal } from '../../store/user_preferences/actions'
import { userNextPay } from '../../selectors/user'
import { PaymentItemWithDate } from '../../store/user_preferences/reducers'
import { resetCron, ResettableCrons } from '../../store/cron/actions'
import { resetLatestClientMessagesLastPoll } from '../../store/nudges/actions'
import ScheduleContainer from '../Scheduling/index'
import useUserInfoState from '../common/useUserInfo'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme =>
  createStyles({
    regularContainer: {
      backgroundColor: 'transparent',
      color: 'inherit',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      fontSize: 'inherit',
      justifyContent: 'space-between',
      margin: '2.3em 0',
      width: '100%',
    },
    oneBoxContainer: {
      backgroundColor: 'transparent',
      color: 'inherit',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      fontSize: 'inherit',
      justifyContent: 'center',
      margin: '2.3em 0',
      width: '100%',
    },
    dashboard: {
      color: DashboardStyles.darkText,
      fontSize: DashboardStyles.fontSize,
      maxWidth: '1074px',
      margin: '0 auto 9.2em',
    },
    [theme.breakpoints.down('sm')]: {
      container: {
        flexDirection: 'column',
      },
      dashboard: {
        padding: DashboardStyles.dashboardMobilePadding,
      },
    },
  })
)

interface DashboardProps {
  user: UserInfo
}

export enum DashboardVariants {
  ActiveIC = 'active-ic',
  InactiveIC = 'inactive-ic',
  Offshore = 'offshore',
  Mentee = 'mentee',
  NewHire = 'new-hire',
}

const Dashboard: React.FC<DashboardProps> = ({ user }) => {
  const isOffshore = user.groups.includes(Groups.Offshore)
  const isLowCostIc = user.tag_keys.includes(FeatureTags.LowCostIc)
  const dashboardVariant = dashboardSelectors.getDashboardVariant(user)
  const userTimeZone = dashboardSelectors.getUserTimeZone(user)
  const currency = dashboardSelectors.getUserCurrency(user)
  const mentorName = dashboardSelectors.getUserMentor(user)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const classes = useStyles()

  const { featureFlags } = useUserInfoState()

  useEffect(() => {
    dispatch(loadDashboard(user.id, isOffshore))
    dispatch(resetLatestClientMessagesLastPoll())
    dispatch(resetCron(ResettableCrons.ClientMessages))
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [user.id, isOffshore])

  const earningsProgress = useSelector<AppState, dashboardSelectors.Progress<BasePay> | null>(
    state => dashboardSelectors.getEarningsProgress(state.userPreferencesReducer, state.dashboardReducer, user.id),
    isEqual
  )

  const nextPay = useSelector<AppState, PaymentItemWithDate | null>(state => userNextPay(state, user.id), isEqual)

  const orderProgress = useSelector<AppState, dashboardSelectors.Progress<number> | null>(
    state => dashboardSelectors.getOrderProgress(state.dashboardReducer, state.userPreferencesReducer, user.id),
    isEqual
  )

  const macroStats = useSelector<AppState, UserDashboardMacro | null>(
    state => dashboardSelectors.getMacroStats(state.dashboardReducer, user.id),
    isEqual
  )

  const macroErrorForUser = useSelector<AppState, boolean>(
    state => dashboardSelectors.didMacroErrorForUser(state.dashboardReducer, user.id),
    isEqual
  )

  const firstDraftAvg = useSelector<AppState, number | null>(state =>
    dashboardSelectors.getFirstDraftAvg(state.dashboardReducer, user.id)
  )

  const responseTimeAvg = useSelector<AppState, number | null>(state =>
    dashboardSelectors.getResponseTimeAvg(state.dashboardReducer, user.id)
  )

  const userOutput = useSelector<AppState, dashboardSelectors.OutputDynamicsViewModel>(
    state => dashboardSelectors.getOutputDynamics(state.dashboardReducer, user.id),
    isEqual
  )

  const industriesWrittenFor = useSelector<AppState, UserIndustryCount[] | null>(state =>
    dashboardSelectors.getIndustriesWrittenFor(state.dashboardReducer, user.id)
  )

  const payStats = useSelector<AppState, YtdAndRelativeStats | null>(
    state => dashboardSelectors.getPayStats(state.dashboardReducer, user.id),
    isEqual
  )

  const paymentHistory = useSelector<AppState, PayHistory[]>(
    state => dashboardSelectors.getPayHistory(state.dashboardReducer, user.id),
    isEqual
  )

  const earningsLoaded = useSelector<AppState, boolean>(
    state => dashboardSelectors.isUserEarningsLoaded(state.dashboardReducer, user.id),
    isEqual
  )

  const setEarningsGoal = useCallback(
    async (amount: number) => {
      await dispatch(updateEarningsGoal(amount, user.id))
    },
    [user.id, dispatch]
  )

  return (
    <Box className={classes.dashboard}>
      <Helmet>
        <title>{i18n.t('dashboard__pageTitle')}</title>
      </Helmet>

      <Greeting name={user.name} variant={dashboardVariant} />

      <Box
        className={
          dashboardVariant === DashboardVariants.Mentee || macroErrorForUser
            ? classes.oneBoxContainer
            : classes.regularContainer
        }
      >
        {macroErrorForUser ? (
          <DashboardErrorCard />
        ) : (
          <MacroSummaryCard variant={dashboardVariant} macro={macroStats} mentorName={mentorName} />
        )}

        {!!dashboardVariant && dashboardVariant !== DashboardVariants.Mentee && (
          <OutputHeroCard
            output_stats={userOutput.output_dynamics}
            loading={userOutput.isLoaded}
            variant={dashboardVariant}
          />
        )}
      </Box>

      <Box className={classes.regularContainer}>
        <OverallPerformance
          variant={dashboardVariant}
          earningsProgress={earningsProgress}
          orderProgress={orderProgress}
          firstDraftAvg={firstDraftAvg}
          responseTimeAvg={responseTimeAvg}
          updateEarningsGoal={setEarningsGoal}
          newHireBonusProgress={!!macroStats ? macroStats.new_hire_bonus_progress : null}
          isLowCostIc={isLowCostIc}
        />
      </Box>

      {featureFlags.showScheduler && !isMobile && (
        <Box className={classes.regularContainer}>
          <ScheduleContainer />
        </Box>
      )}

      <Box className={classes.regularContainer}>
        <UnreadMessages
          load_state={!!macroStats}
          client_messages={!!macroStats ? macroStats.unread_client_messages : []}
        />
        <RecentActivity user={user} />
      </Box>

      {dashboardVariant !== DashboardVariants.Offshore && (
        <FinancialActivity
          userID={user.id}
          isLoaded={earningsLoaded}
          userTimeZone={userTimeZone}
          currency={currency}
          industriesWrittenFor={industriesWrittenFor}
          paymentHistory={paymentHistory}
          nextPay={nextPay}
          payStats={!!payStats ? payStats : null}
        />
      )}
    </Box>
  )
}

// @ts-ignore
Dashboard.whyDidYouRender = true

export default Dashboard
