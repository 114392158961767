import { Upsell } from '../../../store/clients/reducer'

interface Params {
  data: {
    root: {
      snippetName: string
      upsells: Upsell[]
    }
  }
}

export default function(data: Params): string {
  const upsells = data.data.root.upsells ?? []
  const snippetName = data.data.root.snippetName
  const upsell = upsells.find(item => item.name === snippetName)

  return upsell?.discount_token ?? ''
}
