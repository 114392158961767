import produce from 'immer'

import { initialState, CronState } from './types'
import { CronActions, CronActionTypes } from './actions'
import { ConfigActions } from '../config/actions'

export default function cronReducer(state: CronState = initialState, action: CronActions): CronState {
  return produce(state, draft => {
    switch (action.type) {
      case CronActionTypes.SET_INTERVAL_IDS:
      case CronActionTypes.CLEAR_INTERVAL_IDS:
        draft.intervals = { ...state.intervals, ...action.intervals }
        break

      case ConfigActions.FETCH_CONFIG_SUCCESS:
        if (action.payload.data) {
          draft.versions.running = action.payload.data.sentry.release
          draft.versions.latest = action.payload.data.sentry.release
        }
        break

      case ConfigActions.UPDATE_CONFIG_SUCCESS:
        if (action.payload.data) {
          const latestVersion = action.payload.data.sentry.release
          draft.versions.needsUpgrade = latestVersion !== state.versions.running
          draft.versions.latest = latestVersion
        }
        break
    }
  })
}
