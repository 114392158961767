import React, { useState, useCallback } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import update from 'immutability-helper'
import i18n from '../../../i18n'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Industry from './Industry'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

export type IndustriesRankData = number[]

export type IndustriesRankValue = number[] | null

interface IndustriesRankProps {
  data: IndustriesRankData
  handleChange?: (value: IndustriesRankValue) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    industry: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
      '& .index': {
        minWidth: '2.75rem',
      },
    },
  })
)

const IndustriesRank: React.FC<IndustriesRankProps> = props => {
  const classes = useStyles()
  const { data, handleChange } = props

  const [order, setOrder] = useState(data.slice(0))

  const [industries, setIndustries] = useState(
    data.map(d => ({
      id: d,
      title: i18n.t(`industry__industry${d}`),
      description: i18n.t(`industry__industryDescription${d}`),
    }))
  )

  const moveIndustry = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const orderItem = order[dragIndex]
      const dragCard = industries[dragIndex]
      const newOrder = update(order, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, orderItem],
        ],
      })
      setOrder(newOrder)
      setIndustries(
        update(industries, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      )
      handleChange && handleChange(newOrder)
    },
    [industries, order, handleChange]
  )

  return (
    <Box className={classes.root}>
      <DndProvider backend={HTML5Backend}>
        {industries.map((industry, i) => (
          <Box className={classes.industry} key={i}>
            <Typography display="inline" variant={'h3'} className="index">
              {i + 1}
            </Typography>
            <Industry
              id={industry.id}
              title={industry.title}
              description={industry.description}
              index={i}
              moveHandler={moveIndustry}
            />
          </Box>
        ))}
      </DndProvider>
    </Box>
  )
}

export default IndustriesRank
