import React, { useEffect } from 'react'
import i18n from '../../i18n'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import DashboardStyles from '../Dashboard/Styles'
import CalendarStyles from './Styles'
import Onboarding from './Onboarding'
import Agenda from './Agenda'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store'
import { getCurrentStep, getSchedulingMeta, userSetupScheduling } from '../../store/scheduling/selectors'
import { OnboardingStates, SchedulingMeta } from '../../store/scheduling/types'
import { fetchCalendarEvents, SCHEDULING } from '../../store/scheduling/actions'
import useUserInfoState from '../common/useUserInfo'
import isEqual from 'lodash/isEqual'
import ErrorBoundary from '../common/ErrorBoundary'
import { AssignmentPreferences } from '../../store/user_preferences/reducers'
import { userAssignmentPreferences } from '../../selectors/users'
import { fetchUserPreferences } from '../../store/user_preferences/actions'

const useStyles = makeStyles(() =>
  createStyles({
    scheduleContainer: {
      width: DashboardStyles.fullWidth,
      minHeight: CalendarStyles.height,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: DashboardStyles.fullWidth,
      backgroundColor: 'transparent',
    },
    onboardingContainer: {
      width: DashboardStyles.fullWidth,
    },
    agendaContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: DashboardStyles.fullWidth,
      backgroundColor: 'transparent',
    },
    header: {
      color: DashboardStyles.header.color,
      fontFamily: styles.fonts.primary.family,
      fontSize: DashboardStyles.header.size,
      margin: DashboardStyles.header.margin,
      width: DashboardStyles.header.width,
    },
  })
)

const ScheduleContainer: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const userInfo = useUserInfoState()

  const schedulingMeta = useSelector<AppState, SchedulingMeta>(state => getSchedulingMeta(state.schedulingReducer))

  const isSchedulingSetup = useSelector<AppState, boolean>(state => userSetupScheduling(state.schedulingReducer))
  const assignmentPreferences = useSelector<AppState, AssignmentPreferences | null>(state =>
    userAssignmentPreferences(state.userPreferencesReducer)
  )

  useEffect(() => {
    if (isSchedulingSetup && !schedulingMeta[SCHEDULING.GET_CALENDAR_EVENTS].isLoading) {
      dispatch(fetchCalendarEvents(userInfo.id))
    }
    if (!assignmentPreferences) {
      dispatch(fetchUserPreferences(userInfo.id))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fetchCalendarEvents, isSchedulingSetup, userInfo.id])

  const currentStep = useSelector<AppState, OnboardingStates>(state => getCurrentStep(state.schedulingReducer))

  return (
    <ErrorBoundary>
      <Box className={classes.scheduleContainer}>
        <Typography className={classes.header} component="h3">
          {i18n.t('dashboard__schedule__title')}
        </Typography>
        {!schedulingMeta[SCHEDULING.GET_CALENDAR_EVENTS].isLoading &&
        !schedulingMeta[SCHEDULING.GET_PREFERENCES].isLoading ? (
          <Box className={classes.container}>
            {currentStep !== OnboardingStates.Completed && (
              <Box className={classes.onboardingContainer}>
                <Onboarding currentStep={currentStep} />
              </Box>
            )}
            {schedulingMeta[SCHEDULING.GET_CALENDAR_EVENTS].loaded && currentStep === OnboardingStates.Completed && (
              <Box className={classes.agendaContainer}>
                <Agenda userID={userInfo.id} assignmentPreferences={assignmentPreferences} />
              </Box>
            )}
          </Box>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
    </ErrorBoundary>
  )
}

const memoizedScheduleContainer = React.memo(ScheduleContainer, isEqual)
// @ts-ignore
ScheduleContainer.whyDidYouRender = true

export default memoizedScheduleContainer
