import React from 'react'
import Box from '@material-ui/core/Box'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepConnector from '@material-ui/core/StepConnector'
import { StepIconProps } from '@material-ui/core/StepIcon'
import Typography from '@material-ui/core/Typography'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import styles from '../../styles'

interface Props {
  title: string
  activeStep?: number
  closeButton?: boolean
  handleClose?: () => void
  showSteps?: boolean
  transparent?: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    iconButton: {
      color: styles.palette.darkerBlue.color.hex,
      padding: '.35em',
      position: 'absolute',
      right: '-.09em',
      top: '0',
    },
    stepCompleted: {
      '& > *': {
        borderColor: styles.palette.primary.contrast,
      },
    },
    stepConnector: {
      '& > *': {
        borderColor: 'rgba(11, 20, 59, .2)',
      },
    },
    steps: {
      width: '60%',
    },
    title: {
      fontWeight: 600,
      color: styles.palette.primary.contrast,
    },
    transparent: {
      backgroundColor: 'transparent',
    },
  })
)

const StepIcon: React.FC<StepIconProps> = ({ active, completed }) => {
  if (active || completed) {
    return <FiberManualRecordIcon />
  }

  return <RadioButtonUncheckedIcon />
}

const Header: React.FC<Props> = ({
  handleClose,
  title,
  activeStep = 0,
  closeButton = false,
  showSteps = true,
  transparent = false,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.header} component="header" id="header">
      <Typography component="h2" align="center" variant="h1" className={classes.title}>
        {title}
      </Typography>
      {showSteps && (
        <Stepper
          activeStep={activeStep}
          className={clsx(classes.steps, transparent ? classes.transparent : null)}
          connector={
            <StepConnector
              classes={{
                root: classes.stepConnector,
                completed: classes.stepCompleted,
                active: classes.stepCompleted,
              }}
            />
          }
        >
          {[0, 1, 2].map(v => (
            <Step key={v} completed={v <= activeStep} active={v === activeStep}>
              <StepLabel StepIconComponent={StepIcon}> </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {closeButton && (
        <IconButton className={classes.iconButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  )
}

export default Header
