import React, { useCallback, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import FileDrop from 'react-file-drop'
import UploadIcon from '@material-ui/icons/CloudUpload'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import classNames from 'classnames'

import i18n from '../../i18n'
import styles from '../../styles'
import Button from '../common/Button'
import UploadItem from './UploadItem'
import { FileUploadRequest, PendingUploadState, UploadDocTypeMenu } from '../../store/uploads/types'
import Footer from '../common/Footer'
import Header from '../common/Header'
import ModalWrapper from '../common/ModalWrapper'
import CustomCheckbox from '../common/CustomCheckbox'
import UploadStyles from './styles'
import { UploadGoal } from '../../store/documents/types'
import { OrderItemTypes } from '../../store/items/types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import SecondaryDocIcon from '../../assets/svgs/secondary-doc.svg'
import { getDocumentsString } from '../../utils/formatting'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      display: 'flex',
      '@media (max-width: 800px)': {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    leftColumn: {
      width: '14.625rem',
      marginRight: '2rem',
      textAlign: 'center',
      '@media (max-width: 800px)': {
        width: 'auto',
        marginRight: 0,
        marginBottom: '1.5rem',
      },
    },
    rightColumn: {
      flexGrow: 1,
    },
    bodyTitle: {
      marginBottom: '1rem',
      fontSize: '1rem',
      fontWeight: 500,
      '&.narrow': { marginBottom: '.4rem' },
    },
    bodySubtitle: {
      marginBottom: '1rem',
      color: styles.palette.lightBlack.color,
    },
    cancelButton: {
      marginRight: '1.2rem',
      padding: '.5rem 1.547rem',
    },
    submitButton: {
      padding: '.5rem 1.547rem',
    },
    dropZone: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '14.625rem',
      height: '15.625rem',
      marginBottom: '1.25rem',
      backgroundColor: 'rgba(0, 153, 230, 0.1)',
      border: '3px solid #0099e6',
      boxSizing: 'border-box',
      color: '#0099e6',
    },
    dropZoneIcon: {
      fontSize: '3.125rem',
      marginBottom: '1rem',
    },
    dropZoneTitle: {
      fontSize: '.8rem',
      fontWeight: 600,
      textTransform: 'uppercase',
      marginBottom: '2rem',
    },
    uploadButton: {
      position: 'relative',
    },
    uploadInput: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '200%',
      transform: 'translateY(-50%)',
      opacity: 0,
      cursor: 'pointer',
    },
    agreementContainer: {
      textAlign: 'left',
      margin: '.5rem 0 0 0',
      '& .MuiButtonBase-root': { padding: '.375rem', marginRight: '.5rem' },
      '@media (max-width: 800px)': {
        margin: '1.5rem 0 0 0 !important',
      },
    },
    checkboxLabel: {
      color: styles.palette.contrast.color,
    },
    hasError: {
      color: UploadStyles.pink.hex,
    },
    modalTitle: {
      fontWeight: 'bold',
      fontStretch: 'normal',
      forntStyle: 'normal',
      fontSize: '1.25em',
      lineHeight: 1.25,
      color: styles.palette.shadowedSelect.color,
    },
    modalBody: {
      marginBottom: '1em',
      padding: '.6em 1.5em 0 1.5em',
      '& > :first-child': { paddingBottom: '1em' },
    },
    modalText: {
      color: styles.palette.shadowedSelect.color,
    },
    iconBox: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#deecf9',
      minHeight: 176,
    },
    modalButtons: {
      padding: '1.5em',
      float: 'right',
    },
    modalRoot: {
      width: 559,
    },
    '@media (max-width: 800px)': {
      body: {
        flexDirection: 'column',
        justifyContent: 'center',
      },
      rightColumn: {
        width: '70%',
      },
    },
  })
)

export interface UploadMetaOptions {
  type: UploadDocTypeMenu[]
  version: UploadDocVersionMenu[]
}

interface UploadProps {
  files: PendingUploadState[]
  goal: UploadGoal
  isUserExpertMentee: boolean
  onSubmit: () => void
  options: UploadMetaOptions
  addUploads: (files: FileList) => void
  removeUpload: (filename: string) => void
  updateUpload: (filename: string, data: Partial<FileUploadRequest>) => void
  handleCancel: () => void
  // This is null when there are no writer uploads on the order
  handleSkipToAttachments: null | (() => void)
}

export interface UploadDocVersionMenu {
  value: number
  label: string
}

const Upload: React.FC<UploadProps> = ({
  files,
  goal,
  isUserExpertMentee,
  onSubmit,
  options,
  addUploads,
  removeUpload,
  updateUpload,
  handleCancel,
  handleSkipToAttachments,
}) => {
  const classes = useStyles()

  const [agreementCheck, setAgreementCheck] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [missingSecondaryDocuments, setMissingSecondaryDocuments] = useState<string[]>([])

  const getMissingSecondaryDocs = useCallback((): string[] => {
    if (isUserExpertMentee || goal === UploadGoal.AutoLinkedin) {
      return []
    }
    const mainDocFirstDraft = files.find(
      f => (f.doc_type === OrderItemTypes.Resume || f.doc_type === OrderItemTypes.CV) && f.revision === 0
    )
    if (!mainDocFirstDraft) {
      return []
    }

    const secondaryDocs = options.type.filter(
      t =>
        t.doc_type !== OrderItemTypes.CV &&
        t.doc_type !== OrderItemTypes.Resume &&
        t.doc_type !== OrderItemTypes.LinkedIn &&
        t.doc_type !== OrderItemTypes.PhoneConsultation &&
        t.doc_type !== OrderItemTypes.TopInterview &&
        t.revision === 0
    )
    if (secondaryDocs.length === 0) {
      return []
    }

    const uploadedSecondaryDocs = files
      .filter(
        f =>
          f.doc_type !== OrderItemTypes.Resume &&
          f.doc_type !== OrderItemTypes.CV &&
          f.doc_type !== OrderItemTypes.LinkedIn &&
          f.doc_type !== OrderItemTypes.PhoneConsultation &&
          f.doc_type !== OrderItemTypes.TopInterview &&
          f.revision === 0
      )
      .map(f => f.doc_type)

    return secondaryDocs.filter(d => !uploadedSecondaryDocs.includes(d.doc_type)).map(d => d.label)
  }, [files, goal, isUserExpertMentee, options.type])

  const distinctDocumentTypeLabels = [...new Set(options.type.map(t => t.label))]

  const onFileSelect = React.useCallback(
    (event: FileList | null | React.ChangeEvent<HTMLInputElement>) => {
      if (!event) {
        return
      }

      const selectedFiles = event instanceof FileList ? event : event.target.files

      if (selectedFiles) {
        addUploads(selectedFiles)
      }
    },
    [addUploads]
  )

  const handleAgreementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreementCheck(event.target.checked)
  }

  const onFormSubmit = React.useCallback(() => {
    const hasFileErrors = !!files.find(f => {
      if (!f.revision && f.revision !== 0) {
        return true
      }

      if (!f.order_item_id) {
        return true
      }

      return false
    })

    if (hasFileErrors || !agreementCheck) {
      setShowErrors(true)
      return
    }

    const missingSecondaryDocs = getMissingSecondaryDocs()
    if (missingSecondaryDocs.length === 0) {
      onSubmit()
    } else {
      setMissingSecondaryDocuments(missingSecondaryDocs)
    }
  }, [onSubmit, setShowErrors, agreementCheck, files, getMissingSecondaryDocs, setMissingSecondaryDocuments])

  return (
    <ModalWrapper>
      <Header activeStep={0} title={i18n.t('uploadsv3__uploads__title')} />
      <form className={classes.body} autoComplete="off">
        <Box className={classes.leftColumn}>
          <FileDrop className={classes.dropZone} onDrop={e => onFileSelect(e)}>
            <UploadIcon className={classes.dropZoneIcon} />
            <Typography display="block" variant={'h2'} className={classes.dropZoneTitle}>
              {i18n.t('messaging__docs__upload__drag')}
            </Typography>
            <Button type="primary" className={classes.uploadButton}>
              <input
                onChange={e => onFileSelect(e)}
                className={classes.uploadInput}
                type="file"
                multiple
                id="upload-file-input"
              />
              {i18n.t('messaging__docs__upload__select')}
            </Button>
          </FileDrop>
        </Box>
        <Box className={classes.rightColumn}>
          {!!files.length ? (
            <Box>
              {files.map(f => (
                <UploadItem
                  key={f.file.name}
                  file={f}
                  removeItem={() => removeUpload(f.file.name)}
                  updateItem={(data: Partial<FileUploadRequest>) => updateUpload(f.file.name, data)}
                  options={options}
                  showErrors={showErrors}
                />
              ))}
            </Box>
          ) : (
            <Box>
              <Typography display="block" variant={'h2'} className={classes.bodyTitle}>
                {i18n.t('messaging__docs__upload__orderItems')}
              </Typography>
              {distinctDocumentTypeLabels.map(label => (
                <Typography display="block" variant={'h5'} key={`order-item-${label}`} className={classes.bodySubtitle}>
                  {label}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      </form>
      <Footer
        leftColumn={
          <FormControlLabel
            className={classNames({
              [classes.checkboxLabel]: true,
              [classes.hasError]: showErrors && !agreementCheck,
            })}
            control={
              <CustomCheckbox
                checked={agreementCheck}
                onChange={e => handleAgreementChange(e)}
                id="upload-tos-agreements"
              />
            }
            label={i18n.t('messaging__docs__upload__agreement')}
            labelPlacement="end"
          />
        }
        rightColumn={
          <>
            <Button type="secondary" className={classes.cancelButton} onClick={handleCancel} id="upload-cancel">
              {i18n.t('messaging__docs__btn__close')}
            </Button>
            {!!files.length && (
              <Button type="contrast" className={classes.submitButton} onClick={onFormSubmit} id="upload-trigger-scan">
                {i18n.t('messaging__docs__btn__scan')}
              </Button>
            )}
            {!files.length && handleSkipToAttachments && goal === UploadGoal.Attach && (
              <Button
                type="contrast"
                className={classes.submitButton}
                onClick={handleSkipToAttachments}
                id="upload-skip-to-attachments"
              >
                {i18n.t('messaging__docs__btn__skipToAttachments')}
              </Button>
            )}
          </>
        }
      />
      <Dialog open={missingSecondaryDocuments.length > 0}>
        <Box className={classes.modalRoot}>
          <Box className={classes.iconBox}>
            <img src={SecondaryDocIcon} alt="" />
          </Box>
          <DialogTitle disableTypography={true}>
            <Typography variant={'h2'} className={classes.modalTitle}>
              {i18n.t('secondaryDocBlock__title', {
                missing_files: getDocumentsString(missingSecondaryDocuments, true),
              })}
            </Typography>
          </DialogTitle>
          <Box className={classes.modalBody}>
            <Typography variant={'body2'} className={classes.modalText}>
              {i18n.t('secondaryDocBlock__body1', {
                missing_files: getDocumentsString(missingSecondaryDocuments, false),
              })}
            </Typography>
            <Typography variant={'body2'} className={classes.modalText}>
              {i18n.t('secondaryDocBlock__body2')}
            </Typography>
          </Box>
          <DialogActions className={classes.modalButtons}>
            <Button type="secondary" onClick={() => onSubmit()}>
              {i18n.t('secondaryDocBlock__upload_without')}
            </Button>
            <Button type="contrast" onClick={() => setMissingSecondaryDocuments([])}>
              {i18n.t('secondaryDocBlock__upload_more')}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </ModalWrapper>
  )
}

export default React.memo(Upload)
