import { FileScanOption, FileScanOptionKeys, FileScanResult, FileScanResults, FileScan } from './types'
import { Dispatch } from 'redux'
import { ScanFile, scanFile } from './actions'
import { HashMap } from '../items/types'
import { AxiosMiddlewareActionSuccess } from '../../utils/axios'

export interface AsyncScanProcessing {
  option: FileScanOption
  result: object | null
}

export class FileScanner {
  private readonly retryMaxCount: number
  private readonly dispatch: Dispatch

  public constructor(retryMaxCount: number, dispatch: Dispatch) {
    this.retryMaxCount = retryMaxCount
    this.dispatch = dispatch
  }

  private async retryScan(
    preprocessID: number,
    scanKey: FileScanOptionKeys,
    scanRoute: string
  ): Promise<FileScanResults | null> {
    let tryCount = 1
    let scanResult: FileScanResults = FileScanResults.Failure
    do {
      try {
        const result = ((await this.dispatch(
          scanFile(preprocessID, scanKey, scanRoute, tryCount + 1 !== this.retryMaxCount)
        )) as unknown) as AxiosMiddlewareActionSuccess<FileScanResult, ScanFile>
        scanResult = result.payload.data.result
      } catch (e) {
        scanResult = e.error.response.data.result || null
      }
      tryCount++
    } while (
      (!scanResult || scanResult === FileScanResults.Failure || scanResult === FileScanResults.Warning) &&
      tryCount < this.retryMaxCount
    )
    return scanResult
  }

  private async doScan(preprocessID: number, option: FileScanOption, operation: object | null): Promise<boolean> {
    let scanResult: FileScanResults | null
    try {
      const result = (await operation) as AxiosMiddlewareActionSuccess<FileScanResult, ScanFile>
      scanResult = result.payload.data.result
    } catch (e) {
      scanResult = e.error.response.data.result || null
      if (
        (!scanResult || scanResult === FileScanResults.Failure || scanResult === FileScanResults.Warning) &&
        option.allow_failure_retry
      ) {
        scanResult = await this.retryScan(preprocessID, option.key, option.route)
      }
    }
    if (scanResult === FileScanResults.Failure && option.require_success) {
      return false
    } else {
      return true
    }
  }

  private async processAsyncScans(asyncScans: FileScanOption[], preprocessID: number): Promise<boolean> {
    const resultsMap: HashMap<AsyncScanProcessing> = {}
    asyncScans.forEach(a => {
      resultsMap[a.key] = {
        option: a,
        result: null,
      }
    })
    asyncScans.forEach(scan => {
      resultsMap[scan.key].result = this.dispatch(scanFile(preprocessID, scan.key, scan.route, true))
    })

    for (const scan of asyncScans) {
      const res = await this.doScan(preprocessID, resultsMap[scan.key].option, resultsMap[scan.key].result)
      if (!res) return false
    }
    return true
  }

  public async process(preprocessID: number, scanOptions: FileScan): Promise<boolean> {
    const asyncScans: FileScanOption[] = []

    for (const scanKey of scanOptions.order) {
      const scan = scanOptions[scanKey]

      if (!scan) {
        continue
      }

      const { option, meta } = scan

      if (!option || !option.active || meta.loaded) {
        continue
      }

      if (!option.async) {
        const isSuccess = await this.doScan(
          preprocessID,
          option,
          this.dispatch(scanFile(preprocessID, option.key, option.route, option.allow_failure_retry))
        )
        if (!isSuccess) {
          return isSuccess
        }
      } else {
        asyncScans.push(option)
      }
    }

    return this.processAsyncScans(asyncScans, preprocessID)
  }
}

export default FileScanner
