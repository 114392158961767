import React, { useRef } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'
import { XYCoord } from 'dnd-core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import DragIcon from '@material-ui/icons/Dehaze'

interface IndustryProps {
  id: number
  title: string
  description: string
  index: number
  moveHandler: (dragIndex: number, hoverIndex: number) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      minHeight: '2rem',
      padding: '.3rem 1rem',
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.14)',
      backgroundColor: '#f0f9ff',
      borderRadius: '.25rem',
      '& .icon': {
        color: '#666a74',
        fontSize: '1.375rem',
      },
      '& .texts': {
        marginLeft: '1rem',
        '& .title': { fontWeight: 600 },
        '& .description': {
          color: '#a4a7aa',
          fontSize: '.8125rem',
        },
      },
    },
  })
)

interface DragItem {
  index: number
  id: string
  type: string
}

const Industry: React.FC<IndustryProps> = props => {
  const classes = useStyles()
  const ref = useRef<HTMLDivElement>(null)
  const { id, title, description, index, moveHandler } = props

  const [, drop] = useDrop({
    accept: 'industry',
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveHandler(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'industry', id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0.5 : 1

  drag(drop(ref))

  return (
    <div className={classes.root} ref={ref} style={{ opacity }}>
      <DragIcon className="icon" />
      <Box className="texts">
        <Typography display="block" variant={'body1'} className="title">
          {title}
        </Typography>
        {description.length > 0 && (
          <Typography display="block" variant={'body2'} className="description">
            {description}
          </Typography>
        )}
      </Box>
    </div>
  )
}

export default Industry
