import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import allSettled from 'promise.allsettled'
import omit from 'lodash/omit'

import { AppState, AppThunkAction } from '..'
import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import {
  FileScanOptionKeys,
  FileScanResult,
  FileUploadRequest,
  FileUploadResponse,
  UploadFinalizeResponse,
  FileScan,
} from './types'
import { FileContexts } from '../documents/types'
import FileScanner from './fileScanner'
import { orderItemTypesSelectorWithRevision, ordersItemTypesSelector, getItemByIDSelector } from '../../selectors/items'
import { guessDocumentTypeFromFilename } from '../../utils/mapper'
import { getDocumentUploadsForClient } from '../../selectors/documents'
import { scansHaveAnyErrors } from '../../selectors/uploads'
import { sendUserEvent } from '../events/actions'
import { EventTypes } from '../events/types'

export enum UPLOAD {
  UPLOAD_FILE = 'UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL',
  SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS',
  REMOVE_UPLOAD = 'REMOVE_UPLOAD',
  CLEAN_UP_UPLOADS = 'CLEAN_UP_UPLOADS',

  SCAN_FILE = 'SCAN_FILE',
  SCAN_FILE_SUCCESS = 'SCAN_FILE_SUCCESS',
  SCAN_FILE_FAIL = 'SCAN_FILE_FAIL',

  UPLOAD_FINALIZE = 'UPLOAD_FINALIZE',
  UPLOAD_FINALIZE_SUCCESS = 'UPLOAD_FINALIZE_SUCCESS',
  UPLOAD_FINALIZE_FAIL = 'UPLOAD_FINALIZE_FAIL',

  ADD_PENDING_FILE = 'ADD_PENDING_FILE',
  ADD_PENDING_FILES = 'ADD_PENDING_FILES',
  REMOVE_PENDING_FILE = 'REMOVE_PENDING_FILE',
  UPDATE_PENDING_FILE = 'UPDATE_PENDING_FILE',
}

export interface UploadFile extends AxiosMiddlewareActionCreator {
  type: typeof UPLOAD.UPLOAD_FILE
  file: FileUploadRequest
  uploadIdentifier: string
}

export interface UploadFileSuccess extends AxiosMiddlewareActionSuccess<FileUploadResponse, UploadFile> {
  type: typeof UPLOAD.UPLOAD_FILE_SUCCESS
}

export interface UploadFileFail extends AxiosMiddlewareActionFail<UploadFile> {
  type: typeof UPLOAD.UPLOAD_FILE_FAIL
}
export interface SetUploadProgress {
  type: typeof UPLOAD.SET_UPLOAD_PROGRESS
  uploadIdentifier: string
  progressPercent: number
}

export interface RemoveUpload {
  type: typeof UPLOAD.REMOVE_UPLOAD
  uploadIdentifier: string
  preprocessID?: number | null
}

export interface CleanUpUploads {
  type: typeof UPLOAD.CLEAN_UP_UPLOADS
}

export interface ScanFile extends AxiosMiddlewareActionCreator {
  type: typeof UPLOAD.SCAN_FILE
  preprocessId: number
  route: string
  scanOption: FileScanOptionKeys
  hasRetries: boolean
}

export interface ScanFileSuccess extends AxiosMiddlewareActionSuccess<FileScanResult, ScanFile> {
  type: typeof UPLOAD.SCAN_FILE_SUCCESS
}

export interface ScanFileFail extends AxiosMiddlewareActionFail<ScanFile> {
  type: typeof UPLOAD.SCAN_FILE_FAIL
}

export interface UploadFinalize extends AxiosMiddlewareActionCreator {
  type: typeof UPLOAD.UPLOAD_FINALIZE
  preprocessID: number
}

export interface UploadFinalizeSuccess extends AxiosMiddlewareActionSuccess<UploadFinalizeResponse, UploadFinalize> {
  type: typeof UPLOAD.UPLOAD_FINALIZE_SUCCESS
}

export interface UploadFinalizeFail extends AxiosMiddlewareActionFail<UploadFinalize> {
  type: typeof UPLOAD.UPLOAD_FINALIZE_FAIL
}

export function uploadFile(
  file: FileUploadRequest,
  uploadIdentifier: string,
  onUploadProgressChange: (percentCompleted: number) => void
): UploadFile {
  const form = Object.entries(file).reduce((formData, [key, value]) => {
    formData.append(key, value)
    return formData
  }, new FormData())

  return {
    type: UPLOAD.UPLOAD_FILE,
    file: file,
    uploadIdentifier: uploadIdentifier,
    payload: {
      request: {
        url: `/v1/files`,
        method: 'POST',
        data: form,
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          onUploadProgressChange(percentCompleted)
        },
      },
    },
  }
}

export function updateUploadProgress(uploadIdentifier: string, progressPercent: number): SetUploadProgress {
  return {
    type: UPLOAD.SET_UPLOAD_PROGRESS,
    uploadIdentifier: uploadIdentifier,
    progressPercent: progressPercent,
  }
}

export function removeUpload(uploadIdentifier: string, preprocessID?: number | null): RemoveUpload {
  return {
    type: UPLOAD.REMOVE_UPLOAD,
    uploadIdentifier: uploadIdentifier,
    preprocessID: preprocessID,
  }
}

export function cleanUpUploads(): CleanUpUploads {
  return {
    type: UPLOAD.CLEAN_UP_UPLOADS,
  }
}

export function scanFile(
  preprocessId: number,
  option: FileScanOptionKeys,
  route: string,
  hasRetries: boolean
): ScanFile {
  return {
    type: UPLOAD.SCAN_FILE,
    preprocessId,
    scanOption: option,
    route,
    hasRetries,
    payload: {
      request: {
        url: route,
        method: 'POST',
      },
    },
  }
}

export function finalizeUpload(preprocessID: number): UploadFinalize {
  return {
    type: UPLOAD.UPLOAD_FINALIZE,
    preprocessID,
    payload: {
      request: {
        url: `/v1/files/finalize/${preprocessID}`,
        method: 'POST',
      },
    },
  }
}

export interface AddPendingFile {
  type: typeof UPLOAD.ADD_PENDING_FILE
  file: File
  context: FileContexts
  contextID: number
  data: Partial<FileUploadRequest>
}

export function addPendingFile(
  file: File,
  context: FileContexts,
  contextID: number,
  data?: Partial<FileUploadRequest>
): AddPendingFile {
  return {
    type: UPLOAD.ADD_PENDING_FILE,
    file,
    context,
    contextID,
    data: data || {},
  }
}

export interface AddPendingFiles {
  type: typeof UPLOAD.ADD_PENDING_FILES
  actions: AddPendingFile[]
}

export function addPendingFiles(actions: AddPendingFile[]): AddPendingFiles {
  return {
    type: UPLOAD.ADD_PENDING_FILES,
    actions,
  }
}

export function addPendingFilesAndGuess(files: FileList, context: FileContexts, contextID: number): AppThunkAction {
  return async function(dispatch, getState) {
    const state = getState()
    const orderIDs = state.clientReducer.clients[contextID].order_ids
    const documents = getDocumentUploadsForClient(state.documentReducer, contextID)
    const orderItemTypes = orderItemTypesSelectorWithRevision(
      ordersItemTypesSelector(state.itemsReducer, orderIDs),
      documents
    )

    const actions: AddPendingFile[] = Array.from(files).map(file => {
      const action = addPendingFile(file, context, contextID)
      const guessedType = guessDocumentTypeFromFilename(file.name)
      const guess = orderItemTypes.find(oit => oit.doc_type === guessedType || oit.doc_type.includes(guessedType))

      if (guess) {
        action.data = guess
      }

      return action
    })

    await dispatch({
      type: UPLOAD.ADD_PENDING_FILES,
      actions,
    })
  }
}

export interface RemovePendingFile {
  type: typeof UPLOAD.REMOVE_PENDING_FILE
  filename: string
}

export function removePendingFile(filename: string): RemovePendingFile {
  return {
    type: UPLOAD.REMOVE_PENDING_FILE,
    filename,
  }
}

export function removePendingFileThunk(filename: string): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    const pendingUploadToRemove = getState().uploadsReducer.pending[filename]

    dispatch(
      sendUserEvent({
        event: EventTypes.UploadPendingFileRemoved,
        variables: {
          context: pendingUploadToRemove.context,
          contextID: pendingUploadToRemove.context_id,
          preprocessID: pendingUploadToRemove.preprocessID,
          orderItemID: pendingUploadToRemove.order_item_id,
          docType: pendingUploadToRemove.doc_type,
        },
      })
    )

    dispatch(removePendingFile(filename))
  }
}

export interface UpdatePendingFile {
  type: typeof UPLOAD.UPDATE_PENDING_FILE
  filename: string
  data: Partial<FileUploadRequest>
}

export function updatePendingFile(filename: string, data: Partial<FileUploadRequest>): AppThunkAction {
  return async function(dispatch, getState) {
    if (data.order_item_id && !data.order_id) {
      const selectedItem = getItemByIDSelector(getState().itemsReducer, data.order_item_id)

      if (selectedItem) {
        data.order_id = selectedItem.order_id
      }
    }

    dispatch({
      type: UPLOAD.UPDATE_PENDING_FILE,
      filename,
      data,
    })
  }
}

export function uploadFilesAndScan(): AppThunkAction<boolean> {
  return async function(dispatch, getState) {
    // First, we must upload all the pending files for preprocessing
    const pendingUploads = getState().uploadsReducer.pending
    const uploadActions = Object.entries(pendingUploads)
      .filter(([, pendingUpload]) => !pendingUpload.preprocessID)
      .map(([identifier, pendingUpload]) =>
        uploadFile(pendingUpload, identifier, (percent: number) => dispatch(updateUploadProgress(identifier, percent)))
      )
    await allSettled(uploadActions.map(action => dispatch(action)))

    // Send event that all files finished uploading
    const filesToBeProcessed = getState().uploadsReducer.preprocess
    const variables = {
      context: uploadActions[0].file.context,
      contextID: uploadActions[0].file.context_id,
      preprocessIDs: Object.keys(filesToBeProcessed).map(strID => parseInt(strID)),
    }
    dispatch(
      // @ts-ignore
      sendUserEvent({
        event: EventTypes.UploadFilesFinishedUploading,
        variables,
      })
    )

    // All the file uploads finished. Their uploads returned directions on how to scan the files that were stored in the
    // store. We need to loop over all those scans and go to town.
    const preprocessActions = Object.entries(filesToBeProcessed).map(
      ([strPreprocessID, preprocessDirectives]: [string, FileScan]) => {
        const scanner = new FileScanner(3, dispatch)
        const preprocessID = parseInt(strPreprocessID)
        return scanner.process(preprocessID, preprocessDirectives)
      }
    )
    await allSettled(preprocessActions)

    dispatch(
      // @ts-ignore
      sendUserEvent({
        event: EventTypes.UploadFilesFinishedScanning,
        variables,
      })
    )

    // We must now return if there were any errors so that the caller can deal with it as it sees fit.
    return scansHaveAnyErrors(getState().uploadsReducer)
  }
}

export function reuploadFileAndScan(file: File, filenameToRemove: string) {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    // First, remove the file that we are reuploading after we grab some data from it
    const preprocessToRemove = getState().uploadsReducer.pending[filenameToRemove]
    const { context, context_id } = preprocessToRemove
    dispatch(removeUpload(filenameToRemove, preprocessToRemove.preprocessID))

    // Next, add this file to pending and trigger scanning
    dispatch(
      addPendingFile(file, context, context_id, {
        ...omit(preprocessToRemove, ['preprocessID']),
        file,
      })
    )
    await dispatch(uploadFilesAndScan())

    // Send an event that the user reuploaded the file
    dispatch(
      sendUserEvent({
        event: EventTypes.UploadReuploadedFile,
        variables: {
          context: preprocessToRemove.context,
          contextID: preprocessToRemove.context_id,
          oldPreprocessID: preprocessToRemove.preprocessID,
          newPreprocessID: Math.max(...Object.keys(getState().uploadsReducer.preprocess).map(strID => parseInt(strID))),
        },
      })
    )
  }
}

export function finalizeAllUploads(): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    const processedUploads = getState().uploadsReducer.preprocess
    const actions = Object.keys(processedUploads).map(strPreprocessID => finalizeUpload(parseInt(strPreprocessID)))
    await Promise.all(actions.map(action => dispatch(action)))
  }
}

export type UploadsActionTypes =
  | UploadFile
  | UploadFileSuccess
  | UploadFileFail
  | ScanFile
  | ScanFileSuccess
  | ScanFileFail
  | CleanUpUploads
  | UploadFinalize
  | UploadFinalizeSuccess
  | UploadFinalizeFail
  | SetUploadProgress
  | RemoveUpload
  | AddPendingFile
  | AddPendingFiles
  | RemovePendingFile
  | UpdatePendingFile
