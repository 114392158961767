import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'

export const DashboardErrorCard: React.FC<{}> = () => (
  <Card>
    <CardContent>
      <ErrorIcon />
      <Typography component="h2">Oops...something's wrong</Typography>
    </CardContent>
  </Card>
)

export default DashboardErrorCard
