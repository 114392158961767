import { EditorState } from 'draft-js'

import { OutgoingMessage } from '../messages/types'

export interface DirectSchedulingMeta {
  date_time: Date
  item_id: number
  order_id: number
  customerNote: string
  serviceName: string
}

export interface CurrentMessage extends OutgoingMessage {
  editorState: EditorState
  client_id: number | null
  replyActualMessageID: number | null
}

export type CurrentMessageUpdatePayload = Partial<CurrentMessage>

export const defaultCurrentMessage: CurrentMessage = {
  sender: '',
  recipients: [],
  bcc_email: null,
  subject: '',
  body: '',
  message_text: '',
  variables: {},
  attachment_ids: [],
  reply_message_id: null,
  replyActualMessageID: null,
  editorState: EditorState.createEmpty(),
  statuses: [],
  client_id: null,
  omit_attachments_in_email: false,
}
