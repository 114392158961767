import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import { ClientOrdersAggregateResponse, OrderResponse } from '../orders/types'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AppState } from '../index'
import { AnyAction } from 'redux'
import { fetchMessagesAndReadEvent } from '../messages/actions'
import { fetchClientDocuments } from '../documents/actions'
import { fetchClient, fetchClientMetadata } from '../clients/actions'
import { MessageContexts } from '../messages/types'
import { OrderFilter, OrderStatus } from '../orders/actions'
import { fetchTimeBasedIncentivesSafely } from '../incentives/actions'
import { fetchClientQuestionnaire } from '../questionnaire/actions'
import { OrderTableDisplayModes } from '../../components/Orders/useOrderFiltering'

export enum ClientOrdersAggregateActions {
  FETCH_CLIENT_ORDERS = 'FETCH_CLIENT_ORDERS',
  FETCH_CLIENT_ORDERS_SUCCESS = 'FETCH_CLIENT_ORDERS_SUCCESS',
  FETCH_CLIENT_ORDERS_FAIL = 'FETCH_CLIENT_ORDERS_FAIL',

  FETCH_USER_AGGREGATED_ORDERS = 'FETCH_USER_AGGREGATED_ORDERS',
  FETCH_USER_AGGREGATED_ORDERS_SUCCESS = 'FETCH_USER_AGGREGATED_ORDERS_SUCCESS',
  FETCH_USER_AGGREGATED_ORDERS_FAIL = 'FETCH_USER_AGGREGATED_ORDERS_FAIL',

  GO_TO_PAGE = 'GO_TO_PAGE',

  SET_VIEW_PARAMS = 'SET_VIEW_PARAMS',

  CLOSE_ORDER_BY_PRODUCTS_ORDER_ID = 'CLOSE_ORDER_BY_PRODUCTS_ORDER_ID',
  CLOSE_ORDER_BY_PRODUCTS_ORDER_ID_SUCCESS = 'CLOSE_ORDER_BY_PRODUCTS_ORDER_ID_SUCCESS',
  CLOSE_ORDER_BY_PRODUCTS_ORDER_ID_FAIL = 'CLOSE_ORDER_BY_PRODUCTS_ORDER_ID_FAIL',
}

export interface FetchClientOrders extends AxiosMiddlewareActionCreator {
  clientID: number
  type: typeof ClientOrdersAggregateActions.FETCH_CLIENT_ORDERS
}

export interface FetchClientOrdersSuccess extends AxiosMiddlewareActionSuccess<OrderResponse[], FetchClientOrders> {
  type: typeof ClientOrdersAggregateActions.FETCH_CLIENT_ORDERS_SUCCESS
}

export interface FetchClientOrdersFail extends AxiosMiddlewareActionFail<FetchClientOrders> {
  type: typeof ClientOrdersAggregateActions.FETCH_CLIENT_ORDERS_FAIL
}

export interface FetchUserAggregatedOrders extends AxiosMiddlewareActionCreator {
  status?: OrderStatus[]
  filter: OrderFilter
  type: typeof ClientOrdersAggregateActions.FETCH_USER_AGGREGATED_ORDERS
}

export interface FetchUserAggregatedOrdersSuccess
  extends AxiosMiddlewareActionSuccess<ClientOrdersAggregateResponse, FetchUserAggregatedOrders> {
  type: typeof ClientOrdersAggregateActions.FETCH_USER_AGGREGATED_ORDERS_SUCCESS
}

export interface FetchUserAggregatedOrdersFail extends AxiosMiddlewareActionFail<FetchUserAggregatedOrders> {
  type: typeof ClientOrdersAggregateActions.FETCH_USER_AGGREGATED_ORDERS_FAIL
}

export function fetchUserClientOrders(filter: OrderFilter = {}): FetchUserAggregatedOrders {
  return {
    type: ClientOrdersAggregateActions.FETCH_USER_AGGREGATED_ORDERS,
    status: filter.status,
    filter,
    payload: {
      request: {
        url: `/v1/orders/user/me/clients`,
        params: filter,
      },
    },
  }
}

export function fetchClientOrders(clientID: number, filter: OrderFilter = {}): FetchClientOrders {
  return {
    type: ClientOrdersAggregateActions.FETCH_CLIENT_ORDERS,
    clientID: clientID,
    payload: {
      request: {
        url: `/v1/orders/client/${clientID}`,
        params: filter,
      },
    },
  }
}

export interface GoToPageOfClientOrders {
  type: typeof ClientOrdersAggregateActions.GO_TO_PAGE
  paginationKey: 'open' | 'paid'
  page: number
}

export interface SetViewParams {
  type: typeof ClientOrdersAggregateActions.SET_VIEW_PARAMS
  searchTerm?: string
  filter?: OrderTableDisplayModes
}

export function goToPageOfClientOrders(paginationKey: 'open' | 'paid', page: number) {
  return {
    type: ClientOrdersAggregateActions.GO_TO_PAGE,
    paginationKey,
    page: Math.max(page, 1),
  }
}

export function setClientViewSearchTerm(term: string) {
  return {
    type: ClientOrdersAggregateActions.SET_VIEW_PARAMS,
    searchTerm: term,
  }
}

export function setClientViewFilter(filter: OrderTableDisplayModes) {
  return {
    type: ClientOrdersAggregateActions.SET_VIEW_PARAMS,
    filter: filter,
  }
}

export function fetchClientOrderDetailsThunk(
  clientID: number,
  filter: OrderFilter = {}
): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    await dispatch(fetchClientOrders(clientID, filter))
    dispatch(fetchClientMetadata(clientID))
    // @ts-ignore
    dispatch(fetchTimeBasedIncentivesSafely(clientID, getState().userReducer.loggedInUserID))
  }
}

// preloadClient will fetch client's associated verbose orders, messages, documents, and notes.
export function preloadClient(clientID: number): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    // Fetch all the documents, messages, and notes associated with the order
    const promiseArray = [
      fetchClientOrderDetailsThunk(clientID, { verbose: true }),
      fetchClientQuestionnaire(clientID),
      fetchMessagesAndReadEvent(MessageContexts.CLIENT, clientID),
      fetchClientDocuments(clientID),
    ]

    // @ts-ignore
    await Promise.all(promiseArray.map(action => dispatch(action)))

    const state = getState()
    if (!state.clientOrdersAggregateReducer.states[clientID]) {
      await dispatch(fetchClient(clientID))
    }
  }
}

export interface CloseOrderByProductsOrderID extends AxiosMiddlewareActionCreator {
  type: typeof ClientOrdersAggregateActions.CLOSE_ORDER_BY_PRODUCTS_ORDER_ID
  clientID: number
}

export interface CloseOrderByProductsOrderIDSuccess
  extends AxiosMiddlewareActionSuccess<{ magic_link: string }, CloseOrderByProductsOrderID> {
  type: typeof ClientOrdersAggregateActions.CLOSE_ORDER_BY_PRODUCTS_ORDER_ID_SUCCESS
}

export interface CloseOrderByProductsOrderIDFail extends AxiosMiddlewareActionFail<CloseOrderByProductsOrderID> {
  type: typeof ClientOrdersAggregateActions.CLOSE_ORDER_BY_PRODUCTS_ORDER_ID_FAIL
}

export function closeOrderByProductsOrderID(clientID: number, productsOrderID: number): CloseOrderByProductsOrderID {
  return {
    clientID,
    type: ClientOrdersAggregateActions.CLOSE_ORDER_BY_PRODUCTS_ORDER_ID,
    payload: {
      request: {
        url: `/v1/orders/products/${productsOrderID}/complete`,
        method: 'PATCH',
      },
    },
  }
}

export type ClientOrdersAggregateAction =
  | FetchClientOrders
  | FetchClientOrdersSuccess
  | FetchClientOrdersFail
  | FetchUserAggregatedOrdersSuccess
  | FetchUserAggregatedOrders
  | FetchUserAggregatedOrdersFail
  | GoToPageOfClientOrders
  | SetViewParams
  | CloseOrderByProductsOrderID
  | CloseOrderByProductsOrderIDSuccess
  | CloseOrderByProductsOrderIDFail
