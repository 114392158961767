const styles = {
  breakpoint: '768px',
  fontSizeSm: '.75rem',
  fontSize: '.9rem',
  spaceXs: '.9rem',
  spaceSm: '1.25rem', // 20px
  spaceMd: '3.125rem', // 50px
  spaceLd: '6.25rem', // 100px
  buttonWidth: '11.625rem',
  buttonHeight: '2.25rem',
  height: '44.19rem',

  color: {
    time: '#ed3572',
    cta: '#1ba4f1',
    ctaHover: '#038cd9',
    base: '#333f57',
    external: '#f09300',
    externalRGB: '#FCF6ED',
    busy: '#8400f0',
    busyRGB: '#e7d7fc',
    calls: '#0da1ec',
    callsRGB: '#EDF5FC',
    draft: '#ec0d98',
    draftRGB: '#fcedf6',
    unavailable: '#EEE',
    borders: '#DDD',
    blank: '#FEFEFE',
    order: '#ec4e38',
    joinHover: '#ef6653',
    sendButton: '#f5978b',
    darkBlue: '#42516d',
    legendBorder: '#d8d8d8',
    sendHover: 'rgba(236,78,56,0.1)',
    sendActive: 'rgba(236,78,56,0.5)',
  },
}
export default styles
