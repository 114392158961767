import produce from 'immer'

import { initialState, IncentivesState } from './types'
import { IncentiveActionTypes, IncentiveAction } from './actions'
import { MessageActionTypes } from '../messages/actions'

export default function incentivesReducer(
  state: IncentivesState = initialState,
  action: IncentiveAction
): IncentivesState {
  return produce(state, draft => {
    switch (action.type) {
      case IncentiveActionTypes.FETCH_TIME_BASED_INCENTIVES_SUCCESS:
        const { clientID } = action.meta.previousAction
        const timeBasedIncentives = action.payload.data

        if (!timeBasedIncentives || !timeBasedIncentives.length) {
          delete draft.client[clientID]
        } else {
          draft.client[clientID] = timeBasedIncentives
        }
        break

      // On first draft send, we need to clear out the incentives
      case MessageActionTypes.SEND_MESSAGE_SUCCESS: {
        const { context, contextID } = action.meta.previousAction
        const response = action.payload.data

        if (context !== 'client') {
          return
        }

        if (state.client[contextID] && response.event_data.is_first_draft) {
          delete draft.client[contextID]
        }

        break
      }
    }
  })
}
