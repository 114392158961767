import React, { Dispatch, SetStateAction } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Button from '../common/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import i18n from '../../i18n'
import { Box } from '@material-ui/core'
import styles from '../../styles'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

export interface YesNoDialogProps {
  isOpen: boolean
  handleYes: (event: React.SyntheticEvent<EventTarget>) => void
  handleNo: (event: React.SyntheticEvent<EventTarget>) => void
  title: string
  body?: string
  yesMessageKey?: string
  noMessageKey?: string
  showCloseIcon?: boolean
  setIsOpen?: Dispatch<SetStateAction<boolean>>
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '500px',
      padding: '1.5em',
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '0 2em 0 0',
      marginBottom: '1em',
    },
    modalTitle: {
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontSize: '1.25em',
      lineHeight: 1.25,
      color: styles.palette.shadowedSelect.color,
    },
    modalBody: {
      fontSize: '.9em',
      fontWeight: 300,
      marginBottom: '1em',
      color: styles.palette.shadowedSelect.color,
    },
    buttonsRight: {
      float: 'right',
    },
    buttonsCentered: {
      justifyContent: 'space-around',
    },
    closeIcon: {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      width: '1rem',
      height: '1rem',
    },
  })
)

const YesNoDialog: React.FC<YesNoDialogProps> = ({
  isOpen,
  handleNo,
  handleYes,
  title,
  body,
  yesMessageKey,
  noMessageKey,
  showCloseIcon,
  setIsOpen,
}) => {
  const classes = useStyles()
  return (
    <Dialog open={isOpen}>
      {showCloseIcon && (
        <IconButton className={classes.closeIcon} onClick={() => (setIsOpen ? setIsOpen(false) : null)}>
          <CloseIcon />
        </IconButton>
      )}
      <Box className={classes.root}>
        <DialogTitle disableTypography={true} classes={{ root: classes.title }}>
          <Typography className={classes.modalTitle}>{title}</Typography>
        </DialogTitle>
        {!!body && <Typography className={classes.modalBody}>{body}</Typography>}
        <DialogActions className={clsx(!!body ? classes.buttonsRight : classes.buttonsCentered)}>
          <Button type="secondary" onClick={handleYes}>
            <Typography>{yesMessageKey ? <>{i18n.t(yesMessageKey)}</> : <>{i18n.t('yes')}</>}</Typography>
          </Button>
          <Button type="contrast" onClick={handleNo}>
            <Typography>{noMessageKey ? <>{i18n.t(noMessageKey)}</> : <>{i18n.t('no')}</>} </Typography>
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default YesNoDialog
