import { useCallback, useReducer } from 'react'

interface ToggleVisible {
  type: 'TOGGLE_VISIBLE'
  isVisible: boolean
}
interface InfoState {
  visible: boolean
}

type Actions = ToggleVisible

interface UseCardInfo {
  isVisible: boolean
  infoText: string
  toggle: (isVisible: boolean) => void
}

function cardInfoReducer(state: InfoState, action: Actions): InfoState {
  switch (action.type) {
    case 'TOGGLE_VISIBLE':
      state = {
        ...state,
        visible: action.isVisible,
      }
      break
  }
  return state
}

function useCardInfo(infoText: string): UseCardInfo {
  const [state, dispatch] = useReducer(cardInfoReducer, {
    visible: false,
  })

  const toggle = useCallback((visible: boolean) => dispatch({ type: 'TOGGLE_VISIBLE', isVisible: visible }), [])
  return {
    isVisible: state.visible,
    toggle,
    infoText,
  }
}

export default useCardInfo
