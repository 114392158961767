import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import classNames from 'classnames'
import DownloadIcon from '@material-ui/icons/GetApp'
import ClockIcon from '@material-ui/icons/AccessTime'
import AutoQAIcon from '@material-ui/icons/FindInPageOutlined'

import i18n from '../../i18n'
import styles from '../../styles'
import { Document, DocumentTypes, EditorDecisions, FileContexts } from '../../store/documents/types'
import ThankYouNoteIcon from '../common/Icons/ThankYouNote'
import ExtendedRevisionsIcon from '../common/Icons/ExtendedRevisions'
import LinkedInIcon from '../common/Icons/LinkedIn'
import CoverLetterIcon from '../common/Icons/CoverLetter'
import CvIcon from '../common/Icons/Cv'
import ResumeIcon from '../common/Icons/Resume'
import { formatDate } from '../../utils/formatting'
import useUserInfoState from '../common/useUserInfo'
import { GhostLink } from '../../utils/link'
import { getAutoQAResultsUrl } from '../../utils/consts'
import Button from '../common/Button'
import LightTooltip from '../common/LightTooltip'
import { getFileDownloadLink } from '../../utils/files'
import Delete from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import YesNoDialog from '../AutoLinkedin/YesNoDialog'

interface Props {
  document: Document
  onDeleteClick: (fileID: number, context: FileContexts, contextID: number) => void
  onAttemptJudgeClick?: (fileID: number, verdict: EditorDecisions) => void
  awaitingReview?: boolean
  onStartLI?: (baseDocID: number, shouldGenerate: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      border: `2px solid ${styles.palette.darkerBlue.color.hex}`,
      color: styles.palette.darkerBlue.color.hex,
      borderRadius: '10px',
      padding: '0.7rem .2rem',
    },
    icon: {
      '& .dr': { color: '#ff5050' },
      '& .dc': { color: '#2ab500' },
      '& .ds': { color: '#ff9550' },
      '& .dt': { color: '#c950ff' },
      '& .dl': { color: '#0077b5' },
      '& .dp': { color: '#0077b5' },
    },
    actions: {
      width: '85%',
      display: 'flex',
      flexDirection: 'column',

      '& > *': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginBottom: '.3rem',
      },

      '& a': {
        color: theme.palette.primary.main,
      },
    },
    filename: {
      fontWeight: 600,
    },
    actionIcon: {
      verticalAlign: 'text-bottom',
      marginRight: '0.3rem',
    },
    editorButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '90%',
      paddingTop: '.8rem',
    },
    editorButton: {
      width: '45%',
    },
    deleteIconButton: {
      justifyContent: 'unset',
      borderRadius: 'unset',
      padding: 0,
      color: theme.palette.primary.main,
      fontSize: '.98em',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
)

const renderIcon = (type: DocumentTypes) => {
  switch (type) {
    case DocumentTypes.CoverLetter:
      return <CoverLetterIcon className="dc" />
    case DocumentTypes.Resume:
      return <ResumeIcon className="dr" />
    case DocumentTypes.ThankYouLetter:
      return <ThankYouNoteIcon className="dt" />
    case DocumentTypes.Linkedin:
      return <LinkedInIcon className="dl" />
    case DocumentTypes.CV:
      return <CvIcon className="dc" />
    case DocumentTypes.FollowUpLetter:
      return <ExtendedRevisionsIcon className="dp" />
    // TODO: maybe another icon?
    case DocumentTypes.Customer:
    case DocumentTypes.None:
    default:
      return <ResumeIcon className="dr" />
  }
}

const DocumentItemV2: React.FC<Props> = ({ document, onDeleteClick, onAttemptJudgeClick, awaitingReview }) => {
  const classes = useStyles()
  const { timeZone, locale, isAdmin } = useUserInfoState()
  const downloadURL = getFileDownloadLink(document.id)
  const isEditorUpload = document.context === 'editor-client'

  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false)

  const titleTranslation = i18n.t(
    document.context === 'client' && document.type !== DocumentTypes.Customer
      ? 'doucmentItemV2__fileUploadedAt'
      : 'documentItemV2__fileReceivedAt',
    { time: formatDate(document.created_at, timeZone, locale, i18n.t('documentItemV2__uploadedAtFormat')) }
  )

  return (
    <Box className={classNames([classes.root, 'document-item', `document-item-${document.id}`])}>
      <a href={downloadURL} className={classes.icon}>
        {renderIcon(document.type)}
      </a>
      <Box className={classes.actions}>
        <Typography title={document.filename} className={classes.filename}>
          {document.filename}
        </Typography>
        <Typography component="time" dateTime={document.created_at}>
          <LightTooltip title={titleTranslation ? titleTranslation : ''}>
            <ClockIcon className={classes.actionIcon} />
          </LightTooltip>
          {formatDate(document.created_at, timeZone, locale, i18n.t('documentItemV2__uploadedAtFormat'))}
        </Typography>
        <a href={downloadURL}>
          <DownloadIcon className={classes.actionIcon} />
          {i18n.t('documentItemV2__download')}
        </a>
        {document.went_through_auto_qa && (
          <GhostLink to={getAutoQAResultsUrl(document.id)}>
            <AutoQAIcon className={classes.actionIcon} />
            {i18n.t('documentItemV2__autoQAResults')}
          </GhostLink>
        )}
        {isAdmin && (
          <IconButton
            aria-label="delete"
            onClick={() => setConfirmDeleteVisible(true)}
            disableRipple={true}
            className={classes.deleteIconButton}
          >
            <Delete className={classes.actionIcon} />
            {i18n.t('documentItemV2__delete')}
          </IconButton>
        )}
        {isEditorUpload && isAdmin && !!onAttemptJudgeClick && !!awaitingReview && (
          <Box className={classes.editorButtonsContainer}>
            <Button
              type="primary"
              className={classes.editorButton}
              onClick={() => onAttemptJudgeClick(document.id, EditorDecisions.Approve)}
            >
              {i18n.t('documents__editor_upload__decision_approve')}
            </Button>
            <Button
              type="secondary"
              className={classes.editorButton}
              onClick={() => onAttemptJudgeClick(document.id, EditorDecisions.Reject)}
            >
              {i18n.t('documents__editor_upload__decision_reject')}
            </Button>
          </Box>
        )}
      </Box>
      {isAdmin && (
        <YesNoDialog
          title={i18n.t('documents__delete__blocker')}
          isOpen={confirmDeleteVisible}
          // @ts-ignore
          handleNo={() => setConfirmDeleteVisible(false)}
          handleYes={() => {
            onDeleteClick(document.id, document.context, document.context_id)
            setConfirmDeleteVisible(false)
          }}
        />
      )}
    </Box>
  )
}

export default DocumentItemV2
