import React from 'react'
import ArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import Tooltip from '@material-ui/core/Tooltip'
import ButtonBase from '@material-ui/core/Button'

import i18n from '../../i18n'
import Button from '../../components/common/Button'
import FormatingIcon from '../../../src/assets/svgs/formating.svg'
import DocumentsIcon from '../../../src/assets/svgs/documents.svg'

export interface MessageToolboxProps {
  handleSubmit: () => void
  submitDisabled: boolean
  toolbarVisible: boolean
  setToolbarVisible: (toggle: boolean) => void
  openUploads?: [boolean, () => void]
  openClientStrategy?: [boolean, () => void]
  openMessagePreview?: () => void
  isSending: boolean
  templatesComponent?: React.ReactElement
  strategyComponent?: React.ReactElement
  proposeCallTimeComponent?: React.ReactElement
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputActionsContainer: {
      padding: '.8rem 0',
      display: 'flex',
      alignItems: 'center',
      '&.active': {
        // borderTop: '1px solid #f0f1f3',
        // padding: '0.8rem 0 0.8rem ',
      },
      '@media (max-width: 540px)': { flexDirection: 'column' },
    },
    icon: {
      cursor: 'pointer',
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column',
      },
      '& .icon-img': {
        display: 'flex',
        alignItems: 'center',
        height: '1.5rem',
        color: '#bcc9d6',
        '@media (max-width: 540px)': { display: 'none' },
      },
      '& .icon-text': {
        marginTop: '.1rem',
        fontSize: '.5625rem',
        color: '#3f3f3f',
      },
    },
    icons: {
      display: 'flex',
      alignItems: 'flex-end',
      marginLeft: '1.15625rem',
      '@media (max-width: 540px)': { marginLeft: '0', marginTop: '.5rem' },
    },
    activeIcon: {
      color: theme.palette.primary.main,
      '& img': {
        color: theme.palette.primary.main,
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      '@media (max-width: 540px)': {
        marginTop: '1rem',
      },
    },
    button: {
      fontSize: '0.9375rem',
      textTransform: 'initial',
      padding: '0 1.583rem',
      height: '2.5rem',
    },
    darkButton: {
      '&$button': {
        color: '#000',
        marginRight: '.5rem',
        borderColor: '#484444',
      },
    },
    lightButton: {
      '&$button': {
        color: '#fff',
      },
    },
    sendingSpinner: {
      marginLeft: theme.spacing(1),
    },
    preview: {
      marginLeft: '.5rem',
    },
  })
)

export const MessageToolbox: React.FC<MessageToolboxProps> = ({
  handleSubmit,
  submitDisabled,
  toolbarVisible,
  setToolbarVisible,
  openUploads = [false, () => {}],
  openMessagePreview = () => {},
  isSending,
  templatesComponent,
  strategyComponent,
  proposeCallTimeComponent,
}) => {
  const classes = useStyles()
  const [uploadVisible, triggerUpload] = openUploads
  // @TODO Do we want to have a state around client strategy being pre-opened?
  // const [clientStrategyVisible, triggerClientStrategy] = openClientStrategy

  return (
    <Box className={classNames({ active: !submitDisabled, [classes.inputActionsContainer]: true })}>
      <div className={classes.buttons}>
        <Button
          className={classNames({ sending: isSending })}
          type={!submitDisabled ? 'primary' : 'secondary'}
          onClick={handleSubmit}
          disabled={submitDisabled}
          id="message-toolbox-send-message"
          showLoader={isSending}
        >
          {i18n.t('send')}
          {!isSending && <ArrowRight />}
        </Button>

        <Button
          className={classes.preview}
          type="secondary"
          disabled={submitDisabled}
          onClick={openMessagePreview}
          id="message-toolbox-open-preview"
        >
          {i18n.t('preview')}
        </Button>
      </div>
      <div className={classes.icons}>
        <Tooltip title={i18n.t('messaging__tooltip__openFormatting') || 'Open Formatting'}>
          <ButtonBase
            id="message-toolbox-open-toolbar"
            onClick={() => setToolbarVisible(!toolbarVisible)}
            className={classNames({
              [classes.icon]: true,
              [classes.activeIcon]: toolbarVisible,
            })}
          >
            <Box className="icon-img">
              <img alt="" src={FormatingIcon} />
            </Box>
            <Typography display="block" variant={'body2'} className="icon-text">
              {i18n.t('messaging__tool__formatting')}
            </Typography>
          </ButtonBase>
        </Tooltip>
        <Tooltip title={i18n.t('messaging__tooltip__addAttachments') || 'Add Attachments'}>
          <ButtonBase
            id="message-toolbox-attach-file"
            onClick={triggerUpload}
            className={classNames({
              [classes.icon]: true,
              [classes.activeIcon]: uploadVisible,
            })}
          >
            <Box className="icon-img">
              <img alt="" src={DocumentsIcon} />
            </Box>
            <Typography display="block" variant={'body2'} className="icon-text">
              {i18n.t('messaging__tool__documents')}
            </Typography>
          </ButtonBase>
        </Tooltip>
        {templatesComponent}
        {strategyComponent}
        {proposeCallTimeComponent}
      </div>
    </Box>
  )
}

const memoizedMessageToolbox = React.memo(MessageToolbox)
// @ts-ignore
memoizedMessageToolbox.whyDidYouRender = true

export default memoizedMessageToolbox
