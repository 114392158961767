import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store'
import { DirectSchedulingMenu } from '../../selectors/items'
import React, { useCallback, useState } from 'react'
import ProposeCallTime from './ProposeCallTime'
import useUserInfoState from '../common/useUserInfo'
import { HandlebarsContextType } from '../../utils/handlebars'
import { handlebarsTemplateContextSelector } from '../../selectors/templates'
import { MessageContext } from '../../store/messages/types'
import { itemsForDirectScheduling } from '../../selectors/orders'
import { applyDirectSchedulingThunk } from '../../store/templates/actions'
import MessagePreview from './MessagePreview'
import { CurrentMessage } from '../../store/currentMessage/types'
import { currentMessageReset, currentMessageUpdate } from '../../store/currentMessage/actions'

interface ProposeCallTimeContainerProps {
  context: MessageContext
  contextID: number
  orderID: number | null
  showEmailPreview: () => void
  sendMessage: () => void
}

export const ProposeCallTimeContainer: React.FC<ProposeCallTimeContainerProps> = ({
  orderID,
  context,
  contextID,
  sendMessage,
}) => {
  const [showEmailPreview, setShowEmailPreview] = useState(false)

  const { timeZone, locale, id, isAdmin } = useUserInfoState()

  const currentMessage = useSelector<AppState, CurrentMessage>(state => state.currentMessageReducer)

  const items = useSelector<AppState, DirectSchedulingMenu[]>(state =>
    itemsForDirectScheduling(state.clientReducer, state.itemsReducer, state.orderReducer, contextID, isAdmin)
  )

  const handlebarsContext = useSelector<AppState, HandlebarsContextType>(state =>
    handlebarsTemplateContextSelector(state, context, contextID, id, [], orderID)
  )

  const dispatch = useDispatch()

  const updateScheduling = useCallback(
    async (selectedDate: Date, selectedItem: DirectSchedulingMenu, customerNote: string) => {
      await dispatch(applyDirectSchedulingThunk(selectedDate, selectedItem, customerNote, context, contextID, id))
    },
    /* eslint-disable-next-line */
    [applyDirectSchedulingThunk, context, contextID, id]
  )

  const cleanUp = useCallback(() => {
    dispatch(currentMessageReset())
    dispatch(
      currentMessageUpdate({
        direct_scheduling: undefined,
      })
    )
    /* eslint-disable-next-line */
  }, [currentMessageReset, currentMessageUpdate])

  if (items.length === 0) {
    return null
  }
  return (
    <>
      {showEmailPreview && (
        <MessagePreview
          message={currentMessage}
          handlebarsContext={handlebarsContext}
          closeWindow={() => setShowEmailPreview(false)}
        />
      )}
      <ProposeCallTime
        showEmailPreview={() => setShowEmailPreview(true)}
        updateScheduling={updateScheduling}
        cleanUp={cleanUp}
        items={items}
        userLocale={locale}
        userTimezone={timeZone}
        sendMessage={sendMessage}
      />
    </>
  )
}
