import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import { GetEventsResponse, UserEvent, VulcanEvent, VulcanEventContext, VulcanEventPayload } from './types'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AppState } from '../index'
import { AnyAction } from 'redux'
import { fetchClientOrderDetailsThunk } from '../clientOrderAggregate/actions'
import { userId } from '../user/reducers'
import { PaginationArgs } from '../../utils/pagination'
import { addNudge, fetchLatestClientMessages, NewOrdersNudgeTriggerKey } from '../nudges/actions'
import { NewOrderNudgeTrigger, NudgeTypes, NudgeVariables } from '../nudges/types'
import { WIPSegments } from '../nudges/reducer'
import { Routes } from '../../utils/consts'
import { addMinutes } from 'date-fns'
import { ResettableInterval } from '../cron/types'

export enum EventActions {
  POST_EVENT = 'POST_EVENT',
  POST_EVENT_SUCCESS = 'POST_EVENT_SUCCESS',
  POST_EVENT_FAIL = 'POST_EVENT_FAIL',

  FETCH_USER_EVENTS = 'FETCH_USER_EVENTS',
  FETCH_USER_EVENTS_SUCCESS = 'FETCH_USER_EVENTS_SUCCESS',
  FETCH_USER_EVENTS_FAIL = 'FETCH_USER_EVENTS_FAIL',

  GO_TO_PAGE = 'GO_TO_PAGE',
}

export interface GoToPage {
  type: typeof EventActions.GO_TO_PAGE
  page: number
}

export interface PostEvent extends AxiosMiddlewareActionCreator {
  type: typeof EventActions.POST_EVENT
  event: VulcanEventPayload
}

export interface PostEventSuccess extends AxiosMiddlewareActionSuccess<VulcanEvent, PostEvent> {
  type: typeof EventActions.POST_EVENT_SUCCESS
}

export interface PostEventFail extends AxiosMiddlewareActionFail<PostEvent> {
  type: typeof EventActions.POST_EVENT_FAIL
}

export interface FetchUserEvents extends AxiosMiddlewareActionCreator {
  userID: userId
  filter: PaginationArgs
  type: typeof EventActions.FETCH_USER_EVENTS
}

export interface FetchUserEventsSuccess extends AxiosMiddlewareActionSuccess<GetEventsResponse, FetchUserEvents> {
  type: typeof EventActions.FETCH_USER_EVENTS_SUCCESS
}

export interface FetchUserEventsFail extends AxiosMiddlewareActionFail<FetchUserEvents> {
  type: typeof EventActions.FETCH_USER_EVENTS_FAIL
}

export function postEvent(event: VulcanEventPayload): PostEvent {
  return {
    type: EventActions.POST_EVENT,
    event: event,
    payload: {
      request: {
        data: event,
        method: 'POST',
        url: '/v1/events',
      },
    },
  }
}
export function getUserEvents(userID: userId, filter: PaginationArgs): FetchUserEvents {
  return {
    type: EventActions.FETCH_USER_EVENTS,
    userID: userID,
    filter,
    payload: {
      request: {
        url: `/v1/dashboard/${userID}/events`,
        params: filter,
      },
    },
  }
}

export function sendEventThunk(event: VulcanEventPayload): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>) {
    await dispatch(postEvent(event))

    if (event.context === VulcanEventContext.Client) {
      const clientID = event.context_id
      // @ts-ignore
      await dispatch(fetchClientOrderDetailsThunk(clientID, { verbose: true }))
    }
  }
}

export function sendUserEvent(
  event: UserEvent,
  userID?: number | null
): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    if (!userID) {
      userID = getState().userReducer.loggedInUserID
    }

    if (userID) {
      dispatch(
        postEvent({
          context: VulcanEventContext.User,
          context_id: userID,
          origin: 'oms-user',
          ...event,
        })
      )
    }
  }
}

export function getUserEventsTimed(
  interval: number
): ThunkAction<Promise<ResettableInterval>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    const pageSize = getState().eventsReducer.pagination.page_size
    const loggedInUserID = getState().userReducer.loggedInUserID
    const userID = !!loggedInUserID ? loggedInUserID : 'me'
    dispatch(getUserEvents(userID, { page: 1, page_size: pageSize }))
    const intervalID = window.setInterval(() => {
      dispatch(getUserEvents(userID, { page: 1, page_size: pageSize }))
    }, interval)

    return {
      intervalID,
      interval,
    }
  }
}

export function getLatestClientMessagesTimed(
  interval: number
): ThunkAction<Promise<ResettableInterval>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    const intervalID = window.setInterval(() => {
      const messageReceived = getState().nudgesReducer[NudgeTypes.MessageReceived]
      const sinceWhen = !!messageReceived.lastPoll ? messageReceived.lastPoll : addMinutes(new Date(), -2)
      if (!messageReceived.nudges || messageReceived.nudges.length === 0) {
        dispatch(fetchLatestClientMessages(sinceWhen, 'me'))
      }
    }, interval)

    return {
      intervalID,
      interval,
    }
  }
}

const noNudgePages = [Routes.NewOrder, Routes.AssignmentPreferences, Routes.Account]

export function getNewOrdersNudge(interval: number): ThunkAction<Promise<ResettableInterval>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    // when switching to another user via ghosting, reset the trigger
    const trigger = localStorage.getItem(NewOrdersNudgeTriggerKey)
    if (!!trigger) {
      const typedTrigger = JSON.parse(trigger) as NewOrderNudgeTrigger
      if (typedTrigger.userID !== getState().userReducer.loggedInUserID) {
        localStorage.removeItem(NewOrdersNudgeTriggerKey)
      }
    }
    const intervalID = window.setInterval(() => {
      const trigger = localStorage.getItem(NewOrdersNudgeTriggerKey)
      const newOrdersNudge = getState().nudgesReducer[NudgeTypes.NewOrders]
      if (!!trigger && !newOrdersNudge) {
        const typedTrigger = JSON.parse(trigger) as NewOrderNudgeTrigger
        if (
          // @ts-ignore
          Date.parse(typedTrigger.nextTrigger) < new Date() &&
          noNudgePages.every(p => !window.location.pathname.includes(p))
        ) {
          const eligibility = getState().offerReducer.shoppingEligibility

          const variables: NudgeVariables = {
            wipSegment: typedTrigger.wipSegment,
            wipLimit: eligibility?.wip_limit,
            worksInProgress: eligibility?.works_in_progress,
          }

          if (eligibility?.eligible_for_offers) {
            variables.link = Routes.NewOrder
          }

          dispatch(
            addNudge({
              type: NudgeTypes.NewOrders,
              copyKey: typedTrigger.wipSegment === WIPSegments.Reached ? 'nudge__send_draft' : 'nudge__new_orders',
              trigger: typedTrigger,
              variables,
            })
          )
        }
      }
    }, interval)

    return {
      intervalID,
      interval,
    }
  }
}

export type EventsActionTypes = FetchUserEvents | FetchUserEventsSuccess | FetchUserEventsFail | GoToPage
