import React from 'react'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import CardHeader from '@material-ui/core/CardHeader'
import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import enUS from 'date-fns/locale/en-US'
import enGB from 'date-fns/locale/en-GB'
import isEqual from 'lodash/isEqual'

import { getGhostID } from '../../utils/link'
import i18n from '../../i18n'
import { PayHistory } from '../../store/dashboard/types'
import { formatDate, formatCurrency, Currency } from '../../utils/formatting'
import DashboardStyles from './Styles'

interface PaymentHistoryProps {
  userID: number
  paymentRows: PayHistory[] | null
  userTimezone: string
  currency: Currency
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      color: 'inherit',
      fontSize: 'inherit',
      minWidth: 'initial',
      padding: '0',
      position: 'absolute',
      right: '8px',
      top: '8px',
    },
    action: {
      position: 'relative',
    },
    cardContent: {
      height: '100%',
      padding: '0',
    },
    cardHeader: {
      padding: '0',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    header: {
      width: '100%',
    },
    paymentHistoryCard: {
      backgroundColor: DashboardStyles.card.bg,
      border: DashboardStyles.card.border,
      boxShadow: DashboardStyles.card.shadow,
      height: '100%',
      padding: DashboardStyles.card.padding,
    },
    table: {
      '& td': {
        borderBottom: DashboardStyles.row.border,
        color: 'inherit',
        fontSize: 'inherit',
        padding: DashboardStyles.row.paddingInner,
        paddingRight: '1em',
      },
      '& th': {
        borderBottom: 'solid 1px #dbe0eb',
        color: 'inherit',
        fontSize: 'inherit',
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: '#fff',
      },
      '& thead': {
        '& th': {
          fontWeight: 'bold',
        },
      },
      '& tr': {
        '&:last-of-type': {
          '& td': {
            borderBottom: 'none',
          },
        },
      },
    },
    tableBox: {
      color: 'inherit',
      height: '100%',
    },
    tableContainer: {
      height: 'calc(100% - 2em)',
      marginTop: '2em',
      overflow: 'auto',
      width: '100%',
    },
    title: {
      color: DashboardStyles.header4.color,
      fontSize: DashboardStyles.header4.size,
      textTransform: 'uppercase',
    },
  })
)

const getFileDownloadLink = (userID: number, date?: string) => {
  const ghostID = getGhostID()

  let link = `/api/v1/dashboard/${userID}/earnings-csv`
  if (!!ghostID) {
    link += `?ghost=${ghostID}`
  }
  if (!ghostID && !!date) {
    link += `?date=${date}`
  } else if (!!ghostID && !!date) {
    link += `&date=${date}`
  }
  return link
}

function createRowDescription(row: PayHistory): string {
  if (!row) {
    return '-'
  }

  const desc: string[] = []

  if (row.normal_orders && row.normal_orders > 0) {
    desc.push(i18n.t('dashboard__payhist__orders', { count: row.normal_orders }))
  }

  if (row.bonus_orders && row.bonus_orders > 0) {
    if (desc.length > 0) {
      desc.push(' + ')
    }

    desc.push(i18n.t('dashboard__payhist__bonus__orders', { count: row.bonus_orders }))
  }

  return desc.length === 0 ? '-' : desc.join('')
}

export const PaymentHistoryCard: React.FC<PaymentHistoryProps> = ({ userID, paymentRows, userTimezone, currency }) => {
  const classes = useStyles()
  const locale = userTimezone.includes('Europe') ? enGB : enUS
  const filteredPaymentRows = !paymentRows ? [] : paymentRows.filter(pr => pr.amount > 0)

  return (
    <Box className={classes.paymentHistoryCard} flexGrow="1" id="dashboard-payment-history">
      <Card className={classes.tableBox}>
        <CardHeader
          action={
            <Button className={classes.button} id="button-download-earnings-csv" href={getFileDownloadLink(userID)}>
              <GetAppIcon />
            </Button>
          }
          className={classes.cardHeader}
          classes={{
            action: classes.action,
          }}
          title={
            <Typography variant="h3" className={classes.title}>
              {i18n.t('dashboard__payhist__title')}
            </Typography>
          }
        />
        <CardContent className={classes.cardContent}>
          <Box className={classes.tableContainer}>
            <Table aria-label="payment history table" stickyHeader className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left" component="th">
                    {i18n.t('dashboard__payhist__header__date')}
                  </TableCell>
                  <TableCell align="left" component="th">
                    {i18n.t('dashboard__payhist__header__items')}
                  </TableCell>
                  <TableCell align="left" component="th">
                    {i18n.t('dashboard__payhist__header__amount')}
                  </TableCell>
                  <TableCell align="left" component="th">
                    {i18n.t('dashboard__payhist__header__details')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPaymentRows.length > 0 &&
                  filteredPaymentRows.map(row => {
                    return (
                      <TableRow key={row.pay_date}>
                        <TableCell scope="row">
                          {!!row.pay_date && row.pay_date !== '-' ? (
                            <time dateTime={row.pay_date}>
                              {formatDate(
                                row.pay_date,
                                userTimezone,
                                locale,
                                i18n.t('account__payment__payment_date_format')
                              )}
                            </time>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell>{!!row ? createRowDescription(row) : '-'}</TableCell>
                        <TableCell>{!!row.amount ? formatCurrency(row.amount, currency) : '-'}</TableCell>
                        <TableCell align="center">
                          <Button href={getFileDownloadLink(userID, row.pay_date)}>
                            <GetAppIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}

                {filteredPaymentRows.length === 0 && (
                  <TableRow>
                    <TableCell align="right" scope="row">
                      -
                    </TableCell>
                    <TableCell scope="row" align="right">
                      -
                    </TableCell>
                    <TableCell scope="row" align="right">
                      -
                    </TableCell>
                    <TableCell scope="row" align="right">
                      -
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

const memoizedPaymentHistoryCard = React.memo(PaymentHistoryCard, isEqual)
// @ts-ignore
memoizedPaymentHistoryCard.whyDidYouRender = true

export default memoizedPaymentHistoryCard
