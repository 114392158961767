import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import styles from '../../styles'
import UploadsV3Styles from './styles'
import isEqual from 'lodash/isEqual'
import BoolFeatureFeedback from './BoolFeatureFeedback'
import { FeedbackBoolPayload } from '../../store/featureFeedback/types'
import { HashMap } from '../../utils/HashMap'

export interface AutoQAResultBit {
  header?: string
  text: string
  leftTabulated?: boolean
}

export interface AutoQAResultProps {
  feature_key: string
  feature_id?: number
  title?: string
  content: AutoQAResultBit[]
  updateFeedback?: (payload: FeedbackBoolPayload) => void
  feedback?: HashMap<HashMap<FeedbackBoolPayload>>
  withFeedback?: boolean
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      backgroundColor: 'white',
      borderRadius: UploadsV3Styles.autoQAResults.group.borderRadius,
      boxShadow: UploadsV3Styles.autoQAResults.group.boxShadow,
      padding: '1.875rem',
    },
    itemTitle: {
      fontWeight: styles.fonts.primary.weights.semiBold,
      fontSize: '.75rem',
      textTransform: 'uppercase',
      paddingBottom: '1.25rem',
    },
    groupTitle: {
      fontWeight: styles.fonts.primary.weights.semiBold,
      fontSize: '1.2rem',
    },
    contentRoot: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    ctaRoot: {
      display: 'flex',
      width: '20%',
      justifyContent: 'space-between',
    },
    itemRoot: {
      width: '70%',
      '& > :not(:last-child)': {
        marginBottom: '1.875rem',
      },
    },
    buttonsRoot: {
      width: '30%',
    },
    text: {
      fontSize: '1.1rem',
    },
    leftTabulated: {
      borderLeft: 'solid .175rem #333f57',
      borderSpacing: '2rem',
      paddingLeft: '1rem',
    },
  })
)

const AutoQAResult: React.FC<AutoQAResultProps> = ({
  feedback,
  updateFeedback,
  withFeedback,
  title,
  content,
  feature_id,
  feature_key,
}) => {
  const classes = useStyles()

  const initialValue = feature_id && feedback && feedback[feature_id] && feedback[feature_id][feature_key]

  return (
    <Box
      className={classNames({
        [classes.root]: true,
      })}
    >
      <Typography display={'block'} className={classes.groupTitle}>
        {title}
      </Typography>
      <Box className={classes.contentRoot}>
        <Box className={classes.itemRoot}>
          {content.map((result, i) => (
            <Box key={`result-${i}`}>
              <Typography display={'block'} className={classes.itemTitle}>
                {result.header}
              </Typography>
              <Box
                className={classNames({
                  [classes.leftTabulated]: result.leftTabulated,
                  [classes.text]: true,
                })}
              >
                {result.text}
              </Box>
            </Box>
          ))}
        </Box>
        {withFeedback && updateFeedback && feature_id && (
          <Box className={classes.buttonsRoot}>
            <BoolFeatureFeedback
              initialValue={!!initialValue ? initialValue.feedback_bool : null}
              feedback_key={'needs_editing'}
              feature_id={feature_id}
              feature_element={feature_key}
              updateResultFeedback={updateFeedback}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

const memoizedAutoQAResult = React.memo(AutoQAResult, isEqual)
// @ts-ignore
memoizedAutoQAResult.whyDidYouRender = true

export default memoizedAutoQAResult
