import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import Button from '../common/Button'
import i18n from '../../i18n'
import styles from '../../styles'
import { GhostLink } from '../../utils/link'
import { Routes } from '../../utils/consts'

interface OfferExpiredProps {
  onRequestNewOrderClick: () => void
  onCloseClick: () => void
  isLoading: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    offerExpired: {
      color: styles.palette.lightGrey.color,
      textAlign: 'center',
    },
    headline: {
      fontSize: '2.25rem',
      color: styles.palette.lightBlack.color,
      marginBottom: '2.125rem',
    },
    bodyText: {
      color: styles.palette.lightBlack.color,
      marginBottom: '3.1125rem',
      padding: '0 9.5rem',
      '@media (max-width: 900px)': {
        padding: '0 4rem',
      },
      '@media (max-width: 768px)': {
        padding: 0,
      },
    },
    buttons: {
      '& .leftButton': { marginRight: '2.1875rem' },
      '@media (max-width: 620px)': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& .leftButton': { marginRight: 0, marginBottom: '1rem' },
      },
    },
    closeContainer: {
      position: 'absolute',
      right: 0,
      top: 0,
      padding: '.5rem',
      '& .icon': {
        padding: '.1rem',
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.54)',
        color: '#fff',
        fontSize: '1rem',
      },
    },
    spinner: {
      marginLeft: theme.spacing(3),
    },
  })
)

const OfferExpired: React.FC<OfferExpiredProps> = ({ onRequestNewOrderClick, isLoading, onCloseClick }) => {
  const classes = useStyles()

  useEffect(() => {
    return onCloseClick
  }, [onCloseClick])

  return (
    <Box className={classes.offerExpired}>
      <IconButton className={classes.closeContainer}>
        <GhostLink to={Routes.OpenOrders}>
          <CloseIcon className="icon" />
        </GhostLink>
      </IconButton>
      <Typography display="block" className={classes.headline}>
        {i18n.t('order__shopping__offer__expired__headline')}
      </Typography>
      <Typography display="block" variant={'body1'} className={classes.bodyText}>
        {i18n.t('order__shopping__offer__expired__bodytext')}
      </Typography>
      <Box className={classes.buttons}>
        <Button
          className="leftButton"
          type={'primary'}
          size={'medium'}
          onClick={onRequestNewOrderClick}
          showLoader={isLoading}
        >
          {i18n.t('order__shopping__request__new__order')}
        </Button>
      </Box>
    </Box>
  )
}

export default OfferExpired
