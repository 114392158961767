import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import ThankYouNoteIcon from '../common/Icons/ThankYouNote'
import ExtendedRevisionsIcon from '../common/Icons/ExtendedRevisions'
import LinkedInIcon from '../common/Icons/LinkedIn'
import CoverLetterIcon from '../common/Icons/CoverLetter'
import CvIcon from '../common/Icons/Cv'
import ResumeIcon from '../common/Icons/Resume'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import classNames from 'classnames'

import { Document, DocumentTypes } from '../../store/documents/types'
import { getGhostID } from '../../utils/link'

export enum DocumentItemVariants {
  Download = 'document-item-download',
  Closeable = 'document-item-closeable',
  HalfHeight = 'document-size-half-height',
}

export type DocumentItemVariant =
  | DocumentItemVariants.Download
  | DocumentItemVariants.Closeable
  | DocumentItemVariants.HalfHeight

export interface DocumentItemProps {
  document: Document
  variant?: DocumentItemVariant | DocumentItemVariant[]
  tooltip?: React.ReactChild | string
  handleClick?: (attachmentID: number) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      height: '2.625rem',
      '&:not(:last-child)': { marginBottom: '.625rem' },
      '&:last-child': { marginBottom: '.9375rem' },
      '& .fileContainer': {
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: '100%',
        padding: '.3125rem',
        border: '1px solid #d8dbe1',
        borderRadius: '.25rem',
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        '&:hover': {
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 2,
          '& .fileName': { whiteSpace: 'normal', wordBreak: 'break-all' },
          '& .tooltip': { display: 'flex' },
        },
        '& .fileName': {
          flexGrow: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          opacity: 0.5,
          color: '#2e2e2e',
          fontSize: '.8125rem',
          fontWeight: 500,
          lineHeight: '1.125rem',
        },
        '& .fileIcon': {
          position: 'relative',
          width: '1.875rem',
          minWidth: '1.875rem',
          height: '1.875rem',
          marginRight: '.3125rem',
          '& > :first-child': {
            position: 'absolute',
            left: '-.3125rem',
            top: '-.3125rem',
            color: '#d3d3d3', // @TODO Use when no type specified
          },
          '& .dr': { color: '#ff5050' },
          '& .dc': { color: '#2ab500' },
          '& .ds': { color: '#ff9550' },
          '& .dt': { color: '#c950ff' },
          '& .dl': { color: '#0077b5' },
          '& .dp': { color: '#0077b5' },
        },
        '& .deleteIcon': {
          marginLeft: '.3125rem',
          color: '#d8d8d8',
          cursor: 'pointer',
          '&:hover': { color: '#6E6E6E' },
        },
        '& .tooltip': {
          display: 'none',
          position: 'absolute',
          zIndex: 3,
          padding: '.4rem',
          backgroundColor: '#fff',
          borderRadius: '.25rem',
          whiteSpace: 'nowrap',
          color: '#000',
          left: 'calc(100% + .5rem)',
          top: '50%',
          transform: 'translateY(-50%)',
          '@media (max-width: 1350px)': {
            left: '50%',
            top: '-.5rem',
            transform: 'translateY(-100%) translateX(-50%)',
          },
        },
      },
    },
    // @TODO (e-pro): These are the styles for all the variants of this button. They can be combined in pretty much any
    // way so don't marry yourself to one variant.
    [DocumentItemVariants.Download]: {
      cursor: 'pointer',
    },
    [DocumentItemVariants.Closeable]: {},
    [DocumentItemVariants.HalfHeight]: {
      height: '1.875rem',
      '& .fileContainer': {
        padding: '.1875rem .3125rem',
        '& .fileName': {},
        '& .fileIcon': {
          width: '1.375rem',
          minWidth: '1.375rem',
          height: '1.375rem',
          '& > :first-child': {
            width: '1.875rem',
            height: '1.875rem',
            left: '-.25rem',
            top: '-.25rem',
          },
        },
      },
    },
  })
)

const getFileDownloadLink = (fileID: number) => {
  const ghostID = getGhostID()
  let link = `/api/v1/files/id/${fileID}`
  if (!!ghostID) {
    link += `?ghost=${ghostID}`
  }
  return link
}

const variantIsApplied = (
  selectedVariants: DocumentItemVariant | DocumentItemVariant[] | undefined,
  targetVariant: DocumentItemVariant
): boolean => {
  if (Array.isArray(selectedVariants)) {
    return selectedVariants.includes(targetVariant)
  }

  return selectedVariants === targetVariant
}

export const DocumentItem: React.FC<DocumentItemProps> = ({
  document,
  variant,
  tooltip,
  handleClick = (attachmentID: number) => {},
}) => {
  const classes = useStyles()

  const renderIcon = (type: DocumentTypes) => {
    switch (type) {
      case DocumentTypes.CoverLetter:
        return <CoverLetterIcon className="dc" />
      case DocumentTypes.Resume:
        return <ResumeIcon className="dr" />
      case DocumentTypes.ThankYouLetter:
        return <ThankYouNoteIcon className="dt" />
      case DocumentTypes.Linkedin:
        return <LinkedInIcon className="dl" />
      case DocumentTypes.CV:
        return <CvIcon className="dc" />
      case DocumentTypes.FollowUpLetter:
        return <ExtendedRevisionsIcon className="dp" />
      // TODO: maybe another icon?
      case DocumentTypes.Customer:
      case DocumentTypes.None:
      default:
        return <ResumeIcon className="dr" />
    }
  }

  const item = (
    <Box
      onClick={() => handleClick(document.id)}
      className={classNames({
        'document-item': true,
        [`document-item-${document.id}`]: true,
        [classes.root]: true,
        // Add all the classes for the DocumentItemVariants
        ...Object.values(DocumentItemVariants).reduce((acc, variantClass: DocumentItemVariant) => {
          // @ts-ignore
          acc[classes[variantClass]] = variantIsApplied(variant, variantClass)
          return acc
        }, {}),
      })}
    >
      <Box className="fileContainer">
        <Box className="fileIcon">{renderIcon(document.type)}</Box>
        <Typography variant={'body2'} className="fileName">
          {document.filename}
        </Typography>
        {variantIsApplied(variant, DocumentItemVariants.Closeable) && (
          <DeleteForeverOutlinedIcon className="deleteIcon" />
        )}
        {tooltip && <Box className="tooltip">{tooltip}</Box>}
      </Box>
    </Box>
  )

  if (variantIsApplied(variant, DocumentItemVariants.Download)) {
    return (
      <a href={getFileDownloadLink(document.id)} className="file-download-link">
        {item}
      </a>
    )
  }

  return item
}

export default DocumentItem
