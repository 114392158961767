import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'

import i18n from '../../../i18n'
import styles from '../../../styles'
import * as critiqueTypes from '../../../store/critique/types'
import { HashMap } from '../../../utils/HashMap'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontSize: '1.3125rem',
      fontWeight: 'bold',
    },
    reportItemTitle: {
      margin: '1rem 0',
      color: styles.palette.lightBlack.color,
      fontWeight: 'bold',
    },
    reportItem: {
      color: '#5b5b64',
    },
    hr: {
      width: '100%',
      height: '1px',
      margin: '1rem 0',
      background: '#d7dbe1',
    },
    list: {
      listStyle: 'disc',
      '& ul, ol': {
        paddingLeft: '1em',
      },
    },
  })
)

interface DigitalAnalysisProps {
  industries: critiqueTypes.Industry[]
  competencies: string[]
  critiqueResults: HashMap<critiqueTypes.StructuredCritiqueAnswer[]>
}

const DigitalAnalysis: React.FC<DigitalAnalysisProps> = ({ industries, competencies, critiqueResults }) => {
  const classes = useStyles()

  const renderCritiqueResult = (data: HashMap<critiqueTypes.StructuredCritiqueAnswer[]>) => {
    return Object.entries(data).map(([key, results]) => (
      <Box key={key}>
        <Typography variant={'body1'} className={classes.reportItemTitle}>
          {i18n.t(`digital__analysis__title__${key}`)}
        </Typography>
        <ul className={classes.list}>
          {results.map((result, i) => {
            return (
              <Typography variant={'body2'} className={classes.reportItem} key={i} component={'li'}>
                <>
                  {i18n.t(`digital__analysis__critique__${result.key}`)}
                  {result.value.length > 0 && (
                    <ul className={classes.list}>
                      {result.value.map((r, k) => (
                        <Typography variant={'body2'} className={classes.reportItem} key={k} component={'li'}>
                          {r}
                        </Typography>
                      ))}
                    </ul>
                  )}
                </>
              </Typography>
            )
          })}
        </ul>
      </Box>
    ))
  }

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{i18n.t('order__page__client__details__ats__report')}</Typography>
      {industries.length > 0 && (
        <>
          <Typography variant={'body1'} className={classes.reportItemTitle}>
            {i18n.t('digital__analysis__title__industry')}
          </Typography>
          <ul className={classes.list}>
            {industries.map((industry: critiqueTypes.Industry, i: number) => (
              <Typography variant={'body2'} className={classes.reportItem} key={i} component={'li'}>
                {i18n.t('digital__analysis__industry__confidence__format', {
                  industry: industry.name,
                  percent: industry.percent_of_overall,
                })}
              </Typography>
            ))}
          </ul>
        </>
      )}

      {competencies.length > 0 && (
        <Box>
          <Typography variant={'body1'} className={classes.reportItemTitle}>
            {i18n.t('digital__analysis__title__keywords')}
          </Typography>
          <Typography
            variant={'body2'}
            className={classNames({
              [classes.reportItem]: true,
            })}
          >
            {competencies.map(c => c.trim()).join(', ')}
          </Typography>
        </Box>
      )}

      {(industries.length > 0 || competencies.length > 0) && <hr className={classes.hr} />}

      <Box>
        <Typography className={classes.title}>{i18n.t('order__page__client__details__critique__results')}</Typography>
        {renderCritiqueResult(critiqueResults)}
      </Box>
    </Box>
  )
}

export default DigitalAnalysis
