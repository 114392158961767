import { AutoLinkdeinState } from './types'
import { AUTOLINKEDIN, AutoLinkedinActionTypes } from './actions'
import produce from 'immer'
import { initialLoadedLoadingErrorState, setErrorState, setStartState, setSuccessState } from '../../utils/state'
import { ClientOrdersAggregateActions } from '../clientOrderAggregate/actions'

export const initialState: AutoLinkdeinState = {
  byItemID: {},
  revisions: {},
}

const metaInitState = {
  generateMeta: { ...initialLoadedLoadingErrorState },
  getMeta: { ...initialLoadedLoadingErrorState },
  updateMeta: { ...initialLoadedLoadingErrorState },
  publishMeta: { ...initialLoadedLoadingErrorState },
}

export default function autolinkedinReducer(
  state: AutoLinkdeinState = initialState,
  action: AutoLinkedinActionTypes
): AutoLinkdeinState {
  return produce(state, (draft: AutoLinkdeinState) => {
    switch (action.type) {
      case AUTOLINKEDIN.GENERATE_AUTOLINKEDIN:
        if (!draft.byItemID[action.itemID]) {
          draft.byItemID[action.itemID] = {
            ...metaInitState,
          }
        }
        draft.byItemID[action.itemID].generateMeta = setStartState(draft.byItemID[action.itemID].generateMeta)
        break
      case AUTOLINKEDIN.GENERATE_AUTOLINKEDIN_SUCCESS:
        const previousAction = action.meta.previousAction
        draft.byItemID[previousAction.itemID].generateMeta = setSuccessState(
          state.byItemID[previousAction.itemID].generateMeta
        )
        draft.byItemID[previousAction.itemID].autoLinkedin = action.payload.data
        break
      case AUTOLINKEDIN.GENERATE_AUTOLINKEDIN_FAIL: {
        const previousAction = action.meta.previousAction
        draft.byItemID[previousAction.itemID].generateMeta = setErrorState(
          state.byItemID[previousAction.itemID].generateMeta,
          action.error
        )
        break
      }
      case AUTOLINKEDIN.GET_AUTOLINKEDIN_BY_ITEM:
        if (!draft.byItemID[action.itemID]) {
          draft.byItemID[action.itemID] = {
            ...metaInitState,
          }
        }
        draft.byItemID[action.itemID].getMeta = setStartState(draft.byItemID[action.itemID].getMeta)
        break
      case AUTOLINKEDIN.GET_AUTOLINKEDIN_BY_ITEM_SUCCESS: {
        const previousAction = action.meta.previousAction
        draft.byItemID[previousAction.itemID].getMeta = setSuccessState(state.byItemID[previousAction.itemID].getMeta)
        draft.byItemID[previousAction.itemID].autoLinkedin = action.payload.data
        break
      }
      case AUTOLINKEDIN.GET_AUTOLINKEDIN_BY_ITEM_FAIL: {
        const previousAction = action.meta.previousAction
        draft.byItemID[previousAction.itemID].getMeta = setErrorState(
          state.byItemID[previousAction.itemID].getMeta,
          action.error
        )
        break
      }
      case AUTOLINKEDIN.UPDATE_AUTOLINKEDIN: {
        draft.byItemID[action.itemID].publishMeta.error = null
        draft.byItemID[action.itemID].updateMeta = setStartState(state.byItemID[action.itemID].updateMeta)
        break
      }
      case AUTOLINKEDIN.UPDATE_AUTOLINKEDIN_SUCCESS: {
        const previousAction = action.meta.previousAction
        draft.byItemID[previousAction.itemID].updateMeta = setSuccessState(
          state.byItemID[previousAction.itemID].updateMeta
        )
        // preserve resumeUrls after update
        const resumeUrls = draft.byItemID[previousAction.itemID].autoLinkedin?.resume_image_urls
        draft.byItemID[previousAction.itemID].autoLinkedin = action.payload.data
        if (!!draft.byItemID[previousAction.itemID].autoLinkedin) {
          // @ts-ignore
          draft.byItemID[previousAction.itemID].autoLinkedin.resume_image_urls = resumeUrls
        }

        break
      }
      case AUTOLINKEDIN.UPDATE_AUTOLINKEDIN_FAIL: {
        const previousAction = action.meta.previousAction
        draft.byItemID[previousAction.itemID].updateMeta = setErrorState(
          state.byItemID[previousAction.itemID].updateMeta,
          action.error
        )
        break
      }
      case AUTOLINKEDIN.PUBLISH_AUTOLINKEDIN_REVISION:
        draft.byItemID[action.itemID].updateMeta.error = null
        draft.byItemID[action.itemID].publishMeta = setStartState(state.byItemID[action.itemID].publishMeta)
        break
      case AUTOLINKEDIN.PUBLISH_AUTOLINKEDIN_REVISION_SUCCESS: {
        const previousAction = action.meta.previousAction
        draft.byItemID[previousAction.itemID].publishMeta = setSuccessState(
          state.byItemID[previousAction.itemID].publishMeta
        )
        const data = action.payload.data

        if (!!data) {
          if (!draft.revisions[previousAction.autoLinkID]) {
            draft.revisions[previousAction.autoLinkID] = {}
          }
          draft.revisions[previousAction.autoLinkID][data.id] = action.payload.data
        }
        break
      }
      case AUTOLINKEDIN.PUBLISH_AUTOLINKEDIN_REVISION_FAIL: {
        const previousAction = action.meta.previousAction
        draft.byItemID[previousAction.itemID].publishMeta = setErrorState(
          state.byItemID[previousAction.itemID].publishMeta,
          action.error
        )
        break
      }
      case ClientOrdersAggregateActions.FETCH_CLIENT_ORDERS_SUCCESS: {
        const response = action.payload.data
        if (!!response && response.length > 0) {
          response.forEach(orderResponse => {
            if (!!orderResponse.auto_linkedin) {
              if (!draft.byItemID[orderResponse.auto_linkedin?.payment_product_id]) {
                draft.byItemID[orderResponse.auto_linkedin?.payment_product_id] = {
                  autoLinkedin: orderResponse.auto_linkedin,
                  ...metaInitState,
                }
              } else {
                draft.byItemID[orderResponse.auto_linkedin?.payment_product_id].autoLinkedin =
                  orderResponse.auto_linkedin
              }
            }
            if (!!orderResponse.auto_linkedin_revisions && orderResponse.auto_linkedin_revisions.length > 0) {
              orderResponse.auto_linkedin_revisions.forEach(revision => {
                if (!draft.revisions[revision.auto_linkedin_id]) {
                  draft.revisions[revision.auto_linkedin_id] = {}
                }
                draft.revisions[revision.auto_linkedin_id][revision.id] = revision
              })
            }
          })
        }
        break
      }
      case AUTOLINKEDIN.START_FROM_REVISION:
        draft.byItemID[action.itemID].generateMeta = setStartState(state.byItemID[action.itemID].generateMeta)
        break
      case AUTOLINKEDIN.START_FROM_REVISION_SUCCESS: {
        const previousAction = action.meta.previousAction
        draft.byItemID[previousAction.itemID].autoLinkedin = action.payload.data
        draft.byItemID[previousAction.itemID].generateMeta = setSuccessState(
          state.byItemID[previousAction.itemID].generateMeta
        )
        break
      }
      case AUTOLINKEDIN.START_FROM_REVISION_FAIL: {
        const previousAction = action.meta.previousAction
        draft.byItemID[previousAction.itemID].generateMeta = setErrorState(
          state.byItemID[previousAction.itemID].generateMeta,
          action.error
        )
        break
      }
    }
  })
}
