import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import MultipleCheckOption from './MultipleCheckOption'
import Box from '@material-ui/core/Box'

export interface MultipleCheckDataItem {
  label: string
  index: number | string
}

export type MultipleCheckData = MultipleCheckDataItem[]

export type MultipleCheckValue = {
  [value: number]: boolean
  [value: string]: boolean
} | null

export interface MultipleCheckProps {
  data: MultipleCheckData
  value?: MultipleCheckValue
  handleChange?: (value: MultipleCheckValue) => void
  size?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      border: '1px solid #d5d9e0',
      borderRadius: '.25rem',
    },
  })
)

const MultipleCheck: React.FC<MultipleCheckProps> = props => {
  const classes = useStyles()
  const { data, value, handleChange, size } = props
  const [selection, setSelection] = useState<MultipleCheckValue>(value ? value : null)

  const triggerChangeHandler = (value: MultipleCheckValue) => {
    handleChange && handleChange(value)
  }

  const handleSelectionChange = (index: number | string, event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelection = { ...selection, [index]: event.target.checked }
    setSelection(newSelection)
    triggerChangeHandler(newSelection)
  }

  return (
    <Box className={classes.root}>
      {data.map((d, i) => (
        <MultipleCheckOption
          label={d.label}
          index={d.index}
          active={selection ? selection[d.index] : false}
          handleChange={e => handleSelectionChange(d.index, e)}
          size={size}
          key={i}
        />
      ))}
    </Box>
  )
}

export default MultipleCheck
