import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store'
import { Nudge, NudgeDisplay, NudgeTypes } from '../../store/nudges/types'
import {
  getWipSegment,
  removeMessageReceivedNudge,
  removeMessageSentNudge,
  removeNewOrdersNudge,
  scheduleNextNudge,
} from '../../store/nudges/actions'
import FloatingNudge from './FloatingNudge'
import isEqual from 'lodash/isEqual'
import i18n from '../../i18n'
import { sendUserEvent } from '../../store/events/actions'
import { EventTypes } from '../../store/events/types'
import omit from 'lodash/omit'

const NudgeContainer: React.FC = () => {
  const messageSentNudge = useSelector<AppState, Nudge | null>(
    state => state.nudgesReducer[NudgeTypes.MessageSent],
    isEqual
  )
  const newOrdersNudge = useSelector<AppState, Nudge | null>(
    state => state.nudgesReducer[NudgeTypes.NewOrders],
    isEqual
  )

  const messageReceivedNudges = useSelector<AppState, Nudge[] | null>(
    state => state.nudgesReducer[NudgeTypes.MessageReceived].nudges,
    isEqual
  )

  const dispatch = useDispatch()

  const [nudgeDisplay, setNudgeDisplay] = useState<NudgeDisplay | null>(null)

  const cleanUpNudge = useCallback(() => {
    if (!!nudgeDisplay && !!nudgeDisplay.trigger) {
      scheduleNextNudge(nudgeDisplay.trigger)
    }
    if (!!nudgeDisplay) {
      switch (nudgeDisplay.type) {
        case NudgeTypes.MessageSent:
          dispatch(removeMessageSentNudge())
          break
        case NudgeTypes.NewOrders:
          dispatch(removeNewOrdersNudge())
          break
        case NudgeTypes.MessageReceived:
          dispatch(removeMessageReceivedNudge())
      }
      setNudgeDisplay(null)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [setNudgeDisplay, nudgeDisplay])

  const autoDismiss = useCallback(() => {
    cleanUpNudge()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [cleanUpNudge])

  const manualDismiss = useCallback(() => {
    if (!!nudgeDisplay && nudgeDisplay.variables?.link) {
      switch (nudgeDisplay.type) {
        case NudgeTypes.NewOrders:
          {
            const wipLimit = nudgeDisplay.variables.wipLimit || 0
            const worksInProgress = nudgeDisplay.variables.worksInProgress || 0
            const wipSegment = nudgeDisplay.variables.wipSegment || null
            dispatch(
              sendUserEvent({
                event: EventTypes.NewOrdersNudgeDismissed,
                variables: {
                  worksInProgress,
                  wipLimit,
                  wipSegment: wipSegment ? wipSegment : getWipSegment(worksInProgress, wipLimit),
                },
              })
            )
          }
          break
        case NudgeTypes.MessageReceived: {
          dispatch(
            sendUserEvent({
              event: EventTypes.MessageReceivedNudgeDismissed,
              variables: !!nudgeDisplay.variables ? omit(nudgeDisplay.variables, 'client_name') : {},
            })
          )
          break
        }
      }
    }
    cleanUpNudge()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [cleanUpNudge, nudgeDisplay])

  const followNudge = useCallback(() => {
    if (!!nudgeDisplay && nudgeDisplay.variables?.link)
      switch (nudgeDisplay.type) {
        case NudgeTypes.NewOrders:
          const wipLimit = nudgeDisplay.variables.wipLimit || 0
          const worksInProgress = nudgeDisplay.variables.worksInProgress || 0
          const wipSegment = nudgeDisplay.variables.wipSegment
          dispatch(
            sendUserEvent({
              event: EventTypes.NewOrdersNudgeClicked,
              variables: {
                worksInProgress,
                wipLimit,
                wipSegment: wipSegment ? wipSegment : getWipSegment(worksInProgress, wipLimit),
              },
            })
          )
          break
        case NudgeTypes.MessageReceived:
          dispatch(
            sendUserEvent({
              event: EventTypes.MessageReceivedNudgeClicked,
              variables: !!nudgeDisplay.variables ? omit(nudgeDisplay.variables, 'client_name') : {},
            })
          )
          break
      }
    cleanUpNudge()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [nudgeDisplay, cleanUpNudge])

  if (!!messageSentNudge && !nudgeDisplay) {
    // @ts-ignore
    const variables = messageSentNudge?.variables

    if (variables) {
      const newNudge = {
        ...messageSentNudge,
        text: i18n.t(messageSentNudge.copyKey, {
          clientName: variables?.client_name,
          incentiveTotalEarned: variables?.incentiveTotalEarned,
          incentiveEarnedTypes: variables?.incentiveEarnedTypes,
        }),
      }
      setNudgeDisplay(newNudge)
    }
  }

  if (!!messageReceivedNudges && messageReceivedNudges.length > 0 && !nudgeDisplay) {
    const nudge = messageReceivedNudges[0]
    const nudgeDisplay = {
      ...nudge,
      // @ts-ignore
      text: i18n.t(nudge.copyKey, nudge.variables),
    }
    setNudgeDisplay(nudgeDisplay)
  }

  if (!!newOrdersNudge && !messageSentNudge && !nudgeDisplay) {
    const newNudge = {
      ...newOrdersNudge,
      text: i18n.t(newOrdersNudge.copyKey),
    }
    setNudgeDisplay(newNudge)
  }

  if (!nudgeDisplay) {
    return null
  }

  let visibleFor = null
  switch (nudgeDisplay.type) {
    case NudgeTypes.MessageReceived:
      visibleFor = 10 * 1000
      break
    case NudgeTypes.MessageSent:
      visibleFor = 5 * 1000
      break
  }

  return (
    <FloatingNudge
      onClick={followNudge}
      onManualDismiss={manualDismiss}
      onAutoDismiss={autoDismiss}
      text={nudgeDisplay.text}
      visibleFor={visibleFor}
      link={nudgeDisplay?.variables?.link}
    />
  )
}

export default NudgeContainer
