import React, { useContext } from 'react'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from '@material-ui/core/Box'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import FilterListIcon from '@material-ui/icons/FilterList'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useLockBodyScroll } from 'react-use'

import styles from '../../styles'
import ClientList from './ClientList'
import useOrderFiltering, { OrderTableDisplayModes } from '../Orders/useOrderFiltering'
import StyledSelect from '../common/StyledSelect'
import SearchInput from '../common/SearchInput'
import useOrderSearch from '../Orders/useOrderSearch'
import { SidebarContext } from './useSidebarState'

export interface Props {
  expanded: boolean
  toggleNavigation: (open?: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      height: '100%',
      backgroundColor: '#fff',
    },
    drawerPaperTopNavVisible: {
      zIndex: 11,
      marginTop: '3.6rem',
      '@media (max-width: 560px)': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        borderRadius: '1.25rem 1.25rem 0 0',
        height: '80%',
        position: 'absolute',
      },
    },
    drawerPaperTopNavInvisible: {
      zIndex: 11,
      overflowY: 'hidden',
      '@media (max-width: 560px)': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        height: '100%',
        position: 'absolute',
      },
    },
    closeIcon: {
      float: 'right',
    },
    detailed: {
      flexDirection: 'column',
      flexGrow: 1,
      '@media (max-width: 560px)': {
        height: '100%',
      },
    },
    bar: {
      width: styles.sizes.order2orderNavWidth,
      borderRight: '1px solid #CACFD8',

      '@media (min-width: 1200px)': {
        position: 'static',
      },
      '@media (max-width: 560px)': {
        backgroundColor: 'white',
        borderRadius: '1.25rem 1.25rem 0 0',
        bottom: '0',
        height: '80%',
        position: 'absolute',
      },
    },
    filters: {
      boxShadow: '0px 10px 9px #f6f6f6',
      marginBottom: '1rem',

      '& > *': {
        margin: '.5rem 14px',
        maxWidth: `calc(${styles.sizes.order2orderNavWidth} - 28px)`,
        '@media (max-width: 560px)': {
          maxWidth: 'initial',
          width: 'calc(100% - 28px)',
        },
      },
      '@media (max-width: 560px)': {
        backgroundColor: 'white',
        borderRadius: '1.25rem 1.25rem 0 0',
        marginTop: '1rem',
        paddingBottom: '1rem',
      },
    },
    swipeBar: {
      display: 'none',
      '@media (max-width: 560px)': {
        backgroundColor: styles.palette.lightGrey.color,
        borderRadius: '.5rem',
        display: 'block',
        height: '.25rem',
        marginLeft: 'calc((100% - 4.4rem)/2)',
        width: '4.4rem',
      },
    },
    [theme.breakpoints.down('sm')]: {
      drawerPaperTopNavVisible: {
        height: '80%',
        overflow: 'hidden',
      },
      bar: {
        width: '100%',
      },
    },
  })
)

const Order2OrderNavigation: React.FC<Props> = ({ expanded, toggleNavigation }) => {
  const classes = useStyles()
  const orderFiltering = useOrderFiltering()
  const orderSearch = useOrderSearch()

  const sideMenuState = useContext(SidebarContext)
  // We need to have the drawer pop up from below on mobile
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // Prevent the body from scrolling in mobile mode when it's open
  useLockBodyScroll(expanded && isMobile)

  return (
    <SwipeableDrawer
      className={classes.root}
      open={expanded}
      onClose={() => toggleNavigation(false)}
      onOpen={() => toggleNavigation(true)}
      variant="persistent"
      anchor={isMobile ? 'bottom' : 'left'}
      PaperProps={{
        classes: {
          root: !isMobile ? classes.drawerPaperTopNavVisible : classes.drawerPaperTopNavInvisible,
        },
      }}
    >
      <Box className={classes.detailed}>
        <Box className={classes.bar}>
          <Box className={classes.filters}>
            <div className={classes.swipeBar} onClick={() => toggleNavigation(false)} />
            <SearchInput
              id="client-search-side-nav"
              value={orderSearch.localSearchTerm}
              onClearClick={() => orderSearch.setSearchTerm('')}
              onChange={value => orderSearch.setSearchTerm(value.target.value)}
            />
            <StyledSelect
              onChange={e => orderFiltering.setFilter(e.target.value as OrderTableDisplayModes)}
              value={orderFiltering.currentOption.key}
              outlined
              variant="grey"
              startAdornment={
                <InputAdornment position="start">
                  <FilterListIcon />
                </InputAdornment>
              }
              items={orderFiltering.options
                .filter(o => o.isVisible)
                .map(o => {
                  return {
                    label: o.label,
                    count: o.count,
                    value: o.key,
                  }
                })}
            />
          </Box>
          <ClientList onClientLinkClick={sideMenuState.mobile ? () => toggleNavigation(false) : () => {}} />
        </Box>
      </Box>
    </SwipeableDrawer>
  )
}

export default Order2OrderNavigation
