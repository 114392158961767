import produce from 'immer'

import { ConfigAction, ConfigActions } from './actions'
import { ConfigState, defaultState } from './types'

export default function configReducer(state: ConfigState = defaultState, action: ConfigAction): ConfigState {
  return produce(state, (draft: ConfigState) => {
    switch (action.type) {
      case ConfigActions.FETCH_CONFIG_SUCCESS:
      case ConfigActions.UPDATE_CONFIG_SUCCESS:
        draft.config = action.payload.data
        break
    }
  })
}
