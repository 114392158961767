import { CurrentMessage, defaultCurrentMessage } from './types'
import { CurrentMessageActionType, CurrentMessageActionTypes } from './actions'
import { DOCUMENT } from '../documents/actions'

export const initialState: CurrentMessage = { ...defaultCurrentMessage }

export function currentMessageReducer(
  state: CurrentMessage = initialState,
  action: CurrentMessageActionType
): CurrentMessage {
  switch (action.type) {
    case CurrentMessageActionTypes.CURRENT_MESSAGE_UPDATE:
      state = {
        ...state,
        ...action.messageUpdate,
      }
      break

    case CurrentMessageActionTypes.CURRENT_MESSAGE_RESET:
      state = { ...defaultCurrentMessage }
      break

    case DOCUMENT.DELETE_DOC_SUCCESS: {
      /* eslint-disable-next-line eqeqeq */
      state.attachment_ids = state.attachment_ids.filter(id => id != action.meta.previousAction.fileID)
      break
    }
  }

  return state
}

export default currentMessageReducer
