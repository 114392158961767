import React, { useCallback, useEffect } from 'react'
import { useField, useForm } from 'react-final-form-hooks'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store'
import { AutoLinkedinJson, Summary } from '../../store/autolinkedin/types'
import {
  getAutoLinkedinUpdateMeta,
  getAutoLinkedinSummary,
  getAutoLinkedinHeadline,
} from '../../store/autolinkedin/selectors'
import { updateAutoLinkedinByItemID } from '../../store/autolinkedin/actions'
import TextField from '@material-ui/core/TextField'
import i18n from '../../i18n'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Box } from '@material-ui/core'
import UploadStyles from '../UploadsV3/styles'
import styles from '../../styles'
import { LoadedLoadingErrorState } from '../../utils/state'
import Typography from '@material-ui/core/Typography'
import Button from '../common/Button'
import InfoTooltip from '../common/InfoTooltip'
import classNames from 'classnames'
import { Editor } from 'react-draft-wysiwyg'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import { cleanEditorState, fromPlainTextWithBullets, toPlainTextWithBullets } from '../../utils/draftjs'
import { decorators } from '../Messaging/Decorators'
import useLocalEditorState from './useLocalEditorState'

export interface SummaryFormProps {
  linkedinItemID: number
  highlightClass?: string | null
}

const useStyles = makeStyles(() =>
  createStyles({
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    container: {
      border: UploadStyles.autoQAResults.group.border,
      borderRadius: UploadStyles.autoQAResults.group.borderRadius,
      boxShadow: UploadStyles.autoQAResults.group.boxShadow,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1.25em',
      padding: '1.875rem',
      width: '100%',
    },
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    sectionHeaderText: {
      margin: '20px 0',
      minHeight: '0',
      paddingLeft: '.1em',
      paddingRight: '.5em',
      fontSize: '1.25em',
      fontWeight: 'bold',
    },
    inputGroup: {
      marginBottom: '1.3em',
      marginTop: '.71em',
    },
    input: {
      color: styles.palette.darkerBlue.color.hex,
      backgroundColor: styles.palette.inputGray.color,
      borderBottom: `1px solid ${styles.palette.darkerBlue.color.hex}`,
      padding: '.1em',
      '&.Mui-error': {
        borderBottom: `1px solid ${styles.palette.pink.hex}`,
      },
    },
    inputLabel: {
      color: styles.palette.darkerBlue.color.hex,
      fontSize: '.75rem',
      fontWeight: 600,
      marginBottom: '.83em',
      marginTop: '-.71em',
      textTransform: 'uppercase',
      transform: 'translate(0, 1.5px)',
      '&.Mui-error': {
        color: styles.palette.pink.hex,
      },
    },
    editor: {
      padding: '0 0.625rem',
      fontFamily: styles.fonts.primary.family,
      fontWeight: 'normal',
      minHeight: '30vh',
      maxHeight: '35vh',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    toolbar: {
      marginTop: '.5rem',
    },
    error: {
      color: `${styles.palette.pink.hex} !important`,
    },
    labels: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
)

const HeadlineCharLimit = 220
const SummaryCharLimit = 2600

const SummaryForm: React.FC<SummaryFormProps> = ({ linkedinItemID, highlightClass = null }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const summary = useSelector<AppState, Summary | undefined | null>(state =>
    getAutoLinkedinSummary(state.autolinkedinReducer, linkedinItemID)
  )

  const headlineInitValue = useSelector<AppState, string | undefined | null>(state =>
    getAutoLinkedinHeadline(state.autolinkedinReducer, linkedinItemID)
  )

  const meta = useSelector<AppState, LoadedLoadingErrorState | undefined>(state =>
    getAutoLinkedinUpdateMeta(state.autolinkedinReducer, linkedinItemID)
  )

  const onSubmit = useCallback(
    async (values: Partial<AutoLinkedinJson>) => {
      dispatch(
        updateAutoLinkedinByItemID(linkedinItemID, {
          talentLinkedin: values,
        })
      )
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [linkedinItemID]
  )

  const validate = useCallback((values: Partial<AutoLinkedinJson>) => {
    const errors: Record<string, object> = {}

    if (!values.summary || !values.summary.text) {
      errors.summary = {
        text: i18n.t('alink__error__required'),
      }
    } else if (values.summary.text.length > SummaryCharLimit) {
      errors.summary = {
        text: i18n.t('alink__error__limit_exceeded', { diff: values.summary.text.length - SummaryCharLimit }),
      }
    } else if (values.summary.text.search(/system.?\(+/i) >= 0) {
      errors.summary = {
        text: i18n.t('alink__updateSystemError', { diff: values.summary.text.length - SummaryCharLimit }),
      }
    }

    if (!values.headline || values.headline.length === 0) {
      errors.headline = i18n.t('alink__error__required')
    } else if (values.headline.length > HeadlineCharLimit) {
      errors.headline = i18n.t('alink__error__limit_exceeded', { diff: values.headline.length - HeadlineCharLimit })
    } else if (values.headline.search(/system.?\(+/i) >= 0) {
      errors.headline = i18n.t('alink__updateSystemError')
    }

    return errors
  }, [])

  const { form, handleSubmit, pristine, submitting, valid } = useForm({
    onSubmit,
    initialValues: { summary: summary, headline: headlineInitValue },
    validate: validate,
  })

  const text = useField('summary.text', form)
  const headline = useField('headline', form)

  const editorConfig = useLocalEditorState(
    !!summary ? EditorState.createWithContent(convertFromRaw(fromPlainTextWithBullets(summary.text))) : undefined
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!pristine) {
        handleSubmit()
      }
    }, 30 * 1000)
    return () => clearTimeout(timer)
  }, [pristine, handleSubmit])

  return (
    <Box>
      <Box className={classes.sectionHeader}>
        <Typography className={classes.sectionHeaderText} variant={'h2'}>
          {i18n.t(`alink__summary_n_headline`)}
        </Typography>
        <InfoTooltip text={i18n.t('alink__headline_n_summary__tooltip')} />
      </Box>
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className={clsx(classes.container, !text.meta.submitSucceeded ? highlightClass : null)}
      >
        <Box className={classes.labels}>
          <Typography
            className={classNames({
              [classes.inputLabel]: true,
              [classes.error]: !!headline.meta.error && headline.meta.touched,
            })}
          >
            {i18n.t('alink__headline')}
          </Typography>
          {!!headline.meta.error && (
            <Typography
              className={classNames({
                [classes.inputLabel]: true,
                [classes.error]: headline.meta.touched,
              })}
            >
              {headline.meta.error}
            </Typography>
          )}
        </Box>
        <TextField
          className={classes.inputGroup}
          disabled={submitting}
          multiline={true}
          InputLabelProps={{ className: classes.inputLabel }}
          InputProps={{ className: classes.input }}
          inputProps={headline.input as React.HTMLAttributes<HTMLInputElement>}
        />

        <Box className={classes.labels}>
          <Typography
            className={classNames({
              [classes.inputLabel]: true,
              [classes.error]: !!text.meta.error && text.meta.dirty,
            })}
          >
            {i18n.t('alink__summary__text')}
          </Typography>
          {!!text.meta.error && (
            <Typography
              className={classNames({
                [classes.inputLabel]: true,
                [classes.error]: text.meta.dirty,
              })}
            >
              {text.meta.error}
            </Typography>
          )}
        </Box>
        <Editor
          readOnly={submitting}
          onFocus={() => {
            if (editorConfig.toolbarHidden) {
              editorConfig.setToolbarHidden(false)
            }
          }}
          onBlur={() => {
            if (!editorConfig.toolbarHidden) {
              editorConfig.setToolbarHidden(true)
            }
          }}
          editorClassName={clsx([
            classes.editor,
            classes.input,
            classes.inputGroup,
            !!text.meta.error && text.meta.dirty ? 'Mui-error' : null,
          ])}
          toolbarClassName={classes.toolbar}
          editorState={editorConfig.editorState}
          onEditorStateChange={(newValue: EditorState) => {
            editorConfig.setEditorState(previousEditorState => {
              const lastChange = newValue.getLastChangeType()
              const shouldFilterCopyPaste =
                (!previousEditorState || previousEditorState.getCurrentContent() !== newValue.getCurrentContent()) &&
                (lastChange === 'insert-fragment' || lastChange === 'insert-characters')

              if (shouldFilterCopyPaste) {
                return cleanEditorState(newValue)
              }
              return newValue
            })
            text.input.onChange(toPlainTextWithBullets(convertToRaw(newValue.getCurrentContent())))
          }}
          toolbarHidden={editorConfig.toolbarHidden}
          toolbar={{
            options: ['list'],
            inline: {
              options: [],
            },
          }}
          spellCheck={true}
          handlePastedText={editorConfig.handlePastedText}
          customDecorators={decorators}
        />

        <Box className={classes.buttons}>
          <Button
            type="contrast"
            disabled={!valid || submitting || pristine || meta?.isLoading}
            // @ts-ignore
            onClick={handleSubmit}
            showLoader={meta?.isLoading || submitting}
          >
            {i18n.t('alink__button__saveChanges')}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default SummaryForm
