import ButtonBase from '@material-ui/core/Button'
import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import i18n from '../../i18n'
import { FormControlLabel } from '@material-ui/core'
import { FeedbackBoolPayload } from '../../store/featureFeedback/types'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import Box from '@material-ui/core/Box'
import styles from '../../styles'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: '1.875rem',
    },
    buttonsRoot: {
      display: 'flex',
      marginTop: '1.25rem',
      width: '100%',
      justifyContent: 'space-between',
    },
    selected: {
      backgroundColor: '#09143b',
      color: 'white',
      '&:hover': {
        backgroundColor: '#09143b',
      },
    },
  })
)

export interface BoolFeatureFeedbackProps {
  feedback_key: string
  feature_id: number
  feature_element: string
  updateResultFeedback: (value: FeedbackBoolPayload) => void
  initialValue?: 1 | 0 | null | undefined
}

const FormControlLabelStyled = withStyles({
  label: {
    textTransform: 'uppercase',
    fontWeight: styles.fonts.primary.weights.semiBold,
    fontSize: '.75rem',
  },
})(FormControlLabel)

const BoolFeatureFeedback: React.FC<BoolFeatureFeedbackProps> = ({
  feedback_key,
  feature_element,
  updateResultFeedback,
  feature_id,
  initialValue,
}) => {
  const classes = useStyles()
  const [value, setValue] = useState<1 | 0 | null>(initialValue !== undefined ? initialValue : null)

  // when rendering for the first time, updateResultFeedback to null
  useEffect(() => {
    if (initialValue === undefined) {
      updateResultFeedback({
        feature_id,
        feature_element,
        feedback_bool: null,
      })
    }
  }, [feature_id, feature_element, updateResultFeedback, feedback_key, initialValue])

  const handleChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newValue: 1 | 0) => {
    event.preventDefault()
    setValue(newValue)

    updateResultFeedback({
      feature_element,
      feature_id,
      feedback_bool: newValue,
    })
  }

  return (
    <Box className={classes.root}>
      <FormControlLabelStyled
        value={value}
        labelPlacement={'top'}
        label={i18n.t(`auto_qa__file_preprocess__feedback_key__${feedback_key}`)}
        control={
          <Box className={classes.buttonsRoot}>
            <ButtonBase
              className={classNames({
                [classes.selected]: value === 0,
              })}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleChange(event, 0)}
              variant="outlined"
            >
              {i18n.t('feature_feedback__bool_no')}
            </ButtonBase>
            <ButtonBase
              className={classNames({
                [classes.selected]: value === 1,
              })}
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleChange(event, 1)}
              variant="outlined"
            >
              {i18n.t('feature_feedback__bool_yes')}
            </ButtonBase>
          </Box>
        }
      />
    </Box>
  )
}

const memoizedBoolFeatureFeedback = React.memo(BoolFeatureFeedback, isEqual)
// @ts-ignore
memoizedBoolFeatureFeedback.whyDidYouRender = true

export default memoizedBoolFeatureFeedback
