import React, { useCallback } from 'react'
import { enUS } from 'date-fns/locale'
import { getDaysInMonth, getYear } from 'date-fns'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { Field, FieldRenderProps } from 'react-final-form'
import DatePartSelect from './DatePartSelect'

export interface Props {
  inputClass: string
  inputGroupClass: string
  label: string
  outerName: string
  readonly: boolean
  labelClass: string
  views: ('year' | 'day' | 'month')[]
  onEndDateRenderCallback?: (value: number | null) => void
}

type RenderValueType = number | null

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      margin: '0',
    },
    dayMonthYearSelect: {
      width: '31%',
    },
    monthYearSelect: {
      width: '48%',
    },
    selectContainer: {
      alignItems: 'flex-start',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  })
)

// @ts-ignore
const months: RenderValueType[] = Object.freeze([null].concat([...Array(12).keys()]))
// @ts-ignore

const years: RenderValueType[] = Object.freeze(
  [null]
    // @ts-ignore
    .concat([...Array(71).keys()].map(k => getYear(new Date()) - k).reverse())
    // @ts-ignore
    .concat([...Array(10).keys()].map(k => getYear(new Date()) + k + 1))
)

type NA = '-'
type DatePart = number | NA

const DateDropdown: React.FC<Props> = ({
  inputClass,
  inputGroupClass,
  onEndDateRenderCallback,
  outerName,
  readonly,
  label,
  labelClass,
  views,
}) => {
  const classes = useStyles()

  const [selectedDay, setSelectedDay] = React.useState<number | null>()
  const [selectedMonth, setSelectedMonth] = React.useState<number | null>()
  const [selectedYear, setSelectedYear] = React.useState<number | null>()

  const getDayValues = useCallback(
    (monthValue: number | null | undefined, yearValue: number | null | undefined): RenderValueType[] => {
      if (!monthValue || !yearValue) {
        const dayCount = getDaysInMonth(new Date())
        // @ts-ignore
        return [null].concat([...Array(dayCount).keys()].map(k => k + 1))
      } else {
        const dayCount = getDaysInMonth(new Date(yearValue, monthValue))
        // @ts-ignore
        return [null].concat([...Array(dayCount).keys()].map(k => k + 1))
      }
    },
    []
  )

  const threePartControl = views.includes('day')

  return (
    <Container className={classes.container} disableGutters>
      <Typography className={labelClass} variant="h6">
        {label}
      </Typography>
      <Container disableGutters className={classes.selectContainer}>
        {views.includes('day') && (
          <Field name={`${outerName}.day`} key={`${outerName}.day`}>
            {(props: FieldRenderProps<RenderValueType>) => {
              return (
                <DatePartSelect
                  key={`${outerName}.day-${props.input.value}`}
                  readonly={readonly}
                  initValue={props.input.value}
                  inputGroupClass={inputGroupClass}
                  inputClass={inputClass}
                  selectClass={threePartControl ? classes.dayMonthYearSelect : classes.monthYearSelect}
                  renderValue={value => (
                    <Typography>{!!(value as RenderValueType) ? (value as RenderValueType) : '-'}</Typography>
                  )}
                  onValueChange={e => {
                    props.input.onChange(e)
                  }}
                >
                  {getDayValues(selectedMonth, selectedYear).map(day => (
                    <MenuItem key={day as number} value={day as number}>
                      {!!day ? day : '-'}
                    </MenuItem>
                  ))}
                </DatePartSelect>
              )
            }}
          </Field>
        )}
        {views.includes('month') && (
          <Field name={`${outerName}.month`} key={`${outerName}.month`}>
            {(props: FieldRenderProps<RenderValueType>) => {
              return (
                <DatePartSelect
                  key={`${outerName}.month-${props.input.value}`}
                  readonly={readonly}
                  initValue={(props.input.value as number) - 1 >= 0 ? (props.input.value as number) - 1 : null}
                  inputGroupClass={inputGroupClass}
                  inputClass={inputClass}
                  selectClass={threePartControl ? classes.dayMonthYearSelect : classes.monthYearSelect}
                  onValueChange={newMonthValue => {
                    setSelectedMonth(newMonthValue)
                    if (!!selectedDay) {
                      const dayValues = getDayValues(newMonthValue, selectedYear)
                      // if selected month doesn't have previously selected day, reset it
                      if (dayValues.length > 0 && (dayValues[dayValues.length - 1] as number) < selectedDay) {
                        setSelectedDay(null)
                      }
                    }
                    props.input.onChange(newMonthValue === null ? newMonthValue : newMonthValue + 1)
                  }}
                  renderValue={value => (
                    <Typography>{enUS.localize && value !== '-' ? enUS.localize.month(value) : '-'}</Typography>
                  )}
                >
                  {months.map(month => (
                    <MenuItem key={month as number} value={month as number}>
                      {enUS.localize && month !== null ? enUS.localize.month(month) : '-'}
                    </MenuItem>
                  ))}
                </DatePartSelect>
              )
            }}
          </Field>
        )}
        {views.includes('year') && (
          <Field name={`${outerName}.year`} key={`${outerName}.year`}>
            {(props: FieldRenderProps<RenderValueType>) => {
              if (!!onEndDateRenderCallback) {
                onEndDateRenderCallback(props.input.value)
              }
              return (
                <DatePartSelect
                  key={`${outerName}.year-${props.input.value}`}
                  readonly={readonly}
                  initValue={props.input.value}
                  inputGroupClass={inputGroupClass}
                  inputClass={inputClass}
                  selectClass={threePartControl ? classes.dayMonthYearSelect : classes.monthYearSelect}
                  renderValue={value => (
                    <Typography>{!!(value as RenderValueType) ? (value as RenderValueType) : '-'}</Typography>
                  )}
                  onValueChange={e => {
                    setSelectedYear(e)
                    if (!!selectedDay) {
                      const dayValues = getDayValues(selectedMonth, e)
                      // if selected year doesn't have previously selected day, reset it
                      if (dayValues.length > 0 && (dayValues[dayValues.length - 1] as number) < selectedDay) {
                        setSelectedDay(null)
                      }
                    }
                    props.input.onChange(e)
                  }}
                >
                  {years.map(year => (
                    <MenuItem key={year as number} value={year as number}>
                      {!!year ? year : '-'}
                    </MenuItem>
                  ))}
                </DatePartSelect>
              )
            }}
          </Field>
        )}
      </Container>
    </Container>
  )
}

export default DateDropdown
