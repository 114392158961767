import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

export interface SingleSelectOptionProps {
  label?: string
  text: string
  value: string | number
  active: boolean
  handleClick: React.EventHandler<React.MouseEvent>
  size?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '19.3125rem',
      height: '7.125rem',
      border: '1px solid #d5d9e0',
      borderRadius: '.625rem',
      cursor: 'pointer',
      '&.small': {
        height: '3.5625rem',
        minWidth: '9rem',
        '& .content': { padding: '0 1rem' },
        '& .label': { fontSize: '.6875rem', lineHeight: '1rem' },
        '& .text': { fontSize: '.9375rem', lineHeight: '1.4rem' },
        '@media (max-width: 380px)': { minWidth: '7rem' },
      },
      '&:not(.active):hover': {
        borderColor: '#00a3f4',
        color: '#00a3f4',
      },
      '&.active': {
        borderColor: '#00a3f4',
        backgroundColor: '#f0f9ff',
        color: '#00a3f4',
      },
      '& .content': {
        padding: '0 1.25rem',
        '& .label': {
          whiteSpace: 'nowrap',
          fontSize: '1.125rem',
          lineHeight: '1.5625rem',
          fontWeight: 500,
        },
        '& .text': {
          whiteSpace: 'nowrap',
          fontSize: '1.875rem',
          lineHeight: '2.5625rem',
        },
      },
    },
  })
)

const SingleSelectOption: React.FC<SingleSelectOptionProps> = props => {
  const classes = useStyles()
  const { label, text, active, handleClick, size } = props

  return (
    <Box
      className={classNames({
        [classes.root]: true,
        'SingleSelect-option': true,
        active: active,
        small: size && size === 'small',
      })}
      onClick={handleClick}
    >
      <Box className="content">
        {label ? (
          <Typography variant={'body1'} className="label SingleSelect-optionLabel">
            {label}
          </Typography>
        ) : null}
        <Typography variant={'body1'} className="text SingleSelect-optionText">
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

export default SingleSelectOption
