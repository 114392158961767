import { EventActions, EventsActionTypes } from './actions'
import produce from 'immer'
import * as stateUtils from '../../utils/state'
import { initialLoadedLoadingErrorState } from '../../utils/state'
import { EventsState, UserEventStreamEvent } from './types'
import { initialPaginationState } from '../../utils/pagination'

const initialState: EventsState = {
  events: {},
  activityStream: {
    page: 0,
    hasNextPage: true,
  },
  pagination: { ...initialPaginationState, page_size: 20 },
  meta: { ...initialLoadedLoadingErrorState },
}

export default function eventsReducer(state: EventsState = initialState, action: EventsActionTypes): EventsState {
  return produce(state, (draft: EventsState) => {
    switch (action.type) {
      case EventActions.FETCH_USER_EVENTS:
        draft.meta = stateUtils.setStartState(state.meta)

        if (action.filter.page && action.filter.page === 1) {
          draft.activityStream.page = 0
        } else {
          draft.activityStream.page = action.filter.page as number
        }

        if (action.filter.page === 1) {
          draft.events = {}
          draft.pagination = { ...initialPaginationState, page_size: 20 }
        }
        break

      case EventActions.FETCH_USER_EVENTS_SUCCESS: {
        const page = action.meta.previousAction.filter.page as number
        draft.meta = stateUtils.setSuccessState(state.meta)
        draft.pagination.page = page

        if (action.payload.data.events) {
          draft.pagination.pages[page] = action.payload.data.events.map(o => o.aramis_event.id)

          action.payload.data.events.forEach((ev: UserEventStreamEvent) => {
            draft.events[ev.aramis_event.id] = ev
            draft.events[ev.aramis_event.id].aramis_event.variables = JSON.parse(ev.aramis_event.variables as string)
          })
        }

        if (!action.payload.data.events || action.payload.data.events.length < state.pagination.page_size) {
          draft.activityStream.hasNextPage = false
        } else {
          draft.activityStream.hasNextPage = true
        }
        if (action.meta.previousAction.filter.page === 1) {
          draft.activityStream.lastRefreshed = new Date()
        }

        draft.activityStream.page = action.meta.previousAction.filter.page as number

        break
      }

      case EventActions.FETCH_USER_EVENTS_FAIL: {
        draft.meta = stateUtils.setErrorState(state.meta, action.error)
        break
      }

      case EventActions.GO_TO_PAGE: {
        draft.pagination.page = action.page
        draft.meta.loaded = state.pagination.pages[action.page] && state.pagination.pages[action.page].length > 0
        break
      }
    }
  })
}
