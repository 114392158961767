import { MessageContexts, MessageContext } from '../store/messages/types'
import { UserInfo } from '../store/user/reducers'
import { AppState } from '../store'
import { Client } from '../store/clients/reducer'
import { clientByIdSelector } from './clients'

export type ContextType = Client | UserInfo | null

// Given a context and context ID, fetch either a user or client record, returning null if it doesn't exist
export const getContextRecord = (state: AppState, context: MessageContexts, contextID: number): ContextType => {
  switch (context) {
    case 'user':
      return state.userReducer.users[contextID]
    case 'client':
      return clientByIdSelector(state.clientReducer, contextID)
    default:
      return null
  }
}

export const getEmailAddressesForContext = (
  state: AppState,
  context: MessageContext,
  contextID: number,
  orderID?: number | null
): [string, string] => {
  const order = orderID ? state.orderReducer.orders[orderID] : null

  let environment = ''

  if (window.location) {
    if (
      window.location.host.includes('stg') ||
      window.location.host.includes('dev') ||
      window.location.host.includes('staging')
    ) {
      environment = 'staging-'
    }
  }

  switch (context) {
    case MessageContexts.USER: {
      const toEmail = state.userReducer.users[contextID].email
      const clientID = order ? `-${order.client_id}` : ''
      const fromEmail = `Talent Inc OMS <${environment}user${contextID}${clientID}@oms.talentinc.com>`

      return [toEmail, fromEmail]
    }

    case MessageContexts.CLIENT: {
      if (!order) {
        throw Error('Must provide an orderID to generate an order email address')
      }

      const client = clientByIdSelector(state.clientReducer, contextID)
      const brand = order.brand
      const toEmail = client.primary_email
      const fromEmail = `${brand.name} <${environment}order${order.client_id}@${brand.email_domain}>`

      return [toEmail, fromEmail]
    }

    default:
      return ['', '']
  }
}
