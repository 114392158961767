import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'react-use'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import { getSummaryData, getSummaryDataMeta } from '../../../store/autoresearch/selectors'
import { fetchClientSummaryData } from '../../../store/autoresearch/actions'
import { AppState } from '../../../store'
import { LoadedLoadingErrorState } from '../../../utils/state'
import i18n from '../../../i18n'
import SuggestionIcon from '../../../assets/svgs/suggestion.svg'
import Loader from '../../common/Loader'
import { ClientSummaryData } from '../../../store/autoresearch/types'
import { Tooltip } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '1.5rem',
      marginBottom: '0.75rem',
      fontWeight: 'bold',
    },
    titleDesciption: {
      marginBottom: '1.5rem',
      fontSize: '.9375rem',
      fontWeight: 400,
    },
    suggestedDataTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '1.5rem',
      marginBottom: '1.5rem',
    },
    suggestedDataText: {
      fontWeight: 600,
      fontSize: '1.0625rem',
    },
    suggestedDataIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    suggestedDataContainer: {
      border: '1px solid',
      backgroundColor: '#0DA1EC0F',
      borderRadius: '1.25rem',
      borderColor: '#0DA1EC0F',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: '1rem',
    },
    dividerStyle: {
      marginTop: '2.25rem',
      marginBottom: '2.25rem',
    },
    suggestedDataKeywords: {
      display: 'flex',
      flexDirection: 'column',
    },
    suppliedByClient: {
      fontWeight: 600,
      marginTop: '1.5rem',
      marginBottom: '0.75rem',
    },
    suggestedDataItem: {
      fontSize: '.9375rem',
      fontWeight: 400,
    },
    keywordDescription: {
      fontWeight: 400,
      fontSize: '.9375rem',
      marginBottom: '0.5rem',
    },
    tooltip: {
      fontSize: '.9375rem',
    },
  })
)

interface Props {
  clientID: number
}

const OrderSumaryTab: React.FC<Props> = ({ clientID }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const meta = useSelector<AppState, LoadedLoadingErrorState>(state =>
    getSummaryDataMeta(state.autoResearchReducer, clientID)
  )

  const summaryData = useSelector<AppState, ClientSummaryData | undefined | null>(state =>
    getSummaryData(state.autoResearchReducer, clientID)
  )

  useEffectOnce(() => {
    if (!meta || (!meta.loaded && !meta.isLoading)) {
      dispatch(fetchClientSummaryData(clientID))
    }
  })

  const clientSummary = summaryData?.client_supplied_summary
  const suggestedSummary = summaryData?.suggested_summary_sentences
  const clientSkills = summaryData?.client_supplied_skills
  const suggestedSkills = summaryData?.suggested_skills

  const hasData =
    suggestedSummary?.length ||
    clientSummary ||
    suggestedSkills?.soft_skills?.length ||
    suggestedSkills?.hard_skills?.length ||
    clientSkills?.length

  const hasSkills = suggestedSkills?.soft_skills?.length || suggestedSkills?.hard_skills?.length || clientSkills?.length

  if (!!meta && meta.isLoading) {
    return <Loader fullPage={true} />
  }

  return hasData ? (
    <Box>
      {suggestedSummary?.length || clientSummary ? (
        <>
          <Box>
            <Typography className={classes.title}>{i18n.t('order__page__tab__title__summary')}</Typography>
            <Typography className={classes.titleDesciption}>
              {i18n.t('order__page__summaryTab__summary__desciption')}
            </Typography>
          </Box>
          {suggestedSummary?.length ? (
            <Box className={classes.suggestedDataContainer}>
              <Box className={classes.suggestedDataTitle}>
                <Box className={classes.suggestedDataIcon}>
                  <img src={SuggestionIcon} alt="help" style={{ marginRight: '0.5rem' }} />
                  <Box>
                    <Typography className={classes.suggestedDataText}>Suggested Data</Typography>
                  </Box>
                </Box>
                <Box>
                  <Tooltip
                    title={i18n.t('order__page__summaryTab__summary__tooltip') as string}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <HelpOutlineIcon style={{ fill: '#2196F3' }} fontSize="small" />
                  </Tooltip>
                </Box>
              </Box>
              <Box component="ul" style={{ paddingLeft: '1rem' }}>
                {suggestedSummary?.map(item => (
                  <Typography className={classes.suggestedDataItem} component={'li'}>
                    {item}
                  </Typography>
                ))}
              </Box>
            </Box>
          ) : null}

          {clientSummary ? (
            <Box>
              <Typography className={classes.suppliedByClient}>
                {i18n.t('order__page__summaryTab__supplied__by__client')}
              </Typography>
              <Typography className={classes.suggestedDataItem}>{clientSummary}</Typography>
            </Box>
          ) : null}

          {hasSkills ? (
            <Box className={classes.dividerStyle}>
              <hr />
            </Box>
          ) : null}
        </>
      ) : null}
      {hasSkills ? (
        <>
          <Box>
            <Typography className={classes.title}>{i18n.t('order__page__summaryTab__skill__and__keywords')}</Typography>
            <Typography className={classes.titleDesciption}>
              {i18n.t('order__page__summaryTab__essential__keywords__clients__resume')}
            </Typography>
          </Box>
          {suggestedSkills?.soft_skills?.length || suggestedSkills?.hard_skills?.length ? (
            <Box className={classes.suggestedDataContainer}>
              <Box className={classes.suggestedDataTitle}>
                <Box className={classes.suggestedDataIcon}>
                  <img src={SuggestionIcon} alt="help" style={{ marginRight: '0.5rem' }} />
                  <Box>
                    <Typography className={classes.suggestedDataText}>
                      {i18n.t('order__page__summaryTab__suggested__data')}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Tooltip
                    title={i18n.t('order__page__summaryTab__skills__tooltip') as string}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <HelpOutlineIcon style={{ fill: '#2196F3' }} fontSize="small" />
                  </Tooltip>
                </Box>
              </Box>
              <Box className={classes.suggestedDataKeywords}>
                {suggestedSkills?.soft_skills?.length ? (
                  <Typography className={classes.keywordDescription}>
                    <strong>{i18n.t('order__page__summaryTab__soft_skills')} </strong>{' '}
                    {suggestedSkills.soft_skills.join(', ')}
                  </Typography>
                ) : null}
                {suggestedSkills?.hard_skills?.length ? (
                  <Typography className={classes.keywordDescription}>
                    <strong>{i18n.t('order__page__summaryTab__hard_skills')} </strong>{' '}
                    {suggestedSkills.hard_skills.join(', ')}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          ) : null}
          {clientSkills?.length ? (
            <Box>
              <Typography className={classes.suppliedByClient}>
                {i18n.t('order__page__summaryTab__supplied__by__client')}
              </Typography>
              <Typography className={classes.titleDesciption}>{clientSkills?.join(', ')}</Typography>
            </Box>
          ) : null}
        </>
      ) : null}
    </Box>
  ) : (
    <Typography>{i18n.t('order__page__summaryTab__has_no_data')}</Typography>
  )
}

export default OrderSumaryTab
