import React, { useCallback } from 'react'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import isSameDay from 'date-fns/isSameDay'
import dateFormat from 'date-fns/format'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Brand, PhoneConsultationDetails } from '../../../store/scheduling/types'
import CalendarStyle from '../Styles'
import CallIcon from '@material-ui/icons/Call'
import { callTime } from '../utils'
import i18n from '../../../i18n'
import { OrderItemStates } from '../../../store/items/types'
import { useSelector } from 'react-redux'
import { AppState } from '../../../store'
import { phoneConsultationItemsSelector } from '../../../store/scheduling/selectors'
import { utcToZonedTime } from 'date-fns-tz'
import useUserInfoState from '../../common/useUserInfo'
import Button from '@material-ui/core/Button'
import TopInterview from '../../../assets/topinterview.png'
import TopResume from '../../../assets/topresume.png'
import { addMinutes } from 'date-fns'
import { useHistory } from 'react-router'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import clsx from 'clsx'
import { GhostLink } from '../../../utils/link'

interface Props {
  selectedDate: Date
  setEditView: (editView: boolean) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    participants: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      padding: '0 2rem 0 1rem',
      minWidth: '20rem',
      minHeight: '29.2rem',
    },
    participant: {
      padding: '1rem',
      borderBottom: '1px solid #edf0f5',
      borderTop: '1px solid #edf0f5',
    },
    listItems: {
      color: 'inherit',
      alignItems: 'flex-start',
      padding: '0',
    },
    listIcon: {
      width: '1.25rem',
      height: '1.25rem',
      marginRight: '.5rem',
    },
    callInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    link: {
      color: 'inherit',
      '&:hover': {
        color: 'inherit',
      },
    },
    callTime: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '.75rem',
    },
    callWith: {
      fontWeight: 600,
      marginBottom: '.2rem',
    },
    noCall: {
      fontWeight: 600,
      color: '#b7c2d8',
      textAlign: 'center',
      padding: '1rem',
    },
    selectView: {
      marginRight: '1rem',
      alignSelf: 'flex-end',
      width: '7rem',
    },
    labelSm: {
      fontSize: CalendarStyle.fontSizeSm,
      color: 'black',
    },
    labelSmRed: {
      fontSize: CalendarStyle.fontSizeSm,
      color: 'red',
    },
    toolIconBase: {
      position: 'absolute',
      bottom: '2.25rem',
      right: '1.4rem',
      width: '9.5rem',
      height: '2.25rem',
      border: 'solid 1px #333f57',
      borderRadius: '3px',
      textTransform: 'capitalize',
      fontSize: '1rem',
    },
    callIcon: {
      cursor: 'pointer',
      width: '1.5rem',
      height: '1.5rem',
    },
  })
)

const ParticipantList: React.FC<Props> = ({ selectedDate, setEditView }) => {
  const classes = useStyles()
  const history = useHistory()
  const { timeZone } = useUserInfoState()

  const phoneConsultationItems = useSelector<AppState, PhoneConsultationDetails[]>(state =>
    phoneConsultationItemsSelector({
      ...state.itemsReducer,
      ...state.orderReducer,
      ...state.clientReducer,
    })
  )
  const current = utcToZonedTime(new Date(), timeZone)
  const selectedDayEvents = phoneConsultationItems.filter(
    details =>
      (details.item_status === OrderItemStates.ConductCall ||
        details.item_status === OrderItemStates.ConductInterview) &&
      isSameDay(new Date(selectedDate), utcToZonedTime(details.item_due_at || 0, timeZone))
  )

  const callNow = useCallback(
    (clientID: number) => {
      history.push({
        pathname: `/client/${clientID}`,
        search: window.location.search,
      })
    },
    [history]
  )

  return (
    <Box display="flex" justifyContent="flex-start" flexDirection="column" className={classes.participants}>
      <List className={classes.listItems}>
        {selectedDayEvents && selectedDayEvents.length > 0 ? (
          selectedDayEvents.map(event => {
            const event_due_at = utcToZonedTime(event.item_due_at || 0, timeZone)
            return (
              event.meeting_id && (
                <Box key={event.item_id} className={classes.participant}>
                  <Box className={classes.callInfo}>
                    <Box>
                      <Box className={classes.callTime}>
                        <img
                          src={event.brand === Brand.TopResume ? TopResume : TopInterview}
                          className={classes.listIcon}
                          alt={event.brand}
                        />
                        <Typography className={classes.labelSm}>
                          {dateFormat(event_due_at, 'h:mm a')} - {dateFormat(addMinutes(event_due_at, 30), 'h:mm a')}
                        </Typography>
                      </Box>
                      <Box className={classes.callWith}>
                        <Typography variant={'body2'} className={classes.callWith}>
                          <GhostLink to={`/client/${event.client_id}`} className={classes.link}>
                            {event.client_name} (#{event.client_id})
                          </GhostLink>
                        </Typography>
                      </Box>
                      <Typography
                        className={clsx(
                          classes.labelSm,
                          differenceInMinutes(event_due_at, current) <= 0 &&
                            differenceInMinutes(event_due_at, current) > -30
                            ? classes.labelSmRed
                            : classes.labelSm
                        )}
                      >
                        {!event.meeting_occurred && callTime(current, event_due_at)}
                      </Typography>
                    </Box>
                    <Box>
                      {event.meeting_id &&
                        differenceInMinutes(event_due_at, current) <= 15 &&
                        differenceInMinutes(event_due_at, current) > -30 && (
                          <CallIcon onClick={() => callNow(event.client_id)} className={classes.callIcon} />
                        )}
                    </Box>
                  </Box>
                </Box>
              )
            )
          })
        ) : (
          <Box className={classes.participant}>
            <Box className={classes.noCall}>
              <Typography variant={'body2'}> {i18n.t('schedule_calendar_no_call')}</Typography>
            </Box>
          </Box>
        )}
      </List>
      <Button onClick={() => setEditView(true)} className={classes.toolIconBase}>
        {i18n.t('schedule_calendar_setting')}
      </Button>
    </Box>
  )
}

export default ParticipantList
