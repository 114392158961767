import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'

import styles from '../../styles'

const CustomCheckbox = withStyles({
  root: {
    color: styles.palette.contrast.color,
    '&$checked': {
      color: styles.palette.contrast.color,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

export default CustomCheckbox
