import React from 'react'
import { useSelector } from 'react-redux'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import OrderDocuments from './OrderDocuments'
import { AppState } from '../../store'
import { Document } from '../../store/documents/types'
import {
  getDocumentUploadsByClient,
  getDocumentUploadsForClient,
  getDocumentUploadsForEditors,
} from '../../selectors/documents'

const useStyles = makeStyles(() =>
  createStyles({
    leftColumn: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      transform: 'translateZ(0)',
    },
  })
)

interface DocumentsParams {
  clientID: number
}

const Documents = ({ clientID }: DocumentsParams) => {
  const classes = useStyles()
  const writerUploads = useSelector<AppState, Document[]>(store =>
    getDocumentUploadsForClient(store.documentReducer, clientID)
  )
  const clientUploads = useSelector<AppState, Document[]>(store =>
    getDocumentUploadsByClient(store.documentReducer, store.messagesReducer, clientID)
  )
  const editorUploads = useSelector<AppState, Document[]>(store =>
    getDocumentUploadsForEditors(store.documentReducer, clientID)
  )
  const orderIDs = useSelector<AppState, number[]>(store => store.clientReducer.clients[clientID].order_ids)

  return (
    <Box className={classes.leftColumn}>
      <OrderDocuments
        editorUploads={editorUploads}
        writerUploads={writerUploads}
        clientUploads={clientUploads}
        clientID={clientID}
        orderIDs={orderIDs}
      />
    </Box>
  )
}

export default Documents
