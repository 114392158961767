import produce from 'immer'

import * as stateUtils from '../../utils/state'
import { HashMap } from '../items/types'
import { AIDraftActionTypes, AIDraftActionType } from './actions'
import { LoadedLoadingErrorState } from '../../utils/state'

export interface AIDraftLoadedLoadingState {
  individual: HashMap<LoadedLoadingErrorState>
}

export interface AIDraft {
  isAIDraftExists?: boolean
  isAIFeedbackExists?: boolean
  aiDraftMagicLink?: string
}

export interface ClientAIDraftState {
  meta: AIDraftLoadedLoadingState
  aiDraft: HashMap<AIDraft>
  jobRoles: string[]
}

export const initialState: ClientAIDraftState = {
  meta: {
    individual: {
      ...stateUtils.initialMultiLoadedLoadingErrorState.individual,
    },
  },
  aiDraft: {},
  jobRoles: [],
}

export default function clientAIDraftReducer(
  state: ClientAIDraftState = initialState,
  action: AIDraftActionType
): ClientAIDraftState {
  return produce(state, (draft: ClientAIDraftState) => {
    switch (action.type) {
      case AIDraftActionTypes.FETCH_IS_AI_DRAFT_EXISTS:
        {
          const clientID = action.clientID

          draft.meta.individual[clientID] = stateUtils.setStartState()
        }
        break

      case AIDraftActionTypes.FETCH_IS_AI_DRAFT_EXISTS_SUCCESS:
        {
          const response = action.payload.data
          const clientID = action.meta.previousAction.clientID

          draft.meta.individual[clientID] = stateUtils.setSuccessState()

          if (!draft.aiDraft[clientID]) {
            draft.aiDraft[clientID] = {}
          }

          if (!!response) {
            draft.aiDraft[clientID].isAIDraftExists = response.draft_exists
          }
        }
        break

      case AIDraftActionTypes.FETCH_IS_AI_DRAFT_EXISTS_FAIL:
        {
          const clientID = action.meta.previousAction.clientID
          const status = !!action.error.response ? action.error.response.status : -1
          if (status === 404) {
            draft.meta.individual[clientID] = stateUtils.setSuccessState()
          } else {
            draft.meta.individual[clientID] = stateUtils.setErrorState(state.meta.individual[clientID], action.error)
          }
        }
        break

      case AIDraftActionTypes.FETCH_JOB_ROLES_SUCCESS:
        {
          const response = action.payload.data

          if (!!response) {
            draft.jobRoles = response.job_roles ?? []
          }
        }
        break

      case AIDraftActionTypes.GENERATE_AI_DRAFT_SUCCESS:
        {
          const clientID = action.meta.previousAction.clientID
          const response = action.payload.data

          if (!!response) {
            draft.aiDraft[clientID].isAIDraftExists = undefined
          }
        }
        break

      case AIDraftActionTypes.FETCH_IS_AI_FEEDBACK_EXISTS_SUCCESS:
        {
          const response = action.payload.data
          const clientID = action.meta.previousAction.clientID

          if (!draft.aiDraft[clientID]) {
            draft.aiDraft[clientID] = {}
          }

          if (!!response) {
            draft.aiDraft[clientID].isAIFeedbackExists = response.submitted_or_skipped
          }
        }
        break

      case AIDraftActionTypes.SUBMIT_OR_SKIP_AI_FEEDBACK_SUCCESS:
        {
          const clientID = action.meta.previousAction.clientID
          const response = action.payload.data

          if (!!response) {
            draft.aiDraft[clientID].isAIFeedbackExists = true
          }
        }
        break

      case AIDraftActionTypes.FETCH_AI_DRAFT_MAGIC_LINK:
        {
          const clientID = action.clientID

          if (!draft.aiDraft[clientID]) {
            draft.aiDraft[clientID] = {}
          }

          draft.aiDraft[clientID].aiDraftMagicLink = undefined
        }
        break

      case AIDraftActionTypes.FETCH_AI_DRAFT_MAGIC_LINK_SUCCESS:
        {
          const response = action.payload.data
          const clientID = action.meta.previousAction.clientID

          if (!!response) {
            draft.aiDraft[clientID].aiDraftMagicLink = response.link
          }
        }
        break
    }
  })
}
