import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import * as Sentry from '@sentry/browser'

import { AppState } from '..'
import {
  AxiosMiddlewareActionSuccess,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionCreator,
} from '../../utils/axios'
import { Config } from './types'
import { ResettableInterval } from '../cron/types'

export enum ConfigActions {
  FETCH_CONFIG = 'FETCH_CONFIG',
  FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS',
  FETCH_CONFIG_FAIL = 'FETCH_CONFIG_FAIL',
  UPDATE_CONFIG = 'UPDATE_CONFIG',
  UPDATE_CONFIG_SUCCESS = 'UPDATE_CONFIG_SUCCESS',
  UPDATE_CONFIG_FAIL = 'UPDATE_CONFIG_FAIL',
}

interface FetchConfig extends AxiosMiddlewareActionCreator {
  type: ConfigActions.FETCH_CONFIG
}

export interface FetchConfigSuccess extends AxiosMiddlewareActionSuccess<Config, FetchConfig> {
  type: ConfigActions.FETCH_CONFIG_SUCCESS
}

interface FetchConfigFail extends AxiosMiddlewareActionFail {
  type: ConfigActions.FETCH_CONFIG_FAIL
}

export function fetchConfig(): FetchConfig {
  return {
    type: ConfigActions.FETCH_CONFIG,
    payload: {
      request: {
        url: '/v1/config',
      },
    },
  }
}

export function fetchConfigAndConfigure(): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState) {
    await dispatch(fetchConfig())
    const { config } = getState().configReducer

    if (!config) {
      return
    }

    const loggedInUser = getState().userReducer.getLoggedInUser()
    const user: Sentry.User = {}

    if (config.intercom.user_id) {
      user.id = config.intercom.user_id.toString()
    }

    if (loggedInUser) {
      user.username = loggedInUser.uname
    }

    // Setup Sentry
    Sentry.init({
      ...config.sentry,
      ignoreErrors: [
        /Network Error/,
        'Non-Error promise rejection captured with keys: error, meta, type',
        'ResizeObserver loop limit exceeded',
        // This error is caused by a cron with a timestamp in the URL, which makes it impossible for
        // Sentry to group based upon error message. It isn't an error to worry about.
        /\[400\] GET \/api\/v1\/messages\/user\/me\/latest-incoming\?since=/,
      ],
    })
    Sentry.setUser(user)

    // Setup FullStory
    // @ts-ignore
    if (window.FS && window.FS.identify) {
      // @ts-ignore
      window.FS.identify(`${user.id}`, {
        email: config.intercom.email,
        displayName: config.intercom.name,
      })
    }
  }
}

interface UpdateConfig extends AxiosMiddlewareActionCreator {
  type: ConfigActions.UPDATE_CONFIG
}

export interface UpdateConfigSuccess extends AxiosMiddlewareActionSuccess<Config, FetchConfig> {
  type: ConfigActions.UPDATE_CONFIG_SUCCESS
}

interface UpdateConfigFail extends AxiosMiddlewareActionFail {
  type: ConfigActions.UPDATE_CONFIG_FAIL
}

export function updateConfig(): UpdateConfig {
  return {
    type: ConfigActions.UPDATE_CONFIG,
    payload: {
      request: {
        url: '/v1/config',
      },
    },
  }
}

export function updateConfigTimed(
  interval: number,
  forceUpdate: boolean
): ThunkAction<Promise<ResettableInterval>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<AppState, {}, AnyAction>) {
    if (forceUpdate) {
      dispatch(updateConfig())
    }

    const intervalID = window.setInterval(() => dispatch(updateConfig()), interval)
    return {
      intervalID,
      interval,
    }
  }
}

export type ConfigAction =
  | FetchConfig
  | FetchConfigSuccess
  | FetchConfigFail
  | UpdateConfig
  | UpdateConfigSuccess
  | UpdateConfigFail
