import { useEffect } from 'react'

// There are reasons why one wouldn't want to do thsi and they are outlined in the React Router docs. Unfortunately,
// they don't really apply to how we use this application.
// https://reacttraining.com/react-router/web/guides/scroll-restoration
export default function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return null
}
