import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import { AppState } from '..'
import { Incentive } from './types'
import { Groups } from '../user/reducers'
import { SendMessageSuccess } from '../messages/actions'
import { OrderItemStates, OrderCTA } from '../items/types'

export enum IncentiveActionTypes {
  // Fetch time based incentives for the client
  FETCH_TIME_BASED_INCENTIVES = 'FETCH_TIME_BASED_INCENTIVES',
  FETCH_TIME_BASED_INCENTIVES_SUCCESS = 'FETCH_TIME_BASED_INCENTIVES_SUCCESS',
  FETCH_TIME_BASED_INCENTIVES_FAIL = 'FETCH_TIME_BASED_INCENTIVES_FAIL',
}

export interface FetchTimeBasedIncentives extends AxiosMiddlewareActionCreator {
  type: IncentiveActionTypes.FETCH_TIME_BASED_INCENTIVES
  clientID: number
}

export interface FetchTimeBasedIncentivesSuccess
  extends AxiosMiddlewareActionSuccess<Incentive[], FetchTimeBasedIncentives> {
  type: IncentiveActionTypes.FETCH_TIME_BASED_INCENTIVES_SUCCESS
}

export interface FetchTimeBasedIncentivesFail extends AxiosMiddlewareActionFail<FetchTimeBasedIncentives> {
  type: IncentiveActionTypes.FETCH_TIME_BASED_INCENTIVES_FAIL
}

export function fetchTimeBasedIncentives(clientID: number, userID: number): FetchTimeBasedIncentives {
  return {
    type: IncentiveActionTypes.FETCH_TIME_BASED_INCENTIVES,
    clientID,
    payload: {
      request: {
        url: `/v1/dynamic-payments/time-based/${clientID}/user/${userID}`,
      },
    },
  }
}

export function fetchTimeBasedIncentivesSafely(
  clientID: number,
  userID: number | null
): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    if (!userID) {
      return
    }

    const state = getState()
    const user = state.userReducer.users[userID]

    // These users cannot get incentives
    if (user.groups.find(g => g === Groups.Offshore || g === Groups.Staff)) {
      return
    }

    const client = state.clientOrdersAggregateReducer.states[clientID]
    const eligibleForTimeIncentivesStates: OrderCTA[] = [OrderItemStates.SendIntro, OrderItemStates.SendFirstDraft]

    if (client && eligibleForTimeIncentivesStates.includes(client.CTA)) {
      dispatch(fetchTimeBasedIncentives(clientID, userID))
    }
  }
}

export type IncentiveAction =
  | FetchTimeBasedIncentives
  | FetchTimeBasedIncentivesSuccess
  | FetchTimeBasedIncentivesFail
  | SendMessageSuccess
