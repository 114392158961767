import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  fetchCalendarEvents,
  generateSchedule,
  setAvailabilityRules,
  setPreferences,
} from '../../../store/scheduling/actions'
import Box from '@material-ui/core/Box'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import DashboardStyles from '../../Dashboard/Styles'
import CalendarStyles from '../Styles'
import useUserInfoState from '../../common/useUserInfo'
import Trigger from './Trigger'
import Availability from './Availability'
import Preferences from './Preferences'
import { OnboardingStates } from '../../../store/scheduling/types'
import ErrorBoundary from '../../common/ErrorBoundary'
import { setWeeklyPeriod } from '../utils'
import { fetchUserPreferences } from '../../../store/user_preferences/actions'

interface Props {
  currentStep: string
}
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      width: DashboardStyles.fullWidth,
      backgroundColor: DashboardStyles.card.bg,
      border: DashboardStyles.card.border,
      boxShadow: DashboardStyles.card.shadow,
      minHeight: CalendarStyles.height,
    },
  })
)

const Onboarding: React.FC<Props> = ({ currentStep }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const userInfo = useUserInfoState()

  const [currentPage, setCurrentPage] = React.useState(currentStep)

  const onGenerateSchedule = useCallback(
    async () => {
      await dispatch(generateSchedule(userInfo.id))
      await dispatch(fetchUserPreferences())
      setCurrentPage(OnboardingStates.Availability)
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [userInfo.id, generateSchedule, fetchUserPreferences, setCurrentPage]
  )

  const onSetAvailability = useCallback(
    async (start_time: string, end_time: string) => {
      await dispatch(setAvailabilityRules(userInfo.id, setWeeklyPeriod(start_time, end_time)))
      setCurrentPage(OnboardingStates.Preferences)
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [userInfo.id, setAvailabilityRules, setCurrentPage]
  )

  const onSetPreferences = useCallback(
    async preferences => {
      await dispatch(setPreferences(userInfo.id, preferences))
      await dispatch(fetchCalendarEvents(userInfo.id))
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [userInfo.id, setPreferences, setCurrentPage]
  )
  const showCurrentStep = (currentPage: string) => {
    switch (currentPage) {
      case OnboardingStates.Trigger: {
        return <Trigger onGenerateSchedule={onGenerateSchedule} />
      }
      case OnboardingStates.Availability: {
        return <Availability onSetAvailability={onSetAvailability} />
      }
      case OnboardingStates.Preferences: {
        return <Preferences onSetPreferences={onSetPreferences} />
      }
    }
  }
  return (
    <ErrorBoundary>
      <Box className={classes.container}>{showCurrentStep(currentPage)}</Box>
    </ErrorBoundary>
  )
}

export default Onboarding
