import { NotificationsState, Notification } from '../store/notifications/types'

export const userNotifications = (state: NotificationsState): Notification[] => {
  return Object.values(state.notifications).sort((a: Notification, b: Notification) =>
    new Date(a.created_at) > new Date(b.created_at) ? -1 : 1
  )
}

export const unReadNotificationsCount = (state: NotificationsState): number => {
  return Object.values(state.notifications).filter(n => !n.read_at).length
}
