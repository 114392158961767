import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import { AutoLinkedin, AutoLinkedinRevision, AutoLinkedinUpdatePayload } from './types'
import { FetchClientOrdersSuccess } from '../clientOrderAggregate/actions'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AppState } from '../index'
import { AnyAction } from 'redux'
import { fetchClientDocuments } from '../documents/actions'

export enum AUTOLINKEDIN {
  GENERATE_AUTOLINKEDIN = 'GENERATE_AUTOLINKEDIN',
  GENERATE_AUTOLINKEDIN_SUCCESS = 'GENERATE_AUTOLINKEDIN_SUCCESS',
  GENERATE_AUTOLINKEDIN_FAIL = 'GENERATE_AUTOLINKEDIN_FAIL',

  GET_AUTOLINKEDIN_BY_ITEM = 'GET_AUTOLINKEDIN_BY_ITEM',
  GET_AUTOLINKEDIN_BY_ITEM_SUCCESS = 'GET_AUTOLINKEDIN_BY_ITEM_SUCCESS',
  GET_AUTOLINKEDIN_BY_ITEM_FAIL = 'GET_AUTOLINKEDIN_BY_ITEM_FAIL',

  UPDATE_AUTOLINKEDIN = 'UPDATE_AUTOLINKEDIN',
  UPDATE_AUTOLINKEDIN_SUCCESS = 'UPDATE_AUTOLINKEDIN_SUCCESS',
  UPDATE_AUTOLINKEDIN_FAIL = 'UPDATE_AUTOLINKEDIN_FAIL',

  PUBLISH_AUTOLINKEDIN_REVISION = 'PUBLISH_AUTOLINKEDIN_REVISION',
  PUBLISH_AUTOLINKEDIN_REVISION_SUCCESS = 'PUBLISH_AUTOLINKEDIN_REVISION_SUCCESS',
  PUBLISH_AUTOLINKEDIN_REVISION_FAIL = 'PUBLISH_AUTOLINKEDIN_REVISION_FAIL',

  START_FROM_REVISION = 'START_FROM_REVISION',
  START_FROM_REVISION_SUCCESS = 'START_FROM_REVISION_SUCCESS',
  START_FROM_REVISION_FAIL = 'START_FROM_REVISION_FAIL',
}

export interface StartFromRevision extends AxiosMiddlewareActionCreator {
  type: AUTOLINKEDIN.START_FROM_REVISION
  itemID: number
  revisionID: number
}

export interface StartFromRevisionSuccess extends AxiosMiddlewareActionSuccess<AutoLinkedin, StartFromRevision> {
  type: AUTOLINKEDIN.START_FROM_REVISION_SUCCESS
}

export interface StartFromRevisionFail extends AxiosMiddlewareActionFail<StartFromRevision> {
  type: AUTOLINKEDIN.START_FROM_REVISION_FAIL
}

export interface PublishAutolinkedinRevision extends AxiosMiddlewareActionCreator {
  type: AUTOLINKEDIN.PUBLISH_AUTOLINKEDIN_REVISION
  autoLinkID: number
  itemID: number
}

export interface PublishAutolinkedinRevisionSuccess
  extends AxiosMiddlewareActionSuccess<AutoLinkedinRevision, PublishAutolinkedinRevision> {
  type: AUTOLINKEDIN.PUBLISH_AUTOLINKEDIN_REVISION_SUCCESS
}

export interface PublishAutolinkedinRevisionFail extends AxiosMiddlewareActionFail<PublishAutolinkedinRevision> {
  type: AUTOLINKEDIN.PUBLISH_AUTOLINKEDIN_REVISION_FAIL
}

export interface UpdateAutoLinkedin extends AxiosMiddlewareActionCreator {
  type: AUTOLINKEDIN.UPDATE_AUTOLINKEDIN
  itemID: number
}

export interface UpdateAutoLinkedinSuccess extends AxiosMiddlewareActionSuccess<AutoLinkedin, GenerateAutoLinkedin> {
  type: AUTOLINKEDIN.UPDATE_AUTOLINKEDIN_SUCCESS
}

export interface UpdateAutoLinkedinFail extends AxiosMiddlewareActionFail<UpdateAutoLinkedin> {
  type: AUTOLINKEDIN.UPDATE_AUTOLINKEDIN_FAIL
}

export interface GenerateAutoLinkedin extends AxiosMiddlewareActionCreator {
  type: typeof AUTOLINKEDIN.GENERATE_AUTOLINKEDIN
  itemID: number
  baseDocID: number | null
}

export interface GenerateAutoLinkedinSuccess extends AxiosMiddlewareActionSuccess<AutoLinkedin, GenerateAutoLinkedin> {
  type: typeof AUTOLINKEDIN.GENERATE_AUTOLINKEDIN_SUCCESS
}

export interface GenerateAutoLinkedinFail extends AxiosMiddlewareActionFail<GenerateAutoLinkedin> {
  type: typeof AUTOLINKEDIN.GENERATE_AUTOLINKEDIN_FAIL
}

export interface GetAutoLinkedinByItemID extends AxiosMiddlewareActionCreator {
  type: typeof AUTOLINKEDIN.GET_AUTOLINKEDIN_BY_ITEM
  itemID: number
}

export interface GetAutoLinkedinByItemIDSuccess
  extends AxiosMiddlewareActionSuccess<AutoLinkedin, GetAutoLinkedinByItemID> {
  type: typeof AUTOLINKEDIN.GET_AUTOLINKEDIN_BY_ITEM_SUCCESS
}

export interface GetAutoLinkedinByItemIDFail extends AxiosMiddlewareActionFail<GetAutoLinkedinByItemID> {
  type: typeof AUTOLINKEDIN.GET_AUTOLINKEDIN_BY_ITEM_FAIL
}

export function generateAutoLinkedin(itemID: number, baseDocID: number | null): GenerateAutoLinkedin {
  return {
    type: AUTOLINKEDIN.GENERATE_AUTOLINKEDIN,
    itemID,
    baseDocID,
    payload: {
      request: {
        method: 'POST',
        url: `v1/auto-linkedin/items/${itemID}`,
        data: {
          base_doc_id: baseDocID,
        },
      },
    },
  }
}

export function fetchAutoLinkedinByItemID(itemID: number): GetAutoLinkedinByItemID {
  return {
    type: AUTOLINKEDIN.GET_AUTOLINKEDIN_BY_ITEM,
    itemID,
    payload: {
      request: {
        url: `v1/auto-linkedin/items/${itemID}`,
      },
    },
  }
}

export function updateAutoLinkedinByItemID(itemID: number, payload: AutoLinkedinUpdatePayload): UpdateAutoLinkedin {
  return {
    type: AUTOLINKEDIN.UPDATE_AUTOLINKEDIN,
    itemID,
    payload: {
      request: {
        method: 'PATCH',
        url: `v1/auto-linkedin/items/${itemID}`,
        data: payload,
      },
    },
  }
}

export function startFromRevision(itemID: number, revisionID: number): StartFromRevision {
  return {
    type: AUTOLINKEDIN.START_FROM_REVISION,
    itemID,
    revisionID,
    payload: {
      request: {
        method: 'PUT',
        url: `v1/auto-linkedin/items/${itemID}`,
        data: {
          revision_id: revisionID,
        },
      },
    },
  }
}

export function publishAutolinkedinRevision(itemID: number, autoLinkID: number): PublishAutolinkedinRevision {
  return {
    type: AUTOLINKEDIN.PUBLISH_AUTOLINKEDIN_REVISION,
    autoLinkID,
    itemID,
    payload: {
      request: {
        method: 'POST',
        url: `v1/auto-linkedin/publish/${autoLinkID}`,
      },
    },
  }
}

export function publishLinkedinRevisionThunk(
  itemID: number,
  autoLinkID: number,
  clientID: number
): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    await dispatch(publishAutolinkedinRevision(itemID, autoLinkID))

    try {
      const err = getState().autolinkedinReducer.byItemID[itemID].publishMeta.error
      if (!!err) {
        return
      }
      dispatch(fetchClientDocuments(clientID))
    } catch (e) {
      return
    }
  }
}

export type AutoLinkedinActionTypes =
  | GenerateAutoLinkedin
  | GenerateAutoLinkedinSuccess
  | GenerateAutoLinkedinFail
  | GetAutoLinkedinByItemID
  | GetAutoLinkedinByItemIDSuccess
  | GetAutoLinkedinByItemIDFail
  | UpdateAutoLinkedin
  | UpdateAutoLinkedinSuccess
  | UpdateAutoLinkedinFail
  | PublishAutolinkedinRevision
  | PublishAutolinkedinRevisionSuccess
  | PublishAutolinkedinRevisionFail
  | FetchClientOrdersSuccess
  | StartFromRevision
  | StartFromRevisionSuccess
  | StartFromRevisionFail
