import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'

import { DocumentItem, DocumentItemVariants } from '../Documents/DocumentItem'
import { Document } from '../../store/documents/types'

interface MessageAttachmentsProps {
  attachments: Document[]
  removeAttachment: (attachmentID: number) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& > *': {
        display: 'inline-flex',
        width: '12rem',
        marginTop: '.5rem',
        marginRight: '1rem',
        marginBottom: '0 !important',
        verticalAlign: 'top',
        '@media (max-width: 576px)': {
          width: '100%',
          marginRight: 0,
        },
      },
    },
  })
)

export const MessageAttachments: React.FC<MessageAttachmentsProps> = ({ attachments, removeAttachment }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {attachments.map((attachment, i) => (
        <DocumentItem
          document={attachment}
          variant={[DocumentItemVariants.Closeable, DocumentItemVariants.HalfHeight]}
          handleClick={removeAttachment}
          key={i}
        />
      ))}
    </div>
  )
}

export default MessageAttachments
