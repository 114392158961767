import { AxiosError } from 'axios'

import { HashMap } from './HashMap'

export interface LoadingErrorState {
  isLoading: boolean
  error: null | string | AxiosError
}

export const initialLoadingErrorState: LoadingErrorState = {
  isLoading: false,
  error: null,
}

export const startLoadingState: LoadingErrorState = {
  isLoading: true,
  error: null,
}

export interface LoadedLoadingErrorState extends LoadingErrorState {
  loaded: boolean
  lastLoaded?: Date
}

export const initialLoadedLoadingErrorState: LoadedLoadingErrorState = {
  ...initialLoadingErrorState,
  loaded: false,
}

export interface ProgressLoadingErrorState extends LoadingErrorState {
  progress: number
}

export interface MultiLoadedLoadingErrorState {
  global: LoadedLoadingErrorState
  individual: HashMap<LoadedLoadingErrorState>
}

export const initialMultiLoadedLoadingErrorState: MultiLoadedLoadingErrorState = {
  // This is the loading state for list views
  global: { ...initialLoadedLoadingErrorState },
  // This is the loading state for individual fetches
  individual: {},
}

// @TODO This needs to be Redux cache aware
export function itemNeedsFetching(id: number | string, state: MultiLoadedLoadingErrorState): boolean {
  return !state.individual[id] || (!state.individual[id].isLoading && !state.individual[id].loaded)
}

export function setStartState(
  state: LoadedLoadingErrorState = initialLoadedLoadingErrorState
): LoadedLoadingErrorState {
  return {
    ...state,
    ...initialLoadedLoadingErrorState,
    isLoading: true,
  }
}

export function setStartStateForItem(
  id: number | string,
  state: MultiLoadedLoadingErrorState
): MultiLoadedLoadingErrorState {
  return {
    global: state.global,
    individual: {
      ...state.individual,
      [id]: setStartState(),
    },
  }
}

export function setSuccessState(
  state: LoadedLoadingErrorState = initialLoadedLoadingErrorState
): LoadedLoadingErrorState {
  return {
    ...state,
    ...initialLoadingErrorState,
    loaded: true,
  }
}

export function setSuccessStateForItem(
  id: number | string,
  state: MultiLoadedLoadingErrorState
): MultiLoadedLoadingErrorState {
  return {
    global: state.global,
    individual: {
      ...state.individual,
      [id]: setSuccessState(),
    },
  }
}

export function setErrorState(
  state: LoadedLoadingErrorState = initialLoadedLoadingErrorState,
  error: string | AxiosError
): LoadedLoadingErrorState {
  return {
    ...state,
    ...initialLoadedLoadingErrorState,
    isLoading: false,
    loaded: true,
    error,
  }
}

export function setErrorStateForItem(
  id: number | string,
  state: MultiLoadedLoadingErrorState,
  error: string | AxiosError
): MultiLoadedLoadingErrorState {
  return {
    global: state.global,
    individual: {
      ...state.individual,
      [id]: setErrorState(undefined, error),
    },
  }
}
