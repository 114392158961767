import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItem from '@material-ui/core/ListItem'

import i18n from '../../i18n'
import Typography from '@material-ui/core/Typography'
import { PhoneCallsDue } from '../../store/dashboard/types'
import { GhostLink } from '../../utils/link'
import { Routes } from '../../utils/consts'
import CallIcon from '@material-ui/icons/Call'
import ErrorBoundary from '../common/ErrorBoundary'

interface PhoneCallDueInfo {
  count: number
  due: string
}

const getPhoneCallsDue = (phone_calls_due: PhoneCallsDue | null): PhoneCallDueInfo => {
  if (!phone_calls_due) {
    return { count: 0, due: i18n.t('phone_calls_due_thisweek') }
  }
  if (phone_calls_due && phone_calls_due.today > 0) {
    return { count: phone_calls_due.today, due: i18n.t('phone_calls_due_today') }
  } else if (phone_calls_due && phone_calls_due.tomorrow > 0) {
    return { count: phone_calls_due.tomorrow, due: i18n.t('phone_calls_due_tomorrow') }
  } else if (phone_calls_due && phone_calls_due.this_week > 0) {
    return { count: phone_calls_due.this_week, due: i18n.t('phone_calls_due_thisweek') }
  }
  return { count: phone_calls_due.this_week, due: i18n.t('phone_calls_due_thisweek') }
}

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      color: 'inherit',
      minWidth: '1.8em',
    },
    link: {
      fontWeight: 'bold',
      color: 'inherit',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
)

export const UpcomingPhoneCalls: React.FC<{ phoneCallsDue: PhoneCallsDue }> = ({ phoneCallsDue }) => {
  const phoneCallsData = getPhoneCallsDue(phoneCallsDue)
  const classes = useStyles()

  return (
    <ErrorBoundary>
      <ListItem>
        <ListItemIcon className={classes.icon}>
          <CallIcon />
        </ListItemIcon>
        <Typography variant={'body1'}>
          <GhostLink to={Routes.OpenOrders} className={classes.link}>
            {phoneCallsData.count > 1
              ? i18n.t('dashboard__macro__phonecallsclause__linkText', {
                  phoneCallCount: phoneCallsData.count,
                })
              : i18n.t('dashboard__macro__phonecallclause__linkText', {
                  phoneCallCount: phoneCallsData.count,
                })}
          </GhostLink>{' '}
          {phoneCallsData.due}
        </Typography>
      </ListItem>
    </ErrorBoundary>
  )
}

export default UpcomingPhoneCalls
