import { HashMap } from '../../utils/HashMap'
import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import {
  ClientJobData,
  ClientJobTitle,
  ClientSummaryData,
  ManualSearchResult,
  NewManualSearchResult,
  ONETJobDetails,
  ONETSearchResult,
  WorkHistory,
} from './types'

export enum AUTO_RESEARCH {
  FETCH_CLIENT_JOB_DATA = 'FETCH_CLIENT_JOB_DATA',
  FETCH_CLIENT_JOB_DATA_SUCCESS = 'FETCH_CLIENT_JOB_DATA_SUCCESS',
  FETCH_CLIENT_JOB_DATA_FAIL = 'FETCH_CLIENT_JOB_DATA_FAIL',

  FETCH_JOB_DETAILS = 'FETCH_JOB_DETAILS',
  FETCH_JOB_DETAILS_SUCCESS = 'FETCH_JOB_DETAILS_SUCCESS',
  FETCH_JOB_DETAILS_FAIL = 'FETCH_JOB_DETAILS_FAIL',

  SEARCH = 'SEARCH',
  SEARCH_SUCCESS = 'SEARCH_SUCCESS',
  SEARCH_FAIL = 'SEARCH_FAIL',

  DELETE_CLIENT_JOB = 'DELETE_CLIENT_JOB',
  DELETE_CLIENT_JOB_SUCCESS = 'DELETE_CLIENT_JOB_SUCCESS',
  DELETE_CLIENT_JOB_FAIL = 'DELETE_CLIENT_JOB_FAIL',

  SAVE_MANUAL_SEARCH = 'SAVE_MANUAL_SEARCH',
  SAVE_MANUAL_SEARCH_SUCCESS = 'SAVE_MANUAL_SEARCH_SUCCESS',
  SAVE_MANUAL_SEARCH_FAIL = 'SAVE_MANUAL_SEARCH_FAIL',

  DELETE_MANUAL_SEARCH = 'DELETE_MANUAL_SEARCH',
  DELETE_MANUAL_SEARCH_SUCCESS = 'DELETE_MANUAL_SEARCH_SUCCESS',
  DELETE_MANUAL_SEARCH_FAIL = 'DELETE_MANUAL_SEARCH_FAIL',

  RESET_CLIENT_JOB = 'RESET_CLIENT_JOB',
  RESET_CLIENT_JOB_SUCCESS = 'RESET_CLIENT_JOB_SUCCESS',
  RESET_CLIENT_JOB_FAIL = 'RESET_CLIENT_JOB_FAIL',

  REPLACE_CLIENT_JOB = 'REPLACE_CLIENT_JOB',
  REPLACE_CLIENT_JOB_SUCCESS = 'REPLACE_CLIENT_JOB_SUCCESS',
  REPLACE_CLIENT_JOB_FAIL = 'REPLACE_CLIENT_JOB_FAIL',

  FETCH_CLIENT_WORK_HISTORY = 'FETCH_CLIENT_WORK_HISTORY',
  FETCH_CLIENT_WORK_HISTORY_SUCCESS = 'FETCH_CLIENT_WORK_HISTORY_SUCCESS',
  FETCH_CLIENT_WORK_HISTORY_FAIL = 'FETCH_CLIENT_WORK_HISTORY_FAIL',

  FETCH_CLIENT_SUMMARY_DATA = 'FETCH_CLIENT_SUMMARY_DATA',
  FETCH_CLIENT_SUMMARY_DATA_SUCCESS = 'FETCH_CLIENT_SUMMARY_DATA_SUCCESS',
  FETCH_CLIENT_SUMMARY_DATA_FAIL = 'FETCH_CLIENT_SUMMARY_DATA_FAIL',
}

export interface ReplaceClientJob extends AxiosMiddlewareActionCreator {
  jobID: number
  clientID: number
  type: typeof AUTO_RESEARCH.REPLACE_CLIENT_JOB
}

export interface ReplaceClientJobSuccess extends AxiosMiddlewareActionSuccess<ClientJobTitle, ResetClientJob> {
  type: typeof AUTO_RESEARCH.REPLACE_CLIENT_JOB_SUCCESS
}

export interface ReplaceClientJobFail extends AxiosMiddlewareActionFail<ReplaceClientJob> {
  type: typeof AUTO_RESEARCH.REPLACE_CLIENT_JOB_FAIL
}

export interface ResetClientJob extends AxiosMiddlewareActionCreator {
  jobID: number
  clientID: number
  type: typeof AUTO_RESEARCH.RESET_CLIENT_JOB
}

export interface ResetClientJobSuccess extends AxiosMiddlewareActionSuccess<ClientJobTitle, ResetClientJob> {
  type: typeof AUTO_RESEARCH.RESET_CLIENT_JOB_SUCCESS
}

export interface ResetClientJobFail extends AxiosMiddlewareActionFail<ResetClientJob> {
  type: typeof AUTO_RESEARCH.RESET_CLIENT_JOB_FAIL
}

export interface DeleteManualSearch extends AxiosMiddlewareActionCreator {
  clientID: number
  ID: number
  type: typeof AUTO_RESEARCH.DELETE_MANUAL_SEARCH
}

export interface DeleteManualSearchSuccess extends AxiosMiddlewareActionSuccess<void, DeleteManualSearch> {
  type: typeof AUTO_RESEARCH.DELETE_MANUAL_SEARCH_SUCCESS
}

export interface DeleteManualSearchFail extends AxiosMiddlewareActionFail<DeleteManualSearch> {
  type: typeof AUTO_RESEARCH.DELETE_MANUAL_SEARCH_FAIL
}

export interface SaveManualSearch extends AxiosMiddlewareActionCreator {
  clientID: number
  type: typeof AUTO_RESEARCH.SAVE_MANUAL_SEARCH
}

export interface SaveManualSearchSuccess extends AxiosMiddlewareActionSuccess<ManualSearchResult, SaveManualSearch> {
  type: typeof AUTO_RESEARCH.SAVE_MANUAL_SEARCH_SUCCESS
}

export interface SaveManualSearchFail extends AxiosMiddlewareActionFail<SaveManualSearch> {
  type: typeof AUTO_RESEARCH.SAVE_MANUAL_SEARCH_FAIL
}

export interface Search extends AxiosMiddlewareActionCreator {
  keyword: string
  type: typeof AUTO_RESEARCH.SEARCH
}

export interface SearchSuccess extends AxiosMiddlewareActionSuccess<ONETSearchResult, Search> {
  type: typeof AUTO_RESEARCH.SEARCH_SUCCESS
}

export interface SearchFail extends AxiosMiddlewareActionFail<Search> {
  type: typeof AUTO_RESEARCH.SEARCH_FAIL
}

export interface FetchJobDetails extends AxiosMiddlewareActionCreator {
  onetSocCode: string
  type: typeof AUTO_RESEARCH.FETCH_JOB_DETAILS
}

export interface FetchJobDetailsSuccess extends AxiosMiddlewareActionSuccess<ONETJobDetails, FetchJobDetails> {
  type: typeof AUTO_RESEARCH.FETCH_JOB_DETAILS_SUCCESS
}

export interface FetchJobDetailsFail extends AxiosMiddlewareActionFail<FetchJobDetails> {
  type: typeof AUTO_RESEARCH.FETCH_JOB_DETAILS_FAIL
}

export interface FetchClientJobData extends AxiosMiddlewareActionCreator {
  clientID: number
  type: typeof AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA
}

export interface FetchClientJobDataSuccess extends AxiosMiddlewareActionSuccess<ClientJobData, FetchClientJobData> {
  type: typeof AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA_SUCCESS
}

export interface FetchClientJobDataFail extends AxiosMiddlewareActionFail<FetchClientJobData> {
  type: typeof AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA_FAIL
}

export interface DeleteClientJob extends AxiosMiddlewareActionCreator {
  jobHistoryId: number
  clientID: number
  type: typeof AUTO_RESEARCH.DELETE_CLIENT_JOB
}

export interface DeleteClientJobSuccess extends AxiosMiddlewareActionSuccess<void, DeleteClientJob> {
  type: typeof AUTO_RESEARCH.DELETE_CLIENT_JOB_SUCCESS
}

export interface DeleteClientJobFail extends AxiosMiddlewareActionFail<DeleteClientJob> {
  type: typeof AUTO_RESEARCH.DELETE_CLIENT_JOB_FAIL
}

export interface FetchClientWorkHistory extends AxiosMiddlewareActionCreator {
  clientID: number
  type: typeof AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY
}

export interface FetchClientWorkHstorySuccess
  extends AxiosMiddlewareActionSuccess<HashMap<WorkHistory>, FetchClientWorkHistory> {
  type: typeof AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY_SUCCESS
}

export interface FetchClientWorkHistoryFail extends AxiosMiddlewareActionFail<FetchClientWorkHistory> {
  type: typeof AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY_FAIL
}

export interface FetchClientSummaryData extends AxiosMiddlewareActionCreator {
  clientID: number
  type: typeof AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA
}

export interface FetchClientSummaryDataSuccess
  extends AxiosMiddlewareActionSuccess<ClientSummaryData, FetchClientSummaryData> {
  type: typeof AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA_SUCCESS
}

export interface FetchClientSummaryDataFail extends AxiosMiddlewareActionFail<FetchClientSummaryData> {
  type: typeof AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA_FAIL
}

export function resetClientJob(clientID: number, jobID: number): ResetClientJob {
  return {
    type: AUTO_RESEARCH.RESET_CLIENT_JOB,
    clientID: clientID,
    jobID: jobID,
    payload: {
      request: {
        url: `v1/auto-research/jobs/${jobID}`,
        method: 'PUT',
      },
    },
  }
}

export function searchOccupation(keyword: string, start: number, end: number): Search {
  return {
    type: AUTO_RESEARCH.SEARCH,
    keyword: keyword,
    payload: {
      request: {
        url: `v1/auto-research/jobs/search`,
        method: 'GET',
        params: {
          keyword,
          start,
          end,
        },
      },
    },
  }
}

export function fetchClientJobData(clientID: number): FetchClientJobData {
  return {
    type: AUTO_RESEARCH.FETCH_CLIENT_JOB_DATA,
    clientID: clientID,
    payload: {
      request: {
        url: `v1/auto-research/jobs/client/${clientID}`,
        method: 'GET',
      },
    },
  }
}

export function fetchJobDetails(onetSocCode: string): FetchJobDetails {
  return {
    type: AUTO_RESEARCH.FETCH_JOB_DETAILS,
    onetSocCode: onetSocCode,
    payload: {
      request: {
        url: `v1/auto-research/jobs/details/${onetSocCode}`,
        method: 'GET',
      },
    },
  }
}

export function deleteClientJob(jobHistoryID: number, clientID: number): DeleteClientJob {
  return {
    type: AUTO_RESEARCH.DELETE_CLIENT_JOB,
    jobHistoryId: jobHistoryID,
    clientID: clientID,
    payload: {
      request: {
        method: 'DELETE',
        url: `v1/auto-research/jobs/${jobHistoryID}`,
      },
    },
  }
}
export function saveManualSearch(clientID: number, newResult: NewManualSearchResult): SaveManualSearch {
  return {
    type: AUTO_RESEARCH.SAVE_MANUAL_SEARCH,
    clientID,
    payload: {
      request: {
        url: `v1/auto-research/manual-search`,
        method: 'POST',
        data: newResult,
      },
    },
  }
}

export function deleteManualSearch(clientID: number, manualSearchID: number): DeleteManualSearch {
  return {
    type: AUTO_RESEARCH.DELETE_MANUAL_SEARCH,
    clientID,
    ID: manualSearchID,
    payload: {
      request: {
        url: `v1/auto-research/manual-search/${manualSearchID}`,
        method: 'DELETE',
      },
    },
  }
}

export function replaceClientJob(clientID: number, jobID: number, newValue: NewManualSearchResult): ReplaceClientJob {
  return {
    type: AUTO_RESEARCH.REPLACE_CLIENT_JOB,
    clientID,
    jobID,
    payload: {
      request: {
        url: `v1/auto-research/jobs/${jobID}`,
        method: 'POST',
        data: newValue,
      },
    },
  }
}

export function fetchClientWorkHistory(clientID: number): FetchClientWorkHistory {
  return {
    type: AUTO_RESEARCH.FETCH_CLIENT_WORK_HISTORY,
    clientID: clientID,
    payload: {
      request: {
        url: `v1/auto-research/work-data/${clientID}`,
        method: 'GET',
      },
    },
  }
}

export function fetchClientSummaryData(clientID: number): FetchClientSummaryData {
  return {
    type: AUTO_RESEARCH.FETCH_CLIENT_SUMMARY_DATA,
    clientID: clientID,
    payload: {
      request: {
        url: `v1/auto-research/ardata/${clientID}`,
        method: 'GET',
      },
    },
  }
}

export type AutoResearchActionTypes =
  | FetchClientJobData
  | FetchClientJobDataSuccess
  | FetchClientJobDataFail
  | FetchJobDetails
  | FetchJobDetailsSuccess
  | FetchJobDetailsFail
  | Search
  | SearchSuccess
  | SearchFail
  | DeleteClientJob
  | DeleteClientJobSuccess
  | DeleteClientJobFail
  | SaveManualSearch
  | SaveManualSearchSuccess
  | SaveManualSearchFail
  | DeleteManualSearch
  | DeleteManualSearchSuccess
  | DeleteManualSearchFail
  | ResetClientJob
  | ResetClientJobFail
  | ResetClientJobSuccess
  | ReplaceClientJob
  | ReplaceClientJobSuccess
  | ReplaceClientJobFail
  | FetchClientWorkHistory
  | FetchClientWorkHstorySuccess
  | FetchClientWorkHistoryFail
  | FetchClientSummaryData
  | FetchClientSummaryDataSuccess
  | FetchClientSummaryDataFail
