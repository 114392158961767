import React, { useCallback, useState } from 'react'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CalendarStyle from '../Styles'
import { CalendarLinkType, Direction, Preferences } from '../../../store/scheduling/types'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import i18n from '../../../i18n'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import LinkIcon from '@material-ui/icons/Link'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import YesNoDialog from '../../AutoLinkedin/YesNoDialog'
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../store'
import { getCalendarLinkageInfo } from '../../../store/scheduling/selectors'
import { disconnectCalendar, fetchSchedulingPreferences, fetchCalendarEvents } from '../../../store/scheduling/actions'
import useUserInfoState from '../../common/useUserInfo'
import diff from 'object-diff'

interface Props {
  userID: number
  preferences: Preferences
  setEditView: (editView: boolean) => void
  onPreferencesUpdate: (preferences: Preferences) => void
  saveAvailability: () => void
  availabilitySaved: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    participants: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      paddingRight: '1rem',
      maxWidth: '20rem',
      minHeight: '29.2rem',
    },
    icon: {
      width: '1.625rem',
      padding: '0',
      paddingLeft: '4px',
      marginRight: '2px',
      color: CalendarStyle.color.cta,
      '&:hover': {
        color: CalendarStyle.color.ctaHover,
      },
    },
    toolIconBase: {
      backgroundColor: CalendarStyle.color.cta,
      color: 'white',
      position: 'absolute',
      bottom: '2.27rem',
      right: '1.4rem',
      width: '9.5rem',
      height: '2.25rem',
      borderRadius: '4px',
      textTransform: 'capitalize',
      fontSize: '1rem',
      '&:hover': {
        backgroundColor: CalendarStyle.color.ctaHover,
      },
    },
    toolIcon: {
      bottom: '0.2rem',
      right: '1.4rem',
      width: '1rem',
      height: '1rem',
    },
    formGroupAll: {
      display: 'flex',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'row',
    },
    label: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      color: 'black',
    },
    formLabel: {
      fontSize: '.9rem',
      color: 'black',
    },
    formCheck: {
      textAlign: 'center',
      fontSize: '.8rem',
      color: 'black',
    },
    textField: {
      marginTop: '1rem',
      marginLeft: '1rem',
    },
    numberField: {
      width: '4rem',
      marginTop: '1rem',
      marginLeft: '2rem',
      textAlign: 'center',
    },
    '& .MuiTextField-root': {
      textAlign: 'center',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1.2rem',
    },
    paperContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '10px',
    },
    paperContentLink: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '10px',
      paddingLeft: '4px',
    },
    spacer: {
      marginRight: '5px',
    },
    checkbox: {
      color: '#3d5071',
    },
    showWeekend: {
      display: 'none',
    },
    switchBase: {},
  })
)

const UserPreferences: React.FC<Props> = ({
  preferences,
  setEditView,
  userID,
  onPreferencesUpdate,
  saveAvailability,
  availabilitySaved,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const userInfo = useUserInfoState()
  const [values, setValues] = useState({
    ...preferences,
  })
  const [linkCalendar, setLinkCalendar] = useState(false)
  const [unlinkCalendar, setUnlinkCalendar] = useState(false)
  const userAccount = useSelector<AppState, number>(state => getCalendarLinkageInfo(state.schedulingReducer))

  const handleBufferChange = (dir: number) => {
    switch (dir) {
      case Direction.Dec:
        values.buffer > 1 && setValues({ ...values, buffer: values.buffer - 5 })
        break

      case Direction.Inc:
        values.buffer < 60 && setValues({ ...values, buffer: values.buffer + 5 })
        break
    }
  }
  const handleNoticeChange = (dir: number) => {
    switch (dir) {
      case Direction.Dec:
        values.min_notice > 1 && setValues({ ...values, min_notice: values.min_notice - 1 })
        break

      case Direction.Inc:
        values.min_notice < 24 && setValues({ ...values, min_notice: values.min_notice + 1 })
        break
    }
  }

  const handleReminderToggle = useCallback(
    event => {
      setValues({ ...values, [event.target.name]: event.target.checked })
    },
    [values]
  )

  const handleSubmitClick = async () => {
    const updatedPreferences: Preferences = {
      ...preferences,
      ...values,
    }
    const delta = diff(updatedPreferences, preferences)
    if (Object.keys(delta).length > 0 || availabilitySaved) {
      onPreferencesUpdate(updatedPreferences)
      saveAvailability()
    }
    setEditView(false)
  }

  const handlePrimaryNo = () => {
    window.location.assign(`/api/v1/users/${userID}/schedule/connect/?primary=false`)
    setLinkCalendar(false)
  }
  const handlePrimaryYes = () => {
    window.location.assign(`/api/v1/users/${userID}/schedule/connect/?primary=true`)
    setLinkCalendar(false)
  }
  const onLinkCalendar = () => {
    setLinkCalendar(true)
  }

  const onUnlinkCalendar = () => {
    setUnlinkCalendar(true)
  }

  const handleUnlinkNo = () => {
    setUnlinkCalendar(false)
  }
  const handleUnlinkYes = useCallback(async () => {
    setUnlinkCalendar(false)
    await dispatch(disconnectCalendar(userInfo.id))
    await dispatch(fetchSchedulingPreferences(userInfo.id))
    await dispatch(fetchCalendarEvents(userInfo.id))
  }, [dispatch, userInfo.id])

  return (
    <Box display="flex" justifyContent="flex-start" flexDirection="column" className={classes.participants}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography variant={'body2'}>{i18n.t('schedule_calendar_buffer')}</Typography>
              <Box className={classes.paperContent}>
                <IconButton className={classes.icon} onClick={() => handleBufferChange(0)}>
                  <RemoveCircleIcon className={classes.spacer} />
                </IconButton>
                <Typography variant={'body2'} className={classes.spacer}>
                  {values?.buffer}
                </Typography>
                <IconButton className={classes.icon} onClick={() => handleBufferChange(1)}>
                  <AddCircleIcon className={classes.spacer} />
                </IconButton>
                min
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              {userAccount ? (
                <>
                  <Typography variant={'body2'}>{i18n.t('schedule_calendar_unlink')}</Typography>
                  <IconButton className={clsx([classes.icon, classes.paperContentLink])} onClick={onUnlinkCalendar}>
                    <LinkOffIcon className={classes.spacer} />
                  </IconButton>
                </>
              ) : (
                <>
                  <Typography variant={'body2'}>{i18n.t('schedule_calendar_link')}</Typography>
                  <IconButton className={clsx([classes.icon, classes.paperContentLink])} onClick={onLinkCalendar}>
                    <LinkIcon className={classes.spacer} />
                  </IconButton>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography variant={'body2'}>{i18n.t('schedule_calendar_min')}</Typography>
              <Box className={classes.paperContent}>
                <IconButton className={classes.icon} onClick={() => handleNoticeChange(0)}>
                  <RemoveCircleIcon className={classes.spacer} />
                </IconButton>
                <Typography variant={'body2'} className={classes.spacer}>
                  {values?.min_notice}
                </Typography>
                <IconButton className={classes.icon} onClick={() => handleNoticeChange(1)}>
                  <AddCircleIcon className={classes.spacer} />
                </IconButton>
                hours
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={clsx(classes.paper)}>
              <Typography variant={'body2'}>{i18n.t('schedule_calendar_notified')}</Typography>
              <FormControlLabel
                label=""
                control={
                  <Switch
                    checked={values?.email_reminder}
                    color="primary"
                    name={'email_reminder'}
                    inputProps={{ 'aria-label': 'email_reminder' }}
                    onChange={handleReminderToggle}
                  />
                }
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Button onClick={() => handleSubmitClick()} className={classes.toolIconBase}>
        {i18n.t('schedule_calendar_finish')}
      </Button>
      <YesNoDialog
        title={i18n.t('schedule_calendar_primary_calendar')}
        body={'  '}
        isOpen={linkCalendar}
        setIsOpen={setLinkCalendar}
        handleNo={handlePrimaryYes}
        handleYes={handlePrimaryNo}
        yesMessageKey={'No'}
        noMessageKey={'Yes'}
        showCloseIcon={true}
      />
      {unlinkCalendar && (
        <YesNoDialog
          title={i18n.t('schedule_calendar_unlink_calendar')}
          body={
            userAccount === CalendarLinkType.Primary
              ? i18n.t('schedule_calendar_unlink_primary')
              : i18n.t('schedule_calendar_unlink_change')
          }
          isOpen={unlinkCalendar}
          setIsOpen={setUnlinkCalendar}
          handleNo={handleUnlinkYes}
          handleYes={handleUnlinkNo}
          yesMessageKey={'No'}
          noMessageKey={'Yes'}
          showCloseIcon={true}
        />
      )}
    </Box>
  )
}

export default UserPreferences
