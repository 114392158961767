import React, { useState } from 'react'
import i18n from '../../../i18n'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import styles from '../../../styles'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TabPanel from '../../common/TabPanel'
import DashboardStyles from '../../Dashboard/Styles'
import CalendarContainer from './CalendarContainer'
import UnfulfilledContainer from './UnfulfilledContainer'
import WarningIcon from '@material-ui/icons/Warning'
import Typography from '@material-ui/core/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../store'
import { isAvailableForScheduledItems } from '../../../store/scheduling/selectors'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { AssignmentPreferences } from '../../../store/user_preferences/reducers'
import { updateUserAssignmentPreferences } from '../../../store/user_preferences/actions'

interface AgendaProps {
  userID: number
  assignmentPreferences: AssignmentPreferences | null
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: DashboardStyles.fullWidth,
      backgroundColor: 'transparent',
    },
    header: {
      color: DashboardStyles.header.color,
      fontFamily: styles.fonts.primary.family,
      fontSize: DashboardStyles.header.size,
      margin: DashboardStyles.header.margin,
      width: DashboardStyles.header.width,
    },
    tabs: {
      display: 'flex',
      background: DashboardStyles.card.bg,
      padding: '0',
    },
    tabLabel: {
      fontSize: '.9rem',
      textTransform: 'uppercase',
      border: 'solid 1px rgba(46, 91, 255, 0.08)',
    },
    activeLabel: {
      backgroundColor: '#f5f6fa',
    },
    tabContent: {
      width: DashboardStyles.fullWidth,
      border: DashboardStyles.card.border,
      boxShadow: DashboardStyles.card.shadow,
    },
    tabContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    warning: {
      width: '22rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    warningText: {
      fontSize: '12px',
      color: styles.palette.pink.hex,
    },
    warningIcon: {
      color: styles.palette.pink.hex,
      height: '.8rem',
      width: '.8rem',
      marginRight: '.8rem',
    },
    warningLink: {
      color: styles.palette.pink.hex,
      textDecoration: 'underline',
    },
  })
)

const Agenda: React.FC<AgendaProps> = ({ userID, assignmentPreferences }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [tab, setTab] = React.useState(0)
  const [phoneCallsValue, setPhoneCallsValue] = useState<boolean>(assignmentPreferences?.prefer_phone_calls || false)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }
  const availableForScheduledItems = useSelector<AppState, boolean>(state =>
    isAvailableForScheduledItems(state.schedulingReducer)
  )

  const handleToggle = async (newValue: boolean) => {
    if (assignmentPreferences) {
      const updatedPreferences = {
        ...assignmentPreferences,
        prefer_phone_calls: newValue,
      }
      await dispatch(updateUserAssignmentPreferences(assignmentPreferences, updatedPreferences, userID))
    }
  }

  const a11yProps = (index: number) => {
    return {
      id: `page-title-tab-${index}`,
      'aria-controls': `page-title-tabpanel-${index}`,
    }
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.tabContainer}>
        <Box className={classes.tabs}>
          <Tabs value={tab} onChange={handleChange} aria-label="phonecall schedule">
            <Tab
              className={classes.tabLabel}
              label={`${i18n.t('schedule_calendar_title')}`}
              value={0}
              {...a11yProps(0)}
            />
            <Tab
              className={classes.tabLabel}
              label={
                <Box display="flex" flexDirection="row">
                  <span>{`${i18n.t('schedule_unfulfilled_title')} `}</span>
                </Box>
              }
              value={1}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        {!availableForScheduledItems && (
          <Box className={classes.warning}>
            <WarningIcon className={classes.warningIcon} />
            <Typography className={classes.warningText}>
              {i18n.t('schedule_order_low_availability')}
              {i18n.t('schedule_order_low_availability_link')}
            </Typography>
          </Box>
        )}
        <Box>
          <FormControlLabel
            label={<Typography variant={'body2'}>{i18n.t('order__shopping__preferences__phone__desc')}</Typography>}
            control={
              <Switch
                checked={phoneCallsValue}
                color="primary"
                onChange={newValue => {
                  setPhoneCallsValue(newValue.target.checked)
                  handleToggle(newValue.target.checked)
                }}
                inputProps={{ 'aria-label': 'phone calls availability toggle' }}
              />
            }
          />
        </Box>
      </Box>

      <Box className={classes.tabContent}>
        <TabPanel value={tab} index={0} lazy={true}>
          <CalendarContainer />
        </TabPanel>
        <TabPanel value={tab} index={1} lazy={true}>
          <UnfulfilledContainer />
        </TabPanel>
      </Box>
    </Box>
  )
}

export default Agenda
