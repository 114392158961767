import React, { MutableRefObject, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { useEffectOnce } from 'react-use'

import i18n from '../../i18n'
import { AppState } from '../../store'
import { orderItemTypesSelectorWithRevision, ordersItemTypesSelector } from '../../selectors/items'
import { clientHasClientReadyDocs, getDocumentUploadsForClient } from '../../selectors/documents'
import {
  getPendingUploads,
  getScansErrors,
  getUploadedDocumentIDs,
  getUploadOverview,
  ScanErrors,
} from '../../selectors/uploads'
import { Document, FileContexts, UploadGoal } from '../../store/documents/types'
import Scan from './Scan'
import Upload, { UploadMetaOptions } from './Upload'
import Attachments from './Attachments'
import {
  addPendingFilesAndGuess,
  cleanUpUploads,
  finalizeAllUploads,
  removePendingFileThunk,
  reuploadFileAndScan,
  updatePendingFile,
  uploadFilesAndScan,
} from '../../store/uploads/actions'
import { FileUploadRequest, PendingUploadState, UploadOverview } from '../../store/uploads/types'
import { addAttachmentsToMessage } from '../../store/currentMessage/actions'
import { HashMap } from '../../utils/HashMap'
import NewHirePreUpload from './NewHirePreUpload'
import { OrderCTA, OrderItemStates } from '../../store/items/types'
import FullScreenDialog from './FullScreenDialog'
import Improvements from './Improvements'
import useEvents from '../common/useEvents'
import scrollIntoView from 'scroll-into-view-if-needed'
import { useHistory } from 'react-router'
import { fetchClientOrders } from '../../store/clientOrderAggregate/actions'

export interface UploadsContainerProps {
  context: FileContexts
  setUploadContext?: (newContext: FileContexts) => void
  goal: UploadGoal
  contextID: number
  orderIDs: number[]
  isOnboardingMentee: boolean
  cta: OrderCTA
  closeWindow: (attachedDocuments?: boolean) => void
  handleAutoLinkedinFinalizeUpload?: () => void
  canSkipAQAFeedback: boolean
}

enum UploadSteps {
  NewHireWarning = 'NewHireWarning',
  Upload = 'Upload',
  Scan = 'Scan',
  Improvements = 'Improvements',
  Attach = 'Attach',
}

const UploadsContainer: React.FC<UploadsContainerProps> = ({
  context,
  setUploadContext,
  contextID,
  orderIDs,
  closeWindow,
  isOnboardingMentee,
  canSkipAQAFeedback,
  cta,
  handleAutoLinkedinFinalizeUpload,
  goal,
}) => {
  const dispatch = useDispatch()
  const { eventTypes, sendEvent } = useEvents()

  const clientContextDocsExist = useSelector<AppState, boolean>(state =>
    clientHasClientReadyDocs(state.documentReducer, contextID)
  )

  const [currentStep, setCurrentStep] = useState<UploadSteps>(
    isOnboardingMentee &&
      context === 'client' &&
      (cta === OrderItemStates.SendFirstDraft ||
        cta === OrderItemStates.UploadForEditor ||
        cta === OrderItemStates.AwaitingReview ||
        cta === OrderItemStates.SendIntro)
      ? UploadSteps.NewHireWarning
      : UploadSteps.Upload
  )

  const fullScreenDialogRef = React.useRef<HTMLElement>(null)
  const scrollToRef = (ref: MutableRefObject<HTMLElement | null>) =>
    !!ref.current &&
    scrollIntoView(ref.current, {
      block: 'start',
      inline: 'start',
    })

  const pendingUploads = useSelector<AppState, PendingUploadState[]>(state => getPendingUploads(state.uploadsReducer))
  const uploadOverview = useSelector<AppState, UploadOverview[]>(state => getUploadOverview(state.uploadsReducer))
  const writerUploads = useSelector<AppState, Document[]>(store =>
    getDocumentUploadsForClient(store.documentReducer, contextID)
  )
  const uploadMetaOptions = useSelector<AppState, UploadMetaOptions>(state => {
    return {
      type: orderItemTypesSelectorWithRevision(
        ordersItemTypesSelector(state.itemsReducer, orderIDs),
        writerUploads
      ).filter(data => data.label !== 'Extended Revisions'),
      version: [
        {
          value: 0,
          label: i18n.t('messaging__upload__firstDraft'),
        },
        ...[...Array(15).keys()].map(i => ({
          value: i + 1,
          label: i18n.t('messaging__upload__revision', { revision: i + 1 }),
        })),
      ],
    }
  })
  const uploadedDocumentIDs = useSelector<AppState, number[]>(state => getUploadedDocumentIDs(state.uploadsReducer))
  const scansErrors = useSelector<AppState, HashMap<ScanErrors>>(state => getScansErrors(state.uploadsReducer))

  const addPendingUpload = useCallback(
    (files: FileList) => {
      dispatch(addPendingFilesAndGuess(files, context, contextID))
      sendEvent({
        event: eventTypes.UploadPendingFilesAdded,
        variables: {
          context,
          contextID,
          fileCount: files.length,
        },
      })
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [context, contextID, sendEvent, eventTypes]
  )

  const updatePendingUpload = useCallback(
    (filename: string, data: Partial<FileUploadRequest>) => {
      dispatch(updatePendingFile(filename, data))
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    []
  )

  const removePendingUpload = useCallback(
    (filename: string) => {
      dispatch(removePendingFileThunk(filename))
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [sendEvent, eventTypes, context, contextID]
  )

  const onUploadsSubmit = useCallback(async () => {
    setCurrentStep(UploadSteps.Scan)
    sendEvent({
      event: eventTypes.UploadScanStarted,
      variables: {
        context,
        contextID,
      },
    })

    try {
      const hasScanErrors = await dispatch(uploadFilesAndScan())

      // If there are no scan errors, automatically finalize all uploads and advance
      if (!hasScanErrors) {
        // await dispatch(finalizeAllUploads())
        // setCurrentStep(UploadSteps.Attach)
      }
    } catch (e) {
      Sentry.captureException(e)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [setCurrentStep, context, contextID, sendEvent, eventTypes])

  const onFinalizeUploadClick = useCallback(async () => {
    await dispatch(finalizeAllUploads())
    switch (goal) {
      case UploadGoal.Attach:
        setCurrentStep(UploadSteps.Attach)
        break
      case UploadGoal.Upload:
        closeWindow()
        dispatch(cleanUpUploads())
        break
      case UploadGoal.AutoLinkedin:
        if (!!handleAutoLinkedinFinalizeUpload) {
          handleAutoLinkedinFinalizeUpload()
        }
        break
    }
    if (context === 'editor-client') {
      // verbose = true is crucial here, otherwise the response will rewrite vital info - client email
      await dispatch(fetchClientOrders(contextID, { verbose: true }))
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [setCurrentStep, contextID])

  const onAdvanceToImprovementsClick = useCallback(async () => {
    sendEvent({
      event: eventTypes.UploadAdvanceToImprovements,
      variables: {
        context,
        contextID,
        preprocessIDs: uploadOverview.map(uo => uo.pending.preprocessID).filter(ppid => !!ppid),
      },
    })
    scrollToRef(fullScreenDialogRef)
    setCurrentStep(UploadSteps.Improvements)
  }, [setCurrentStep, uploadOverview, eventTypes, sendEvent, context, contextID])

  const onBackToFeedbackClick = useCallback(async () => {
    scrollToRef(fullScreenDialogRef)
    setCurrentStep(UploadSteps.Scan)
  }, [setCurrentStep])

  const history = useHistory()

  const skipToAttachments = useCallback(() => setCurrentStep(UploadSteps.Attach), [setCurrentStep])
  const skipToUploads = useCallback(() => setCurrentStep(UploadSteps.Upload), [setCurrentStep])

  const handleSendToMentor = useCallback(() => {
    sendEvent({
      event: eventTypes.UploadNewHireBlockerClosedToSendToAdvocate,
      variables: {
        context,
        contextID,
      },
    })

    if (window.location.pathname.includes('documents')) {
      if (!!setUploadContext) {
        setUploadContext('editor-client')
      }
      setCurrentStep(UploadSteps.Upload)
    } else {
      history.push({
        pathname: window.location.pathname.replace('messages', 'documents'),
        search: window.location.search,
        state: {
          context: 'editor-client',
          uploadOpen: true,
        },
      })
    }
  }, [sendEvent, eventTypes, context, setUploadContext, contextID, history])

  const onCancel = useCallback(() => {
    sendEvent({
      event: eventTypes.UploadModalClosed,
      variables: {
        context,
        contextID,
        preprocessIDs: uploadOverview.map(uo => uo.pending.preprocessID || 0).filter(ppid => ppid > 0),
      },
    })
    closeWindow()
    dispatch(cleanUpUploads())
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [closeWindow, sendEvent, eventTypes, context, contextID])

  const onCloseOfNewHireBlocker = useCallback(() => {
    closeWindow()
    sendEvent({
      event: eventTypes.UploadNewHireBlockerClosed,
      variables: {
        context,
        contextID,
      },
    })
  }, [closeWindow, sendEvent, eventTypes, context, contextID])

  const attachDocuments = useCallback(
    async (attachmentIDs: number[]) => {
      sendEvent({
        event: eventTypes.UploadFilesAttachedToMessage,
        variables: {
          context,
          contextID,
          fileIDs: attachmentIDs,
        },
      })
      await dispatch(addAttachmentsToMessage(attachmentIDs))
      closeWindow(true)
      dispatch(cleanUpUploads())
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [closeWindow, sendEvent, eventTypes.UploadFilesAttachedToMessage, context, contextID]
  )

  const reuploadFile = useCallback(
    (file: File, filenameToRemove: string) => {
      dispatch(reuploadFileAndScan(file, filenameToRemove))
      setCurrentStep(UploadSteps.Scan)
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [setCurrentStep]
  )

  // Send an event when the upload modal is opened
  useEffectOnce(() => {
    sendEvent({
      event: eventTypes.UploadModalOpened,
      variables: {
        context,
        contextID,
      },
    })
  })

  // Send an event if the user abandons the upload flow by refreshing the page
  // This may or may not work as broswers can cancel this request as the page refreshes
  useEffect(() => {
    const abandonEvent = async () => {
      await sendEvent({
        event: eventTypes.UploadAbandonedByPageRefresh,
        variables: {
          context,
          contextID,
        },
      })
    }

    window.addEventListener('beforeunload', abandonEvent)

    return function cleanup() {
      window.removeEventListener('beforeunload', abandonEvent)
    }
  }, [sendEvent, context, contextID, eventTypes])

  if (currentStep === UploadSteps.NewHireWarning) {
    return (
      <NewHirePreUpload
        orderHasApprovedDocs={clientContextDocsExist}
        handleSkip={skipToUploads}
        handleUploadForReview={handleSendToMentor}
        handleClose={onCloseOfNewHireBlocker}
      />
    )
  }

  return (
    <FullScreenDialog>
      <span ref={fullScreenDialogRef} />
      {currentStep === UploadSteps.Upload && (
        <Upload
          goal={goal}
          isUserExpertMentee={isOnboardingMentee}
          files={pendingUploads}
          onSubmit={onUploadsSubmit}
          options={uploadMetaOptions}
          addUploads={addPendingUpload}
          removeUpload={removePendingUpload}
          updateUpload={updatePendingUpload}
          handleCancel={onCancel}
          handleSkipToAttachments={writerUploads.length ? skipToAttachments : null}
        />
      )}
      {currentStep === UploadSteps.Scan && (
        <Scan
          goal={goal}
          scans={uploadOverview}
          onCancel={onCancel}
          onFinalizeUploadClick={onFinalizeUploadClick}
          onAdvanceToImprovementsClick={onAdvanceToImprovementsClick}
          scansErrors={scansErrors}
          removeUpload={removePendingUpload}
          reuploadFile={reuploadFile}
          canSkipAQAFeedback={canSkipAQAFeedback}
        />
      )}
      {currentStep === UploadSteps.Improvements && (
        <Improvements
          scans={uploadOverview}
          reuploadFile={reuploadFile}
          onBackClick={onBackToFeedbackClick}
          onFinalizeUploadClick={onFinalizeUploadClick}
        />
      )}
      {currentStep === UploadSteps.Attach && (
        <Attachments
          documents={writerUploads}
          onCancel={onCancel}
          onSubmit={attachDocuments}
          defaultSelected={uploadedDocumentIDs}
        />
      )}
    </FullScreenDialog>
  )
}

export default UploadsContainer
