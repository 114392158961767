import {
  AxiosMiddlewareActionCreator,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionSuccess,
} from '../../utils/axios'
import { AcceptRejectFeedbackOfferResponse, GetUserOffersResponse, OfferFeedback } from './types'
import { userId } from '../user/reducers'

export enum OfferActionTypes {
  GENERATE_USER_OFFER = 'GENERATE_USER_OFFER',
  GENERATE_USER_OFFER_SUCCESS = 'GENERATE_USER_OFFER_SUCCESS',
  GENERATE_USER_OFFER_FAIL = 'GENERATE_USER_OFFER_FAIL',

  FETCH_USER_OFFERS = 'FETCH_USER_OFFERS',
  FETCH_USER_OFFERS_SUCCESS = 'FETCH_USER_OFFERS_SUCCESS',
  FETCH_USER_OFFERS_FAIL = 'FETCH_USER_OFFERS_FAIL',

  ACCEPT_OFFER = 'ACCEPT_OFFER',
  ACCEPT_OFFER_SUCCESS = 'ACCEPT_OFFER_SUCCESS',
  ACCEPT_OFFER_FAIL = 'ACCEPT_OFFER_FAIL',

  REJECT_OFFER = 'REJECT_OFFER',
  REJECT_OFFER_SUCCESS = 'REJECT_OFFER_SUCCESS',
  REJECT_OFFER_FAIL = 'REJECT_OFFER_FAIL',

  SUBMIT_OFFER_FEEDBACK = 'SUBMIT_OFFER_FEEDBACK',
  SUBMIT_OFFER_FEEDBACK_SUCCESS = 'SUBMIT_OFFER_FEEDBACK_SUCCESS',
  SUBMIT_OFFER_FEEDBACK_FAIL = 'SUBMIT_OFFER_FEEDBACK_FAIL',

  REMOVE_USER_OFFER = 'REMOVE_USER_OFFER',
  DISCARD_CURRENT_USER_OFFER = 'DISCARD_CURRENT_USER_OFFER',
  EXPIRE_CURRENT_USER_OFFER = 'EXPIRE_CURRENT_USER_OFFER',
  CLEAR_GLOBAL_META = 'CLEAR_GLOBAL_META',

  PROMOTE_NEXT_OFFER_TO_CURRENT = 'PROMOTE_NEXT_OFFER_TO_CURRENT',
}

export interface GenerateUserOffer extends AxiosMiddlewareActionCreator {
  type: typeof OfferActionTypes.GENERATE_USER_OFFER
  userID: number
}

export interface GenerateUserOfferSuccess
  extends AxiosMiddlewareActionSuccess<GetUserOffersResponse, GenerateUserOffer> {
  type: typeof OfferActionTypes.GENERATE_USER_OFFER_SUCCESS
}

export interface GenerateUserOfferFail extends AxiosMiddlewareActionFail<GenerateUserOffer> {
  type: typeof OfferActionTypes.GENERATE_USER_OFFER_FAIL
}

export interface FetchUserOffers extends AxiosMiddlewareActionCreator {
  type: typeof OfferActionTypes.FETCH_USER_OFFERS
  userID: userId
}

export interface FetchUserOffersSuccess extends AxiosMiddlewareActionSuccess<GetUserOffersResponse, FetchUserOffers> {
  type: typeof OfferActionTypes.FETCH_USER_OFFERS_SUCCESS
  userID: number
}

export interface FetchUserOffersFail extends AxiosMiddlewareActionFail<FetchUserOffers> {
  type: typeof OfferActionTypes.FETCH_USER_OFFERS_FAIL
}

export interface AcceptOffer extends AxiosMiddlewareActionCreator {
  type: typeof OfferActionTypes.ACCEPT_OFFER
  userID: number
  offerID: number
}

export interface AcceptOfferSuccess
  extends AxiosMiddlewareActionSuccess<AcceptRejectFeedbackOfferResponse, AcceptOffer> {
  type: typeof OfferActionTypes.ACCEPT_OFFER_SUCCESS
  userID: number
  offerID: number
  offerToken: string
}

export interface AcceptOfferFail extends AxiosMiddlewareActionFail<AcceptOffer> {
  type: typeof OfferActionTypes.ACCEPT_OFFER_FAIL
}

export interface RejectOffer extends AxiosMiddlewareActionCreator {
  type: typeof OfferActionTypes.REJECT_OFFER
  userID: number
  offerID: number
}

export interface RejectOfferSuccess
  extends AxiosMiddlewareActionSuccess<AcceptRejectFeedbackOfferResponse, RejectOffer> {
  type: typeof OfferActionTypes.REJECT_OFFER_SUCCESS
  userID: number
  offerToken: string
}

export interface RejectOfferFail extends AxiosMiddlewareActionFail<RejectOffer> {
  type: typeof OfferActionTypes.REJECT_OFFER_FAIL
}

export interface SubmitOfferFeedback extends AxiosMiddlewareActionCreator {
  type: typeof OfferActionTypes.SUBMIT_OFFER_FEEDBACK
  userID: number
  offerID: number
}

export interface SubmitOfferFeedbackSuccess
  extends AxiosMiddlewareActionSuccess<AcceptRejectFeedbackOfferResponse, SubmitOfferFeedback> {
  type: typeof OfferActionTypes.SUBMIT_OFFER_FEEDBACK_SUCCESS
  userID: number
}

export interface SubmitOfferFeedbackFail extends AxiosMiddlewareActionFail<SubmitOfferFeedback> {
  type: typeof OfferActionTypes.SUBMIT_OFFER_FEEDBACK_FAIL
}

export interface RemoveUserOffer {
  type: typeof OfferActionTypes.REMOVE_USER_OFFER
  offerID: number
  userID: number
}

export interface DiscardCurrentUserOffer {
  type: typeof OfferActionTypes.DISCARD_CURRENT_USER_OFFER
  userID: number
}

export interface ExpireCurrentUserOffer {
  type: typeof OfferActionTypes.EXPIRE_CURRENT_USER_OFFER
  userID: number
}

export interface PromoteNextOfferToCurrent {
  type: typeof OfferActionTypes.PROMOTE_NEXT_OFFER_TO_CURRENT
  userID: number
}

export interface ClearGlobalMeta {
  type: typeof OfferActionTypes.CLEAR_GLOBAL_META
  userID: number
}
export function clearGlobalMeta(userID: number): ClearGlobalMeta {
  return {
    type: OfferActionTypes.CLEAR_GLOBAL_META,
    userID,
  }
}

export function generateOfferForUser(userID: number): GenerateUserOffer {
  return {
    type: OfferActionTypes.GENERATE_USER_OFFER,
    userID,
    payload: {
      request: {
        method: 'POST',
        url: `v1/assignment/offers/user/${userID}`,
        data: {
          num_seconds: 5 * 60 + 30, // 5 minutes + 30 seconds buffer for requests to not fail
        },
      },
    },
  }
}

export function fetchOffersForUser(userID: userId): FetchUserOffers {
  return {
    type: OfferActionTypes.FETCH_USER_OFFERS,
    userID,
    payload: {
      request: {
        url: `v1/assignment/offers/user/${userID}`,
      },
    },
  }
}

export function acceptOffer(userID: number, offerToken: string, offerID: number): AcceptOffer {
  return {
    type: OfferActionTypes.ACCEPT_OFFER,
    userID,
    offerID,
    payload: {
      request: {
        method: 'POST',
        url: `/v1/assignment/offers/user/${userID}/${offerToken}/accept`,
      },
    },
  }
}

export function rejectOffer(userID: number, offerToken: string, offerID: number): RejectOffer {
  return {
    type: OfferActionTypes.REJECT_OFFER,
    userID,
    offerID,
    payload: {
      request: {
        method: 'POST',
        url: `/v1/assignment/offers/user/${userID}/${offerToken}/reject`,
      },
    },
  }
}

export function submitOfferFeedback(
  userID: number,
  offerToken: string,
  offerID: number,
  feedback: OfferFeedback
): SubmitOfferFeedback {
  return {
    type: OfferActionTypes.SUBMIT_OFFER_FEEDBACK,
    userID,
    offerID,
    payload: {
      request: {
        method: 'POST',
        url: `v1/assignment/offers/user/${userID}/${offerToken}/feedback`,
        data: feedback,
      },
    },
  }
}

export function removeUserOffer(userID: number, offerID: number): RemoveUserOffer {
  return {
    type: OfferActionTypes.REMOVE_USER_OFFER,
    userID,
    offerID,
  }
}

export function discardCurrentUserOffer(userID: number): DiscardCurrentUserOffer {
  return {
    type: OfferActionTypes.DISCARD_CURRENT_USER_OFFER,
    userID,
  }
}

export function expireCurrentUserOffer(userID: number): ExpireCurrentUserOffer {
  return {
    type: OfferActionTypes.EXPIRE_CURRENT_USER_OFFER,
    userID,
  }
}

export function promoteNextOfferToCurrent(userID: number): PromoteNextOfferToCurrent {
  return {
    type: OfferActionTypes.PROMOTE_NEXT_OFFER_TO_CURRENT,
    userID,
  }
}
