import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

export const getGhostID = (): number | null => {
  const queryParams = new URLSearchParams(window.location.search)
  const ghostID = queryParams.get('ghost')

  if (ghostID) {
    return parseInt(ghostID)
  }

  return null
}

export class GhostLink extends React.Component<NavLinkProps> {
  public render() {
    const ghostID = getGhostID()
    let to = this.props.to
    let shouldApplyGhost = true

    switch (typeof to) {
      case 'string':
        to = {
          pathname: to,
        }
        break
      case 'function':
        shouldApplyGhost = false
        break
    }

    if (ghostID && shouldApplyGhost && typeof to !== 'function') {
      const searchParams = new URLSearchParams(to.search ? to.search.toString() : '')
      searchParams.set('ghost', ghostID.toString())
      to.search = `?${searchParams.toString()}`
    }

    return <NavLink {...this.props} to={to} />
  }
}
