import i18n from '../../i18n'
import { FilterOption } from './Filtering'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store'
import { ClientOrdersDisplayAggregate, filteredClientsSelectorFactory } from '../../selectors/clientOrderAggregates'
import isEqual from 'lodash/isEqual'
import { useCallback } from 'react'
import { fetchUserClientOrders, setClientViewFilter } from '../../store/clientOrderAggregate/actions'
import queryString from 'query-string'
import { Routes } from '../../utils/consts'
import { DEFAULT_OPEN_PAGE_SIZE } from './OrdersContainer'

export enum OrderTableDisplayModes {
  Open = 'open',
  Paid = 'paid',
  New = 'new',
  UnreadMessages = 'unread_messages',
  EligibleToClose = 'close',
  InRevisions = 'revisions',
  Scheduled = 'scheduled',
  ToSchedule = 'toSchedule',
  SendSummary = 'sendSummary',
  PhoneCalls = 'phoneCalls',
  Complete = 'complete',
}

const labels = [
  'clients_list__filtering__open',
  'clients_list__filtering__new',
  'clients_list__filtering__unread_messages',
  'clients_list__filtering__eligible_close',
  'clients_list__filtering__revisions',
  'clients_list__filtering__toSchedule',
  'clients_list__filtering__scheduled',
  'clients_list__filtering__sendSummary',
  'clients_list__filtering__phoneCalls',
  'clients_list__filtering__complete',
]
const keys = [
  OrderTableDisplayModes.Open,
  OrderTableDisplayModes.New,
  OrderTableDisplayModes.UnreadMessages,
  OrderTableDisplayModes.EligibleToClose,
  OrderTableDisplayModes.InRevisions,
  OrderTableDisplayModes.ToSchedule,
  OrderTableDisplayModes.Scheduled,
  OrderTableDisplayModes.SendSummary,
  OrderTableDisplayModes.PhoneCalls,
  OrderTableDisplayModes.Complete,
]

const options: FilterOption[] = [...Array(keys.length).keys()].map(key => ({
  id: key + 1,
  key: keys[key],
  label: i18n.t(labels[key]),
  count: 0,
  isVisible: ![
    OrderTableDisplayModes.ToSchedule,
    OrderTableDisplayModes.Scheduled,
    OrderTableDisplayModes.SendSummary,
  ].includes(keys[key]),
}))

interface UseOrderFiltering {
  options: FilterOption[]
  currentFilter: OrderTableDisplayModes
  currentOption: FilterOption
  clients: ClientOrdersDisplayAggregate[]
  setFilter: (filter: OrderTableDisplayModes) => Promise<void>
  getFilterFromQueryString: () => OrderTableDisplayModes
}

const useOrderFiltering = (): UseOrderFiltering => {
  const currentFilter = useSelector<AppState, OrderTableDisplayModes>(
    state => state.clientOrdersAggregateReducer.view.filter,
    isEqual
  )

  const dispatch = useDispatch()

  const getFilterFromQueryString = useCallback((): OrderTableDisplayModes => {
    if (window.location.pathname === Routes.PaidOrders) {
      return OrderTableDisplayModes.Paid
    }
    if (!window.location || window.location.search === '') {
      return OrderTableDisplayModes.Open
    }
    const query = window.location.search
    const values = queryString.parse(query)
    if (!values.filter) {
      return OrderTableDisplayModes.Open
    }
    return (values.filter as OrderTableDisplayModes)
      ? (values.filter as OrderTableDisplayModes)
      : OrderTableDisplayModes.Open
  }, [])

  const setFilter = useCallback(
    async (displayMode: OrderTableDisplayModes) => {
      const oldFilter = currentFilter

      if (oldFilter !== displayMode) {
        await dispatch(setClientViewFilter(displayMode))

        if (oldFilter === OrderTableDisplayModes.Paid) {
          dispatch(
            fetchUserClientOrders({
              status: ['open'],
              page_size: DEFAULT_OPEN_PAGE_SIZE,
              page: 1,
            })
          )
        }
      }
    },
    [dispatch, currentFilter]
  )

  const selectedFilter = options.filter(x => x.key === currentFilter)
  const currentOption =
    !!selectedFilter && selectedFilter.length > 0
      ? selectedFilter[0]
      : options.filter(o => o.key === OrderTableDisplayModes.Open)[0]

  const clients = useSelector<AppState, ClientOrdersDisplayAggregate[]>(state => {
    let filteredClients: ClientOrdersDisplayAggregate[] = []
    options.forEach(option => {
      const filter = filteredClientsSelectorFactory(option.key)
      const result = filter(
        {
          ...state.orderReducer,
          ...state.clientReducer,
          ...state.itemsReducer,
          ...state.clientOrdersAggregateReducer,
          ...state.incentivesReducer,
          ...state.userReducer,
        },
        state.itemsReducer
      )
      option.count = result.length
      if (option === currentOption) {
        filteredClients = result.filter(res => !!res) as ClientOrdersDisplayAggregate[]
      }
    })
    return filteredClients
  })

  return {
    currentFilter,
    options,
    currentOption,
    setFilter,
    getFilterFromQueryString,
    clients,
  }
}

export default useOrderFiltering
