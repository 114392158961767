import React from 'react'
import { BasePay } from '../../store/orders/types'
import { OrderStatus } from '../../store/orders/actions'
import { OrderCTA } from '../../store/items/types'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { GhostLink } from '../../utils/link'
import ClientInfo from '../common/ClientInfo'
import classNames from 'classnames'
import OrderActionContainer from './OrderActionContainer'
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MessageIcon from '@material-ui/icons/Chat'
import { formatCurrency, formatOrderDueDate } from '../../utils/formatting'
import OrderItems from './OrderItems'
import { ClientOrdersDisplayAggregate } from '../../selectors/clientOrderAggregates'
import { getClientDefaultUrl, getClientUrl } from '../../utils/consts'
import i18n from '../../i18n'
import useUserInfoState from '../common/useUserInfo'
import isEqual from 'lodash/isEqual'
import { OrderTableDisplayModes } from './useOrderFiltering'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { Client } from '../../store/clients/reducer'
import { clientByIdSelector } from '../../selectors/clients'

export interface ClientOrdersRowProps {
  data: ClientOrdersDisplayAggregate
  displayMode: OrderTableDisplayModes
  userID?: number
}

const useStyles = makeStyles(() => ({
  cell: {
    padding: '0.6rem 1rem',
    '@media (max-width: 1050px)': {
      fontSize: '0.9375rem',
    },
    '@media (max-width: 540px)': {
      padding: '0.6rem 0.6rem',
    },
  },
  orderName: {
    display: 'flex',
    alignItems: 'center',
  },
  unread: {
    marginRight: '.5rem',
    whiteSpace: 'nowrap',
    color: '#00a3f4',
    '& .unread-top': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .unread-count': { fontSize: '1rem' },
    },
    '& .unread-label': { fontSize: '.6875rem' },
  },
  orderNameLink: {
    textDecoration: 'none',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  primaryText: {
    color: '#09123b',
  },
  secondaryText: {
    color: '#666a74',
  },
  testOrderFlag: {
    fontWeight: 600,
    color: '#f26d6d',
  },
}))

const ClientOrdersRow: React.FC<ClientOrdersRowProps> = ({ data, userID, displayMode }) => {
  const classes = useStyles()

  const { timeZone, locale, shouldSeeBasePay, isAdmin } = useUserInfoState()
  const client = useSelector<AppState, Client>(store => clientByIdSelector(store.clientReducer, data.client.id))
  const renderDueDate = (macroState: OrderCTA, macroDueDate: string | Date) => {
    const [formattedDate, description] = formatOrderDueDate(new Date(macroDueDate), timeZone, locale, macroState)
    return (
      <div className={classes.content}>
        <Typography variant={'body1'} className={classes.primaryText}>
          {formattedDate}
        </Typography>
        <Typography variant={'body2'} className={classes.secondaryText}>
          {description}
        </Typography>
      </div>
    )
  }

  const renderBasePay = (basePay: BasePay, status: OrderStatus) => {
    return (
      <TableCell className={classNames({ [classes.cell]: true, hideMobile: true })} align="left">
        <div className={classes.content}>
          <Typography className={classes.primaryText} variant={'body1'}>
            {formatCurrency(basePay.amount, basePay.currency)}
          </Typography>
          {status === 'paid' && (
            <Typography className={classes.secondaryText} variant={'body2'}>
              {i18n.t('orders__table__paid')}
            </Typography>
          )}
        </div>
      </TableCell>
    )
  }

  return (
    <TableRow key={`client-row-${data.client.id}`}>
      <TableCell className={classes.cell} scope="row">
        {data.client && (
          <Box className={classes.orderName}>
            {data.unreadMailCount > 0 && (
              <GhostLink to={{ pathname: getClientUrl(data.client.id, '/messages') }}>
                <Box className={classes.unread}>
                  <Box className="unread-top">
                    <Typography variant={'body2'} className="unread-count">
                      {data.unreadMailCount}
                    </Typography>
                    <MessageIcon />
                  </Box>
                  <Typography display="block" variant={'body2'} className="unread-label">
                    {i18n.t('orders__table__unread')}
                  </Typography>
                </Box>
              </GhostLink>
            )}
            <GhostLink to={getClientDefaultUrl(data.client.id)} className={classes.orderNameLink}>
              <ClientInfo
                unreadMailCount={data.unreadMailCount}
                clientId={data.client.id}
                name={data.client.full_name}
                hideAvatar={true}
              />
              {client.test_order === 1 && (
                <Typography className={classes.testOrderFlag} variant={'h6'}>
                  Test Order
                </Typography>
              )}
            </GhostLink>
          </Box>
        )}
      </TableCell>
      {displayMode !== OrderTableDisplayModes.Paid && !!data.macroState && (
        <TableCell className={classes.cell} align="left">
          <OrderActionContainer hideFarewell macroState={data.macroState} clientID={data.client.id} userID={userID} />
        </TableCell>
      )}
      {!!data.macroState && (
        <TableCell className={classes.cell} align="left">
          {data.macroState.dueDate && renderDueDate(data.macroState.CTA, data.macroState.dueDate)}
        </TableCell>
      )}
      <TableCell className={classNames({ [classes.cell]: true, hideTablet: true, hideMobile: true })} align="left">
        <OrderItems
          stackItems={false}
          shouldRenderBasePay={shouldSeeBasePay}
          shouldSeeInternalIncentiveNames={isAdmin}
          items={data.items}
          shouldApplyColors={true}
          shouldRenderHistory={true}
        />
      </TableCell>
      {shouldSeeBasePay && renderBasePay(data.basePay, data.status)}
    </TableRow>
  )
}

const memoizedRow = React.memo(ClientOrdersRow, isEqual)
// @ts-ignore
memoizedRow.whyDidYouRender = true

export default memoizedRow
