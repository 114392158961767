import React, { useState, useCallback } from 'react'

interface TopNavBarContextState {
  open: boolean
}

const defaultState: TopNavBarContextState = {
  open: false,
}

export const TopNavBarContext = React.createContext<TopNavBarContextState>(defaultState)

export default function useTopNavBar(): [TopNavBarContextState, () => void] {
  const [state, setState] = useState(defaultState)
  const toggleExpansion = useCallback(
    () =>
      setState(s => {
        if (window.innerWidth < 541) {
          return {
            ...s,
            open: !s.open,
          }
        } else {
          return {
            open: false,
          }
        }
      }),
    [setState]
  )

  return [state, toggleExpansion]
}
