import React from 'react'

const SvgRush = props => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="currentColor" {...props}>
    <g transform="translate(6.731707, 11.219512)">
      <path d="M18.3554008,0 C15.5080487,0.00255524932 12.8283012,1.30502484 11.1231429,3.51518049 L11.108014,3.51219512 L5.67247389,3.51219512 C5.17214624,3.51219512 4.76655054,3.90531095 4.76655054,4.3902439 C4.76655054,4.87517685 5.17214624,5.26829268 5.67247389,5.26829268 L10.0579582,5.26829268 C9.67824257,6.10449524 9.43672589,6.99342715 9.3422788,7.90243902 L2.95470384,7.90243902 C2.45437619,7.90243902 2.04878049,8.29555485 2.04878049,8.7804878 C2.04878049,9.26542076 2.45437619,9.65853659 2.95470384,9.65853659 L9.34209762,9.65853659 C9.4365328,10.5675505 9.67804997,11.4564842 10.0577771,12.2926829 L5.67247389,12.2926829 C5.17214624,12.2926829 4.76655054,12.6857988 4.76655054,13.1707317 C4.76655054,13.6556647 5.17214624,14.0487805 5.67247389,14.0487805 L11.108014,14.0487805 L11.1231429,14.0457951 C11.6411053,14.7125061 12.2538883,15.3047492 12.9432335,15.804878 L10.2020907,15.804878 C9.701763,15.804878 9.2961673,16.1979939 9.2961673,16.6829268 C9.2961673,17.1678598 9.701763,17.5609756 10.2020907,17.5609756 L17.4494775,17.5609756 C17.5112825,17.5554703 17.572299,17.5435483 17.6314775,17.5254146 C17.8710036,17.5438537 18.1110733,17.5609756 18.3554008,17.5609756 C23.3586773,17.5609755 27.4146341,13.6298172 27.4146341,8.7804878 C27.4146341,3.93115837 23.3586773,1.02191981e-07 18.3554008,0 Z M18.3554008,15.804878 C14.3527796,15.804878 11.108014,12.6599514 11.108014,8.7804878 C11.108014,4.9010242 14.3527796,1.75609756 18.3554008,1.75609756 C22.358022,1.75609756 25.6027876,4.9010242 25.6027876,8.7804878 C25.5981947,12.658106 22.356118,15.8004265 18.3554008,15.804878 Z" />
      <path d="M1.07317073,5.36585366 L3.02439024,5.36585366 C3.56320463,5.36585366 4,4.92905829 4,4.3902439 C4,3.85142951 3.56320463,3.41463415 3.02439024,3.41463415 L1.07317073,3.41463415 C0.534356342,3.41463415 0.0975609756,3.85142951 0.0975609756,4.3902439 C0.0975609756,4.92905829 0.534356342,5.36585366 1.07317073,5.36585366 Z" />
      <path d="M7.80487805,15.902439 L4.87804878,15.902439 C4.33923439,15.902439 3.90243902,16.3392344 3.90243902,16.8780488 C3.90243902,17.4168632 4.33923439,17.8536585 4.87804878,17.8536585 L7.80487805,17.8536585 C8.34369244,17.8536585 8.7804878,17.4168632 8.7804878,16.8780488 C8.7804878,16.3392344 8.34369244,15.902439 7.80487805,15.902439 Z" />
      <path d="M1.95121951,15.902439 L0.975609756,15.902439 C0.436795366,15.902439 0,16.3392344 0,16.8780488 C-1.08314441e-16,17.4168632 0.436795366,17.8536585 0.975609756,17.8536585 L1.95121951,17.8536585 C2.4900339,17.8536585 2.92682927,17.4168632 2.92682927,16.8780488 C2.92682927,16.3392344 2.4900339,15.902439 1.95121951,15.902439 Z" />
      <text fontFamily="AvenirNext-Bold, Avenir Next" fontSize="10" fontWeight="bold">
        <tspan x="15.1219512" y="12.0731707">
          $
        </tspan>
      </text>
    </g>
  </svg>
)

export default SvgRush
