import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import UploadStyles from '../UploadsV3/styles'
import styles from '../../styles'
import React, { useCallback, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import {
  AutoLinkedinMeta,
  AutoLinkedinSections,
  AutoLinkedinUpdatePayload,
  Sections,
  WorkExperienceSectionShape,
} from '../../store/autolinkedin/types'
import isEqual from 'lodash/isEqual'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { Form as FinalForm } from 'react-final-form'
import Box from '@material-ui/core/Box'
import i18n from '../../i18n'
import { FormFieldSchema, SectionSchemaMap } from './formSchema'
import ALIFormField from './ALIFormField'
import Button from '../common/Button'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { updateAutoLinkedinByItemID } from '../../store/autolinkedin/actions'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store'
import { LoadedLoadingErrorState } from '../../utils/state'
import { getAutoLinkedinUpdateMeta, getAutoLinkJsonMeta } from '../../store/autolinkedin/selectors'
import YesNoDialog from './YesNoDialog'
import { Typography } from '@material-ui/core'
import InfoTooltip from '../common/InfoTooltip'

export interface FormSectionProps {
  setSectionValidity?: (sectionValidity: Record<string, boolean>) => void
  sectionValidity?: Record<string, boolean>
  sectionPristine?: Record<string, boolean>
  setSectionPristine?: (sectionPristine: Record<string, boolean>) => void
  linkedinItemID: number
  section: Sections
  highlightClass?: string | null
  validationOn?: boolean
  readOnly: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    accordion: {
      backgroundColor: 'transparent',
      borderBottom: `.125em solid ${styles.palette.darkerBlue.color.hex}`,
      margin: '0 !important',
      padding: '1.4em 0',
    },
    accordionSummary: {
      color: styles.palette.darkerBlue.color.hex,
      fontSize: '1.25em',
      minHeight: '0',
    },
    accordionSummaryContent: {
      margin: 0,
    },
    addBoxIcon: {
      height: '1.5em',
      width: '1.5em',
    },
    addItemBox: {
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      marginLeft: '1.07em',
    },
    buttons: {
      paddingTop: '.3rem',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonsAligned: {
      display: 'flex',
      width: '36%',
      justifyContent: 'space-between',
    },
    emptySection: {
      color: styles.palette.lightGrey.color,
    },
    expandMoreIcon: {
      padding: '0 .75em',
    },
    root: {
      width: '100%',
    },
    section: {
      border: UploadStyles.autoQAResults.group.border,
      borderRadius: UploadStyles.autoQAResults.group.borderRadius,
      boxShadow: UploadStyles.autoQAResults.group.boxShadow,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1.25em',
      padding: '1.875rem',
      width: '100%',
    },
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    sectionHeaderText: {
      paddingRight: '.5em',
      fontSize: '1em',
      fontWeight: 'bold',
    },
  })
)

const SectionForm: React.FC<FormSectionProps> = ({
  setSectionValidity,
  sectionValidity,
  sectionPristine,
  setSectionPristine,
  readOnly,
  section,
  linkedinItemID,
  validationOn,
  highlightClass = null,
}) => {
  const classes = useStyles()
  const schema = SectionSchemaMap[section.section]
  const requiredFields = schema.required

  const [deleteConfirmDialogVisible, setDeleteConfirmDialogVisible] = useState(false)
  const [deletionCallback, setDeletionCallback] = useState(() => {})

  const jsonMeta = useSelector<AppState, AutoLinkedinMeta | null>(state =>
    getAutoLinkJsonMeta(state.autolinkedinReducer, linkedinItemID)
  )

  const workExperienceValidated = !!jsonMeta && jsonMeta.parsed_work_experience_validated

  const dispatch = useDispatch()

  const updateMeta = useSelector<AppState, LoadedLoadingErrorState | undefined>(state =>
    getAutoLinkedinUpdateMeta(state.autolinkedinReducer, linkedinItemID)
  )

  const onSubmit = useCallback(
    async (values, meta?: AutoLinkedinMeta | undefined) => {
      const payload: AutoLinkedinUpdatePayload = {}

      if (values !== null) {
        payload.talentLinkedin = values
      }

      if (!!meta) {
        payload.meta = meta
      }

      dispatch(updateAutoLinkedinByItemID(linkedinItemID, payload))
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [linkedinItemID]
  )

  const markWorkExperienceValidated = useCallback(() => {
    onSubmit(null, {
      progress: !!jsonMeta?.progress?.record_index
        ? {
            section: AutoLinkedinSections.WorkExperience,
            record_index: jsonMeta?.progress?.record_index,
          }
        : null,
      parsed_work_experience_validated: true,
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [jsonMeta?.progress?.record_index, onSubmit])

  //Handle button click so that user has to sign off on all work experiences before moving on
  const handleLooksGoodClick = async (
    index: number,
    recordCount: number,
    values: WorkExperienceSectionShape,
    pristine: boolean
  ) => {
    const progress = {
      section: AutoLinkedinSections.WorkExperience,
      record_index: index,
    }
    const meta: AutoLinkedinMeta = {
      progress: progress,
      parsed_work_experience_validated: index === recordCount - 1,
    }

    if (pristine) {
      await onSubmit(null, meta)
    } else {
      await onSubmit(values, meta)
    }

    if (index < recordCount - 1) {
      const nextWE = document.getElementById(`workExperience-${index + 1}`)
      if (!!nextWE) {
        nextWE.scrollIntoView()
      }
    }
  }

  // validation is delayed until after "Next" is clicked (except for Work Experience records, they are validated right away)
  const validate = useCallback(
    (values: Record<string, string>[]) => {
      const errorArray: Record<string, string>[] = []

      values.forEach(value => {
        const errors: Record<string, string> = {}

        requiredFields.forEach(r => {
          if (!value[r]) {
            errors[r] = i18n.t('alink__error__required')
          } else if (!!schema.maxLength) {
            Object.keys(schema.maxLength).forEach(m => {
              // @ts-ignore
              const maxLength = schema?.maxLength[m]
              if (!!value[m] && value[m].length > maxLength) {
                errors[m] = i18n.t('alink__error__limit_exceeded', { diff: value[m].length - maxLength })
              } else if (!!value[m] && value[m].search(/system.?\(+/i) >= 0) {
                errors[m] = i18n.t('alink__updateSystemError')
              }
            })
          }
        })
        errorArray.push(errors)
      })
      if (errorArray.find(e => Object.keys(e).length > 0)) {
        if (!!sectionValidity && !!setSectionValidity) {
          sectionValidity[section.section] = false
          setSectionValidity(sectionValidity)
        }
        // keep track of errors, but don't highlight until validationOn
        return validationOn ? errorArray : undefined
      } else {
        if (!!sectionValidity && !!setSectionValidity) {
          sectionValidity[section.section] = true
          setSectionValidity(sectionValidity)
        }
        return undefined
      }
    },
    [schema, requiredFields, sectionValidity, setSectionValidity, validationOn, section.section]
  )

  if (!schema) {
    return null
  }

  const sortedFields = schema.properties.sort((p1: FormFieldSchema, p2: FormFieldSchema) =>
    p1.propertyOrder > p2.propertyOrder ? 1 : -1
  )

  const isHighlighted = (index: number) => {
    if (workExperienceValidated || section.section !== AutoLinkedinSections.WorkExperience) {
      return null
    }
    const progressIndex = jsonMeta?.progress?.record_index
    if (progressIndex === undefined) {
      return highlightClass
    }
    return index > progressIndex ? highlightClass : null
  }

  return (
    <>
      <Accordion className={classes.accordion} defaultExpanded={section.recordCount > 0} id={section.section} square>
        <AccordionSummary
          classes={{ content: classes.accordionSummaryContent, expandIcon: classes.expandMoreIcon }}
          className={clsx(classes.accordionSummary, section.recordCount === 0 ? classes.emptySection : null)}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${section}_accordion_details`}
          id={`${section}_accordion_header`}
        >
          <Box className={classes.sectionHeader}>
            <Typography className={classes.sectionHeaderText} component="h2">
              {i18n.t(`alink__${section.section}`)}
            </Typography>
            <InfoTooltip text={i18n.t(`alink__${section.section}__tooltip`)} />
          </Box>
        </AccordionSummary>
        <AccordionDetails id={`${section}_accordion_details`}>
          <FinalForm
            onSubmit={values => onSubmit(values)}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={section.data}
            render={({
              handleSubmit,
              form: {
                mutators: { remove, push },
              },
              pristine,
              submitting,
              values,
            }) => {
              if (!readOnly && !validationOn && sectionPristine && setSectionPristine) {
                if (sectionPristine[`${section.section}-form`] !== pristine) {
                  sectionPristine[`${section.section}-form`] = pristine
                  setSectionPristine(sectionPristine)
                }
              }
              return (
                <form
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  className={classes.root}
                  id={`${section.section}-form`}
                >
                  <fieldset disabled={readOnly}>
                    <FieldArray name={section.fieldArrayName} validate={!!sectionValidity ? validate : () => undefined}>
                      {({ fields, meta }) => (
                        <Box key={`${section}`}>
                          {fields.map((name, index) => (
                            <Box
                              className={clsx(classes.section, isHighlighted(index))}
                              id={`${section.section}-${index}`}
                              key={`${section}${index}`}
                            >
                              {sortedFields.map(field => {
                                // don't allow invalid work descriptions EVER
                                const shouldValidateRightAway =
                                  section.section === AutoLinkedinSections.WorkExperience &&
                                  ((!workExperienceValidated &&
                                    index <=
                                      (jsonMeta?.progress?.record_index === undefined
                                        ? 0
                                        : jsonMeta?.progress?.record_index + 1)) ||
                                    workExperienceValidated)
                                return (
                                  <ALIFormField
                                    readOnly={readOnly}
                                    pristine={pristine}
                                    key={`${name}.${field.name}`}
                                    outerName={name}
                                    fieldSchema={field}
                                    index={index}
                                    section={section.section}
                                    required={shouldValidateRightAway ? requiredFields.includes(field.name) : false}
                                    maxLength={
                                      shouldValidateRightAway && !!schema?.maxLength
                                        ? schema?.maxLength[field.name]
                                        : undefined
                                    }
                                  />
                                )
                              })}
                              {!readOnly && (
                                <Box className={classes.buttons}>
                                  <Box className={classes.buttonsAligned}>
                                    <Button
                                      type="secondary"
                                      disabled={
                                        submitting ||
                                        updateMeta?.isLoading ||
                                        (!workExperienceValidated &&
                                          (section.section !== AutoLinkedinSections.WorkExperience ||
                                            (section.section === AutoLinkedinSections.WorkExperience &&
                                              ((!jsonMeta && index > 0) ||
                                                (!!jsonMeta &&
                                                  !!jsonMeta.progress &&
                                                  jsonMeta.progress.record_index + 1 < index)))))
                                      }
                                      onClick={() => {
                                        setDeletionCallback(() => async () => {
                                          remove(section.fieldArrayName, index)
                                          handleSubmit()
                                          if (index >= section.recordCount - 1) {
                                            markWorkExperienceValidated()
                                          }
                                          setDeletionCallback(() => {})
                                          setDeleteConfirmDialogVisible(false)
                                        })
                                        setDeleteConfirmDialogVisible(true)
                                      }}
                                    >
                                      {i18n.t('alink__button__delete')}
                                    </Button>
                                    {section.section === AutoLinkedinSections.WorkExperience &&
                                      !workExperienceValidated &&
                                      (!jsonMeta ||
                                        (!!jsonMeta &&
                                          !!jsonMeta.progress &&
                                          jsonMeta.progress.record_index < index)) && (
                                        <Button
                                          className={classes.button}
                                          type="contrast"
                                          disabled={
                                            (!jsonMeta && index > 0) ||
                                            (!!jsonMeta &&
                                              !!jsonMeta.progress &&
                                              jsonMeta.progress.record_index + 1 < index) ||
                                            (!!meta.error &&
                                              !!meta.error[index] &&
                                              Object.keys(meta.error[index]).length > 0) ||
                                            submitting ||
                                            updateMeta?.isLoading
                                          }
                                          onClick={() =>
                                            handleLooksGoodClick(index, section.recordCount, values, pristine)
                                          }
                                          showLoader={updateMeta?.isLoading || submitting}
                                        >
                                          {i18n.t('alink__button__looksGood')}
                                        </Button>
                                      )}
                                    {(section.section !== AutoLinkedinSections.WorkExperience ||
                                      (section.section === AutoLinkedinSections.WorkExperience &&
                                        ((!!jsonMeta &&
                                          !!jsonMeta.progress &&
                                          jsonMeta.progress.record_index >= index) ||
                                          workExperienceValidated))) && (
                                      <Button
                                        className={classes.button}
                                        type="contrast"
                                        disabled={
                                          (!!meta.error &&
                                            !!meta.error[index] &&
                                            Object.keys(meta.error[index]).length > 0) ||
                                          submitting ||
                                          pristine ||
                                          updateMeta?.isLoading ||
                                          (!workExperienceValidated &&
                                            section.section !== AutoLinkedinSections.WorkExperience)
                                        }
                                        onClick={handleSubmit}
                                        showLoader={updateMeta?.isLoading || submitting}
                                      >
                                        {i18n.t('alink__button__update')}
                                      </Button>
                                    )}
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          ))}
                          {!readOnly && workExperienceValidated && (
                            <Box className={classes.addItemBox}>
                              <AddBoxIcon
                                className={classes.addBoxIcon}
                                cursor={'pointer'}
                                fontSize={'large'}
                                onClick={() => push(section.fieldArrayName, {})}
                              />
                            </Box>
                          )}
                        </Box>
                      )}
                    </FieldArray>
                  </fieldset>
                </form>
              )
            }}
          />
        </AccordionDetails>
      </Accordion>
      <YesNoDialog
        title={i18n.t('alink__DeleteRecord__question')}
        isOpen={deleteConfirmDialogVisible}
        // @ts-ignore
        handleYes={deletionCallback}
        handleNo={() => setDeleteConfirmDialogVisible(false)}
      />
    </>
  )
}

const memoizedSectionForm = React.memo(SectionForm, isEqual)
// @ts-ignore
memoizedSectionForm.whyDidYouRender = true
export default memoizedSectionForm
