import produce from 'immer'

import {
  ClientOrdersDisplayAggregate,
  clientOrdersSelector,
  getClientOrdersForCarousel,
} from '../../../selectors/clientOrderAggregates'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../store'

export interface UseCarousel {
  currentOrderGroup: ClientOrdersDisplayAggregate
  hasMoreThanOneGroup: boolean
  moveForward: () => void
  moveBackwards: () => void
}

interface MoveForward {
  type: 'MOVE_FORWARD'
}

interface MoveBackwards {
  type: 'MOVE_BACKWARDS'
}

interface SetKeys {
  type: 'SET_KEYS'
  orderGroups: ClientOrdersDisplayAggregate[]
}

interface State {
  keys: string[]
  currentKey: string | null
}

type Actions = MoveBackwards | MoveForward | SetKeys

function carouselReducer(state: State, action: Actions) {
  return produce(state, draft => {
    switch (action.type) {
      case 'MOVE_BACKWARDS':
      case 'MOVE_FORWARD':
        const currentIdx = state.keys.findIndex(key => key === state.currentKey)

        if (currentIdx === -1) {
          return
        }

        if (currentIdx === 0 && action.type === 'MOVE_BACKWARDS') {
          draft.currentKey = state.keys[state.keys.length - 1]
        } else if (currentIdx === state.keys.length - 1 && action.type === 'MOVE_FORWARD') {
          draft.currentKey = state.keys[0]
        } else {
          draft.currentKey = state.keys[action.type === 'MOVE_FORWARD' ? currentIdx + 1 : currentIdx - 1]
        }

        break

      case 'SET_KEYS':
        draft.keys = action.orderGroups.map(og => og.orderIDs.sort().join(','))

        if (state.currentKey === null) {
          draft.currentKey = draft.keys[0]
        }

        break
    }
  })
}

export const useOrderCarousel = (clientID: number): UseCarousel => {
  const clientOrdersAggregate = useSelector<AppState, ClientOrdersDisplayAggregate | null>(state =>
    clientOrdersSelector(
      {
        ...state.clientReducer,
        ...state.clientOrdersAggregateReducer,
        ...state.itemsReducer,
        ...state.orderReducer,
        ...state.incentivesReducer,
        ...state.userReducer,
      },
      clientID
    )
  )

  const orderGroups = useSelector<AppState, ClientOrdersDisplayAggregate[]>(state => {
    if (!clientOrdersAggregate) {
      return []
    }
    const res = getClientOrdersForCarousel(
      clientOrdersAggregate.client,
      clientOrdersAggregate.macroState,
      state.orderReducer.orders,
      state.itemsReducer.itemsByOrderId,
      state.incentivesReducer.client,
      state.userReducer.getLoggedInUser()
    )
    return res.filter(r => !!r) as ClientOrdersDisplayAggregate[]
  })

  const [state, dispatch] = React.useReducer(carouselReducer, {
    keys: [],
    currentKey: null,
  })

  const currentOrderGroup: ClientOrdersDisplayAggregate =
    state.currentKey !== null
      ? orderGroups.find(og => og.orderIDs.sort().join(',') === state.currentKey) || orderGroups[0]
      : orderGroups[0]

  const moveForward = React.useCallback(() => dispatch({ type: 'MOVE_FORWARD' }), [dispatch])
  const moveBackwards = React.useCallback(() => dispatch({ type: 'MOVE_BACKWARDS' }), [dispatch])
  const setKeys = React.useCallback(
    (orderGroups: ClientOrdersDisplayAggregate[]) => dispatch({ type: 'SET_KEYS', orderGroups }),
    [dispatch]
  )

  // If we have no keys in the state OR if we added/removed an order, we must set them
  React.useEffect(() => {
    if ((!state.keys.length && orderGroups) || state.keys.length !== orderGroups.length) {
      setKeys(orderGroups)
    }
  }, [state.keys, orderGroups, setKeys])

  return {
    currentOrderGroup,
    hasMoreThanOneGroup: state.keys.length > 1,
    moveBackwards,
    moveForward,
  }
}
