import {
  AccountList,
  AccountType,
  CalendarEvent,
  CalendarLinkType,
  EventTypes,
  OnboardingStates,
  PhoneConsultationDetails,
  Preferences,
  SchedulingMeta,
  SchedulingState,
  WeeklyPeriod,
  WeeklyPeriodNumeric,
} from './types'
import { LoadedLoadingErrorState } from '../../utils/state'
import { SCHEDULING } from './actions'
import { utcToZonedTime } from 'date-fns-tz'
import { addMinutes, subSeconds } from 'date-fns'
import { createSelector } from 'reselect'
import { clientsSelector } from '../../selectors/clients'
import { filterHashMapAny } from '../../utils/selectors'
import { OrderItem, OrderItemState, OrderItemStates, OrderItemTypes } from '../items/types'
import { itemsByOrderIdSelector, ordersSelector } from '../../selectors/items'
import { Client, ClientsState } from '../clients/reducer'
import { OrdersState } from '../orders/reducer'
import { HashMap } from '../../utils/HashMap'
import { Order } from '../orders/types'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { Selector } from 'reselect/src'
import useUserInfoState from '../../components/common/useUserInfo'

const week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export const getAccountData = (state: SchedulingState): AccountList => {
  try {
    return state.accounts
  } catch (e) {
    return {}
  }
}

export const getCalendarLinkageInfo = (state: SchedulingState): number => {
  const userAccounts = state.accounts[AccountType.user]
  if (!userAccounts) {
    return CalendarLinkType.None
  }
  const userAccountID = Object.keys(userAccounts)[0]
  return state.preferences?.primary_scheduling_account_id === parseInt(userAccountID)
    ? CalendarLinkType.Primary
    : CalendarLinkType.Secondary
}

export const getAvailabilityRules = ({ weeklyPeriods }: SchedulingState): WeeklyPeriod[] => {
  return weeklyPeriods
}

export const getWeeklyPeriod = ({ weeklyPeriods }: SchedulingState): WeeklyPeriodNumeric[] => {
  return weeklyPeriods.map(weeklyPeriod => {
    return {
      day: week.indexOf(weeklyPeriod.day),
      start_time: parseInt(weeklyPeriod.start_time.replace(':', '')),
      end_time: parseInt(weeklyPeriod.end_time.replace(':', '')),
    }
  })
}

export const getPreferences = ({ preferences }: SchedulingState): Preferences | null => {
  return preferences
}

export const isAvailableForScheduledItems = (state: SchedulingState): boolean => {
  return state.is_available_for_scheduled_items
}

export const getElementToken = ({ elementToken }: SchedulingState): string | null => {
  return elementToken
}

export const getCalendarEvents = ({ calendarEvents }: SchedulingState, user_timeZone: string): CalendarEvent[] => {
  if (calendarEvents) {
    calendarEvents = calendarEvents.map(calendarEvent => {
      return {
        ...calendarEvent,
        start: utcToZonedTime(calendarEvent.start, user_timeZone),
        end: utcToZonedTime(calendarEvent.end, user_timeZone),
      }
    })
    return calendarEvents
  } else {
    return []
  }
}

export const getCurrentStep = (state: SchedulingState): OnboardingStates => {
  const accountSub = getAccountData(state)
  const availabilityRules = getAvailabilityRules(state)
  const preferences = getPreferences(state)
  const preferencesMeta = getPreferencesMeta(state)
  const schedulingMeta = getSchedulingMeta(state)

  if (Object.keys(accountSub).length === 0 && schedulingMeta[SCHEDULING.GET_PREFERENCES].loaded) {
    return OnboardingStates.Trigger
  } else if (availabilityRules && availabilityRules.length === 0 && preferencesMeta && preferencesMeta.loaded) {
    return OnboardingStates.Availability
  } else if (!preferences && preferencesMeta && preferencesMeta.loaded) {
    return OnboardingStates.Preferences
  } else {
    return OnboardingStates.Completed
  }
}
export const getAvailabilityTimeBoundaries = (state: SchedulingState): [string, string] => {
  const availabilityRules = getAvailabilityRules(state)
  const preferencesMeta = getPreferencesMeta(state)
  if (preferencesMeta && preferencesMeta.loaded) {
    const start = availabilityRules.reduce(
      (min, period) => (period.start_time < min ? period.start_time : min),
      availabilityRules[0].start_time
    )
    const end = availabilityRules.reduce(
      (max, period) => (period.end_time > max ? period.end_time : max),
      availabilityRules[0].end_time
    )

    let buffer = 0
    const [startHr, startMin] = start.split(':')
    const [endHr, endMin] = end.split(':')

    const diff = parseInt(endHr) - parseInt(startHr)
    if (diff < 8) {
      buffer = Math.floor((8 - diff) / 2)
    }
    const min = parseInt(startHr) - buffer + ':' + startMin
    const max = parseInt(endHr) + buffer + ':' + endMin

    return [min, max]
  } else {
    return ['08:00', '18:00']
  }
}

export const getCalendarTimeBoundaries = (state: SchedulingState): [Date, Date] => {
  const [min, max] = getAvailabilityTimeBoundaries(state)
  const start = new Date(1970, 1, 1, parseInt(min.split(':')[0]), parseInt(min.split(':')[1]), 0)
  const end = subSeconds(new Date(1970, 1, 1, parseInt(max.split(':')[0]), parseInt(max.split(':')[1]), 0), 1)
  return [start, end]
}
export const getSchedulingMeta = (state: SchedulingState): SchedulingMeta => state.metaData

export const userSetupScheduling = (state: SchedulingState): boolean =>
  Object.keys(state.accounts).length > 0 &&
  state.weeklyPeriods.length > 0 &&
  state.preferences != null &&
  Object.keys(state.preferences).length > 0

export const getPreferencesMeta = (state: SchedulingState): LoadedLoadingErrorState =>
  state.metaData[SCHEDULING.GET_PREFERENCES]

export const getElementTokenMeta = (state: SchedulingState): LoadedLoadingErrorState =>
  state.metaData[SCHEDULING.GET_AVAILABILITY_WIDGET_TOKEN]

export const getPhoneConsultationItems = (itemsDict: HashMap<HashMap<OrderItem>>): OrderItem[] => {
  return Object.values(
    filterHashMapAny<OrderItem>(
      itemsDict,
      item =>
        item.core_product_key === OrderItemTypes.PhoneConsultation ||
        item.core_product_key === OrderItemTypes.TopInterview
    )
  )
    .map(items =>
      Object.values(items).filter(
        item =>
          !!item.status.active &&
          item.status.active.find(
            s =>
              s.state === OrderItemStates.SchedulePhoneCall ||
              s.state === OrderItemStates.ScheduleInterview ||
              s.state === OrderItemStates.ConductCall ||
              s.state === OrderItemStates.ConductInterview ||
              s.state === OrderItemStates.AwaitingScheduling
          )
      )
    )
    .flat()
}

export const phoneConsultationItemsSelector = createSelector<
  OrderItemState & ClientsState & OrdersState,
  HashMap<HashMap<OrderItem>>,
  HashMap<Order>,
  Client[],
  PhoneConsultationDetails[]
>(itemsByOrderIdSelector, ordersSelector, clientsSelector, (itemsDict, orders, clients) => {
  return getPhoneConsultationItems(itemsDict)
    .map(item => {
      const client = clients.find(client => client.id === orders[item.order_id].client_id) as Client
      return {
        item_id: item.id,
        item_status: item.status.active[0].state,
        item_due_at: item.status.active[0].due_at,
        order_id: item.order_id,
        client_name: client.full_name,
        client_id: client.id,
        brand: orders[item.order_id].brand.name,
        meeting_id: item.meeting_id,
        meeting_occurred: item.meeting_occurred,
      }
    })
    .sort((a, b) => {
      return (
        differenceInMinutes(new Date(a.item_due_at || 0), new Date()) -
        differenceInMinutes(new Date(b.item_due_at || 0), new Date())
      )
    })
})

export const schedulingSelector: Selector<SchedulingState, CalendarEvent[]> = state => state.calendarEvents

export const calendarEventsSelector = createSelector<
  ClientsState & SchedulingState,
  Client[],
  CalendarEvent[],
  CalendarEvent[]
>([clientsSelector, schedulingSelector], (clients, calendarEvents) => {
  if (calendarEvents) {
    const { timeZone } = useUserInfoState()
    return calendarEvents.map(event => {
      const client = clients.find(client => client.id === parseInt(event.client_id))
      return {
        ...event,
        title: `${client?.full_name || event.title}`,
        start: utcToZonedTime(event.start, timeZone),
        end:
          event.type === EventTypes.Draft
            ? addMinutes(utcToZonedTime(event.end, timeZone), 30)
            : utcToZonedTime(event.end, timeZone),
      }
    })
  } else {
    return []
  }
})
