import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import i18n from '../../../i18n'
import Button from '../../common/Button'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import DashboardStyles from '../../Dashboard/Styles'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

interface Props {
  onSetAvailability: (start_time: string, end_time: string) => void
}
const useStyles = makeStyles(() =>
  createStyles({
    formGroup: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
    },
    label: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      color: 'black',
      textAlign: 'center',
    },
    formLabel: {
      textAlign: 'center',
      fontSize: '.8rem',
      color: 'black',
      marginTop: '2rem',
    },
    setUpButton: {
      width: '10rem',
      backgroundColor: DashboardStyles.getOrdersButton.regularColor,
      color: 'white',
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: DashboardStyles.getOrdersButton.weight,
      '&:hover': {
        backgroundColor: DashboardStyles.getOrdersButton.hoverColor,
      },
      marginTop: '2.3rem',
    },
    textField: {
      marginTop: '2rem',
      marginLeft: '1rem',
    },
    error: {
      fontSize: '12px',
      color: 'red',
    },
  })
)

const Availability: React.FC<Props> = ({ onSetAvailability }) => {
  const classes = useStyles()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [workingHours, setWorkingHours] = React.useState({
    startTime: '08:00',
    endTime: '17:00',
  })

  const [timeError, setTimeError] = useState('')
  const handleTimeChange = (event: { target: { name: string; value: string } }) => {
    setWorkingHours({ ...workingHours, [event.target.name]: event.target.value })
    if (!/^([0-1][0-9]|2[0-3]):([0-5][0-9])?$/.test(event.target.value)) {
      setTimeError(i18n.t('schedule_calendar_invalid'))
    } else {
      setTimeError('')
    }
  }

  const handleSubmitClick = async () => {
    if (
      /^([0-1][0-9]|2[0-3]):([0-5][0-9])?$/.test(workingHours.endTime) &&
      /^([0-1][0-9]|2[0-3]):([0-5][0-9])?$/.test(workingHours.startTime)
    ) {
      setTimeError('')
      if (workingHours.startTime >= workingHours.endTime) {
        setTimeError(i18n.t('schedule_calendar_invalid_start'))
      } else {
        setTimeError('')
        setIsSubmitting(true)
        await onSetAvailability(workingHours.startTime, workingHours.endTime)
      }
    } else {
      setTimeError(i18n.t('schedule_calendar_invalid'))
    }
  }

  return (
    <React.Fragment>
      <Typography className={classes.label} component={'h2'}>
        {i18n.t('schedule_calendar_start_end')}
      </Typography>
      <FormGroup className={classes.formGroup}>
        {!!timeError && <Typography className={classes.error}>{timeError}</Typography>}
        <Grid container justify="center">
          <TextField
            name="startTime"
            value={workingHours.startTime}
            label={i18n.t('schedule_calendar_start')}
            type="time"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 30 min
            }}
            onChange={handleTimeChange}
            size={'small'}
          />
          <TextField
            name="endTime"
            value={workingHours.endTime}
            label={i18n.t('schedule_calendar_end')}
            type="time"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800, // 30 min
            }}
            onChange={handleTimeChange}
            size={'small'}
          />
        </Grid>
        <Button
          type="primary"
          className={classes.setUpButton}
          onClick={() => handleSubmitClick()}
          showLoader={isSubmitting}
          disabled={!!timeError}
        >
          {i18n.t('schedule_calendar_next')}
        </Button>
      </FormGroup>
    </React.Fragment>
  )
}

export default Availability
