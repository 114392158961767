import React from 'react'
import htmlparser2, { DomElement } from 'htmlparser2'
import ReactHtmlParser from 'react-html-parser'

import FallbackErrorBoundary from '../common/FallbackErrorBoundary'

interface MessageTextProps {
  html: string
}

const cleanupHTML = (node: DomElement): React.ReactElement | void | null => {
  if (node.name) {
    if (['html', 'body'].includes(node.name)) {
      node.name = 'div'
    } else if (['style', 'head', 'meta', 'xml', 'script'].includes(node.name)) {
      return null
    }
    // Hide the account page URL from the writer
    else if (
      node.name === 'a' &&
      node.attribs &&
      node.attribs.href &&
      node.attribs.href.includes('productquestionnaire')
    ) {
      node.name = 'span'
      node.attribs.href = ''
    }
    // Email from headers are fun
    else if (node.name && node.name.match(/\W/g)) {
      const elm = document.createElement('span')
      elm.innerText = `&lt;${node.name}&rt;`
      node.name = 'span'
      // This is so much harder than it seems
      // @ts-ignore
      htmlparser2.DomUtils.appendChild(node, elm)
    }
    // Remove the Office prefix things
    else if (node.name) {
      node.name = node.name.replace('o:', '')
    }
  }

  if (node.attribs) {
    if (node.attribs['lang']) {
      delete node.attribs['lang']
    }

    // Malformed CSS will cause the parser to barf
    if (node.attribs.style && node.attribs.style.includes('=')) {
      node.attribs.style = ''
    }

    // Strip all JS events that may be in here
    Object.keys(node.attribs || {})
      .filter(key => key.includes('on'))
      // @ts-ignore
      .forEach(key => delete node.attribs[key])
  }

  return
}

export const stripHTML = (
  source: string,
  { stripNewlines = false, preview = false, plainText = true } = {}
): string => {
  const el = document.createElement('div')
  el.innerHTML = source
  clean(el)

  if (!el.textContent) {
    return el.textContent || ''
  }

  // Select the first text node so that we can avoid Word tossing CSS in the textContent
  if (preview) {
    const firstTextNode = el.querySelector('div, body, p')

    if (firstTextNode && firstTextNode.textContent) {
      if (stripNewlines) {
        return firstTextNode.textContent.replace(/\n/g, ' ')
      }

      return firstTextNode.textContent
    }
  }

  if (stripNewlines) {
    return el.textContent.replace(/\n/g, ' ')
  }

  if (!plainText) {
    return el.innerHTML
  }

  return el.textContent
}

// This will scrub bad HTML from the nodes so that it can be directly inserted into the HTML
const clean = (node: HTMLElement | ChildNode) => {
  for (let n = 0; n < node.childNodes.length; n++) {
    const child: ChildNode | Element = node.childNodes[n]

    // Strip some attributes
    if (child.nodeType === Node.ELEMENT_NODE && child instanceof Element) {
      // Don't let writers give themselves a bonus
      if (child.nodeName.toLowerCase() === 'a') {
        const href = child.getAttribute('href')

        if (href && href.includes('productquestionnaire')) {
          node.removeChild(child)
          n--
          continue
        }
      }

      // Filter out all the JS that I can. XSS would be really bad here.
      if (child.attributes.length > 0) {
        Array.from(child.attributes).forEach(attr => {
          if (attr.name.includes('on')) {
            child.removeAttribute(attr.name)
          }
        })
      }
    }

    // Outlook does some nasty things with comments that throw off rendering quite a bit. Strip all of them, if
    // possible.
    if (child.nodeType === Node.COMMENT_NODE) {
      node.removeChild(child)
      n--
    } else if (['style', 'link', 'meta', 'xml', 'script'].includes(child.nodeName.toLowerCase())) {
      node.removeChild(child)
      n--
    } else if (child.nodeType === Node.ELEMENT_NODE) {
      clean(child)
    }
  }
}

const MessageText: React.FC<MessageTextProps> = ({ html }) => {
  try {
    const reactHTML = ReactHtmlParser(html, { transform: cleanupHTML })
    return <FallbackErrorBoundary fallback={<pre>{stripHTML(html)}</pre>}>{reactHTML}</FallbackErrorBoundary>
  } catch (e) {
    // If we absolutely cannot parse this into react code, clean it up and directly set it in the HTML.
    return <div dangerouslySetInnerHTML={{ __html: stripHTML(html, { plainText: false }) }} />
  }
}

export default MessageText
