import React from 'react'

const SvgVideoCritique = props => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor" transform="scale(1.2, 1.2)" {...props}>
    <path d="M9.75 9.75C9.55109 9.75 9.36032 9.82902 9.21967 9.96967C9.07902 10.1103 9 10.3011 9 10.5V17.25C9 17.4489 9.07902 17.6397 9.21967 17.7803C9.36032 17.921 9.55109 18 9.75 18H20.25C20.4489 18 20.6397 17.921 20.7803 17.7803C20.921 17.6397 21 17.4489 21 17.25V10.5C21 10.3011 20.921 10.1103 20.7803 9.96967C20.6397 9.82902 20.4489 9.75 20.25 9.75H9.75ZM9.75 8.25H20.25C20.8467 8.25 21.419 8.48705 21.841 8.90901C22.2629 9.33097 22.5 9.90326 22.5 10.5V17.25C22.5 17.8467 22.2629 18.419 21.841 18.841C21.419 19.2629 20.8467 19.5 20.25 19.5H9.75C9.15326 19.5 8.58097 19.2629 8.15901 18.841C7.73705 18.419 7.5 17.8467 7.5 17.25V10.5C7.5 9.90326 7.73705 9.33097 8.15901 8.90901C8.58097 8.48705 9.15326 8.25 9.75 8.25ZM12.75 20.25H17.25C17.4489 20.25 17.6397 20.329 17.7803 20.4697C17.921 20.6103 18 20.8011 18 21C18 21.1989 17.921 21.3897 17.7803 21.5303C17.6397 21.671 17.4489 21.75 17.25 21.75H12.75C12.5511 21.75 12.3603 21.671 12.2197 21.5303C12.079 21.3897 12 21.1989 12 21C12 20.8011 12.079 20.6103 12.2197 20.4697C12.3603 20.329 12.5511 20.25 12.75 20.25Z" />
    <path d="M18 14L13.125 16.1651V11.8349L18 14Z" />
  </svg>
)

export default SvgVideoCritique
