import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import Box from '@material-ui/core/Box'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import i18n from '../../i18n'
import styles from '../../styles'
import useEditorState, { MessageDraftStorageKey } from './useEditorState'
import { decorators } from './Decorators'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      cursor: 'pointer',
    },
    buttons: {
      color: 'rgba(0, 0, 0, 0.54)',
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      right: '1.3rem',
      top: '.8em',
      width: '3rem',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      marginTop: '.8rem',
      border: 'solid 1px #ebecf0',
      borderRadius: '3px',
    },
    containerExpanded: {
      height: 'calc(100vh - 16.9rem)',

      '@media (max-width: 560px)': {
        height: 'calc(100vh - 13.2rem)',
      },
      '@media (max-width: 540px)': {
        height: 'calc(100vh - 15.4rem)',
      },
    },
    inputContainer: {
      width: '100%',
      boxSizing: 'border-box',
      background: '#fff',
      position: 'relative',
      fontSize: '0.875rem',
    },
    receiver: {
      padding: '0.625rem 0 0 0.625rem',
      display: 'flex',
      fontWeight: 600,

      '@media (max-width: 540px)': {
        maxWidth: 'calc(100% - 5rem)',
      },
    },
    subjectContainer: {
      display: 'flex',
      padding: '0.625rem 0 0.625rem 0.625rem',
      fontWeight: 600,
      alignItems: 'center',
    },
    subject: {
      '&:focus': {
        border: 'none',
        boxShadow: '0',
        outline: 'none',
      },
      flexGrow: 1,
      width: '100%',
      marginLeft: '.2rem',
      border: 'none',
      fontSize: '0.875rem',
      fontWeight: 600,
    },
    editor: {
      padding: '0 0.625rem',
      fontFamily: styles.fonts.primary.family,
      fontWeight: 'normal',
      maxHeight: '25vh',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    editorExpanded: {
      maxHeight: 'calc(100vh - 21rem)',

      '@media (max-width: 560px)': {
        maxHeight: 'calc(100vh - 17.4rem)',
      },
      '@media (max-width: 540px)': {
        maxHeight: 'calc(100vh - 19.6rem)',
      },
    },
  })
)

interface MessageInputProps {
  toolbarVisible: boolean
  subject: string
  setSubject: (value: string) => void
  emailAddress: string
  bccEmailAddress: string | undefined | null
  handleClose?: () => void
}

export const MessageInput: React.FC<MessageInputProps> = ({
  subject,
  setSubject,
  toolbarVisible,
  emailAddress,
  bccEmailAddress,
  handleClose,
}) => {
  const classes = useStyles()
  const { editorState, setEditorState, syncEditorState, clearEditorState, handlePastedText } = useEditorState()
  const [expanded, setExpanded] = useState(false)

  const closeWindow = React.useCallback(() => {
    clearEditorState()
    localStorage.removeItem(MessageDraftStorageKey)
    handleClose && handleClose()
  }, [handleClose, clearEditorState])
  return (
    <Box className={clsx(classes.container, expanded ? classes.containerExpanded : null)}>
      <Box className={classes.inputContainer}>
        <Container className={classes.buttons} disableGutters>
          {!expanded && <ExpandLess className={classes.button} onClick={() => setExpanded(true)} />}
          {expanded && <ExpandMore className={classes.button} onClick={() => setExpanded(false)} />}
          <Close className={classes.button} onClick={closeWindow} />
        </Container>
        <Typography style={{ fontWeight: 'bold' }} variant={'body2'} component="div" className={classes.receiver}>
          {i18n.t('messaging__to', { emailAddress })}
        </Typography>

        {bccEmailAddress && (
          <Typography style={{ fontWeight: 'bold' }} variant={'body2'} component="div" className={classes.receiver}>
            {i18n.t('messaging__bcc', { bccEmailAddress })}
          </Typography>
        )}

        <Box className={classes.subjectContainer}>
          <Typography style={{ fontWeight: 'bold' }} variant={'body2'}>
            <label htmlFor="message-subject">{i18n.t('message__input__subject')}</label>
          </Typography>
          <input
            id="message-subject"
            onChange={e => setSubject(e.target.value)}
            className={classes.subject}
            value={subject}
          />
        </Box>
        <Editor
          placeholder={i18n.t('message__input__placeholder')}
          editorClassName={clsx(classes.editor, 'message-body-input', expanded ? classes.editorExpanded : null)}
          editorState={editorState}
          onEditorStateChange={setEditorState}
          onBlur={() => syncEditorState()}
          toolbarHidden={!toolbarVisible}
          toolbar={{
            options: ['inline', 'list'],
            inline: {
              options: ['bold', 'italic'],
            },
          }}
          spellCheck={true}
          handlePastedText={handlePastedText}
          customDecorators={decorators}
        />
      </Box>
    </Box>
  )
}

export default MessageInput
