import { createSelector } from 'reselect'
import omit from 'lodash/omit'

import { FileUpload, ScanDetails, UploadsState, UploadOverview, FileScan } from '../store/uploads/types'
import * as types from '../store/uploads/types'
import { HashMap } from '../utils/HashMap'
import { OrderItemTypes } from '../store/items/types'
import produce from 'immer'

export interface UploadScanProgress {
  fileUploadIdentifier: string
  scans: ScanDetails[]
  upload: FileUpload
}

export const getPendingUploads = (state: types.UploadsState): types.PendingUploadState[] => Object.values(state.pending)

export const getUploadOverview = (state: UploadsState): UploadOverview[] => {
  return Object.entries(state.pending)
    .map(([filename, pending]) => {
      let preprocess: FileScan | null = null
      let upload: FileUpload | null = null

      if (pending.preprocessID && state.preprocess[pending.preprocessID]) {
        preprocess = state.preprocess[pending.preprocessID]
      }

      if (state.uploads[filename]) {
        upload = state.uploads[filename]
      }

      return {
        pending,
        preprocess,
        upload,
      }
    })
    .sort(overview => {
      // Resumes need to show up first in the scan list for AutoQA to make sense
      return overview.pending.doc_type === OrderItemTypes.CV ||
        overview.pending.doc_type === OrderItemTypes.Resume ||
        overview.preprocess?.order?.includes(types.FileScanOptionKeys.DraftComparison)
        ? -1
        : 1
    })
}

export const uploadsScanProgress = (state: UploadsState): UploadScanProgress[] => {
  try {
    const progress: UploadScanProgress[] = []
    const preprocess = Object.keys(state.preprocess)
    Object.entries(state.uploads).forEach(([identifier, upload]) => {
      const progressItem: UploadScanProgress = {
        fileUploadIdentifier: identifier,
        upload: upload,
        scans: [],
      }
      if (preprocess && upload.preprocessID) {
        const fileScans = state.preprocess[upload.preprocessID]
        if (fileScans) {
          // @ts-ignore
          progressItem.scans = Object.values(fileScans)
        }
      }
      progress.push(progressItem)
    })
    return progress
  } catch (e) {
    return []
  }
}

export const hasUploadsSelector = (state: UploadsState): boolean => Object.keys(state.preprocess).length > 0

export const scansFinishedSelector = createSelector(uploadsScanProgress, uploadsScanProgress =>
  uploadsScanProgress.every(progress => progress.scans.every(scan => !!scan.result))
)

export const getPreprocessScans = (preprocess: FileScan) => omit(preprocess, ['order'])

export const getScanProgress = (overview: UploadOverview): number => {
  const preprocessScans = getPreprocessScans(overview.preprocess || { order: [] })
  // This is variable depending on the documents, so let's assume the worst
  const numberOfScans = Object.keys(preprocessScans).length || 5
  // This is the number of completed scans
  const completedScans = Object.values(preprocessScans).reduce(
    (completed, p) => (completed += p && p.meta.loaded ? 1 : 0),
    0
  )
  // This is the progress of the actual file being sent to the server
  const fileUploadProgress = overview.upload ? overview.upload.meta.progress : 0

  return 100 / ((numberOfScans + 1) / (completedScans + fileUploadProgress / 100))
}

export const getCurrentScanKey = (overview: UploadOverview): types.FileScanOptionKeys | null =>
  overview.preprocess?.order.find(key => {
    return overview.preprocess?.[key]?.meta.isLoading
  }) || null

export const allScansCompleted = (overviews: UploadOverview[]): boolean => {
  return !overviews.find(o => getScanProgress(o) !== 100)
}

export const getUploadedDocumentIDs = (state: UploadsState): number[] => {
  return Object.values(state.uploads)
    .map(u => u.fileID)
    .filter((id: number | undefined): id is number => !!id)
}

export const scansHaveAnyErrors = (state: UploadsState): boolean => {
  return !!Object.values(getScansErrors(state)).find(s => scanHasErrors(s))
}

export const overviewHasAnyErrors = (overviews: UploadOverview[]): boolean => {
  return !!overviews.find(overview => {
    if (!overview.preprocess) {
      return false
    }

    return scanHasErrors(getScanErrors(overview.preprocess))
  })
}

export const getOverviewErrorKeys = (overview: UploadOverview) => {
  if (!overview.preprocess) {
    return null
  }

  return getScanErrors(overview.preprocess)
}

export const scanHasErrors = (scanErrors: ScanErrors): boolean => {
  return Object.values(scanErrors).filter(e => e && e.blockingError).length > 0
}

export interface ScanErrors {
  [types.FileScanOptionKeys.Watermark]: {
    result: types.FileScanResults
    blockingError: boolean
    error: string | null
  }
  [types.FileScanOptionKeys.RA]: {
    result: types.FileScanResults
    blockingError: boolean
  }
  [types.FileScanOptionKeys.Virus]: {
    result: types.FileScanResults
    blockingError: boolean
  }
  [types.FileScanOptionKeys.FileSize]: {
    result: types.FileScanResults
    blockingError: boolean
    percentile: number
    size: string
  }
  [types.FileScanOptionKeys.DraftComparison]: {
    result: types.FileScanResults
    blockingError: boolean
    diff: types.ContactInformationDiff[] | null
  }
  [types.FileScanOptionKeys.AutoQA]: {
    result: types.FileScanResults
    blockingError: boolean
    error: string | null
  }
}

export const defaultScanErrors: ScanErrors = {
  [types.FileScanOptionKeys.Watermark]: {
    result: types.FileScanResults.Pending,
    blockingError: false,
    error: null,
  },
  [types.FileScanOptionKeys.RA]: {
    result: types.FileScanResults.Pending,
    blockingError: false,
  },
  [types.FileScanOptionKeys.Virus]: {
    result: types.FileScanResults.Pending,
    blockingError: false,
  },
  [types.FileScanOptionKeys.FileSize]: {
    result: types.FileScanResults.Pending,
    blockingError: false,
    percentile: 0,
    size: '0KB',
  },
  [types.FileScanOptionKeys.DraftComparison]: {
    result: types.FileScanResults.Pending,
    blockingError: false,
    diff: null,
  },
  [types.FileScanOptionKeys.AutoQA]: {
    result: types.FileScanResults.Pending,
    blockingError: false,
    error: null,
  },
}

export const getScanErrors = (preprocess: types.FileScan): ScanErrors => {
  return produce(defaultScanErrors, draft => {
    const virusScan = preprocess[types.FileScanOptionKeys.Virus]
    if (virusScan?.option && virusScan?.result?.result) {
      draft[types.FileScanOptionKeys.Virus] = {
        result: virusScan.result.result,
        blockingError:
          virusScan.result.result === types.FileScanResults.Failure &&
          !!virusScan.option.require_success &&
          virusScan.meta.loaded,
      }
    }

    const watermarkScan = preprocess[types.FileScanOptionKeys.Watermark]
    if (watermarkScan?.option && watermarkScan?.result?.result) {
      draft[types.FileScanOptionKeys.Watermark] = {
        result: watermarkScan.result.result,
        blockingError:
          watermarkScan.result.result === types.FileScanResults.Failure &&
          !!watermarkScan.option.require_success &&
          watermarkScan.meta.loaded,
        error:
          watermarkScan.result.output && 'error' in watermarkScan.result.output
            ? watermarkScan.result.output['error']
            : null,
      }
    }

    const raScan = preprocess[types.FileScanOptionKeys.RA]
    if (raScan?.option && raScan?.result?.result) {
      draft[types.FileScanOptionKeys.RA] = {
        result: raScan.result.result,
        blockingError:
          raScan.result.result === types.FileScanResults.Failure &&
          !!raScan.option.require_success &&
          raScan.meta.loaded,
      }
    }

    const fileSizeScan = preprocess[types.FileScanOptionKeys.FileSize]
    if (fileSizeScan?.option && fileSizeScan?.result?.result) {
      draft[types.FileScanOptionKeys.FileSize] = {
        ...draft[types.FileScanOptionKeys.FileSize],
        result: fileSizeScan.result.result,
        blockingError:
          fileSizeScan.result.result === types.FileScanResults.Failure &&
          !!fileSizeScan.option.require_success &&
          fileSizeScan.meta.loaded,
      }

      if (fileSizeScan.result.output && !('error' in fileSizeScan.result.output)) {
        draft[types.FileScanOptionKeys.FileSize].percentile = fileSizeScan.result.output.percentile
        draft[types.FileScanOptionKeys.FileSize].size = fileSizeScan.result.output.size
      }
    }
    const autoQAScan = preprocess[types.FileScanOptionKeys.AutoQA]
    if (!!autoQAScan && !!autoQAScan.result && autoQAScan.meta.loaded) {
      if (!autoQAScan.result.output) {
        // @ts-ignore
        draft[types.FileScanOptionKeys.AutoQA].error = autoQAScan.result
      }
      if (
        autoQAScan.result.result === types.FileScanResults.Failure &&
        !!autoQAScan.result.output &&
        'error' in autoQAScan.result.output
      ) {
        draft[types.FileScanOptionKeys.AutoQA].error = autoQAScan.result.output.error
      }
    }
  })
}

export const getScansErrors = (state: UploadsState): HashMap<ScanErrors> => {
  return Object.entries(state.preprocess).reduce((acc, [preprocessID, preprocess]: [string, types.FileScan]) => {
    acc[preprocessID] = getScanErrors(preprocess)
    return acc
  }, {} as HashMap<ScanErrors>)
}

export const getScanErrorsV3 = (preprocess: types.FileScan | null): Partial<ScanErrors> => {
  const errors = preprocess ? getScanErrors(preprocess) : null
  const filteredErrors: Partial<ScanErrors> = {}
  const failureResults = [types.FileScanResults.Warning, types.FileScanResults.Failure]

  if (!errors) {
    return filteredErrors
  }

  if (failureResults.includes(errors[types.FileScanOptionKeys.Virus].result)) {
    filteredErrors[types.FileScanOptionKeys.Virus] = errors[types.FileScanOptionKeys.Virus]
  }

  if (failureResults.includes(errors[types.FileScanOptionKeys.Watermark].result)) {
    filteredErrors[types.FileScanOptionKeys.Watermark] = errors[types.FileScanOptionKeys.Watermark]
  }

  if (failureResults.includes(errors[types.FileScanOptionKeys.FileSize].result)) {
    filteredErrors[types.FileScanOptionKeys.FileSize] = errors[types.FileScanOptionKeys.FileSize]
  }

  if (!!errors[types.FileScanOptionKeys.AutoQA].error) {
    filteredErrors[types.FileScanOptionKeys.AutoQA] = errors[types.FileScanOptionKeys.AutoQA]
  }

  return filteredErrors
}
