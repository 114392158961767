import React, { useCallback, useState } from 'react'
import Box from '@material-ui/core/Box'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import i18n from '../../../i18n'
import DashboardStyles from '../../Dashboard/Styles'
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import styles from '../../../styles'
import LightTooltip from '../../common/LightTooltip'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../store'
import { phoneConsultationItemsSelector } from '../../../store/scheduling/selectors'
import { PhoneConsultationDetails } from '../../../store/scheduling/types'
import { OrderItemStates } from '../../../store/items/types'
import { addMinutes, isAfter } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import useUserInfoState from '../../common/useUserInfo'
import { useHistory } from 'react-router'
import { applyTemplateBasedUponOrderCTA } from '../../../store/templates/actions'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: DashboardStyles.fullWidth,
      backgroundColor: '#fff',
      minHeight: '35.3rem',
    },
    accordionContainer: {
      width: DashboardStyles.fullWidth,
      backgroundColor: DashboardStyles.card.bg,
    },
    accordion: {
      width: DashboardStyles.fullWidth,
      display: 'flex',
      flexDirection: 'column',
    },
    accordionSummary: {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: '1rem 2rem 0',
    },
    accordionSummaryContent: {
      cursor: 'auto',
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      padding: '0 2rem',
      color: styles.palette.darkerBlue.color.hex,
      fontSize: '1.25em',
      minHeight: '0',
    },
    accordionDetailsContent: {
      margin: 0,
    },
    expandIcon: {
      height: 'auto',
      width: '2rem',
      color: 'black',
    },
    root: {
      width: '100%',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1.25em',
      padding: '1.875rem',
      width: '100%',
    },
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    sectionHeaderText: {
      paddingRight: '.5em',
      fontSize: '1em',
      fontWeight: 'bold',
    },
    details: {
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: '1px solid #edf0f5',
      padding: '1rem 0',
    },
    unassignedName: {
      minWidth: '17.6rem',
      fontWeight: 600,
      fontSize: '.9rem',
    },
    invite: {
      alignSelf: 'flex-start',
      fontSize: '0.9rem',
      fontStyle: 'italic',
      color: '#42516d',
    },
    nudge: {
      alignSelf: 'flex-end',
    },
    rescheduleButton: {
      width: '8rem',
      backgroundColor: '#09143b',
      color: 'white',
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: DashboardStyles.getOrdersButton.weight,
      '&:hover': {
        backgroundColor: '#09143b',
      },
    },
  })
)

const UnfulfilledContainer: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id, timeZone } = useUserInfoState()
  const history = useHistory()
  const [unassigned, setUnassigned] = useState<boolean>(true)
  const [missed, setMissed] = useState<boolean>(true)

  const handleChangeUnassigned = () => {
    setUnassigned(!unassigned)
  }

  const handleChangeMissed = () => {
    setMissed(!missed)
  }

  const phoneConsultationItems = useSelector<AppState, PhoneConsultationDetails[]>(state =>
    phoneConsultationItemsSelector({
      ...state.itemsReducer,
      ...state.orderReducer,
      ...state.clientReducer,
    })
  )

  const unassignedDetails = phoneConsultationItems.filter(
    details =>
      details.item_status === OrderItemStates.SchedulePhoneCall ||
      details.item_status === OrderItemStates.ScheduleInterview ||
      details.item_status === OrderItemStates.AwaitingScheduling
  )

  const missedDetails = phoneConsultationItems.filter(
    details =>
      (details.item_status === OrderItemStates.ConductCall ||
        details.item_status === OrderItemStates.ConductInterview) &&
      isAfter(new Date(), addMinutes(utcToZonedTime(details.item_due_at || 0, timeZone), 31))
  )

  const reschedule = useCallback(
    async (clientID: number, orderID: number) => {
      if (id) {
        await dispatch(applyTemplateBasedUponOrderCTA(id, clientID, orderID, OrderItemStates.RescheduleMessage))
      }

      history.push({
        pathname: `/client/${clientID}/messages`,
        search: window.location.search,
        state: {
          reschedule: true,
        },
      })
    },
    [dispatch, history, id]
  )

  return (
    <Box className={classes.container}>
      <Box className={classes.accordionContainer}>
        <Accordion className={classes.accordion} expanded={unassigned} onChange={handleChangeUnassigned}>
          <AccordionSummary
            classes={{
              content: classes.accordionSummaryContent,
              root: classes.accordionSummary,
            }}
            aria-controls={`unassigned`}
            id={`unassigned`}
            expandIcon={
              <LightTooltip title={<>{i18n.t('aresearch__learn_More')}</>} placement="bottom">
                <ExpandMoreIcon id={'expand-icon'} className={classes.expandIcon} />
              </LightTooltip>
            }
          >
            <Box className={classes.sectionHeader}>
              <Typography className={classes.sectionHeaderText} component="h2">
                {i18n.t('schedule_unfulfilled_unassigned')}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {unassignedDetails &&
              unassignedDetails.map(detail => (
                <Box key={detail.item_id} className={classes.details}>
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.unassignedName}>
                      {detail.client_name} (#{detail.client_id})
                    </Typography>
                    <Typography className={classes.invite}></Typography>
                  </Box>
                  <Typography className={classes.nudge}></Typography>
                </Box>
              ))}
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion} expanded={missed} onChange={handleChangeMissed}>
          <AccordionSummary
            classes={{
              content: classes.accordionSummaryContent,
              root: classes.accordionSummary,
            }}
            aria-controls={`missed`}
            id={`missed`}
            expandIcon={
              <LightTooltip title={<>{i18n.t('aresearch__learn_More')}</>} placement="bottom">
                <ExpandMoreIcon id={'expand-icon'} className={classes.expandIcon} />
              </LightTooltip>
            }
          >
            <Box className={classes.sectionHeader}>
              <Typography className={classes.sectionHeaderText} component="h2">
                {i18n.t('schedule_unfulfilled_missed')}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {missedDetails &&
              missedDetails.map(detail => (
                <Box key={detail.item_id} className={classes.details}>
                  <Box display="flex" flexDirection="row">
                    <Typography className={classes.unassignedName}>
                      {detail.client_name} (#{detail.client_id}){' '}
                    </Typography>
                    <Typography className={classes.invite}>{i18n.t('schedule_unfulfilled_missed_call')}</Typography>
                  </Box>
                  <Button
                    onClick={() => reschedule(detail.client_id, detail.order_id)}
                    className={classes.rescheduleButton}
                  >
                    {i18n.t('schedule_unfulfilled_reschedule')}
                  </Button>
                </Box>
              ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  )
}

export default UnfulfilledContainer
