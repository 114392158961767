import React from 'react'
import { AutoLinkedinSections } from '../../store/autolinkedin/types'
import TextField from '@material-ui/core/TextField'
import { Field, FieldRenderProps } from 'react-final-form'
import i18n from '../../i18n'
import { FormFieldSchema } from './formSchema'
import { FieldValidator } from 'final-form'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import makeStyles from '@material-ui/core/styles/makeStyles'
import createStyles from '@material-ui/core/styles/createStyles'
import styles from '../../styles'

export interface LocationProps {
  fieldSchema: FormFieldSchema
  index: number
  inputClass: string
  inputGroupClass: string
  labelClass: string
  noPadding: string
  outerName: string
  required: boolean
  section: AutoLinkedinSections
  validate?: FieldValidator<string>
}

const useStyles = makeStyles(() =>
  createStyles({
    error: {
      color: `${styles.palette.pink.hex} !important`,
    },
  })
)

// to support structured Address, add more Fields to this component
export const LocationField: React.FC<LocationProps> = ({
  fieldSchema,
  index,
  inputClass,
  inputGroupClass,
  labelClass,
  noPadding,
  outerName,
  required,
  section,
  validate,
}) => {
  const classes = useStyles()

  return (
    <Field
      name={`${outerName}.${fieldSchema.name}.freeForm`}
      key={`${outerName}.${fieldSchema.name}`}
      required={required}
      validate={validate}
    >
      {(props: FieldRenderProps<string>) => (
        <>
          <TextField
            {...props.input}
            className={inputGroupClass}
            InputLabelProps={{
              className: classNames({
                [labelClass]: true,
                [classes.error]: !!props.meta.error,
              }),
            }}
            InputProps={{ className: inputClass }}
            inputProps={{ className: noPadding }}
            label={i18n.t(`alink__${section}__${fieldSchema.name}`)}
          />
          {!!props.meta.error && (
            <Typography
              className={classNames({
                [labelClass]: true,
                [classes.error]: true,
              })}
            >
              {props.meta.error}
            </Typography>
          )}
        </>
      )}
    </Field>
  )
}
