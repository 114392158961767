import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import i18n from '../../i18n'
import styles from '../../styles'
import isEqual from 'lodash/isEqual'
import { ClientDataPoint } from '../../store/autolinkedin/types'

export interface SummaryClientDataListProps {
  data: ClientDataPoint[]
  questionnaireGoals: string | undefined
}

const useStyles = makeStyles(() =>
  createStyles({
    mainbox: {
      backgroundColor: '#f5f6fa',
      borderBottom: `.125em solid ${styles.palette.darkerBlue.color.hex}`,
    },
    primaryText: { fontWeight: 'bold' },
  })
)

const SummaryClientDataList: React.FC<SummaryClientDataListProps> = ({ data, questionnaireGoals }) => {
  const classes = useStyles()
  if (!!questionnaireGoals) {
    data.push({ titleKey: 'goals', content: questionnaireGoals })
  }
  return (
    <Box className={classes.mainbox}>
      <List>
        {data.map((e, i, arr) => {
          return (
            <Box>
              <ListItem key={e.titleKey}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography className={classes.primaryText}>{i18n.t('alink__' + e.titleKey) + ':'}</Typography>
                  }
                  secondary={<Typography>{e.content}</Typography>}
                />
              </ListItem>
              {i !== arr.length - 1 && <Divider />}
            </Box>
          )
        })}
      </List>
    </Box>
  )
}

const memoizedSummaryClientDataList = React.memo(SummaryClientDataList, isEqual)
// @ts-ignore
memoizedSummaryClientDataList.whyDidYouRender = true
export default memoizedSummaryClientDataList
