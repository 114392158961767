import { useCallback, useEffect, useState } from 'react'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import { useDispatch, useSelector } from 'react-redux'
import { createGlobalState } from 'react-hooks-global-state'

import { AppState } from '../../store'
import { Snippet } from '../../store/templates/types'
import { currentMessageUpdate, currentMessageReset } from '../../store/currentMessage/actions'
import { applyTemplateToCurrentMessage } from '../../store/templates/actions'
import { cleanEditorState, insertHtmlAtCursor, editorStateHasPlaceholderText, onPaste } from '../../utils/draftjs'
import { HandlebarsContextType, renderEmailTemplate } from '../../utils/handlebars'
import { Template } from '../../store/templates/types'
import { addSeconds } from 'date-fns'
import { CurrentMessage } from '../../store/currentMessage/types'
import { useEffectOnce } from 'react-use'

export const MessageDraftStorageKey = 'message-draft'

interface UseEditorState {
  editorState: EditorState
  setEditorState: (editorState: EditorState) => void
  syncEditorState: (updatedEditorState?: EditorState) => void
  insertSnippetAtCursor: (snippet: Snippet, handlebarsContext: HandlebarsContextType) => void
  insertTemplate: (template: Template, handlebarsContext: HandlebarsContextType) => Promise<void>
  clearEditorState: (effectRedux?: boolean) => void
  handlePastedText: (text: string, html: string) => boolean
  hasText: boolean
  hasPlaceholderText: boolean
}

// Could I have saved a dependency and rolled my own context provider? Yes, but my brain is mush and the API around this
// was so much easier than rolling my own.
const { GlobalStateProvider, useGlobalState } = createGlobalState({
  editorState: EditorState.createEmpty(),
})

export const EditorStateProvider = GlobalStateProvider

/**
 * useEditorState is a React hook the provides utilites for working with draft.js's EditorState in the context of our
 * application.
 *
 * A little bit of background: Once upon a time, we were syncing the EditorState to Redux on each keypress. This worked
 * fine for a bit, but we eventually ran into performance issues because large parts of the app were re-rendering on
 * each keypress. In an effort to avoid this, we moved the state so that it's local to the component and shared via
 * a context provider. We still have a bunch of thunks that use the currentMessage store and we don't want to rewrite
 * that and we need to sync to it, so we sync to Redux in the hook when the editor loses focus.
 */
export const useEditorState = (): UseEditorState => {
  const dispatch = useDispatch()
  const currentMessageState = useSelector<AppState, CurrentMessage>(state => state.currentMessageReducer)
  const [editorState, updateEditorState] = useGlobalState('editorState')
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [lastSynced, setLastSynced] = useState<Date | undefined>()
  const hasText = editorState.getCurrentContent().hasText()
  const hasPlaceholderText = editorStateHasPlaceholderText(editorState)

  const setEditorState = useCallback(
    (newEditorState: EditorState) => {
      updateEditorState(previousEditorState => {
        const lastChange = newEditorState.getLastChangeType()
        const shouldFilterCopyPaste =
          previousEditorState.getCurrentContent() !== newEditorState.getCurrentContent() &&
          (lastChange === 'insert-fragment' || lastChange === 'insert-characters')

        if (shouldFilterCopyPaste) {
          return cleanEditorState(newEditorState)
        }

        return newEditorState
      })
      if ((!lastSynced || lastSynced < addSeconds(new Date(), -20)) && newEditorState.getCurrentContent().hasText()) {
        localStorage.setItem(
          MessageDraftStorageKey,
          JSON.stringify({
            ...currentMessageState,
            editorState: convertToRaw(newEditorState.getCurrentContent()),
          })
        )
        setLastSynced(new Date())
      }
    },
    // We don't want it to run every time when currentMessageState changes
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [updateEditorState, lastSynced, setLastSynced]
  )

  const handlePastedText = useCallback(
    (text: string, html: string): boolean => {
      updateEditorState(previousEditorState => {
        return onPaste(previousEditorState, text, html)
      })

      return true
    },
    [updateEditorState]
  )

  const syncEditorState = useCallback(
    (updatedEditorState?: EditorState) =>
      dispatch(
        currentMessageUpdate({
          editorState: updatedEditorState || editorState,
        })
      ),
    [dispatch, editorState]
  )

  const insertSnippetAtCursor = useCallback(
    (snippet: Snippet, handlebarsContext: HandlebarsContextType) => {
      updateEditorState(currentState => {
        const renderedSnippetText = renderEmailTemplate(snippet.body, handlebarsContext)
        const updatedState = insertHtmlAtCursor(currentState, renderedSnippetText)
        syncEditorState(updatedState)

        return updatedState
      })
    },
    [updateEditorState, syncEditorState]
  )

  const insertTemplate = useCallback(
    async (template: Template, handlebarsContext: HandlebarsContextType) => {
      const action = applyTemplateToCurrentMessage(template, handlebarsContext)

      if (action.messageUpdate.editorState) {
        updateEditorState(action.messageUpdate.editorState)
      }

      await dispatch(action)
    },
    [dispatch, updateEditorState]
  )

  const clearEditorState = useCallback(
    async (effectRedux = true) => {
      if (effectRedux) {
        await dispatch(currentMessageReset())
      }

      setEditorState(EditorState.createEmpty())
    },
    [setEditorState, dispatch]
  )

  // If the Redux content is much larger than the content in the local state, assume that a CTA key press changed it and
  // switch to it. This is a terrible hack for when the message composition window is open and the user presses a CTA.
  // This can't be done "the right way" because it leads to re-render hell if I were to wrap the whole message
  // composition window with the EditorStateProvider. We will only do this outside of the first render AND if the editor
  // doesn't have focus.
  // @TODO Convert this to event listening logic as this is hacky as hell
  useEffect(() => {
    if (!isFirstRender && !editorState.getSelection().getHasFocus()) {
      const reduxContentLength = currentMessageState.editorState.getCurrentContent().getPlainText().length
      const localContentLength = editorState.getCurrentContent().getPlainText().length

      if (reduxContentLength - localContentLength >= 10) {
        setEditorState(currentMessageState.editorState)
      }
    }
  }, [editorState, currentMessageState, setEditorState, isFirstRender])

  // Check local storage for a message draft for this client before rendering the default message. If draft found, render it instead; If draft found but
  // it is for another client, remove the draft from local storage since we're only keeping track of one draft at a time in case of emergency (app crash/
  // accidental page refresh)
  useEffectOnce(() => {
    if (isFirstRender) {
      const messageDraft = JSON.parse(localStorage.getItem(MessageDraftStorageKey) as string)
      if (!!messageDraft) {
        if (messageDraft.client_id && window.location.pathname.includes(messageDraft.client_id)) {
          const newEditorState = EditorState.createWithContent(convertFromRaw(messageDraft.editorState))
          dispatch(
            currentMessageUpdate({
              ...messageDraft,
              editorState: newEditorState,
            })
          )
          setEditorState(newEditorState)
        } else {
          localStorage.removeItem(MessageDraftStorageKey)
        }
      }
      if (currentMessageState.editorState.getCurrentContent().hasText()) {
        setEditorState(currentMessageState.editorState)
      }
      setIsFirstRender(false)
    }
  })

  return {
    editorState,
    setEditorState,
    syncEditorState,
    insertSnippetAtCursor,
    insertTemplate,
    clearEditorState,
    handlePastedText,
    hasText,
    hasPlaceholderText,
  }
}

export default useEditorState
