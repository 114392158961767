import Lightbox from 'react-image-lightbox'
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import VisibilityIcon from '@material-ui/icons/Visibility'
import i18n from '../../i18n'
import { Box } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'

export interface ResumePreviewProps {
  imageUrls?: string[] | undefined
  iconClass: string
  iconTextClass: string
}

const useStyles = makeStyles(() =>
  createStyles({
    '@global .ril__image': {
      backgroundColor: 'white',
    },
    '@global .ril__image.ril-not-loaded': {
      backgroundColor: 'transparent',
    },
  })
)

const ResumePreview: React.FC<ResumePreviewProps> = ({ imageUrls, iconClass, iconTextClass }) => {
  const [visible, setVisible] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  // eslint-disable-next-line
  const classes = useStyles()

  if (!imageUrls || imageUrls.length === 0) {
    return null
  }

  return (
    <Box>
      <Typography className={iconTextClass} onClick={() => setVisible(true)}>
        <VisibilityIcon className={iconClass} /> {i18n.t('alink__nav__resume_preview')}
      </Typography>
      {visible && (
        <Lightbox
          mainSrc={imageUrls[photoIndex]}
          nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
          prevSrc={imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]}
          onCloseRequest={() => {
            setVisible(false)
            setPhotoIndex(0)
          }}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + imageUrls.length - 1) % imageUrls.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % imageUrls.length)}
        />
      )}
    </Box>
  )
}

export default ResumePreview
