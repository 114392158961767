import { createSelector, Selector } from 'reselect'
import { Client, ClientsState } from '../store/clients/reducer'
import { OrdersState } from '../store/orders/reducer'
import { OrderItem, OrderItemState, OrderItemStates } from '../store/items/types'
import { HashMap } from '../utils/HashMap'
import { BasePay, Order, Brand } from '../store/orders/types'
import { getOrderItemsForDisplay, paidOrdersSelector } from './orders'
import {
  activeOrdersSelector,
  completeOrderIdsSelector,
  eligibleToCloseOrderIdsSelector,
  itemsByOrderIdSelector,
  newOrderIdsSelector,
  ordersSelector,
  phoneCallOrderIdsSelector,
  revisionsOrderIdsSelector,
  scheduledOrderIdsSelector,
  sendSummaryOrderIdsSelector,
  toScheduleOrderIdsSelector,
} from './items'
import { clientByIdSelector, clientsSelector, clientStateByIdSelector, clientStatesSelector } from './clients'
import { calculateMacroState, MacroState } from '../utils/mapper'
import { OrderStatus } from '../store/orders/actions'
import { FulfillableOrderItemDisplay, GenericOrderItemDisplay } from '../components/Orders/OrderItems'
import { ClientOrdersAggregateState } from '../store/clientOrderAggregate/reducer'
import { LoadedLoadingErrorState } from '../utils/state'
import { groupBy } from 'lodash'
import { Incentive, IncentivesState } from '../store/incentives/types'
import { timeBasedIncentivesSelector } from './incentives'
import { TagsPK, UserInfo, UserState } from '../store/user/reducers'
import { addDays } from 'date-fns'
import { loggedInUserSelector } from './users'
import { OrderTableDisplayModes } from '../components/Orders/useOrderFiltering'
import { showScheduler } from './featureFlags'

export interface ClientOrdersDisplayAggregate {
  client: Client
  orderIDs: number[]
  openOrderIDs: number[]
  assignedWriter: string | null
  lastAssignedDate: Date | null
  macroState: MacroState
  basePay: BasePay
  unreadMailCount: number
  status: OrderStatus
  items: (FulfillableOrderItemDisplay | GenericOrderItemDisplay)[]
  timeBasedIncentives: Incentive[]
  isOpen: boolean
  brand: Brand
}

export const clientStateLoaded = (state: ClientOrdersAggregateState, clientID: number): boolean => {
  const meta = state.metaData.individual[clientID]
  return !!meta && !meta.isLoading && !meta.error && meta.loaded
}

export const clientStateNeedsLoading = (state: ClientOrdersAggregateState, clientID: number): boolean => {
  const meta = state.metaData.individual[clientID]
  return !meta || (!meta.isLoading && !meta.error && !meta.loaded)
}

export const clientStateMeta = (state: ClientOrdersAggregateState, clientID: number): LoadedLoadingErrorState =>
  state.metaData.individual[clientID]

export const getClientWriters = (orders: HashMap<Order>, clientID: number) => {
  return [
    ...new Set(
      Object.values(orders)
        .filter(order => order.client_id === clientID)
        .map(order => order.assigned_writer)
    ),
  ]
}

const getClientSpecificOrders = (
  client: Client,
  macroState: MacroState,
  orders: HashMap<Order>,
  orderIDs: number[],
  items: HashMap<HashMap<OrderItem>>,
  incentives: HashMap<Incentive[]>,
  user: UserInfo | null,
  onlyOpen?: boolean
): ClientOrdersDisplayAggregate | null => {
  let clientOrders = orderIDs.map(id => orders[id])

  if (onlyOpen) {
    clientOrders = clientOrders.filter(x => !!x && x.status === 'open')
    orderIDs = orderIDs.filter(id => clientOrders.map(o => o.order_id).includes(id))
    if (clientOrders.length === 0) {
      return null
    }
  }

  const sortedOrders = clientOrders.sort((a: Order, b: Order) => (a.due_date > b.due_date ? 1 : -1))
  const mostRecentOrder = sortedOrders[0]
  const isUserPK = !!user && user.tag_keys.includes(TagsPK)
  const isUserSchedulerTest = !!user && showScheduler(user)

  // this is a display hack
  const clientItems = getOrderItemsForDisplay(
    orderIDs.map(id => Object.values(!!items[id] ? items[id] : [])).flat(),
    isUserPK ? addDays(new Date(mostRecentOrder.last_assigned_date), 3) : mostRecentOrder.due_date
  )

  if (isUserPK) {
    clientItems.forEach(item => {
      const fulfillableItem = item as FulfillableOrderItemDisplay
      if (fulfillableItem.history) {
        fulfillableItem.history.active.forEach(state => {
          if (state.state === OrderItemStates.SendFirstDraft) {
            state.due_at = addDays(new Date(orders[item.order_id].last_assigned_date), 3).toISOString()
          }
        })
      }
    })
  }

  const groupMacroState = calculateMacroState(
    orderIDs.map(id => Object.values(!!items[id] ? items[id] : [])).flat(),
    isUserPK ? addDays(new Date(mostRecentOrder.last_assigned_date), 3) : mostRecentOrder.due_date,
    isUserSchedulerTest
  )

  const timeBasedIncentives = !incentives[client.id]
    ? []
    : incentives[client.id].filter(i => new Date(i.bonus_due_date) > new Date())

  const latestAssignedDate =
    clientOrders.filter(o => o.last_assigned_date !== '').length > 0
      ? new Date(
          clientOrders
            .filter(o => o.last_assigned_date !== '')
            .map(o => o.last_assigned_date)
            .reduce((latestAssigned, current) =>
              new Date(current) >= new Date(latestAssigned) ? current : latestAssigned
            )
        )
      : null

  const basePayAmount = clientOrders.map(order => order.base_pay.amount).reduce((sum, pay) => sum + pay, 0)
  return {
    client: client,
    orderIDs: orderIDs,
    openOrderIDs: clientOrders.filter(o => o.status === 'open').map(or => or.order_id),
    assignedWriter: clientOrders[0].assigned_writer,
    macroState: groupMacroState,
    items: clientItems.flat(),
    basePay: {
      amount: basePayAmount,
      currency: clientOrders[0].base_pay.currency,
    },
    unreadMailCount: client.unread_mail_count,
    status: mostRecentOrder.status,
    timeBasedIncentives,
    isOpen:
      groupMacroState.CTA !== OrderItemStates.Paid &&
      groupMacroState.CTA !== OrderItemStates.Closed &&
      groupMacroState.CTA !== OrderItemStates.Refunded &&
      groupMacroState.CTA !== OrderItemStates.Canceled,
    brand: clientOrders[0].brand,
    lastAssignedDate: latestAssignedDate,
  }
}

const getClientOrders = (
  client: Client,
  macroState: MacroState,
  orders: HashMap<Order>,
  items: HashMap<HashMap<OrderItem>>,
  incentives: HashMap<Incentive[]>,
  userInfo: UserInfo | null,
  onlyOpen?: boolean
) => {
  const orderIDs = client.order_ids
  return getClientSpecificOrders(client, macroState, orders, orderIDs, items, incentives, userInfo, onlyOpen)
}

export const getClientOrdersForCarousel = (
  client: Client,
  macroState: MacroState,
  orders: HashMap<Order>,
  items: HashMap<HashMap<OrderItem>>,
  incentives: HashMap<Incentive[]>,
  user: UserInfo | null
) => {
  const orderIDs = client.order_ids
  const clientOrders = orderIDs.map(id => orders[id])

  const groupedOrders = groupBy(clientOrders, order => {
    const state = order.macroState.CTA
    const key1 =
      state !== OrderItemStates.Paid && state !== OrderItemStates.Closed && state !== OrderItemStates.Refunded
        ? 'open'
        : state
    const key2 = order.assigned_writer

    return `${key1}-${key2}`
  })
  // Everything which is in open groups -> combine
  // Assigned writer sees their orders first
  const open = Object.keys(groupedOrders)
    .filter(key => key.includes('open'))
    .sort((keyOne: string, keyTwo: string) => {
      if (!user) {
        return 0
      }
      if (keyOne.includes(user.uname) && !keyTwo.includes(user.uname)) {
        return -1
      }
      if (keyTwo.includes(user.uname) && !keyOne.includes(user.uname)) {
        return 1
      }
      return 0
    })
    .map(key => groupedOrders[key].map(order => order.order_id))

  // Everything in paid/complete/refunded -> keep separated
  const completed = Object.keys(groupedOrders)
    .filter(key => !key.includes('open'))
    .map(key => groupedOrders[key].map(order => [order.order_id]))
    .flat()

  const groups = [...open]
  completed.forEach(id => groups.push(id))
  return groups.map(group => getClientSpecificOrders(client, macroState, orders, group.flat(), items, incentives, user))
}

export const clientsOrdersSelector = createSelector<
  ClientsState & ClientOrdersAggregateState & OrdersState & OrderItemState & IncentivesState & UserState,
  Client[],
  HashMap<MacroState>,
  HashMap<Order>,
  HashMap<HashMap<OrderItem>>,
  HashMap<Incentive[]>,
  UserInfo | null,
  (ClientOrdersDisplayAggregate | null)[]
>(
  [
    clientsSelector,
    clientStatesSelector,
    ordersSelector,
    itemsByOrderIdSelector,
    timeBasedIncentivesSelector,
    loggedInUserSelector,
  ],
  (clients, states, orders, items, incentives, user) => {
    return clients
      .map(client => getClientOrders(client, states[client.id], orders, items, incentives, user))
      .filter(res => !!res)
  }
)

export const clientsOpenOrdersSelector = createSelector<
  ClientsState & ClientOrdersAggregateState & OrdersState & OrderItemState & IncentivesState & UserState,
  Client[],
  HashMap<MacroState>,
  HashMap<Order>,
  HashMap<HashMap<OrderItem>>,
  HashMap<Incentive[]>,
  UserInfo | null,
  (ClientOrdersDisplayAggregate | null)[]
>(
  [
    clientsSelector,
    clientStatesSelector,
    ordersSelector,
    itemsByOrderIdSelector,
    timeBasedIncentivesSelector,
    loggedInUserSelector,
  ],
  (clients, states, orders, items, incentives, user) => {
    return clients
      .map(client => getClientOrders(client, states[client.id], orders, items, incentives, user, true))
      .filter(res => !!res)
  }
)

export const clientOrdersSelector = createSelector<
  ClientsState & ClientOrdersAggregateState & OrdersState & OrderItemState & IncentivesState & UserState,
  number,
  Client,
  MacroState,
  HashMap<Order>,
  HashMap<HashMap<OrderItem>>,
  HashMap<Incentive[]>,
  UserInfo | null,
  ClientOrdersDisplayAggregate | null
>(
  [
    clientByIdSelector,
    clientStateByIdSelector,
    ordersSelector,
    itemsByOrderIdSelector,
    timeBasedIncentivesSelector,
    loggedInUserSelector,
  ],
  (client, state, orders, items, incentives, userInfo) => {
    return getClientOrders(client, state, orders, items, incentives, userInfo)
  }
)

export const filteredClientsSelectorFactory = (mode: OrderTableDisplayModes | null) => {
  let filter:
    | Selector<OrderItemState, HashMap<HashMap<OrderItem>>>
    | Selector<OrderItemState & OrdersState, HashMap<HashMap<OrderItem>>>
    | Selector<OrdersState, HashMap<Order> | {}> = activeOrdersSelector
  switch (mode) {
    case OrderTableDisplayModes.InRevisions:
      filter = revisionsOrderIdsSelector
      break
    case OrderTableDisplayModes.EligibleToClose:
      filter = eligibleToCloseOrderIdsSelector
      break
    case OrderTableDisplayModes.New:
      filter = newOrderIdsSelector
      break
    case OrderTableDisplayModes.Open:
      filter = activeOrdersSelector
      break
    case OrderTableDisplayModes.Scheduled:
      filter = scheduledOrderIdsSelector
      break
    case OrderTableDisplayModes.ToSchedule:
      filter = toScheduleOrderIdsSelector
      break
    case OrderTableDisplayModes.Paid:
      filter = paidOrdersSelector
      break
    case OrderTableDisplayModes.SendSummary:
      filter = sendSummaryOrderIdsSelector
      break
    case OrderTableDisplayModes.PhoneCalls:
      filter = phoneCallOrderIdsSelector
      break
    case OrderTableDisplayModes.Complete:
      filter = completeOrderIdsSelector
      break
  }
  return createSelector<
    ClientsState & ClientOrdersAggregateState & OrdersState & OrderItemState & IncentivesState & UserState,
    OrderItemState | OrdersState,
    (ClientOrdersDisplayAggregate | null)[],
    HashMap<HashMap<OrderItem>> | HashMap<Order>,
    (ClientOrdersDisplayAggregate | null)[]
  >(
    mode === OrderTableDisplayModes.Paid ? clientsOrdersSelector : clientsOpenOrdersSelector,
    filter,
    (clients, filteredOrderItems) => {
      const orderIDs = Object.keys(filteredOrderItems).map(key => parseInt(key))
      return clients.filter(client => {
        if (mode === OrderTableDisplayModes.UnreadMessages) {
          return !!client ? client.unreadMailCount > 0 : false
        }
        if (mode === OrderTableDisplayModes.SendSummary) {
          return !!client ? client.openOrderIDs.some(id => orderIDs.includes(id)) : []
        }
        return !!client ? client.openOrderIDs.every(id => orderIDs.includes(id)) : []
      })
    }
  )
}

export const paidClientPaginationSelector: Selector<ClientOrdersAggregateState, number[]> = state =>
  state.pagination.paid.pages[state.pagination.paid.page] || []

export const paidOrdersPaginationSelector = createSelector(
  clientsOrdersSelector,
  paidClientPaginationSelector,
  (clients, clientIDsForPage) => {
    if (!clientIDsForPage.length) {
      return []
    }

    return clients.filter(client => !!client && clientIDsForPage.includes(client.client.id))
  }
)

export const searchClientOrderAggregate = (
  data: ClientOrdersDisplayAggregate[],
  searchTerm: string
): ClientOrdersDisplayAggregate[] => {
  if (searchTerm === '') {
    return data
  }

  const term = searchTerm.toLowerCase()

  return data.filter(d => {
    return (
      d.client.full_name.split(' ').some(part => part.toLowerCase().startsWith(term)) ||
      d.client.id.toString().includes(term)
    )
  })
}
