import { UserPromiseState } from './types'
import { initialLoadingErrorState, startLoadingState } from '../../utils/state'
import {
  CleanUpPromises,
  ConvertUserPromise,
  ConvertUserPromiseError,
  ConvertUserPromiseSuccess,
  DismissUserPromise,
  DismissUserPromiseFail,
  DismissUserPromiseSuccess,
  GetUserPromises,
  GetUserPromisesFail,
  GetUserPromisesSuccess,
  PromiseActionTypes,
} from './actions'
import produce from 'immer'

export const initialState: UserPromiseState = {
  promises: {},
  currentPromiseID: null,
  meta: initialLoadingErrorState,
  displayPromises: false,
}

export type PromiseActionType =
  | GetUserPromises
  | GetUserPromisesFail
  | GetUserPromisesSuccess
  | ConvertUserPromise
  | ConvertUserPromiseSuccess
  | ConvertUserPromiseError
  | DismissUserPromise
  | DismissUserPromiseSuccess
  | DismissUserPromiseFail
  | CleanUpPromises

export default function promiseReducer(
  state: UserPromiseState = initialState,
  action: PromiseActionType
): UserPromiseState {
  return produce(state, (draft: UserPromiseState) => {
    switch (action.type) {
      case PromiseActionTypes.GET_USER_PROMISES: {
        draft.meta = startLoadingState
        break
      }

      case PromiseActionTypes.GET_USER_PROMISES_SUCCESS: {
        const response = action.payload.data
        draft.meta = initialLoadingErrorState

        if (!!response) {
          draft.displayPromises = response.display_promises
          if (!!response.promises) {
            response.promises.forEach((promise, i) => {
              if (i === 0) {
                draft.currentPromiseID = promise.id
              }
              draft.promises[promise.id] = promise
            })
            // gotta clean up!
          } else if (draft.currentPromiseID) {
            draft.currentPromiseID = null
            draft.promises = {}
          }
        }
        break
      }

      case PromiseActionTypes.CONVERT_PROMISE:
        draft.meta.isLoading = true
        break

      case PromiseActionTypes.GET_USER_PROMISES_FAIL:
      case PromiseActionTypes.DISMISS_PROMISE_FAIL: {
        draft.meta = {
          error: action.error,
          isLoading: false,
        }
        break
      }
      case PromiseActionTypes.CONVERT_PROMISE_SUCCESS:
      case PromiseActionTypes.DISMISS_PROMISE_SUCCESS: {
        delete draft.promises[action.meta.previousAction.promiseID]
        draft.currentPromiseID = null
        draft.meta.isLoading = false
        break
      }
      case PromiseActionTypes.CONVERT_PROMISE_FAIL:
        draft.meta.error = action.error
        draft.meta.isLoading = false
        break

      case PromiseActionTypes.DISMISS_PROMISE: {
        draft.meta = startLoadingState
        break
      }

      case PromiseActionTypes.CLEAN_UP_PROMISES: {
        draft.currentPromiseID = null
        draft.promises = {}
        break
      }
    }
  })
}
