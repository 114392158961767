import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AccountCircleIconOutlined from '@material-ui/icons/AccountCircleOutlined'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Typography from '@material-ui/core/Typography'
import styles from '../../styles'

import TopMenuItem from './TopMenuItem'
import i18n from '../../i18n'
import { Routes } from '../../utils/consts'
import classNames from 'classnames'
import NavigationItem from '../TopNavBar/NavigationItem'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderLeft: '1px solid #e9ebee',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    menuItem: {
      padding: 0,
      '& a': {
        display: 'block',
        padding: '.25rem 1rem',
      },
    },
    typographyExpanded: {
      borderBottom: 'solid 1px #e4e8f0',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      padding: '1.3rem 0',
      width: '100%',
    },
    userMenu: {
      '@media (max-width: 540px)': {
        display: 'block',
      },
    },
    userMenuExpanded: {
      '@media (max-width: 540px)': {
        backgroundColor: '#fafafc',
        borderTop: 'solid 1px #e4e8f0',
        color: styles.palette.primary.contrast,
        fontSize: '1.1rem',
        padding: '0 2.5rem',
        width: '100%',

        '& a': {
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 600,
          width: '100%',
        },
      },
    },
    collapsed: {
      '@media (max-width: 540px)': {
        display: 'none',
      },
    },
  })
)

export interface UserMenuProps {
  collapsed?: boolean
  expanded?: boolean
  toggleTopNavBar?: React.EventHandler<React.SyntheticEvent>
}

const UserMenu: React.FC<UserMenuProps> = ({ collapsed, expanded, toggleTopNavBar }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  if (expanded) {
    return (
      <Box className={classes.userMenuExpanded}>
        <NavigationItem
          to={Routes.Account}
          onClick={toggleTopNavBar}
          children={
            <Typography className={classes.typographyExpanded} variant={'body2'}>
              {i18n.t('topBar__account__myAccount')}
            </Typography>
          }
        />
        <NavigationItem to={Routes.Logout} onClick={toggleTopNavBar}>
          <Typography className={classes.typographyExpanded} variant={'body2'}>
            {i18n.t('topBar__account__logout')}
          </Typography>
        </NavigationItem>
      </Box>
    )
  } else {
    return (
      <div
        className={classNames({
          [classes.userMenu]: true,
          [classes.collapsed]: collapsed,
        })}
      >
        <TopMenuItem delimiter="none" handleClick={handleClick}>
          <AccountCircleIconOutlined />
          <ArrowDropDownIcon />
        </TopMenuItem>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem className={classes.menuItem}>
            <NavigationItem
              to={Routes.Account}
              children={<Typography variant={'body2'}>{i18n.t('topBar__account__myAccount')}</Typography>}
            />
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <NavigationItem to={Routes.Logout}>
              <Typography variant={'body2'}>{i18n.t('topBar__account__logout')}</Typography>
            </NavigationItem>
          </MenuItem>
        </Menu>
      </div>
    )
  }
}

export default UserMenu
