import { HashMap } from '../../utils/HashMap'

export interface Incentive {
  id: number
  name: string
  internal_name: string
  start_date: string
  end_date: string
  amount: number
  incentive_type: string
  segmentation: string
  industries: number[] | null
  reach: number
  required_tags: number[] | null
  excluded_tags: number[] | null
  all_writers: boolean
  show_in_offer: boolean
  deactivated_at: string | null
  total_spent: number
  time_scale: string
  magnitude: number
  created_by: number
  bonus_due_date: string
}

export interface IncentivesState {
  client: HashMap<Incentive[]>
}

export const initialState: IncentivesState = {
  client: {},
}
