import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

interface ClientInfoProps {
  name: string
  unreadMailCount: number
  clientId: number
  hideAvatar?: boolean
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  table: {
    background: '#fafafa',
  },
  icon: {
    display: 'flex',
    position: 'relative',
    width: '2.813rem',
    height: '2.813rem',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f2f2f4',
    borderRadius: '50%',
    marginRight: '2rem',
    color: theme.palette.primary.main,
    '@media (max-width: 768px)': {
      marginRight: '1rem',
    },
    '@media (max-width: 540px)': {
      minWidth: '2rem',
      width: '2rem',
      height: '2rem',
    },
  },
  notifications: {
    position: 'absolute',
    top: '-.3rem',
    right: '-.6rem',
    borderRadius: '50%',
    width: '1.375rem',
    height: '1.375rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: '0.8125rem',
  },
  name: {
    fontWeight: 600,
    color: '#09123b',
  },
  id: {
    color: '#666a74',
  },
  initials: {
    fontSize: '0.8125rem',
  },
}))

const ClientInfo: React.FC<ClientInfoProps> = (props: ClientInfoProps) => {
  const classes = useStyles()

  const { name, unreadMailCount, clientId, hideAvatar } = props

  const renderInitials = (clientName: string) => {
    return clientName.split(' ').map((n: string) => n.slice(0, 1))
  }

  return (
    <Box className={classes.container}>
      {!hideAvatar && (
        <div className={classes.icon}>
          {unreadMailCount !== 0 && <div className={classes.notifications}>{unreadMailCount}</div>}
          <Typography className={classes.initials} variant={'h3'}>
            {renderInitials(name)}
          </Typography>
        </div>
      )}
      <div className={classes.content}>
        <Typography variant={'body1'} className={classes.name} area-label="order_name">
          {name}
        </Typography>
        <Typography className={classes.id} variant={'h6'} area-label="order_id">
          #{clientId}
        </Typography>
      </div>
    </Box>
  )
}

export default ClientInfo
