import { EditorState } from 'draft-js'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { onPaste } from '../../utils/draftjs'

interface UseLocalEditorState {
  editorState: EditorState | undefined
  setEditorState: Dispatch<SetStateAction<EditorState | undefined>>
  toolbarHidden: boolean
  setToolbarHidden: (v: boolean) => void
  handlePastedText: (
    text: string,
    html: string,
    editorState: EditorState,
    onChange: (editorState: EditorState) => void
  ) => boolean
}

export const useLocalEditorState = (initValue?: EditorState): UseLocalEditorState => {
  const [editorState, setEditorState] = useState<EditorState | undefined>(initValue)
  const [toolbarHidden, setToolbarHidden] = useState(true)

  const handlePastedText = useCallback(
    (text: string, html: string): boolean => {
      setEditorState(previousEditorState => {
        // @ts-ignore
        return onPaste(previousEditorState, text, html)
      })

      return true
    },
    [setEditorState]
  )

  return {
    editorState,
    setEditorState,
    toolbarHidden,
    setToolbarHidden,
    handlePastedText,
  }
}

export default useLocalEditorState
