// import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItem from '@material-ui/core/ListItem'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

import i18n from '../../i18n'
import Typography from '@material-ui/core/Typography'
import { TasksDue } from '../../store/dashboard/types'
import { GhostLink } from '../../utils/link'
import { Routes } from '../../utils/consts'

interface TasksDueInfo {
  countOfDue: number
  urgency: string
}

function getTaskUrgencyData(tasks?: TasksDue | null): TasksDueInfo {
  if (!tasks) {
    return { countOfDue: 0, urgency: i18n.t('tasksdue__urgency__week') }
  }

  if (tasks.late > 0) {
    return { countOfDue: tasks.late, urgency: i18n.t('tasksdue__urgency__late') }
  } else if (tasks.urgent > 0) {
    return { countOfDue: tasks.urgent, urgency: i18n.t('tasksdue__urgency__urgent') }
  } else if (tasks.soon > 0) {
    return { countOfDue: tasks.soon, urgency: i18n.t('tasksdue__urgency__soon') }
  }

  return { countOfDue: tasks.this_week, urgency: i18n.t('tasksdue__urgency__week') }
}

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      color: 'inherit',
      minWidth: '1.8em',
    },
    link: {
      fontWeight: 'bold',
      color: 'inherit',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
)

export const MacroTaskLine: React.FC<{ tasks: TasksDue }> = ({ tasks }) => {
  const taskData = getTaskUrgencyData(tasks)
  const classes = useStyles()

  return (
    <ListItem>
      <ListItemIcon className={classes.icon}>
        <AccessTimeIcon />
      </ListItemIcon>
      <Typography variant={'body1'}>
        <GhostLink to={Routes.OpenOrders} className={classes.link}>
          {taskData.urgency === i18n.t('tasksdue__urgency__late')
            ? i18n.t('dashboard__macro__overdue', { taskCount: taskData.countOfDue })
            : i18n.t('dashboard__macro__taskcount', {
                taskCount: taskData.countOfDue,
                due_time_period: taskData.urgency,
              })}
        </GhostLink>
      </Typography>
    </ListItem>
  )
}

export default MacroTaskLine
