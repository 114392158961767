import React, { useState, useCallback } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Dictionary, groupBy } from 'lodash'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import SubmitIcon from '@material-ui/icons/KeyboardArrowRight'

import i18n from '../../i18n'
import Button from '../common/Button'
import AttachmentsItem from './AttachmentsItem'
import Header from '../common/Header'
import Footer from '../common/Footer'
import ModalWrapper from '../common/ModalWrapper'
import { Document } from '../../store/documents/types'

interface AttachmentsProps {
  documents: Document[]
  onCancel: () => void
  onSubmit: (attachmentIDs: number[]) => void
  defaultSelected: number[]
}

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
    section: {},
    sectionTitle: {
      marginBottom: '.25rem',
      fontSize: '1.4rem',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    sectionContent: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginTop: '.5rem',
        marginRight: '.3rem',
        '@media (max-width: 520px)': {
          width: '100%',
          marginRight: 0,
        },
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      height: '2.25rem',
      marginTop: '1.25rem',
      '@media (max-width: 800px)': {
        justifyContent: 'center',
        flexWrap: 'wrap',
        height: 'auto',
        marginTop: '1rem',
        '& > *': { margin: '.5rem .6rem 0 .6rem !important' },
      },
    },
    cancelButton: {
      marginRight: '1.2rem',
      padding: '.5rem 1.547rem',
    },
    submitButton: {
      position: 'relative',
      padding: '.5rem 2.8595rem .5rem 1.547rem',
      '& .submitButton-icon': {
        position: 'absolute',
        top: '50%',
        right: '1.3rem',
        transform: 'translateY(-50%)',
      },
    },
  })
)

const Attachments: React.FC<AttachmentsProps> = ({ documents, onCancel, onSubmit, defaultSelected }) => {
  const classes = useStyles()
  const [selected, setSelected] = useState<number[]>(defaultSelected)
  const groupedDocuments: Dictionary<Document[]> = groupBy<Document>(documents, d => d.type)

  const toggleAttachment = useCallback(
    (attachmentID: number) => {
      setSelected(currentlySelected => {
        if (currentlySelected.includes(attachmentID)) {
          return currentlySelected.filter(id => id !== attachmentID)
        } else {
          return [...currentlySelected, attachmentID]
        }
      })
    },
    [setSelected]
  )

  return (
    <ModalWrapper>
      <Header activeStep={2} title={i18n.t('uploadsv3__atttachments__title')} />
      <Box className={classes.body}>
        {Object.entries(groupedDocuments).map(([sectionTitle, documents]) => (
          <Box className={classes.section} key={`attachments-section-${sectionTitle}`}>
            <Typography display="block" variant="h3" className={classes.sectionTitle}>
              {sectionTitle}
            </Typography>
            <Box className={classes.sectionContent}>
              {documents.map(doc => (
                <AttachmentsItem
                  key={`attachments-item-${doc.id}`}
                  doc={doc}
                  checked={selected.includes(doc.id)}
                  toggleChecked={() => toggleAttachment(doc.id)}
                />
              ))}
            </Box>
          </Box>
        ))}
        <Footer
          rightColumn={
            <>
              <Button type="secondary" className={classes.cancelButton} onClick={onCancel}>
                {i18n.t('messaging__docs__btn__close')}
              </Button>
              <Button
                type="contrast"
                className={classes.submitButton}
                onClick={() => onSubmit(selected)}
                disabled={!selected.length}
              >
                {i18n.t('messaging__docs__btn__attach', { count: selected.length })}
                <SubmitIcon className="submitButton-icon" />
              </Button>
            </>
          }
        />
      </Box>
    </ModalWrapper>
  )
}

export default Attachments
