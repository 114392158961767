import { HashMap } from '../../utils/HashMap'
import { OrderItemStates } from '../items/types'
import { PaginationState } from '../../utils/pagination'
import { LoadedLoadingErrorState } from '../../utils/state'
import { Currency } from '../../utils/formatting'

export interface InfiniteListState {
  page: number
  lastRefreshed?: Date
  hasNextPage: boolean
}

export interface EventsState {
  pagination: PaginationState
  events: HashMap<UserEventStreamEvent>
  meta: LoadedLoadingErrorState
  activityStream: InfiniteListState
}

export interface GetEventsResponse {
  events: UserEventStreamEvent[]
}

export interface UserEventStreamEvent {
  aramis_event: VulcanEvent | RevisionDraftSubmittedEvent | FirstDraftSubmittedEvent
  client_name: string | null
}

export interface VulcanEventPayload {
  event: EventTypes
  context: VulcanEventContext
  context_id: number
  origin: string
  variables: unknown
}

export interface VulcanEvent extends VulcanEventPayload {
  id: number
  client_email: string | null
  created_at: string
}

export interface UserEvent {
  event: EventTypes
  variables: unknown
  origin?: string
}

export enum VulcanEventContext {
  Client = 'client',
  User = 'user',
}

export enum EventTypes {
  PhoneScheduled = 'Phone Call Scheduled',
  InterviewScheduled = 'Interview Scheduled',
  InterviewCompleted = 'Interview Completed',
  PhoneCompleted = 'Phone Call Completed',
  UserLoggedInWithAuth0 = 'User Logged In With Auth0',
  MessageSnippetInserted = 'Message Snippet Inserted',
  MessageTemplateUsed = 'Message Template Used',
  ViewedPage = 'Viewed Page',
  FirstDraftSubmitted = 'First Draft Submitted',
  RevisionDraftSubmitted = 'Revision Draft Submitted',
  OrderClosed = 'Order Closed',
  OrderRefunded = 'Order Refunded',
  OrderCanceled = 'Order Canceled',
  UserOrderReassigned = 'User Order Reassigned',
  OrderItemDeleted = 'Order Item Deleted',
  OrderItemAdded = 'Order Item Added',
  OrderUpdated = 'Order Updated',
  OrderAdHocPayCreated = 'Order Adhoc Pay Created',
  ExpertEarnedPerformanceIncentive = 'Expert Earned Performance Incentive',
  OrdersPaid = 'Orders Paid',
  OrderBonusPayCreated = 'Order Bonus Pay Created',
  OfferWithoutImage = 'Offer Without Image',
  OfferImageLoadError = 'Offer Image Load Error',
  UploadModalOpened = 'Upload - Modal Opened',
  UploadModalClosed = 'Upload - Modal Closed',
  UploadAbandonedByPageRefresh = 'Upload - Abandoned By Page Refresh',
  UploadNewHireBlockerClosed = 'Upload - New Hire Blocker Closed',
  UploadNewHireBlockerClosedToSendToAdvocate = 'Upload - New Hire Blocker Closed To Send Document To Advocate',
  UploadPendingFilesAdded = 'Upload - Pending Files Added',
  UploadPendingFileRemoved = 'Upload - Pending File Removed',
  UploadScanStarted = 'Upload - Scan Started',
  UploadFilesFinishedUploading = 'Upload - Files Finished Uploading',
  UploadFilesFinishedScanning = 'Upload - Files Finished Scanning',
  UploadReuploadedFile = 'Upload - Reuploaded File',
  UploadAdvanceToImprovements = 'Upload - Advance To Improvements',
  UploadFilesAttachedToMessage = 'Upload - Files Attached To Message',
  UploadBypassImprovementsYes = ' Upload - Bypass Improvements - User Pressed Yes',
  UploadBypassImprovementsNo = ' Upload - Bypass Improvements - User Pressed No',
  NewOrdersNudgeClicked = 'New Orders Nudge Clicked',
  NewOrdersNudgeDismissed = 'New Orders Nudge Dismissed',
  MessageReceivedNudgeClicked = 'Message Received Nudge Clicked',
  MessageReceivedNudgeDismissed = 'Message Received Nudge Dismissed',
  AutolinkedinCTAStart = 'AutoLinkedIn CTA Start',
  AutolinkedinCTAEdit = 'AutoLinkedIn CTA Edit',
}

export const CTAToEventTypeMap: HashMap<EventTypes> = {
  [OrderItemStates.ScheduleInterview]: EventTypes.InterviewScheduled,
  [OrderItemStates.SchedulePhoneCall]: EventTypes.PhoneScheduled,
  [OrderItemStates.ConductCall]: EventTypes.PhoneCompleted,
  [OrderItemStates.ConductInterview]: EventTypes.InterviewCompleted,
}

export interface AppliedDeliveryIncentive {
  amount: number
  description: string
  id: number | null
}

export interface DraftEventVars {
  is_first_draft: boolean
  client_fulfilled: boolean
  fulfilled_count: number
  applied_incentives: AppliedDeliveryIncentive[] | null
}

export interface FirstDraftSubmittedEvent extends VulcanEvent {
  variables: {
    doc_keys: string[]
    client_id: number
  }
}
export interface OrderBonusCreatedEvent extends VulcanEvent {
  variables: {
    rating: number
    client_id: number
  }
}

export interface OrderAdHocPayCreatedEvent extends VulcanEvent {
  variables: {
    amount: number
    client_id: number
  }
}

export interface OrderItemAddedDeletedEvent extends VulcanEvent {
  variables: {
    items: string[]
    client_id: number
    order_id: number
  }
}

export interface OrdersPaidEvent extends VulcanEvent {
  variables: {
    payment: {
      amount: number
      currency: Currency
    }
  }
}

interface DocMeta {
  doc_key: string
  revision: number
}

export interface RevisionDraftSubmittedEvent extends VulcanEvent {
  variables: {
    docs: DocMeta[]
    client_id: number
  }
}

export interface OrderClosedEvent extends VulcanEvent {
  variables: {
    initiator: 'writer' | 'admin' | 'client'
    client_id: number
  }
}

export interface OrderRefundedEvent extends VulcanEvent {
  variables: {
    pay_original_writer: boolean
    client_id: number
  }
}

export interface VulcanBeautifiedEvent {
  id: number
  text: string
  created_at: string
  client_id: number | null
}
