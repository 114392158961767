import produce from 'immer'

import { OrdersActionTypes, OrdersActionType } from './actions'
import { Order } from './types'
import { mapOrderResponseToOrder } from '../../utils/mapper'
import { HashMap } from '../../utils/HashMap'
import * as stateUtils from '../../utils/state'
import { ClientOrdersAggregateActions } from '../clientOrderAggregate/actions'

export interface OrdersState {
  orders: HashMap<Order>
  meta: stateUtils.MultiLoadedLoadingErrorState
}

export const initialState: OrdersState = {
  orders: {},
  meta: { ...stateUtils.initialMultiLoadedLoadingErrorState },
}

export default function ordersReducer(state: OrdersState = initialState, action: OrdersActionType): OrdersState {
  return produce(state, (draft: OrdersState) => {
    switch (action.type) {
      case OrdersActionTypes.FETCH_ORDERS:
        // I'm reading from the current state for speed purposes here
        // https://github.com/immerjs/immer#pitfalls
        draft.meta.global = stateUtils.setStartState(state.meta.global)
        break
      case OrdersActionTypes.FETCH_ORDERS_SUCCESS:
        draft.meta.global = stateUtils.setSuccessState(state.meta.global)
        const orders = action.payload.data
        orders &&
          orders.forEach(orderResponse => {
            draft.orders[orderResponse.order_id] = mapOrderResponseToOrder(
              orderResponse,
              draft.orders[orderResponse.order_id]
            )
          })
        break
      case OrdersActionTypes.FETCH_ORDERS_FAIL:
        draft.meta.global = stateUtils.setErrorState(state.meta.global, action.error)
        break
      case OrdersActionTypes.FETCH_ORDER:
        draft.meta = stateUtils.setStartStateForItem(action.id, state.meta)
        break
      case OrdersActionTypes.FETCH_ORDER_SUCCESS:
        draft.meta = stateUtils.setSuccessStateForItem(action.meta.previousAction.id, state.meta)
        const orderResponse = action.payload.data
        draft.orders[orderResponse.order_id] = mapOrderResponseToOrder(
          orderResponse,
          draft.orders[orderResponse.order_id]
        )
        break
      case OrdersActionTypes.FETCH_ORDER_FAIL:
        draft.meta = stateUtils.setErrorStateForItem(action.meta.previousAction.id, state.meta, action.error)
        break
      case ClientOrdersAggregateActions.FETCH_USER_AGGREGATED_ORDERS_SUCCESS:
        const response = action.payload.data
        if (!!response && !!response.orders) {
          response.orders.map(aggr =>
            aggr.orders.map(
              order =>
                (draft.orders[order.order_id] = mapOrderResponseToOrder(
                  order,
                  draft.orders[order.order_id],
                  aggr.client.id
                ))
            )
          )
        }
        break
      case ClientOrdersAggregateActions.FETCH_CLIENT_ORDERS_SUCCESS: {
        const response = action.payload.data
        const clientID = action.meta.previousAction.clientID
        if (!!response) {
          response.map(
            order =>
              (draft.orders[order.order_id] = mapOrderResponseToOrder(order, draft.orders[order.order_id], clientID))
          )
        }
        break
      }
    }
  })
}
